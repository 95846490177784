import Button from 'components/atoms/Button/Button';
import { getDateRanges, isRangeWithinLimit } from './helpers';
import { STANDARD_SPACING } from 'constants/styles';
import { IDateRange } from 'interfaces/Summary';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { TTimeZone } from 'types/DateTime';
import { ZonedDateTime } from 'utils/zonedDateTime';
import {
  RANGES_COLUMN_MAXIMUM_ITEMS,
  RANGE_BUTTON_WIDTH_VALUE,
  CALENDAR_PANEL_HEIGHT_VALUE,
  ACTIONS_MINIMUM_HEIGHT_VALUE,
} from './constants';

interface IRangesProps {
  numberOfColumns: number;
}

const Ranges = styled.div<IRangesProps>`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  /* First 32px derived from 28px RangeButton height + 4px bottom margin */
  /* Second 32px derived from 16px Ranges top padding + 16px Ranges bottom padding */
  max-height: calc((${RANGES_COLUMN_MAXIMUM_ITEMS} * 32px) + 32px);
  padding: 16px;
  /* 36px derived from 16px Ranges left padding + 16px Ranges right padding + 4px RangeButton right margin = 36px */
  width: ${(props) =>
    `calc((${props.numberOfColumns} * ${RANGE_BUTTON_WIDTH_VALUE}px) + 36px)`};

  > button {
    margin: 0 4px 4px 0;
  }
`;

const RangeButton = styled(Button)`
  width: ${RANGE_BUTTON_WIDTH_VALUE}px;
`;

const Actions = styled.div`
  align-items: flex-end;
  bottom: 0;
  display: flex;
  flex-direction: row-reverse;
  height: calc(100% - ${CALENDAR_PANEL_HEIGHT_VALUE}px);
  justify-content: space-between;
  min-height: ${ACTIONS_MINIMUM_HEIGHT_VALUE}px;
  padding: 16px 16px 16px 0;
  position: absolute;
  right: 0;
  width: 100%;
`;

const RowLayout = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;

  > :not(:last-child) {
    margin-right: ${STANDARD_SPACING};
  }
`;

interface IProps {
  endDate: ZonedDateTime | null;
  includeRanges?: boolean;
  onCancel: () => void;
  onOk: () => void;
  onRangeButton: (range: IDateRange) => () => void;
  rangeLimitInHours?: number;
  startDate: ZonedDateTime | null;
  timeZone: TTimeZone;
}

const TransmissionCapacityAvailabilityDatePickerFooter = (
  props: IProps,
): JSX.Element => {
  const {
    endDate,
    includeRanges,
    onCancel,
    onOk,
    onRangeButton,
    rangeLimitInHours,
    startDate,
    timeZone,
  } = props;

  const [ranges, setRanges] = useState<IDateRange[]>([]);

  useEffect(() => {
    if (includeRanges && timeZone !== undefined) {
      setRanges(getDateRanges(timeZone));
    }
  }, [includeRanges, timeZone]);

  return (
    <div>
      {ranges !== undefined && ranges.length > 0 ? (
        <Ranges
          numberOfColumns={Math.ceil(
            ranges.length / RANGES_COLUMN_MAXIMUM_ITEMS,
          )}
        >
          {ranges!.map(
            (range: IDateRange): JSX.Element => (
              <RangeButton
                isDisabled={!isRangeWithinLimit(range, rangeLimitInHours)}
                label={range.label}
                key={range.label}
                onClick={onRangeButton(range)}
              />
            ),
          )}
        </Ranges>
      ) : null}
      <Actions>
        <RowLayout>
          <Button label='Cancel' onClick={onCancel} />
          <Button
            isDisabled={startDate === null || endDate === null}
            isPrimary={true}
            label='Ok'
            onClick={onOk}
          />
        </RowLayout>
      </Actions>
    </div>
  );
};

export default TransmissionCapacityAvailabilityDatePickerFooter;
