import { HeaderRendererProps } from 'react-data-grid';
import styled from 'styled-components';
import { TProfileDataGridRow, TProfileDataGridSummaryRow } from 'types/Detail';

const Container = styled.div`
  padding: 3px 4px;
`;

const EnergyProfileSnapshotsDateColumnHeader = (
  props: HeaderRendererProps<TProfileDataGridRow, TProfileDataGridSummaryRow>,
): JSX.Element => {
  const {
    column: { name },
  } = props;

  return <Container>{name}</Container>;
};

export default EnergyProfileSnapshotsDateColumnHeader;
