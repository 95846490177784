import Page from 'components/organisms/Page/Page';
import DetailPrintPageContent from 'components/pages/DetailPrintPage/DetailPrintPageContent';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { TRootState } from 'types/Redux';

interface IDetailPrintPage {
  setIsLightMode: (isLightMode: boolean) => void;
}

const retrieveDetailPageState = (state: TRootState) => {
  const { draft_id, name, tag_id, tag_primary_key, template_id } =
    state.detail.present;

  return {
    draft_id,
    name,
    tag_id,
    tag_primary_key,
    template_id,
  };
};

const DetailPrintPage = ({ setIsLightMode }: IDetailPrintPage): JSX.Element => {
  const { draft_id, name, tag_id, tag_primary_key, template_id } = useSelector(
    retrieveDetailPageState,
  );

  useEffect(() => {
    setIsLightMode(true);
  }, [setIsLightMode]);

  let pageTitle: string = 'Summary Print Detail - PCI e-Tag+';

  if (template_id === undefined) {
    if (tag_id !== null && tag_id.ui_tag_id !== null) {
      if (draft_id === null) {
        if (tag_primary_key !== undefined) {
          pageTitle = `Print Tag: ${tag_id.ui_tag_id}`;
        }
      } else {
        pageTitle = `Print Draft: ${tag_id.ui_tag_id}`;
      }
    }
  } else if (name !== null) {
    pageTitle = name;
  }

  return (
    <Page
      appActions={null}
      PageContent={DetailPrintPageContent}
      pageTitle={pageTitle}
      setIsLightMode={setIsLightMode}
    />
  );
};

export default DetailPrintPage;
