import DataTable, {
  IDataTableProps,
} from 'components/molecules/DataTable/DataTable';
import {
  DEFAULT_FONT_SIZE_STEP,
  DEFAULT_MAXIMUM_FONT_SIZE,
  DEFAULT_MAXIMUM_PADDING_SIZE,
  DEFAULT_MINIMUM_FONT_SIZE,
  DEFAULT_MINIMUM_PADDING_SIZE,
  DEFAULT_PADDING_STEP,
  FONT_SIZE_EPSILON,
  PADDING_SIZE_EPSILON,
} from 'components/pages/DetailPrintPage/AutoSizingProfileDataTable/constants';
import { getProfileDataTableDataFor } from 'components/pages/DetailPrintPage/AutoSizingProfileDataTable/helpers';
import { ID_KEY } from 'constants/General';
import {
  VIEW_DATA_TABLE_CENTERED_CONTENT,
  VIEW_DATA_TABLE_SHARED_STYLES,
} from 'constants/styles';
import { EProfileDataGridCellType } from 'enums/Detail';
import { IThemedProps } from 'interfaces/Component';
import { IProfileDataGridCell } from 'interfaces/Detail';
import { IViewDataTableColumn } from 'interfaces/View';
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import styled from 'styled-components';
import { TTimeZone } from 'types/DateTime';
import {
  TProfileDataGridColumn,
  TProfileDataGridRow,
  TProfileDataGridSummaryRow,
} from 'types/Detail';
import { alternatingTableRowBackground } from 'utils/styles';

interface IScalingProps {
  fontSize: number;
  paddingSize: number;
}

const ViewDataTable = styled(
  (
    props: IDataTableProps<
      IViewDataTableColumn<IProfileDataGridCell | null>,
      TProfileDataGridRow
    > &
      IThemedProps &
      IScalingProps,
  ) => <DataTable {...props} />,
)`
  ${VIEW_DATA_TABLE_SHARED_STYLES}
  ${VIEW_DATA_TABLE_CENTERED_CONTENT}
  ${(props) => alternatingTableRowBackground(props)}

  ${(props) => `
    &&& .ant-table {
      &-thead {
        > tr > th {
          padding: 3px ${props.paddingSize}px;
        }
      }
    }
  `}

  ${(props) => `
    .ant-table {
      &-thead {
        > tr > th {
          font-size: ${props.fontSize}px;
          max-width: 103px;
          white-space: pre-line;
        }
      }

      &-tbody, &-summary {
        > tr > td {
          font-size: ${props.fontSize}px;
          white-space: pre-line;
        }
      }
    }
  `}
`;

interface IProfileDataTableProps {
  columns: TProfileDataGridColumn[];
  fontStep?: number;
  maximumFontSize?: number;
  maximumPaddingSize?: number;
  minimumFontSize?: number;
  minimumPaddingSize?: number;
  paddingStep?: number;
  rows: TProfileDataGridRow[];
  selectedRequestKey: string;
  summaryRows: TProfileDataGridSummaryRow[];
  timeZone: TTimeZone;
  width: number;
}

const AutoSizingProfileDataTable = ({
  columns,
  fontStep = DEFAULT_FONT_SIZE_STEP,
  maximumFontSize = DEFAULT_MAXIMUM_FONT_SIZE,
  maximumPaddingSize = DEFAULT_MAXIMUM_PADDING_SIZE,
  minimumFontSize = DEFAULT_MINIMUM_FONT_SIZE,
  minimumPaddingSize = DEFAULT_MINIMUM_PADDING_SIZE,
  paddingStep = DEFAULT_PADDING_STEP,
  rows,
  selectedRequestKey,
  summaryRows,
  timeZone,
  width,
}: IProfileDataTableProps): JSX.Element => {
  const { currentTheme } = useThemeSwitcher();
  const [fontSize, setFontSize] = useState<number>(maximumFontSize);
  const [paddingSize, setPaddingSize] = useState<number>(maximumPaddingSize);
  const [previousWidth, setPreviousWidth] = useState<number>(width + 1);
  const divRef = useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;
  const tableContentRef = useRef<HTMLDivElement>();
  const tableRef = useRef<HTMLTableElement>();

  useEffect(() => {
    if (divRef.current) {
      const tableContentElements: HTMLCollectionOf<Element> =
        divRef.current.getElementsByClassName('ant-table-content');

      if (tableContentElements.length === 1) {
        tableContentRef.current = tableContentElements[0] as HTMLDivElement;

        const tableElements: HTMLCollectionOf<HTMLTableElement> =
          tableContentRef.current.getElementsByTagName('table');

        if (tableElements.length === 1) {
          tableRef.current = tableElements[0];
        } else {
          throw new Error('Invalid number of table elements found');
        }
      } else {
        throw new Error('Invalid number of table content elements found');
      }
    }
  }, [divRef]);

  useEffect(() => {
    if (tableContentRef.current && tableRef.current) {
      const widthDelta: number = width - previousWidth;

      if (widthDelta < 0) {
        if (
          tableRef.current.getBoundingClientRect().width -
            tableContentRef.current.getBoundingClientRect().width >
          0
        ) {
          if (paddingSize > minimumPaddingSize + PADDING_SIZE_EPSILON) {
            setPaddingSize(
              (previousPaddingSize: number): number =>
                (previousPaddingSize -= paddingStep),
            );
          } else if (fontSize > minimumFontSize + FONT_SIZE_EPSILON) {
            setFontSize(
              (previousFontSize: number): number =>
                (previousFontSize -= fontStep),
            );
          }
        } else {
          setPreviousWidth(width);
        }
      } else if (widthDelta > 0) {
        if (
          tableRef.current.getBoundingClientRect().width -
            tableContentRef.current.getBoundingClientRect().width ===
          0
        ) {
          if (fontSize < maximumFontSize) {
            setFontSize(
              (previousFontSize: number): number =>
                (previousFontSize += fontStep),
            );
          } else if (paddingSize < maximumPaddingSize) {
            setPaddingSize(
              (previousPaddingSize: number): number =>
                (previousPaddingSize += paddingStep),
            );
          }
        } else {
          setPreviousWidth(width);
        }
      }
    }
  }, [
    fontSize,
    fontStep,
    maximumFontSize,
    maximumPaddingSize,
    minimumFontSize,
    minimumPaddingSize,
    paddingSize,
    paddingStep,
    previousWidth,
    width,
  ]);

  const { profileColumns, profileSummary } = useMemo(
    () =>
      getProfileDataTableDataFor(
        columns,
        summaryRows,
        selectedRequestKey,
        timeZone,
      ),
    [columns, selectedRequestKey, summaryRows, timeZone],
  );

  const getRowKey = useCallback(
    (profileDataGridRow: TProfileDataGridRow): string => {
      if (
        profileDataGridRow[ID_KEY]?.type !== EProfileDataGridCellType.String
      ) {
        throw new Error(
          `Invalid id for profileDataGridRow: ${JSON.stringify(
            profileDataGridRow,
          )}`,
        );
      }

      return profileDataGridRow[ID_KEY]!.value as string;
    },
    [],
  );

  return (
    <div className='profile-data-table' ref={divRef}>
      <ViewDataTable
        columns={profileColumns}
        currentTheme={currentTheme!}
        data={rows}
        fontSize={fontSize}
        paddingSize={paddingSize}
        pagination={false}
        rowKey={getRowKey}
        summary={profileSummary}
      />
    </div>
  );
};

export default AutoSizingProfileDataTable;
