interface ITerminatedIconProps {
  className?: string;
}

const TerminatedIcon = ({ className }: ITerminatedIconProps): JSX.Element => (
  <svg
    aria-hidden='true'
    className={className}
    clipRule='evenodd'
    fillRule='evenodd'
    height='1em'
    strokeLinejoin='round'
    strokeMiterlimit='2'
    viewBox='0 0 1000 1000'
    width='1em'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle
      cx='413.804'
      cy='336.526'
      r='238.771'
      fill='#8a8a8a'
      transform='translate(-366.528 -204.703) scale(2.09405)'
    />
    <path
      d='M545.8 750h-91.073V326.777H293.244v-76.532h414.038v76.532H545.8V750z'
      fill='#fff'
      fillRule='nonzero'
    />
  </svg>
);

export default TerminatedIcon;
