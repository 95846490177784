import { BOOLEAN_FALSE_LABEL, BOOLEAN_TRUE_LABEL } from 'constants/misc';
import { HIGHLIGHT_ORANGE, STANDARD_SPACING } from 'constants/styles';
import { IEntityInfo } from 'interfaces/Entity';
import {
  ITagHeaderDataSet,
  ITagNoteDataSet,
} from 'interfaces/SummaryInformation';
import { IViewDataTableColumn } from 'interfaces/View';
import styled from 'styled-components';
import { isEmptyValue } from 'utils/general';
import { getColumnRender } from 'utils/views';

export const getTagNoteColumns = (
  isUnconstrained: boolean,
): IViewDataTableColumn<ITagNoteDataSet>[] => [
  {
    dataIndex: 'notes',
    render: getColumnRender(isUnconstrained),
    title: 'Tag Note',
  },
];
interface ITestFlagProps {
  highlight?: boolean;
}

const TestFlag = styled.div<ITestFlagProps>`
  color: ${(props) =>
    props.highlight === true ? HIGHLIGHT_ORANGE : 'inherit'};
  font-weight: ${(props) => (props.highlight === true ? 'bold' : 'inherit')};
`;

const CcEntities = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  > span {
    margin-right: ${STANDARD_SPACING};
    white-space: nowrap;
  }
`;

export const getTagHeaderColumns = (
  isUnconstrained: boolean,
): IViewDataTableColumn<ITagHeaderDataSet>[] => [
  {
    dataIndex: 'transaction_type',
    render: getColumnRender(isUnconstrained),
    title: 'Tag Type',
    width: 'calc(100% * (5 / 24))',
  },
  {
    dataIndex: 'test_flag',
    render: (value: unknown): JSX.Element => {
      const testFlag: boolean | undefined = isEmptyValue(value)
        ? undefined
        : (value as boolean);
      return (
        <TestFlag highlight={testFlag}>
          {testFlag === true
            ? BOOLEAN_TRUE_LABEL
            : testFlag === false
            ? BOOLEAN_FALSE_LABEL
            : ''}
        </TestFlag>
      );
    },
    title: 'Test Flag',
    width: 'calc(100% * (3 / 24))',
  },
  {
    dataIndex: 'ccList',
    render: (value: unknown): JSX.Element => (
      <CcEntities>
        {(value as IEntityInfo[]).map(
          (entityInfo: IEntityInfo): JSX.Element => (
            <span key={entityInfo.tagging_entity_id}>
              {entityInfo.entity_code}
            </span>
          ),
        )}
      </CcEntities>
    ),
    title: 'CC Entities',
  },
];
