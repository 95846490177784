import { List as AntDesignList } from 'antd';
import Link from 'components/atoms/Link/Link';
import { ISearchResult } from 'components/molecules/ToEntityETagSearchPanel/types';
import { DATE_TIME_FORMAT } from 'constants/time';
import { ESearchType } from 'enums/ETag';
import { IETagSummaryAttribute } from 'interfaces/ETag';
import styled from 'styled-components';
import { TTimeZone } from 'types/DateTime';
import { TToEntityId } from 'types/ToEntity';
import { getTimeZoneDisplayString } from 'utils/time';
import { ZonedDateTime } from 'utils/zonedDateTime';

const { Item: AntDesignListItem } = AntDesignList;

const ListItem = styled(AntDesignListItem)`
  padding: 2px 0;
`;

export const searchTypeToUid = (value: ESearchType): string => value as string;

export const getETagSummaryAttributeToSearchResult =
  (timeZone: TTimeZone, toEntityId: TToEntityId) =>
  (eTagSummaryAttribute: IETagSummaryAttribute): ISearchResult => {
    const {
      end_date,
      start_date,
      tag_id: { ui_tag_id },
      tag_primary_key,
    } = eTagSummaryAttribute;
    const timeZoneDisplayString: string = getTimeZoneDisplayString(timeZone);
    const start: string =
      start_date === null
        ? ''
        : ZonedDateTime.parseIso(start_date, timeZone).format(DATE_TIME_FORMAT);
    const end: string =
      end_date === null
        ? ''
        : ZonedDateTime.parseIso(end_date, timeZone).format(DATE_TIME_FORMAT);
    let label: string = `${ui_tag_id} (${start} - ${end} ${timeZoneDisplayString})`;

    label = (eTagSummaryAttribute as any)['entity']
      ? `${(eTagSummaryAttribute as any)['entity']} - ${label}`
      : label;

    const toEntity = (eTagSummaryAttribute as any).to_entity || toEntityId;

    return {
      key: label,
      label,
      url: `/detail?mode=review&toEntity=${toEntity}&tagPrimaryKey=${tag_primary_key}&defaultTimeZone=${timeZone}`,
    };
  };

export const searchResultListItemRenderer = (
  searchResult: ISearchResult,
  index: number,
): JSX.Element => {
  const { key, label, url } = searchResult;

  return (
    <ListItem key={key}>
      <Link to={url} target='_blank'>
        {label}
      </Link>
    </ListItem>
  );
};
