import { MenuOutlined } from '@ant-design/icons';
import Menu from './Menu/Menu';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { TTimeZone } from '../../../types/DateTime';
import { IToEntity } from '../../../interfaces/ToEntity';
import useUserInfo from '../../../hooks/useUserInfo';

const StyledDiv = styled.div`
  cursor: pointer;
  font-size: 16px;
  padding-top: 6px;
`;

interface ITopBarMenuProps {
  timeZone?: TTimeZone;
  encodedPermissionsId?: string;
  encodedTenantPermissionsId?: string;
  toEntity?: IToEntity;
}

const TopBarMenu = (props: ITopBarMenuProps) => {
  const {
    encodedPermissionsId,
    encodedTenantPermissionsId,
    timeZone,
    toEntity,
  } = props;
  const node = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);

  const { toEntities } = useUserInfo();

  useEffect(() => {
    setOpen(false);
  }, []);

  return (
    <div ref={node}>
      <StyledDiv
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
      >
        <MenuOutlined />
        {open ? (
          <Menu
            open={open}
            setOpen={setOpen}
            encodedPermissionsId={encodedPermissionsId}
            encodedTenantPermissionsId={encodedTenantPermissionsId}
            timeZone={timeZone}
            toEntity={toEntity}
            toEntities={toEntities}
          />
        ) : null}
      </StyledDiv>
    </div>
  );
};

export default TopBarMenu;
