import { AxiosResponse } from 'axios';
import { IEditSummaryInformation } from 'components/organisms/SummaryInformationView/types';
import { TEMPLATE_GROUP_NAME_REGEX } from 'constants/ETag';
import { IAutoCompleteValue } from 'interfaces/Component';
import {
  IETagTemplateGroup,
  IETagTemplateGroupsResponse,
} from 'interfaces/ETag';
import { ITemplateDataSet } from 'interfaces/SummaryInformation';
import { useCallback, useMemo } from 'react';
import { retrieveTemplateGroups } from 'services/agent/templates';
import { TToEntityId } from 'types/ToEntity';
import { filterTemplateGroups } from 'utils/detail';
import { isEmptyValue, isSuccessStatus } from 'utils/general';
import { getColumnAutoCompleteRender, getColumnRender } from 'utils/views';

const groupNameInputRender =
  getColumnAutoCompleteRender<ITemplateDataSet>('100%');

const useTemplateHeaderEditColumns = (
  initialTemplateDataSet: ITemplateDataSet[] | undefined,
  isDetailDeleted: boolean,
  isDetailDeletedChanged: boolean,
  isDetailUpdating: boolean,
  isDetailUpdatingChanged: boolean,
  isUnconstrained: boolean,
  onChange: (editSummaryInformation: IEditSummaryInformation) => void,
  toEntityId?: TToEntityId,
) => {
  const getInitialGroupNameInputValue = useCallback(
    (): string =>
      initialTemplateDataSet?.[0] === undefined
        ? ''
        : initialTemplateDataSet[0].group_name || '',
    [initialTemplateDataSet],
  );

  const getGroupNameInputKey = useCallback(
    (record: ITemplateDataSet): string => record.key,
    [],
  );

  const getGroupNameValues = useCallback(async () => {
    if (toEntityId === undefined) {
      return [];
    }

    const groupsResponse: AxiosResponse = await retrieveTemplateGroups(
      toEntityId,
    );

    const eTagTemplateGroupsResponse: IETagTemplateGroupsResponse =
      groupsResponse.data;

    if (!isSuccessStatus(groupsResponse.status)) {
      throw new Error(eTagTemplateGroupsResponse.errorMessage!);
    }

    const incomingTemplateGroups: IETagTemplateGroup[] =
      eTagTemplateGroupsResponse.response;

    return incomingTemplateGroups.map(
      (option: IETagTemplateGroup): IAutoCompleteValue => ({
        value: option.group_name,
      }),
    );
  }, [toEntityId]);

  const handleGroupNameInputChange = useCallback(
    (value: string) => {
      if (isEmptyValue(value) || TEMPLATE_GROUP_NAME_REGEX.test(value)) {
        onChange({ groupName: value });
      }
    },
    [onChange],
  );

  return useMemo(
    () => [
      {
        dataIndex: 'group_name',
        render: groupNameInputRender({
          filterOption: filterTemplateGroups,
          getAutoCompleteValues: getGroupNameValues,
          getDefaultValue: getInitialGroupNameInputValue,
          getKey: getGroupNameInputKey,
          isDisabled: isDetailDeleted || isDetailUpdating,
          onChange: handleGroupNameInputChange,
          toEntityId,
        }),
        shouldCellUpdate: (
          record: ITemplateDataSet,
          previousRecord: ITemplateDataSet,
        ): boolean =>
          isDetailDeletedChanged ||
          isDetailUpdatingChanged ||
          record.group_name !== previousRecord.group_name,
        title: 'Template Group',
        width: '25%',
      },
      {
        dataIndex: 'creator',
        render: getColumnRender(isUnconstrained),
        title: 'Template Owner',
        width: '25%',
      },
      {
        dataIndex: 'lastUpdateTime',
        render: getColumnRender(isUnconstrained),
        title: 'Last Update Time',
        width: '25%',
      },
      {
        dataIndex: 'last_update_user',
        render: getColumnRender(isUnconstrained),
        title: 'Last Updated By',
        width: '25%',
      },
    ],
    [
      getInitialGroupNameInputValue,
      getGroupNameInputKey,
      getGroupNameValues,
      isDetailDeleted,
      isDetailUpdating,
      isUnconstrained,
      handleGroupNameInputChange,
      toEntityId,
      isDetailDeletedChanged,
      isDetailUpdatingChanged,
    ],
  );
};

export default useTemplateHeaderEditColumns;
