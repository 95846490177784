import { Auth, Hub } from 'aws-amplify';
import { PCI_MODULE_KEY } from 'components/services/ETagWebSocket/constants';
import EchoPopover from 'components/services/ETagWebSocket/EchoPopover';
import {
  EETagMessageSchema,
  EETagPciModule,
  TETagMessage,
} from 'components/services/ETagWebSocket/types';
import usePrevious from 'hooks/usePrevious';
import useWebSocket from 'hooks/useWebSocket/useWebSocket';
import { IAlert } from 'interfaces/Alert';
import {
  IETagCheckoutReportNotification,
  IETagNotification,
} from 'interfaces/ETag';
import { useCallback, useEffect, useState } from 'react';
import { TPlacement } from 'types/General';
import { TToEntityId } from 'types/ToEntity';
import useAsyncEffect from 'use-async-effect';
import { getIsProduction } from 'utils/general';

const apiConfig = window.ETAG_DEPLOYMENT.apiConfig;

const ECHO_POPOVER_CONFIG = {
  buttonLabel: 'ETag Web Socket Echo',
  popoverButtonOffset: { right: '96px', top: '8px' },
  popoverPlacement: 'leftBottom' as TPlacement,
};

export interface IETagMessage {
  message_schema: EETagMessageSchema;
  [PCI_MODULE_KEY]: string;
}

const messageSerializer = (eTagMessage: TETagMessage): string =>
  JSON.stringify(eTagMessage);

const messageDeserializer = (data: string): TETagMessage => {
  const eTagMessage: TETagMessage = JSON.parse(data) as TETagMessage;

  if (eTagMessage.message_schema === undefined) {
    throw new Error(`Missing property: message_schema on data: ${data}`);
  }

  if (eTagMessage[PCI_MODULE_KEY] === undefined) {
    throw new Error(`Missing property: ${PCI_MODULE_KEY} on data: ${data}`);
  }

  return eTagMessage;
};

interface IProps {
  handleAlertRef?: React.RefObject<(alert: IAlert) => void>;
  handleETagCheckoutReportNotificationRef?: React.RefObject<
    (eTagNotification: IETagCheckoutReportNotification) => void
  >;
  handleETagNotificationRef?: React.RefObject<
    (eTagNotification: IETagNotification) => void
  >;
  toEntityId: TToEntityId;
  key?: string;
}

const ETagWebSocket = (props: IProps): JSX.Element => {
  const {
    handleAlertRef,
    handleETagCheckoutReportNotificationRef,
    handleETagNotificationRef,
    toEntityId,
  } = props;
  const [webSocketUrl, setWebSocketUrl] = useState<string | undefined>(
    undefined,
  );
  const [feedback, setFeedback] = useState<string | null>(null);
  const [enableEcho, setEnableEcho] = useState<boolean>(false);
  const [tokenRefreshFlag, setTokenRefreshFlag] = useState<boolean>(false);
  const previousToEntityId = usePrevious(toEntityId);

  const { error, isConnected, postMessage, setMessageHandler, setSentHandler } =
    useWebSocket<TETagMessage>({
      messageDeserializer,
      messageSerializer,
      useEcho: enableEcho,
      webSocketUrl,
    });

  // Shortcut to enable echo
  useEffect(() => {
    // Listen for token refresh events
    const awsAmplifyAuthEventListener = (data: any) => {
      switch (data.payload.event) {
        case 'tokenRefresh':
          setTokenRefreshFlag(true);
          break;
      }
    };
    Hub.listen('auth', awsAmplifyAuthEventListener);
    const isProduction: boolean = getIsProduction();
    const keyupHandler = (keyboardEvent: KeyboardEvent) => {
      if (
        keyboardEvent.code === 'KeyD' &&
        keyboardEvent.ctrlKey &&
        keyboardEvent.altKey
      ) {
        setEnableEcho(
          (previousEnableEcho: boolean): boolean => !previousEnableEcho,
        );
      }
    };

    if (!isProduction) {
      document.addEventListener('keyup', keyupHandler);
    }

    return () => {
      if (!isProduction) {
        document.removeEventListener('keyup', keyupHandler);
      }
    };
  }, []);

  // Manage connection
  useAsyncEffect(async () => {
    if (
      (!enableEcho && (!isConnected || previousToEntityId !== toEntityId)) ||
      tokenRefreshFlag
    ) {
      const cognitoUserSession = await Auth.currentSession();
      const authorization: string = cognitoUserSession
        .getIdToken()
        .getJwtToken();
      const webSocketUrl: string = `${
        apiConfig.websocket.baseurl
      }?toEntity=${encodeURIComponent(
        toEntityId,
      )}&Authorization=${encodeURIComponent(authorization)}`;

      setWebSocketUrl(webSocketUrl);
      setTokenRefreshFlag(false);
    }
  }, [isConnected, previousToEntityId, toEntityId, tokenRefreshFlag]);

  // Message filter and demultiplexer. This is only set up on mount to avoid
  // changing the message handler once the webSocketWorker is running.
  const messageHandler = useCallback(
    (eTagMessage: TETagMessage) => {
      if (eTagMessage[PCI_MODULE_KEY] === EETagPciModule.ETag) {
        const { message_schema } = eTagMessage;
        switch (message_schema) {
          case EETagMessageSchema.AlertViewModel: {
            if (handleAlertRef && handleAlertRef.current) {
              handleAlertRef.current(eTagMessage as IAlert);
            }
            break;
          }
          case EETagMessageSchema.CompositeStateChangeNotification:
          case EETagMessageSchema.DraftNotification:
          case EETagMessageSchema.RevisionFileNotification:
          case EETagMessageSchema.ScheduledRequestNewTagNotification:
          case EETagMessageSchema.SimpleTagNotification: {
            if (
              handleETagNotificationRef &&
              handleETagNotificationRef.current
            ) {
              handleETagNotificationRef.current(
                eTagMessage as IETagNotification,
              );
            }
            break;
          }
          case EETagMessageSchema.SummaryCheckoutReportNotification:
          case EETagMessageSchema.AFTActualEnergyFlowReportNotification: {
            if (
              handleETagCheckoutReportNotificationRef &&
              handleETagCheckoutReportNotificationRef.current
            ) {
              handleETagCheckoutReportNotificationRef.current(
                eTagMessage as IETagCheckoutReportNotification,
              );
            }
            break;
          }
          default:
            break;
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleAlertRef, handleETagNotificationRef],
  );

  useEffect(
    () => {
      setMessageHandler(messageHandler);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleMessageSent = useCallback(() => {
    setFeedback('Successfully sent');
  }, []);

  useEffect(() => {
    setSentHandler(handleMessageSent);
  }, [handleMessageSent, setSentHandler]);

  return (
    <>
      {enableEcho ? (
        <EchoPopover
          config={ECHO_POPOVER_CONFIG!}
          error={error}
          feedback={feedback}
          isConnected={isConnected}
          messageDeserializer={messageDeserializer}
          messageHandler={messageHandler}
          postMessage={postMessage}
        />
      ) : null}
    </>
  );
};

export default ETagWebSocket;
