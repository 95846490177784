import {
  PlusCircleOutlined,
  ReloadOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import { Card, Collapse, Table } from 'antd';
import { AxiosResponse } from 'axios';
import Checkbox from 'components/atoms/Checkbox/Checkbox';
import ErrorMessage from 'components/atoms/ErrorMessage/ErrorMessage';
import IconButton from 'components/atoms/IconButton/IconButton';
import SeparatedRowLayout from 'components/atoms/SeparatedRowLayout/SeparatedRowLayout';
import DotSpinner from 'components/atoms/Spinner/Spinner';
import Tooltip from 'components/molecules/Tooltip/Tooltip';
import { ConfigSectionTitle } from 'components/organisms/ToEntitySummaryUiConfiguration/styledComponents';
import { DEFAULT_REASONS } from 'constants/Approval';
import { STANDARD_SPACING } from 'constants/styles';
import usePermissions from 'hooks/usePermissions';
import { IThemedProps } from 'interfaces/Component';
import {
  IETagPutReasons,
  IETagReasons,
  IETagReasonsResponse,
} from 'interfaces/ETag';
import { IToEntity } from 'interfaces/ToEntity';
import { useCallback, useMemo, useState } from 'react';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import { retrieveReasons, updateReasons } from 'services/approval/approval';
import styled from 'styled-components';
import { TToEntityId } from 'types/ToEntity';
import useAsyncEffect from 'use-async-effect';
import { captureError } from 'utils/error';
import { encodeIds, isSuccessStatus } from 'utils/general';
import { componentBackgroundColour } from 'utils/styles';
import EditableCell from './EditableCell';
import { EditableRow } from './EditableRow';

export enum REASON_GROUP {
  APPROVE = 'approve_reasons',
  DENIED = 'denied_reasons',
  STUDY = 'study_reasons',
}

interface ETagCommonReasonNotesConfigurationProps {
  selectedToEntity: IToEntity;
  saveToAll: (reasons: IETagReasons) => Promise<any[]>;
  setIsDirty: (state: boolean) => void;
  isDirty: boolean;
  entityCount: number;
  encodedPermissionsId: string;
  onConfirmBeforeReload?: () => boolean;
}

export type TDataTableETagReasons = {
  key: string;
  index: number;
  name: string;
  groupKey: keyof IETagReasons;
};

const GroupContainer = styled.div<IThemedProps>`
  width: 25%;
  min-width: 700px;
  display: flex;
  flex-direction: column;
  padding: ${STANDARD_SPACING};
  align-items: stretch;
  background: ${(props) => componentBackgroundColour(props)};
`;

const GroupList = styled.div`
  /* width: 30%; */
`;

const ComponentHeading = styled(SeparatedRowLayout)`
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0.25rem;
`;

const ReasonActions = styled.div`
  display: flex;
  flex-direction: row;
`;

const CheckboxWrapper = styled.div`
  align-self: center;
  padding-left: 0.25rem;
  && > span {
    padding-left: 0.25rem;
  }
}
`;

const Spinner = styled(DotSpinner)`
  width: 100%;
`;

type TGroupReasons = {
  key: keyof IETagReasons;
  title: string;
};

const ETagCommonReasonNotesConfiguration = ({
  selectedToEntity,
  saveToAll,
  setIsDirty,
  isDirty,
  entityCount,
  encodedPermissionsId,
  onConfirmBeforeReload,
}: ETagCommonReasonNotesConfigurationProps): JSX.Element => {
  const { to_entity } = selectedToEntity;
  const { currentTheme } = useThemeSwitcher();
  const [reasons, setReasons] = useState<IETagReasons>(DEFAULT_REASONS);
  const [loading, setLoading] = useState<boolean>(true);
  const [saveAll, setSaveAll] = useState<boolean>(false);
  const permissions = usePermissions(
    encodeIds([encodedPermissionsId, 'CommonReasonNotesConfiguration']),
  );

  const handleSetSaveAll = () => {
    setSaveAll(!saveAll);
    setIsDirty(true);
  };

  const handleRemove = useCallback(
    (record: TDataTableETagReasons) => {
      const _reasons = Object.assign({}, reasons);
      _reasons[record.groupKey as keyof IETagReasons].splice(
        record.index as number,
        1,
      );
      setIsDirty(true);
      setReasons(_reasons);
    },
    [reasons, setIsDirty],
  );

  const handleAdd = (groupKey: keyof IETagReasons) => {
    const _reasons = Object.assign({}, reasons);
    _reasons[groupKey].push('');
    setIsDirty(true);
    setReasons(_reasons);
  };

  const handleReasonInput = useCallback(
    (record: TDataTableETagReasons) => {
      const _reasons: IETagReasons = Object.assign({}, reasons);
      const currentName: string = _reasons[record.groupKey][record.index];
      if (currentName.trim() !== record.name.trim()) {
        _reasons[record.groupKey].splice(record.index, 1, record.name);
        setIsDirty(true);
        setReasons(_reasons);
      }
    },
    [reasons, setIsDirty],
  );

  const saveList = useCallback(async () => {
    setLoading(true);
    if (saveAll) {
      try {
        await saveToAll(reasons);

        setIsDirty(false);
      } catch (error: any) {
        captureError(error);
      } finally {
        setLoading(false);
      }
    } else {
      try {
        const updateReasonsResponse: AxiosResponse<IETagReasonsResponse> =
          await updateReasons(to_entity as TToEntityId, reasons);

        const eTagReasonsResponse: IETagReasonsResponse =
          updateReasonsResponse.data;

        if (!isSuccessStatus(updateReasonsResponse.status)) {
          throw new Error(eTagReasonsResponse.errorMessage!);
        }

        if (
          (eTagReasonsResponse.response as IETagPutReasons)
            .common_reasons_id === to_entity
        ) {
          setIsDirty(false);
        }
      } catch (error: any) {
        captureError(error);
      } finally {
        setLoading(false);
      }
    }
  }, [reasons, saveAll, saveToAll, setIsDirty, to_entity]);

  const reloadList = useCallback(async () => {
    try {
      setLoading(true);

      const retrieveReasonsResponse: AxiosResponse<IETagReasonsResponse> =
        await retrieveReasons(to_entity as TToEntityId);

      const eTagReasonsResponse: IETagReasonsResponse =
        retrieveReasonsResponse.data;

      if (!isSuccessStatus(retrieveReasonsResponse.status)) {
        throw new Error(eTagReasonsResponse.errorMessage!);
      }

      setReasons(eTagReasonsResponse.response as IETagReasons);
      setIsDirty(false);
    } catch (error: any) {
      setReasons(DEFAULT_REASONS);
    } finally {
      setLoading(false);
    }
  }, [setIsDirty, to_entity]);

  const onReload = useCallback(() => {
    if (onConfirmBeforeReload !== undefined) {
      onConfirmBeforeReload() && reloadList();
      return;
    }

    reloadList();
  }, [reloadList, onConfirmBeforeReload]);

  useAsyncEffect(async () => {
    reloadList();
  }, [reloadList]);

  const columns = useMemo(
    () => [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        editable: true,
        onCell: (record: TDataTableETagReasons) => ({
          record,
          editable: true,
          dataIndex: 'name',
          title: 'Name',
          handleSave: handleReasonInput,
          handleRemove: handleRemove,
          encodedPermissionsId,
        }),
        shouldCellUpdate: (
          record: TDataTableETagReasons,
          prevRecord: TDataTableETagReasons,
        ) => {
          return record.name !== prevRecord.name;
        },
      },
    ],
    [handleReasonInput, handleRemove, encodedPermissionsId],
  );

  const hasEmptyReasonName: boolean = useMemo(() => {
    const { approve_reasons, study_reasons, denied_reasons } = reasons;
    return approve_reasons
      .concat(study_reasons, denied_reasons)
      .find((name: string) => name === '') === undefined
      ? false
      : true;
  }, [reasons]);

  const groupList: TGroupReasons[] = useMemo(
    () => [
      {
        key: REASON_GROUP.APPROVE,
        title: 'Approve',
      },
      {
        key: REASON_GROUP.DENIED,
        title: 'Deny',
      },
      {
        key: REASON_GROUP.STUDY,
        title: 'Study',
      },
    ],
    [],
  );

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const mapReasonsByGroup = (
    groupKey: keyof IETagReasons,
  ): TDataTableETagReasons[] => {
    const grpIdx = groupList.findIndex(({ key }) => key === groupKey);
    return reasons[groupKey].map((name, index) => ({
      key: `${grpIdx ? grpIdx * index : index}`,
      index: index,
      name: name,
      groupKey,
    }));
  };

  return permissions.isDisplayable ? (
    <GroupContainer currentTheme={currentTheme!}>
      <Card>
        <ComponentHeading>
          <ConfigSectionTitle>
            Common Reason Notes Configuration
          </ConfigSectionTitle>
        </ComponentHeading>
        <ReasonActions>
          <Tooltip title={'Refresh reasons'}>
            <IconButton
              icon={<ReloadOutlined />}
              onClick={onReload}
              encodedPermissionsId={encodeIds([
                encodedPermissionsId,
                'CommonReasonNotesConfiguration:reload',
              ])}
            />
          </Tooltip>
          <Tooltip title={'Save Reasons'}>
            <IconButton
              isDisabled={!isDirty || loading || hasEmptyReasonName}
              icon={<SaveOutlined />}
              onClick={saveList}
              encodedPermissionsId={encodeIds([
                encodedPermissionsId,
                'CommonReasonNotesConfiguration:save',
              ])}
            />
          </Tooltip>
          {entityCount > 0 && (
            <CheckboxWrapper>
              <Checkbox
                checked={saveAll}
                label='Save to all Entities'
                onChange={handleSetSaveAll}
                encodedPermissionsId={encodeIds([
                  encodedPermissionsId,
                  'CommonReasonNotesConfiguration:save',
                ])}
              />
            </CheckboxWrapper>
          )}
        </ReasonActions>
        {loading ? (
          <Spinner />
        ) : (
          <GroupList>
            <Collapse
              defaultActiveKey={groupList[0].key}
              // onChange={collapseGroup}
            >
              {groupList.map((group: TGroupReasons) => (
                <Collapse.Panel header={group.title} key={group.key}>
                  <Table
                    columns={columns}
                    components={components}
                    dataSource={mapReasonsByGroup(group.key)}
                    bordered
                    size='middle'
                    pagination={false}
                  />
                  <SeparatedRowLayout>
                    <Tooltip title={'Add reason'}>
                      <IconButton
                        icon={<PlusCircleOutlined />}
                        onClick={() => handleAdd(group.key)}
                        encodedPermissionsId={encodeIds([
                          encodedPermissionsId,
                          'CommonReasonNotesConfiguration:add',
                        ])}
                      />
                    </Tooltip>
                  </SeparatedRowLayout>
                </Collapse.Panel>
              ))}
            </Collapse>
          </GroupList>
        )}
      </Card>
    </GroupContainer>
  ) : (
    <Card>
      <ComponentHeading>
        <ConfigSectionTitle>
          Common Reason Notes Configuration
        </ConfigSectionTitle>
      </ComponentHeading>
      <ErrorMessage>{`You do not have permissions to view the common reason notes configuration for the ToEntity ${selectedToEntity.entity_code}`}</ErrorMessage>
    </Card>
  );
};

export default ETagCommonReasonNotesConfiguration;
