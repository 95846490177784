import {
  IETagColumnData,
  IETagColumnDataAgGrid,
  IETagData,
  IETagDataSet,
  IETagIdentifier,
  IETagSummaryAttributeDataSet,
  IETagSummaryAttributeRecord,
  IETagSummaryDealLinkage,
  IETagSummaryDealLinkageRecord,
  IETagSummaryProfile,
  IETagSummaryProfilesRecord,
  IHeData,
} from '../../interfaces/ETag';
import {
  IToEntitiesRecord,
  IToEntity,
  IToEntityRecord,
} from '../../interfaces/ToEntity';
import {
  TToEntityDataTableSummary,
  TToEntityDataTableSummaryDataSet,
} from '../../types/Component';
import {
  applyFiltersAndSorters,
  expandColumns,
  getColumnsForTableConfiguration,
  getColumnsForTableConfigurationAgGrid,
  retrieveETagData,
  transformColumnInformation,
  transformGridColumnInformation,
} from '../../components/organisms/ToEntityMonitor/helpers';
import { TTimeZone } from '../../types/DateTime';
import { ZonedDateTime } from '../../utils/zonedDateTime';
import { TToEntityId } from '../../types/ToEntity';
import {
  ISummaryStyleCoding,
  ITableConfiguration,
} from '../../interfaces/Summary';
import { ColDef } from 'ag-grid-community';
import {
  EColumnPosition,
  EColumnType,
  EProfileSegment,
} from '../../enums/ETag';
import {
  TETagDataArraySorter,
  TETagDataSetFilter,
  TETagRecordKey,
  TETagSummaryAttributeMap,
  TETagSummaryDealLinkageMap,
  TETagSummaryProfilesMap,
} from '../../types/ETag';
import {
  getKeyForETagDataSet,
  getRecordKeyForETagIdentifier,
} from '../../utils/eTag';
import { IIndexable } from '../../interfaces/General';
import {
  SUMMARY_PROFILE_OFF_PEAK_TOTAL_MW_KEY,
  SUMMARY_PROFILE_ON_PEAK_TOTAL_MW_KEY,
  SUMMARY_PROFILE_TOTAL_MW_KEY,
} from '../../constants/ETag';
import { HOUR_ENDING_KEY_PREFIX } from '../../components/organisms/ToEntityMonitor/constants';
import { isEmptyValue } from '../../utils/general';
import { MutableRefObject } from 'react';
import { TETagUpdateMap } from '../../components/organisms/ToEntityMonitor/types';
import { TFilterId } from '../../types/Filter';
import { retrieveSummaryCustomFilters } from '../../services/agent/tags/summary';
import { ICustomFilter } from '../../interfaces/Filter';

export const getETagSummaryAttributeCount = (
  toEntityRecord: IToEntityRecord | IToEntitiesRecord | undefined,
): number => {
  let count: number = 0;
  if (toEntityRecord?.eTagsSummaryAttributeMap) {
    Object.keys(toEntityRecord.eTagsSummaryAttributeMap).forEach(
      (recordKey: string) => {
        const attributeRecord: IETagSummaryAttributeRecord | undefined =
          toEntityRecord.eTagsSummaryAttributeMap[recordKey];

        if (attributeRecord !== undefined) {
          const eTagSummaryAttributeDataSet:
            | IETagSummaryAttributeDataSet
            | undefined = attributeRecord.eTagSummaryAttributeDataSet;

          if (eTagSummaryAttributeDataSet !== undefined) {
            count += 1;
          }
        }
      },
    );
    return count;
  }
  return 0;
};

export const getAGBottomRowData = (
  summaryDataSets: TToEntityDataTableSummaryDataSet[],
) => {
  let indexableData: { [key: string]: any } = {};
  if (summaryDataSets.length > 0) {
    [...summaryDataSets[0]].forEach((datum: TToEntityDataTableSummary) => {
      const keys = Object.keys(datum);
      if (keys.length === 1) {
        const dataTableSummary = datum[keys[0]];
        if (dataTableSummary) {
          if (dataTableSummary.isTagCount) {
            indexableData[keys[0]] = `Tag Count: ${dataTableSummary.value}`;
          } else {
            indexableData[keys[0]] = dataTableSummary.value;
          }
        }
      }
    });
  }

  return [
    {
      id: '_totals',
      data: indexableData,
    },
  ];
};

interface IGridColumnConfig {
  gridColumns: ColDef[];
  isDisplayingProfilesAgGrid: boolean;
  isDisplayingDealLinkageDataAgGrid: boolean;
}

export const getGridColumnConfig = (
  timeZone: TTimeZone | undefined,
  selectedStartDate: ZonedDateTime | null,
  selectedEndDate: ZonedDateTime | null,
  fixedProfile: boolean,
  toEntityId: TToEntityId,
  selectedTableConfiguration: ITableConfiguration | undefined,
  styleCoding?: ISummaryStyleCoding,
  addToEntityColumn?: boolean,
  toEntityConfigShowLosses?: boolean,
  currentTheme?: string,
): IGridColumnConfig => {
  let gridColumns: ColDef[] = [];
  let isDisplayingProfilesAgGrid: boolean = false;
  let isDisplayingDealLinkageDataAgGrid: boolean = false;

  if (timeZone && selectedEndDate !== null && selectedStartDate !== null) {
    const columnData: IETagColumnDataAgGrid[] =
      getColumnsForTableConfigurationAgGrid(
        selectedTableConfiguration,
        fixedProfile,
        selectedStartDate,
        selectedEndDate,
        toEntityConfigShowLosses,
      );

    if (addToEntityColumn) {
      const toEntityColumnData: IETagColumnDataAgGrid = {
        field: 'entity_code',
        headerName: 'Entity',
        minWidth: 125,
        maxWidth: 125,
        type: EColumnType.String,
        fixed: EColumnPosition.Left,
        styleIndex: 'composite_state',
      };

      columnData.push(toEntityColumnData);
    }

    gridColumns = transformGridColumnInformation(
      columnData,
      timeZone,
      toEntityId,
      styleCoding,
      currentTheme,
    );

    isDisplayingProfilesAgGrid =
      columnData.find(
        (column: IETagColumnDataAgGrid) => column.isProfileData === true,
      ) !== undefined;

    isDisplayingDealLinkageDataAgGrid =
      columnData.find(
        (column: IETagColumnDataAgGrid) => column.isDealLinkageData === true,
      ) !== undefined;
  }

  return {
    gridColumns,
    isDisplayingProfilesAgGrid,
    isDisplayingDealLinkageDataAgGrid,
  };
};

interface IColumnConfig {
  columns: IETagColumnData[];
  expandedColumns: IETagColumnData[];
  isDisplayingProfiles: boolean;
  isDisplayingDeaLinkageData: boolean;
}

export const getColumnConfig = (
  timeZone: TTimeZone | undefined,
  selectedStartDate: ZonedDateTime | null,
  selectedEndDate: ZonedDateTime | null,
  fixedProfile: boolean,
  toEntityId: TToEntityId,
  selectedTableConfiguration: ITableConfiguration | undefined,
  styleCoding?: ISummaryStyleCoding,
): IColumnConfig => {
  let columns: IETagColumnData[] = [];
  let expandedColumns: IETagColumnData[] = [];
  let isDisplayingProfiles: boolean = false;
  let isDisplayingDeaLinkageData: boolean = false;

  if (
    timeZone !== undefined &&
    selectedEndDate !== null &&
    selectedStartDate !== null
  ) {
    const columnData: IETagColumnData[] = getColumnsForTableConfiguration(
      selectedTableConfiguration,
      fixedProfile,
    );

    columns = transformColumnInformation(
      columnData,
      selectedTableConfiguration,
      timeZone,
      toEntityId,
      styleCoding,
    );

    expandedColumns = expandColumns(
      columns,
      toEntityId,
      styleCoding,
      selectedStartDate,
      selectedEndDate,
    );

    isDisplayingProfiles =
      columns.find(
        (column: IETagColumnData) => column.isProfileData === true,
      ) !== undefined;

    isDisplayingDeaLinkageData =
      columns.find(
        (column: IETagColumnData) => column.isDealLinkageData === true,
      ) !== undefined;
  }

  return {
    columns,
    expandedColumns,
    isDisplayingProfiles,
    isDisplayingDeaLinkageData,
  };
};

export const getEtagDataSets = (
  expandedColumns: IETagColumnData[],
  attributeMap: TETagSummaryAttributeMap,
  profilesMap: TETagSummaryProfilesMap,
  isDisplayingProfiles: boolean,
  dealLinkageMap: TETagSummaryDealLinkageMap,
  isDisplayingDealLinkageData: boolean,
  isDisplayingDealLinkageDataAgGrid: boolean,
  arrayFilters: Record<string, TETagDataSetFilter>,
  arraySorters: TETagDataArraySorter[],
) => {
  const eTagDataSets: IETagDataSet[] = [];
  const isFixedMap: Record<string, boolean> = {};

  expandedColumns.forEach((eTagColumnData: IETagColumnData) => {
    isFixedMap[eTagColumnData.dataIndex] = eTagColumnData.fixed !== undefined;
  });

  Object.keys(attributeMap).forEach((recordKey: TETagRecordKey) => {
    const attributeRecord: IETagSummaryAttributeRecord | undefined =
      attributeMap[recordKey];

    if (attributeRecord !== undefined) {
      const eTagSummaryAttributeDataSet:
        | IETagSummaryAttributeDataSet
        | undefined = attributeRecord.eTagSummaryAttributeDataSet;

      if (eTagSummaryAttributeDataSet !== undefined) {
        const profileRecord: IETagSummaryProfilesRecord | undefined =
          profilesMap[recordKey];

        if (
          profileRecord !== undefined &&
          profileRecord.eTagSummaryProfiles.length > 0 &&
          isDisplayingProfiles
        ) {
          const linkageRecord: IETagSummaryDealLinkageRecord | undefined =
            dealLinkageMap[recordKey];

          if (
            linkageRecord !== undefined &&
            (isDisplayingDealLinkageData || isDisplayingDealLinkageDataAgGrid)
          ) {
            profileRecord.eTagSummaryProfiles.forEach(
              (profile: IETagSummaryProfile, index: number) => {
                const linkageForDay: IETagSummaryDealLinkage | undefined =
                  linkageRecord.eTagSummaryDealLinkageDataSets.find(
                    (linkageDataSet: IETagSummaryDealLinkage) =>
                      linkageDataSet.linkageDay === profile.day,
                  );
                eTagDataSets.push({
                  ...eTagSummaryAttributeDataSet,
                  ...linkageForDay,
                  ...profile,
                  isFixedMap,
                  isPrimary: index === 0,
                });
              },
            );
          } else {
            profileRecord.eTagSummaryProfiles.forEach(
              (profile: IETagSummaryProfile, index: number) => {
                eTagDataSets.push({
                  ...eTagSummaryAttributeDataSet,
                  ...profile,
                  isFixedMap,
                  isPrimary: index === 0,
                });
              },
            );
          }
        } else {
          eTagDataSets.push({
            ...attributeRecord.eTagSummaryAttributeDataSet,
            isFixedMap,
            isPrimary: true,
          } as IETagDataSet);
        }
      }
    }
  });
  return applyFiltersAndSorters(eTagDataSets, arrayFilters, arraySorters);
};

const rowKey = (eTagDataSet: IETagDataSet): string => {
  return getKeyForETagDataSet(eTagDataSet, 'UTC');
};
export const getActionBarETagDataSets = (
  eTagDataSets: IETagDataSet[],
  rowIds: string[],
): IETagDataSet[] => {
  // get the datasets that match an id in the ag grid table
  const filteredDataSets: IETagDataSet[] = eTagDataSets.filter(
    (record: IETagDataSet) => rowIds.includes(rowKey(record)),
  );
  const resultingETagDataSets: IETagDataSet[] = [];
  // use the ids to pull out the matching dataset from the filtered set
  // to preserve the order of agGridRowIds so CSV exports in order
  rowIds.forEach((id: string) => {
    const found: IETagDataSet | undefined = filteredDataSets.find(
      (element: IETagDataSet) => rowKey(element) === id,
    );
    if (found) {
      resultingETagDataSets.push(found);
    }
  });
  return resultingETagDataSets;
};

export const getSummaryDataSets = (
  actionBarETagDataSets: IETagDataSet[],
  expandedColumns: IETagColumnData[],
  isRetrievingSummaryAttributes: boolean,
  isLoadingToEntityMonitorConfiguration: boolean,
): TToEntityDataTableSummaryDataSet[] => {
  const summaryDataSets: TToEntityDataTableSummaryDataSet[] = [];
  const currentDataSets: IETagDataSet[] = [...actionBarETagDataSets];

  if (
    !isLoadingToEntityMonitorConfiguration &&
    !isRetrievingSummaryAttributes &&
    currentDataSets.length > 0 &&
    expandedColumns.length > 0
  ) {
    const tagSet: Set<string> = new Set<string>();
    const summaryData: TToEntityDataTableSummary[] = [];
    let indexOfFirstProfileTotalColumn: number = -1;

    expandedColumns.forEach(() => {
      summaryData.push({});
    });

    currentDataSets.forEach((eTagDataSet: IETagDataSet & IIndexable) => {
      tagSet.add(getRecordKeyForETagIdentifier(eTagDataSet));

      expandedColumns.forEach(
        (eTagColumnData: IETagColumnData, index: number) => {
          const { dataIndex, isProfileData } = eTagColumnData;

          if (isProfileData === true) {
            if (
              dataIndex === SUMMARY_PROFILE_OFF_PEAK_TOTAL_MW_KEY ||
              dataIndex === SUMMARY_PROFILE_ON_PEAK_TOTAL_MW_KEY ||
              dataIndex === SUMMARY_PROFILE_TOTAL_MW_KEY
            ) {
              if (indexOfFirstProfileTotalColumn === -1) {
                indexOfFirstProfileTotalColumn = index;
              }

              const mw: number =
                eTagDataSet[dataIndex] === undefined
                  ? 0
                  : eTagDataSet[dataIndex];

              summaryData![index] =
                summaryData![index][dataIndex] === undefined
                  ? { [dataIndex]: { value: mw } }
                  : {
                      [dataIndex]: {
                        value:
                          (summaryData![index][dataIndex]!.value as number) +
                          mw,
                      },
                    };
            } else if (dataIndex.startsWith(HOUR_ENDING_KEY_PREFIX)) {
              const hourEnding: IHeData | undefined = eTagDataSet[dataIndex];
              const mw: number = isEmptyValue(hourEnding?.energy)
                ? 0
                : hourEnding!.energy!.mw;

              if (indexOfFirstProfileTotalColumn === -1) {
                indexOfFirstProfileTotalColumn = index;
              }

              summaryData![index] =
                summaryData![index][dataIndex] === undefined
                  ? { [dataIndex]: { value: mw } }
                  : {
                      [dataIndex]: {
                        value:
                          (summaryData![index][dataIndex]!.value as number) +
                          mw,
                      },
                    };
            }
          }
        },
      );
    });

    if (indexOfFirstProfileTotalColumn > -1) {
      summaryDataSets.push(summaryData);
    }

    // We need at least 1 column before the profile data columns in order to
    // display the MWs Totals label
    if (indexOfFirstProfileTotalColumn > 0) {
      const index: number = indexOfFirstProfileTotalColumn - 1;
      summaryData[index][expandedColumns[index].dataIndex] = {
        value: 'MWs Totals',
      };
    }

    // We need at least 2 columns before the profile data columns in order to
    // display the tag count in the summary otherwise we add an extra summary
    // row
    if (indexOfFirstProfileTotalColumn > 1) {
      summaryData[0][expandedColumns[0].dataIndex] = {
        isTagCount: true,
        value: tagSet.size,
      };
    } else {
      const extraSummaryData: TToEntityDataTableSummary[] = [];

      expandedColumns.forEach(() => {
        extraSummaryData.push({});
      });

      extraSummaryData[0][expandedColumns[0].dataIndex] = {
        isTagCount: true,
        value: tagSet.size,
      };

      summaryDataSets.push(extraSummaryData);
    }
  }

  return summaryDataSets;
};

export const handleUpdateTenantETagsFrom = async (
  eTagUpdateMap: TETagUpdateMap,
  toEntities: IToEntity[],
  start: ZonedDateTime,
  end: ZonedDateTime,
  timeZone: TTimeZone,
  profileSegment: EProfileSegment,
  isETagEventsPaused: MutableRefObject<boolean>,
  eTagPausedUpdateQueue: MutableRefObject<IETagData[]>,
  eTagPausedUpdateCount: MutableRefObject<number>,
  updateTenantETags: (
    toEntities: IToEntity[],
    eTagsData: IETagData[],
    timeZone: TTimeZone,
  ) => void,
  filterId?: TFilterId,
) => {
  const retrievable = Object.values(eTagUpdateMap).filter(
    (eTagIdentifier: IETagIdentifier | undefined) =>
      eTagIdentifier !== undefined,
  );

  if (retrievable.length > 0) {
    const retrieveResults: PromiseSettledResult<IETagData>[] =
      await Promise.allSettled(
        retrievable.map((eTagIdentifier: IETagIdentifier | undefined) => {
          const to_entity = (eTagIdentifier as any).to_entity;
          const entity_code = toEntities.find(
            (entity) => entity.to_entity === to_entity,
          )?.entity_code;
          return retrieveETagData(
            to_entity,
            eTagIdentifier!,
            start,
            end,
            timeZone,
            profileSegment,
            filterId,
            entity_code,
          );
        }),
      );

    const fulfilledResults = retrieveResults
      .filter(
        (result: PromiseSettledResult<IETagData>): boolean =>
          result.status === 'fulfilled',
      )
      .map(
        (result: PromiseSettledResult<IETagData>) =>
          (result as PromiseFulfilledResult<IETagData>).value,
      );

    if (fulfilledResults.length > 0) {
      if (isETagEventsPaused.current) {
        eTagPausedUpdateQueue.current =
          eTagPausedUpdateQueue.current.concat(fulfilledResults);
        eTagPausedUpdateCount.current += eTagPausedUpdateQueue.current.length;
      } else {
        updateTenantETags(toEntities, fulfilledResults, timeZone);
      }
    }
  }
};

export const handleUpdateToEntityETagsFrom = async (
  eTagUpdateMap: TETagUpdateMap,
  toEntity: IToEntity,
  start: ZonedDateTime,
  end: ZonedDateTime,
  timeZone: TTimeZone,
  profileSegment: EProfileSegment,
  isETagEventsPaused: MutableRefObject<boolean>,
  eTagPausedUpdateQueue: MutableRefObject<IETagData[]>,
  eTagPausedUpdateCount: MutableRefObject<number>,
  updateToEntityETags: (
    toEntity: IToEntity,
    eTagsData: IETagData[],
    timeZone: TTimeZone,
  ) => void,
  filterId?: TFilterId,
) => {
  const retrievable = Object.values(eTagUpdateMap).filter(
    (eTagIdentifier: IETagIdentifier | undefined) =>
      eTagIdentifier !== undefined,
  );

  if (retrievable.length > 0) {
    const retrieveResults: PromiseSettledResult<IETagData>[] =
      await Promise.allSettled(
        retrievable.map((eTagIdentifier: IETagIdentifier | undefined) =>
          retrieveETagData(
            toEntity.to_entity,
            eTagIdentifier!,
            start,
            end,
            timeZone,
            profileSegment,
            filterId,
          ),
        ),
      );

    const fulfilledResults = retrieveResults
      .filter(
        (result: PromiseSettledResult<IETagData>): boolean =>
          result.status === 'fulfilled',
      )
      .map(
        (result: PromiseSettledResult<IETagData>) =>
          (result as PromiseFulfilledResult<IETagData>).value,
      );

    if (fulfilledResults.length > 0) {
      if (isETagEventsPaused.current) {
        eTagPausedUpdateQueue.current =
          eTagPausedUpdateQueue.current.concat(fulfilledResults);
        eTagPausedUpdateCount.current += eTagPausedUpdateQueue.current.length;
      } else {
        updateToEntityETags(toEntity, fulfilledResults, timeZone);
      }
    }
  }
};

export const getCustomFilterOptionsMap = async (toEntities: IToEntity[]) => {
  let customFilterOptions: any[] = [];
  const map = new Map<string, Promise<any>>();
  toEntities.forEach((entity) => {
    map.set(entity.to_entity, retrieveSummaryCustomFilters(entity.to_entity));
  });

  const customFilterOptionsMap = new Map<string, any[]>();
  for (const entity of toEntities) {
    const retrieveSummaryCustomFiltersResponse =
      await retrieveSummaryCustomFilters(entity.to_entity);
    const retrieveSummaryCustomFiltersMappedResponse =
      retrieveSummaryCustomFiltersResponse.data.response.map(
        (customFilter: ICustomFilter) => {
          return { ...customFilter, to_entity: entity.to_entity };
        },
      );

    retrieveSummaryCustomFiltersMappedResponse.forEach((res: any) => {
      customFilterOptions.push({
        label: (res && res.filter_name) || '',
        value: res,
      });
    });

    customFilterOptionsMap.set(
      entity.to_entity,
      retrieveSummaryCustomFiltersMappedResponse,
    );
  }

  return { customFilterOptionsMap, customFilterOptions };

  /*
  map.forEach((value: Promise<any>, key: string, map) => {
    value
      .then((result) => {
        const response = result.data.response.map((data: any[]) => ({
          ...data,
          to_entity: key,
        }));
        /!*response.forEach((res: any) => {
            filterResponseData.push({
              label: (res && res.filter_name) || '',
              value: res,
            });
          });*!/
        responseMap.set(key, response);
      })
      .finally(() => {
        return responseMap;
      });
  });
*/
};
