import { MARKET_INFO_PRICES_DATA_GRID_CELL_PRICE_KEY } from 'components/organisms/MarketInformation/MarketInfoPrices/constants';
import { ID_KEY } from 'constants/General';
import { DATA_GRID_CELL_CONTAINER } from 'constants/styles';
import { ESelectedCellType } from 'enums/Component';
import { EMarketInfoPricesDataGridCellType } from 'enums/Detail';
import {
  IContainerSelectable,
  IDataGridCellProps,
  IDataGridSelectionContext,
} from 'interfaces/Component';
import {
  IMarketInfoPricesDataGridCell,
  IMarketInfoPricesRowCellData,
} from 'interfaces/Detail';
import { IIndexable } from 'interfaces/General';
import { Context, useCallback, useContext, useMemo } from 'react';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import styled from 'styled-components';
import { TMarketInfoPricesDataGridRow } from 'types/Detail';
import {
  getInitialMarketInfoPricesRowForId,
  getMarketInfoPricesRowCellData,
} from 'utils/detail';
import {
  getHighlightedStyle,
  getSelectedStyle,
  inputValueChanged,
} from 'utils/styles';

interface IContainerProps extends IContainerSelectable {}

const Container = styled.div<IContainerProps>`
  ${DATA_GRID_CELL_CONTAINER}

  ${(props) => inputValueChanged(props)}

  ${(props) => getHighlightedStyle(props)}

  ${(props) => getSelectedStyle(props)}
`;

export interface IMarketInfoPricesDataGridCellReviewProps
  extends IDataGridCellProps<TMarketInfoPricesDataGridRow, IIndexable> {
  DataGridSelectionContext: Context<IDataGridSelectionContext>;
  initialDataSet?: TMarketInfoPricesDataGridRow[];
  isUnconstrained?: boolean;
}

const MarketInfoPricesDataGridCellReview = ({
  column: { idx, key },
  DataGridSelectionContext,
  initialDataSet,
  isUnconstrained = false,
  onMouseDown,
  onMouseOver,
  onMouseUp,
  row,
  rowIdx,
}: IMarketInfoPricesDataGridCellReviewProps): JSX.Element => {
  const { dataGridHasFocus, selectedCells, selectedPrimaryCell } =
    useContext<IDataGridSelectionContext>(DataGridSelectionContext);
  const { currentTheme } = useThemeSwitcher();

  const handleMouseDown = useCallback(() => {
    onMouseDown?.({
      idx,
      rowIdx,
    });
  }, [idx, onMouseDown, rowIdx]);

  const handleMouseOver = useCallback(() => {
    onMouseOver?.({
      idx,
      rowIdx,
    });
  }, [idx, onMouseOver, rowIdx]);

  const handleMouseUp = useCallback(() => {
    onMouseUp?.({
      idx,
      rowIdx,
    });
  }, [idx, onMouseUp, rowIdx]);

  const { cellType: initialCellType, cellValue: initialCellValue } =
    useMemo(() => {
      const rowId: IMarketInfoPricesDataGridCell | null | undefined =
        row[ID_KEY];

      if (rowId === undefined) {
        throw new Error(`Missing key: id for row: ${JSON.stringify(row)}`);
      }

      const initialRow: TMarketInfoPricesDataGridRow | undefined =
        rowId === null
          ? undefined
          : getInitialMarketInfoPricesRowForId(
              rowId.value as string,
              initialDataSet,
            );
      const initialCell: IMarketInfoPricesDataGridCell | null | undefined =
        initialRow === undefined ? null : initialRow[key];

      return getMarketInfoPricesRowCellData(
        initialCell === undefined ? null : initialCell,
        isUnconstrained,
      );
    }, [key, initialDataSet, isUnconstrained, row]);

  const { cellType, cellValue } = useMemo((): IMarketInfoPricesRowCellData => {
    const cell: IMarketInfoPricesDataGridCell | null | undefined = row[key];

    if (cell === undefined) {
      throw new Error(`Invalid key: ${key} for row: ${JSON.stringify(row)}`);
    }

    return getMarketInfoPricesRowCellData(cell, isUnconstrained);
  }, [isUnconstrained, key, row]);

  const selectedCellType: ESelectedCellType = useMemo(() => {
    const isPrimaryCell: boolean =
      selectedPrimaryCell !== undefined &&
      selectedPrimaryCell.idx === idx &&
      selectedPrimaryCell.rowIdx === rowIdx;
    const isSelectedCell: boolean =
      selectedCells[idx] !== undefined &&
      selectedCells[idx][rowIdx] !== undefined &&
      selectedCells[idx][rowIdx] === true;

    if (isPrimaryCell) {
      return ESelectedCellType.Primary;
    }

    if (isSelectedCell) {
      return ESelectedCellType.Selected;
    }

    return ESelectedCellType.None;
  }, [idx, rowIdx, selectedCells, selectedPrimaryCell]);

  const valueChanged: boolean = useMemo(
    (): boolean =>
      cellType !== initialCellType || cellValue !== initialCellValue,
    [cellType, cellValue, initialCellType, initialCellValue],
  );

  const adjustedCellValue: string | number = useMemo(
    (): string | number =>
      key === MARKET_INFO_PRICES_DATA_GRID_CELL_PRICE_KEY &&
      cellType === EMarketInfoPricesDataGridCellType.Number
        ? (cellValue as number).toFixed(2)
        : cellValue,
    [cellType, cellValue, key],
  );

  return (
    <Container
      currentTheme={currentTheme!}
      hasFocus={dataGridHasFocus}
      onMouseDown={handleMouseDown}
      onMouseOver={handleMouseOver}
      onMouseUp={handleMouseUp}
      selectedCellType={selectedCellType}
      valueChanged={valueChanged}
    >
      {adjustedCellValue}
    </Container>
  );
};

export default MarketInfoPricesDataGridCellReview;
