import EditDataTable, {
  IEditDataTableProps,
} from 'components/molecules/EditDataTable/EditDataTable';
import {
  EDIT_TRANSMISSION_ALLOCATION_MISC_INFO_PRIMARY_KEY,
  INITIAL_RECORD_ID,
} from 'constants/Detail';
import {
  BUTTON_ICON_DIMENSION,
  BUTTON_ICON_DIMENSION_VALUE,
  DETAIL_POPOVER_DATA_TABLE_MAXIMUM_HEIGHT,
  STANDARD_SPACING,
  VIEW_DATA_TABLE_COLUMN_NITS_RESOURCE_INPUT_COLUMN_WIDTH,
  VIEW_DATA_TABLE_COLUMN_OASIS_INFO_EDIT_COLUMN_WIDTH,
  VIEW_DATA_TABLE_COLUMN_OASIS_INPUT_COLUMN_WIDTH,
  VIEW_DATA_TABLE_COLUMN_PID_SELECT_COLUMN_WIDTH,
  VIEW_DATA_TABLE_COLUMN_PRODUCT_SELECT_COLUMN_WIDTH,
  VIEW_DATA_TABLE_COLUMN_PSE_SELECT_COLUMN_WIDTH,
} from 'constants/styles';
import { EDistributedTagItem } from 'enums/ETag';
import {
  EMiscInfosExpandedColumn,
  ERegistryType,
  ERetreiveState,
  EUpdateState,
} from 'enums/General';
import { EViewMode } from 'enums/View';
import useExpandableDataTable from 'hooks/useExpandableDataTable';
import usePrevious from 'hooks/usePrevious';
import ExpandedMiscInfoEditable from 'hooks/useTransmissionEditColumns/OasisInfoEdit/ExpandedMiscInfoEditable';
import { editOasisInfoToDetailState } from 'hooks/useTransmissionEditColumns/OasisInfoEdit/helpers';
import { IEditOasisInfo } from 'hooks/useTransmissionEditColumns/OasisInfoEdit/types';
import { IOption, IValueChanged } from 'interfaces/Component';
import { IEntityInfo, IRegistryEntity } from 'interfaces/Entity';
import { IETagTransmissionAllocation } from 'interfaces/ETag';
import { IMiscInfo } from 'interfaces/General';
import { IInputColumnConfig } from 'interfaces/View';
import {
  ChangeEvent,
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { detailEditETagDetail } from 'reduxes/Detail/actions';
import styled from 'styled-components';
import { TRootState } from 'types/Redux';
import {
  getEditInfoKey,
  getInitialTransmissionAllocation,
  getSplitEditInfoKey,
  getTransmissionAllocationKey,
  transmissionAllocationByIdSorter,
  transmissionAllocationSorter,
} from 'utils/detail';
import { registryEntityToEntityInfoOption } from 'utils/entity';
import { eventToStringOrNull } from 'utils/general';
import { inputValueChanged } from 'utils/styles';
import { useOasisInfoEditColumns } from 'utils/views';

const ADD_BUTTON_OFFSET = {
  left: `calc(-1 * (6px + ${VIEW_DATA_TABLE_COLUMN_PID_SELECT_COLUMN_WIDTH} + ${VIEW_DATA_TABLE_COLUMN_OASIS_INPUT_COLUMN_WIDTH} + ${VIEW_DATA_TABLE_COLUMN_PRODUCT_SELECT_COLUMN_WIDTH} + ${VIEW_DATA_TABLE_COLUMN_PSE_SELECT_COLUMN_WIDTH} + ${VIEW_DATA_TABLE_COLUMN_NITS_RESOURCE_INPUT_COLUMN_WIDTH}))`,
  top: 'calc(100% - 2px)',
};

const REMOVE_BUTTON_OFFSET = {
  right: '0',
  top: `calc(50% - ${BUTTON_ICON_DIMENSION_VALUE / 2}px)`,
};

const REMOVE_BUTTON_OFFSET_FOR_CORRECTIONS = {
  right: '-12px',
  top: `calc(50% - ${BUTTON_ICON_DIMENSION_VALUE / 2}px)`,
};

const ExpandedRowLayout = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-bottom: ${STANDARD_SPACING};
`;

const EDIT_BUTTON_WIDTH = `calc(${VIEW_DATA_TABLE_COLUMN_OASIS_INFO_EDIT_COLUMN_WIDTH} - ${STANDARD_SPACING})`;
const Wrapper = styled.div<IValueChanged>`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: calc(${EDIT_BUTTON_WIDTH} - ${STANDARD_SPACING});

  ${(props) => inputValueChanged(props)}
`;

const entityToPseOption = registryEntityToEntityInfoOption(true, false);

interface IOasisInfoEditProps {
  isDisabled?: boolean;
  isUnconstrained?: boolean;
  physicalSegmentId?: number | null;
}

const retrieveLossMethodsEditState = (state: TRootState) => {
  const {
    allTransmissionAllocations,
    focusKey,
    isDetailDeleted,
    registryEntities,
    retrievingDistributedTagItems,
    transmission_physical_segments,
    transmissionAllocations,
    updatingDetail,
    viewMode,
  } = state.detail.present;
  const isDetailUpdating: boolean =
    updatingDetail !== EUpdateState.NotUpdating &&
    updatingDetail !== EUpdateState.UpdateCompleted;
  const isTransmissionAllocationsLoading: boolean =
    retrievingDistributedTagItems[EDistributedTagItem.TransmissionAllocations]
      .retrieveState !== ERetreiveState.NotRetrieving &&
    retrievingDistributedTagItems[EDistributedTagItem.TransmissionAllocations]
      .retrieveState !== ERetreiveState.RetrievingCompleted;

  return {
    allTransmissionAllocations,
    focusKey,
    isDetailDeleted,
    isDetailUpdating,
    isTransmissionAllocationsLoading,
    registryEntities,
    transmission_physical_segments,
    transmissionAllocations,
    viewMode,
  };
};

const OasisInfoEdit = ({
  isDisabled,
  isUnconstrained = false,
  physicalSegmentId,
}: IOasisInfoEditProps): JSX.Element => {
  const dispatch = useDispatch();
  const {
    allTransmissionAllocations,
    focusKey,
    isDetailDeleted,
    isDetailUpdating,
    isTransmissionAllocationsLoading,
    registryEntities,
    transmission_physical_segments,
    transmissionAllocations,
    viewMode,
  } = useSelector(retrieveLossMethodsEditState);
  const [pidOptions, setPidOptions] = useState<IOption<number>[]>([]);
  const previousTransmissionAllocations: MutableRefObject<
    IETagTransmissionAllocation[] | null
  > = useRef<IETagTransmissionAllocation[]>(transmissionAllocations);
  const previousIsDetailDeleted: boolean | undefined =
    usePrevious(isDetailDeleted);
  const previousIsDetailUpdating: boolean | undefined =
    usePrevious(isDetailUpdating);
  const previousIsTransmissionAllocationsLoading: boolean | undefined =
    usePrevious(isTransmissionAllocationsLoading);
  const isDetailDisabled: boolean = isDetailDeleted || isDetailUpdating;
  const hasDetailChanged: boolean =
    isDetailDeleted !== previousIsDetailDeleted ||
    isDetailUpdating !== previousIsDetailUpdating;

  const initialTransmissionAllocations: IETagTransmissionAllocation[] | null =
    useMemo(() => {
      if (
        (isDetailUpdating === false && previousIsDetailUpdating === true) ||
        (isTransmissionAllocationsLoading === false &&
          previousIsTransmissionAllocationsLoading === true)
      ) {
        previousTransmissionAllocations.current = transmissionAllocations;
      }

      return previousTransmissionAllocations.current;
    }, [
      isDetailUpdating,
      isTransmissionAllocationsLoading,
      previousIsDetailUpdating,
      previousIsTransmissionAllocationsLoading,
      previousTransmissionAllocations,
      transmissionAllocations,
    ]);

  const adjustedTransmissionAllocations: IETagTransmissionAllocation[] =
    useMemo(
      () =>
        transmissionAllocations === null
          ? []
          : [...transmissionAllocations].sort(
              (
                a: IETagTransmissionAllocation,
                b: IETagTransmissionAllocation,
              ): number => a.trans_alloc_id - b.trans_alloc_id,
            ),
      [transmissionAllocations],
    );

  const getInitialTransmissionAllocationForId = useCallback(
    (trans_alloc_id: number): IETagTransmissionAllocation | undefined =>
      initialTransmissionAllocations === undefined ||
      initialTransmissionAllocations === null
        ? undefined
        : initialTransmissionAllocations.find(
            (transmissionAllocation: IETagTransmissionAllocation): boolean =>
              transmissionAllocation.trans_alloc_id === trans_alloc_id,
          ),
    [initialTransmissionAllocations],
  );

  const initialTransAllocIds: number[] = useMemo(
    () =>
      initialTransmissionAllocations === null
        ? []
        : initialTransmissionAllocations.map(
            (transmissionAllocation: IETagTransmissionAllocation): number =>
              transmissionAllocation.trans_alloc_id,
          ),
    [initialTransmissionAllocations],
  );

  const maxTransmissionId: number = useMemo(() => {
    let maxId: number = 0;
    allTransmissionAllocations?.forEach(
      (transAlloc: IETagTransmissionAllocation) => {
        if (maxId < transAlloc.trans_alloc_id) {
          maxId = transAlloc.trans_alloc_id;
        }
      },
    );

    transmissionAllocations?.forEach(
      (transAlloc: IETagTransmissionAllocation) => {
        if (maxId < transAlloc.trans_alloc_id) {
          maxId = transAlloc.trans_alloc_id;
        }
      },
    );
    return maxId;
  }, [allTransmissionAllocations, transmissionAllocations]);

  useEffect(() => {
    if (transmission_physical_segments === null) {
      if (pidOptions.length > 0) {
        setPidOptions([]);
      }
    } else if (pidOptions.length !== transmission_physical_segments.length) {
      const newPidOptions: IOption<number>[] = [];

      for (
        let i: number = 0;
        i < transmission_physical_segments.length;
        i += 1
      ) {
        // Transmission physical segment ids start from 2
        newPidOptions.push({ label: `${i + 2}`, value: i + 2 });
      }

      setPidOptions(newPidOptions);
    }
  }, [pidOptions, transmission_physical_segments]);

  const pseOptions: IOption<IEntityInfo>[] = useMemo(
    () =>
      registryEntities
        .filter(
          (registryEntity: IRegistryEntity): boolean =>
            registryEntity['Registry Type'] === ERegistryType.PSE,
        )
        .map(entityToPseOption),
    [registryEntities],
  );

  const handleChange = useCallback(
    (editOasisInfo: IEditOasisInfo) =>
      dispatch(
        detailEditETagDetail({
          isDetailEdited: true,
          stateTransform: editOasisInfoToDetailState(editOasisInfo),
        }),
      ),
    [dispatch],
  );

  const initialiser = useCallback(
    () => getInitialTransmissionAllocation(INITIAL_RECORD_ID),
    [],
  );

  const addedTranmissionAllocations: IETagTransmissionAllocation[] =
    useMemo(() => {
      if (!transmissionAllocations) {
        return [];
      }
      return transmissionAllocations.filter(
        (transAlloc) =>
          initialTransAllocIds.find(
            (id) => id === transAlloc.trans_alloc_id,
          ) === undefined,
      );
    }, [initialTransAllocIds, transmissionAllocations]);

  const handleAddTransmissionAllocation = useCallback(
    (value: unknown, record: IETagTransmissionAllocation) => {
      // For a profile change, we want to first add after maxTransmissionId
      let addAfterId = maxTransmissionId;
      if (physicalSegmentId) {
        // To find the right place to add the next one, it needs to be before the first new transAlloc
        // with a greater segment to keep transAllocs in order during the profile change process
        addedTranmissionAllocations.forEach((addedAlloc) => {
          if (
            addedAlloc.physical_segment_ref &&
            addedAlloc.physical_segment_ref > physicalSegmentId
          ) {
            addAfterId = addedAlloc.trans_alloc_id - 1;
          }
        });
      }
      handleChange({
        addAfterTransAllocId:
          viewMode === EViewMode.EditETagDraft ||
          viewMode === EViewMode.EditETagTemplate ||
          viewMode === EViewMode.ReviewETagDraft ||
          viewMode === EViewMode.ReviewETagTemplate
            ? record.trans_alloc_id
            : addAfterId,
        withPhysicalSegmentRef:
          physicalSegmentId === null || physicalSegmentId === undefined
            ? undefined
            : physicalSegmentId,
      });
    },
    [
      addedTranmissionAllocations,
      handleChange,
      maxTransmissionId,
      physicalSegmentId,
      viewMode,
    ],
  );

  const handleRemoveTransmissionAllocation = useCallback(
    (record: IETagTransmissionAllocation) => {
      handleChange({
        removeTransAllocId: record.trans_alloc_id,
      });
    },
    [handleChange],
  );

  const handleAddMiscInfo = useCallback(
    (value: unknown, record: IMiscInfo) => {
      const { label: transmissionAllocationKey } = getSplitEditInfoKey(
        record.key,
      );
      const { primaryId: trans_alloc_id } = getSplitEditInfoKey(
        transmissionAllocationKey,
      );

      handleChange({
        editTransmissionAllocationMap: {
          [trans_alloc_id]: {
            editMiscInfos: {
              addAfterMiscInfo: record.key,
            },
          },
        },
      });
    },
    [handleChange],
  );

  const handleRemoveMiscInfo = useCallback(
    (record: IMiscInfo) => {
      const { label: transmissionAllocationKey } = getSplitEditInfoKey(
        record.key,
      );
      const { primaryId: trans_alloc_id } = getSplitEditInfoKey(
        transmissionAllocationKey,
      );

      handleChange({
        editTransmissionAllocationMap: {
          [trans_alloc_id]: {
            editMiscInfos: {
              removeMiscInfo: record.key,
            },
          },
        },
      });
    },
    [handleChange],
  );

  const getInitialTokenInputValue = useCallback(
    (miscInfoRecord: IMiscInfo): string | null => {
      const { editIndex, label: transmissionAllocationEditKey } =
        getSplitEditInfoKey(miscInfoRecord.key);
      const { primaryId: trans_alloc_id } = getSplitEditInfoKey(
        transmissionAllocationEditKey,
      );
      const initialTransmissionAllocation:
        | IETagTransmissionAllocation
        | undefined = getInitialTransmissionAllocationForId(trans_alloc_id);

      if (
        initialTransmissionAllocation === undefined ||
        initialTransmissionAllocation.misc_infos === null
      ) {
        return null;
      }

      const initialMiscInfo: IMiscInfo | undefined =
        initialTransmissionAllocation.misc_infos[editIndex];

      return initialMiscInfo === undefined ? null : initialMiscInfo.token;
    },
    [getInitialTransmissionAllocationForId],
  );

  const getInitialValueInputValue = useCallback(
    (miscInfoRecord: IMiscInfo): string | null => {
      const { editIndex, label: transmissionAllocationEditKey } =
        getSplitEditInfoKey(miscInfoRecord.key);
      const { primaryId: trans_alloc_id } = getSplitEditInfoKey(
        transmissionAllocationEditKey,
      );
      const initialTransmissionAllocation:
        | IETagTransmissionAllocation
        | undefined = getInitialTransmissionAllocationForId(trans_alloc_id);

      if (
        initialTransmissionAllocation === undefined ||
        initialTransmissionAllocation.misc_infos === null
      ) {
        return null;
      }

      const initialMiscInfo: IMiscInfo | undefined =
        initialTransmissionAllocation.misc_infos[editIndex];

      return initialMiscInfo === undefined ? null : initialMiscInfo.value;
    },
    [getInitialTransmissionAllocationForId],
  );

  const tokenInputColumnConfig: IInputColumnConfig<IMiscInfo> = useMemo(
    () => ({
      getInitialValue: getInitialTokenInputValue,
      getKey: (record: IMiscInfo) => record.key,
      isDisabled: isDetailDisabled,
      onChange: (event: ChangeEvent<HTMLInputElement>, record: IMiscInfo) => {
        const { label: transmissionAllocationKey } = getSplitEditInfoKey(
          record.key,
        );
        const { primaryId: trans_alloc_id } = getSplitEditInfoKey(
          transmissionAllocationKey,
        );

        handleChange({
          editTransmissionAllocationMap: {
            [trans_alloc_id]: {
              editMiscInfos: {
                editMiscInfoMap: {
                  [record.key]: {
                    token: eventToStringOrNull(event),
                  },
                },
              },
            },
          },
        });
      },
      shouldFocusOn: (record: IMiscInfo): boolean => record.key === focusKey,
    }),
    [focusKey, getInitialTokenInputValue, handleChange, isDetailDisabled],
  );

  const valueInputColumnConfig: IInputColumnConfig<IMiscInfo> = useMemo(
    () => ({
      getInitialValue: getInitialValueInputValue,
      getKey: (record: IMiscInfo) => record.key,
      isDisabled: isDetailDisabled,
      onChange: (event: ChangeEvent<HTMLInputElement>, record: IMiscInfo) => {
        const { label: transmissionAllocationKey } = getSplitEditInfoKey(
          record.key,
        );
        const { primaryId: trans_alloc_id } = getSplitEditInfoKey(
          transmissionAllocationKey,
        );

        handleChange({
          editTransmissionAllocationMap: {
            [trans_alloc_id]: {
              editMiscInfos: {
                editMiscInfoMap: {
                  [record.key]: {
                    value: eventToStringOrNull(event),
                  },
                },
              },
            },
          },
        });
      },
    }),
    [getInitialValueInputValue, handleChange, isDetailDisabled],
  );

  const getExpandedRowRender = useCallback(
    (
        getRowExpandedKey: (
          record: IETagTransmissionAllocation,
        ) => EMiscInfosExpandedColumn | undefined,
      ) =>
      (record: IETagTransmissionAllocation): JSX.Element => {
        const miscInfosExpandedColumn: EMiscInfosExpandedColumn | undefined =
          getRowExpandedKey(record);

        if (miscInfosExpandedColumn !== undefined) {
          if (miscInfosExpandedColumn === EMiscInfosExpandedColumn.MiscInfos) {
            return (
              <ExpandedRowLayout>
                <ExpandedMiscInfoEditable
                  hasChanged={hasDetailChanged}
                  initialEditKey={getEditInfoKey(
                    getTransmissionAllocationKey(record),
                    EDIT_TRANSMISSION_ALLOCATION_MISC_INFO_PRIMARY_KEY,
                    0,
                  )}
                  isDisabled={isDisabled}
                  onAdd={handleAddMiscInfo}
                  onRemove={handleRemoveMiscInfo}
                  tokenInputColumnConfig={tokenInputColumnConfig}
                  transmissionAllocation={record}
                  valueInputColumnConfig={valueInputColumnConfig}
                />
              </ExpandedRowLayout>
            );
          }
        }

        return <></>;
      },
    [
      handleAddMiscInfo,
      handleRemoveMiscInfo,
      hasDetailChanged,
      isDisabled,
      tokenInputColumnConfig,
      valueInputColumnConfig,
    ],
  );

  const getRowKey = useCallback(
    (record: IETagTransmissionAllocation): string =>
      record.trans_alloc_id.toString(),
    [],
  );

  const { expandableConfig, updateRowExpandedKey } = useExpandableDataTable<
    IETagTransmissionAllocation,
    EMiscInfosExpandedColumn
  >(EMiscInfosExpandedColumn.None, getExpandedRowRender, getRowKey, true);

  const handleClickExpand = useCallback(
    (
      miscInfosExpandedColumn: EMiscInfosExpandedColumn,
      record: IETagTransmissionAllocation,
    ) => {
      updateRowExpandedKey(miscInfosExpandedColumn, record);
    },
    [updateRowExpandedKey],
  );

  const oasisInfoColumns = useOasisInfoEditColumns(
    getInitialTransmissionAllocationForId,
    initialTransmissionAllocations,
    initialTransAllocIds,
    isDetailDeleted,
    isDetailUpdating,
    isUnconstrained,
    handleChange,
    handleClickExpand,
    pidOptions,
    previousIsDetailDeleted,
    previousIsDetailUpdating,
    pseOptions,
    adjustedTransmissionAllocations,
    viewMode,
  );

  const shouldFloatOverUpdate = useCallback(
    (
      props: IEditDataTableProps<IETagTransmissionAllocation>,
      previousProps:
        | IEditDataTableProps<IETagTransmissionAllocation>
        | undefined,
    ): boolean =>
      previousProps === undefined ||
      props.columns !== previousProps.columns ||
      props.data !== previousProps.data ||
      props.expandable !== previousProps.expandable,
    [],
  );

  const filteredTransAllocs: IETagTransmissionAllocation[] =
    useMemo((): IETagTransmissionAllocation[] => {
      if (physicalSegmentId === undefined) {
        return adjustedTransmissionAllocations.sort(
          transmissionAllocationByIdSorter,
        );
      }

      const onlyMatchingTransmissionAllocations: IETagTransmissionAllocation[] =
        adjustedTransmissionAllocations.filter(
          (transAlloc: IETagTransmissionAllocation) =>
            transAlloc.physical_segment_ref === physicalSegmentId ||
            transAlloc.physical_segment_ref === null,
        );

      return onlyMatchingTransmissionAllocations;
    }, [adjustedTransmissionAllocations, physicalSegmentId]);

  const initialFilteredTransAllocs: IETagTransmissionAllocation[] =
    useMemo(() => {
      return filteredTransAllocs.filter(
        (filteredTransAlloc: IETagTransmissionAllocation) =>
          initialTransAllocIds.find(
            (transAllocId: number) =>
              transAllocId === filteredTransAlloc.trans_alloc_id,
          ) !== undefined,
      );
    }, [filteredTransAllocs, initialTransAllocIds]);

  const allowRowAdd = useCallback(
    (
      value: unknown,
      record: IETagTransmissionAllocation,
      data: IETagTransmissionAllocation[],
      index: number,
    ): boolean =>
      adjustedTransmissionAllocations.findIndex(
        (transAlloc: IETagTransmissionAllocation) =>
          transAlloc.trans_alloc_id === record.trans_alloc_id,
      ) >=
        initialTransAllocIds.length - 1 ||
      // We also need to be able to add if it's the last item in the filtered grid
      // or was added within this workflow
      record.trans_alloc_id >=
        initialFilteredTransAllocs[initialFilteredTransAllocs.length - 1]
          .trans_alloc_id,
    [
      adjustedTransmissionAllocations,
      initialFilteredTransAllocs,
      initialTransAllocIds.length,
    ],
  );

  const allowRowRemove = useCallback(
    (
      value: unknown,
      record: IETagTransmissionAllocation,
      data: IETagTransmissionAllocation[],
      index: number,
    ): boolean =>
      adjustedTransmissionAllocations.findIndex(
        (transAlloc: IETagTransmissionAllocation) =>
          transAlloc.trans_alloc_id === record.trans_alloc_id,
      ) >
        initialTransAllocIds.length - 1 ||
      // We also need to be able to remove if it was added within this workflow
      record.trans_alloc_id >
        initialFilteredTransAllocs[initialFilteredTransAllocs.length - 1]
          .trans_alloc_id,
    [
      adjustedTransmissionAllocations,
      initialFilteredTransAllocs,
      initialTransAllocIds.length,
    ],
  );

  useEffect(() => {
    // If no allocs for this segment
    if (filteredTransAllocs.length === 0 && physicalSegmentId) {
      let addAfterId: number;
      // Look at all allocs before this one
      if (physicalSegmentId === 1 || !transmissionAllocations) {
        addAfterId = 0;
      } else {
        const previousSegmentAllocations: IETagTransmissionAllocation[] =
          transmissionAllocations
            .filter(
              (alloc: IETagTransmissionAllocation) =>
                alloc.physical_segment_ref &&
                alloc.physical_segment_ref <= physicalSegmentId - 1,
            )
            .sort(transmissionAllocationSorter);
        // If there are no allocations before this segment, this allocation should be first
        if (previousSegmentAllocations.length === 0) {
          addAfterId = 0;
          // If there are allocations before this segment, add this allocation after the latest ID prior to this segment
        } else {
          addAfterId =
            previousSegmentAllocations[previousSegmentAllocations.length - 1]
              .trans_alloc_id;
        }
      }

      handleChange({
        addAfterTransAllocId: addAfterId,
        withPhysicalSegmentRef:
          physicalSegmentId === null || physicalSegmentId === undefined
            ? undefined
            : physicalSegmentId,
      });
    }
  }, [
    filteredTransAllocs,
    handleChange,
    physicalSegmentId,
    transmissionAllocations,
  ]);

  const dataString: string = filteredTransAllocs
    .map(
      (transAlloc: IETagTransmissionAllocation) =>
        // ContractName (#-X)
        `${transAlloc.contract_number || ''}${
          transAlloc.trans_product_ref?.product_name
            ? ` (${transAlloc.trans_product_ref?.product_name})`
            : ''
        }`,
    )
    .join(', ');

  return (
    <EditDataTable<IETagTransmissionAllocation>
      addButtonOffset={ADD_BUTTON_OFFSET}
      allowRowAdd={
        viewMode === EViewMode.EditETagAdjustment ||
        viewMode === EViewMode.EditETagAdjustmentWithATF ||
        viewMode === EViewMode.EditETagCorrection
          ? viewMode === EViewMode.EditETagCorrection
            ? () => true
            : allowRowAdd
          : undefined
      }
      allowRowRemove={
        viewMode === EViewMode.EditETagAdjustment ||
        viewMode === EViewMode.EditETagAdjustmentWithATF ||
        viewMode === EViewMode.EditETagCorrection
          ? viewMode === EViewMode.EditETagCorrection
            ? () => true
            : allowRowRemove
          : undefined
      }
      columns={oasisInfoColumns}
      data={filteredTransAllocs}
      editButtonWidth={
        physicalSegmentId ? EDIT_BUTTON_WIDTH : BUTTON_ICON_DIMENSION
      }
      expandable={expandableConfig}
      hideExpandIconColumn={true}
      id={`oasisInfo-${physicalSegmentId}`}
      initialiser={initialiser}
      isDisabled={isDisabled}
      label={
        physicalSegmentId === null || physicalSegmentId === undefined
          ? 'OASIS Info'
          : undefined
      }
      indicatorContent={
        physicalSegmentId !== undefined && physicalSegmentId !== null ? (
          <Wrapper>{dataString}</Wrapper>
        ) : undefined
      }
      maximumAllowableAdds={-1}
      maximumHeight={DETAIL_POPOVER_DATA_TABLE_MAXIMUM_HEIGHT}
      onAdd={handleAddTransmissionAllocation}
      onRemove={handleRemoveTransmissionAllocation}
      pagination={false}
      removeButtonOffset={
        viewMode === EViewMode.EditETagCorrection
          ? REMOVE_BUTTON_OFFSET_FOR_CORRECTIONS
          : REMOVE_BUTTON_OFFSET
      }
      rowKey='trans_alloc_id'
      shouldFloatOverUpdate={shouldFloatOverUpdate}
      showDataCount={
        physicalSegmentId !== undefined && physicalSegmentId !== null
      }
      tableTitle={`OASIS Information Details${
        physicalSegmentId ? ' ' + physicalSegmentId : ''
      }`}
      tooltipTitle={dataString}
    />
  );
};

export default OasisInfoEdit;
