import IconButton from 'components/atoms/IconButton/IconButton';
import { LOSS_TAG_IDS_WIDTH } from 'constants/styles';
import { EDetailLossMethodsExpandedColumn } from 'enums/Detail';
import { getColumnLossMethodsIdColumnConfig } from 'hooks/useTransmissionEditColumns/LossMethodsEdit/useLossMethodsEditColumns';
import { IViewDataTableColumn } from 'interfaces/View';
import { useCallback } from 'react';
import { IDetailLossAccounting } from 'reduxes/Detail/types';
import styled from 'styled-components';
import { TTimeZone } from 'types/DateTime';
import {
  detailLossMethodToContractsString,
  detailLossMethodToLossMethodString,
  detailLossMethodToTagIdsString,
} from 'utils/detail';
import { toFormattedDateTimeString } from 'utils/time';
import { getColumnRender } from 'utils/views';

const ExpandContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
`;

const getColumnLossTagIdsRender =
  (
    isUnconstrained: boolean,
    handleExpand?: (
      detailLossMethodsExpandedColumn: EDetailLossMethodsExpandedColumn,
      record: IDetailLossAccounting,
    ) => void,
  ) =>
  (value: unknown, record: IDetailLossAccounting): JSX.Element => {
    const handleClick = useCallback(() => {
      if (handleExpand !== undefined) {
        handleExpand(EDetailLossMethodsExpandedColumn.LossTagIds, record);
      }
    }, [record]);

    return isUnconstrained && handleExpand !== undefined ? (
      <ExpandContainer>
        <IconButton
          icon={
            <>
              {record.lossMethod === null || record.lossMethod.tag_ids === null
                ? 0
                : record.lossMethod.tag_ids.length}
            </>
          }
          onClick={handleClick}
        />
      </ExpandContainer>
    ) : (
      getColumnRender(
        isUnconstrained,
        detailLossMethodToTagIdsString,
      )(record.lossMethod)
    );
  };

export const getLossMethodsReviewColumns = (
  isUnconstrained: boolean,
  timeZone: TTimeZone,
  handleLossMethodsExpand?: (
    detailLossMethodsExpandedColumn: EDetailLossMethodsExpandedColumn,
    record: IDetailLossAccounting,
  ) => void,
): IViewDataTableColumn<IDetailLossAccounting>[] => {
  return [
    getColumnLossMethodsIdColumnConfig(),
    {
      dataIndex: 'physical_segment_ref',
      render: getColumnRender(isUnconstrained),
      title: 'PID',
    },
    {
      dataIndex: 'start',
      render: getColumnRender(isUnconstrained, (value: string) =>
        toFormattedDateTimeString(value, timeZone),
      ),
      title: 'Start',
    },
    {
      dataIndex: 'stop',
      render: getColumnRender(isUnconstrained, (value: string) =>
        toFormattedDateTimeString(value, timeZone),
      ),
      title: 'Stop',
    },
    {
      dataIndex: 'lossMethod',
      render: getColumnRender(
        isUnconstrained,
        detailLossMethodToLossMethodString,
      ),
      title: 'Loss Method',
    },
    {
      dataIndex: 'lossMethod',
      render: getColumnLossTagIdsRender(
        isUnconstrained,
        handleLossMethodsExpand,
      ),
      title: 'Loss Tag IDs',
      width: LOSS_TAG_IDS_WIDTH,
    },
    {
      dataIndex: 'lossMethod',
      render: getColumnRender(
        isUnconstrained,
        detailLossMethodToContractsString,
      ),
      title: 'Loss Contract Numbers',
    },
  ];
};
