import DataTable, {
  IDataTableProps,
} from 'components/molecules/DataTable/DataTable';
import EditableDataTable from 'components/molecules/EditableDataTable/EditableDataTable';
import {
  STANDARD_SPACING,
  VIEW_DATA_TABLE_CENTERED_CONTENT,
  VIEW_DATA_TABLE_SHARED_STYLES,
} from 'constants/styles';
import { EDetailLossMethodsExpandedColumn } from 'enums/Detail';
import useExpandableDataTable from 'hooks/useExpandableDataTable';
import usePrevious from 'hooks/usePrevious';
import { IThemedProps } from 'interfaces/Component';
import { IETagTagId } from 'interfaces/ETag';
import { IViewDataTableColumn } from 'interfaces/View';
import { useCallback, useMemo } from 'react';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import { IDetailLossAccounting } from 'reduxes/Detail/types';
import styled from 'styled-components';
import { TTimeZone } from 'types/DateTime';
import { copyDetailLossAccounting } from 'utils/detail';
import { isEmptyValue } from 'utils/general';
import { getLossMethodsReviewColumns } from 'utils/LossMethods';
import { alternatingTableRowBackground } from 'utils/styles';
import { getETagTagIdColumns } from 'utils/views';

const ExpandedRowLayout = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: ${STANDARD_SPACING};
`;

const ViewDataTable = styled(
  (
    props: IDataTableProps<IViewDataTableColumn<IETagTagId>, IETagTagId> &
      IThemedProps,
  ) => DataTable<IViewDataTableColumn<IETagTagId>, IETagTagId>(props),
)`
  ${VIEW_DATA_TABLE_SHARED_STYLES}
  ${VIEW_DATA_TABLE_CENTERED_CONTENT}
  ${(props) => alternatingTableRowBackground(props)}
`;

interface ILossMethodsDataTableProps {
  isUnconstrained: boolean;
  lossAccountings: IDetailLossAccounting[];
  timeZone: TTimeZone;
}

const LossMethodsDataTable = ({
  isUnconstrained,
  lossAccountings,
  timeZone,
}: ILossMethodsDataTableProps): JSX.Element => {
  const { currentTheme } = useThemeSwitcher();
  const previousTimeZone: string | undefined = usePrevious(timeZone);

  const getExpandedRowRender = useCallback(
    (
        getRowExpandedKey: (
          record: IDetailLossAccounting,
        ) => EDetailLossMethodsExpandedColumn | undefined,
      ) =>
      (record: IDetailLossAccounting): JSX.Element => {
        const detailLossMethodsExpandedColumn:
          | EDetailLossMethodsExpandedColumn
          | undefined = getRowExpandedKey(record);

        if (detailLossMethodsExpandedColumn !== undefined) {
          if (
            detailLossMethodsExpandedColumn ===
            EDetailLossMethodsExpandedColumn.LossTagIds
          ) {
            const tagIds: IETagTagId[] = isEmptyValue(
              record.lossMethod?.tag_ids,
            )
              ? []
              : record.lossMethod!.tag_ids!;

            const eTagTagIdsColumns: IViewDataTableColumn<IETagTagId>[] =
              getETagTagIdColumns(isUnconstrained);

            return (
              <ExpandedRowLayout>
                <ViewDataTable
                  columns={eTagTagIdsColumns}
                  currentTheme={currentTheme!}
                  data={tagIds}
                  pagination={false}
                />
              </ExpandedRowLayout>
            );
          }
        }

        return <></>;
      },
    [currentTheme, isUnconstrained],
  );

  const getLossMethodsRowKey = useCallback(
    (record: IDetailLossAccounting): string => record.key,
    [],
  );

  const { expandableConfig, updateRowExpandedKey } = useExpandableDataTable<
    IDetailLossAccounting,
    EDetailLossMethodsExpandedColumn
  >(
    EDetailLossMethodsExpandedColumn.None,
    getExpandedRowRender,
    getLossMethodsRowKey,
  );

  const handleLossMethodsExpand = useCallback(
    (
      detailLossMethodsExpandedColumn: EDetailLossMethodsExpandedColumn,
      record: IDetailLossAccounting,
    ) => {
      updateRowExpandedKey(detailLossMethodsExpandedColumn, record);
    },
    [updateRowExpandedKey],
  );

  const lossMethodsColumns: IViewDataTableColumn<IDetailLossAccounting>[] =
    useMemo(
      () =>
        getLossMethodsReviewColumns(true, timeZone, handleLossMethodsExpand),
      [handleLossMethodsExpand, timeZone],
    );

  const adjustedLossAccountings = useMemo(
    () =>
      (previousTimeZone === timeZone
        ? lossAccountings
        : lossAccountings.map(copyDetailLossAccounting)
      ).filter(
        (detailLossAccounting: IDetailLossAccounting): boolean =>
          detailLossAccounting.physical_segment_ref !== null ||
          detailLossAccounting.lossMethod !== null,
      ),
    [lossAccountings, previousTimeZone, timeZone],
  );

  return (
    <EditableDataTable
      columns={lossMethodsColumns}
      data={adjustedLossAccountings}
      expandable={expandableConfig}
      hideExpandIconColumn={true}
      maximumAllowableAdds={0}
      pagination={false}
    />
  );
};

export default LossMethodsDataTable;
