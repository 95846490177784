import { numberToString } from '@amcharts/amcharts4/.internal/core/utils/Utils';
import { SyncOutlined } from '@ant-design/icons';
import { notification as AntDesignNotification } from 'antd';
import IconButton from 'components/atoms/IconButton/IconButton';
import Badge from 'components/molecules/Badge/Badge';
import Select, { ISelectProps } from 'components/molecules/Select/Select';
import SeverityIcon from 'components/molecules/SeverityIcon/SeverityIcon';
import Tooltip from 'components/molecules/Tooltip/Tooltip';
import { BADGE_COUNT_OFFSET } from 'components/organisms/ETagManager/Refresher/constants';
import RefreshActions from 'components/organisms/ETagManager/Refresher/RefreshActions';
import { AUTO_REFRESH_OPTIONS, DEFAULT_AUTO_REFRESH } from 'constants/Detail';
import { BUTTON_ICON_DIMENSIONS } from 'constants/styles';
import { ESeverity } from 'enums/General';
import { EViewMode } from 'enums/View';
import usePrevious from 'hooks/usePrevious';
import { IETagExtendedIdentifier } from 'interfaces/ETag';
import { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { captureError } from 'utils/error';

const RefreshIcon = styled(SyncOutlined)`
  ${BUTTON_ICON_DIMENSIONS}
`;

const StyledSelect = styled((props: ISelectProps<number>) =>
  Select<number>(props),
)`
  width: 97px;
`;

interface IRefresherProps {
  defaultAutoRefreshInSeconds?: number;
  eTagExtendedIdentifier: IETagExtendedIdentifier;
  isDetailEdited: boolean;
  isDetailLoading: boolean;
  isDisabled?: boolean;
  numberOfDetailUpdates: number;
  onRefresh: () => Promise<void>;
  viewMode: EViewMode;
}

const Refresher = ({
  defaultAutoRefreshInSeconds,
  eTagExtendedIdentifier,
  isDetailEdited,
  isDetailLoading,
  isDisabled,
  numberOfDetailUpdates,
  onRefresh,
  viewMode,
}: IRefresherProps): JSX.Element => {
  const [notificationAPI, contextHolder] =
    AntDesignNotification.useNotification();
  const [autoRefreshInSeconds, setAutoRefreshInSeconds] = useState<number>(
    defaultAutoRefreshInSeconds === undefined
      ? DEFAULT_AUTO_REFRESH
      : defaultAutoRefreshInSeconds,
  );
  const previousNumberOfDetailUpdates: number | undefined = usePrevious(
    numberOfDetailUpdates,
  );
  const onRefreshRef = useRef<() => Promise<void>>(onRefresh);
  const refreshTimeoutIdRef = useRef<number | undefined>();
  const notificationKeyRef = useRef<string | undefined>();
  const nextRefreshTimeRef = useRef<Date | undefined>(undefined);
  const isAutoRefreshEnabled: boolean =
    viewMode === EViewMode.ReviewETag || viewMode === EViewMode.ReviewETagDraft;
  const isNotificationsEnabled: boolean =
    viewMode === EViewMode.EditETagAdjustment ||
    viewMode === EViewMode.EditETagAdjustmentWithATF ||
    viewMode === EViewMode.EditETagCorrection ||
    viewMode === EViewMode.EditETagDraft;

  useEffect(() => {
    if (defaultAutoRefreshInSeconds !== undefined) {
      setAutoRefreshInSeconds(defaultAutoRefreshInSeconds);
    }
  }, [defaultAutoRefreshInSeconds]);

  useEffect(() => {
    onRefreshRef.current = onRefresh;
  }, [onRefresh]);

  const clearRefreshTimeout = useCallback(() => {
    window.clearTimeout(refreshTimeoutIdRef.current);

    refreshTimeoutIdRef.current = undefined;
    nextRefreshTimeRef.current = undefined;
  }, []);

  const handleRefresh = useCallback(async () => {
    try {
      clearRefreshTimeout();

      await onRefreshRef.current();
    } catch (error: any) {
      captureError(error);
    }
  }, [clearRefreshTimeout]);

  useEffect(() => {
    if (
      previousNumberOfDetailUpdates !== undefined &&
      numberOfDetailUpdates > previousNumberOfDetailUpdates &&
      numberOfDetailUpdates > 0 &&
      isAutoRefreshEnabled &&
      autoRefreshInSeconds > 0
    ) {
      if (isDetailLoading) {
        if (nextRefreshTimeRef.current === undefined) {
          const now: number = Date.now();

          window.clearTimeout(refreshTimeoutIdRef.current);

          refreshTimeoutIdRef.current = window.setTimeout(
            handleRefresh,
            autoRefreshInSeconds * 1000,
          );

          nextRefreshTimeRef.current = new Date(
            now + autoRefreshInSeconds * 1000,
          );
        }
      } else {
        if (nextRefreshTimeRef.current === undefined) {
          const now: number = Date.now();

          refreshTimeoutIdRef.current = window.setTimeout(
            handleRefresh,
            autoRefreshInSeconds * 1000,
          );

          nextRefreshTimeRef.current = new Date(
            now + autoRefreshInSeconds * 1000,
          );
        } else {
          if (new Date() >= nextRefreshTimeRef.current) {
            handleRefresh();
          }
        }
      }
    }
  }, [
    autoRefreshInSeconds,
    handleRefresh,
    isAutoRefreshEnabled,
    isDetailLoading,
    numberOfDetailUpdates,
    previousNumberOfDetailUpdates,
  ]);

  useEffect(() => {
    if (autoRefreshInSeconds === 0) {
      clearRefreshTimeout();
    }
  }, [autoRefreshInSeconds, clearRefreshTimeout]);

  const handleNotificationClose = useCallback(() => {
    if (notificationKeyRef.current !== undefined) {
      AntDesignNotification.close(notificationKeyRef.current);

      notificationKeyRef.current = undefined;
    }
  }, []);

  const handleNotificationRefresh = useCallback(() => {
    handleRefresh();

    handleNotificationClose();
  }, [handleNotificationClose, handleRefresh]);

  useEffect(() => {
    if (
      previousNumberOfDetailUpdates !== undefined &&
      numberOfDetailUpdates > previousNumberOfDetailUpdates &&
      numberOfDetailUpdates > 0 &&
      isNotificationsEnabled &&
      notificationKeyRef.current === undefined
    ) {
      notificationKeyRef.current = Date.now().toString();

      notificationAPI.open({
        closeIcon: <></>,
        description: (
          <RefreshActions
            onKeep={handleNotificationClose}
            onRefresh={handleNotificationRefresh}
          />
        ),
        duration: 0,
        icon: <SeverityIcon severity={ESeverity.Warning} />,
        key: notificationKeyRef.current,
        message: `Updates have been made to this ${
          eTagExtendedIdentifier.draft_id === null ? 'E-Tag' : 'Draft'
        }`,
        placement: 'bottomRight',
      });
    }
  }, [
    eTagExtendedIdentifier.draft_id,
    handleNotificationClose,
    handleNotificationRefresh,
    isNotificationsEnabled,
    notificationAPI,
    numberOfDetailUpdates,
    previousNumberOfDetailUpdates,
  ]);

  const handleAutoRefreshChange = useCallback(
    (autoRefreshInSeconds: number | undefined) => {
      setAutoRefreshInSeconds(
        autoRefreshInSeconds === undefined ? 0 : autoRefreshInSeconds,
      );
    },
    [],
  );

  const hasDetailUpdates: boolean = numberOfDetailUpdates > 0;

  return (
    <>
      {contextHolder}
      <Tooltip
        title={`Refresh View${
          hasDetailUpdates ? ' (New Updates Available)' : ''
        }`}
      >
        <Badge
          count={
            numberOfDetailUpdates === undefined || numberOfDetailUpdates < 1
              ? undefined
              : numberOfDetailUpdates
          }
          offset={BADGE_COUNT_OFFSET}
        >
          <IconButton
            highlightSeverity={hasDetailUpdates ? ESeverity.Warning : undefined}
            icon={<RefreshIcon />}
            isDisabled={isDisabled || isDetailEdited || isDetailLoading}
            onClick={handleRefresh}
          />
        </Badge>
      </Tooltip>
      {isAutoRefreshEnabled ? (
        <Tooltip title='View Auto Refresh Time in Seconds After First Received Update Event'>
          <StyledSelect
            onChange={handleAutoRefreshChange}
            options={AUTO_REFRESH_OPTIONS}
            value={autoRefreshInSeconds}
            valueToUid={numberToString}
          />
        </Tooltip>
      ) : null}
    </>
  );
};

export default Refresher;
