import { EApprovalStatus } from 'enums/Approval';

// attributes from Etag-Alert/doc/openapi-spec_Alert.yml#AlertMessageAttributes
export enum EAlertMessageAttribute {
  End = 'end',
  NewCompositeState = 'new_composite_state',
  Notes = 'notes',
  PreviousCompositeState = 'previous_composite_state',
  RequestId = 'request_id',
  RequestNote = 'request_note',
  RequestType = 'request_type',
  Start = 'start',
  TagId = 'tag_id',
  TagNote = 'tag_note',
  TagPrimaryKey = 'tag_primary_key',
  UiRequestor = 'ui_requestor',
  UiTagId = 'ui_tag_id',
  EntityCode = 'entity_code',
}

export enum EAlertRuleType {
  AlertRuleByMessageType = 'AlertRuleByMessageType',
  AlertRuleByCompositeStateChange = 'AlertRuleByCompositeStateChange',
  AlertRuleByApprovalStatus = 'AlertRuleByApprovalStatus',
  AlertRuleByApprovalStatusAndUser = 'AlertRuleByApprovalStatusAndUser',
  AlertRuleByRequestStatus = 'AlertRuleByRequestStatus',
  AlertRuleByRequestStatusAndUser = 'AlertRuleByRequestStatusAndUser',
  AlertRuleByNotesAddition = 'AlertRuleByNotesAddition',
}

export enum ETransactionApprovalStatus {
  Study = EApprovalStatus.Study,
  Denied = EApprovalStatus.Denied,
}

export enum ENoteType {
  Request = 'REQUEST',
  Tag = 'TAG',
}

export enum EAlertSound {
  Alarm = 'alarm',
  Beep = 'beep',
  None = 'none',
}

export enum EAlertEffect {
  All = 'COLOR_SOUND',
  Message = 'COLOR_ONLY',
  None = 'NO_EFFECTS',
  Sound = 'SOUND_ONLY',
}

export enum EAlertNoteType {
  Request = 'REQUEST',
  Tag = 'TAG',
}

export enum EAlertMessageType {
  DistributeCorrection = 'DistributeCorrection',
  DistributeNewTag = 'DistributeNewTag',
  DistributeTerminateTag = 'DistributeTerminateTag',
}

export enum EAlertAcknowledged {
  All = 'ALL',
  Read = 'Y',
  Unread = 'N',
}
