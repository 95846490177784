import SeparatedRowLayout from 'components/atoms/SeparatedRowLayout/SeparatedRowLayout';
import { IPageContentProps } from 'components/organisms/Page/Page';
import ETagCodeSchemeForm from 'components/organisms/ToEntitySummaryUiConfiguration/ETagCodeSchemeForm';
import {
  saveButtonExecutePermissions,
  TO_ENTITY_SUMMARY_UI_CONFIG_PERMISSIONS_ID,
} from 'components/organisms/ToEntitySummaryUiConfiguration/helpers';
import {
  ConfigSection,
  ConfigSectionTitle,
} from 'components/organisms/ToEntitySummaryUiConfiguration/styledComponents';
import ToEntitySummaryUiConfiguration from 'components/organisms/ToEntitySummaryUiConfiguration/ToEntitySummaryUiConfiguration';
import UserToEntitySelection from 'components/organisms/UserToEntitySelection/UserToEntitySelection';
import {
  PAGE_LAYOUT_STYLES,
  PUSH_RIGHT_VALUE,
  STANDARD_SPACING,
  TO_ENTITY_TAG_WIDTH_VALUE,
} from 'constants/styles';
import { IToEntity } from 'interfaces/ToEntity';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { TTimeZone } from 'types/DateTime';
import { encodeIds, encodeTenantIds } from 'utils/general';
import { getToEntityUserState } from 'utils/user';
import { ZonedDateTime } from 'utils/zonedDateTime';
import TopBarMenu from '../../organisms/TopBarMenu/TopBarMenu';
import DownloadTagButton from '../../molecules/DownloadTagButton/DownloadTagButton';
import NavigationActions from '../../atoms/NavigationActions/NavigationActions';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import { TThemeValue } from '../../../types/Style';
import { useDispatch, useSelector } from 'react-redux';
import { TRootState } from '../../../types/Redux';
import { ERetreiveState } from '../../../enums/General';
import Button from '../../atoms/Button/Button';
import useAsyncEffect from 'use-async-effect';
import { AxiosResponse } from 'axios';
import { IETagConfigResponse } from '../../../interfaces/ETag';
import { retrieveToEntityConfig } from '../../../services/configclient/config';
import { userSetSelectedToEntity } from '../../../reduxes/User/actions';
import { TENANT_LABEL } from '../../../constants/misc';

const Layout = styled.div`
  ${PAGE_LAYOUT_STYLES}

  padding: ${STANDARD_SPACING};

  > :not(:first-child) {
    margin-top: ${STANDARD_SPACING};
  }

  > :last-child {
    margin-bottom: 0;
  }
`;

const ToEntitySelectionBar = styled(SeparatedRowLayout)`
  flex-shrink: 0;
  height: 40px;
`;

const StyledButton = styled(Button)`
  width: ${TO_ENTITY_TAG_WIDTH_VALUE}px;
`;

interface ISummaryOptionsPageContentProps
  extends IPageContentProps<undefined> {}

const SummaryOptionsPageContent = (
  props: ISummaryOptionsPageContentProps,
): JSX.Element => {
  const { userInfo } = props;
  const { selectedToEntity, toEntities, toEntityUserStates } = userInfo;
  const timeZone: TTimeZone =
    getToEntityUserState(selectedToEntity, toEntityUserStates)
      ?.selectedTimeZone ?? ZonedDateTime.defaultTimeZone();
  const [
    currentlySelectedToEntitySaveable,
    setCurrentlySelectedToEntitySaveable,
  ] = useState<boolean>(false);
  const [saveableToEntities, setSaveableToEntities] = useState<IToEntity[]>([]);
  const themeSwitcher = useThemeSwitcher();
  const currentTheme = themeSwitcher.currentTheme as TThemeValue;

  useEffect(() => {
    const toEntityCanPressSave: (toEntity: IToEntity) => boolean =
      saveButtonExecutePermissions(toEntities);

    setSaveableToEntities(toEntities.filter(toEntityCanPressSave));
  }, [toEntities]);

  useEffect(() => {
    if (selectedToEntity !== undefined) {
      setCurrentlySelectedToEntitySaveable(
        saveButtonExecutePermissions(toEntities)(selectedToEntity),
      );
    }
  }, [toEntities, selectedToEntity]);

  const [hideTenantTitle, setHideTenantTitle] = useState<boolean>(true);
  const [showSetTenantButton, setShowSetTenantButton] =
    useState<boolean>(false);
  const [showTenantConfig, setShowTenantConfig] = useState<boolean>(false);

  const configState = useSelector((state: TRootState) => state.config);
  useEffect(() => {
    if (configState.retrievingConfig === ERetreiveState.RetrievingCompleted) {
      const hideTenantTitleConfig =
        configState.hideTenantTitleConfig &&
        selectedToEntity &&
        configState.hideTenantTitleConfig.get(selectedToEntity.to_entity);
      setHideTenantTitle(hideTenantTitleConfig as boolean);
    }
  }, [configState, selectedToEntity, setHideTenantTitle]);

  const [isLoadingTenantConfig, setIsLoadingTenantConfig] =
    useState<boolean>(false);

  useAsyncEffect(async () => {
    setIsLoadingTenantConfig(true);
    let isCombinedViewEnabled = false;
    for (const entity of toEntities) {
      const retrieveToEntityConfigResponse: AxiosResponse<IETagConfigResponse> =
        await retrieveToEntityConfig(entity.to_entity);
      const eTagConfigResponse: IETagConfigResponse =
        retrieveToEntityConfigResponse.data;
      if (
        eTagConfigResponse &&
        eTagConfigResponse.response &&
        eTagConfigResponse.response.combined_view
      ) {
        isCombinedViewEnabled = eTagConfigResponse.response.combined_view;
      }
    }
    setShowSetTenantButton(isCombinedViewEnabled);
    setIsLoadingTenantConfig(false);
  }, [toEntities]);

  const dispatch = useDispatch();
  const handleTenantSelection = () => {
    dispatch(userSetSelectedToEntity({ selectedToEntity: undefined }));
    setShowTenantConfig(!showTenantConfig);
  };

  return (
    <Layout>
      <SeparatedRowLayout>
        {selectedToEntity ? (
          <TopBarMenu
            // We are hard coding "pattern" since this is the only tenant currently using the combined view mode
            // and the role changes needed for tenant users are not setup yet
            encodedTenantPermissionsId={encodeTenantIds(
              ['summaryOptions'],
              'pattern',
            )}
            encodedPermissionsId={encodeIds(
              ['summaryOptions'],
              selectedToEntity?.to_entity,
            )}
          />
        ) : null}
        {hideTenantTitle ? null : !isLoadingTenantConfig ? (
          <ToEntitySelectionBar>
            <UserToEntitySelection setShowTenantConfig={setShowTenantConfig} />
            {showSetTenantButton ? (
              <StyledButton
                key={'button_tenant'}
                label={TENANT_LABEL}
                onClick={handleTenantSelection}
                isPrimary={showTenantConfig && !selectedToEntity}
              />
            ) : null}
          </ToEntitySelectionBar>
        ) : null}
        <NavigationActions right={PUSH_RIGHT_VALUE}>
          <DownloadTagButton
            currentTheme={currentTheme}
            toEntityId={selectedToEntity?.to_entity}
          />
        </NavigationActions>
      </SeparatedRowLayout>
      {showTenantConfig ? (
        <ToEntitySummaryUiConfiguration
          encodedTenantPermissionsId={encodeTenantIds(
            [TO_ENTITY_SUMMARY_UI_CONFIG_PERMISSIONS_ID],
            'pattern',
          )}
          saveable={true}
          saveableToEntities={[]}
          toEntity={selectedToEntity}
        >
          <ConfigSection></ConfigSection>
        </ToEntitySummaryUiConfiguration>
      ) : selectedToEntity && !showTenantConfig ? (
        <ToEntitySummaryUiConfiguration
          encodedPermissionsId={encodeIds(
            [TO_ENTITY_SUMMARY_UI_CONFIG_PERMISSIONS_ID],
            selectedToEntity.to_entity,
          )}
          saveable={currentlySelectedToEntitySaveable}
          saveableToEntities={saveableToEntities}
          toEntity={selectedToEntity}
        >
          <ConfigSection>
            <ConfigSectionTitle>Default Tag Code Scheme</ConfigSectionTitle>
            <ETagCodeSchemeForm
              encodedPermissionsId={encodeIds(
                ['defaultTagCodeScheme'],
                selectedToEntity.to_entity,
              )}
              timeZone={timeZone}
              toEntity={selectedToEntity}
            />
          </ConfigSection>
        </ToEntitySummaryUiConfiguration>
      ) : null}
    </Layout>
  );
};

export default SummaryOptionsPageContent;
