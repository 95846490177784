import { EDIT_SUMMARY_INFORMATION_CONTACT_INFO_LABEL } from 'constants/Detail';
import { ETransactionType } from 'enums/ETag';
import { IEntityInfo } from 'interfaces/Entity';
import { IETagTagId } from 'interfaces/ETag';
import { IContactInfo } from 'interfaces/General';
import {
  ITagDateTimeDataSet,
  ITagHeaderDataSet,
  ITagNoteDataSet,
  ITemplateDataSet,
} from 'interfaces/SummaryInformation';
import { useMemo } from 'react';
import { TTimeZone } from 'types/DateTime';
import { getEditInfoKey } from 'utils/detail';
import { toFormattedDateTimeString } from 'utils/time';

interface IuseSummaryInformationReview {
  contactInfos: IContactInfo[];
  dateTimeDataSet: ITagDateTimeDataSet[];
  eTagTagIds: IETagTagId[];
  tagHeaderDataSet: ITagHeaderDataSet[];
  tagNoteDataSet: ITagNoteDataSet[];
  templateDataSet: ITemplateDataSet[];
}

const useSummaryInformationReview = (
  approved_termination_time: string | null,
  cc_list: IEntityInfo[] | null,
  contact_info: IContactInfo | null,
  creator: string | null,
  end_date: string | null,
  group_name: string | null,
  last_update_time: string | null,
  last_update_user: string | null,
  notes: string | null,
  start_date: string | null,
  tag_id: IETagTagId | null,
  test_flag: boolean | null,
  timeZone: TTimeZone,
  transaction_type: ETransactionType | null,
): IuseSummaryInformationReview => {
  const contactInfos: IContactInfo[] = useMemo(
    () => [
      {
        contact: contact_info === null ? null : contact_info.contact,
        fax: contact_info === null ? null : contact_info.fax,
        key:
          contact_info === null
            ? getEditInfoKey(EDIT_SUMMARY_INFORMATION_CONTACT_INFO_LABEL, 0, 0)
            : contact_info.key,
        phone: contact_info === null ? null : contact_info.phone,
      },
    ],
    [contact_info],
  );

  const dateTimeDataSet: ITagDateTimeDataSet[] = useMemo(
    () => [
      {
        approvedTerminationTime:
          approved_termination_time === null ||
          approved_termination_time === undefined
            ? null
            : toFormattedDateTimeString(approved_termination_time, timeZone),
        endDate:
          end_date === null || end_date === undefined
            ? null
            : toFormattedDateTimeString(end_date, timeZone),
        key: 'dateTimeData',
        startDate:
          start_date === null || start_date === undefined
            ? null
            : toFormattedDateTimeString(start_date, timeZone),
      },
    ],
    [approved_termination_time, end_date, start_date, timeZone],
  );

  const eTagTagIds: IETagTagId[] = useMemo(
    () => (tag_id === null ? [] : [tag_id]),
    [tag_id],
  );

  const tagHeaderDataSet: ITagHeaderDataSet[] = useMemo(
    () => [
      {
        ccList:
          cc_list === null
            ? []
            : cc_list.map(
                (entityInfo: IEntityInfo): IEntityInfo => ({ ...entityInfo }),
              ),
        key: 'tagHeaderData',
        test_flag,
        transaction_type,
      },
    ],
    [cc_list, test_flag, transaction_type],
  );

  const tagNoteDataSet: ITagNoteDataSet[] = useMemo(
    () => [
      {
        key: 'tagNoteData',
        notes,
      },
    ],
    [notes],
  );

  const templateDataSet: ITemplateDataSet[] = useMemo(
    () => [
      {
        creator,
        group_name,
        key: 'templateData',
        last_update_user,
        lastUpdateTime:
          last_update_time === null
            ? null
            : toFormattedDateTimeString(last_update_time, timeZone),
      },
    ],
    [creator, group_name, last_update_time, last_update_user, timeZone],
  );

  return {
    contactInfos,
    dateTimeDataSet,
    eTagTagIds,
    tagHeaderDataSet,
    tagNoteDataSet,
    templateDataSet,
  };
};

export default useSummaryInformationReview;
