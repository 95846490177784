import ETagTagIdsEditable, {
  IETagTagIdsEditableProps,
} from 'components/organisms/ETagTagIdsEditable/ETagTagIdsEditable';
import { BUTTON_ICON_DIMENSION_VALUE } from 'constants/styles';
import { IETagTagId } from 'interfaces/ETag';
import { useMemo } from 'react';
import { IDetailLossAccounting } from 'reduxes/Detail/types';
import styled from 'styled-components';

const ADD_BUTTON_OFFSET = {
  left: '-306px',
  top: 'calc(100% - 2px)',
};

const REMOVE_BUTTON_OFFSET = {
  right: '0',
  top: `calc(50% - ${BUTTON_ICON_DIMENSION_VALUE / 2}px)`,
};

const ETagTagIdsContainer = styled.div`
  width: 400px;
`;

interface IExpandedETagTagIdsEditableProps
  extends Omit<
    IETagTagIdsEditableProps,
    'addButtonOffset' | 'data' | 'removeButtonOffset'
  > {
  lossAccounting: IDetailLossAccounting;
}

const ExpandedETagTagIdsEditable = (
  props: IExpandedETagTagIdsEditableProps,
): JSX.Element => {
  const { lossAccounting, ...rest } = props;

  const data: IETagTagId[] = useMemo(
    () =>
      lossAccounting.lossMethod === null ||
      lossAccounting.lossMethod.tag_ids === null
        ? []
        : lossAccounting.lossMethod.tag_ids,
    [lossAccounting.lossMethod],
  );

  return (
    <ETagTagIdsContainer>
      <ETagTagIdsEditable
        {...rest}
        addButtonOffset={ADD_BUTTON_OFFSET}
        data={data}
        removeButtonOffset={REMOVE_BUTTON_OFFSET}
      />
    </ETagTagIdsContainer>
  );
};

export default ExpandedETagTagIdsEditable;
