import {
  CellClassParams,
  ColDef,
  IAggFunc,
  IAggFuncParams,
  IRowNode,
  ValueFormatterFunc,
  ValueFormatterParams,
} from 'ag-grid-community';
import { EColumnType } from 'enums/ETag';
import { ETheme } from 'enums/Style';
import { IOption } from 'interfaces/Component';
import {
  ITransmissionAvailabilityColumnDataAgGrid,
  ITransmissionAvailabilityDataSet,
} from 'interfaces/ETag';
import { backgroundColour } from 'utils/styles';
import { ETransmissionCapacityDataViews } from './types';

export const TRANSMISSION_AVAILABILITY_TEXT_WHITE = '#FFFFFF';
export const TRANSMISSION_AVAILABILITY_TEXT_BLACK = '#000000';

export const certainTypeSum: IAggFunc = (params: IAggFuncParams) => {
  let sum = 0;
  if (
    params.rowNode.key !== 'Available' &&
    params.rowNode.key !== 'Granted' &&
    params.rowNode.key !== 'CustomCapacity' &&
    params.rowNode.key !== 'Used'
  ) {
    return null;
  }
  params.values.forEach((value: number) => {
    sum += value;
  });
  return sum;
};

const availableRowFirstAgg: IAggFunc = (params: IAggFuncParams) => {
  if (params.rowNode.key === 'Available') {
    return params.values[0];
  }
  return null;
};

const priceFormatter: ValueFormatterFunc = (params: ValueFormatterParams) => {
  if (params.value) {
    const valueFloat: number = parseFloat(params.value);
    return valueFloat.toFixed(1);
  } else {
    return '';
  }
};

export const transmissionCapacityAutoGroupColumnDef: ColDef = {
  headerName: 'AREF',
  cellRendererParams: { suppressCount: true },
  cellRendererSelector: (params) => {
    if (
      params.node.key === 'Available' ||
      params.node.key === 'Granted' ||
      params.node.key === 'CustomCapacity' ||
      (params.node.key === 'Used' &&
        params.node.allLeafChildren[0].data &&
        params.node.allLeafChildren[0].data.data_name === 'Used')
    ) {
      return undefined;
    } else {
      return { component: 'agGroupCellRenderer' };
    }
  },
  filter: true,
  filterValueGetter: (params) => {
    return params.data.aref;
  },
  sort: 'asc',
};

export const TRANSMISSION_AVAILABILITY_COLUMN_DATA_AG_GRID: ITransmissionAvailabilityColumnDataAgGrid[] =
  [
    {
      field: 'aref',
      headerName: 'AREF',
      minWidth: 70,
      maxWidth: 70,
      type: EColumnType.Group,
      rowGroup: true,
      hide: true,
      sort: 'asc',
    },
    {
      field: 'transmission_provider',
      headerName: 'TP',
      minWidth: 70,
      maxWidth: 70,
      type: EColumnType.String,
      aggFunc: availableRowFirstAgg,
    },
    {
      field: 'pod',
      headerName: 'POD',
      minWidth: 70,
      maxWidth: 70,
      type: EColumnType.String,
      aggFunc: availableRowFirstAgg,
    },
    {
      field: 'por',
      headerName: 'POR',
      minWidth: 70,
      maxWidth: 70,
      type: EColumnType.String,
      aggFunc: availableRowFirstAgg,
    },
    {
      field: 'path',
      headerName: 'Path',
      minWidth: 90,
      maxWidth: 90,
      type: EColumnType.String,
      aggFunc: availableRowFirstAgg,
    },
    {
      field: 'request_type',
      headerName: 'Request\nType',
      minWidth: 90,
      maxWidth: 90,
      type: EColumnType.String,
      aggFunc: availableRowFirstAgg,
    },
    {
      field: 'offer_price',
      headerName: 'Offer\nPrice',
      minWidth: 90,
      maxWidth: 90,
      type: EColumnType.String,
      valueFormatter: priceFormatter,
      aggFunc: availableRowFirstAgg,
    },
    {
      field: 'bid_price',
      headerName: 'Bid\nPrice',
      minWidth: 90,
      maxWidth: 90,
      type: EColumnType.String,
      valueFormatter: priceFormatter,
      aggFunc: availableRowFirstAgg,
    },
    {
      field: 'ts_type',
      headerName: 'TS\nType',
      minWidth: 90,
      maxWidth: 90,
      type: EColumnType.String,
      aggFunc: availableRowFirstAgg,
    },
    {
      field: 'ts_class',
      headerName: 'TS\nClass',
      minWidth: 90,
      maxWidth: 90,
      type: EColumnType.String,
      aggFunc: availableRowFirstAgg,
    },
    {
      field: 'service_increment',
      headerName: 'Service\nIncrement',
      minWidth: 90,
      maxWidth: 90,
      type: EColumnType.String,
      aggFunc: availableRowFirstAgg,
    },
    {
      field: 'nerc_curtailment_priority',
      headerName: 'NERC\nPriority',
      minWidth: 90,
      maxWidth: 90,
      type: EColumnType.Integer,
      aggFunc: availableRowFirstAgg,
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 90,
      maxWidth: 90,
      type: EColumnType.String,
      aggFunc: availableRowFirstAgg,
    },
    {
      field: 'date',
      headerName: 'Date',
      minWidth: 70,
      maxWidth: 70,
      type: EColumnType.Group,
      rowGroup: true,
      hide: true,
    },
    {
      field: 'data_type',
      headerName: 'Category',
      maxWidth: 0,
      minWidth: 0,
      type: EColumnType.Group,
      rowGroup: true,
      hide: true,
    },
    {
      field: 'data_name',
      headerName: 'Data',
      minWidth: 180,
      maxWidth: 180,
      type: EColumnType.DetailLink,
    },
    {
      field: 'he_01',
      headerName: 'HE\n01',
      minWidth: 54,
      maxWidth: 54,
      type: EColumnType.Integer,
      aggFunc: certainTypeSum,
    },
    {
      field: 'he_02',
      headerName: 'HE\n02',
      minWidth: 54,
      maxWidth: 54,
      type: EColumnType.Integer,
      aggFunc: certainTypeSum,
    },
    {
      field: 'he_02_extra',
      headerName: 'HE\n02*',
      minWidth: 58,
      maxWidth: 58,
      type: EColumnType.Integer,
      hide: true,
      aggFunc: certainTypeSum,
    },
    {
      field: 'he_03',
      headerName: 'HE\n03',
      minWidth: 54,
      maxWidth: 54,
      type: EColumnType.Integer,
      aggFunc: certainTypeSum,
    },
    {
      field: 'he_04',
      headerName: 'HE\n04',
      minWidth: 54,
      maxWidth: 54,
      type: EColumnType.Integer,
      aggFunc: certainTypeSum,
    },
    {
      field: 'he_05',
      headerName: 'HE\n05',
      minWidth: 54,
      maxWidth: 54,
      type: EColumnType.Integer,
      aggFunc: certainTypeSum,
    },
    {
      field: 'he_06',
      headerName: 'HE\n06',
      minWidth: 54,
      maxWidth: 54,
      type: EColumnType.Integer,
      aggFunc: certainTypeSum,
    },
    {
      field: 'he_07',
      headerName: 'HE\n07',
      minWidth: 54,
      maxWidth: 54,
      type: EColumnType.Integer,
      aggFunc: certainTypeSum,
    },
    {
      field: 'he_08',
      headerName: 'HE\n08',
      minWidth: 54,
      maxWidth: 54,
      type: EColumnType.Integer,
      aggFunc: certainTypeSum,
    },
    {
      field: 'he_09',
      headerName: 'HE\n09',
      minWidth: 54,
      maxWidth: 54,
      type: EColumnType.Integer,
      aggFunc: certainTypeSum,
    },
    {
      field: 'he_10',
      headerName: 'HE\n10',
      minWidth: 54,
      maxWidth: 54,
      type: EColumnType.Integer,
      aggFunc: certainTypeSum,
    },
    {
      field: 'he_11',
      headerName: 'HE\n11',
      minWidth: 54,
      maxWidth: 54,
      type: EColumnType.Integer,
      aggFunc: certainTypeSum,
    },
    {
      field: 'he_12',
      headerName: 'HE\n12',
      minWidth: 54,
      maxWidth: 54,
      type: EColumnType.Integer,
      aggFunc: certainTypeSum,
    },
    {
      field: 'he_13',
      headerName: 'HE\n13',
      minWidth: 54,
      maxWidth: 54,
      type: EColumnType.Integer,
      aggFunc: certainTypeSum,
    },
    {
      field: 'he_14',
      headerName: 'HE\n14',
      minWidth: 54,
      maxWidth: 54,
      type: EColumnType.Integer,
      aggFunc: certainTypeSum,
    },
    {
      field: 'he_15',
      headerName: 'HE\n15',
      minWidth: 54,
      maxWidth: 54,
      type: EColumnType.Integer,
      aggFunc: certainTypeSum,
    },
    {
      field: 'he_16',
      headerName: 'HE\n16',
      minWidth: 54,
      maxWidth: 54,
      type: EColumnType.Integer,
      aggFunc: certainTypeSum,
    },
    {
      field: 'he_17',
      headerName: 'HE\n17',
      minWidth: 54,
      maxWidth: 54,
      type: EColumnType.Integer,
      aggFunc: certainTypeSum,
    },
    {
      field: 'he_18',
      headerName: 'HE\n18',
      minWidth: 54,
      maxWidth: 54,
      type: EColumnType.Integer,
      aggFunc: certainTypeSum,
    },
    {
      field: 'he_19',
      headerName: 'HE\n19',
      minWidth: 54,
      maxWidth: 54,
      type: EColumnType.Integer,
      aggFunc: certainTypeSum,
    },
    {
      field: 'he_20',
      headerName: 'HE\n20',
      minWidth: 54,
      maxWidth: 54,
      type: EColumnType.Integer,
      aggFunc: certainTypeSum,
    },
    {
      field: 'he_21',
      headerName: 'HE\n21',
      minWidth: 54,
      maxWidth: 54,
      type: EColumnType.Integer,
      aggFunc: certainTypeSum,
    },
    {
      field: 'he_22',
      headerName: 'HE\n22',
      minWidth: 54,
      maxWidth: 54,
      type: EColumnType.Integer,
      aggFunc: certainTypeSum,
    },
    {
      field: 'he_23',
      headerName: 'HE\n23',
      minWidth: 54,
      maxWidth: 54,
      type: EColumnType.Integer,
      aggFunc: certainTypeSum,
    },
    {
      field: 'he_24',
      headerName: 'HE\n24',
      minWidth: 54,
      maxWidth: 54,
      type: EColumnType.Integer,
      aggFunc: certainTypeSum,
    },
  ];

export const defaultUsedRow: ITransmissionAvailabilityDataSet = {
  aref: '',
  por: '',
  pod: '',
  request_type: '',
  offer_price: '',
  bid_price: '',
  ts_type: '',
  ts_class: '',
  service_increment: '',
  nerc_curtailment_priority: null,
  status: null,
  path: '',
  date: '',
  data_type: 'Used',
  data_name: 'Used',
  start_date: '',
  end_date: '',
  transmission_provider: '',
  he_01: 0,
  he_02: 0,
  he_03: 0,
  he_04: 0,
  he_05: 0,
  he_06: 0,
  he_07: 0,
  he_08: 0,
  he_09: 0,
  he_10: 0,
  he_11: 0,
  he_12: 0,
  he_13: 0,
  he_14: 0,
  he_15: 0,
  he_16: 0,
  he_17: 0,
  he_18: 0,
  he_19: 0,
  he_20: 0,
  he_21: 0,
  he_22: 0,
  he_23: 0,
  he_24: 0,
};

export const transmissionCapacityDefaultColumnDefinition = (
  currentTheme: string | undefined = ETheme.Light,
): ColDef => {
  const defaultColDef: ColDef = {
    cellStyle: (params: CellClassParams) => ({
      backgroundColor: backgroundColour({ currentTheme }),
      textAlign: 'right',
      paddingLeft: '4px',
      paddingRight: '4px',
      ...(params.node.rowGroupColumn?.getColDef().field === 'data_type' && {
        display: 'flex',
        'justify-content': 'flex-end',
      }),
      fontWeight:
        params.node.rowGroupColumn?.getColDef().field === 'aref'
          ? 'bold'
          : 'normal',
    }),
    filter: true,
    floatingFilter: true,
    resizable: true,
    sortable: true,
    menuTabs: [],
    filterParams: {
      newRowsAction: 'keep',
    },
    // TODO: Apply AG Grid recommended fix, if any
    // Disabled this to adress the column filter not being clickable in certain browsers
    // floatingFilterComponentParams: { suppressFilterButton: true },
    minWidth: 150,
    maxWidth: 150,
  };
  return defaultColDef;
};

export const transmissionAvailabilityOptionalColumns: string[] = [
  'transmission_provider',
  'por',
  'pod',
  'path',
  'request_type',
  'offer_price',
  'bid_price',
  'ts_type',
  'ts_class',
  'nerc_curtailment_priority',
  'status',
  'service_increment',
];

export const transmissionAvailabilityOptionalColumnSelectorOptions: IOption<string>[] =
  [
    { label: 'TP', value: 'transmission_provider' },
    { label: 'POR', value: 'por' },
    { label: 'POD', value: 'pod' },
    { label: 'Path', value: 'path' },
    { label: 'Request Type', value: 'request_type' },
    { label: 'Offer Price', value: 'offer_price' },
    { label: 'Bid Price', value: 'bid_price' },
    { label: 'TS Type', value: 'ts_type' },
    { label: 'TS Class', value: 'ts_class' },
    { label: 'NERC Priority', value: 'nerc_curtailment_priority' },
    { label: 'Status', value: 'status' },
    { label: 'Service Increment', value: 'service_increment' },
  ].sort((a, b) => a.label.localeCompare(b.label));

export const transmissionAvailabilityDataViewSelectorOptions: IOption<ETransmissionCapacityDataViews>[] =
  [
    { label: 'Used AREF', value: ETransmissionCapacityDataViews.USED_AREF },
    {
      label: 'Unused AREF',
      value: ETransmissionCapacityDataViews.UNUSED_AREF,
    },
    { label: 'TSN', value: ETransmissionCapacityDataViews.TSN },
  ];

export const transmissionAvailabilityExpandAllRows = (rowNode: IRowNode) => {
  // Never expand Available or Granted rows since they are being used just for aggregation
  if (
    rowNode.key !== 'Available' &&
    rowNode.key !== 'Granted' &&
    rowNode.key !== 'CustomCapacity'
  ) {
    // Only expand the Used row if it has actual data (not just the 'dummy' row of all zeros)
    if (
      rowNode.key !== 'Used' ||
      (rowNode.key === 'Used' &&
        rowNode.allLeafChildren[0].data.data_name !== 'Used')
    ) {
      rowNode.setExpanded(true);
    }
  }
};
