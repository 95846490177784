import Tooltip from 'components/molecules/Tooltip/Tooltip';
import { INITIAL_POSITION_INDICATOR_WIDTH_VALUE } from 'components/pages/DetailPrintPage/PrintWidthTuner/constants';
import { IPosition } from 'components/pages/DetailPrintPage/PrintWidthTuner/types';
import {
  MAXIMUM_Z_INDEX,
  PRINT_WIDTH_TUNER_INITIAL_POSITION_INDICATOR_Z_INDEX,
} from 'constants/styles';
import { useCallback, useMemo, useState } from 'react';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import styled from 'styled-components';

interface IHeightProps {
  height?: number;
}

const TunerBar = styled.div<IHeightProps>`
  background-color: rgba(127, 127, 127, 0.5);
  cursor: col-resize;
  height: ${(props) =>
    props.height === undefined ? '100vh' : `${props.height}px`};
  position: absolute;
  vertical-align: top;
  width: 20px;
  z-index: ${MAXIMUM_Z_INDEX};

  @media print {
    display: none;
  }
`;

const Container = styled.div<IHeightProps>`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: min(
    100vh,
    ${(props) => (props.height === undefined ? '100vh' : `${props.height}px`)}
  );
  justify-content: center;
  position: sticky;
  top: 0;
`;

interface IInitialPositionIndicatorProps extends IHeightProps {
  initialXOffset?: number;
}

const InitialPositionIndicator = styled.div<IInitialPositionIndicatorProps>`
  border-left: dashed ${INITIAL_POSITION_INDICATOR_WIDTH_VALUE}px
    rgba(127, 127, 127, 0.33);
  height: ${(props) =>
    props.height === undefined ? '100vh' : `${props.height}px`};
  left: ${(props) =>
    props.initialXOffset === undefined ? 0 : `${props.initialXOffset}px`};
  position: absolute;
  z-index: ${PRINT_WIDTH_TUNER_INITIAL_POSITION_INDICATOR_Z_INDEX};

  @media print {
    display: none;
  }
`;

interface IPrintWidthTunerProps {
  height: number | undefined;
  initialXOffset?: number;
  maximumLeftOffset?: number;
  onMove: (xDelta: number) => void;
}

const PrintWidthTuner = ({
  height,
  initialXOffset,
  maximumLeftOffset,
  onMove,
}: IPrintWidthTunerProps): JSX.Element => {
  const [tooltipIsDisabled, setTooltipIsDisabled] = useState<boolean>(false);

  const handleDraggableDrag = useCallback(() => {
    setTooltipIsDisabled(true);
  }, []);

  const handleDraggableStop = useCallback(
    (_draggableEvent: DraggableEvent, draggableData: DraggableData) => {
      onMove(draggableData.x);
      setTooltipIsDisabled(false);
    },
    [onMove],
  );

  const initialPositionOffset: IPosition = useMemo(
    (): IPosition =>
      initialXOffset === undefined
        ? { x: 0, y: 0 }
        : { x: initialXOffset, y: 0 },
    [initialXOffset],
  );

  const bounds = useMemo(
    () =>
      maximumLeftOffset === undefined
        ? undefined
        : {
            left: maximumLeftOffset,
          },
    [maximumLeftOffset],
  );

  return (
    <>
      <Draggable
        axis='x'
        bounds={bounds}
        onDrag={handleDraggableDrag}
        onStop={handleDraggableStop}
        positionOffset={initialPositionOffset}
      >
        <TunerBar height={height}>
          <Container height={height}>
            <Tooltip
              isDisabled={tooltipIsDisabled}
              placement='left'
              title='Print Width Tuner'
            >
              ||
            </Tooltip>
          </Container>
        </TunerBar>
      </Draggable>
      <InitialPositionIndicator
        height={height}
        initialXOffset={initialXOffset}
      />
    </>
  );
};

export default PrintWidthTuner;
