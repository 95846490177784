import { ICustomFilter } from 'interfaces/Filter';

export enum EAttribute {
  ApprovalRight = 'approval_rights',
  CompositeState = 'composite_state',
  CPSE = 'ui_cpse',
  Curtailed = 'curtailed',
  FromEntity = 'ui_from_entity',
  GCA = 'ui_gca',
  LCA = 'ui_lca',
  LossesTag = 'is_losses_tag',
  MarketSegmentContractNumber = 'market_segment_contract_number',
  MarketSegmentEnergyProduct = 'market_segment_energy_product',
  MarketSegmentMiscInfoToken = 'market_segment_misc_info_token',
  MarketSegmentMiscInfoValue = 'market_segment_misc_info_value',
  MarketSegmentPSE = 'ui_market_segment_pse',
  MO = 'ui_mo',
  NITS = 'nits',
  PhysicalSegmentContractNumber = 'physical_segment_contract_number',
  PhysicalSegmentMiscInfoToken = 'physical_segment_misc_info_token',
  PhysicalSegmentMiscInfoValue = 'physical_segment_misc_info_value',
  POD = 'ui_pod',
  POR = 'ui_por',
  SE = 'ui_se',
  Sink = 'ui_sink',
  Source = 'ui_source',
  TagNote = 'tag_note',
  TagType = 'tag_type',
  TestTag = 'test_tag',
  TimeClassification = 'time_classification',
  TransAllocCustomer = 'ui_trans_alloc_customer',
  TransAllocMiscInfoToken = 'trans_alloc_misc_info_token',
  TransAllocMiscInfoValue = 'trans_alloc_misc_info_value',
  TransAllocProduct = 'trans_alloc_product',
  TSP = 'ui_tsp',
  TSR = 'tsr',
}

export enum EOperator {
  And = 'and',
  Not = 'not',
  Or = 'or',
}

export enum EOperation {
  Criteria = 'criteria',
  Operator = 'operator',
  OperatorBefore = 'operatorBefore',
}

export enum EList {
  All = 'all',
  Any = 'any',
  First = 'first',
  Last = 'last',
}

export interface ICustomFilterEditHistory {
  future: ICustomFilter[];
  past: ICustomFilter[];
  present: ICustomFilter;
}
