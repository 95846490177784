import { AxiosResponse } from 'axios';
import { SendOutlined } from '@ant-design/icons';
import { Card as AntDesignCard } from 'antd';
import IconButton from 'components/atoms/IconButton/IconButton';
import Spinner from 'components/atoms/Spinner/Spinner';
import { ETagRequestFormFeedback } from 'components/molecules/ETagRequestFormFeedback/ETagRequestFormFeedback';
import { ETagRequestFormFooter } from 'components/molecules/ETagRequestFormFooter/ETagRequestFormFooter';
import { EFloatOverPlacement } from 'components/molecules/FloatOver/FloatOver';
import Tooltip from 'components/molecules/Tooltip/Tooltip';
import {
  BUTTON_ICON_DIMENSIONS,
  STANDARD_SPACING_VALUE,
} from 'constants/styles';
import {
  FloatOverContext,
  IFloatOverContext,
} from 'contexts/FloatOver/FloatOver';
import { EActionState, ESeverity } from 'enums/General';
import { EPageMode } from 'enums/Page';
import { EModalType, useModal } from 'hooks/useModal';
import { useValidationSubmissionHandlers } from 'hooks/useValidationSubmissionHandlers';
import { IETagTagId, IETagDraft, IETagValidationErrors } from 'interfaces/ETag';
import {
  MutableRefObject,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import {
  saveAndRequestNewTag,
  saveAndValidateNewTag,
} from 'services/agent/tags/requests';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { TTimeZone } from 'types/DateTime';
import { TETagDraftId } from 'types/ETag';
import { TRootState } from 'types/Redux';
import { TToEntityId } from 'types/ToEntity';
import {
  buildETagDraft,
  cleanUpDetailState,
  detailPageLocationString,
  transformAndUpdateMarketInfos,
  updateDetailPageLocationDescriptor,
} from 'utils/detail';
import { captureError } from 'utils/error';
import { computeFullTagPrimaryKey } from 'utils/eTag';
import { isEmptyValue } from 'utils/general';
import {
  detailEditETagDetail,
  detailUpdateETagDetails,
  detailUpdateETagDetailsStart,
  detailUpdateETagDetailsSuccess,
} from 'reduxes/Detail/actions';
import { IETagDetail } from 'reduxes/Detail/types';
import { ActionCreators } from 'redux-undo';

const PANEL_MAXIMUM_HEIGHT = '600px';

const ValidateOrSubmitNewTagIcon = styled(SendOutlined)`
  ${BUTTON_ICON_DIMENSIONS}
`;

const TITLE: string = 'Validate or Submit New Tag';
const REQUEST_DISPLAY_NAME = 'New Tag';

interface IProps {
  draftId: Exclude<TETagDraftId, null>;
  encodedPermissionsId: string;
  id: string;
  isDisabled: boolean;
  security_key: string | null;
  setNumberOfDetailUpdates: (value: SetStateAction<number>) => void;
  tag_id: IETagTagId | null;
  timeZone: TTimeZone;
  toEntityId: TToEntityId;
  setActionBarValidationErrors: (value: IETagValidationErrors) => void;
}

const retrieveETagManagerState = (state: TRootState) => ({
  state: state.detail.present,
});

const ValidateOrSubmitNewTag = ({
  draftId,
  encodedPermissionsId,
  id,
  isDisabled,
  security_key,
  setNumberOfDetailUpdates,
  tag_id,
  timeZone,
  toEntityId,
  setActionBarValidationErrors,
}: IProps): JSX.Element => {
  const { state } = useSelector(retrieveETagManagerState);
  const {
    floatOverContent,
    floatOverId,
    setFloatOverContent,
    setFloatOverDefaultPosition,
    setFloatOverId,
    setFloatOverMaximumHeight,
    setFloatOverUseDragPanel,
  } = useContext<IFloatOverContext>(FloatOverContext);
  const {
    errorMessage,
    isValidateAndSubmitSuccessful,
    isValidateSuccessful,
    isValidating,
    setSkipValidation,
    skipValidation,
    successMessage,
    validateAndSubmitActionState,
    validationFailures,
    validationWarnings,
    wrapValidateHandler,
    wrapValidateAndSubmitHandler,
  } = useValidationSubmissionHandlers(REQUEST_DISPLAY_NAME);
  const modal = useModal();
  const history = useHistory();
  const { search } = useLocation();
  const dispatch = useDispatch();

  const buttonRef = useRef<HTMLElement>() as MutableRefObject<HTMLElement>;

  const cleanUpETag = useCallback(async () => {
    const eTagDetail: IETagDetail = {
      stateTransform: cleanUpDetailState(),
    };
    return dispatch(detailEditETagDetail(eTagDetail));
  }, [dispatch]);

  const saveAndValidateRequest = useCallback(async () => {
    // In order to prevent a notification message appearing after each save,
    // we reduce this count by 1 to account for an expected etag websocket
    // message corresponding to the successful update to the draft.
    setNumberOfDetailUpdates(
      (previousNumberOfDetailUpdates: number): number =>
        previousNumberOfDetailUpdates - 1,
    );

    cleanUpETag();

    dispatch(detailUpdateETagDetailsStart());

    dispatch(detailUpdateETagDetails(transformAndUpdateMarketInfos()));

    const eTagDraft: IETagDraft = buildETagDraft(state, timeZone);

    const response: AxiosResponse = await saveAndValidateNewTag(
      toEntityId,
      draftId,
      timeZone,
      eTagDraft,
    );

    dispatch(detailUpdateETagDetailsSuccess());

    dispatch(ActionCreators.clearHistory());

    return response;
  }, [
    cleanUpETag,
    dispatch,
    draftId,
    setNumberOfDetailUpdates,
    state,
    timeZone,
    toEntityId,
  ]);

  const saveValidateAndSubmitRequest = useCallback(async () => {
    // In order to prevent a notification message appearing after each save,
    // we reduce this count by 1 to account for an expected etag websocket
    // message corresponding to the successful update to the draft.
    setNumberOfDetailUpdates(
      (previousNumberOfDetailUpdates: number): number =>
        previousNumberOfDetailUpdates - 1,
    );

    cleanUpETag();

    dispatch(detailUpdateETagDetailsStart());

    dispatch(detailUpdateETagDetails(transformAndUpdateMarketInfos()));

    const eTagDraft: IETagDraft = buildETagDraft(state, timeZone);

    const response: AxiosResponse = await saveAndRequestNewTag(
      toEntityId,
      skipValidation,
      draftId,
      timeZone,
      eTagDraft,
    );

    dispatch(detailUpdateETagDetailsSuccess());

    dispatch(ActionCreators.clearHistory());

    return response;
  }, [
    cleanUpETag,
    dispatch,
    draftId,
    setNumberOfDetailUpdates,
    skipValidation,
    state,
    timeZone,
    toEntityId,
  ]);

  const handleValidate = useMemo(
    () => wrapValidateHandler(saveAndValidateRequest),
    [saveAndValidateRequest, wrapValidateHandler],
  );

  const handleValidateAndSubmit = useMemo(
    () => wrapValidateAndSubmitHandler(saveValidateAndSubmitRequest),
    [saveValidateAndSubmitRequest, wrapValidateAndSubmitHandler],
  );

  const FloatOverContent: JSX.Element = useMemo(
    () => (
      <AntDesignCard key={id} title={TITLE}>
        <ETagRequestFormFeedback
          errorMessage={errorMessage}
          isValidateAndSubmitSuccessful={isValidateAndSubmitSuccessful}
          isValidateSuccessful={isValidateSuccessful}
          isValidating={isValidating}
          requestType={REQUEST_DISPLAY_NAME}
          successMessage={successMessage ?? undefined}
          validationErrors={validationFailures}
          validationWarnings={validationWarnings}
        />
        <ETagRequestFormFooter
          isSaveAction={true}
          handleValidate={handleValidate}
          handleValidateAndSubmit={handleValidateAndSubmit}
          isDisabled={isValidating}
          isValidateAndSubmitDisabled={false}
          isValidateDisabled={false}
          isValidateHidden={false}
          requestType={REQUEST_DISPLAY_NAME}
          setSkipValidation={setSkipValidation}
          skipValidation={skipValidation}
          validationErrors={validationFailures}
        />
      </AntDesignCard>
    ),
    [
      errorMessage,
      id,
      isValidateAndSubmitSuccessful,
      isValidateSuccessful,
      isValidating,
      setSkipValidation,
      successMessage,
      skipValidation,
      validationFailures,
      validationWarnings,
      handleValidate,
      handleValidateAndSubmit,
    ],
  );

  useEffect(() => {
    if (!isEmptyValue(floatOverContent) && floatOverId === id) {
      setFloatOverUseDragPanel(true);
      setFloatOverContent(FloatOverContent);
      setActionBarValidationErrors({
        failure_list:
          validationFailures?.map((message) => ({
            message,
            severity: ESeverity.Error,
          })) ?? [],
        warning_list:
          validationWarnings?.map((message) => ({
            message,
            severity: ESeverity.Warning,
          })) ?? [],
      });
    }
  }, [
    floatOverContent,
    FloatOverContent,
    floatOverId,
    id,
    setFloatOverContent,
    setFloatOverUseDragPanel,
    setActionBarValidationErrors,
    validationFailures,
    validationWarnings,
  ]);

  useEffect(() => {
    if (validateAndSubmitActionState === EActionState.Actioning) {
      modal.show(EModalType.INFO, {
        content: <Spinner />,
        title:
          'Submitting New Tag' + (skipValidation ? ' without validations' : ''),
        okButtonProps: { disabled: true },
      });
    } else if (isValidateAndSubmitSuccessful) {
      if (floatOverId === id) {
        setFloatOverContent(null);
      }

      const fullTagPrimaryKey = computeFullTagPrimaryKey(tag_id, security_key);

      modal.show(EModalType.SUCCESS, {
        content: successMessage,
        title: 'New Tag has been submitted',
        okText: fullTagPrimaryKey === undefined ? 'OK' : 'Review New Tag',
        onOk: () => {
          if (fullTagPrimaryKey !== undefined) {
            const href: string = detailPageLocationString({
              defaultTimeZone: timeZone,
              tagPrimaryKey: fullTagPrimaryKey,
              mode: EPageMode.Review,
              toEntity: toEntityId,
            });
            window.location.href = `${href}`;
          } else {
            captureError(
              new Error(
                'Could not compute full tagPrimaryKey from tag_id and security key. Cannot open tag detail review page.',
              ),
            );
          }

          history.push(
            updateDetailPageLocationDescriptor(search, {
              mode: EPageMode.Review,
            }),
          );
        },
        cancelText: fullTagPrimaryKey === undefined ? undefined : 'OK',
      });
    } else {
      modal.destroy();
    }
  }, [
    floatOverId,
    history,
    id,
    isValidateAndSubmitSuccessful,
    modal,
    search,
    security_key,
    setFloatOverContent,
    skipValidation,
    successMessage,
    tag_id,
    timeZone,
    toEntityId,
    validateAndSubmitActionState,
  ]);

  const handleClick = () => {
    if (isEmptyValue(floatOverContent) || floatOverId !== id) {
      const { height, x, y } = buttonRef.current.getBoundingClientRect();

      setFloatOverId(id);
      setFloatOverDefaultPosition({
        placement: EFloatOverPlacement.Left,
        x,
        y: y + height + STANDARD_SPACING_VALUE,
      });
      setFloatOverMaximumHeight(PANEL_MAXIMUM_HEIGHT);
      setFloatOverUseDragPanel(true);
      setFloatOverContent(FloatOverContent);
    } else if (floatOverId === id) {
      setFloatOverContent(null);
    }
  };

  return (
    <Tooltip title={TITLE}>
      <IconButton
        buttonRef={buttonRef}
        encodedPermissionsId={encodedPermissionsId}
        icon={<ValidateOrSubmitNewTagIcon />}
        isDisabled={isDisabled}
        onClick={handleClick}
      />
    </Tooltip>
  );
};

export default ValidateOrSubmitNewTag;
