import { ExpandableConfig } from 'antd/lib/table/interface';
import { AxiosResponse } from 'axios';
import DataTable, {
  IDataTableProps,
} from 'components/molecules/DataTable/DataTable';
import {
  generateRequestsDataTableExpandable,
  getAuditForTransactionStatus,
  getCustomComponents,
  getRequestsColumns,
} from 'components/organisms/RequestsInformationDataTable/helpers';
import {
  CURRENT_PENDING_REQUEST_ID,
  CURRENT_REQUEST_ID,
} from 'constants/Detail';
import {
  VIEW_DATA_TABLE_CENTERED_CONTENT,
  VIEW_DATA_TABLE_SHARED_STYLES,
} from 'constants/styles';
import { DATE_TIME_FORMAT } from 'constants/time';
import { ERequestType } from 'enums/ETag';
import usePermissions from 'hooks/usePermissions';
import { IThemedProps } from 'interfaces/Component';
import {
  IApprovalHistoryRecord,
  IApprovalHistoryResponse,
  IRequestDataSet,
  IRequestsDataSet,
} from 'interfaces/Detail';
import {
  IETagAuditStatuses,
  IETagAuditStatusesResponse,
} from 'interfaces/ETag';
import { IToEntity } from 'interfaces/ToEntity';
import { IViewDataTableColumn } from 'interfaces/View';
import { TableComponents } from 'rc-table/lib/interface';
import { HTMLAttributes, useCallback, useMemo, useState } from 'react';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import { useDispatch } from 'react-redux';
import { detailSetETagDetailSelectedRequestKey } from 'reduxes/Detail/actions';
import {
  IDetailTransactionStatus,
  IDetailTransactionStatuses,
} from 'reduxes/Detail/types';
import { requestAudit } from 'services/agent/tags/requests';
import { retrieveApprovalHistory } from 'services/approval/approval';
import styled from 'styled-components';
import { TTimeZone } from 'types/DateTime';
import useAsyncEffect from 'use-async-effect';
import { getRequestKey, requestsSorter } from 'utils/detail';
import { captureError } from 'utils/error';
import { encodeIds, isSuccessStatus } from 'utils/general';
import { alternatingExpandableTableRowBackground } from 'utils/styles';
import { toFormattedDateTimeString } from 'utils/time';
import { ZonedDateTime } from 'utils/zonedDateTime';

// Specialize the DataTable component
const ViewDataTable = styled(
  (
    props: IDataTableProps<
      IViewDataTableColumn<IRequestsDataSet>,
      IRequestsDataSet
    > &
      IThemedProps,
  ) =>
    DataTable<IViewDataTableColumn<IRequestsDataSet>, IRequestsDataSet>(props),
)`
  ${VIEW_DATA_TABLE_SHARED_STYLES}
  ${VIEW_DATA_TABLE_CENTERED_CONTENT}
  ${(props) => alternatingExpandableTableRowBackground(props)}
`;

interface IRequestsInformationDataTableProps {
  isUnconstrained: boolean;
  selectedRequestKey: string;
  tag_primary_key: string | undefined;
  toEntity: IToEntity | null;
  timeZone: TTimeZone;
  transactionStatuses: IDetailTransactionStatuses[];
  fromSummary?: boolean;
  isLoading?: boolean;
}

const RequestsInformationDataTable = ({
  isUnconstrained,
  selectedRequestKey,
  tag_primary_key,
  toEntity,
  timeZone,
  transactionStatuses,
  fromSummary,
  isLoading,
}: IRequestsInformationDataTableProps): JSX.Element => {
  const dispatch = useDispatch();
  const { currentTheme } = useThemeSwitcher();
  const approvalColumnPermissions = usePermissions(
    encodeIds(['auditDataTable', 'approvalColumns'], toEntity?.to_entity),
  );

  const requestsColumns: IViewDataTableColumn<IRequestsDataSet>[] = useMemo(
    () => getRequestsColumns(isUnconstrained),
    [isUnconstrained],
  );

  const setSelectedRequestKey = useCallback(
    (selectedRequestKey: string) => {
      if (fromSummary) {
        dispatch(
          detailSetETagDetailSelectedRequestKey(
            selectedRequestKey,
            toEntity,
            tag_primary_key,
          ),
        );
      } else {
        dispatch(detailSetETagDetailSelectedRequestKey(selectedRequestKey));
      }
    },
    [dispatch, fromSummary, toEntity, tag_primary_key],
  );

  const onRow = useCallback(
    (record: IRequestsDataSet): HTMLAttributes<HTMLElement> => ({
      onClick: () => {
        if (
          record.correction_id === null ||
          record.correction_id === 0 ||
          record.correction_id === 1
        ) {
          setSelectedRequestKey(getRequestKey(record));
        }
      },
    }),
    [setSelectedRequestKey],
  );

  const rowClassName = useCallback(
    (record: IRequestsDataSet): string =>
      record.correction_id !== null &&
      record.correction_id !== 0 &&
      record.correction_id !== 1
        ? 'unselectable'
        : getRequestKey(record) === selectedRequestKey
        ? 'selected'
        : '',
    [selectedRequestKey],
  );
  const [auditData, setAuditData] = useState<IETagAuditStatuses[]>([]);
  const [approvalHistoryData, setApprovalHistoryData] = useState<
    IApprovalHistoryRecord[]
  >([]);
  const customComponents: TableComponents<IRequestsDataSet> = useMemo(
    getCustomComponents,
    [],
  );

  useAsyncEffect(async () => {
    if (tag_primary_key && toEntity && transactionStatuses.length > 0) {
      try {
        const auditResponse: AxiosResponse<IETagAuditStatusesResponse> =
          await requestAudit(toEntity.to_entity, tag_primary_key);

        if (!isSuccessStatus(auditResponse.status)) {
          throw new Error(auditResponse.data.errorMessage!);
        }

        const auditData: IETagAuditStatuses[] = auditResponse.data.response;
        setAuditData(auditData);
      } catch (error: any) {
        captureError(error);
      }
    }
  }, [transactionStatuses]);

  useAsyncEffect(async () => {
    if (
      tag_primary_key &&
      toEntity &&
      transactionStatuses.length > 0 &&
      approvalColumnPermissions.isDisplayable
    ) {
      try {
        const historyResponse: AxiosResponse<IApprovalHistoryResponse> =
          await retrieveApprovalHistory(toEntity.to_entity, tag_primary_key);

        if (!isSuccessStatus(historyResponse.status)) {
          throw new Error(historyResponse.data.errorMessage!);
        }

        const historyData: IApprovalHistoryRecord[] =
          historyResponse.data.response;
        setApprovalHistoryData(historyData);
      } catch (error: any) {
        captureError(error);
      }
    }
  }, [transactionStatuses]);

  const requestsData: IRequestsDataSet[] = useMemo(() => {
    const requestsData: IRequestsDataSet[] = [];
    for (const detailTransactionStatuses of transactionStatuses) {
      requestsData.push({
        audit: [
          getAuditForTransactionStatus(
            detailTransactionStatuses,
            auditData,
            approvalHistoryData,
            timeZone,
          ),
        ],
        actOnByTime:
          detailTransactionStatuses.act_on_by_time === null
            ? null
            : ZonedDateTime.parseIso(
                detailTransactionStatuses.act_on_by_time,
                timeZone,
              ).format(DATE_TIME_FORMAT),
        contact_info:
          detailTransactionStatuses.contact_info === null
            ? null
            : { ...detailTransactionStatuses.contact_info },
        correction_id: detailTransactionStatuses.correction_id,
        notes: detailTransactionStatuses.notes,
        request_id: detailTransactionStatuses.request_id,
        requestor:
          detailTransactionStatuses.requestor === null
            ? null
            : { ...detailTransactionStatuses.requestor },
        request_timestamp: detailTransactionStatuses.request_timestamp,
        resolution_status: detailTransactionStatuses.resolution_status,
        statuses:
          detailTransactionStatuses.statuses === null
            ? []
            : detailTransactionStatuses.statuses.map(
                (
                  detailTransactionStatus: IDetailTransactionStatus,
                ): IRequestDataSet => ({
                  approval_status: detailTransactionStatus.approval_status,
                  approval_status_type:
                    detailTransactionStatus.approval_status_type,
                  approvalTimestamp:
                    detailTransactionStatus.approval_timestamp === null
                      ? null
                      : toFormattedDateTimeString(
                          detailTransactionStatus.approval_timestamp,
                          timeZone,
                        ),
                  approver_notes: detailTransactionStatus.approver_notes,
                  delivery_status: detailTransactionStatus.delivery_status,
                  entity_code: detailTransactionStatus.entity_code,
                  entity_type: detailTransactionStatus.entity_type,
                }),
              ),
        ui_transaction_message_type:
          detailTransactionStatuses.ui_transaction_message_type,
      });
    }

    // Sort by request id and correction id
    requestsData.sort(requestsSorter);

    // We use a special current pending request to allow for inclusion of
    // pending info.
    requestsData.unshift({
      audit: [],
      actOnByTime: null,
      contact_info: null,
      correction_id: null,
      notes: null,
      request_id: CURRENT_PENDING_REQUEST_ID,
      requestor: null,
      request_timestamp: null,
      resolution_status: null,
      statuses: [],
      ui_transaction_message_type: ERequestType.CurrentPendingLevel,
    });

    // We use a special current level request to allow for viewing latest info.
    requestsData.unshift({
      audit: [],
      actOnByTime: null,
      contact_info: null,
      correction_id: null,
      notes: null,
      request_id: CURRENT_REQUEST_ID,
      requestor: null,
      request_timestamp: null,
      resolution_status: null,
      statuses: [],
      ui_transaction_message_type: ERequestType.CurrentLevel,
    });

    return requestsData;
  }, [approvalHistoryData, auditData, timeZone, transactionStatuses]);

  const requestsDataTableExpandable: ExpandableConfig<IRequestsDataSet> =
    useMemo(
      () =>
        generateRequestsDataTableExpandable(
          isUnconstrained,
          toEntity?.to_entity,
        ),
      [isUnconstrained, toEntity],
    );

  return (
    <ViewDataTable
      className='requests-information-data-table'
      columns={requestsColumns}
      components={customComponents}
      currentTheme={currentTheme!}
      expandable={requestsDataTableExpandable}
      data={requestsData}
      onRow={onRow}
      pagination={false}
      rowClassName={rowClassName}
      rowKey={getRequestKey}
      tableLayout='fixed'
      isLoading={isLoading}
    />
  );
};

export default RequestsInformationDataTable;
