import Button from 'components/atoms/Button/Button';
import SeparatedRowLayout from 'components/atoms/SeparatedRowLayout/SeparatedRowLayout';
import Tooltip from 'components/molecules/Tooltip/Tooltip';
import { TO_ENTITY_TAG_WIDTH_VALUE } from 'constants/styles';
import useUserInfo from 'hooks/useUserInfo';
import { IToEntity } from 'interfaces/ToEntity';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { userSetSelectedToEntity } from 'reduxes/User/actions';
import styled from 'styled-components';
import { TToEntityId } from '../../../types/ToEntity';

interface IProps {
  filteredToEntities?: IToEntity[];
  onConfirmBeforeChange?: () => boolean;
  wrapElements?: boolean;
  setToEntityId?: (toEntityId: TToEntityId) => void;
  setShowTenantConfig?: (showTenantConfig: boolean) => void;
}

const StyledButton = styled(Button)`
  width: ${TO_ENTITY_TAG_WIDTH_VALUE}px;
`;

/**
 * A version of ToEntitySelection which reads and writes
 * to the global redux store value state.user.selectedToEntity.
 */
const UserToEntitySelection = ({
  filteredToEntities,
  onConfirmBeforeChange,
  wrapElements,
  setToEntityId,
  setShowTenantConfig,
}: IProps): JSX.Element => {
  const { selectedToEntity, toEntities } = useUserInfo();
  const dispatch = useDispatch();
  const adjustedToEntities: IToEntity[] = filteredToEntities ?? toEntities;

  const setSelectedToEntity = useCallback(
    (selectedToEntity: IToEntity | undefined) => {
      if (onConfirmBeforeChange !== undefined) {
        onConfirmBeforeChange() &&
          dispatch(userSetSelectedToEntity({ selectedToEntity }));
        return;
      }

      dispatch(userSetSelectedToEntity({ selectedToEntity }));

      if (setToEntityId && selectedToEntity?.to_entity) {
        setToEntityId(selectedToEntity.to_entity);
      }
    },
    [onConfirmBeforeChange, dispatch, setToEntityId],
  );

  useEffect(() => {
    if (
      adjustedToEntities.length === 1 &&
      adjustedToEntities[0] !== selectedToEntity
    ) {
      setSelectedToEntity(adjustedToEntities[0]);
    }
  }, [adjustedToEntities, selectedToEntity, setSelectedToEntity]);

  const handleClick = (toEntity: IToEntity) => {
    setSelectedToEntity(toEntity);
    if (setShowTenantConfig) {
      setShowTenantConfig(false);
    }
  };

  return (
    <SeparatedRowLayout>
      {adjustedToEntities.map((toEntity: IToEntity) =>
        selectedToEntity?.to_entity === toEntity.to_entity ? (
          <Tooltip
            key={'tooltip' + toEntity.to_entity}
            title={`Currently Selected Entity is ${toEntity.entity_code} (${toEntity.to_entity})`}
          >
            <StyledButton
              key={'button' + toEntity.to_entity}
              label={toEntity.entity_code}
              onClick={() => handleClick(toEntity)}
              isPrimary={true}
            />
          </Tooltip>
        ) : (
          <Tooltip
            key={'tooltip' + toEntity.to_entity}
            title={`Select Entity ${toEntity.entity_code} (${toEntity.to_entity})`}
          >
            <StyledButton
              key={toEntity.to_entity}
              label={toEntity.entity_code}
              onClick={() => handleClick(toEntity)}
              isPrimary={false}
            />
          </Tooltip>
        ),
      )}
    </SeparatedRowLayout>
  );
};

export default UserToEntitySelection;
