import {
  CaretDownOutlined,
  CaretUpOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import IconButton from 'components/atoms/IconButton/IconButton';
import AdHocFilter from 'components/molecules/AdHocFilter/AdHocFilter';
import Tooltip from 'components/molecules/Tooltip/Tooltip';
import {
  BUTTON_ICON_DIMENSIONS,
  CELL_PADDING_VALUE,
  COLUMN_STICKY_LEFT_OFFSET_Z_INDEX,
  COLUMN_STICKY_RIGHT_OFFSET_Z_INDEX,
  HEADER_CELL_MIN_HEIGHT_VALUE,
  HIGHLIGHT_BLUE,
  INPUT_EXPAND_OVER_Z_INDEX,
  INPUT_HEIGHT_VALUE,
} from 'constants/styles';
import {
  ETagSortingContext,
  IETagSortingContext,
} from 'contexts/ETagSorting/ETagSorting';
import { EColumnPosition } from 'enums/ETag';
import { ESortDirection } from 'enums/Sort';
import usePrevious from 'hooks/usePrevious';
import { IThemedProps } from 'interfaces/Component';
import { IETagAdHocFilters, IETagColumnData } from 'interfaces/ETag';
import { IColumnSort } from 'interfaces/Summary';
import {
  CSSProperties,
  HTMLAttributes,
  memo,
  MutableRefObject,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import styled from 'styled-components';
import { TTimeZone } from 'types/DateTime';
import {
  TETagDataArrayDirectionalSorter,
  TETagDataArraySorter,
} from 'types/ETag';
import { isEmptyValue, shallowObjectCompare } from 'utils/general';
import { textColour } from 'utils/styles';

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  width: 100%;
`;

const DisplayNameFiller = styled.div`
  height: 1px;
`;

interface ISortButtonProps {
  highlightColour?: string;
}

const getSharedSortButtonStyles = (props: ISortButtonProps) => `
  color: ${
    props.highlightColour === undefined ? 'inherit' : props.highlightColour
  };
`;

const ClearAllSortIcon = styled(CloseCircleOutlined)`
  ${BUTTON_ICON_DIMENSIONS}
`;

const ClearAllSortButton = styled(IconButton)<ISortButtonProps>`
  ${(props) => getSharedSortButtonStyles(props)}
`;

const SortActions = styled.span`
  position: relative;
`;

const HeaderCellFullTitle = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-height: ${HEADER_CELL_MIN_HEIGHT_VALUE}px;
  padding: 2px 4px;
  width: 100%;
`;

const ArrowButton = styled(IconButton)<ISortButtonProps>`
  ${(props) => getSharedSortButtonStyles(props)}

  height: 14px;
  width: 14px;
`;

const CloseButton = styled(IconButton)<ISortButtonProps>`
  ${(props) => getSharedSortButtonStyles(props)}

  height: 10px;
  width: 10px;

  > span {
    font-size: 10px;
  }
`;

const SortOrder = styled.span<IThemedProps>`
  color: ${(props) => textColour(props)};
  font-size: 11px;
  height: 17px;
  cursor: default;
  position: absolute;
  top: -13px;
`;

const Title = styled.div`
  white-space: pre;
`;

export interface IHeaderCellProps
  extends IETagColumnData,
    HTMLAttributes<HTMLElement> {
  adHocFilters: IETagAdHocFilters;
  columnIndex: number;
  defaultSort?: IColumnSort;
  isPrimaryColumn: boolean;
  sort?: TETagDataArrayDirectionalSorter;
  timeZone: TTimeZone;
}

// HeaderCell is responsible for
// * Rendering a custom cell to show a column's displayName
// * Provide the ability to set up a default sort - in order to do this,
//   we explictly track changes to the defaultSort by keeping local state
//   of the previousDefaultSort and checking when they differ. Since we
//   don't want the defaultSort to overwrite a user's adjustments, we
//   MUST make sure we only allow user adjustments once previousDefaultSort
//   and defaultSort are the same, signalling that it has not changed.
// * Allow the user to adjust or remove a sort - as mention above, this MUST
//   only happen once we have determined that the defaultSort is not changing.
// * Allow the user to adjust a filter - these are ad hoc filters which are
//   NOT retained between screen configurations nor browser sessions.
const HeaderCell = (props: IHeaderCellProps): JSX.Element => {
  const { currentTheme } = useThemeSwitcher();
  const {
    arraySorters,
    addArraySorter,
    clearArraySorters,
    removeArraySorter,
    replaceArraySorter,
    setDefaultArraySorter,
  } = useContext<IETagSortingContext>(ETagSortingContext);
  const thRef =
    useRef<HTMLTableHeaderCellElement>() as MutableRefObject<HTMLTableHeaderCellElement>;
  const divRef = useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;
  const {
    adHocFilters,
    className,
    columnIndex,
    dataIndex,
    defaultSort,
    displayName,
    fixed,
    isPrimaryColumn,
    minColumnWidth,
    sort,
    style,
    timeZone,
    type,
  } = props;
  const [selectedSort, setSelectedSort] = useState<
    TETagDataArraySorter | undefined
  >();
  const [sortDirection, setSortDirection] = useState<ESortDirection>(
    ESortDirection.None,
  );
  const [sortOrder, setSortOrder] = useState<number | undefined>();
  const previousArraySorters: TETagDataArraySorter[] | undefined =
    usePrevious(arraySorters);
  const previousDefaultSort: IColumnSort | undefined = usePrevious(defaultSort);
  // We have to exlicitly provide access to setting the previousSelectedSort
  // value in order to allow us to reset its value when we are setting
  // defaultSorts.
  const [previousSelectedSort, setPreviousSelectedSort] = useState<
    TETagDataArraySorter | undefined
  >();
  // This value is used to ensure that the AdHocFilter component is correctly
  // aligned to the bottom of the table header row. Since the header is
  // dynamically calculated by the browser based on its contents, we have to
  // get the calculated height and make our adjustment to the filter to allow
  // us to pin it to the bottom of the table header row.
  const [filterAlignmentOffset, setFilterAlignmentOffset] = useState<number>(0);

  useEffect(() => {
    if (thRef.current && divRef.current) {
      setFilterAlignmentOffset(
        -(
          (thRef.current.getBoundingClientRect().height -
            divRef.current.getBoundingClientRect().height -
            INPUT_HEIGHT_VALUE) /
            2 -
          CELL_PADDING_VALUE
        ),
      );
    }
  }, [divRef, sort, thRef]);

  useEffect(() => {
    // When we capture a clear of the arraySorters, reset all component state
    if (previousArraySorters !== arraySorters && arraySorters.length === 0) {
      setPreviousSelectedSort(undefined);
      setSelectedSort(undefined);
      setSortDirection(ESortDirection.None);
      setSortOrder(undefined);
    }
  }, [arraySorters, previousArraySorters]);

  useEffect(() => {
    if (previousDefaultSort !== defaultSort) {
      if (
        defaultSort === undefined ||
        defaultSort.sortDirection === ESortDirection.Disabled ||
        sort === undefined
      ) {
        // Only clear component state and don't clear arraySorter because we
        // cannot guarantee the order of clears in arraySorter when other
        // components are also wanting to clear/set their previous default sort.
        setSortDirection(ESortDirection.None);
        setPreviousSelectedSort(undefined);
        setSelectedSort(undefined);
      } else {
        const newSort: TETagDataArraySorter = sort(
          defaultSort.sortDirection === ESortDirection.Ascending,
        );

        setSortDirection(defaultSort.sortDirection);

        // Setting arraySorter assumes it's safe to directly assign within
        // arraySorter, since we don't know the order with which the
        // arraySorter will clear prior items, we just overwrite it.
        // sortOrder is 1 indexed (for human readability in the config) so we
        // need to convert to 0 index for the array.
        // Additionally, unless we have a disabled sort, we can use ! to assert
        // that there will always be a sortOrder.
        setDefaultArraySorter(newSort, defaultSort.sortOrder! - 1);

        setPreviousSelectedSort(() => newSort);
        setSelectedSort(() => newSort);
      }
    }
  }, [defaultSort, previousDefaultSort, setDefaultArraySorter, sort]);

  useEffect(() => {
    if (
      previousDefaultSort === defaultSort &&
      previousSelectedSort !== selectedSort
    ) {
      if (previousSelectedSort === undefined && selectedSort !== undefined) {
        addArraySorter(selectedSort);
        setPreviousSelectedSort(() => selectedSort);
      } else if (
        previousSelectedSort !== undefined &&
        selectedSort === undefined
      ) {
        removeArraySorter(previousSelectedSort);
        setPreviousSelectedSort(() => selectedSort);
      } else if (
        previousSelectedSort !== undefined &&
        selectedSort !== undefined
      ) {
        replaceArraySorter(previousSelectedSort, selectedSort);
        setPreviousSelectedSort(() => selectedSort);
      }
    }
  }, [
    addArraySorter,
    defaultSort,
    previousDefaultSort,
    previousSelectedSort,
    removeArraySorter,
    replaceArraySorter,
    selectedSort,
  ]);

  useEffect(() => {
    if (previousDefaultSort === defaultSort) {
      if (selectedSort === undefined || arraySorters.length === 0) {
        setSortDirection(ESortDirection.None);
        setSortOrder(undefined);
      } else {
        const index: number = arraySorters.indexOf(selectedSort);
        // Convert to 1 indexed for human readability
        setSortOrder(index === -1 ? undefined : index + 1);
      }
    }
  }, [arraySorters, defaultSort, previousDefaultSort, selectedSort]);

  const handleSortAdd = (sortDirection: ESortDirection) => () => {
    setSelectedSort(
      (
        previousSelectedSort: TETagDataArraySorter | undefined,
      ): TETagDataArraySorter | undefined => {
        setSortDirection(sortDirection);

        setPreviousSelectedSort(() => previousSelectedSort);

        return sort === undefined
          ? undefined
          : sort(sortDirection === ESortDirection.Ascending);
      },
    );
  };

  const handleSortRemove = () => {
    setSelectedSort(
      (previousSelectedSort: TETagDataArraySorter | undefined): undefined => {
        setSortDirection(ESortDirection.None);

        setPreviousSelectedSort(() => previousSelectedSort);

        return undefined;
      },
    );
  };

  // This value allows left to right prioritising of HeaderCells. We adjust this
  // dynamically based on the position property to account for whether or not a
  // colunn is 'fixed' or not i.e. it has position set to sticky. We also use
  // this value within the AdHocFilter to further prioritise expanding input
  // elements so that they correctly lie on top of each other on a left to right
  // basis. Of note is that we further distinguish between fixed left and fixed
  // right such that fixed right has a higher prority. This is to match the same
  // priority within the Ant Design table component itself.
  const cascadingZIndex: number =
    INPUT_EXPAND_OVER_Z_INDEX - (columnIndex === undefined ? 0 : columnIndex);

  return (
    <th
      className={className}
      ref={thRef}
      style={{
        ...style,
        minWidth:
          minColumnWidth === undefined ? undefined : `${minColumnWidth}px`,
        zIndex:
          cascadingZIndex +
          (style?.position === 'sticky'
            ? fixed === EColumnPosition.Right
              ? COLUMN_STICKY_RIGHT_OFFSET_Z_INDEX
              : COLUMN_STICKY_LEFT_OFFSET_Z_INDEX
            : 0),
      }}
    >
      <Container ref={divRef}>
        {isPrimaryColumn ? (
          <Tooltip placement='topLeft' title='Clear All Column Sorts'>
            <ClearAllSortButton
              icon={<ClearAllSortIcon />}
              isContained={true}
              noBorder={true}
              onClick={clearArraySorters}
              transparentBackground={true}
            />
          </Tooltip>
        ) : null}
        {defaultSort?.sortDirection === ESortDirection.Disabled ? (
          isEmptyValue(displayName) ? (
            <DisplayNameFiller />
          ) : (
            <Title>{displayName}</Title>
          )
        ) : (
          <HeaderCellFullTitle>
            <Title>{displayName}</Title>
            <SortActions className='ant-table-column-sorter ant-table-column-sorter-full'>
              <span className='ant-table-column-sorter-inner'>
                <Tooltip title='Sort Column in Ascending Order'>
                  <ArrowButton
                    highlightColour={
                      sortDirection === ESortDirection.Ascending
                        ? HIGHLIGHT_BLUE
                        : undefined
                    }
                    icon={<CaretUpOutlined />}
                    isContained={true}
                    noBorder={true}
                    onClick={handleSortAdd(ESortDirection.Ascending)}
                    transparentBackground={true}
                  />
                </Tooltip>
                <Tooltip title='Remove Sort'>
                  <CloseButton
                    icon={<CloseCircleOutlined />}
                    isContained={true}
                    noBorder={true}
                    onClick={handleSortRemove}
                    transparentBackground={true}
                  />
                </Tooltip>
                <Tooltip title='Sort Column in Descending Order'>
                  <ArrowButton
                    highlightColour={
                      sortDirection === ESortDirection.Descending
                        ? HIGHLIGHT_BLUE
                        : undefined
                    }
                    icon={<CaretDownOutlined />}
                    isContained={true}
                    noBorder={true}
                    onClick={handleSortAdd(ESortDirection.Descending)}
                    transparentBackground={true}
                  />
                </Tooltip>
                {sortOrder === undefined ? null : (
                  <SortOrder currentTheme={currentTheme!}>
                    <Tooltip title='Sort Level'>{sortOrder}</Tooltip>
                  </SortOrder>
                )}
              </span>
            </SortActions>
          </HeaderCellFullTitle>
        )}
      </Container>
      <AdHocFilter
        adHocFilters={adHocFilters}
        columnType={type}
        dataIndex={dataIndex}
        expandIndex={cascadingZIndex}
        showClearAll={isPrimaryColumn}
        style={{ bottom: filterAlignmentOffset }}
        timeZone={timeZone}
      />
    </th>
  );
};

export default memo(
  HeaderCell,
  (prevProps: IHeaderCellProps, nextProps: IHeaderCellProps): boolean =>
    shallowObjectCompare<IHeaderCellProps>(prevProps, nextProps, [
      'children',
      'expandTo',
      'style',
    ]) &&
    ((prevProps.style === undefined && nextProps.style === undefined) ||
      (prevProps.style !== undefined &&
        nextProps.style !== undefined &&
        shallowObjectCompare<CSSProperties>(prevProps.style, nextProps.style))),
);
