import ErrorMessage from 'components/atoms/ErrorMessage/ErrorMessage';
import InfoMessage from 'components/molecules/ValidationMessagesList/InfoMessage';
import WarningMessage from 'components/molecules/ValidationMessagesList/WarningMessage';
import { ESeverity } from 'enums/General';
import { IValidationMessage } from 'interfaces/Component';
import styled from 'styled-components';

const Wrapper = styled.div``;

interface IValidationMessagesListProps {
  className?: string;
  messageMaxWidth?: string;
  validationMessages: IValidationMessage[];
}

const ValidationMessagesList = (
  props: IValidationMessagesListProps,
): JSX.Element => {
  const { className, messageMaxWidth, validationMessages } = props;

  return (
    <Wrapper className={className}>
      {validationMessages.length === 0 ? null : (
        <ul>
          {validationMessages.map(
            (
              validationMessage: IValidationMessage,
              index: number,
            ): JSX.Element => (
              <li key={String(index)}>
                {validationMessage.severity === ESeverity.Error ? (
                  <ErrorMessage maxWidth={messageMaxWidth}>
                    {validationMessage.message}
                  </ErrorMessage>
                ) : validationMessage.severity === ESeverity.Warning ? (
                  <WarningMessage maxWidth={messageMaxWidth}>
                    {validationMessage.message}
                  </WarningMessage>
                ) : validationMessage.severity === ESeverity.Info ? (
                  <InfoMessage maxWidth={messageMaxWidth}>
                    {validationMessage.message}
                  </InfoMessage>
                ) : null}
              </li>
            ),
          )}
        </ul>
      )}
    </Wrapper>
  );
};

export default ValidationMessagesList;
