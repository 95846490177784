import MiscInfoEditable, {
  IMiscInfoEditableProps,
} from 'components/molecules/MiscInfoEditable/MiscInfoEditable';
import { IETagTransmissionAllocation } from 'interfaces/ETag';
import { IMiscInfo } from 'interfaces/General';
import { useMemo } from 'react';
import styled from 'styled-components';

const MiscInfoContainer = styled.div`
  width: 400px;
`;

interface IExpandedMiscInfoEditableProps
  extends Omit<IMiscInfoEditableProps, 'data'> {
  transmissionAllocation: IETagTransmissionAllocation;
}

const ExpandedMiscInfoEditable = (
  props: IExpandedMiscInfoEditableProps,
): JSX.Element => {
  const {
    hasChanged,
    initialEditKey,
    isDisabled,
    onAdd,
    onRemove,
    tokenInputColumnConfig,
    transmissionAllocation,
    valueInputColumnConfig,
  } = props;

  const data: IMiscInfo[] = useMemo(
    () =>
      transmissionAllocation.misc_infos === null
        ? []
        : transmissionAllocation.misc_infos,
    [transmissionAllocation],
  );

  return (
    <MiscInfoContainer>
      <MiscInfoEditable
        data={data}
        hasChanged={hasChanged}
        initialEditKey={initialEditKey}
        isDisabled={isDisabled}
        onAdd={onAdd}
        onRemove={onRemove}
        tokenInputColumnConfig={tokenInputColumnConfig}
        valueInputColumnConfig={valueInputColumnConfig}
      />
    </MiscInfoContainer>
  );
};

export default ExpandedMiscInfoEditable;
