import EditableDataTable from 'components/molecules/EditableDataTable/EditableDataTable';
import { generateMarketSegmentColumns } from 'components/organisms/MarketPathReview/helpers';
import useExpandableMiscInfos from 'hooks/useExpandableMiscInfos';
import { IViewDataTableColumn } from 'interfaces/View';
import { useCallback, useMemo } from 'react';
import { IDetailMarketSegment } from 'reduxes/Detail/types';

interface IMarketPathReviewProps {
  isExpandable?: boolean;
  isUnconstrained?: boolean;
  marketSegments: IDetailMarketSegment[];
}

const MarketPathReview = ({
  isExpandable,
  isUnconstrained = false,
  marketSegments,
}: IMarketPathReviewProps): JSX.Element => {
  const getRowKey = useCallback(
    (record: IDetailMarketSegment): string =>
      record.market_segment_id.toString(),
    [],
  );

  const { expandableConfig, updateRowMiscInfosExpandedKey } =
    useExpandableMiscInfos(getRowKey, true);

  const handleExpandMiscInfos = useCallback(
    (record: IDetailMarketSegment) => {
      updateRowMiscInfosExpandedKey(record);
    },
    [updateRowMiscInfosExpandedKey],
  );

  const marketSegmentColumns: IViewDataTableColumn<IDetailMarketSegment>[] =
    useMemo(
      () =>
        generateMarketSegmentColumns(
          isUnconstrained,
          isExpandable ? handleExpandMiscInfos : undefined,
        ),
      [handleExpandMiscInfos, isExpandable, isUnconstrained],
    );

  return (
    <EditableDataTable<IDetailMarketSegment>
      columns={marketSegmentColumns}
      data={marketSegments}
      expandable={isExpandable ? expandableConfig : undefined}
      hideExpandIconColumn={true}
      maximumAllowableAdds={0}
      pagination={false}
      rowKey='market_segment_id'
      tableLayout='fixed'
    />
  );
};

export default MarketPathReview;
