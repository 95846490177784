import SeparatedRowLayout from 'components/atoms/SeparatedRowLayout/SeparatedRowLayout';
import { IEditableOption, IOption } from 'interfaces/Component';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { TreeSelect } from 'antd';
import * as React from 'react';
import { IToEntity } from '../../../interfaces/ToEntity';

interface IData {
  value: string;
  title: string;
  key: string;
  children?: IData[];
  disabled?: boolean;
  selectable?: boolean;
}

const StyledTreeSelect = styled(TreeSelect)`
  overflow-y: auto;
`;

interface IProps<T> {
  allowClear?: boolean;
  copyConfiguration?: (configuration: T) => T;
  getOptionLabel?: (option: IOption<T>) => string;
  isDisabled?: boolean;
  label?: string;
  newConfigurationGenerator?: () => T;
  onAddConfigurationOption?: (configurationOption: IEditableOption<T>) => void;
  onChange: (value: T[]) => void;
  options: IOption<T>[] | IEditableOption<T>[];
  optionsMap: Map<string, any[]> | undefined;
  placeholder?: string;
  showSelectionArrowOnly?: boolean;
  showUnsavedIndicator?: boolean;
  value: string | undefined;
  setValue: (value: string) => void;
  valueToUid: (value: T) => string;
  width: number;
  toEntities?: IToEntity[];
}

interface ISelected {
  parent: string;
  label: string;
  filterId: string;
}

interface ITreeExtra {
  preValue: any[];
  triggerValue: Object;
  allCheckedNodes: any[];
}

const MultipleConfigurationSelector = <T extends any>(
  props: IProps<T>,
): JSX.Element => {
  const {
    allowClear,
    isDisabled,
    label,
    onChange,
    optionsMap,
    placeholder,
    toEntities,
    value,
    setValue,
  } = props;

  const [data, setData] = useState<IData[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<ISelected[]>([]);

  useEffect(() => {
    const treeData: IData[] = [];
    if (optionsMap && toEntities) {
      if (toEntities.length > 0) {
        toEntities.forEach((entity) => {
          const filters: any[] | undefined = optionsMap
            .get(entity.to_entity)
            ?.map((option) => {
              return {
                key: option.filter_name,
                value: { filter_id: option.filter_id },
                title: option.filter_name,
                parent: entity.entity_code,
                children: [],
              };
            });
          if (filters && filters.length > 0) {
            treeData.push({
              key: entity.entity_code,
              value: entity.entity_code,
              title: entity.entity_code,
              children: filters,
              disabled: true,
            });
          }
        });
      }
    }
    setData(treeData);
  }, [toEntities, optionsMap]);

  const handleChange = (value: any, labelList: any, extra: ITreeExtra) => {
    if (extra.preValue.length > value.length) {
      const selected: any[] =
        value.length === 0
          ? []
          : selectedKeys
              .slice()
              .filter(
                (key) =>
                  value.filter((val: any) => val.label === key.label).length >
                  0,
              );
      setSelectedKeys(selected);
      setValue(value);
      onChange(value);
    } else {
      if (value.length > 0) {
        const checkedNodeLabel = value
          .slice()
          .filter((v: any) => v.value === extra.triggerValue);
        const parent = extra.allCheckedNodes
          .slice()
          .filter((node: any) => node.key === checkedNodeLabel[0].label);
        const parentKey = parent[0].props.parent;
        const parentKeyExists =
          parentKey &&
          selectedKeys.slice().find((key) => key.parent === parentKey);
        if (parentKeyExists) {
          const children = selectedKeys
            .slice()
            .filter((key) => key.parent === parentKey);
          const selected = value.slice().filter((v: any) => {
            return (
              children.slice().filter((c: any) => c.label === v.label)
                .length === 0
            );
          });
          const selectedPerParent = selectedKeys.filter(
            (key) => key.parent !== parent[0].props.parent,
          );
          selectedPerParent.push({
            parent: parent[0].props.parent,
            label: checkedNodeLabel[0].label,
            filterId: checkedNodeLabel[0].value,
          });
          setSelectedKeys(selectedPerParent);
          setValue(selected);
          onChange(selected);
        } else {
          const currentSelected = selectedKeys;
          currentSelected.push({
            parent: parent[0].props.parent,
            label: checkedNodeLabel[0].label,
            filterId: checkedNodeLabel[0].value,
          });
          setValue(value);
          setSelectedKeys(currentSelected);
          onChange(value);
        }
      } else {
        setValue(value);
        setSelectedKeys([]);
        onChange(value);
      }
    }
  };

  return (
    <SeparatedRowLayout>
      {label === undefined ? null : <div>{label}</div>}
      <StyledTreeSelect
        disabled={isDisabled}
        allowClear={allowClear}
        maxTagTextLength={40}
        multiple={true}
        treeCheckStrictly={true}
        placeholder={placeholder}
        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
        style={{ width: '300px' }}
        treeData={data}
        onChange={handleChange}
        showSearch={false}
        value={value}
      ></StyledTreeSelect>
    </SeparatedRowLayout>
  );
};

export default MultipleConfigurationSelector;
