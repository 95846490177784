import { EDefaultDateRange } from 'enums/Summary';
import { IOption } from 'interfaces/Component';
import { IDateRange } from 'interfaces/Summary';
import { TTimeZone } from 'types/DateTime';
import { defaultDateRangeToDateRange } from 'utils/summary';
import { ZonedDateTime } from 'utils/zonedDateTime';

export const isRangeWithinLimit = (
  range: IDateRange,
  rangeLimitInHours?: number,
): boolean =>
  rangeLimitInHours === undefined
    ? true
    : range.end.diff(range.start, 'hours') <= rangeLimitInHours;

export const listenToMouseDownInElement = (
  element: HTMLElement,
  mouseDownCallback: (isInside: boolean) => void,
) => {
  const onMouseDown = (event: MouseEvent) => {
    mouseDownCallback(element.contains(event.target as HTMLElement));
  };

  window.document.addEventListener('mousedown', onMouseDown);
};

export const injectMouseDownInElementCallback =
  (mouseDownCallback: (isInside: boolean) => void) => (): HTMLElement => {
    const container: HTMLElement = window.document.createElement('div');

    window.document.body.appendChild(container);

    listenToMouseDownInElement(container, mouseDownCallback);

    return container;
  };

export const generateHourOptions = (): IOption<number>[] => {
  const hourOptions: IOption<number>[] = [];
  for (let i: number = 0; i < 24; i += 1) {
    hourOptions.push({
      isDisabled: false,
      label: `${i.toString().padStart(2, '0')}:00`,
      value: i,
    });
  }
  return hourOptions;
};

export const getDateRanges = (timeZone: TTimeZone): IDateRange[] => [
  defaultDateRangeToDateRange(EDefaultDateRange.Today, timeZone),
  defaultDateRangeToDateRange(EDefaultDateRange.Tomorrow, timeZone),
  defaultDateRangeToDateRange(EDefaultDateRange.Yesterday, timeZone),
  defaultDateRangeToDateRange(EDefaultDateRange.TodayTomorrow, timeZone),
  defaultDateRangeToDateRange(EDefaultDateRange.TodayPlus2Days, timeZone),
  defaultDateRangeToDateRange(EDefaultDateRange.TodayPlus3Days, timeZone),
  defaultDateRangeToDateRange(EDefaultDateRange.TodayPlus4Days, timeZone),
];

export const getDateTimeFrom = (
  date: ZonedDateTime,
  hour: number,
): ZonedDateTime => date.startOf('day').withHour(hour);
