import { StopOutlined } from '@ant-design/icons';
import { Dropdown as AntDesignDropdown, Menu as AntDesignMenu } from 'antd';
import Button from 'components/atoms/Button/Button';
import IconButton from 'components/atoms/IconButton/IconButton';
import Input, { IInputProps } from 'components/atoms/Input/Input';
import InputNumber, {
  IInputNumberProps,
} from 'components/atoms/InputNumber/InputNumber';
import DateTimePicker, {
  IDateTimePickerProps,
} from 'components/molecules/DateTimePicker/DateTimePicker';
import DateTimeRangePicker, {
  IDateTimeRangePickerProps,
} from 'components/molecules/DateTimeRangePicker/DateTimeRangePicker';
import InputNumberRange, {
  IInputNumberRangeProps,
} from 'components/molecules/InputNumberRange/InputNumberRange';
import Select, { ISelectProps } from 'components/molecules/Select/Select';
import Tooltip from 'components/molecules/Tooltip/Tooltip';
import { ETAG_AD_HOC_FILTER_MAP } from 'constants/ETag';
import { BOOLEAN_FALSE_LABEL, BOOLEAN_TRUE_LABEL } from 'constants/misc';
import {
  BUTTON_ICON_DIMENSIONS,
  ENUMERATION_DROPDOWN_MAX_HEIGHT_VALUE,
  EXPANDED_INPUT_HEIGHT_VALUE,
  FILTER_BUTTON_WIDTH_VALUE,
  FILTER_LAYOUT_RIGHT_MARGIN_VALUE,
  HIGHLIGHT_BLUE,
  ICON_BUTTON_SIZE_VALUE,
  INPUT_HEIGHT_VALUE,
} from 'constants/styles';
import { DATE_FORMAT, DATE_TIME_FORMAT, TIME_FORMAT } from 'constants/time';
import {
  ETagFilteringContext,
  IETagFilteringContext,
} from 'contexts/ETagFiltering/ETagFiltering';
import { EAdHocFilter, EAdHocFilterConfigurationType } from 'enums/AdHocFilter';
import { EColumnType } from 'enums/ETag';
import usePrevious from 'hooks/usePrevious';
import { IAdHocFilterConfiguration } from 'interfaces/AdHocFilter';
import { IShowTime } from 'interfaces/Component';
import { IETagAdHocFilters, IETagDataSet, IHeData } from 'interfaces/ETag';
import { IIndexable } from 'interfaces/General';
import { MenuInfo } from 'rc-menu/lib/interface';
import {
  ChangeEvent,
  CSSProperties,
  HTMLAttributes,
  useContext,
  useEffect,
  useState,
} from 'react';
import styled from 'styled-components';
import { TGranularity, TTimeZone, TZonedDateTimeRange } from 'types/DateTime';
import {
  TETagAdHocFilterConfiguration,
  TETagDataSetFilter,
  TETagEnumeration,
} from 'types/ETag';
import { isEmptyValue } from 'utils/general';
import { getUTCDateTimeForTimeZone } from 'utils/time';
import { ZonedDateTime } from 'utils/zonedDateTime';

const BOOLEAN_ENUMERATION_DROPDOWN_WIDTH_VALUE = 55;
const ENUMERATION_DROPDOWN_WIDTH_VALUE = 135;

interface ISharedLayoutProps {
  expandedWidth: number;
  positionOffset: number;
  zIndex: number;
}

const trim = (s: string, c: string): string => {
  if (c === ']') {
    c = '\\]';
  }
  if (c === '^') {
    c = '\\^';
  }

  if (c === '\\') {
    c = '\\\\';
  }

  return s.replace(new RegExp('^[' + c + ']+|[' + c + ']+$', 'g'), '');
};

const getSharedLayoutStyles = (props: ISharedLayoutProps) => `
  left: calc(${props.positionOffset}px + ${FILTER_BUTTON_WIDTH_VALUE}px + ${FILTER_LAYOUT_RIGHT_MARGIN_VALUE}px);
  position: absolute;
  top: 0;
  width: calc(100% - ${props.positionOffset}px - ${FILTER_BUTTON_WIDTH_VALUE}px - ${FILTER_LAYOUT_RIGHT_MARGIN_VALUE}px);
  z-index: ${props.zIndex};
`;

const Layout = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;

  > :not(:last-child) {
    margin-right: 1px;
  }
`;

const ClearAllColumnsFilterIcon = styled(StopOutlined)`
  ${BUTTON_ICON_DIMENSIONS}
`;

interface IFilterButtonWrapperProps extends HTMLAttributes<HTMLButtonElement> {
  active?: boolean;
}

const FilterButtonWrapper = styled.span<IFilterButtonWrapperProps>`
  > button {
    font-size: 9px;
    padding: 0;
    width: ${FILTER_BUTTON_WIDTH_VALUE}px;

    ${(props) =>
      props.active
        ? `
        color: ${HIGHLIGHT_BLUE};
        font-weight: bold;
      `
        : ''};
  }
`;

const FilterInput = styled(Input)<ISharedLayoutProps & IInputProps>`
  ${(props) => getSharedLayoutStyles(props)}

  :focus {
    top: ${(INPUT_HEIGHT_VALUE - EXPANDED_INPUT_HEIGHT_VALUE) / 2}px;
    width: ${(props) => props.expandedWidth}px;
  }
`;

// Specialize the Select component
const EnumerationSelect = (props: ISelectProps<TETagEnumeration>) =>
  Select<TETagEnumeration>(props);

const StyledEnumerationSelect = styled(EnumerationSelect)<
  ISharedLayoutProps & ISelectProps<TETagEnumeration>
>`
  ${(props) => getSharedLayoutStyles(props)}

  .ant-select-focused .ant-select-selector {
    top: ${(INPUT_HEIGHT_VALUE - EXPANDED_INPUT_HEIGHT_VALUE) / 2}px;
    width: ${(props) => props.expandedWidth}px;
  }
`;

const StyledDateTimePicker = styled(DateTimePicker)<
  ISharedLayoutProps & IDateTimePickerProps
>`
  ${(props) => getSharedLayoutStyles(props)}

  .ant-picker-focused {
    top: ${(INPUT_HEIGHT_VALUE - EXPANDED_INPUT_HEIGHT_VALUE) / 2}px;
    width: ${(props) => props.expandedWidth}px;
  }
`;

const StyledDateTimeRangePicker = styled(DateTimeRangePicker)<
  ISharedLayoutProps & IDateTimeRangePickerProps
>`
  ${(props) => getSharedLayoutStyles(props)}

  .ant-picker-focused {
    top: ${(INPUT_HEIGHT_VALUE - EXPANDED_INPUT_HEIGHT_VALUE) / 2}px;
    width: ${(props) => props.expandedWidth}px;
  }
`;

const StyledInputNumber = styled(InputNumber)<
  ISharedLayoutProps & IInputNumberProps
>`
  ${(props) => getSharedLayoutStyles(props)}

  .ant-input-number-focused {
    top: ${(INPUT_HEIGHT_VALUE - EXPANDED_INPUT_HEIGHT_VALUE) / 2}px;
    width: ${(props) => props.expandedWidth}px;
  }
`;

const StyledInputNumberRange = styled(InputNumberRange)<
  ISharedLayoutProps & IInputNumberRangeProps
>`
  ${(props) => getSharedLayoutStyles(props)}

  .ant-picker-focused {
    top: ${(INPUT_HEIGHT_VALUE - EXPANDED_INPUT_HEIGHT_VALUE) / 2}px;
    width: ${(props) => props.expandedWidth}px;
  }
`;

interface IProps {
  adHocFilters?: IETagAdHocFilters;
  columnType: EColumnType;
  dataIndex: string;
  expandIndex: number;
  showClearAll?: boolean;
  style?: CSSProperties;
  timeZone: TTimeZone;
}

const AdHocFilter = (props: IProps): JSX.Element => {
  const { arrayFilters, clearArrayFilters, setFilter, unSetFilter } =
    useContext<IETagFilteringContext>(ETagFilteringContext);
  const {
    adHocFilters,
    columnType,
    dataIndex,
    expandIndex,
    showClearAll,
    style,
    timeZone,
  } = props;
  const isDateTime: boolean = columnType === EColumnType.DateTime;
  const dateFilterUnit: TGranularity = isDateTime ? 'minute' : 'day';

  const [
    selectedAdHocFilterConfiguration,
    setSelectedAdHocFilterConfiguration,
  ] = useState<TETagAdHocFilterConfiguration | undefined>(
    adHocFilters === undefined
      ? undefined
      : ETAG_AD_HOC_FILTER_MAP[adHocFilters.filters[0]],
  );
  const [date, setDate] = useState<ZonedDateTime | null>(null);
  const [dates, setDates] = useState<TZonedDateTimeRange>(null);
  const [enumerations, setEnumerations] = useState<TETagEnumeration[]>([]);
  const [_number, setNumber] = useState<number | null>(null);
  const [numbers, setNumbers] = useState<[number | null, number | null] | null>(
    null,
  );
  const [inputValue, setInputValue] = useState<string | undefined>();
  const previousSelectedAdHocFilterConfiguration:
    | TETagAdHocFilterConfiguration
    | undefined = usePrevious(selectedAdHocFilterConfiguration);

  const clearFilterState = () => {
    setDate(null);
    setDates(null);
    setNumber(null);
    setNumbers(null);
    setEnumerations([]);
    setInputValue('');
  };

  const setBlankFilter = (
    eTagAdHocFilterConfiguration: TETagAdHocFilterConfiguration,
  ) => {
    // We use a not null (undefined) assertion for filterString since it's
    // an optional value within the selected filter configuration. Since
    // we set up these filters in the constant ETAG_FILTER_MAP, we know if
    // it has the type EFilterConfiguration.String then it will have a
    // TStringFilter set on the filterString property.
    const eTagDataSetFilter: TETagDataSetFilter = (
      eTagDataSet: IETagDataSet & IIndexable,
    ): boolean => {
      const dataValue: any = eTagDataSet[dataIndex];
      return eTagAdHocFilterConfiguration.filterBlank!(dataValue);
    };
    setFilter(dataIndex, eTagDataSetFilter);
  };

  const setDateFilter = (
    date: ZonedDateTime,
    eTagFilterAdHocConfiguration: TETagAdHocFilterConfiguration,
  ) => {
    // We use a not null (undefined) assertion for filterTwoDateTime
    // since it's an optional value within the selected filter
    // configuration. Since we set up these filters in the constant
    // ETAG_FILTER_MAP, we know if it has the type
    // EFilterConfiguration.Date then it will have a TTwoDateTimeFilter
    // set on the filterTwoDateTime property.

    const eTagDataSetFilter: TETagDataSetFilter = (
      eTagDataSet: IETagDataSet & IIndexable,
    ): boolean => {
      const dataValue: any = eTagDataSet[dataIndex];
      // It's important to note that we expect our filters to work with UTC
      // time values since time values from the backend are in UTC.
      return dataValue === undefined || dataValue === null
        ? false
        : eTagFilterAdHocConfiguration.filterTwoDateTime!(
            getUTCDateTimeForTimeZone(date, timeZone),
            dateFilterUnit,
          )(dataValue);
    };
    setFilter(dataIndex, eTagDataSetFilter);
  };

  const setDatesFilter = (
    lower: ZonedDateTime,
    upper: ZonedDateTime,
    eTagAdHocConfiguration: TETagAdHocFilterConfiguration,
  ) => {
    // We use a not null (undefined) assertion for filterThreeDateTime
    // since it's an optional value within the selected filter
    // configuration. Since we set up these filters in the constant
    // ETAG_FILTER_MAP, we know if it has the type
    // EFilterConfiguration.Dates then it will have a TThreeDateTimeFilter
    // set on the filterThreeDateTime property.
    const eTagDataSetFilter: TETagDataSetFilter = (
      eTagDataSet: IETagDataSet & IIndexable,
    ): boolean => {
      const dataValue: any = eTagDataSet[dataIndex];
      // It's important to note that we expect our filters to work with UTC
      // time values since time values from the backend are in UTC.
      return dataValue === undefined || dataValue === null
        ? false
        : eTagAdHocConfiguration.filterThreeDateTime!(
            getUTCDateTimeForTimeZone(lower, timeZone),
            dateFilterUnit,
          )(getUTCDateTimeForTimeZone(upper, timeZone))(dataValue);
    };
    setFilter(dataIndex, eTagDataSetFilter);
  };

  const setEnumerationsFilter = (
    enumerations: TETagEnumeration[],
    eTagAdHocConfiguration: TETagAdHocFilterConfiguration,
  ) => {
    // We use a not null (undefined) assertion for filterEnumeration
    // since it's an optional value within the selected filter
    // configuration. Since we set up these filters in the constant
    // ETAG_FILTER_OPTIONS_MAP, we know if it has the type
    // EFilterConfiguration.Enumerations then it will have a
    // TEnumerationFilter set on the filterEnumeration property.
    const eTagDataSetFilter: TETagDataSetFilter = (
      eTagDataSet: IETagDataSet & IIndexable,
    ): boolean => {
      const dataValue: any = eTagDataSet[dataIndex];
      return dataValue === undefined || dataValue === null
        ? false
        : eTagAdHocConfiguration.filterEnumeration!(enumerations)(dataValue);
    };
    setFilter(dataIndex, eTagDataSetFilter);
  };

  const setNumberFilter = (
    _number: number,
    eTagAdHocConfiguration: TETagAdHocFilterConfiguration,
  ) => {
    // We use a not null (undefined) assertion for filterTwoNumber
    // since it's an optional value within the selected filter
    // configuration. Since we set up these filters in the constant
    // ETAG_FILTER_MAP, we know if it has the type
    // EFilterConfiguration.Number then it will have a TTwoNumberFilter
    // set on the filterTwoNumber property.
    const eTagDataSetFilter: TETagDataSetFilter = (
      eTagDataSet: IETagDataSet & IIndexable,
    ): boolean => {
      const dataValue: any = eTagDataSet[dataIndex];

      if (dataValue === undefined || dataValue === null) {
        return false;
      }

      // Hour endings use the same number filter but we must map an
      // IHeData structure to the number we are checking against.
      const heData: IHeData | undefined = dataValue as IHeData;
      if (
        heData !== undefined &&
        !isEmptyValue(heData.energy) &&
        heData.energy!.mw !== undefined
      ) {
        return eTagAdHocConfiguration.filterTwoNumber!(_number)(
          heData.energy!.mw,
        );
      }

      return eTagAdHocConfiguration.filterTwoNumber!(_number)(dataValue);
    };
    setFilter(dataIndex, eTagDataSetFilter);
  };

  const setNumbersFilter = (
    lower: number,
    upper: number,
    eTagAdHocConfiguration: TETagAdHocFilterConfiguration,
  ) => {
    // We use a not null (undefined) assertion for filterThreeNumber
    // since it's an optional value within the selected filter
    // configuration. Since we set up these filters in the constant
    // ETAG_FILTER_MAP, we know if it has the type
    // EFilterConfiguration.Numbers then it will have a TThreeNumberFilter
    // set on the filterThreeNumber property.
    const eTagDataSetFilter: TETagDataSetFilter = (
      eTagDataSet: IETagDataSet & IIndexable,
    ): boolean => {
      const dataValue: any = eTagDataSet[dataIndex];

      if (dataValue === undefined || dataValue === null) {
        return false;
      }

      // Hour endings use the same number filter but we must map an
      // IHeData structure to the number we are checking against.
      const heData: IHeData | undefined = dataValue as IHeData;
      if (
        heData !== undefined &&
        !isEmptyValue(heData.energy) &&
        heData.energy!.mw !== undefined
      ) {
        return eTagAdHocConfiguration.filterThreeNumber!(lower)(upper)(
          heData.energy!.mw,
        );
      }

      return eTagAdHocConfiguration.filterThreeNumber!(lower)(upper)(dataValue);
    };
    setFilter(dataIndex, eTagDataSetFilter);
  };

  const setStringFilter = (
    value: string,
    eTagAdHocConfiguration: TETagAdHocFilterConfiguration,
  ) => {
    // We use a not null (undefined) assertion for filterString since it's
    // an optional value within the selected filter configuration. Since
    // we set up these filters in the constant ETAG_FILTER_MAP, we know if
    // it has the type EFilterConfiguration.String then it will have a
    // TStringFilter set on the filterString property.
    const eTagDataSetFilter: TETagDataSetFilter = (
      eTagDataSet: IETagDataSet & IIndexable,
    ): boolean => {
      const dataValue: any = eTagDataSet[dataIndex];
      return dataValue === undefined || dataValue === null
        ? false
        : eTagAdHocConfiguration.filterString!(value)(dataValue);
    };
    setFilter(dataIndex, eTagDataSetFilter);
  };

  useEffect(() => {
    if (
      Object.keys(arrayFilters).length === 0 ||
      selectedAdHocFilterConfiguration?.type ===
        EAdHocFilterConfigurationType.Blank
    ) {
      clearFilterState();
    }

    if (
      previousSelectedAdHocFilterConfiguration !==
        selectedAdHocFilterConfiguration &&
      previousSelectedAdHocFilterConfiguration?.type ===
        EAdHocFilterConfigurationType.Blank &&
      selectedAdHocFilterConfiguration?.type !==
        EAdHocFilterConfigurationType.Blank
    ) {
      unSetFilter(dataIndex);
    }
  }, [
    arrayFilters,
    dataIndex,
    previousSelectedAdHocFilterConfiguration,
    selectedAdHocFilterConfiguration,
    unSetFilter,
  ]);

  const handleFilterSelect = (menuInfo: MenuInfo) => {
    const eTagAdHocConfiguration: TETagAdHocFilterConfiguration =
      ETAG_AD_HOC_FILTER_MAP[menuInfo.key];

    setSelectedAdHocFilterConfiguration(eTagAdHocConfiguration);

    switch (eTagAdHocConfiguration.type) {
      case EAdHocFilterConfigurationType.Blank: {
        setBlankFilter(eTagAdHocConfiguration);
        return;
      }
      case EAdHocFilterConfigurationType.Date: {
        if (date !== null) {
          setDateFilter(date, eTagAdHocConfiguration);
        }
        return;
      }
      case EAdHocFilterConfigurationType.Dates: {
        if (dates !== null && dates[0] !== null && dates[1] !== null) {
          setDatesFilter(dates[0], dates[1], eTagAdHocConfiguration);
        }
        return;
      }
      case EAdHocFilterConfigurationType.Boolean:
      case EAdHocFilterConfigurationType.Enumerations: {
        if (enumerations.length > 0) {
          setEnumerationsFilter(enumerations, eTagAdHocConfiguration);
        }
        return;
      }
      case EAdHocFilterConfigurationType.Number: {
        if (_number !== null) {
          setNumberFilter(_number, eTagAdHocConfiguration);
        }
        return;
      }
      case EAdHocFilterConfigurationType.Numbers: {
        if (numbers !== null && numbers[0] !== null && numbers[1] !== null) {
          setNumbersFilter(numbers[0], numbers[1], eTagAdHocConfiguration);
        }
        return;
      }
      default: {
        if (!isEmptyValue(inputValue)) {
          setStringFilter(inputValue as string, eTagAdHocConfiguration);
        }
        return;
      }
    }
  };

  const FilterMenu: JSX.Element = (
    <AntDesignMenu onClick={handleFilterSelect}>
      {adHocFilters?.filters.map((filter: EAdHocFilter) => (
        <AntDesignMenu.Item key={filter}>
          {ETAG_AD_HOC_FILTER_MAP[filter].description}
        </AntDesignMenu.Item>
      ))}
    </AntDesignMenu>
  );

  const handleDateSelect = (date: ZonedDateTime | null) => {
    setDate(date);

    if (date === null) {
      unSetFilter(dataIndex);
    } else if (
      selectedAdHocFilterConfiguration !== undefined &&
      selectedAdHocFilterConfiguration.type ===
        EAdHocFilterConfigurationType.Date
    ) {
      setDateFilter(date, selectedAdHocFilterConfiguration);
    }
  };

  const handleDatesSelect = (dates: TZonedDateTimeRange) => {
    setDates(dates);

    if (dates === null || dates[0] === null || dates[1] === null) {
      unSetFilter(dataIndex);
    } else if (
      selectedAdHocFilterConfiguration !== undefined &&
      selectedAdHocFilterConfiguration.type ===
        EAdHocFilterConfigurationType.Dates
    ) {
      setDatesFilter(dates[0], dates[1], selectedAdHocFilterConfiguration);
    }
  };

  const handleEnumerationSelect = (enumerations: TETagEnumeration[]) => {
    setEnumerations(enumerations);

    if (enumerations.length === 0) {
      unSetFilter(dataIndex);
    } else if (
      selectedAdHocFilterConfiguration !== undefined &&
      (selectedAdHocFilterConfiguration.type ===
        EAdHocFilterConfigurationType.Boolean ||
        selectedAdHocFilterConfiguration.type ===
          EAdHocFilterConfigurationType.Enumerations)
    ) {
      setEnumerationsFilter(
        [...enumerations],
        selectedAdHocFilterConfiguration,
      );
    }
  };

  const handleNumberInput = (_number: number | undefined) => {
    let validatedNumber: number | null = _number === undefined ? null : _number;

    // This must be a runtime check since the input box can return a string
    // even though we are typing as a number or undefined !!!
    if (typeof _number === 'string') {
      validatedNumber = _number === '' ? null : parseInt(_number, 10);
    }

    setNumber(validatedNumber);

    if (validatedNumber === null) {
      unSetFilter(dataIndex);
    } else if (
      selectedAdHocFilterConfiguration !== undefined &&
      selectedAdHocFilterConfiguration.type ===
        EAdHocFilterConfigurationType.Number
    ) {
      setNumberFilter(validatedNumber, selectedAdHocFilterConfiguration);
    }
  };

  const handleNumbersInput = (numbers: [number | null, number | null]) => {
    setNumbers(numbers);

    if (numbers[0] === null || numbers[1] === null) {
      unSetFilter(dataIndex);
    } else {
      if (
        selectedAdHocFilterConfiguration !== undefined &&
        selectedAdHocFilterConfiguration.type ===
          EAdHocFilterConfigurationType.Numbers
      ) {
        setNumbersFilter(
          numbers[0],
          numbers[1],
          selectedAdHocFilterConfiguration,
        );
      }
    }
  };

  const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
    const value =
      (event.nativeEvent as InputEvent).inputType === 'insertFromPaste'
        ? trim(event.target.value, '"')
        : event.target.value;

    setInputValue(value);

    if (isEmptyValue(value)) {
      unSetFilter(dataIndex);
    } else if (
      selectedAdHocFilterConfiguration !== undefined &&
      selectedAdHocFilterConfiguration.type ===
        EAdHocFilterConfigurationType.String
    ) {
      setStringFilter(value, selectedAdHocFilterConfiguration);
    }
  };

  const getFilterInput = (
    adHocFilterConfiguration: IAdHocFilterConfiguration<TETagEnumeration>,
  ): JSX.Element => {
    const layoutProps = {
      positionOffset: showClearAll
        ? ICON_BUTTON_SIZE_VALUE + FILTER_LAYOUT_RIGHT_MARGIN_VALUE
        : 0,
      shouldExpand: true,
      zIndex: expandIndex,
    };

    let format: string = DATE_FORMAT;
    let showTime: IShowTime | undefined = undefined;
    let dateTimeExpandedWidth: number = 83;
    let dateTimesExpandedWidth: number = 179;

    if (isDateTime) {
      format = DATE_TIME_FORMAT;
      showTime = { format: TIME_FORMAT };
      dateTimeExpandedWidth = 123;
      dateTimesExpandedWidth = 247;
    }

    switch (adHocFilterConfiguration.type) {
      case EAdHocFilterConfigurationType.Date:
        return (
          <Tooltip
            placement='topLeft'
            title={date ? date.format(format) : undefined}
          >
            <StyledDateTimePicker
              allowClear={true}
              format={format}
              onChange={handleDateSelect}
              placeholder=''
              showTime={showTime}
              suffixIcon={null}
              timeZone={timeZone}
              value={date}
              {...layoutProps}
              expandedWidth={dateTimeExpandedWidth}
            />
          </Tooltip>
        );
      case EAdHocFilterConfigurationType.Dates:
        return (
          <Tooltip
            placement='topLeft'
            title={dates
              ?.map((date: ZonedDateTime | null) => date?.format(format))
              .join(' to ')}
          >
            <StyledDateTimeRangePicker
              allowClear={true}
              format={format}
              onChange={handleDatesSelect}
              placeholder={['', '']}
              showTime={showTime}
              suffixIcon={null}
              timeZone={timeZone}
              value={dates}
              {...layoutProps}
              expandedWidth={dateTimesExpandedWidth}
            />
          </Tooltip>
        );
      case EAdHocFilterConfigurationType.Boolean:
      case EAdHocFilterConfigurationType.Enumerations:
        return (
          <Tooltip
            placement='topLeft'
            title={enumerations
              .map((value: TETagEnumeration) => {
                if (value === true) {
                  return BOOLEAN_TRUE_LABEL;
                } else if (value === false) {
                  return BOOLEAN_FALSE_LABEL;
                }
                return value as string;
              })
              .join(', ')}
          >
            <StyledEnumerationSelect
              allowMultiple={true}
              listHeight={ENUMERATION_DROPDOWN_MAX_HEIGHT_VALUE}
              onChangeMultiple={handleEnumerationSelect}
              options={
                adHocFilters?.enumOptions === undefined
                  ? []
                  : adHocFilters.enumOptions
              }
              placeholder=''
              values={enumerations}
              valueToUid={(value: TETagEnumeration): string => value as string}
              {...layoutProps}
              dropdownMatchSelectWidth={
                adHocFilterConfiguration.type ===
                EAdHocFilterConfigurationType.Boolean
                  ? BOOLEAN_ENUMERATION_DROPDOWN_WIDTH_VALUE
                  : ENUMERATION_DROPDOWN_WIDTH_VALUE
              }
              expandedWidth={231}
            />
          </Tooltip>
        );
      case EAdHocFilterConfigurationType.Number:
        return (
          <Tooltip placement='topLeft' title={_number?.toString()}>
            <StyledInputNumber
              hideArrows={true}
              min={0}
              onChange={handleNumberInput}
              preventSpecialCharacters={true}
              step={1}
              value={_number === null ? undefined : _number}
              {...layoutProps}
              expandedWidth={107}
            />
          </Tooltip>
        );
      case EAdHocFilterConfigurationType.Numbers:
        return (
          <Tooltip placement='topLeft' title={numbers?.join(' to ')}>
            <StyledInputNumberRange
              hideArrows={true}
              min={0}
              onChange={handleNumbersInput}
              preventSpecialCharacters={true}
              step={1}
              values={numbers}
              {...layoutProps}
              expandedWidth={157}
            />
          </Tooltip>
        );
      default:
        return (
          <Tooltip placement='topLeft' title={inputValue}>
            <FilterInput
              isDisabled={
                adHocFilterConfiguration.type ===
                EAdHocFilterConfigurationType.Blank
              }
              onChange={handleInput}
              value={inputValue}
              {...layoutProps}
              expandedWidth={231}
            />
          </Tooltip>
        );
    }
  };

  return (
    <Layout style={style}>
      {showClearAll ? (
        <Tooltip placement='topLeft' title='Clear All Column Filters'>
          <IconButton
            icon={<ClearAllColumnsFilterIcon />}
            onClick={clearArrayFilters}
          />
        </Tooltip>
      ) : null}
      {selectedAdHocFilterConfiguration === undefined ? null : (
        <>
          <Tooltip title={selectedAdHocFilterConfiguration.description}>
            <AntDesignDropdown overlay={FilterMenu} trigger={['click']}>
              <FilterButtonWrapper
                active={arrayFilters[dataIndex] !== undefined}
              >
                <Button label={selectedAdHocFilterConfiguration.label} />
              </FilterButtonWrapper>
            </AntDesignDropdown>
          </Tooltip>
          {getFilterInput(selectedAdHocFilterConfiguration)}
        </>
      )}
    </Layout>
  );
};

export default AdHocFilter;
