import { IToEntityConfiguratorProps } from 'components/molecules/ToEntityConfiguration/ToEntityConfiguration';
import ToEntityConfigurationEdit from 'components/molecules/ToEntityConfigurationEdit/ToEntityConfigurationEdit';
import CustomFilterEditor from 'components/organisms/ToEntityCustomFilterConfigurator/CustomFilterEditor/CustomFilterEditor';
import {
  copyEditableCustomFilterOption,
  generateEditableCustomFilterOption,
  generateNewPlaceholderCustomFilterId,
  getNewConfiguration,
  isCustomFilterConfigured,
  isCustomFilterEqual,
  isPlaceholderCustomFilterId,
} from 'components/organisms/ToEntityCustomFilterConfigurator/helpers';
import {
  COLUMN_LAYOUT_SHARED_STYLES,
  CUSTOM_FILTER_SELECT_WIDTH_VALUE,
} from 'constants/styles';
import { EActionState, ESeverity } from 'enums/General';
import usePrevious from 'hooks/usePrevious';
import {
  IActionResponse,
  IEditableOption,
  IModalHandlers,
  IOption,
} from 'interfaces/Component';
import { ICustomFilter } from 'interfaces/Filter';
import { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { sortByOptionLabel } from 'utils/component';
import { captureError } from 'utils/error';
import { copyCustomFilter, customFilterToUid } from 'utils/filter';
import { encodeIds } from 'utils/general';
import { useDialog } from '../../../providers/DialogProvider';
import { TToEntityId } from '../../../types/ToEntity';
import useTenantInfo from '../../../hooks/useTenantInfo';
import { getCustomFilterOptionsMap } from '../../../shared/helpers/summary.helper.shared';
import useUserInfo from '../../../hooks/useUserInfo';

const Layout = styled.div`
  ${COLUMN_LAYOUT_SHARED_STYLES}
`;

export interface IToEntityCustomFilterConfigurationProps {
  createCustomFilterOption: (
    customFilterOption: IOption<ICustomFilter>,
    entityId?: TToEntityId,
  ) => Promise<IOption<ICustomFilter>>;
  currentCustomFilter: ICustomFilter | undefined;
  customFilterOptions: IOption<ICustomFilter>[];
  encodedPermissionsId: string;
  removeCustomFilterOption: (
    customFilterOption: IOption<ICustomFilter>,
    entityId?: TToEntityId,
  ) => Promise<void>;
  updateCustomFilterOption: (
    customFilterOption: IOption<ICustomFilter>,
    entityId?: TToEntityId,
  ) => Promise<void>;
  optionsMap?: Map<string, any[]>;
  setCustomFilterOptionsMap?: (value: Map<string, any[]>) => void;
  setCustomFilterOptions?: (value: IOption<ICustomFilter>[]) => void;
}

const ToEntityCustomFilterConfigurator = ({
  createCustomFilterOption,
  currentCustomFilter,
  customFilterOptions,
  encodedPermissionsId,
  isDisabled,
  removeCustomFilterOption,
  setHandlers,
  updateCustomFilterOption,
  setCustomFilterOptionsMap,
  setCustomFilterOptions,
}: IToEntityCustomFilterConfigurationProps &
  IToEntityConfiguratorProps): JSX.Element => {
  const { selectedTenantToEntityId } = useTenantInfo();
  const { toEntities } = useUserInfo();
  const [internalCustomFilterOptions, setInternalCustomFilterOptions] =
    useState<IEditableOption<ICustomFilter>[]>([]);
  const [selectedCustomFilter, setSelectedCustomFilter] = useState<
    ICustomFilter | undefined
  >(undefined);
  const [editedCustomFilterOption, setEditedCustomFilterOption] = useState<
    IEditableOption<ICustomFilter> | undefined
  >(undefined);
  const previousSelectedCustomFilter: ICustomFilter | undefined = usePrevious<
    ICustomFilter | undefined
  >(selectedCustomFilter);

  useEffect(() => {
    setSelectedCustomFilter(currentCustomFilter);
  }, [currentCustomFilter]);

  useEffect(() => {
    setInternalCustomFilterOptions(
      (
        previousInternalCustomFilterOptions: IEditableOption<ICustomFilter>[],
      ): IEditableOption<ICustomFilter>[] => {
        let updatedInternalCustomFilterOptions: IEditableOption<ICustomFilter>[] =
          [];

        previousInternalCustomFilterOptions.forEach(
          (editableCustomFilterOption: IEditableOption<ICustomFilter>) => {
            if (editableCustomFilterOption.isUnsaved) {
              updatedInternalCustomFilterOptions.push(
                editableCustomFilterOption,
              );
            } else {
              const customFilterOption = customFilterOptions.find(
                (customFilterOption: IOption<ICustomFilter>) =>
                  customFilterOption.value.filter_id ===
                  editableCustomFilterOption.value.filter_id,
              );

              if (customFilterOption !== undefined) {
                updatedInternalCustomFilterOptions.push(
                  editableCustomFilterOption,
                );
              }
            }
          },
        );

        customFilterOptions.forEach(
          (customFilterOption: IOption<ICustomFilter>) => {
            if (
              updatedInternalCustomFilterOptions.find(
                (
                  editableCustomFilterOption: IEditableOption<ICustomFilter>,
                ): boolean =>
                  editableCustomFilterOption.value.filter_id ===
                  customFilterOption.value.filter_id,
              ) === undefined
            ) {
              updatedInternalCustomFilterOptions.push(
                generateEditableCustomFilterOption(customFilterOption),
              );
            }
          },
        );

        // Filter the options based on the selected entity
        if (selectedTenantToEntityId) {
          updatedInternalCustomFilterOptions =
            updatedInternalCustomFilterOptions.filter(
              (options) =>
                (options.value as any).to_entity === selectedTenantToEntityId,
            );
        }

        updatedInternalCustomFilterOptions.sort(
          (
            a: IEditableOption<ICustomFilter>,
            b: IEditableOption<ICustomFilter>,
          ): number => {
            if (a.isUnsaved && !b.isUnsaved) {
              return -1;
            } else if (!a.isUnsaved && b.isUnsaved) {
              return 1;
            }

            return sortByOptionLabel(a, b);
          },
        );

        return updatedInternalCustomFilterOptions;
      },
    );
  }, [customFilterOptions, selectedTenantToEntityId]);

  useEffect(() => {
    if (previousSelectedCustomFilter !== selectedCustomFilter) {
      let copyOfCustomFilterOption: IEditableOption<ICustomFilter> | undefined =
        undefined;

      if (selectedCustomFilter !== undefined) {
        const customFilterOption: IEditableOption<ICustomFilter> | undefined =
          internalCustomFilterOptions.find(
            (value: IEditableOption<ICustomFilter>): boolean =>
              value.value.filter_id === selectedCustomFilter.filter_id,
          );

        if (customFilterOption !== undefined) {
          copyOfCustomFilterOption =
            copyEditableCustomFilterOption(customFilterOption);
        }
      }
      setEditedCustomFilterOption(copyOfCustomFilterOption);
    }
  }, [
    internalCustomFilterOptions,
    previousSelectedCustomFilter,
    selectedCustomFilter,
  ]);

  useEffect(() => {
    if (editedCustomFilterOption !== undefined) {
      setInternalCustomFilterOptions(
        (
          previousInternalCustomFilterOptions: IEditableOption<ICustomFilter>[],
        ) =>
          previousInternalCustomFilterOptions.map(
            (option: IEditableOption<ICustomFilter>) =>
              editedCustomFilterOption.value.filter_id ===
              option.value.filter_id
                ? editedCustomFilterOption
                : option,
          ),
      );
    }
  }, [editedCustomFilterOption]);
  const showDialog = useDialog();

  useEffect(
    () => {
      const modalHandlers: IModalHandlers = {};

      if (editedCustomFilterOption !== undefined) {
        modalHandlers.cancelHandler = async () => {
          let returnVal = false;
          const actionResponse: IActionResponse = {
            actionState: EActionState.NoAction,
            errorMessage: null,
          };

          try {
            if (editedCustomFilterOption.isUnsaved) {
              const confirmed = await showDialog({
                title: 'Unsaved changes',
                severity: ESeverity.Warning,
                message: 'Discard them?',
              });
              if (confirmed) {
                returnVal = false;
              } else {
                returnVal = true;
              }
            }
          } catch (error: any) {
            captureError(error);

            actionResponse.actionState = EActionState.Failed;
            actionResponse.errorMessage = error;
          } finally {
            return returnVal;
          }
        };

        modalHandlers.deleteHandler = async () => {
          const actionResponse: IActionResponse = {
            actionState: EActionState.NoAction,
            errorMessage: null,
          };

          try {
            if (
              !isPlaceholderCustomFilterId(
                editedCustomFilterOption.value.filter_id,
              )
            ) {
              await removeCustomFilterOption(editedCustomFilterOption);
            }

            setInternalCustomFilterOptions(
              internalCustomFilterOptions.filter(
                (customFilterOption: IOption<ICustomFilter>): boolean =>
                  customFilterOption.value.filter_id !==
                  editedCustomFilterOption.value.filter_id,
              ),
            );

            if (
              editedCustomFilterOption.value.filter_id ===
              selectedCustomFilter?.filter_id
            ) {
              setSelectedCustomFilter(undefined);
            }

            const { customFilterOptionsMap, customFilterOptions } =
              await getCustomFilterOptionsMap(toEntities);
            if (setCustomFilterOptions) {
              setCustomFilterOptions(customFilterOptions);
            }
            if (setCustomFilterOptionsMap) {
              setCustomFilterOptionsMap(customFilterOptionsMap);
            }
            actionResponse.actionState = EActionState.Succeeded;
          } catch (error: any) {
            captureError(error);

            actionResponse.actionState = EActionState.Failed;
            actionResponse.errorMessage = error;
          } finally {
            return actionResponse;
          }
        };

        if (
          editedCustomFilterOption.isUnsaved &&
          isCustomFilterConfigured(editedCustomFilterOption.value)
        ) {
          modalHandlers.saveHandler = async () => {
            const actionResponse: IActionResponse = {
              actionState: EActionState.NoAction,
              errorMessage: null,
            };

            try {
              let updatedEditedCustomFilterOption: IEditableOption<ICustomFilter>;

              if (
                isPlaceholderCustomFilterId(
                  editedCustomFilterOption.value.filter_id,
                )
              ) {
                updatedEditedCustomFilterOption =
                  copyEditableCustomFilterOption(editedCustomFilterOption);

                const newCustomFilterOption: IOption<ICustomFilter> =
                  await createCustomFilterOption(
                    updatedEditedCustomFilterOption,
                  );

                updatedEditedCustomFilterOption = {
                  ...newCustomFilterOption,
                  isUnsaved: false,
                };

                setInternalCustomFilterOptions(
                  (
                    previousInternalCustomFilterOptions: IEditableOption<ICustomFilter>[],
                  ) =>
                    previousInternalCustomFilterOptions.filter(
                      (editableOption: IEditableOption<ICustomFilter>) =>
                        editableOption.value.filter_id !==
                        editedCustomFilterOption.value.filter_id,
                    ),
                );

                setSelectedCustomFilter(newCustomFilterOption.value);
              } else {
                updatedEditedCustomFilterOption =
                  copyEditableCustomFilterOption(editedCustomFilterOption);

                await updateCustomFilterOption(updatedEditedCustomFilterOption);

                updatedEditedCustomFilterOption.isUnsaved = false;
              }

              setEditedCustomFilterOption(updatedEditedCustomFilterOption);

              actionResponse.actionState = EActionState.Succeeded;
            } catch (error: any) {
              actionResponse.actionState = EActionState.Failed;
              actionResponse.errorMessage = error.message;
            } finally {
              const { customFilterOptionsMap, customFilterOptions } =
                await getCustomFilterOptionsMap(toEntities);
              if (setCustomFilterOptions) {
                setCustomFilterOptions(customFilterOptions);
              }
              if (setCustomFilterOptionsMap) {
                setCustomFilterOptionsMap(customFilterOptionsMap);
              }
              return actionResponse;
            }
          };
        }
      }

      setHandlers(modalHandlers);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      editedCustomFilterOption,
      internalCustomFilterOptions,
      selectedCustomFilter,
    ],
  );

  const newConfiguration = useMemo(
    () =>
      getNewConfiguration(
        editedCustomFilterOption === undefined
          ? ''
          : editedCustomFilterOption.label,
      ),
    [editedCustomFilterOption],
  );

  const handleAddCustomFilterOption = useCallback(
    (customFilterOption: IEditableOption<ICustomFilter>) => {
      customFilterOption.value.filter_name =
        customFilterOption.label && customFilterOption.label.trim();

      setInternalCustomFilterOptions(
        (
          previousInternalCustomFilterOptions: IEditableOption<ICustomFilter>[],
        ): IEditableOption<ICustomFilter>[] => {
          // Generate a placeholder filter_id which will be replaced later, we will
          // use a marker to indicate that this is a placeholder since we expect the
          // real id to be a UUID.
          customFilterOption.value.filter_id =
            generateNewPlaceholderCustomFilterId(
              previousInternalCustomFilterOptions.length + 1,
            );

          return [customFilterOption].concat(
            previousInternalCustomFilterOptions,
          );
        },
      );

      setSelectedCustomFilter(customFilterOption.value);
    },
    [],
  );

  const handleChangeCustomFilterOptionLabel = useCallback((label: string) => {
    setEditedCustomFilterOption(
      (
        previousEditedCustomFilterOption:
          | IEditableOption<ICustomFilter>
          | undefined,
      ): IEditableOption<ICustomFilter> | undefined =>
        previousEditedCustomFilterOption === undefined
          ? undefined
          : {
              ...previousEditedCustomFilterOption,
              isUnsaved: true,
              label,
            },
    );
  }, []);

  const handleCustomFilterChange = useCallback(
    (customFilter: ICustomFilter) => {
      if (editedCustomFilterOption !== undefined) {
        if (
          !isCustomFilterEqual(customFilter, editedCustomFilterOption.value)
        ) {
          const updatedEditedCustomFilterOption: IEditableOption<ICustomFilter> =
            copyEditableCustomFilterOption(editedCustomFilterOption);
          updatedEditedCustomFilterOption.value =
            copyCustomFilter(customFilter);
          updatedEditedCustomFilterOption.isUnsaved = true;

          setEditedCustomFilterOption(updatedEditedCustomFilterOption);
        }
      }
    },
    [editedCustomFilterOption],
  );

  return (
    <Layout>
      <ToEntityConfigurationEdit<ICustomFilter>
        configurationToUid={customFilterToUid}
        copyConfiguration={copyCustomFilter}
        encodedPermissionsId={encodeIds([
          encodedPermissionsId,
          'toEntityConfigurationEdit',
        ])}
        isDisabled={isDisabled}
        label='Selected Custom Filter:'
        newConfiguration={newConfiguration}
        onAddConfigurationOption={handleAddCustomFilterOption}
        onConfigurationLabelChange={handleChangeCustomFilterOptionLabel}
        onSelect={setSelectedCustomFilter}
        options={internalCustomFilterOptions}
        selectedConfigurationOption={editedCustomFilterOption}
        selectPlaceholder='Select Custom Filter'
        selectWidth={CUSTOM_FILTER_SELECT_WIDTH_VALUE}
      />
      <CustomFilterEditor
        customFilter={selectedCustomFilter}
        onCustomFilterChange={handleCustomFilterChange}
      />
    </Layout>
  );
};

export default ToEntityCustomFilterConfigurator;
