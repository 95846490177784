import {
  IStylingColumnData,
  IStylingData,
} from 'components/molecules/ETagTemplateRow/types';
import { transformColumnInformation } from 'components/molecules/RowStylingConfigurator/helpers';
import { ETheme } from 'enums/Style';
import { IIndexable } from 'interfaces/General';
import { ISummaryThemedStyles } from 'interfaces/Summary';

export const STYLING_COLUMNS_WIDTH: number = 86;

const STYLING_SAMPLE_COLUMNS: IStylingColumnData[] = [
  {
    dataIndex: 'column1',
    minColumnWidth: STYLING_COLUMNS_WIDTH,
  },
  {
    dataIndex: 'column2',
    minColumnWidth: STYLING_COLUMNS_WIDTH,
  },
  {
    dataIndex: 'column3',
    minColumnWidth: STYLING_COLUMNS_WIDTH,
  },
  {
    dataIndex: 'column4',
    minColumnWidth: STYLING_COLUMNS_WIDTH,
  },
];

export const STYLING_DATA_TABLE_MAX_HEIGHT: string = '400px';
const STYLING_TABLE_DATA_STRING: string = 'test';

export const STYLING_SAMPLE_DATA: IStylingData[] = [
  {
    column1: STYLING_TABLE_DATA_STRING,
    column2: STYLING_TABLE_DATA_STRING,
    column3: STYLING_TABLE_DATA_STRING,
    column4: STYLING_TABLE_DATA_STRING,
    id: '0',
    rowIndex: 0,
  },
  {
    column1: STYLING_TABLE_DATA_STRING,
    column2: STYLING_TABLE_DATA_STRING,
    column3: STYLING_TABLE_DATA_STRING,
    column4: STYLING_TABLE_DATA_STRING,
    id: '1',
    rowIndex: 1,
  },
  {
    column1: STYLING_TABLE_DATA_STRING,
    column2: STYLING_TABLE_DATA_STRING,
    column3: STYLING_TABLE_DATA_STRING,
    column4: STYLING_TABLE_DATA_STRING,
    id: '2',
    rowIndex: 2,
  },
  {
    column1: STYLING_TABLE_DATA_STRING,
    column2: STYLING_TABLE_DATA_STRING,
    column3: STYLING_TABLE_DATA_STRING,
    column4: STYLING_TABLE_DATA_STRING,
    id: '3',
    rowIndex: 3,
  },
];

export const DEFAULT_ALTERNATING_ROW_STYLE: ISummaryThemedStyles & IIndexable =
  {
    [ETheme.Dark]: { filter: 'brightness(1.11)' },
    [ETheme.Light]: { filter: 'brightness(0.89)' },
  };

export const DEFAULT_ROW_HIGHLIGHTING_STYLE: ISummaryThemedStyles & IIndexable =
  {
    [ETheme.Dark]: { filter: 'brightness(1.23)' },
    [ETheme.Light]: { filter: 'brightness(0.77)' },
  };

export const ROW_STYLING_CONFIGURATOR_COLUMNS: IStylingColumnData[] =
  transformColumnInformation(STYLING_SAMPLE_COLUMNS);

export const ALTERNATING_ROW_STYLING_DEFAULT_MARK = { 0.89: '' };
export const ROW_HIGHLIGHT_STYLING_DEFAULT_MARK = { 0.77: '' };
