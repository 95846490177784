import { Slider } from 'antd';
import TextAligned from 'components/atoms/TextAligned/TextAligned';
import DataTable, {
  IDataTableProps,
} from 'components/molecules/DataTable/DataTable';
import {
  IStylingColumnData,
  IStylingData,
  IStylingRowProps,
} from 'components/molecules/ETagTemplateRow/types';
import {
  ALTERNATING_ROW_STYLING_DEFAULT_MARK,
  DEFAULT_ALTERNATING_ROW_STYLE,
  DEFAULT_ROW_HIGHLIGHTING_STYLE,
  ROW_HIGHLIGHT_STYLING_DEFAULT_MARK,
  ROW_STYLING_CONFIGURATOR_COLUMNS,
  STYLING_COLUMNS_WIDTH,
  STYLING_DATA_TABLE_MAX_HEIGHT,
  STYLING_SAMPLE_DATA,
} from 'components/molecules/RowStylingConfigurator/constants';
import {
  getBrightnessValueFromFilter,
  getCustomComponents,
} from 'components/molecules/RowStylingConfigurator/helpers';
import { IStylingConfiguratorRowHandler } from 'components/molecules/RowStylingConfigurator/types';
import { ID_KEY } from 'constants/General';
import { STANDARD_SPACING } from 'constants/styles';
import { ETheme } from 'enums/Style';
import { IThemedProps } from 'interfaces/Component';
import { IIndexable } from 'interfaces/General';
import { ISummaryThemedStyles } from 'interfaces/Summary';
import { TableComponents } from 'rc-table/lib/interface';
import { useEffect, useState } from 'react';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import styled from 'styled-components';
import { alternatingTableRowBackground } from 'utils/styles';

const Layout = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  border: 1px dashed;
  margin: 2px;
  padding: 2px;
`;

const StyledSlider = styled(Slider)`
  width: ${STYLING_COLUMNS_WIDTH * 4}px;
  margin: 10px;
`;

interface IStylingDataTableProps
  extends IDataTableProps<IStylingColumnData, IStylingData>,
    IThemedProps {
  isEmpty: boolean;
  maxHeight: string;
}

// Specialize the DataTable component
const StylingDataTable = styled(
  ({ isEmpty, maxHeight, ...rest }: IStylingDataTableProps) =>
    DataTable<IStylingColumnData, IStylingData>(rest),
)<IStylingDataTableProps>`
  .ant-table-content {
    margin-bottom: ${STANDARD_SPACING};
    max-height: ${(props) => props.maxHeight};
    overflow: auto !important;
  }

  .ant-table-thead {
    > tr > th {
      padding: 2px;
      position: sticky !important;
      text-align: center;
      top: 0px !important;
    }
  }

  .ant-table-tbody {
    > tr > td {
      padding: 0;
    }
  }

  ${(props) => alternatingTableRowBackground(props)}
  ${(props) => (props.isEmpty ? '.ant-table-cell { border: 0; }' : '')}
`;

interface IRowStylingConfiguratorProps {
  alternatingRowStyle?: ISummaryThemedStyles;
  onChange: (
    alternatingRowStyle: ISummaryThemedStyles,
    rowHighlightingStyle: ISummaryThemedStyles,
  ) => void;
  rowHighlightingStyle?: ISummaryThemedStyles;
}

const RowStylingConfigurator = (
  props: IRowStylingConfiguratorProps,
): JSX.Element => {
  const {
    alternatingRowStyle = DEFAULT_ALTERNATING_ROW_STYLE,
    onChange,
    rowHighlightingStyle = DEFAULT_ROW_HIGHLIGHTING_STYLE,
  } = props;

  const [selectedRowKey, setSelectedRowKey] = useState<string>('');

  const [localAlternatingRowStyle, setLocalAlternatingRowStyle] = useState<
    ISummaryThemedStyles & IIndexable
  >(DEFAULT_ALTERNATING_ROW_STYLE);

  const [localRowHighlightingStyle, setLocalRowHighlightingStyle] = useState<
    ISummaryThemedStyles & IIndexable
  >(DEFAULT_ROW_HIGHLIGHTING_STYLE);

  const { currentTheme } = useThemeSwitcher();

  const [customTableComponents] = useState<TableComponents<IStylingData>>(
    getCustomComponents(),
  );

  const [onRowHandler, setOnRowHandler] =
    useState<IStylingConfiguratorRowHandler>({ handler: undefined });

  const [lightAlternatingValue, setLightAlternatingValue] = useState<number>(
    getBrightnessValueFromFilter(localAlternatingRowStyle.light.filter),
  );

  const [darkAlternatingValue, setDarkAlternatingValue] = useState<number>(
    getBrightnessValueFromFilter(localAlternatingRowStyle.dark.filter),
  );

  const [lightHighlightValue, setLightHighlightValue] = useState<number>(
    getBrightnessValueFromFilter(localRowHighlightingStyle.light.filter),
  );

  const [darkHighlightValue, setDarkHighlightValue] = useState<number>(
    getBrightnessValueFromFilter(localRowHighlightingStyle.dark.filter),
  );

  useEffect(() => {
    setLightAlternatingValue(
      getBrightnessValueFromFilter(alternatingRowStyle.light.filter),
    );
    setDarkAlternatingValue(
      getBrightnessValueFromFilter(alternatingRowStyle.dark.filter),
    );
  }, [alternatingRowStyle]);

  useEffect(() => {
    setLightHighlightValue(
      getBrightnessValueFromFilter(rowHighlightingStyle.light.filter),
    );
    setDarkHighlightValue(
      getBrightnessValueFromFilter(rowHighlightingStyle.dark.filter),
    );
  }, [rowHighlightingStyle]);

  const alternatingSliderValue: number =
    currentTheme === ETheme.Light
      ? lightAlternatingValue
      : currentTheme === ETheme.Dark
      ? darkAlternatingValue
      : 0;

  const hoverSliderValue: number =
    currentTheme === ETheme.Light
      ? lightHighlightValue
      : currentTheme === ETheme.Dark
      ? darkHighlightValue
      : 0;

  useEffect(() => {
    setLocalAlternatingRowStyle({
      [ETheme.Dark]: { filter: `brightness(${darkAlternatingValue})` },
      [ETheme.Light]: { filter: `brightness(${lightAlternatingValue})` },
    });
  }, [lightAlternatingValue, darkAlternatingValue]);

  useEffect(() => {
    setLocalRowHighlightingStyle({
      [ETheme.Dark]: { filter: `brightness(${darkHighlightValue})` },
      [ETheme.Light]: { filter: `brightness(${lightHighlightValue})` },
    });
  }, [lightHighlightValue, darkHighlightValue]);

  useEffect(() => {
    const onRowClick = (rowKey: string) => {
      if (selectedRowKey === rowKey) {
        setSelectedRowKey('');
      } else {
        setSelectedRowKey(rowKey);
      }
    };

    setOnRowHandler({
      handler: (record: IStylingData, index?: number): IStylingRowProps => ({
        alternatingRowStyle: localAlternatingRowStyle[currentTheme!],
        index,
        onRowClick,
        record,
        rowHighlightingStyle: localRowHighlightingStyle[currentTheme!],
        selectedRowKey,
      }),
    });
  }, [
    currentTheme,
    localAlternatingRowStyle,
    localRowHighlightingStyle,
    selectedRowKey,
  ]);

  const handleAlternatingSliderChange = (value: number) =>
    currentTheme === ETheme.Light
      ? setLightAlternatingValue(value)
      : currentTheme === ETheme.Dark
      ? setDarkAlternatingValue(value)
      : null;

  const handleHoverSliderChange = (value: number) =>
    currentTheme === ETheme.Light
      ? setLightHighlightValue(value)
      : currentTheme === ETheme.Dark
      ? setDarkHighlightValue(value)
      : null;

  const handleAfterChange = () => {
    onChange(localAlternatingRowStyle, localRowHighlightingStyle);
  };

  return (
    <Layout>
      <StylingDataTable
        columns={ROW_STYLING_CONFIGURATOR_COLUMNS}
        components={customTableComponents}
        currentTheme={currentTheme!}
        data={STYLING_SAMPLE_DATA}
        hasBorders={true}
        isEmpty={STYLING_SAMPLE_DATA.length === 0}
        maxHeight={STYLING_DATA_TABLE_MAX_HEIGHT}
        onRow={onRowHandler.handler}
        pagination={false}
        rowKey={ID_KEY}
      />
      <>
        <TextAligned height={'10px'} minWidth={0} textAlign={'center'}>
          Alternating Row Styling:
        </TextAligned>
        <StyledSlider
          marks={ALTERNATING_ROW_STYLING_DEFAULT_MARK}
          max={1}
          min={0}
          onChange={handleAlternatingSliderChange}
          onAfterChange={handleAfterChange}
          step={0.001}
          tooltipVisible={false}
          value={alternatingSliderValue}
        />
      </>
      <>
        <TextAligned height={'10px'} minWidth={0} textAlign={'center'}>
          Row Hover Styling:
        </TextAligned>
        <StyledSlider
          marks={ROW_HIGHLIGHT_STYLING_DEFAULT_MARK}
          max={1}
          min={0}
          onChange={handleHoverSliderChange}
          onAfterChange={handleAfterChange}
          step={0.001}
          tooltipVisible={false}
          value={hoverSliderValue}
        />
      </>
    </Layout>
  );
};
export default RowStylingConfigurator;
