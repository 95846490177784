import { Card as AntDesignCard } from 'antd';
import TextArea from 'components/atoms/TextArea/TextArea';
import ContactInfoEditable from 'components/molecules/ContactInfoEditable/ContactInfoEditable';
import DataTable, {
  IDataTableProps,
} from 'components/molecules/DataTable/DataTable';
import ETagTagIdsEditable from 'components/organisms/ETagTagIdsEditable/ETagTagIdsEditable';
import {
  EDIT_SUMMARY_INFORMATION_CONTACT_INFO_FAX_LABEL,
  EDIT_SUMMARY_INFORMATION_CONTACT_INFO_PHONE_LABEL,
  FERC_TAGGING_ENTITY_ID,
  TEMPLATE_DESCRIPTION_LABEL,
} from 'components/organisms/SummaryInformationView/constants';
import useTagHeaderEditColumns from 'components/organisms/SummaryInformationView/hooks/useTagHeaderEditColumns';
import useTagNoteEditColumns from 'components/organisms/SummaryInformationView/hooks/useTagNoteEditColumns';
import useTemplateHeaderEditColumns from 'components/organisms/SummaryInformationView/hooks/useTemplateHeaderEditColumns';
import { IEditSummaryInformation } from 'components/organisms/SummaryInformationView/types';
import {
  EDIT_ETAG_TAG_ID_LABEL,
  EDIT_SUMMARY_INFORMATION_CONTACT_INFO_LABEL,
} from 'constants/Detail';
import {
  COLUMN_LAYOUT_SHARED_STYLES,
  STANDARD_SPACING,
  VIEW_DATA_TABLE_SHARED_STYLES,
} from 'constants/styles';
import { EUpdateState } from 'enums/General';
import { EViewMode } from 'enums/View';
import useContactInfoEditable from 'hooks/useContactInfoEditable';
import usePrevious from 'hooks/usePrevious';
import { IOption } from 'interfaces/Component';
import { IEntityInfo, IRegistryEntity } from 'interfaces/Entity';
import { IETagTagId } from 'interfaces/ETag';
import { IContactInfo } from 'interfaces/General';
import {
  ITagDateTimeDataSet,
  ITagHeaderDataSet,
  ITagNoteDataSet,
  ITemplateDataSet,
} from 'interfaces/SummaryInformation';
import { IViewDataTableColumn } from 'interfaces/View';
import {
  ChangeEvent,
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { detailSetValidation } from 'reduxes/Detail/actions';
import styled from 'styled-components';
import { TRootState } from 'types/Redux';
import { getEditInfoKey } from 'utils/detail';
import { registryEntityToEntityInfoOption } from 'utils/entity';
import { eventToStringOrNull } from 'utils/general';
import { getDateTimeColumns } from 'utils/SummaryInformation';
import { getUserContactInfo } from 'utils/user';

const entityToCCEntitiesOption = registryEntityToEntityInfoOption(true, true);

const Layout = styled.div`
  ${COLUMN_LAYOUT_SHARED_STYLES}
  height: 100%;

  > :not(:last-child) {
    margin-bottom: 4px;
  }
`;

const Card = styled(AntDesignCard)`
  height: 100%;
  min-height: 24px;

  > .ant-card-body {
    display: flex;
    flex-direction: row;
    line-height: 16px;
    padding: 4px;

    span {
      font-weight: 500;
      margin-right: ${STANDARD_SPACING};
      white-space: nowrap;
    }
  }
`;

// Specialize the DataTable component
const DateTimeDataTable = styled(
  (
    props: IDataTableProps<
      IViewDataTableColumn<ITagDateTimeDataSet>,
      ITagDateTimeDataSet
    >,
  ) =>
    DataTable<IViewDataTableColumn<ITagDateTimeDataSet>, ITagDateTimeDataSet>(
      props,
    ),
)`
  ${VIEW_DATA_TABLE_SHARED_STYLES}
`;

const TemplateDataTable = styled(
  (
    props: IDataTableProps<
      IViewDataTableColumn<ITemplateDataSet>,
      ITemplateDataSet
    >,
  ) =>
    DataTable<IViewDataTableColumn<ITemplateDataSet>, ITemplateDataSet>(props),
)`
  ${VIEW_DATA_TABLE_SHARED_STYLES}
`;

const TagHeaderDataTable = styled(
  (
    props: IDataTableProps<
      IViewDataTableColumn<ITagHeaderDataSet>,
      ITagHeaderDataSet
    >,
  ) =>
    DataTable<IViewDataTableColumn<ITagHeaderDataSet>, ITagHeaderDataSet>(
      props,
    ),
)`
  ${VIEW_DATA_TABLE_SHARED_STYLES}
`;

const TagNoteDataTable = styled(
  (
    props: IDataTableProps<
      IViewDataTableColumn<ITagNoteDataSet>,
      ITagNoteDataSet
    >,
  ) => DataTable<IViewDataTableColumn<ITagNoteDataSet>, ITagNoteDataSet>(props),
)`
  ${VIEW_DATA_TABLE_SHARED_STYLES}
`;

interface ISummaryInformationEditProps {
  contactInfos: IContactInfo[];
  dateTimeDataSet: ITagDateTimeDataSet[];
  description: string | null;
  eTagTagIds: IETagTagId[];
  initialDescription: string | null | undefined;
  initialETagTagIds: IETagTagId[] | undefined;
  initialTagHeaderDataSet: ITagHeaderDataSet[] | undefined;
  initialTagNoteDataSet: ITagNoteDataSet[] | undefined;
  initialTemplateDataSet: ITemplateDataSet[] | undefined;
  isDisabled?: boolean;
  isUnconstrained?: boolean;
  onChange: (editSummaryInformation: IEditSummaryInformation) => void;
  preventAutoEdits?: boolean;
  previousIsDetailUpdating: boolean | undefined;
  tagHeaderDataSet: ITagHeaderDataSet[];
  tagNoteDataSet: ITagNoteDataSet[];
  templateDataSet: ITemplateDataSet[];
  viewMode: EViewMode;
}

const retrieveSummaryInformationEditState = (state: TRootState) => {
  const { toEntities } = state.user;
  const {
    isDetailDeleted,
    isDetailValidating,
    registryEntities,
    toEntity,
    updatingDetail,
  } = state.detail.present;
  const contactInfo: IContactInfo | undefined = getUserContactInfo(state);
  const defaultContactInfos: IContactInfo[] = contactInfo ? [contactInfo] : [];
  const isDetailUpdating: boolean =
    updatingDetail !== EUpdateState.NotUpdating &&
    updatingDetail !== EUpdateState.UpdateCompleted;

  return {
    defaultContactInfos,
    isDetailDeleted,
    isDetailUpdating,
    isDetailValidating,
    registryEntities,
    toEntity,
    toEntities,
  };
};

const SummaryInformationEdit = ({
  contactInfos,
  dateTimeDataSet,
  description,
  eTagTagIds,
  initialDescription,
  initialETagTagIds,
  initialTagHeaderDataSet,
  initialTagNoteDataSet,
  initialTemplateDataSet,
  isDisabled,
  isUnconstrained = false,
  onChange,
  preventAutoEdits,
  previousIsDetailUpdating,
  tagHeaderDataSet,
  tagNoteDataSet,
  templateDataSet,
  viewMode,
}: ISummaryInformationEditProps): JSX.Element => {
  const dispatch = useDispatch();
  const {
    defaultContactInfos,
    isDetailDeleted,
    isDetailUpdating,
    isDetailValidating,
    registryEntities,
    toEntity,
    toEntities,
  } = useSelector(retrieveSummaryInformationEditState);

  const previousIsDetailDeleted: boolean | undefined =
    usePrevious(isDetailDeleted);
  const previousContactInfos: MutableRefObject<IContactInfo[]> =
    useRef<IContactInfo[]>(contactInfos);

  const { ccEntitiesOptions, fercEntityInfoOption } = useMemo(() => {
    // Identify the FERC entity option as this is a required include in every
    // CC Entities list
    const ccEntitiesOptions: IOption<IEntityInfo>[] = [];
    let fercEntityInfoOption: IOption<IEntityInfo> | undefined;

    registryEntities.forEach((registryEntity: IRegistryEntity) => {
      const entityInfoOption: IOption<IEntityInfo> =
        entityToCCEntitiesOption(registryEntity);

      if (registryEntity.TaggingEntityID === FERC_TAGGING_ENTITY_ID) {
        fercEntityInfoOption = entityInfoOption;
        fercEntityInfoOption.isDisabled = true;
      }

      ccEntitiesOptions.push(entityInfoOption);
    });

    return { ccEntitiesOptions, fercEntityInfoOption };
  }, [registryEntities]);

  useEffect(() => {
    if (fercEntityInfoOption !== undefined) {
      const fercEntityInfo: IEntityInfo = fercEntityInfoOption.value;

      if (
        tagHeaderDataSet[0].ccList.find(
          (entityInfo: IEntityInfo): boolean =>
            entityInfo.tagging_entity_id === fercEntityInfo.tagging_entity_id,
        ) === undefined
      ) {
        onChange({
          tagHeaderData: [
            {
              ccList: [...tagHeaderDataSet[0].ccList, fercEntityInfo],
            },
          ],
        });
      }
    }
  }, [fercEntityInfoOption, onChange, tagHeaderDataSet]);

  const isDetailDeletedChanged: boolean = useMemo(
    () => isDetailDeleted !== previousIsDetailDeleted,
    [isDetailDeleted, previousIsDetailDeleted],
  );

  const isDetailUpdatingChanged: boolean = useMemo(
    () => isDetailUpdating !== previousIsDetailUpdating,
    [isDetailUpdating, previousIsDetailUpdating],
  );

  const templateHeaderEditColumns = useTemplateHeaderEditColumns(
    initialTemplateDataSet,
    isDetailDeleted,
    isDetailDeletedChanged,
    isDetailUpdating,
    isDetailUpdatingChanged,
    isUnconstrained,
    onChange,
    toEntity?.to_entity,
  );

  const tagHeaderEditColumns = useTagHeaderEditColumns(
    ccEntitiesOptions,
    initialTagHeaderDataSet,
    isDetailDeleted,
    isDetailDeletedChanged,
    isDetailUpdating,
    isDetailUpdatingChanged,
    onChange,
  );

  const tagNoteEditColumns = useTagNoteEditColumns(
    initialTagNoteDataSet,
    isDetailDeleted,
    isDetailDeletedChanged,
    isDetailUpdating,
    isDetailUpdatingChanged,
    onChange,
  );

  const handleCpseChange = useCallback(
    (value: IEntityInfo | null | undefined) => {
      onChange({ eTagTagIds: [{ pse: value === undefined ? null : value }] });
    },
    [onChange],
  );

  const handleGcaChange = useCallback(
    (value: IEntityInfo | null | undefined) => {
      onChange({ eTagTagIds: [{ gca: value === undefined ? null : value }] });
    },
    [onChange],
  );

  const handleLcaChange = useCallback(
    (value: IEntityInfo | null | undefined) => {
      onChange({ eTagTagIds: [{ lca: value === undefined ? null : value }] });
    },
    [onChange],
  );

  const handleTagCodeChange = useCallback(
    (tagCode: string) => {
      onChange({
        eTagTagIds: [{ tag_code: tagCode }],
      });
    },
    [onChange],
  );

  const handleDescriptionChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      onChange({ description: eventToStringOrNull(event) });
    },
    [onChange],
  );

  const handleContactChange = useCallback(
    (value: IContactInfo) => {
      onChange({ contactInfos: [value] });
    },
    [onChange],
  );

  useEffect(() => {
    // if we use the default contact infos to populate the grid, we need to change the redux store
    if (
      contactInfos.length > 0 &&
      contactInfos[0].contact === null &&
      defaultContactInfos.length > 0 &&
      defaultContactInfos[0].contact !== null
    ) {
      handleContactChange(defaultContactInfos[0]);
    }
  }, [contactInfos, defaultContactInfos, handleContactChange]);

  const uiContactInfo: IContactInfo =
    contactInfos[0].contact !== null ? contactInfos[0] : defaultContactInfos[0];

  const Description = useMemo(
    () =>
      viewMode === EViewMode.None ? null : (
        <Card bordered={false}>
          <span>{TEMPLATE_DESCRIPTION_LABEL}</span>
          <TextArea
            initialValue={initialDescription}
            isDisabled={isDetailDeleted || isDetailUpdating}
            onChange={handleDescriptionChange}
            rows={2}
            value={description === null ? undefined : description}
          />
        </Card>
      ),
    [
      description,
      handleDescriptionChange,
      initialDescription,
      isDetailDeleted,
      isDetailUpdating,
      viewMode,
    ],
  );

  const dateTimeColumns: IViewDataTableColumn<ITagDateTimeDataSet>[] = useMemo(
    () => getDateTimeColumns(isUnconstrained),
    [isUnconstrained],
  );

  const Tag = useMemo(
    () =>
      viewMode === EViewMode.None ? null : viewMode ===
        EViewMode.EditETagTemplate ? (
        <>
          <TemplateDataTable
            columns={templateHeaderEditColumns}
            data={templateDataSet}
            pagination={false}
            tableLayout='fixed'
          />
          {Description}
          <ETagTagIdsEditable
            data={eTagTagIds}
            initialEditKey={getEditInfoKey(EDIT_ETAG_TAG_ID_LABEL, 0, 0)}
            initialETagTagIds={initialETagTagIds}
            isDetailDeleted={isDetailDeleted}
            isDetailUpdating={isDetailUpdating}
            isDisabled={isDisabled}
            maximumAllowableAdds={1}
            onCpseChange={handleCpseChange}
            onGcaChange={handleGcaChange}
            onLcaChange={handleLcaChange}
            registryEntities={registryEntities}
            toEntities={toEntities}
          />
        </>
      ) : (
        <>
          <ETagTagIdsEditable
            data={eTagTagIds}
            initialEditKey={getEditInfoKey(EDIT_ETAG_TAG_ID_LABEL, 0, 0)}
            initialETagTagIds={initialETagTagIds}
            isDetailDeleted={isDetailDeleted}
            isDetailUpdating={isDetailUpdating}
            isDisabled={isDisabled}
            maximumAllowableAdds={1}
            onCpseChange={handleCpseChange}
            onGcaChange={handleGcaChange}
            onLcaChange={handleLcaChange}
            onTagCodeChange={handleTagCodeChange}
            registryEntities={registryEntities}
            toEntities={toEntities}
          />
          <DateTimeDataTable
            columns={dateTimeColumns}
            data={dateTimeDataSet}
            pagination={false}
            tableLayout='fixed'
          />
        </>
      ),
    [
      dateTimeColumns,
      dateTimeDataSet,
      Description,
      eTagTagIds,
      handleCpseChange,
      handleGcaChange,
      handleLcaChange,
      handleTagCodeChange,
      initialETagTagIds,
      isDetailDeleted,
      isDetailUpdating,
      isDisabled,
      registryEntities,
      templateHeaderEditColumns,
      templateDataSet,
      toEntities,
      viewMode,
    ],
  );

  const TagHeader = useMemo(
    () =>
      viewMode === EViewMode.None ? null : (
        <TagHeaderDataTable
          columns={tagHeaderEditColumns}
          data={tagHeaderDataSet}
          pagination={false}
          tableLayout='fixed'
        />
      ),
    [tagHeaderEditColumns, tagHeaderDataSet, viewMode],
  );

  const setValidation = useCallback(
    (id: string, isValid: boolean) => {
      dispatch(detailSetValidation({ id, isValid }));
    },
    [dispatch],
  );

  const { contactInfoEditableProps, contactInfo, isFaxValid, isPhoneValid } =
    useContactInfoEditable(
      EDIT_SUMMARY_INFORMATION_CONTACT_INFO_LABEL,
      isDetailDeleted || isDetailUpdating,
      true,
      uiContactInfo,
      isDetailValidating,
    );

  useEffect(() => {
    setValidation(
      getEditInfoKey(EDIT_SUMMARY_INFORMATION_CONTACT_INFO_FAX_LABEL, 0, 0),
      isFaxValid,
    );
  }, [isFaxValid, setValidation]);

  useEffect(() => {
    setValidation(
      getEditInfoKey(EDIT_SUMMARY_INFORMATION_CONTACT_INFO_PHONE_LABEL, 0, 0),
      isPhoneValid,
    );
  }, [isPhoneValid, setValidation]);

  useEffect(() => {
    if (
      contactInfo !== previousContactInfos.current[0] &&
      preventAutoEdits !== true
    ) {
      handleContactChange(contactInfo);
    }
  }, [contactInfo, handleContactChange, preventAutoEdits]);

  const Contact = useMemo(
    () =>
      viewMode === EViewMode.None ? null : (
        <ContactInfoEditable {...contactInfoEditableProps} />
      ),
    [viewMode, contactInfoEditableProps],
  );

  const TagNote = useMemo(
    () =>
      viewMode === EViewMode.None ? null : (
        <TagNoteDataTable
          columns={tagNoteEditColumns}
          data={tagNoteDataSet}
          pagination={false}
          tableLayout='fixed'
        />
      ),
    [tagNoteEditColumns, tagNoteDataSet, viewMode],
  );

  return (
    <Layout>
      {Tag}
      {TagHeader}
      {Contact}
      {TagNote}
    </Layout>
  );
};

export default SummaryInformationEdit;
