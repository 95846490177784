import { IEditSummaryInformation } from 'components/organisms/SummaryInformationView/types';
import { ITagNoteDataSet } from 'interfaces/SummaryInformation';
import { ChangeEvent, useCallback, useMemo } from 'react';
import { eventToStringOrNull } from 'utils/general';
import { getColumnInputRender } from 'utils/views';

const noteInputRender = getColumnInputRender<ITagNoteDataSet>('100%', 40);

const useTagNoteEditColumns = (
  initialTagNoteDataSet: ITagNoteDataSet[] | undefined,
  isDetailDeleted: boolean,
  isDetailDeletedChanged: boolean,
  isDetailUpdating: boolean,
  isDetailUpdatingChanged: boolean,
  onChange: (editSummaryInformation: IEditSummaryInformation) => void,
) => {
  const getInitialTagNoteInputValue = useCallback(
    (): string | null =>
      initialTagNoteDataSet?.[0] === undefined
        ? null
        : initialTagNoteDataSet[0].notes,
    [initialTagNoteDataSet],
  );

  const getTagNoteInputKey = useCallback(
    (record: ITagNoteDataSet): string => record.key,
    [],
  );

  const handleTagNoteInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange({
        notes: eventToStringOrNull(event),
      });
    },
    [onChange],
  );

  return useMemo(
    () => [
      {
        dataIndex: 'notes',
        render: noteInputRender({
          getInitialValue: getInitialTagNoteInputValue,
          getKey: getTagNoteInputKey,
          isDisabled: isDetailDeleted || isDetailUpdating,
          onChange: handleTagNoteInputChange,
        }),
        shouldCellUpdate: (
          record: ITagNoteDataSet,
          previousRecord: ITagNoteDataSet,
        ): boolean =>
          isDetailDeletedChanged ||
          isDetailUpdatingChanged ||
          record.notes !== previousRecord.notes,
        title: 'Tag Note',
      },
    ],
    [
      getInitialTagNoteInputValue,
      getTagNoteInputKey,
      handleTagNoteInputChange,
      isDetailDeleted,
      isDetailDeletedChanged,
      isDetailUpdating,
      isDetailUpdatingChanged,
    ],
  );
};

export default useTagNoteEditColumns;
