import Button from 'components/atoms/Button/Button';
import Input from 'components/atoms/Input/Input';
import SeparatedRowLayout from 'components/atoms/SeparatedRowLayout/SeparatedRowLayout';
import Select from 'components/molecules/Select/Select';
import { IEditableOption, IOption } from 'interfaces/Component';
import { ChangeEvent, useState } from 'react';
import styled from 'styled-components';
import { selectOptionLabelFilter } from 'utils/general';

const SELECT_ITEM_HEIGHT_VALUE = 32;
const SELECT_ARROW_WIDTH_VALUE = 26;

const EditMenuActions = styled.div`
  display: flex;
  flex-direction: row;
  padding: 4px;

  > :not(:last-child) {
    margin-right: 4px;
  }
`;

interface IConfigurationSelectProps {
  showSelectionArrowOnly?: boolean;
  width: number;
}

const SelectWrapper = styled.div<IConfigurationSelectProps>`
  width: ${(props) => props.width}px;
`;

interface IProps<T> {
  allowClear?: boolean;
  copyConfiguration?: (configuration: T) => T;
  getOptionLabel?: (option: IOption<T>) => string;
  isDisabled?: boolean;
  label?: string;
  newConfigurationGenerator?: () => T;
  onAddConfigurationOption?: (configurationOption: IEditableOption<T>) => void;
  onChange: (value: T | undefined) => void;
  options: IOption<T>[] | IEditableOption<T>[];
  placeholder?: string;
  showSelectionArrowOnly?: boolean;
  showUnsavedIndicator?: boolean;
  value: T | undefined;
  valueToUid: (value: T) => string;
  width: number;
}

const ConfigurationSelector = <T extends any>(
  props: IProps<T>,
): JSX.Element => {
  const {
    allowClear,
    copyConfiguration,
    getOptionLabel,
    isDisabled,
    label,
    newConfigurationGenerator,
    onAddConfigurationOption,
    onChange,
    options,
    placeholder,
    showSelectionArrowOnly,
    showUnsavedIndicator,
    value,
    valueToUid,
    width,
  } = props;
  const [newConfigurationName, setNewConfigurationName] = useState<
    string | undefined
  >();

  const handleEditMenuInput = (event: ChangeEvent<HTMLInputElement>) => {
    setNewConfigurationName(event.target.value);
  };

  const handleEditMenuAdd = () => {
    if (
      newConfigurationGenerator !== undefined &&
      onAddConfigurationOption !== undefined &&
      newConfigurationName !== undefined
    ) {
      const newConfigurationOption: IEditableOption<T> = {
        isUnsaved: true,
        label: newConfigurationName,
        value: newConfigurationGenerator(),
      };

      setNewConfigurationName(undefined);

      onAddConfigurationOption(newConfigurationOption);
    }
  };

  const handleEditMenuCopy = (configuration: T) => () => {
    if (
      copyConfiguration !== undefined &&
      onAddConfigurationOption !== undefined &&
      newConfigurationName !== undefined
    ) {
      const newConfigurationOption: IEditableOption<T> = {
        isUnsaved: true,
        label: newConfigurationName,
        value: copyConfiguration(configuration),
      };

      setNewConfigurationName(undefined);

      onAddConfigurationOption(newConfigurationOption);
    }
  };

  const editMenu = (menu: JSX.Element): JSX.Element => (
    <div>
      {menu}
      <EditMenuActions>
        <Input onChange={handleEditMenuInput} value={newConfigurationName} />
        {newConfigurationGenerator === undefined ? null : (
          <Button
            isDisabled={!newConfigurationName}
            label='Add new'
            onClick={handleEditMenuAdd}
          />
        )}
        {copyConfiguration === undefined ? null : (
          <Button
            isDisabled={!newConfigurationName || value === undefined}
            label='Copy as'
            onClick={handleEditMenuCopy(value!)}
          />
        )}
      </EditMenuActions>
    </div>
  );

  const editedOptions: IOption<T>[] = (options as IOption<T>[]).map(
    (option: IOption<T>) => {
      const label: string | undefined = getOptionLabel?.(option);

      return (option as IEditableOption<T>).isUnsaved
        ? {
            ...option,
            label: `${label === undefined ? option.label : label}${
              showUnsavedIndicator === true ? ' (Unsaved)' : ''
            }`,
          }
        : label === undefined
        ? option
        : {
            ...option,
            label,
          };
    },
  );

  return (
    <SeparatedRowLayout>
      {label === undefined ? null : <div>{label}</div>}
      <SelectWrapper
        width={
          showSelectionArrowOnly
            ? SELECT_ARROW_WIDTH_VALUE
            : SELECT_ARROW_WIDTH_VALUE + width
        }
      >
        <Select<T>
          allowClear={allowClear}
          dropdownMatchSelectWidth={false}
          dropdownRender={
            onAddConfigurationOption === undefined ? undefined : editMenu
          }
          filter={selectOptionLabelFilter}
          isDisabled={isDisabled}
          listItemHeight={SELECT_ITEM_HEIGHT_VALUE}
          onChange={onChange}
          options={editedOptions}
          placeholder={showSelectionArrowOnly ? undefined : placeholder}
          showSearch={!showSelectionArrowOnly}
          showSelectionArrowOnly={showSelectionArrowOnly}
          value={value}
          valueToUid={valueToUid}
        />
      </SelectWrapper>
    </SeparatedRowLayout>
  );
};

export default ConfigurationSelector;
