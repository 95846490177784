import { IFloatOverPosition } from 'components/molecules/FloatOver/FloatOver';
import { NO_OP_HANDLER } from 'constants/General';
import {
  ComponentType,
  Context,
  createContext,
  ReactNode,
  useState,
} from 'react';

export interface IFloatOverChildComponent {
  Component: ComponentType<any>;
}

export interface IFloatOverContext {
  floatOverContent: JSX.Element | null;
  floatOverDefaultPosition: IFloatOverPosition | undefined;
  floatOverId: string;
  floatOverMaximumHeight: string | undefined;
  floatOverUseDragPanel: boolean | undefined;
  setFloatOverContent: (content: JSX.Element | null) => void;
  setFloatOverDefaultPosition: (defaultPosition: IFloatOverPosition) => void;
  setFloatOverId: (id: string) => void;
  setFloatOverMaximumHeight: (maximumHeight: string | undefined) => void;
  setFloatOverUseDragPanel: (useDragPanel: boolean | undefined) => void;
}

export const FloatOverContext: Context<IFloatOverContext> =
  createContext<IFloatOverContext>({
    floatOverContent: null,
    floatOverDefaultPosition: undefined,
    floatOverId: '',
    floatOverMaximumHeight: undefined,
    floatOverUseDragPanel: undefined,
    setFloatOverContent: NO_OP_HANDLER,
    setFloatOverDefaultPosition: NO_OP_HANDLER,
    setFloatOverId: NO_OP_HANDLER,
    setFloatOverMaximumHeight: NO_OP_HANDLER,
    setFloatOverUseDragPanel: NO_OP_HANDLER,
  });

interface IFloatOverProviderProps {
  children: ReactNode;
}

const FloatOverProvider = (props: IFloatOverProviderProps): JSX.Element => {
  const { children } = props;
  const [floatOverContent, setFloatOverContent] = useState<JSX.Element | null>(
    null,
  );
  const [floatOverDefaultPosition, setFloatOverDefaultPosition] = useState<
    IFloatOverPosition | undefined
  >(undefined);
  const [floatOverId, setFloatOverId] = useState<string>('');
  const [floatOverMaximumHeight, setFloatOverMaximumHeight] = useState<
    string | undefined
  >(undefined);
  const [floatOverUseDragPanel, setFloatOverUseDragPanel] = useState<
    boolean | undefined
  >(undefined);

  return (
    <FloatOverContext.Provider
      value={{
        floatOverContent,
        floatOverDefaultPosition,
        floatOverId,
        floatOverMaximumHeight,
        floatOverUseDragPanel,
        setFloatOverContent,
        setFloatOverDefaultPosition,
        setFloatOverId,
        setFloatOverMaximumHeight,
        setFloatOverUseDragPanel,
      }}
    >
      {children}
    </FloatOverContext.Provider>
  );
};

export default FloatOverProvider;
