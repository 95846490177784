import { NULL_CELL_VALUE } from 'constants/Component';
import { EMarketInfoPricesDataGridCellType } from 'enums/Detail';
import { EMarketInfoMarket } from 'enums/ETag';
import { IMarketInfoPricesDataGridCell } from 'interfaces/Detail';
import { TMarketInfoPricesDataGridRow } from 'types/Detail';
import { isEmptyValue } from 'utils/general';

export const isStringAMarket = (value: string): boolean =>
  value === EMarketInfoMarket.MISO || value === EMarketInfoMarket.SPP;

export const marketInfoPricesDataGridCellToString = (
  cell: IMarketInfoPricesDataGridCell | null | undefined,
  useNullCellValue?: boolean,
): string => {
  let valueAsString: string = '';

  if (!isEmptyValue(cell?.value)) {
    const { type, value } = cell!;

    if (
      type === EMarketInfoPricesDataGridCellType.Number ||
      type === EMarketInfoPricesDataGridCellType.String
    ) {
      valueAsString = value as string;
    }
  }

  if (valueAsString === '' && useNullCellValue === true) {
    return NULL_CELL_VALUE;
  }

  return valueAsString;
};

export const updateMarketInfoPricesDataGridRowWithValue = (
  value: string,
  targetColumnKey: string,
  targetRow: TMarketInfoPricesDataGridRow,
): TMarketInfoPricesDataGridRow => {
  const targetCell: IMarketInfoPricesDataGridCell | null | undefined =
    targetRow[targetColumnKey];

  if (targetCell === undefined) {
    throw new Error(
      `Invalid targetColumnKey: ${targetColumnKey} for targetRow: ${JSON.stringify(
        targetRow,
      )}`,
    );
  }

  let updatedRow: TMarketInfoPricesDataGridRow = targetRow;

  if (
    targetCell === null ||
    targetCell.type === EMarketInfoPricesDataGridCellType.Number
  ) {
    let updatedValue: string | undefined = undefined;

    if (value !== NULL_CELL_VALUE) {
      updatedValue = value;
    }

    if (
      targetCell === null ||
      (targetCell !== null && (targetCell.value as string) !== updatedValue)
    ) {
      if (updatedValue === undefined) {
        updatedRow = {
          ...updatedRow,
          [targetColumnKey]: null,
        };
      } else {
        updatedRow = {
          ...updatedRow,
          [targetColumnKey]: {
            type: EMarketInfoPricesDataGridCellType.Number,
            value: updatedValue,
          },
        };
      }
    }
  }

  return updatedRow;
};

export const copyMarketInfoPricesDataGridCellTo = (
  sourceRow: TMarketInfoPricesDataGridRow,
  sourceColumnKey: string,
  targetRow: TMarketInfoPricesDataGridRow,
  targetColumnKey: string,
): TMarketInfoPricesDataGridRow => {
  const sourceCell: IMarketInfoPricesDataGridCell | null | undefined =
    sourceRow[sourceColumnKey];

  if (sourceCell === undefined) {
    throw new Error(
      `Invalid sourceColumnKey: ${sourceColumnKey} for sourceRow: ${JSON.stringify(
        sourceRow,
      )}`,
    );
  }

  const targetCell: IMarketInfoPricesDataGridCell | null | undefined =
    targetRow[targetColumnKey];

  if (targetCell === undefined) {
    throw new Error(
      `Invalid targetColumnKey: ${targetColumnKey} for targetRow: ${JSON.stringify(
        targetRow,
      )}`,
    );
  }

  if (sourceCell === null) {
    if (targetCell !== null) {
      if (
        targetCell.type === EMarketInfoPricesDataGridCellType.Empty ||
        targetCell.type === EMarketInfoPricesDataGridCellType.Number
      ) {
        return { ...targetRow, [targetColumnKey]: sourceCell };
      }
    }
  } else {
    if (targetCell === null) {
      return { ...targetRow, [targetColumnKey]: { ...sourceCell } };
    } else {
      if (sourceCell.type === EMarketInfoPricesDataGridCellType.Empty) {
        if (targetCell.type === EMarketInfoPricesDataGridCellType.Number) {
          return { ...targetRow, [targetColumnKey]: null };
        }
      } else if (sourceCell.type === EMarketInfoPricesDataGridCellType.Number) {
        if (targetCell.type === EMarketInfoPricesDataGridCellType.Empty) {
          return { ...targetRow, [targetColumnKey]: { ...sourceCell } };
        } else if (
          targetCell.type === EMarketInfoPricesDataGridCellType.Number
        ) {
          return { ...targetRow, [targetColumnKey]: { ...sourceCell } };
        }
      }
    }
  }

  return targetRow;
};
