const SetStateApprovedIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    stroke='currentColor'
    viewBox='64 64 896 896'
    width='1.2em'
    height='1.2em'
  >
    <path d='M180 904 A 330 330 0 0 1 689 627' fill='none' strokeWidth='80' />
    <circle cx='510' cy='360' r='210' fill='none' strokeWidth='80' />
    <path
      d='M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 00-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z'
      fill='currentColor'
      strokeWidth='98'
      strokeLinejoin='round'
      transform='scale(0.6) translate(630 710)'
    />
  </svg>
);

export default SetStateApprovedIcon;
