import { IStylingData } from 'components/molecules/ETagTemplateRow/types';
import {
  getHoverHighlightStyles,
  getSelectedStyles,
} from 'components/molecules/RowStylingConfigurator/helpers';
import { ISummaryStyles } from 'interfaces/Summary';
import { ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';
import { unpackSummaryStyles } from 'utils/styles';

const IStyledStylingRow = styled.tr<IStyledStylingRowProps>`
  ${(props) => props.rowStyle};
  ${(props) => getSelectedStyles(props)}
  ${(props) => getHoverHighlightStyles(props)}
`;

export interface IStyledStylingRowProps {
  allowHoverHighlight?: boolean;
  alternatingRowStyle: string;
  isSelected: boolean;
  rowHighlightingStyle: string;
  rowStyle: string;
}

interface IStylingRowProps {
  alternatingRowStyle: ISummaryStyles;
  children?: ReactNode;
  onRowClick: (rowKey: string) => void;
  record?: IStylingData;
  rowHighlightingStyle: ISummaryStyles;
  selectedRowKey: string;
}

const StylingRow = (props: IStylingRowProps): JSX.Element => {
  const {
    alternatingRowStyle,
    children,
    onRowClick,
    record,
    rowHighlightingStyle,
    selectedRowKey,
  } = props;

  const [isSelected, setIsSelected] = useState<boolean>(false);

  const [unpackedAlternatingRowStyle, setUnpackedAlternatingRowStyle] =
    useState<string>('');

  const [unpackedRowHighlightingStyle, setUnpackedRowHighlightingStyle] =
    useState<string>('');

  useEffect(() => {
    setUnpackedAlternatingRowStyle(unpackSummaryStyles(alternatingRowStyle));
  }, [alternatingRowStyle]);

  useEffect(() => {
    setUnpackedRowHighlightingStyle(unpackSummaryStyles(rowHighlightingStyle));
  }, [rowHighlightingStyle]);

  useEffect(() => {
    setIsSelected(record !== undefined && selectedRowKey === record.id);
  }, [selectedRowKey, record]);

  const handleClick = () => {
    if (onRowClick !== undefined && record !== undefined) {
      onRowClick(record.id);
    }
  };

  const hasRecord: boolean = record !== undefined;
  const hasRowIndex: boolean = hasRecord && record!.rowIndex !== undefined;
  const rowStyle: string =
    hasRowIndex && record!.rowIndex! % 2 === 1
      ? unpackedAlternatingRowStyle
      : '';

  return (
    <IStyledStylingRow
      allowHoverHighlight={record !== undefined}
      alternatingRowStyle={unpackedAlternatingRowStyle}
      isSelected={isSelected}
      onClick={handleClick}
      rowHighlightingStyle={unpackedRowHighlightingStyle}
      rowStyle={rowStyle}
    >
      {children}
    </IStyledStylingRow>
  );
};

export default StylingRow;
