import Alert from 'components/molecules/ToEntityAlertHistory/Alert';
import { IAlert } from 'interfaces/Alert';
import { ComponentType, useRef, useState } from 'react';
import styled from 'styled-components';
import { TAlertHeightMap, TAlertId } from 'types/Alert';
import { TTimeZone } from 'types/DateTime';
import { TErrorMessage } from 'types/Error';

const HiddenAlertsContainer = styled.div`
  left: 0;
  margin: 0;
  padding: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  visibility: hidden;
`;

const HiddenAlert = styled(Alert)`
  left: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
`;

interface IHiddenAlert extends IAlert {
  errorMessage: TErrorMessage;
}

interface IHiddenAlertsProps {
  alertHeightMap: TAlertHeightMap;
  hiddenAlerts: IHiddenAlert[];
  setAlertHeight: (alert_id: TAlertId, height: number) => void;
  timeZone: TTimeZone;
}

const HiddenAlerts = (props: IHiddenAlertsProps): JSX.Element => {
  const { hiddenAlerts, setAlertHeight, timeZone } = props;

  const handleSetAlertHeight = (alert_id: TAlertId) => (height: number) => {
    setAlertHeight(alert_id, height);
  };

  return (
    <HiddenAlertsContainer>
      {hiddenAlerts.map(
        (hiddenAlert: IHiddenAlert): JSX.Element => (
          <HiddenAlert
            alert={hiddenAlert}
            errorMessage={hiddenAlert.errorMessage}
            key={hiddenAlert.alert_id}
            setAlertHeight={handleSetAlertHeight(hiddenAlert.alert_id)}
            timeZone={timeZone}
          />
        ),
      )}
    </HiddenAlertsContainer>
  );
};

export interface IwithAlertHeightMapProps {
  getAlertHeightMap: () => TAlertHeightMap;
  recalculateAlertHeightMap: (
    alerts: IAlert[],
    alertsErrorMap: Record<TAlertId, TErrorMessage>,
  ) => void;
  resetAlertHeightMap: () => void;
  setAlertsForHeightMap: (
    alerts: IAlert[],
    alertsErrorMap: Record<TAlertId, TErrorMessage>,
  ) => void;
  timeZone: TTimeZone;
}

export const withAlertHeightMap =
  <T extends IwithAlertHeightMapProps>(Component: ComponentType<T>) =>
  (
    props: Omit<
      T,
      | 'getAlertHeightMap'
      | 'recalculateAlertHeightMap'
      | 'resetAlertHeightMap'
      | 'setAlertsForHeightMap'
    >,
  ) => {
    const { timeZone } = props;
    const [hiddenAlerts, setHiddenAlerts] = useState<IHiddenAlert[]>([]);
    const alertHeightMapRef = useRef<TAlertHeightMap>({});
    const nextAlertHeightMapRef = useRef<TAlertHeightMap>({});

    const getAlertHeightMap = (): TAlertHeightMap => alertHeightMapRef.current;

    const resetAlertHeightMap = () => {
      nextAlertHeightMapRef.current = {};
    };

    const setAlertsForHeightMap = (
      alerts: IAlert[],
      alertsErrorMap: Record<TAlertId, TErrorMessage>,
    ) => {
      setHiddenAlerts(
        alerts.map((alert: IAlert): IHiddenAlert => {
          const errorMessage: TErrorMessage | undefined =
            alertsErrorMap[alert.alert_id];

          return {
            ...alert,
            errorMessage: errorMessage === undefined ? null : errorMessage,
          };
        }),
      );
    };

    const recalculateAlertHeightMap = (
      alerts: IAlert[],
      alertsErrorMap: Record<TAlertId, TErrorMessage>,
    ) => {
      resetAlertHeightMap();

      setAlertsForHeightMap(alerts, alertsErrorMap);
    };

    const setAlertHeight = (alert_id: TAlertId, height: number) => {
      nextAlertHeightMapRef.current[alert_id] = height;

      const alertHeightMapKeys: TAlertId[] = Object.keys(
        nextAlertHeightMapRef.current,
      );

      if (
        (hiddenAlerts
          .map((hiddenAlert: IHiddenAlert): boolean =>
            alertHeightMapKeys.includes(hiddenAlert.alert_id),
          )
          .reduce(
            (previous: boolean, current: boolean): boolean =>
              previous && current,
          ),
        true)
      ) {
        alertHeightMapRef.current = nextAlertHeightMapRef.current;
      }
    };

    return (
      <>
        <Component
          {...(props as T)}
          getAlertHeightMap={getAlertHeightMap}
          recalculateAlertHeightMap={recalculateAlertHeightMap}
          resetAlertHeightMap={resetAlertHeightMap}
          setAlertsForHeightMap={setAlertsForHeightMap}
        />
        <HiddenAlerts
          alertHeightMap={alertHeightMapRef.current}
          hiddenAlerts={hiddenAlerts}
          setAlertHeight={setAlertHeight}
          timeZone={timeZone}
        />
      </>
    );
  };
