import { EMPTY_CELLS_SELECTION, TAG_COUNT_LABEL } from 'constants/Component';
import { NO_OP_HANDLER } from 'constants/General';
import {
  IDataGridPosition,
  IDataGridSelectionContext,
  IOption,
  ISelectedCellsMapDimensions,
  IToEntityDataTableSummary,
} from 'interfaces/Component';
import { IIndexable } from 'interfaces/General';
import { Context, createContext } from 'react';
import { Column } from 'react-data-grid';
import {
  TColumn,
  TRow,
  TSelectedCellsMap,
  TSelectedCellsRow,
  TToEntityDataTableSummary,
} from 'types/Component';
import { TDataGridMatrix } from 'types/General';

export const getKeyAndValueForToEntityDataTableSummary = (
  toEntityDataTableSummary: TToEntityDataTableSummary,
) => {
  const keys: string[] = Object.keys(toEntityDataTableSummary);
  let key: string | undefined = undefined;
  let value: IToEntityDataTableSummary | undefined = undefined;

  if (keys.length > 1) {
    throw new Error(
      `Invalid summary data: ${JSON.stringify(toEntityDataTableSummary)}`,
    );
  } else if (keys.length === 1) {
    key = keys[0];
  }

  if (key !== undefined) {
    value = toEntityDataTableSummary[key];

    if (value === undefined) {
      throw new Error(
        `Missing value for summary data: ${JSON.stringify(
          toEntityDataTableSummary,
        )}`,
      );
    }
  }

  return { key, value };
};

export const getTagCountMessage = (tagCount: number): string =>
  `${TAG_COUNT_LABEL}: ${tagCount.toString().padStart(5, ' ')}`;

export const sortByOptionLabel = (
  a: IOption<unknown>,
  b: IOption<unknown>,
): number => {
  const filterNameA: string = a.label.toLocaleLowerCase();
  const filterNameB: string = b.label.toLocaleLowerCase();

  if (filterNameA.startsWith('_') && !filterNameB.startsWith('_')) {
    return -1;
  } else if (!filterNameA.startsWith('_') && filterNameB.startsWith('_')) {
    return 1;
  } else if (filterNameA < filterNameB) {
    return -1;
  } else if (filterNameA > filterNameB) {
    return 1;
  }

  return 0;
};

export const getSelectedCellsMapDimensions = (
  selectedCellsMap: TSelectedCellsMap,
): ISelectedCellsMapDimensions => {
  const columnKeys: string[] = Object.keys(selectedCellsMap);
  const numSelectedColumns: number = columnKeys.length;
  const startPosition: IDataGridPosition = {
    idx: Number.MAX_SAFE_INTEGER,
    rowIdx: Number.MAX_SAFE_INTEGER,
  };
  let numSelectedRows: number = -1;

  columnKeys.forEach((columnKey: string) => {
    const column: TColumn = parseInt(columnKey, 10) as TColumn;
    const selectedCellsRow: TSelectedCellsRow = selectedCellsMap[column];
    const rowKeys: string[] = Object.keys(selectedCellsRow);

    if (numSelectedRows === -1) {
      numSelectedRows = rowKeys.length;
    } else if (rowKeys.length !== numSelectedRows) {
      throw new Error('Invalid selectedCellsMap dimensions');
    }

    rowKeys.forEach((rowKey: string) => {
      const row: TRow = parseInt(rowKey, 10) as TRow;

      if (row < startPosition.rowIdx) {
        startPosition.rowIdx = row;
      }
    });

    if (column < startPosition.idx) {
      startPosition.idx = column;
    }
  });

  return { numSelectedColumns, numSelectedRows, startPosition };
};

export const updateColumns = <
  C extends Column<R, S>,
  R extends IIndexable,
  S extends IIndexable,
>(
  matrix: TDataGridMatrix,
  columns: C[],
  rows: R[],
  rowIdx: number,
  rowStartIdx: number,
  columnStartIdx: number,
  columnEndIdx: number,
  updateRowWithValue?: (
    value: string,
    targetColumnKey: string,
    targetRow: R,
  ) => R,
  updateSingle?: string,
) => {
  if (updateRowWithValue !== undefined) {
    for (
      let columnIdx: number = columnStartIdx;
      columnIdx <= columnEndIdx;
      columnIdx += 1
    ) {
      const value: string =
        updateSingle === undefined
          ? matrix[rowIdx - rowStartIdx][columnIdx - columnStartIdx]
          : updateSingle;
      const columnKey: string = columns[columnIdx].key;
      const targetRow: R = rows[rowIdx];
      const updatedRow: R = updateRowWithValue(value, columnKey, targetRow);

      rows[rowIdx] = updatedRow;
    }
  }
};

export const getDataGridSelectionContext =
  (): Context<IDataGridSelectionContext> =>
    createContext<IDataGridSelectionContext>({
      dataGridHasFocus: false,
      onExitEditCallback: undefined,
      selectedCells: EMPTY_CELLS_SELECTION,
      selectedPrimaryCell: undefined,
      selectedSourceCells: EMPTY_CELLS_SELECTION,
      setDataGridHasFocus: NO_OP_HANDLER,
      setOnExitEditCallback: NO_OP_HANDLER,
      setSelectedCells: NO_OP_HANDLER,
      setSelectedPrimaryCell: NO_OP_HANDLER,
      setSelectedSourceCells: NO_OP_HANDLER,
    });
