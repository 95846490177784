import { SplitCellsOutlined } from '@ant-design/icons';
import IconButton from 'components/atoms/IconButton/IconButton';
import InputNumber from 'components/atoms/InputNumber/InputNumber';
import SeparatedRowLayout from 'components/atoms/SeparatedRowLayout/SeparatedRowLayout';
import DateTimeRangePicker from 'components/molecules/DateTimeRangePicker/DateTimeRangePicker';
import EditorFooter from 'components/molecules/EditorFooter/EditorFooter';
import Modal from 'components/molecules/Modal/Modal';
import RadioGroup, {
  IRadioGroupProps,
} from 'components/molecules/RadioGroup/RadioGroup';
import Select from 'components/molecules/Select/Select';
import Tooltip from 'components/molecules/Tooltip/Tooltip';
import {
  MODAL_WIDTH,
  START_STOP_PLACEHOLDERS,
  TIME_SPLIT_OPTIONS,
  TIME_UNIT_OPTIONS,
} from 'components/organisms/DetailAdHocSplitProfile/constants';
import CustomInterval from 'components/organisms/DetailAdHocSplitProfile/CustomInterval';
import {
  getDisabledHours,
  getDisabledMinutes,
  getShowTime,
} from 'components/organisms/DetailAdHocSplitProfile/helpers';
import {
  BUTTON_ICON_DIMENSIONS,
  COLUMN_LAYOUT_SHARED_STYLES,
  INPUT_HEIGHT,
  STANDARD_SPACING,
  STANDARD_SPACING_VALUE,
} from 'constants/styles';
import { DATE_TIME_FORMAT } from 'constants/time';
import { EProfileTimeSplit, EProfileTimeUnit } from 'enums/Detail';
import { ERequestType } from 'enums/ETag';
import { IOption } from 'interfaces/Component';
import { IAdHocProfile, ICustomInterval } from 'interfaces/Detail';
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import { TTimeZone, TZonedDateTimeRange } from 'types/DateTime';
import { TErrorMessage } from 'types/Error';
import { getInitialCustomInterval, isValidProfileDateTime } from 'utils/detail';
import { captureError } from 'utils/error';
import { encodeIds, isEmptyValue } from 'utils/general';
import { ZonedDateTime } from 'utils/zonedDateTime';
import { IEditProfileDataGridEditedValues } from '../ProfileInformationView/types';

const CHILD_SPACING = `
  > :not(:last-child) {
    margin-bottom: ${(STANDARD_SPACING_VALUE * 3) / 4}px;
  }

  > :last-child {
    margin-top: ${(STANDARD_SPACING_VALUE * 3) / 2}px;
  }
`;

const SplitIcon = styled(SplitCellsOutlined)`
  ${BUTTON_ICON_DIMENSIONS}
`;

const TimeSplitRadioGroup = styled(
  (props: IRadioGroupProps<EProfileTimeSplit>): JSX.Element => (
    <RadioGroup<EProfileTimeSplit> {...props} />
  ),
)`
  ${COLUMN_LAYOUT_SHARED_STYLES}
  ${CHILD_SPACING}
`;

const ModalLayout = styled.div`
  > :not(:last-child) {
    margin-bottom: ${STANDARD_SPACING};
  }
`;

const ProfileTimeLayout = styled(SeparatedRowLayout)`
  align-items: flex-start;
`;

const Customisations = styled.div`
  ${COLUMN_LAYOUT_SHARED_STYLES}
  ${CHILD_SPACING}

  flex: 1;
`;

const CustomisationsFiller = styled.div`
  height: 20px;
`;

const CustomisationsLabel = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: center;

  > div {
    height: ${INPUT_HEIGHT};
    padding-top: 4px;

    :nth-child(2) {
      padding-top: 8px;
    }
  }
`;

const FixedIntervalRow = styled(SeparatedRowLayout)`
  margin-top: -4px;
`;

const CustomIntervalsRow = styled(SeparatedRowLayout)`
  && {
    align-items: flex-start;
    margin-top: 0;
  }
`;

const CustomIntervalsLayout = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  > * {
    margin-bottom: 4px;
  }

  > :not(:last-child) {
    margin-right: 4px;
  }
`;

interface IDetailAdHocProfileSplitProps {
  defaultStartDate?: string | null;
  encodedPermissionsId: string;
  isDisabled?: boolean;
  onApply: (
    adHocProfile: IAdHocProfile,
    editedValues?: IEditProfileDataGridEditedValues | undefined,
  ) => void;
  requestType?: ERequestType;
  startDate?: string | null;
  timeZone: TTimeZone;
}

const DetailAdHocProfileSplit = ({
  defaultStartDate,
  encodedPermissionsId,
  isDisabled,
  onApply,
  requestType,
  startDate,
  timeZone,
}: IDetailAdHocProfileSplitProps): JSX.Element => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [profileDateTimeRange, setProfileDateTimeRange] =
    useState<TZonedDateTimeRange>(
      isEmptyValue(startDate)
        ? null
        : [ZonedDateTime.parseIso(startDate!, timeZone), null],
    );
  const [selectedTimeSplit, setSelectedTimeSplit] = useState<
    EProfileTimeSplit | undefined
  >(EProfileTimeSplit.Hourly);
  const [selectedTimeUnit, setSelectedTimeUnit] = useState<
    EProfileTimeUnit | undefined
  >(EProfileTimeUnit.Minute);
  const [fixedDuration, setFixedDuration] = useState<number | null>(null);
  const [customIntervals, setCustomIntervals] = useState<ICustomInterval[]>([
    getInitialCustomInterval(),
  ]);
  const [errorMessage, setErrorMessage] = useState<TErrorMessage>(null);
  const [isConfirmDisabled, setIsConfirmDisabled] = useState<boolean>(false);
  const buttonRef = useRef<HTMLElement>() as MutableRefObject<HTMLElement>;

  useEffect(() => {
    setIsConfirmDisabled(
      profileDateTimeRange === null ||
        profileDateTimeRange[0] === null ||
        profileDateTimeRange[1] === null,
    );
  }, [profileDateTimeRange]);

  useEffect(() => {
    if (
      requestType === ERequestType.AtfAdjustment ||
      requestType === ERequestType.BtfAdjustment
    ) {
      setProfileDateTimeRange([
        ZonedDateTime.parseIso(defaultStartDate!, timeZone),
        null,
      ]);
    }
  }, [requestType, defaultStartDate, timeZone]);

  const disabledDate = useCallback(
    (currentDateTime: ZonedDateTime | null): boolean =>
      startDate === undefined
        ? false
        : !isValidProfileDateTime(startDate, requestType, currentDateTime)
            .isValid,
    [requestType, startDate],
  );

  const handleClick = () => {
    setShowModal(true);
  };

  const handleSplitProfileValueChange = (_number: number | undefined) => {
    let validatedNumber: number | null = _number === undefined ? null : _number;

    // This must be a runtime check since the input box can return a string
    // even though we are typing as a number or undefined !!!
    if (typeof _number === 'string') {
      validatedNumber = _number === '' ? null : parseInt(_number, 10);
    }

    setFixedDuration(validatedNumber);
  };

  const handleCustomIntervalChange =
    (index: number) => (customInterval: ICustomInterval) => {
      setCustomIntervals(
        (previousCustomIntervals: ICustomInterval[]): ICustomInterval[] => {
          const updatedCustomIntervals: ICustomInterval[] = [
            ...previousCustomIntervals,
          ];

          if (
            customInterval.genRequest === null &&
            customInterval.time === null
          ) {
            updatedCustomIntervals.splice(index, 1);

            if (updatedCustomIntervals.length === 0) {
              updatedCustomIntervals.push(getInitialCustomInterval());
            }
          } else {
            updatedCustomIntervals[index] = customInterval;
          }

          return updatedCustomIntervals;
        },
      );
    };

  const handleCustomIntervalAdd = () => {
    setCustomIntervals(
      (previousCustomIntervals: ICustomInterval[]): ICustomInterval[] => {
        const updatedCustomIntervals: ICustomInterval[] = [
          ...previousCustomIntervals,
        ];

        updatedCustomIntervals.push(getInitialCustomInterval());

        return updatedCustomIntervals;
      },
    );
  };

  const handleCancel = async () => {
    setShowModal(false);
  };

  const handleApply = async () => {
    try {
      setErrorMessage(null);

      const filteredCustomIntervals: ICustomInterval[] = customIntervals.filter(
        (customInterval: ICustomInterval): boolean =>
          customInterval.time !== null,
      );

      onApply({
        customIntervals: filteredCustomIntervals,
        fixedDuration,
        startDateTime: profileDateTimeRange![0]!,
        stopDateTime: profileDateTimeRange![1]!,
        timeSplit: selectedTimeSplit,
        timeUnit: selectedTimeUnit,
        genValue: null,
        skipEditedValues: true,
      });

      if (filteredCustomIntervals.length > 0) {
        setCustomIntervals(filteredCustomIntervals);
      } else {
        setCustomIntervals([getInitialCustomInterval()]);
      }

      setShowModal(false);
    } catch (error: any) {
      captureError(error);

      setErrorMessage(error.message);
    }
  };

  const customIntervalElements = useMemo(() => {
    const customIntervalElements: JSX.Element[] = [];
    let previousCustomInterval: ICustomInterval = {
      genRequest: null,
      time: profileDateTimeRange === null ? null : profileDateTimeRange[0],
    };

    customIntervals.forEach(
      (customInterval: ICustomInterval, index: number) => {
        customIntervalElements.push(
          <CustomInterval
            customInterval={customInterval}
            disabledHours={
              previousCustomInterval.time === null || index > 0
                ? undefined
                : getDisabledHours(previousCustomInterval.time.getHour())
            }
            disabledMinutes={
              previousCustomInterval.time === null || index > 0
                ? undefined
                : getDisabledMinutes(
                    previousCustomInterval.time.getHour(),
                    previousCustomInterval.time.getMinute(),
                  )
            }
            isAddDisabled={index < customIntervals.length - 1}
            isDisabled={selectedTimeSplit !== EProfileTimeSplit.CustomInterval}
            key={index.toString()}
            onAdd={handleCustomIntervalAdd}
            onChange={handleCustomIntervalChange(index)}
            timeZone={timeZone}
          />,
        );

        if (customInterval !== null) {
          previousCustomInterval = customInterval;
        }
      },
    );

    return customIntervalElements;
  }, [customIntervals, profileDateTimeRange, selectedTimeSplit, timeZone]);

  const hasProfileDateRange: boolean =
    profileDateTimeRange !== null &&
    profileDateTimeRange.length === 2 &&
    profileDateTimeRange[0] !== null &&
    profileDateTimeRange[1] !== null;

  return (
    <>
      <Tooltip title={'Ad Hoc Profile Split'}>
        <IconButton
          buttonRef={buttonRef}
          icon={<SplitIcon />}
          isDisabled={isDisabled}
          onClick={handleClick}
        />
      </Tooltip>
      <Modal
        footer={
          <EditorFooter
            confirmLabel='Apply'
            encodedPermissionsId={encodeIds([encodedPermissionsId, 'footer'])}
            errorMessage={errorMessage}
            isConfirmDisabled={isConfirmDisabled}
            onCancel={handleCancel}
            onConfirm={handleApply}
          />
        }
        isVisible={showModal}
        onCancel={handleCancel}
        title={'Ad Hoc Profile Split'}
        width={MODAL_WIDTH}
      >
        <ModalLayout>
          <SeparatedRowLayout>
            <div>Profile Date Time Range:</div>
            <DateTimeRangePicker
              allowClear={true}
              disabledDate={isEmptyValue(startDate) ? undefined : disabledDate}
              format={DATE_TIME_FORMAT}
              onChange={setProfileDateTimeRange}
              placeholder={START_STOP_PLACEHOLDERS}
              showTime={getShowTime(timeZone)}
              timeZone={timeZone}
              value={profileDateTimeRange}
            />
          </SeparatedRowLayout>
          <ProfileTimeLayout>
            <div>Profile Time Interval:</div>
            <TimeSplitRadioGroup
              isDisabled={!hasProfileDateRange}
              onChange={setSelectedTimeSplit}
              options={TIME_SPLIT_OPTIONS}
              value={selectedTimeSplit}
              valueToUid={(value: EProfileTimeSplit): string => value as string}
            />
            <Customisations>
              {TIME_SPLIT_OPTIONS.map(
                (timeSplitOption: IOption<EProfileTimeSplit>): JSX.Element => {
                  switch (timeSplitOption.value) {
                    case EProfileTimeSplit.FixedDuration:
                      return (
                        <FixedIntervalRow key={timeSplitOption.value}>
                          <CustomisationsLabel>
                            <div>Split Profile Every</div>
                          </CustomisationsLabel>
                          <InputNumber
                            isDisabled={
                              selectedTimeSplit !==
                              EProfileTimeSplit.FixedDuration
                            }
                            min={1}
                            onChange={handleSplitProfileValueChange}
                            value={fixedDuration}
                          />
                          <Select
                            isDisabled={
                              selectedTimeSplit !==
                              EProfileTimeSplit.FixedDuration
                            }
                            onChange={setSelectedTimeUnit}
                            options={TIME_UNIT_OPTIONS}
                            value={selectedTimeUnit}
                            valueToUid={(value: EProfileTimeUnit): string =>
                              value as string
                            }
                          />
                        </FixedIntervalRow>
                      );
                    case EProfileTimeSplit.CustomInterval:
                      return (
                        <CustomIntervalsRow key={timeSplitOption.value}>
                          <CustomisationsLabel>
                            <div>Split Profile At</div>
                            <div>With Gen Value</div>
                          </CustomisationsLabel>
                          <CustomIntervalsLayout>
                            {customIntervalElements}
                          </CustomIntervalsLayout>
                        </CustomIntervalsRow>
                      );
                    default:
                      return (
                        <CustomisationsFiller key={timeSplitOption.value} />
                      );
                  }
                },
              )}
            </Customisations>
          </ProfileTimeLayout>
        </ModalLayout>
      </Modal>
    </>
  );
};

export default DetailAdHocProfileSplit;
