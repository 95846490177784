import { ClockCircleOutlined } from '@ant-design/icons';
import { Popover as AntDesignPopover } from 'antd';
import { AxiosResponse } from 'axios';
import IconButton from 'components/atoms/IconButton/IconButton';
import { getStatusLabelFromStatus } from 'components/molecules/ScheduleSubmission/helpers';
import { EDraftScheduledStatusLabel } from 'components/molecules/ScheduleSubmission/interfaces';
import ScheduleSubmissionPopover from 'components/molecules/ScheduleSubmission/ScheduleSubmissionPopover';
import Tooltip from 'components/molecules/Tooltip/Tooltip';
import {
  BUTTON_ICON_DIMENSIONS,
  ERROR_RED,
  SUCCESS_GREEN,
  WARNING_ORANGE,
} from 'constants/styles';
import { EDraftScheduleStatus } from 'enums/ETag';
import {
  IETagDeleteScheduledDraftSubmissionResponse,
  IETagScheduledDraftResponse,
  IETagScheduledDraftSubmission,
  IETagScheduleDraftSubmissionResponse,
  IETagValidationResultList,
} from 'interfaces/ETag';
import { useEffect, useState } from 'react';
import {
  cancelScheduledDraftSubmission,
  getScheduledSubmissionForDraft,
  scheduleDraftSubmission,
} from 'services/agent/tags/requests';
import styled from 'styled-components';
import { TTimeZone } from 'types/DateTime';
import { TErrorMessage } from 'types/Error';
import { TToEntityId } from 'types/ToEntity';
import useAsyncEffect from 'use-async-effect';
import {
  extractValidationFailureMessages,
  extractValidationWarningMessages,
} from 'utils/eTag';
import { encodeIds, isSuccessStatus } from 'utils/general';
import { ZonedDateTime } from 'utils/zonedDateTime';

const ScheduleIcon = styled(ClockCircleOutlined)`
  color: ${(props) => props.color};
  ${BUTTON_ICON_DIMENSIONS}
`;
interface IScheduleSubmissionProps {
  contactName: string;
  draftId: string;
  encodedPermissionsId: string;
  isDisabled: boolean;
  numberOfRefreshes: number;
  startDate: string | null;
  timeZone: TTimeZone;
  toEntityId: TToEntityId;
}

const ScheduleSubmission = ({
  contactName,
  draftId,
  encodedPermissionsId,
  isDisabled,
  numberOfRefreshes,
  startDate,
  timeZone,
  toEntityId,
}: IScheduleSubmissionProps): JSX.Element => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [submitErrorMessage, setSubmitErrorMessage] =
    useState<TErrorMessage>(null);
  const [submissionDateTime, setSubmissionDateTime] =
    useState<ZonedDateTime | null>(null);
  const [status, setStatus] = useState<EDraftScheduledStatusLabel>(
    EDraftScheduledStatusLabel.Empty,
  );
  const [currentScheduledTime, setCurrentScheduledTime] =
    useState<ZonedDateTime | null>(null);
  const [scheduledSubmission, setScheduledSubmission] =
    useState<IETagScheduledDraftSubmission | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [validationFailures, setValidationFailures] = useState<string[]>([]);
  const [validationWarnings, setValidationWarnings] = useState<string[]>([]);
  const [previousNumOfRefreshes, setPreviousNumOfRefreshes] =
    useState<number>(0);

  const color: string =
    status === EDraftScheduledStatusLabel.Scheduled
      ? SUCCESS_GREEN
      : status === EDraftScheduledStatusLabel.Failed
      ? ERROR_RED
      : status === EDraftScheduledStatusLabel.InProgress
      ? WARNING_ORANGE
      : '';

  useEffect(() => {
    if (scheduledSubmission === null) {
      setStatus(EDraftScheduledStatusLabel.NotScheduled);
      setCurrentScheduledTime(null);
      setSubmissionDateTime(null);
    } else {
      setStatus(getStatusLabelFromStatus(scheduledSubmission.status));
      const scheduledDraftSubmissionTime: ZonedDateTime =
        ZonedDateTime.parseIso(scheduledSubmission.execution_time, timeZone);
      setCurrentScheduledTime(scheduledDraftSubmissionTime);
      setSubmissionDateTime(scheduledDraftSubmissionTime);
      if (scheduledSubmission.status === EDraftScheduleStatus.Failed) {
        setSubmitErrorMessage(null);
        setValidationFailures([]);
        setValidationWarnings([]);
      }
    }
  }, [scheduledSubmission, timeZone]);

  const handleSubmit = async () => {
    if (draftId && submissionDateTime) {
      setIsLoading(true);
      const response: AxiosResponse<IETagScheduleDraftSubmissionResponse> =
        await scheduleDraftSubmission(toEntityId, {
          draft_id: draftId,
          execution_time: submissionDateTime.toIsoString(),
          scheduled_by: contactName,
        });
      const scheduleDraftSubmissionResponse: IETagScheduleDraftSubmissionResponse =
        response.data;

      setValidationWarnings(
        extractValidationWarningMessages(
          scheduleDraftSubmissionResponse.response.warning_list ?? [],
        ),
      );

      if (!isSuccessStatus(response.status)) {
        const response400: IETagValidationResultList =
          scheduleDraftSubmissionResponse.response;
        const msgs: string[] = extractValidationFailureMessages(
          response400.failure_list ?? [],
        );
        if (msgs.length === 0) {
          setSubmitErrorMessage(scheduleDraftSubmissionResponse.errorMessage);
        } else {
          setValidationFailures(msgs);
        }
      } else {
        setStatus(EDraftScheduledStatusLabel.Scheduled);
        setCurrentScheduledTime(submissionDateTime);
        setIsSuccess(true);
        setValidationFailures([]);
        setValidationWarnings([]);
      }
      setIsSuccess(false);
      setIsLoading(false);
    }
  };

  const handleCancel = async () => {
    if (draftId) {
      setIsLoading(true);
      const response: AxiosResponse<IETagDeleteScheduledDraftSubmissionResponse> =
        await cancelScheduledDraftSubmission(toEntityId, draftId);
      const scheduleDraftSubmissionResponse: IETagDeleteScheduledDraftSubmissionResponse =
        response.data;

      if (!isSuccessStatus(response.status)) {
        setSubmitErrorMessage(scheduleDraftSubmissionResponse.errorMessage);
      } else {
        setStatus(EDraftScheduledStatusLabel.NotScheduled);
        setScheduledSubmission(null);
      }
      setIsSuccess(false);
      setIsLoading(false);
    }
  };

  useAsyncEffect(async () => {
    if (
      scheduledSubmission === null ||
      isVisible ||
      numberOfRefreshes > previousNumOfRefreshes
    ) {
      //This extra prop, updated and passed in when the view refresh is triggered, allows us to update the state of
      //this ScheduleSubmission component when the view is updated instead of just when the whole page is refreshed.
      //Updating the state rerenders the component, allowing values like the icon color to be properly recomputed based
      //on the current state of the submission.
      setPreviousNumOfRefreshes(numberOfRefreshes);
      setIsLoading(true);
      const response: AxiosResponse<IETagScheduledDraftResponse> =
        await getScheduledSubmissionForDraft(toEntityId, draftId);
      const scheduleDraftSubmissionResponse: IETagScheduledDraftResponse =
        response.data;

      if (!isSuccessStatus(response.status)) {
        setSubmitErrorMessage(response.data.errorMessage);
        throw new Error(scheduleDraftSubmissionResponse.errorMessage!);
      }

      const data: IETagScheduledDraftSubmission[] =
        scheduleDraftSubmissionResponse.response;
      if (data.length > 0) {
        const scheduledSubmission: IETagScheduledDraftSubmission =
          data[data.length - 1];
        setScheduledSubmission(scheduledSubmission);
      } else {
        setScheduledSubmission(null);
      }
      setIsLoading(false);
    }
  }, [draftId, numberOfRefreshes, timeZone, status, isVisible]);

  const isScheduleDisabled: boolean =
    submissionDateTime === null ||
    submissionDateTime.isSame(currentScheduledTime) ||
    status === EDraftScheduledStatusLabel.Failed;

  return (
    <Tooltip title='Schedule Draft Submission' isDisabled={isVisible}>
      <AntDesignPopover
        content={
          <ScheduleSubmissionPopover
            encodedPermissionsId={encodeIds(
              [encodedPermissionsId, 'popover'],
              toEntityId,
            )}
            errorMessage={submitErrorMessage}
            isLoading={isLoading}
            isScheduleDisabled={isScheduleDisabled}
            isSuccess={isSuccess}
            onCancel={handleCancel}
            onSchedule={handleSubmit}
            onSubmissionDateTimeChange={setSubmissionDateTime}
            scheduledSubmission={scheduledSubmission}
            startDate={startDate}
            status={status}
            statusColor={color}
            submissionDateTime={submissionDateTime}
            timeZone={timeZone}
            toEntityId={toEntityId}
            validationErrors={validationFailures}
            validationWarnings={validationWarnings}
          />
        }
        destroyTooltipOnHide={true}
        onVisibleChange={setIsVisible}
        placement='bottom'
        trigger='click'
        visible={isVisible}
      >
        <IconButton
          encodedPermissionsId={encodeIds([encodedPermissionsId], toEntityId)}
          icon={<ScheduleIcon color={color} />}
          isDisabled={isDisabled}
        />
      </AntDesignPopover>
    </Tooltip>
  );
};

export default ScheduleSubmission;
