import { IETagMarketDetail, IETagMarketInfo } from 'interfaces/ETag';
import { TETagMarketInfoMarketData } from 'types/ETag';

export const getMarketData = (
  marketDetail: IETagMarketDetail,
  marketInfos: IETagMarketInfo[],
): TETagMarketInfoMarketData[] => {
  const marketData: TETagMarketInfoMarketData[] = [];

  marketInfos.forEach((marketInfo: IETagMarketInfo) => {
    if (marketInfo.market_info_market === marketDetail.market) {
      marketData.push(marketInfo.data);
    }
  });

  if (marketData.length === 0) {
    throw new Error(
      `Missing marketData for marketDetail.market: ${marketDetail.market}`,
    );
  }

  return marketData;
};
