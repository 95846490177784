import {
  EAttribute,
  EList,
  EOperation,
  EOperator,
} from 'components/organisms/ToEntityCustomFilterConfigurator/CustomFilterEditor/types';
import { ECustomFilterAttribute, ECustomFilterType } from 'enums/Filter';
import { IOption } from 'interfaces/Component';

export const UNARY_OPERATORS: ECustomFilterType[] = [ECustomFilterType.Not];

export const BINARY_OPERATORS: ECustomFilterType[] = [
  ECustomFilterType.And,
  ECustomFilterType.Or,
];

export const OPERANDS: ECustomFilterType[] = [
  ECustomFilterType.Equals,
  ECustomFilterType.StringBeginsWith,
  ECustomFilterType.StringContains,
  ECustomFilterType.StringEndsWith,
];

export const BOOLEAN_CUSTOM_FILTER_ATTRIBUTES: ECustomFilterAttribute[] = [
  ECustomFilterAttribute.ApprovalRight,
  ECustomFilterAttribute.LossesTag,
  ECustomFilterAttribute.TestTag,
  ECustomFilterAttribute.Curtailed,
];

export const ENUMERATION_CUSTOM_FILTER_ATTRIBUTES: ECustomFilterAttribute[] = [
  ECustomFilterAttribute.CompositeState,
  ECustomFilterAttribute.TagType,
  ECustomFilterAttribute.TimeClassification,
];

export const ENUMERATION_LIST_CUSTOM_FILTER_ATTRIBUTES: ECustomFilterAttribute[] =
  [
    ECustomFilterAttribute.MarketSegmentEnergyProduct,
    ECustomFilterAttribute.TransAllocProduct,
  ];

export const MISC_INFO_CUSTOM_FILTER_ATTRIBUTES: ECustomFilterAttribute[] = [
  ECustomFilterAttribute.Token,
  ECustomFilterAttribute.Value,
];

export const MISC_INFO_LIST_CUSTOM_FILTER_ATTRIBUTES: ECustomFilterAttribute[] =
  [
    ECustomFilterAttribute.MarketSegmentMiscInfo,
    ECustomFilterAttribute.PhysicalSegmentMiscInfo,
    ECustomFilterAttribute.TransAllocMiscInfo,
  ];

export const STRING_CUSTOM_FILTER_ATTRIBUTES: ECustomFilterAttribute[] = [
  ECustomFilterAttribute.CPSE,
  ECustomFilterAttribute.GCA,
  ECustomFilterAttribute.LCA,
  ECustomFilterAttribute.Sink,
  ECustomFilterAttribute.Source,
  ECustomFilterAttribute.TagNote,
];

export const STRING_LIST_CUSTOM_FILTER_ATTRIBUTES: ECustomFilterAttribute[] = [
  ECustomFilterAttribute.MarketSegmentContractNumber,
  ECustomFilterAttribute.MarketSegmentPSE,
  ECustomFilterAttribute.MO,
  ECustomFilterAttribute.NITS,
  ECustomFilterAttribute.PhysicalSegmentContractNumber,
  ECustomFilterAttribute.POD,
  ECustomFilterAttribute.POR,
  ECustomFilterAttribute.SE,
  ECustomFilterAttribute.TransAllocCustomer,
  ECustomFilterAttribute.TSP,
  ECustomFilterAttribute.TSR,
];

export const STRING_LIST_ENHANCED_CUSTOM_FILTER_ATTRIBUTES: ECustomFilterAttribute[] =
  [
    ECustomFilterAttribute.MarketSegmentContractNumber,
    ECustomFilterAttribute.MarketSegmentPSE,
    ECustomFilterAttribute.MO,
  ];

export const STRING_CUSTOM_FILTER_TYPE: ECustomFilterType[] = [
  ECustomFilterType.Equals,
  ECustomFilterType.StringBeginsWith,
  ECustomFilterType.StringContains,
  ECustomFilterType.StringEndsWith,
];

export const LIST_CUSTOM_FILTER_TYPE: ECustomFilterType[] = [
  ECustomFilterType.AllMatch,
  ECustomFilterType.AnyMatch,
  ECustomFilterType.FirstInList,
  ECustomFilterType.LastInList,
];

export const MISC_INFO_MARKET_SEGMENT_ATTRIBUTES: EAttribute[] = [
  EAttribute.MarketSegmentMiscInfoToken,
  EAttribute.MarketSegmentMiscInfoValue,
];

export const MISC_INFO_PHYSICAL_SEGMENT_ATTRIBUTES: EAttribute[] = [
  EAttribute.PhysicalSegmentMiscInfoToken,
  EAttribute.PhysicalSegmentMiscInfoValue,
];

export const MISC_INFO_TRANS_ALLOC_ATTRIBUTES: EAttribute[] = [
  EAttribute.TransAllocMiscInfoToken,
  EAttribute.TransAllocMiscInfoValue,
];

export const MISC_INFO_TOKEN_ATTRIBUTES: EAttribute[] = [
  EAttribute.MarketSegmentMiscInfoToken,
  EAttribute.PhysicalSegmentMiscInfoToken,
  EAttribute.TransAllocMiscInfoToken,
];

export const MISC_INFO_VALUE_ATTRIBUTES: EAttribute[] = [
  EAttribute.MarketSegmentMiscInfoValue,
  EAttribute.PhysicalSegmentMiscInfoValue,
  EAttribute.TransAllocMiscInfoValue,
];

export const ATTRIBUTE_OPTIONS: IOption<EAttribute>[] = [
  {
    label: 'Approval Right',
    value: EAttribute.ApprovalRight,
  },
  {
    label: 'Composite State',
    value: EAttribute.CompositeState,
  },
  {
    label: 'CPSE',
    value: EAttribute.CPSE,
  },
  {
    label: 'Curtailed',
    value: EAttribute.Curtailed,
  },
  {
    label: 'GCA',
    value: EAttribute.GCA,
  },
  {
    label: 'LCA',
    value: EAttribute.LCA,
  },
  {
    label: 'Losses Tag',
    value: EAttribute.LossesTag,
  },
  {
    label: 'Market Segment Contract Number',
    value: EAttribute.MarketSegmentContractNumber,
  },
  {
    label: 'Market Segment Energy Product',
    value: EAttribute.MarketSegmentEnergyProduct,
  },
  {
    label: 'Market Segment Misc Info Token',
    value: EAttribute.MarketSegmentMiscInfoToken,
  },
  {
    label: 'Market Segment Misc Info Value',
    value: EAttribute.MarketSegmentMiscInfoValue,
  },
  {
    label: 'Market Segment PSE',
    value: EAttribute.MarketSegmentPSE,
  },
  {
    label: 'MO',
    value: EAttribute.MO,
  },
  {
    label: 'NITS',
    value: EAttribute.NITS,
  },
  {
    label: 'Physical Segment Contract Number',
    value: EAttribute.PhysicalSegmentContractNumber,
  },
  {
    label: 'Physical Segment Misc Info Token',
    value: EAttribute.PhysicalSegmentMiscInfoToken,
  },
  {
    label: 'Physical Segment Misc Info Value',
    value: EAttribute.PhysicalSegmentMiscInfoValue,
  },
  {
    label: 'POD',
    value: EAttribute.POD,
  },
  {
    label: 'POR',
    value: EAttribute.POR,
  },
  {
    label: 'SE',
    value: EAttribute.SE,
  },
  {
    label: 'Sink',
    value: EAttribute.Sink,
  },
  {
    label: 'Source',
    value: EAttribute.Source,
  },
  {
    label: 'Tag Note',
    value: EAttribute.TagNote,
  },
  {
    label: 'Tag Type',
    value: EAttribute.TagType,
  },
  {
    label: 'Test Tag',
    value: EAttribute.TestTag,
  },
  {
    label: 'Time Classification',
    value: EAttribute.TimeClassification,
  },
  {
    label: 'Transmission Allocation Customer',
    value: EAttribute.TransAllocCustomer,
  },
  {
    label: 'Transmission Allocation Misc Info Token',
    value: EAttribute.TransAllocMiscInfoToken,
  },
  {
    label: 'Transmission Allocation Misc Info Value',
    value: EAttribute.TransAllocMiscInfoValue,
  },
  {
    label: 'Transmission Allocation Product',
    value: EAttribute.TransAllocProduct,
  },
  {
    label: 'TSP',
    value: EAttribute.TSP,
  },
  {
    label: 'TSR',
    value: EAttribute.TSR,
  },
];

export const OPERATOR_OPTIONS: IOption<EOperator>[] = [
  {
    label: 'And',
    value: EOperator.And,
  },
  {
    label: 'Or',
    value: EOperator.Or,
  },
  {
    label: 'Not',
    value: EOperator.Not,
  },
];

export const OPERATION_OPTIONS: IOption<EOperation>[] = [
  {
    label: 'Criteria',
    value: EOperation.Criteria,
  },
  {
    label: 'Operator',
    value: EOperation.Operator,
  },
  {
    label: 'Operator Before',
    value: EOperation.OperatorBefore,
  },
];

export const LIST_OPTIONS: IOption<EList>[] = [
  {
    label: 'Any',
    value: EList.Any,
  },
  {
    label: 'All',
    value: EList.All,
  },
];

export const ENHANCED_LIST_OPTIONS: IOption<EList>[] = LIST_OPTIONS.concat([
  {
    label: 'First in List',
    value: EList.First,
  },
  {
    label: 'Last in List',
    value: EList.Last,
  },
]);
