import styled from 'styled-components';
import Select, { ISelectProps } from '../Select/Select';
import React, { useEffect, useState } from 'react';
import { IOption } from '../../../interfaces/Component';
import SeparatedRowLayout from '../../atoms/SeparatedRowLayout/SeparatedRowLayout';
import { useDispatch } from 'react-redux';
import { setTenantSelectedToEntityId } from '../../../reduxes/User/actions';
import useTenantInfo from '../../../hooks/useTenantInfo';

interface IEntitySelectorProps {
  entityOptions: IOption<string>[];
  handleEntityChange: (entity: string | undefined) => void;
}

const EntitySelectContainer = (props: IEntitySelectorProps): JSX.Element => {
  const { entityOptions, handleEntityChange } = props;
  return (
    <SeparatedRowLayoutFullWidth>
      <SelectWrapper>
        <div>Entity:</div>
        <EntitySelect
          placeholder={'Select Entity'}
          showSearch={false}
          allowClear={false}
          dropdownMatchSelectWidth={false}
          options={entityOptions}
          valueToUid={(value: string): string => value}
          onChange={handleEntityChange}
        ></EntitySelect>
      </SelectWrapper>
    </SeparatedRowLayoutFullWidth>
  );
};

const EntitySelect = styled((props: ISelectProps<string>) =>
  Select<string>(props),
)`
  width: 100%;
`;

const SelectWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 5px;
  align-items: center;
  width: 100%;
`;

const SeparatedRowLayoutFullWidth = styled(SeparatedRowLayout)`
  width: 95%;
`;

export const MultipleEntityWrapper = (props: any): JSX.Element => {
  const tenantInfo = useTenantInfo();
  const { toEntities } = tenantInfo;
  const [entityOptions, setEntityOptions] = useState<IOption<string>[]>([]);
  const dispatch = useDispatch();
  const [displayChildren, setDisplayChildren] = useState<boolean>();

  useEffect(() => {
    const options: IOption<string>[] = [];
    if (toEntities && toEntities.length > 0) {
      toEntities.forEach((entity) => {
        options.push({
          value: entity.to_entity,
          label: `${entity.entity_code} - ${entity.to_entity}`,
        });
      });
      setEntityOptions(options);
    }
  }, [toEntities]);

  useEffect(() => {
    if (props.toEntityId || props.byPassEntityCheck) {
      setDisplayChildren(true);
    }
  }, [props]);

  const handleEntityChange = (entity: string | undefined) => {
    if (entity) {
      dispatch(setTenantSelectedToEntityId({ toEntityId: entity }));
      setDisplayChildren(true);
    }
  };

  return (
    <>
      {displayChildren ? (
        props.children
      ) : (
        <EntitySelectContainer
          entityOptions={entityOptions}
          handleEntityChange={handleEntityChange}
        />
      )}
    </>
  );
};
