import { AxiosResponse } from 'axios';
import { EAlertAcknowledged } from 'enums/Alert';
import { ESeverity } from 'enums/General';
import {
  IAlert,
  IAlertConfiguration,
  IAlertsRetrieveResponse,
  IConfiguredAlert,
} from 'interfaces/Alert';
import { retrieveAlerts } from 'services/alert/alerts';
import { TAlertId, TAlertRuleId } from 'types/Alert';
import { TZonedDateTimeRange } from 'types/DateTime';
import { TErrorMessage } from 'types/Error';
import { TNextToken } from 'types/Summary';
import { TToEntityId } from 'types/ToEntity';
import { captureError } from 'utils/error';
import { isSuccessStatus } from 'utils/general';
import { ZonedDateTime } from 'utils/zonedDateTime';
import { IToEntity } from '../../../interfaces/ToEntity';

export const loadAlerts = async (
  acknowledgeStatus: EAlertAcknowledged,
  alertConfigurationsMap: Record<TAlertRuleId, IAlertConfiguration>,
  alertRuleIds: TAlertRuleId[],
  alertSeverities: ESeverity[],
  alerts: IAlert[],
  alertsErrorMap: Record<TAlertId, TErrorMessage>,
  dateTimes: TZonedDateTimeRange,
  next_token: TNextToken | undefined,
  setAlerts: (alerts: IAlert[]) => void,
  setAlertsForHeightMap: (
    alerts: IAlert[],
    alertsErrorMap: Record<TAlertId, TErrorMessage>,
  ) => void,
  setErrorMessage: (errorMessage: TErrorMessage) => void,
  setIsLoading: (isLoading: boolean) => void,
  setLoadedRows: (loadedRows: true[]) => void,
  setNextToken: (next_token: TNextToken) => void,
  toEntitiesIds?: TToEntityId[],
  toEntities?: IToEntity[],
) => {
  const start: ZonedDateTime | null = dateTimes === null ? null : dateTimes[0];
  const stop: ZonedDateTime | null = dateTimes === null ? null : dateTimes[1];

  if (start !== null && stop !== null && toEntitiesIds) {
    try {
      setIsLoading(true);

      setErrorMessage(null);

      const cutOffTimeInMinutes: number[] = alertRuleIds.map(
        (alert_rule_id: TAlertRuleId): number =>
          alertConfigurationsMap[alert_rule_id].cutoff_time_in_mins,
      );

      let updatedAlerts: IAlert[] = [];

      for (const toEntity of toEntitiesIds) {
        const entity = toEntities
          ? toEntities.find((entity) => entity.to_entity === toEntity)
          : null;
        const entityCode = entity ? entity.entity_code : '';
        const response: AxiosResponse<IAlertsRetrieveResponse> =
          await retrieveAlerts(
            toEntity,
            start,
            stop,
            alertSeverities,
            acknowledgeStatus,
            alertRuleIds,
            cutOffTimeInMinutes,
            next_token,
          );
        const alertsRetrieveResponse: IAlertsRetrieveResponse = response.data;

        if (!isSuccessStatus(response.status)) {
          throw new Error(alertsRetrieveResponse.errorMessage!);
        }

        setNextToken(alertsRetrieveResponse.response.next_token);
        updatedAlerts = alerts.concat(
          alertsRetrieveResponse.response.alerts
            .map((alert) => {
              if (toEntitiesIds.length > 1) {
                alert.entity_code = entityCode;
              }

              return alert;
            })
            .concat(updatedAlerts),
        );

        const updatedLoadedRows: true[] = new Array(updatedAlerts.length);
        updatedLoadedRows.fill(true);
        setLoadedRows(updatedLoadedRows);
      }
      setNextToken(null);
      // Trigger alert height calculations
      setAlertsForHeightMap(updatedAlerts, alertsErrorMap);

      setAlerts(updatedAlerts);
    } catch (error: any) {
      captureError(error);

      setErrorMessage(
        'An error occurred during loading. Please try again later.',
      );
    } finally {
      setIsLoading(false);
    }
  }
};

const mergeLiveAlertWithAlerts = (
  liveAlert: IConfiguredAlert,
  alerts: IAlert[],
  dateTimes: TZonedDateTimeRange,
  acknowledgeStatus: EAlertAcknowledged,
  alertSeverities: ESeverity[],
  alertRuleIds: TAlertRuleId[],
): {
  isPrepended: boolean;
  updatedAlerts: IAlert[];
} => {
  const start: ZonedDateTime | null = dateTimes === null ? null : dateTimes[0];
  const stop: ZonedDateTime | null = dateTimes === null ? null : dateTimes[1];

  if (
    (start === null ||
      start.isSameOrBefore(
        ZonedDateTime.parseIso(liveAlert.created_at_time, start.timeZone()),
      )) &&
    (stop === null ||
      stop.isAfter(
        ZonedDateTime.parseIso(liveAlert.created_at_time, stop.timeZone()),
      )) &&
    (acknowledgeStatus === EAlertAcknowledged.All ||
      (acknowledgeStatus === EAlertAcknowledged.Read &&
        liveAlert.acknowledged) ||
      (acknowledgeStatus === EAlertAcknowledged.Unread &&
        !liveAlert.acknowledged)) &&
    (alertSeverities.length === 0 ||
      alertSeverities.includes(liveAlert.severity)) &&
    (alertRuleIds.length === 0 ||
      alertRuleIds.includes(liveAlert.alert_rule_id))
  ) {
    const index: number = alerts.findIndex(
      (currentAlert: IAlert): boolean =>
        currentAlert.alert_id === liveAlert.alert_id,
    );

    if (index === -1) {
      return {
        isPrepended: true,
        updatedAlerts: [liveAlert as IAlert].concat(alerts),
      };
    }

    const updatedAlerts: IAlert[] = [...alerts];
    updatedAlerts[index] = liveAlert as IAlert;

    return { isPrepended: false, updatedAlerts: updatedAlerts };
  }

  return { isPrepended: false, updatedAlerts: alerts };
};

export const mergeLiveAlerts = async (
  liveAlerts: IConfiguredAlert[],
  alerts: IAlert[],
  loadedRows: true[],
  dateTimes: TZonedDateTimeRange,
  acknowledgeStatus: EAlertAcknowledged,
  alertSeverities: ESeverity[],
  alertRuleIds: TAlertRuleId[],
): Promise<{ updatedAlerts: IAlert[]; updatedLoadedRows: true[] }> => {
  const updatedLoadedRows: true[] = [...loadedRows];
  let updatedAlerts: IAlert[] = alerts;

  liveAlerts.forEach((liveAlert: IConfiguredAlert) => {
    const { isPrepended, updatedAlerts: newAlerts } = mergeLiveAlertWithAlerts(
      liveAlert,
      updatedAlerts,
      dateTimes,
      acknowledgeStatus,
      alertSeverities,
      alertRuleIds,
    );
    updatedAlerts = newAlerts;

    if (isPrepended) {
      updatedLoadedRows.unshift(true);
    }
  });

  return { updatedAlerts, updatedLoadedRows };
};
