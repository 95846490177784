import { DEFAULT_ON_SCREEN_TIME_IN_SECONDS } from 'constants/Alert';
import { COLUMN_DATA_MAP } from 'constants/Summary';
import { EAlertEffect, EAlertSound } from 'enums/Alert';
import { ESortDirection } from 'enums/Sort';
import { EDefaultDateRange } from 'enums/Summary';
import {
  IAlertConfiguration,
  IAlertRule,
  IEditableAlertConfiguration,
} from 'interfaces/Alert';
import { IEditableOption, IOption } from 'interfaces/Component';
import { IETagColumnData } from 'interfaces/ETag';
import {
  IColumnConfiguration,
  IColumnSort,
  ITableConfiguration,
} from 'interfaces/Summary';
import { TAlertRuleId, TAlertRulesMap } from 'types/Alert';

export const copyTableConfiguration = (
  tableConfiguration: ITableConfiguration,
): ITableConfiguration => {
  const copyOfTableConfiguration: ITableConfiguration = {
    id: tableConfiguration.id,
    columns: tableConfiguration.columns.map((column: IColumnConfiguration) => ({
      ...column,
    })),
    userCreated: tableConfiguration.userCreated,
  };

  if (tableConfiguration.defaultSort !== undefined) {
    copyOfTableConfiguration.defaultSort = {};
    Object.keys(tableConfiguration.defaultSort).forEach((key: string) => {
      copyOfTableConfiguration.defaultSort![key] = {
        ...tableConfiguration.defaultSort![key],
      };
    });
  }

  return copyOfTableConfiguration;
};

export const generateEditableTableConfigurationOption = (
  tableConfigurationOption: IOption<ITableConfiguration>,
): IEditableOption<ITableConfiguration> => ({
  isUnsaved: false,
  label: tableConfigurationOption.label,
  value: copyTableConfiguration(tableConfigurationOption.value),
});

export const copyEditableTableConfigurationOption = (
  editableTableConfigurationOption: IEditableOption<ITableConfiguration>,
): IEditableOption<ITableConfiguration> => ({
  isUnsaved: editableTableConfigurationOption.isUnsaved,
  label: editableTableConfigurationOption.label,
  value: copyTableConfiguration(editableTableConfigurationOption.value),
});

export const updateColumnSort = (
  editableTableConfigurationOption: IEditableOption<ITableConfiguration>,
  dataIndex: string,
  editedColumnSort?: IColumnSort,
) => {
  if (editedColumnSort === undefined) {
    if (editableTableConfigurationOption.value.defaultSort !== undefined) {
      const currentColumnSort: IColumnSort | undefined =
        editableTableConfigurationOption.value.defaultSort[dataIndex];

      if (currentColumnSort !== undefined) {
        if (currentColumnSort.sortDirection !== ESortDirection.Disabled) {
          Object.values(
            editableTableConfigurationOption.value.defaultSort,
          ).forEach((columnSort: IColumnSort) => {
            if (
              columnSort.sortDirection !== ESortDirection.Disabled &&
              columnSort.sortOrder! > currentColumnSort.sortOrder!
            ) {
              columnSort.sortOrder! -= 1;
            }
          });
        }

        delete editableTableConfigurationOption.value.defaultSort[dataIndex];
      }
    }
  } else {
    if (editableTableConfigurationOption.value.defaultSort === undefined) {
      editableTableConfigurationOption.value.defaultSort = {
        [dataIndex]: editedColumnSort,
      };
    } else {
      const currentColumnSort: IColumnSort | undefined =
        editableTableConfigurationOption.value.defaultSort[dataIndex];

      if (currentColumnSort === undefined) {
        Object.values(
          editableTableConfigurationOption.value.defaultSort,
        ).forEach((columnSort: IColumnSort) => {
          if (
            columnSort.sortDirection !== ESortDirection.Disabled &&
            columnSort.sortOrder! >= editedColumnSort.sortOrder!
          ) {
            columnSort.sortOrder! += 1;
          }
        });
      } else {
        if (
          currentColumnSort.sortDirection !== ESortDirection.Disabled &&
          editedColumnSort.sortDirection === ESortDirection.Disabled
        ) {
          Object.values(
            editableTableConfigurationOption.value.defaultSort,
          ).forEach((columnSort: IColumnSort) => {
            if (
              columnSort.sortDirection !== ESortDirection.Disabled &&
              columnSort.sortOrder! > currentColumnSort.sortOrder!
            ) {
              columnSort.sortOrder! -= 1;
            }
          });
        } else if (
          currentColumnSort.sortDirection === editedColumnSort.sortDirection &&
          currentColumnSort.sortOrder !== editedColumnSort.sortOrder
        ) {
          Object.values(
            editableTableConfigurationOption.value.defaultSort,
          ).forEach((columnSort: IColumnSort) => {
            if (
              columnSort.sortDirection !== ESortDirection.Disabled &&
              editedColumnSort.sortOrder! <= columnSort.sortOrder! &&
              columnSort.sortOrder! < currentColumnSort.sortOrder!
            ) {
              columnSort.sortOrder! += 1;
            } else if (
              columnSort.sortDirection !== ESortDirection.Disabled &&
              currentColumnSort.sortOrder! < columnSort.sortOrder! &&
              columnSort.sortOrder! <= editedColumnSort.sortOrder!
            ) {
              columnSort.sortOrder! -= 1;
            }
          });
        }
      }

      editableTableConfigurationOption.value.defaultSort[dataIndex] =
        editedColumnSort;
    }
  }
};

export const generateNewTableConfiguration = (): ITableConfiguration => ({
  columns: Object.values(COLUMN_DATA_MAP)
    .filter((value: IETagColumnData | undefined): boolean => !!value!.disabled)
    .map(
      (value: IETagColumnData | undefined): IETagColumnData => ({ ...value! }),
    ),
  id: -1,
  userCreated: true,
});

export const hasUnsavedAlertConfiguration = (
  editableAlertConfigurations: IEditableAlertConfiguration[],
  previousAlertConfigurations?: IAlertConfiguration[],
): boolean =>
  previousAlertConfigurations === undefined ||
  previousAlertConfigurations.length !== editableAlertConfigurations.length ||
  editableAlertConfigurations.find(
    (editableAlertConfiguration: IEditableAlertConfiguration): boolean =>
      editableAlertConfiguration.isUnsaved,
  ) !== undefined;

export const editableAlertConfigurationToAlertConfiguration = (
  editableAlertConfiguration: IEditableAlertConfiguration,
): IAlertConfiguration => {
  const { isUnsaved, ...rest } = editableAlertConfiguration;
  return rest;
};

export const generateNewEditableAlertConfiguration = (
  alertRule: IAlertRule,
): IEditableAlertConfiguration => ({
  alert_effect:
    alertRule.sound_effect === EAlertSound.None
      ? EAlertEffect.Message
      : EAlertEffect.All,
  alert_rule_id: alertRule.alert_rule_id,
  cutoff_time_in_mins: 60,
  isUnsaved: true,
  sound_play_repetitions: 1,
  subscribe_to_alerts: true,
  visible_time: DEFAULT_ON_SCREEN_TIME_IN_SECONDS,
});

export const containsAllAlertRules = (
  alertConfigurations: IAlertConfiguration[],
  alertRulesMap: TAlertRulesMap,
): boolean => {
  if (Object.keys(alertRulesMap).length === alertConfigurations.length) {
    const uniquenessMap: Record<TAlertRuleId, true> = {};

    for (let i: number = 0; i < alertConfigurations.length; i += 1) {
      const { alert_rule_id } = alertConfigurations[i];

      if (uniquenessMap[alert_rule_id] === undefined) {
        uniquenessMap[alert_rule_id] = true;
      } else {
        return false;
      }

      if (alertRulesMap[alert_rule_id] === undefined) {
        return false;
      }
    }

    return true;
  }

  return false;
};

export const getUpdatedEditableAlertConfigurations = (
  editableAlertConfigurations: IEditableAlertConfiguration[],
  alertRulesMap: TAlertRulesMap,
): IEditableAlertConfiguration[] => {
  const updatedEditableAlertConfigurations: IEditableAlertConfiguration[] = [];

  Object.keys(alertRulesMap).forEach((key: TAlertRuleId) => {
    let editableAlertConfiguration: IEditableAlertConfiguration | undefined =
      editableAlertConfigurations.find(
        (editableAlertConfiguration: IEditableAlertConfiguration): boolean =>
          editableAlertConfiguration.alert_rule_id === key,
      );

    const alertRule: IAlertRule | undefined = alertRulesMap[key];

    if (editableAlertConfiguration === undefined && alertRule !== undefined) {
      editableAlertConfiguration =
        generateNewEditableAlertConfiguration(alertRule);
    }

    if (editableAlertConfiguration !== undefined) {
      updatedEditableAlertConfigurations.push(editableAlertConfiguration);
    }
  });

  return updatedEditableAlertConfigurations;
};

export const tableConfigurationToUid = (value: ITableConfiguration): string =>
  value.id.toString();

export const defaultDateRangeToString = (value: EDefaultDateRange): string =>
  value as string;
