import { AxiosResponse } from 'axios';
import Input, { IInputProps } from 'components/atoms/Input/Input';
import InputNumber from 'components/atoms/InputNumber/InputNumber';
import SeparatedRowLayout from 'components/atoms/SeparatedRowLayout/SeparatedRowLayout';
import Separator from 'components/atoms/Separator/Separator';
import DateTimeRangePicker from 'components/molecules/DateTimeRangePicker/DateTimeRangePicker';
import Select, { ISelectProps } from 'components/molecules/Select/Select';
import SelectEnum, {
  ISelectEnumProps,
} from 'components/molecules/Select/SelectEnum';
import {
  ALL_QUERY_TYPE_OPTIONS,
  LOAD_ENTITY_NAMES_ERROR_MESSAGE,
  QUERY_CHECK_INPUT_MESSAGE,
  REGISTRY_TYPE_OPTIONS,
  SHOW_TIME_FORMAT,
} from 'components/organisms/ToEntityQueryETagAuthority/constants';
import {
  queryTypeToUid,
  registryEntityToUid,
  registryTypeToUid,
  serviceTypeToUid,
  submitQueryAvailability,
  submitQueryCheck,
  submitQueryCorrection,
  submitQueryRequest,
  submitQueryRequestIds,
  submitQueryResolution,
  submitQueryStatus,
  submitQuerySummaries,
  submitQueryTag,
} from 'components/organisms/ToEntityQueryETagAuthority/helpers';
import { IModalHandlers } from 'components/organisms/ToEntityQueryETagAuthority/types';
import { COLUMN_LAYOUT_SHARED_STYLES } from 'constants/styles';
import { DATE_TIME_FORMAT } from 'constants/time';
import { ERequestStatus } from 'enums/ETag';
import { ERegistryType, EServiceType } from 'enums/General';
import { EQueryType } from 'enums/Query';
import { IOption } from 'interfaces/Component';
import { IRegistryEntity } from 'interfaces/Entity';
import { IETagExtendedIdentifier, IETagTagId } from 'interfaces/ETag';
import { IToEntity } from 'interfaces/ToEntity';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import { retrieveRegistryEntitiesByRegistryType } from 'services/naesb-registry/registry';
import styled from 'styled-components';
import { TTimeZone, TZonedDateTimeRange } from 'types/DateTime';
import { TErrorMessage } from 'types/Error';
import useAsyncEffect from 'use-async-effect';
import {
  isEmptyValue,
  isSuccessStatus,
  selectOptionLabelFilter,
} from 'utils/general';
import { getTimeZoneDisplayString } from 'utils/time';
import { getQueryEntityFor } from 'utils/toEntity';
import { ZonedDateTime } from 'utils/zonedDateTime';
import useTenantInfo from '../../../hooks/useTenantInfo';

const Layout = styled.div`
  ${COLUMN_LAYOUT_SHARED_STYLES}

  height: 168px;

  > :not(:last-child) {
    margin-bottom: 12px;
  }
`;

const StyledSeparator = styled(Separator)`
  margin-left: -16px;
  width: calc(100% + 32px);
`;

const Label = styled.div`
  white-space: nowrap;
`;

const ToEntityInput = styled(Input)<IInputProps>`
  pointer-events: none;
  width: 141px;
`;

const RegistryTypeSelect = styled((props: ISelectProps<ERegistryType>) =>
  Select<ERegistryType>(props),
)`
  width: 137px;
`;

const RegistryEntitySelect = styled((props: ISelectProps<IRegistryEntity>) =>
  Select<IRegistryEntity>(props),
)`
  width: 339px;
  width: 151px;
`;

const QueryTypeSelect = styled((props: ISelectProps<EQueryType>) =>
  Select<EQueryType>(props),
)`
  width: 200px;
`;

const ServiceTypeSelect = styled((props: ISelectEnumProps<EServiceType>) =>
  SelectEnum<EServiceType>(props),
)`
  width: 139px;
`;

const RequestStatusSelect = styled((props: ISelectEnumProps<ERequestStatus>) =>
  SelectEnum<ERequestStatus>(props),
)`
  width: 157px;
`;

const TimeZoneInput = styled(Input)<IInputProps>`
  pointer-events: none;
  width: 126px;
`;

const TagPrimaryKeyInput = styled(Input)<IInputProps>`
  width: 353px;
`;

const TagPrimaryKeyInputContainer = styled.div`
  display: flex;
  gap: 5px;
  div:nth-child(1) {
    margin-top: 2px;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  span {
    font-size: 11px;
    color: grey;
    margin: 2px 0 0 2px;
  }
`;

interface IQueryETagAuthorityProps {
  eTagExtendedIdentifier: IETagExtendedIdentifier | undefined;
  isDisabled?: boolean;
  setErrorMessage: (errorMessage: TErrorMessage) => void;
  setHandlers: (handlers: IModalHandlers) => void;
  timeZone: TTimeZone;
  toEntities: IToEntity[];
  toEntity?: IToEntity;
  tagID?: IETagTagId;
}

const QueryETagAuthority = ({
  eTagExtendedIdentifier,
  isDisabled,
  setErrorMessage,
  setHandlers,
  timeZone,
  toEntity,
  toEntities,
  tagID,
}: IQueryETagAuthorityProps): JSX.Element => {
  const tenantInfo = useTenantInfo();
  const { currentTheme } = useThemeSwitcher();
  const [isLoadingRegistryEntities, setIsLoadingRegistryEntities] =
    useState<boolean>(false);
  const [registryEntityOptions, setRegistryEntityOptions] = useState<
    IOption<IRegistryEntity>[]
  >([]);
  const [selectedRegistryType, setSelectedRegistryType] = useState<
    ERegistryType | undefined
  >(ERegistryType.BA);
  const [selectedRegistryEntity, setSelectedRegistryEntity] = useState<
    IRegistryEntity | undefined
  >(undefined);
  const [selectedQueryType, setSelectedQueryType] = useState<
    EQueryType | undefined
  >(undefined);
  const [selectedServiceType, setSelectedServiceType] = useState<
    EServiceType | undefined
  >(undefined);
  const [selectedDateTimeRange, setSelectedDateTimeRange] =
    useState<TZonedDateTimeRange>([
      ZonedDateTime.now(timeZone).startOf('day'),
      ZonedDateTime.now(timeZone).startOf('day').add(1, 'day'),
    ]);
  const [selectedTagPrimaryKey, setSelectedTagPrimaryKey] = useState<
    string | undefined
  >(eTagExtendedIdentifier?.tag_primary_key);
  const [selectedCorrectionId, setSelectedCorrectionId] = useState<number>(0);
  const [selectedRequestId, setSelectedRequestId] = useState<number>(0);
  const [selectedRequestStatus, setSelectedRequestStatus] = useState<
    ERequestStatus | undefined
  >(undefined);

  const [currentToEntityId, setCurrentToEntityId] = useState<
    string | undefined
  >(undefined);
  const [currentToEntityCode, setCurrentToEntityCode] = useState<
    string | undefined
  >(undefined);

  useEffect(() => {
    const entity = tenantInfo.selectedTenantToEntityId || toEntity?.to_entity;
    setCurrentToEntityId(entity);
    const entityCode =
      toEntity?.entity_code || (toEntities.length > 0 && entity)
        ? toEntities.filter((toEntity) => toEntity.to_entity === entity)[0]
            .entity_code
        : '';
    setCurrentToEntityCode(entityCode);
  }, [tenantInfo.selectedTenantToEntityId, toEntity, toEntities]);

  useEffect(() => {
    if (tagID && tagID.lca && tagID.lca.entity_code) {
      const registryEntityOption = registryEntityOptions.find(
        (option) => option.value.Code === tagID?.lca?.entity_code,
      );
      if (registryEntityOption) {
        setSelectedRegistryEntity(registryEntityOption.value);
      }
    } else {
      if (registryEntityOptions.length > 0) {
        setSelectedRegistryEntity(registryEntityOptions[0].value);
      }
    }
  }, [registryEntityOptions, tagID]);

  useEffect(() => {
    const modalHandlers: IModalHandlers = {};

    if (currentToEntityId) {
      if (
        selectedDateTimeRange !== null &&
        (selectedQueryType === EQueryType.Summary ||
          selectedQueryType === EQueryType.MissingTagsAndRevisions)
      ) {
        const [start, end] = selectedDateTimeRange;

        if (start !== null && end !== null) {
          if (selectedQueryType === EQueryType.Summary) {
            modalHandlers.submitHandler = submitQuerySummaries(
              currentToEntityId,
              // toEntity.to_entity,
              start,
              end,
            );
          }
          if (selectedQueryType === EQueryType.MissingTagsAndRevisions) {
            const ee =
              toEntities.length > 0
                ? toEntities.map((i) => i.to_entity)
                : [currentToEntityId];
            modalHandlers.submitHandler = submitQueryCheck(ee, start, end);
          }
        }
      } else if (
        selectedRegistryType !== undefined &&
        selectedRegistryEntity !== undefined &&
        selectedRegistryEntity.TaggingEntityID !== undefined
      ) {
        const queryEntity: string = getQueryEntityFor(
          selectedRegistryType,
          selectedRegistryEntity.TaggingEntityID,
        );

        switch (selectedQueryType) {
          case EQueryType.Availability: {
            modalHandlers.submitHandler = submitQueryAvailability(
              currentToEntityId,
              // toEntity.to_entity,
              queryEntity,
              selectedServiceType,
            );
            break;
          }
          case EQueryType.Correction: {
            if (!isEmptyValue(selectedTagPrimaryKey)) {
              modalHandlers.submitHandler = submitQueryCorrection(
                currentToEntityId,
                // toEntity.to_entity,
                selectedTagPrimaryKey!,
                queryEntity,
                selectedCorrectionId,
              );
            }
            break;
          }
          case EQueryType.Request: {
            if (!isEmptyValue(selectedTagPrimaryKey)) {
              modalHandlers.submitHandler = submitQueryRequest(
                currentToEntityId,
                // toEntity.to_entity,
                selectedTagPrimaryKey!,
                queryEntity,
                selectedRequestId,
              );
            }
            break;
          }
          case EQueryType.RequestIds: {
            if (
              !isEmptyValue(selectedTagPrimaryKey) &&
              selectedRequestStatus !== undefined
            ) {
              modalHandlers.submitHandler = submitQueryRequestIds(
                currentToEntityId,
                // toEntity.to_entity,
                selectedTagPrimaryKey!,
                queryEntity,
                selectedRequestStatus,
              );
            }
            break;
          }
          case EQueryType.Resolution: {
            if (!isEmptyValue(selectedTagPrimaryKey)) {
              modalHandlers.submitHandler = submitQueryResolution(
                currentToEntityId,
                // toEntity.to_entity,
                selectedTagPrimaryKey!,
                queryEntity,
                selectedRequestId,
              );
            }
            break;
          }
          case EQueryType.Status: {
            if (!isEmptyValue(selectedTagPrimaryKey)) {
              modalHandlers.submitHandler = submitQueryStatus(
                currentToEntityId,
                // toEntity.to_entity,
                selectedTagPrimaryKey!,
                queryEntity,
                selectedRequestId,
              );
            }
            break;
          }
          case EQueryType.Tag: {
            if (!isEmptyValue(selectedTagPrimaryKey)) {
              modalHandlers.submitHandler = submitQueryTag(
                currentToEntityId,
                // toEntity.to_entity,
                selectedTagPrimaryKey!,
                queryEntity,
              );
            }
            break;
          }
          default: {
            break;
          }
        }
      }

      setHandlers(modalHandlers);
    }
  }, [
    selectedCorrectionId,
    selectedDateTimeRange,
    selectedQueryType,
    selectedRegistryEntity,
    selectedRegistryType,
    selectedRequestId,
    selectedRequestStatus,
    selectedServiceType,
    selectedTagPrimaryKey,
    setHandlers,
    toEntity?.to_entity,
    toEntities,
    currentToEntityId,
  ]);

  useAsyncEffect(async () => {
    if (selectedRegistryType === undefined) {
      setRegistryEntityOptions([]);
    } else {
      try {
        if (currentToEntityId) {
          setIsLoadingRegistryEntities(true);

          const response: AxiosResponse<IRegistryEntity[]> =
            await retrieveRegistryEntitiesByRegistryType(
              // toEntity.to_entity,
              currentToEntityId,
              selectedRegistryType,
            );

          if (!isSuccessStatus(response.status)) {
            throw new Error(LOAD_ENTITY_NAMES_ERROR_MESSAGE);
          }

          const registryEntities: IRegistryEntity[] = response.data;

          setRegistryEntityOptions(
            registryEntities.map(
              (registryEntity: IRegistryEntity): IOption<IRegistryEntity> => ({
                label:
                  registryEntity.Code === undefined ? '' : registryEntity.Code,
                value: registryEntity,
              }),
            ),
          );
        }
      } catch (error: any) {
        setErrorMessage(
          isEmptyValue(error.message)
            ? LOAD_ENTITY_NAMES_ERROR_MESSAGE
            : error.message,
        );
      } finally {
        setIsLoadingRegistryEntities(false);
      }
    }
  }, [selectedRegistryType, toEntity, currentToEntityId]);

  const handleRegistryTypeChange = useCallback(
    (registryType: ERegistryType | undefined) => {
      setSelectedRegistryEntity(undefined);

      setSelectedRegistryType(registryType);
    },
    [],
  );

  const handleTagPrimaryKeyChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSelectedTagPrimaryKey(event.target.value);
    },
    [],
  );

  const handleCorrectionIdChange = useCallback((value: number | undefined) => {
    setSelectedCorrectionId(value === undefined ? 0 : value);
  }, []);

  const handleRequestIdChange = useCallback((value: number | undefined) => {
    setSelectedRequestId(value === undefined ? 0 : value);
  }, []);

  const queryTypeOptions: IOption<EQueryType>[] = useMemo(
    () => ALL_QUERY_TYPE_OPTIONS,
    [],
  );

  const timeZoneDisplayString: string = useMemo(
    () => getTimeZoneDisplayString(timeZone),
    [timeZone],
  );

  const [disableEntitySection, setDisableEntitySection] =
    useState<boolean>(false);

  const handleQueryTypeChange = (option: any) => {
    setDisableEntitySection(false);
    if (
      option === EQueryType.MissingTagsAndRevisions ||
      option === EQueryType.Summary
    ) {
      setDisableEntitySection(true);
    }
    setSelectedQueryType(option);
  };

  return (
    <Layout>
      <SeparatedRowLayout>
        <SeparatedRowLayout>
          <Label>From:</Label>
          <ToEntityInput value={currentToEntityCode} />
        </SeparatedRowLayout>
        <SeparatedRowLayout>
          <Label>To:</Label>
          <RegistryTypeSelect
            isDisabled={isDisabled || disableEntitySection}
            onChange={handleRegistryTypeChange}
            options={REGISTRY_TYPE_OPTIONS}
            placeholder='Select Entity Type'
            value={selectedRegistryType}
            valueToUid={registryTypeToUid}
          />
          <RegistryEntitySelect
            filter={selectOptionLabelFilter}
            isDisabled={
              isDisabled || isLoadingRegistryEntities || disableEntitySection
            }
            isLoading={isLoadingRegistryEntities}
            onChange={setSelectedRegistryEntity}
            options={registryEntityOptions}
            placeholder='Select Entity Code'
            showSearch={true}
            value={selectedRegistryEntity}
            valueToUid={registryEntityToUid}
          />
        </SeparatedRowLayout>
      </SeparatedRowLayout>
      <StyledSeparator currentTheme={currentTheme!} />
      <SeparatedRowLayout>
        <Label>Query Type:</Label>
        <QueryTypeSelect
          isDisabled={isDisabled}
          onChange={handleQueryTypeChange}
          options={queryTypeOptions}
          placeholder='Select Query Type'
          value={selectedQueryType}
          valueToUid={queryTypeToUid}
        />
      </SeparatedRowLayout>
      {selectedQueryType === EQueryType.Availability ? (
        <SeparatedRowLayout>
          <Label>Service Type (Optional):</Label>
          <ServiceTypeSelect
            allowClear={true}
            enumType={EServiceType}
            isDisabled={isDisabled}
            onChange={setSelectedServiceType}
            placeholder='Select Service Type'
            value={selectedServiceType}
            valueToUid={serviceTypeToUid}
          />
        </SeparatedRowLayout>
      ) : null}
      {selectedQueryType === EQueryType.Correction ? (
        <>
          <SeparatedRowLayout>
            <TagPrimaryKeyInputContainer>
              <Label>Tag Primary Key:</Label>
              <InputWrapper>
                <TagPrimaryKeyInput
                  isDisabled={isDisabled}
                  onChange={handleTagPrimaryKeyChange}
                  placeholder='Enter Tag Primary Key'
                  value={selectedTagPrimaryKey}
                />
                <span>{QUERY_CHECK_INPUT_MESSAGE}</span>
              </InputWrapper>
            </TagPrimaryKeyInputContainer>
          </SeparatedRowLayout>
          <SeparatedRowLayout>
            <Label>Correction Id:</Label>
            <InputNumber
              isDisabled={isDisabled}
              min={0}
              onChange={handleCorrectionIdChange}
              value={selectedCorrectionId}
            />
          </SeparatedRowLayout>
        </>
      ) : null}
      {selectedQueryType === EQueryType.Request ||
      selectedQueryType === EQueryType.Resolution ||
      selectedQueryType === EQueryType.Status ? (
        <>
          <SeparatedRowLayout>
            <TagPrimaryKeyInputContainer>
              <Label>Tag Primary Key:</Label>
              <InputWrapper>
                <TagPrimaryKeyInput
                  isDisabled={isDisabled}
                  onChange={handleTagPrimaryKeyChange}
                  placeholder='Enter Tag Primary Key'
                  value={selectedTagPrimaryKey}
                />
                <span>{QUERY_CHECK_INPUT_MESSAGE}</span>
              </InputWrapper>
            </TagPrimaryKeyInputContainer>
          </SeparatedRowLayout>
          <SeparatedRowLayout>
            <Label>Request Id:</Label>
            <InputNumber
              isDisabled={isDisabled}
              min={0}
              onChange={handleRequestIdChange}
              value={selectedRequestId}
            />
          </SeparatedRowLayout>
        </>
      ) : null}
      {selectedQueryType === EQueryType.RequestIds ? (
        <>
          <SeparatedRowLayout>
            <TagPrimaryKeyInputContainer>
              <Label>Tag Primary Key:</Label>
              <InputWrapper>
                <TagPrimaryKeyInput
                  isDisabled={isDisabled}
                  onChange={handleTagPrimaryKeyChange}
                  placeholder='Enter Tag Primary Key'
                  value={selectedTagPrimaryKey}
                />
                <span>{QUERY_CHECK_INPUT_MESSAGE}</span>
              </InputWrapper>
            </TagPrimaryKeyInputContainer>
          </SeparatedRowLayout>
          <SeparatedRowLayout>
            <Label>Request Status:</Label>
            <RequestStatusSelect
              enumType={ERequestStatus}
              isDisabled={isDisabled}
              onChange={setSelectedRequestStatus}
              placeholder='Select Request Status'
              value={selectedRequestStatus}
            />
          </SeparatedRowLayout>
        </>
      ) : null}
      {selectedQueryType === EQueryType.Summary ||
      selectedQueryType === EQueryType.MissingTagsAndRevisions ? (
        <SeparatedRowLayout>
          <Label>Date Time Range:</Label>
          <DateTimeRangePicker
            format={DATE_TIME_FORMAT}
            isDisabled={isDisabled}
            onChange={setSelectedDateTimeRange}
            placeholder={['Start', 'End']}
            showTime={SHOW_TIME_FORMAT}
            timeZone={timeZone}
            value={selectedDateTimeRange}
          />
          <TimeZoneInput value={timeZoneDisplayString} />
        </SeparatedRowLayout>
      ) : null}
      {selectedQueryType === EQueryType.Tag ? (
        <SeparatedRowLayout>
          <TagPrimaryKeyInputContainer>
            <Label>Tag Primary Key:</Label>
            <InputWrapper>
              <TagPrimaryKeyInput
                isDisabled={isDisabled}
                onChange={handleTagPrimaryKeyChange}
                placeholder='Enter Tag Primary Key'
                value={selectedTagPrimaryKey}
              />
              <span>{QUERY_CHECK_INPUT_MESSAGE}</span>
            </InputWrapper>
          </TagPrimaryKeyInputContainer>
        </SeparatedRowLayout>
      ) : null}
    </Layout>
  );
};

export default QueryETagAuthority;
