import { Card as AntDesignCard, Popover as AntDesignPopover } from 'antd';
import DataTable, {
  IDataTableProps,
} from 'components/molecules/DataTable/DataTable';
import ScrollableContent from 'components/molecules/ScrollableContent/ScrollableContent';
import {
  STANDARD_SPACING,
  VIEW_DATA_TABLE_CENTERED_CONTENT,
  VIEW_DATA_TABLE_SHARED_STYLES,
} from 'constants/styles';
import {
  MutableRefObject,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import styled from 'styled-components';
import { TPlacement } from 'types/General';
import { isEmptyValue } from 'utils/general';
import { alternatingTableRowBackground } from 'utils/styles';

const Card = styled(AntDesignCard)`
  margin-bottom: ${STANDARD_SPACING};
  margin-right: 1px;

  > .ant-card-head {
    > .ant-card-head-wrapper {
      > .ant-card-head-title {
        white-space: pre;
      }
    }
  }
`;

interface IContainerProps {
  maximumHeight?: string;
}

const Container = styled.div<IContainerProps>`
  ${(props) =>
    isEmptyValue(props.maximumHeight)
      ? ''
      : `max-height: ${props.maximumHeight};`}
`;

const Padding = styled.div`
  padding: 1px 1px 0 0;
`;

const ViewDataTable = styled(({ centerDataTable, ...rest }) => (
  <DataTable {...rest} />
))`
  ${VIEW_DATA_TABLE_SHARED_STYLES}
  ${(props) => (props.centerDataTable ? VIEW_DATA_TABLE_CENTERED_CONTENT : '')}
  ${(props) => alternatingTableRowBackground(props)}
`;

const DataCount = styled.div`
  height: 100%;
  width: 100%;
`;

interface IContentProps {
  children?: ReactNode;
  getIsVisible?: (visible: boolean) => void;
  maximumHeight?: string;
}

const Content = (props: IContentProps): JSX.Element => {
  const { children, getIsVisible, maximumHeight } = props;
  const [hasOverflowedX, setHasOverflowedX] = useState<boolean>(false);
  const [hasOverflowedY, setHasOverflowedY] = useState<boolean>(false);
  const [contentHeight, setContentHeight] = useState<number | undefined>(
    undefined,
  );
  const [contentWidth, setContentWidth] = useState<number | undefined>(
    undefined,
  );
  const containerRef =
    useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;

  // We use the mounting of the Content component as an indication of the
  // visibility of the Popover since the Content component is not mounted
  // unless the Popover has been triggered.
  useEffect(() => {
    if (getIsVisible) {
      getIsVisible(true);
    }

    return () => {
      if (getIsVisible) {
        getIsVisible(false);
      }
    };
  }, [getIsVisible]);

  useEffect(() => {
    if (containerRef.current) {
      if (
        containerRef.current.clientHeight !== containerRef.current.scrollHeight
      ) {
        setHasOverflowedY(true);
      }
      if (
        containerRef.current.clientWidth !== containerRef.current.scrollWidth
      ) {
        setHasOverflowedX(true);
      }
    }
  }, [containerRef]);

  const handleResize = (width?: number, height?: number) => {
    if (contentHeight === undefined && contentWidth === undefined) {
      setContentWidth(width);
      setContentHeight(height);
    }
  };

  // The Ant Design Popover component does some internal calculations in order
  // to determine the correct placement of a triggered popover. Since we do not
  // want to invalidate these calculations we effectively render our children
  // twice. In the first instance we allow the children to be freely rendered
  // and then once we have determined the dimensions of the rendered children
  // (and allowed the placement calculations to be made), we then wrap the
  // children in the ScrollableContent component so that we can restrict its
  // maximum height and thus enable our custom scrollbar as necessary.
  return (
    <Container maximumHeight={maximumHeight} ref={containerRef}>
      {contentHeight === undefined || contentWidth === undefined ? (
        <Padding>{children}</Padding>
      ) : null}
      {hasOverflowedX || hasOverflowedY ? (
        <ScrollableContent
          maximumHeight={maximumHeight}
          onResize={handleResize}
        >
          {children}
        </ScrollableContent>
      ) : null}
    </Container>
  );
};

interface IProps<C, D> extends IDataTableProps<C, D> {
  additionalContent?: ReactNode;
  centerDataTable?: boolean;
  children?: ReactNode;
  className?: string;
  getIsVisible?: (isVisible: boolean) => void;
  getShowDataCount?: (data: D[]) => boolean;
  isDisabled?: boolean;
  maximumHeight?: string;
  placement?: TPlacement;
  title?: string;
}

const PopoverDataTable = <C extends any, D extends any>(
  props: IProps<C, D>,
): JSX.Element => {
  const { currentTheme } = useThemeSwitcher();
  const {
    additionalContent,
    centerDataTable,
    children,
    className,
    data,
    getIsVisible,
    getShowDataCount,
    isDisabled,
    maximumHeight,
    placement,
    title,
    ...rest
  } = props;
  const dataCount: number = data.length;
  const content = children ? (
    children
  ) : getShowDataCount === undefined || getShowDataCount(data) ? (
    <DataCount>{dataCount}</DataCount>
  ) : (
    <></>
  );

  return (isDisabled === undefined || isDisabled === false) &&
    (dataCount > 0 || additionalContent) ? (
    <AntDesignPopover
      className={className}
      content={
        <Content getIsVisible={getIsVisible} maximumHeight={maximumHeight}>
          {dataCount > 0 ? (
            <Card title={title}>
              <ViewDataTable
                centerDataTable={centerDataTable}
                currentTheme={currentTheme!}
                data={data}
                {...rest}
              />
            </Card>
          ) : null}
          {additionalContent}
        </Content>
      }
      destroyTooltipOnHide={true}
      placement={placement === undefined ? 'bottom' : placement}
      trigger='hover'
    >
      {content}
    </AntDesignPopover>
  ) : (
    <>{content}</>
  );
};

export default PopoverDataTable;
