import Select, { ISelectProps } from 'components/molecules/Select/Select';
import { ATTRIBUTE_OPTIONS } from 'components/organisms/ToEntityCustomFilterConfigurator/CustomFilterEditor/constants';
import { attributeToUid } from 'components/organisms/ToEntityCustomFilterConfigurator/CustomFilterEditor/helpers';
import { EAttribute } from 'components/organisms/ToEntityCustomFilterConfigurator/CustomFilterEditor/types';
import { IOption } from 'interfaces/Component';
import { useMemo } from 'react';
import styled from 'styled-components';
import { selectOptionLabelFilter } from 'utils/general';

const StyledSelect = styled((props: ISelectProps<EAttribute>) =>
  Select<EAttribute>(props),
)`
  width: 227px;
`;

interface IAttributeSelectProps {
  isDisabled?: boolean;
  onChange?: (attribute: EAttribute | undefined) => void;
  value?: EAttribute;
}

const AttributeSelect = ({
  isDisabled,
  onChange,
  value,
}: IAttributeSelectProps): JSX.Element => {
  const options: IOption<EAttribute>[] = useMemo(
    (): IOption<EAttribute>[] => ATTRIBUTE_OPTIONS,
    [],
  );

  return (
    <StyledSelect
      dropdownMatchSelectWidth={false}
      filter={selectOptionLabelFilter}
      isDisabled={isDisabled}
      onChange={onChange}
      options={options}
      placeholder='Select Attribute'
      showSearch={true}
      value={value}
      valueToUid={attributeToUid}
    />
  );
};

export default AttributeSelect;
