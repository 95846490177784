import styled from 'styled-components';
import {
  COLUMN_LAYOUT_SHARED_STYLES,
  ERROR_RED,
  STANDARD_SPACING,
  SUCCESS_GREEN,
  WARNING_ORANGE,
} from 'constants/styles';
import ContainedText from 'components/molecules/ContainedText/ContainedText';
import SeparatedRowLayout from 'components/atoms/SeparatedRowLayout/SeparatedRowLayout';
import { TITLE_TEXT_MAX_WIDTH } from 'components/molecules/ScheduleSubmission/constants';
import { IETagScheduledDraftSubmission } from 'interfaces/ETag';
import Link from 'components/atoms/Link/Link';
import { detailPageLocationString } from 'utils/detail';
import { TTimeZone } from 'types/DateTime';
import { EPageMode } from 'enums/Page';
import { TToEntityId } from 'types/ToEntity';
import { EDraftScheduledStatusLabel } from 'components/molecules/ScheduleSubmission/interfaces';
import Spinner from 'components/atoms/Spinner/Spinner';
import { getStatusLabelFromStatus } from 'components/molecules/ScheduleSubmission/helpers';

const Layout = styled.div`
  ${COLUMN_LAYOUT_SHARED_STYLES}

  padding: ${STANDARD_SPACING};
`;

const LinkText = styled.text`
  float: left;
  text-decoration: underline;
  padding-right: 50px;
  color: ${(props) => props.color};
`;

const StatusText = styled.text`
  float: right;
  color: ${(props) => props.color};
`;

const DataRow = styled.div`
  color: ${(props) => props.color};
  display: inline-block;
  width: 100%;
`;

interface IReviewScheduledSubmissionsPopoverProps {
  data: IETagScheduledDraftSubmission[];
  isLoading: boolean;
  timeZone: TTimeZone;
  toEntityId: TToEntityId;
}

const ReviewScheduledSubmissionsPopover = ({
  data,
  isLoading,
  timeZone,
  toEntityId,
}: IReviewScheduledSubmissionsPopoverProps): JSX.Element => {
  const getColorForStatus = (status: string): string =>
    status === EDraftScheduledStatusLabel.Scheduled
      ? SUCCESS_GREEN
      : status === EDraftScheduledStatusLabel.Failed
      ? ERROR_RED
      : status === EDraftScheduledStatusLabel.InProgress
      ? WARNING_ORANGE
      : '';

  return (
    <Layout>
      <ContainedText
        maxWidth={TITLE_TEXT_MAX_WIDTH}
        text={'Scheduled Draft Submissions'}
      />
      {isLoading && data.length === 0 ? <Spinner /> : null}
      {data.map((datum: IETagScheduledDraftSubmission) => (
        <SeparatedRowLayout>
          <DataRow>
            <Link
              to={detailPageLocationString({
                draftId: datum.draft_id,
                defaultTimeZone: timeZone,
                mode: EPageMode.Review,
                toEntity: toEntityId,
              })}
              target='_blank'
            >
              <LinkText
                color={getColorForStatus(
                  getStatusLabelFromStatus(datum.status),
                )}
              >
                {datum.ui_tag_id}
              </LinkText>
            </Link>
            <StatusText
              color={getColorForStatus(getStatusLabelFromStatus(datum.status))}
            >
              {getStatusLabelFromStatus(datum.status)}
            </StatusText>
          </DataRow>
        </SeparatedRowLayout>
      ))}
    </Layout>
  );
};

export default ReviewScheduledSubmissionsPopover;
