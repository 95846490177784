interface IConfirmedIconProps {
  className?: string;
}

const ConfirmedIcon = ({ className }: IConfirmedIconProps): JSX.Element => (
  <svg
    aria-hidden='true'
    className={className}
    clipRule='evenodd'
    fillRule='evenodd'
    height='1em'
    strokeLinejoin='round'
    strokeMiterlimit='2'
    viewBox='0 0 1000 1000'
    width='1em'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle
      cx='413.804'
      cy='336.526'
      r='238.771'
      fill='#228b22'
      transform='translate(-366.528 -204.703) scale(2.09405)'
    />
    <path
      d='M409.101 663.224L726.452 210l86.328 60.448L434.981 810 147.22 608.507l60.448-86.328 201.433 141.045z'
      fill='#fff'
    />
  </svg>
);

export default ConfirmedIcon;
