import Page from 'components/organisms/Page/Page';
import TemplatesPageContent from 'components/pages/TemplatesPage/TemplatesPageContent';
import { EXTERNAL_LINKS_MAP } from 'constants/General';
import { EExternalLinks } from 'enums/General';
import { IRouteProps } from 'interfaces/Component';
import { useEffect } from 'react';

const TemplatesPage = ({
  appActions,
  setUserGuideExternalLink,
}: IRouteProps): JSX.Element => {
  useEffect(() => {
    setUserGuideExternalLink(EXTERNAL_LINKS_MAP[EExternalLinks.TemplateGuide]);
  }, [setUserGuideExternalLink]);

  return (
    <Page
      appActions={appActions}
      PageContent={TemplatesPageContent}
      pageTitle='Tag Templates - PCI e-Tag+'
    />
  );
};

export default TemplatesPage;
