import { AxiosResponse } from 'axios';
import SeparatedRowLayout from 'components/atoms/SeparatedRowLayout/SeparatedRowLayout';
import TextArea from 'components/atoms/TextArea/TextArea';
import ContactInfoEditable, {
  IContactInfoEditableProps,
} from 'components/molecules/ContactInfoEditable/ContactInfoEditable';
import { ETagRequestFormFeedback } from 'components/molecules/ETagRequestFormFeedback/ETagRequestFormFeedback';
import { ETagRequestFormFooter } from 'components/molecules/ETagRequestFormFooter/ETagRequestFormFooter';
import MiscInfoEditable, {
  IMiscInfoEditableProps,
} from 'components/molecules/MiscInfoEditable/MiscInfoEditable';
import { CHANGE_REQUEST_MAXIMUM_HEIGHT } from 'components/organisms/DetailChangeRequest/constants';
import {
  getCorrectionRequest,
  getProfileChangeRequest,
} from 'components/organisms/DetailChangeRequest/helpers';
import { NOTES_MAX_LENGTH } from 'constants/Detail';
import { COLUMN_LAYOUT_SHARED_STYLES } from 'constants/styles';
import { ERequestType } from 'enums/ETag';
import {
  IETagCorrectionRequest,
  IETagPhysicalSegmentsProfile,
  IETagProfileChangeRequest,
  IETagTagId,
  IETagTransmissionAllocation,
  IETagTransmissionPhysicalSegment,
  IETagValidationResponse,
  IETagValidationResultList,
} from 'interfaces/ETag';
import { IContactInfo, IMiscInfo } from 'interfaces/General';
import { ChangeEvent, useCallback } from 'react';
import {
  IDetailGenerationPhysicalSegment,
  IDetailLoadPhysicalSegment,
  IDetailLossAccounting,
  IDetailMarketSegment,
} from 'reduxes/Detail/types';
import {
  requestCorrection,
  requestProfileChange,
  validateRequestCorrection,
  validateRequestProfileChange,
} from 'services/agent/tags/requests';
import styled from 'styled-components';
import { TTimeZone } from 'types/DateTime';
import { TProfileDataGridRow } from 'types/Detail';
import { TErrorMessage } from 'types/Error';
import { TETagTagPrimaryKey } from 'types/ETag';
import { TToEntityId } from 'types/ToEntity';
import { captureError } from 'utils/error';
import {
  extractValidationFailureMessages,
  extractValidationWarningMessages,
} from 'utils/eTag';
import { isEmptyValue, isSuccessStatus } from 'utils/general';
import { useSelector } from 'react-redux';
import { TRootState } from '../../../types/Redux';

const CHANGE_REQUEST_LAYOUT_WIDTH = '481px';

const Layout = styled.div`
  ${COLUMN_LAYOUT_SHARED_STYLES}

  flex-shrink: 0;
  max-height: ${CHANGE_REQUEST_MAXIMUM_HEIGHT};
  width: ${CHANGE_REQUEST_LAYOUT_WIDTH};
`;

const OptionalLabel = styled.span`
  max-width: 62px;
  width: 100%;
`;

const MiscInfoContainer = styled.div`
  width: 100%;
`;

interface IChangeRequestProps {
  cleanUpProfileChanges: () => void;
  clearETagDetailEdits: () => void;
  contactInfo: IContactInfo;
  contactInfoEditableProps: IContactInfoEditableProps;
  errorMessage: TErrorMessage;
  generationPhysicalSegment: IDetailGenerationPhysicalSegment | null;
  initialGenerationPhysicalSegment:
    | IDetailGenerationPhysicalSegment
    | null
    | undefined;
  initialLoadPhysicalSegment: IDetailLoadPhysicalSegment | null | undefined;
  initialLossAccountings: IDetailLossAccounting[] | undefined;
  initialMarketSegments: IDetailMarketSegment[] | null | undefined;
  initialTransmissionAllocations:
    | IETagTransmissionAllocation[]
    | null
    | undefined;
  initialTransmissionPhysicalSegments:
    | IETagTransmissionPhysicalSegment[]
    | null
    | undefined;
  isCorrection: boolean;
  isFaxValid: boolean;
  isNotesDisabled: boolean;
  isPhoneValid: boolean;
  isProfileChange: boolean;
  isValidateAndSubmitSuccessful: boolean;
  isValidateSuccessful: boolean;
  isValidating: boolean;
  loadPhysicalSegment: IDetailLoadPhysicalSegment | null;
  lossAccountings: IDetailLossAccounting[];
  marketSegments: IDetailMarketSegment[] | null;
  miscInfoEditableProps: IMiscInfoEditableProps;
  miscInfos: IMiscInfo[];
  notes: string;
  physicalSegmentsProfiles: IETagPhysicalSegmentsProfile[] | null;
  profileChanges: TProfileDataGridRow[];
  retrieveTransactionStatuses: () => void;
  securityKey: string | undefined;
  selectedRequestType: ERequestType | undefined;
  setErrorMessage: (errorMessage: TErrorMessage) => void;
  setIsValidateSuccessful: (isValidateSuccessful: boolean) => void;
  setIsValidateAndSubmitSuccessful: (
    isValidateAndSubmitSuccessful: boolean,
  ) => void;
  setIsValidating: (isValidating: boolean) => void;
  setNotes: (notes: string) => void;
  setSkipValidation: (skipValidation: boolean) => void;
  setValidationErrors: (validationErrors: string[]) => void;
  setValidationWarnings: (validationWarnings: string[]) => void;
  resetActionBarValidationErrors: () => void;
  skipValidation: boolean;
  tagId: IETagTagId | null;
  timeZone: TTimeZone;
  tagPrimaryKey: TETagTagPrimaryKey | undefined;
  toEntityId: TToEntityId;
  transmissionAllocations: IETagTransmissionAllocation[] | null;
  transmissionPhysicalSegments: IETagTransmissionPhysicalSegment[] | null;
  useUniqueProfiles: boolean;
  validationErrors: string[];
  validationWarnings: string[];
  showLosses?: boolean | null;
  onRefresh?: () => Promise<void>;
}

const retrieveETagManagerState = (state: TRootState) => {
  const { isDetailEdited } = state.detail.present;

  return {
    isDetailEdited,
  };
};

const ChangeRequest = (props: IChangeRequestProps): JSX.Element => {
  const {
    cleanUpProfileChanges,
    clearETagDetailEdits,
    contactInfo,
    contactInfoEditableProps,
    errorMessage,
    generationPhysicalSegment,
    initialGenerationPhysicalSegment,
    initialLoadPhysicalSegment,
    initialLossAccountings,
    initialMarketSegments,
    initialTransmissionAllocations,
    initialTransmissionPhysicalSegments,
    isCorrection,
    isFaxValid,
    isNotesDisabled,
    isPhoneValid,
    isProfileChange,
    isValidateAndSubmitSuccessful,
    isValidateSuccessful,
    isValidating,
    loadPhysicalSegment,
    lossAccountings,
    marketSegments,
    miscInfoEditableProps,
    miscInfos,
    notes,
    physicalSegmentsProfiles,
    profileChanges,
    retrieveTransactionStatuses,
    securityKey,
    selectedRequestType,
    setErrorMessage,
    setIsValidateSuccessful,
    setIsValidateAndSubmitSuccessful,
    setIsValidating,
    setNotes,
    setSkipValidation,
    setValidationErrors,
    setValidationWarnings,
    resetActionBarValidationErrors,
    skipValidation,
    tagId,
    tagPrimaryKey,
    timeZone,
    toEntityId,
    transmissionAllocations,
    transmissionPhysicalSegments,
    useUniqueProfiles,
    validationErrors,
    validationWarnings,
    showLosses,
    onRefresh,
  } = props;

  const { isDetailEdited } = useSelector(retrieveETagManagerState);

  const handleNotesChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setNotes(event.target.value);
  };

  const reset = useCallback(() => {
    setErrorMessage(null);
    setIsValidateSuccessful(false);
    setIsValidateAndSubmitSuccessful(false);
    setValidationErrors([]);
    setValidationWarnings([]);
    resetActionBarValidationErrors();
  }, [
    setErrorMessage,
    setIsValidateSuccessful,
    setIsValidateAndSubmitSuccessful,
    setValidationErrors,
    setValidationWarnings,
    resetActionBarValidationErrors,
  ]);

  const handleValidate = useCallback(async () => {
    try {
      reset();
      setIsValidating(true);

      if (isCorrection) {
        const correctionRequest: IETagCorrectionRequest | null =
          getCorrectionRequest(
            tagPrimaryKey,
            tagId,
            securityKey,
            marketSegments,
            initialMarketSegments,
            generationPhysicalSegment,
            initialGenerationPhysicalSegment,
            transmissionPhysicalSegments,
            initialTransmissionPhysicalSegments,
            loadPhysicalSegment,
            initialLoadPhysicalSegment,
            transmissionAllocations,
            initialTransmissionAllocations,
            lossAccountings,
            initialLossAccountings,
            contactInfo,
            notes,
            useUniqueProfiles,
            timeZone,
            showLosses,
          );

        if (correctionRequest === null) {
          setErrorMessage(
            'Incomplete changes were found, nothing to validate.',
          );
        } else {
          const response: AxiosResponse<IETagValidationResponse> =
            await validateRequestCorrection(
              toEntityId,
              timeZone,
              correctionRequest,
            );
          const eTagValidationResponse: IETagValidationResponse = response.data;

          if (isSuccessStatus(response.status)) {
            const validationResultList: IETagValidationResultList =
              eTagValidationResponse.response;

            setValidationWarnings(
              extractValidationWarningMessages(
                validationResultList.warning_list,
              ),
            );

            setValidationErrors(
              extractValidationFailureMessages(
                validationResultList.failure_list,
              ),
            );

            if (validationResultList.valid) {
              setIsValidateSuccessful(true);
            }
          } else {
            resetActionBarValidationErrors();

            throw new Error(eTagValidationResponse.errorMessage!);
          }
        }
      } else if (isProfileChange) {
        if (selectedRequestType === undefined) {
          setErrorMessage('A Change Request Type must be selected.');
        } else {
          const profileChangeRequest: IETagProfileChangeRequest | null =
            getProfileChangeRequest(
              tagPrimaryKey,
              tagId,
              securityKey,
              selectedRequestType,
              loadPhysicalSegment,
              transmissionAllocations,
              initialTransmissionAllocations,
              lossAccountings,
              initialLossAccountings,
              physicalSegmentsProfiles,
              profileChanges,
              contactInfo,
              notes,
              miscInfos,
              useUniqueProfiles,
              timeZone,
              showLosses,
            );

          if (profileChangeRequest === null) {
            setErrorMessage(
              'Incomplete changes were found, nothing to validate.',
            );
          } else {
            cleanUpProfileChanges();

            const response: AxiosResponse<IETagValidationResponse> =
              await validateRequestProfileChange(
                toEntityId,
                timeZone,
                profileChangeRequest,
              );
            const eTagValidationResponse: IETagValidationResponse =
              response.data;

            if (isSuccessStatus(response.status)) {
              const validationResultList: IETagValidationResultList =
                eTagValidationResponse.response;

              setValidationWarnings(
                extractValidationWarningMessages(
                  validationResultList.warning_list,
                ),
              );

              setValidationErrors(
                extractValidationFailureMessages(
                  validationResultList.failure_list,
                ),
              );

              if (validationResultList.valid) {
                setIsValidateSuccessful(true);
              }
            } else {
              resetActionBarValidationErrors();

              throw new Error(
                `${response.status} ${eTagValidationResponse.errorMessage} ${eTagValidationResponse.response}`,
              );
            }
          }
        }
      }
    } catch (error: any) {
      captureError(error);

      setErrorMessage(
        'An error occurred during validation. Please try again later.',
      );
    } finally {
      setIsValidating(false);
    }
  }, [
    cleanUpProfileChanges,
    contactInfo,
    generationPhysicalSegment,
    initialGenerationPhysicalSegment,
    initialLoadPhysicalSegment,
    initialLossAccountings,
    initialMarketSegments,
    initialTransmissionAllocations,
    initialTransmissionPhysicalSegments,
    isCorrection,
    isProfileChange,
    loadPhysicalSegment,
    lossAccountings,
    marketSegments,
    miscInfos,
    notes,
    physicalSegmentsProfiles,
    profileChanges,
    reset,
    securityKey,
    selectedRequestType,
    setErrorMessage,
    setIsValidateSuccessful,
    setIsValidating,
    setValidationErrors,
    setValidationWarnings,
    tagId,
    tagPrimaryKey,
    timeZone,
    toEntityId,
    transmissionAllocations,
    transmissionPhysicalSegments,
    useUniqueProfiles,
    showLosses,
    resetActionBarValidationErrors,
  ]);

  const handleValidateAndSubmit = useCallback(async () => {
    let validResponse = false;

    try {
      reset();

      setIsValidating(true);

      if (isCorrection) {
        const correctionRequest: IETagCorrectionRequest | null =
          getCorrectionRequest(
            tagPrimaryKey,
            tagId,
            securityKey,
            marketSegments,
            initialMarketSegments,
            generationPhysicalSegment,
            initialGenerationPhysicalSegment,
            transmissionPhysicalSegments,
            initialTransmissionPhysicalSegments,
            loadPhysicalSegment,
            initialLoadPhysicalSegment,
            transmissionAllocations,
            initialTransmissionAllocations,
            lossAccountings,
            initialLossAccountings,
            contactInfo,
            notes,
            useUniqueProfiles,
            timeZone,
            showLosses,
          );

        if (correctionRequest === null) {
          setErrorMessage(
            'Incomplete changes were found, nothing to validate and submit.',
          );
        } else {
          const axiosResponse: AxiosResponse<IETagValidationResponse> =
            await requestCorrection(
              toEntityId,
              timeZone,
              skipValidation,
              correctionRequest,
            );
          const validationResultList: IETagValidationResultList =
            axiosResponse.data.response;

          if (isSuccessStatus(axiosResponse.status)) {
            setValidationWarnings(
              extractValidationWarningMessages(
                validationResultList.warning_list,
              ),
            );

            setValidationErrors(
              extractValidationFailureMessages(
                validationResultList?.failure_list ?? [],
              ),
            );

            if (
              !validationResultList.failure_list ||
              (validationResultList &&
                validationResultList.failure_list.length === 0) ||
              validationResultList.valid
            ) {
              setIsValidateAndSubmitSuccessful(true);

              clearETagDetailEdits();
              validResponse = true;
            }
          } else {
            if (
              validationResultList.failure_list === null ||
              validationResultList.failure_list.length === 0
            ) {
              resetActionBarValidationErrors();

              throw new Error(
                axiosResponse.data.errorMessage === null
                  ? 'Unknown error'
                  : axiosResponse.data.errorMessage,
              );
            } else {
              setValidationWarnings(
                extractValidationWarningMessages(
                  validationResultList.warning_list,
                ),
              );

              setValidationErrors(
                extractValidationFailureMessages(
                  validationResultList?.failure_list ?? [],
                ),
              );
            }
          }
        }
      } else if (isProfileChange) {
        const profileChangeRequest: IETagProfileChangeRequest | null =
          getProfileChangeRequest(
            tagPrimaryKey,
            tagId,
            securityKey,
            selectedRequestType,
            loadPhysicalSegment,
            transmissionAllocations,
            initialTransmissionAllocations,
            lossAccountings,
            initialLossAccountings,
            physicalSegmentsProfiles,
            profileChanges,
            contactInfo,
            notes,
            miscInfos,
            useUniqueProfiles,
            timeZone,
            showLosses,
          );

        if (profileChangeRequest === null) {
          setErrorMessage(
            'Incomplete changes were found, nothing to validate and submit.',
          );
        } else {
          cleanUpProfileChanges();

          const axiosResponse: AxiosResponse<IETagValidationResponse> =
            await requestProfileChange(
              toEntityId,
              timeZone,
              skipValidation,
              profileChangeRequest,
            );
          const validationResultList: IETagValidationResultList =
            axiosResponse.data.response;

          if (isSuccessStatus(axiosResponse.status)) {
            setValidationWarnings(
              extractValidationWarningMessages(
                validationResultList.warning_list,
              ),
            );

            setValidationErrors(
              extractValidationFailureMessages(
                validationResultList?.failure_list ?? [],
              ),
            );

            if (
              !validationResultList.failure_list ||
              (validationResultList &&
                validationResultList.failure_list.length === 0) ||
              validationResultList.valid
            ) {
              setIsValidateAndSubmitSuccessful(true);

              retrieveTransactionStatuses();

              clearETagDetailEdits();
              validResponse = true;
            }
          } else {
            if (
              validationResultList.failure_list === null ||
              validationResultList.failure_list.length === 0
            ) {
              resetActionBarValidationErrors();

              throw new Error(
                axiosResponse.data.errorMessage === null
                  ? 'Unknown error'
                  : axiosResponse.data.errorMessage,
              );
            } else {
              setValidationWarnings(
                extractValidationWarningMessages(
                  validationResultList.warning_list,
                ),
              );

              setValidationErrors(
                extractValidationFailureMessages(
                  validationResultList?.failure_list ?? [],
                ),
              );
            }
          }
        }
      }
    } catch (error: any) {
      captureError(error);

      setErrorMessage(
        'An error occurred during validation and submit. Please try again later.',
      );
    } finally {
      setIsValidating(false);
      if (onRefresh && validResponse && !isDetailEdited) {
        onRefresh().then(() => (validResponse = false));
      }
    }
  }, [
    cleanUpProfileChanges,
    clearETagDetailEdits,
    contactInfo,
    generationPhysicalSegment,
    initialGenerationPhysicalSegment,
    initialLoadPhysicalSegment,
    initialLossAccountings,
    initialMarketSegments,
    initialTransmissionAllocations,
    initialTransmissionPhysicalSegments,
    isCorrection,
    isProfileChange,
    loadPhysicalSegment,
    lossAccountings,
    marketSegments,
    miscInfos,
    notes,
    physicalSegmentsProfiles,
    profileChanges,
    reset,
    retrieveTransactionStatuses,
    securityKey,
    selectedRequestType,
    setErrorMessage,
    setIsValidateAndSubmitSuccessful,
    setIsValidating,
    setValidationErrors,
    setValidationWarnings,
    resetActionBarValidationErrors,
    skipValidation,
    tagId,
    tagPrimaryKey,
    timeZone,
    toEntityId,
    transmissionAllocations,
    transmissionPhysicalSegments,
    useUniqueProfiles,
    showLosses,
    onRefresh,
    isDetailEdited,
  ]);

  const isDisabled: boolean =
    isValidating ||
    isEmptyValue(contactInfo.contact) ||
    !isFaxValid ||
    !isPhoneValid;
  const requestType: string = isCorrection
    ? 'Correction'
    : isProfileChange
    ? 'Profile change'
    : '';

  return (
    <Layout>
      <ContactInfoEditable {...contactInfoEditableProps} />
      <SeparatedRowLayout>
        <OptionalLabel>
          Notes:
          <br />
          (optional)
        </OptionalLabel>
        <TextArea
          isDisabled={isNotesDisabled}
          maxLength={NOTES_MAX_LENGTH}
          onChange={handleNotesChange}
          placeholder='Notes'
          rows={3}
          value={notes}
        />
      </SeparatedRowLayout>
      {isProfileChange ? (
        <SeparatedRowLayout>
          <OptionalLabel>
            Misc Infos:
            <br />
            (optional)
          </OptionalLabel>
          <MiscInfoContainer>
            <MiscInfoEditable {...miscInfoEditableProps} />
          </MiscInfoContainer>
        </SeparatedRowLayout>
      ) : null}
      <ETagRequestFormFeedback
        errorMessage={errorMessage}
        isValidateAndSubmitSuccessful={isValidateAndSubmitSuccessful}
        isValidateSuccessful={isValidateSuccessful}
        isValidating={isValidating}
        requestType={requestType}
        validationErrors={validationErrors}
        validationWarnings={validationWarnings}
      />
      <ETagRequestFormFooter
        handleValidate={handleValidate}
        handleValidateAndSubmit={handleValidateAndSubmit}
        isDisabled={isDisabled}
        requestType={requestType}
        setSkipValidation={setSkipValidation}
        skipValidation={skipValidation}
        validationErrors={validationErrors}
      />
    </Layout>
  );
};

export default ChangeRequest;
