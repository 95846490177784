import {
  ControlOutlined,
  CopyrightCircleOutlined,
  PlusOutlined,
  RedoOutlined,
  SlidersOutlined,
  StarOutlined,
  StopOutlined,
  ToolOutlined,
  UndoOutlined,
} from '@ant-design/icons';
import CurrentPendingIcon from 'components/atoms/CurrentPendingIcon/CurrentPendingIcon';
import Tooltip from 'components/molecules/Tooltip/Tooltip';
import {
  ICON_FONT_SIZE,
  STATE_BLUE,
  STATE_GREEN,
  STATE_GREY,
  STATE_ORANGE,
  STATE_RED,
} from 'constants/styles';
import { ERequestType } from 'enums/ETag';
import styled from 'styled-components';

const AtfAdjustmentIcon = styled(SlidersOutlined)`
  color: ${STATE_BLUE};
  font-size: ${ICON_FONT_SIZE};
`;

const BtfAdjustmentIcon = styled(ControlOutlined)`
  color: ${STATE_ORANGE};
  font-size: ${ICON_FONT_SIZE};
`;

const CorrectionIcon = styled(ToolOutlined)`
  color: ${STATE_GREY};
  font-size: ${ICON_FONT_SIZE};
`;

const CurrentLevelIcon = styled(CopyrightCircleOutlined)`
  color: ${STATE_GREEN};
  font-size: ${ICON_FONT_SIZE};
`;

const StyledCurrentPendingIcon = styled(CurrentPendingIcon)`
  color: ${STATE_ORANGE};
  font-size: ${ICON_FONT_SIZE};
`;

const CurtailmentIcon = styled(RedoOutlined)`
  color: ${STATE_RED};
  font-size: ${ICON_FONT_SIZE};
`;

const ExtensionIcon = styled(PlusOutlined)`
  color: ${STATE_ORANGE};
  font-size: ${ICON_FONT_SIZE};
`;

const NewTagIcon = styled(StarOutlined)`
  color: ${STATE_GREY};
  font-size: ${ICON_FONT_SIZE};
`;

const ReloadIcon = styled(UndoOutlined)`
  color: ${STATE_GREEN};
  font-size: ${ICON_FONT_SIZE};
`;

const TerminationIcon = styled(StopOutlined)`
  color: ${STATE_GREY};
  font-size: ${ICON_FONT_SIZE};
`;

const StyledTooltip = styled(Tooltip)`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

interface IProps {
  hideTooltip?: boolean;
  requestType: ERequestType | null;
}

const RequestTypeIcon = (props: IProps): JSX.Element | null => {
  const { hideTooltip, requestType } = props;
  let icon: JSX.Element;

  switch (requestType) {
    case ERequestType.AtfAdjustment: {
      icon = <AtfAdjustmentIcon />;
      break;
    }
    case ERequestType.BtfAdjustment: {
      icon = <BtfAdjustmentIcon />;
      break;
    }
    case ERequestType.Correction: {
      icon = <CorrectionIcon />;
      break;
    }
    case ERequestType.CurrentLevel: {
      icon = <CurrentLevelIcon />;
      break;
    }
    case ERequestType.CurrentPendingLevel: {
      icon = <StyledCurrentPendingIcon />;
      break;
    }
    case ERequestType.Curtailment: {
      icon = <CurtailmentIcon />;
      break;
    }
    case ERequestType.Extension: {
      icon = <ExtensionIcon />;
      break;
    }
    case ERequestType.NewTag: {
      icon = <NewTagIcon />;
      break;
    }
    case ERequestType.Reload: {
      icon = <ReloadIcon />;
      break;
    }
    case ERequestType.Termination: {
      icon = <TerminationIcon />;
      break;
    }
    default: {
      return null;
    }
  }

  return hideTooltip === true ? (
    icon
  ) : (
    <StyledTooltip title={requestType}>{icon}</StyledTooltip>
  );
};

export default RequestTypeIcon;
