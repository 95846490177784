import { INPUT_HEIGHT, INPUT_HEIGHT_VALUE } from 'constants/styles';
import { IValueChanged } from 'interfaces/Component';
import styled from 'styled-components';
import { inputValueChanged } from 'utils/styles';

const Wrapper = styled.div<IValueChanged>`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: ${INPUT_HEIGHT};
  width: ${1.5 * INPUT_HEIGHT_VALUE}px;

  > div {
    white-space: nowrap;
    width: 100%;
  }

  ${(props) => inputValueChanged(props)}
`;

interface IDataIndicatorProps extends IValueChanged {
  value: number | string;
}

const DataIndicator = ({
  value,
  valueChanged,
}: IDataIndicatorProps): JSX.Element => {
  return (
    <Wrapper valueChanged={valueChanged}>
      <div>{value}</div>
    </Wrapper>
  );
};

export default DataIndicator;
