import { DatePicker as AntDesignDatePicker } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import { toZonedDateTimeRange } from 'components/molecules/DateTimeRangePicker/helpers';
import { IShowTime } from 'interfaces/Component';
// this is one of only a few places we allow moment
// See (utils/zonedDateTime.ts) for why.
// eslint-disable-next-line no-restricted-imports
import { Moment } from 'moment';
import { useMemo } from 'react';
import { TTimeZone, TZonedDateTimeRange } from 'types/DateTime';
import { TDisabledDateHandler } from 'types/General';
import {
  toDisabledMoment,
  ZonedDateTime,
  zonedDateTimetoMoment,
} from 'utils/zonedDateTime';

const { RangePicker: AntDesignRangePicker } = AntDesignDatePicker;

export type TMomentRange = [Moment | null, Moment | null] | null;

export interface IRangeInfo {
  range: 'start' | 'end';
}

export interface IZonedDateTimeRangePickerProps
  extends Omit<
    RangePickerProps,
    'disabledDate' | 'onChange' | 'onCalendarChange' | 'value'
  > {
  disabledDate?: TDisabledDateHandler;
  isDisabled?: boolean;
  onCalendarChange?: (
    values: TZonedDateTimeRange,
    _formatString: [string, string],
    rangeInfo: IRangeInfo,
  ) => void;
  onChange?: (dates: TZonedDateTimeRange) => void;
  showTime?: IShowTime;
  timeZone: TTimeZone;
  value: TZonedDateTimeRange;
}

const ZonedDateTimeRangePicker = ({
  disabledDate,
  isDisabled,
  onCalendarChange,
  onChange,
  showTime,
  timeZone,
  value,
  ...rest
}: IZonedDateTimeRangePickerProps): JSX.Element => {
  const disabledMoment = useMemo(
    () => toDisabledMoment(timeZone, disabledDate),
    [timeZone, disabledDate],
  );

  const onChangeMomentRange = useMemo(
    () =>
      onChange === undefined
        ? undefined
        : (range: TMomentRange): void =>
            onChange(toZonedDateTimeRange(timeZone, range)),
    [onChange, timeZone],
  );

  const onCalendarChangeMoment = useMemo(
    () =>
      onCalendarChange === undefined
        ? undefined
        : (
            values: TMomentRange,
            _formatString: [string, string],
            rangeInfo: IRangeInfo,
          ) =>
            onCalendarChange(
              toZonedDateTimeRange(timeZone, values),
              _formatString,
              rangeInfo,
            ),
    [onCalendarChange, timeZone],
  );

  const startMoment = useMemo(
    () => zonedDateTimetoMoment(value?.[0] ?? null),
    [value],
  );

  const endMoment = useMemo(
    () => zonedDateTimetoMoment(value?.[1] ?? null),
    [value],
  );

  const showTimeMoment = useMemo(
    () =>
      showTime === undefined
        ? undefined
        : {
            defaultValue:
              showTime.defaultValue === undefined
                ? undefined
                : (showTime.defaultValue as ZonedDateTime[]).map(
                    (zonedDateTime: ZonedDateTime): Moment =>
                      zonedDateTime.asMoment(),
                  ),
            format: showTime.format,
          },
    [showTime],
  );

  return (
    <AntDesignRangePicker
      {...rest}
      disabledDate={disabledMoment}
      disabled={isDisabled}
      onChange={onChangeMomentRange}
      onCalendarChange={onCalendarChangeMoment}
      showTime={showTimeMoment}
      value={[startMoment, endMoment]}
    />
  );
};

export default ZonedDateTimeRangePicker;
