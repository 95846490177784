import RedoButton from 'components/molecules/RedoButton/RedoButton';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ActionCreators } from 'redux-undo';
import { TRootState } from 'types/Redux';

interface IETagManagerRedoButtonProps {
  isDisabled?: boolean;
}

const retrieveRedoButtonState = (state: TRootState) => {
  const { future } = state.detail;
  const isRedoable: boolean = future.length > 0;

  return {
    isRedoable,
  };
};

const ETagManagerRedoButton = ({
  isDisabled,
}: IETagManagerRedoButtonProps): JSX.Element => {
  const dispatch = useDispatch();
  const { isRedoable } = useSelector(retrieveRedoButtonState);

  const handleClick = useCallback(() => {
    dispatch(ActionCreators.redo());
  }, [dispatch]);

  return (
    <RedoButton isDisabled={isDisabled || !isRedoable} onClick={handleClick} />
  );
};

export default ETagManagerRedoButton;
