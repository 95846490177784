import {
  CellClassParams,
  ColDef,
  ICellRendererParams,
  ITooltipParams,
  ValueGetterParams,
} from 'ag-grid-community';
import { DEFAULT_ALTERNATING_ROW_STYLE } from 'components/molecules/RowStylingConfigurator/constants';
import { ERROR_RED, WARNING_ORANGE } from 'constants/styles';
import { DATE_FORMAT } from 'constants/time';
import { EColumnType } from 'enums/ETag';
import { EPageMode } from 'enums/Page';
import { ETheme } from 'enums/Style';
import { EDateTimeRangeDST } from 'enums/Time';
import { IThemedProps } from 'interfaces/Component';
import {
  ITransmissionAvailabilityColumnDataAgGrid,
  ITransmissionAvailabilityDataSet,
  ITransmissionCapacityAvailabilityReportViewData,
  ITransmissionCapacityAvailabilityReportViewTags,
} from 'interfaces/ETag';
import { TTimeZone } from 'types/DateTime';
import { TDetailPageLocationIdParameter } from 'types/Detail';
import { TToEntityId } from 'types/ToEntity';
import { detailPageLocationString } from 'utils/detail';
import { captureError } from 'utils/error';
import { backgroundColour } from 'utils/styles';
import {
  getDateTimeRangeDstType,
  toFormattedDateStringHandleNull,
  toFormattedDateTimeStringHandleNull,
} from 'utils/time';
import { ZonedDateTime } from 'utils/zonedDateTime';
import {
  defaultUsedRow,
  TRANSMISSION_AVAILABILITY_TEXT_BLACK,
  TRANSMISSION_AVAILABILITY_TEXT_WHITE,
} from './constants';
import { EHourlyTransmissionFields } from './types';

const transmissionAvailabilityTextColour = (props: IThemedProps): string =>
  props.currentTheme === ETheme.Dark
    ? TRANSMISSION_AVAILABILITY_TEXT_WHITE
    : TRANSMISSION_AVAILABILITY_TEXT_BLACK;

export const getColumnsForTransmissionAvailabiltyReport = (
  columnData: ITransmissionAvailabilityColumnDataAgGrid[],
  timeZone: TTimeZone,
  toEntityId: TToEntityId,
  currentTheme: string | undefined = ETheme.Light,
  showExtraHourColumn: boolean,
): ColDef[] => {
  // Transform from ITransmissionAvailabilityColumnDataAgGrid to ColDef
  const transformed: ColDef[] = [];

  columnData.forEach((column: ITransmissionAvailabilityColumnDataAgGrid) => {
    const columnDefinition: ColDef = {
      cellStyle: (params: CellClassParams) => ({
        backgroundColor:
          params.node.key === 'Available'
            ? params.value === 0
              ? WARNING_ORANGE
              : params.value < 0
              ? ERROR_RED
              : backgroundColour({ currentTheme })
            : backgroundColour({ currentTheme }),
        color:
          params.node.key === 'Available'
            ? params.value <= 0
              ? '#000000' // Use pure black text for contrast against warning/error backgrounds
              : transmissionAvailabilityTextColour({ currentTheme })
            : transmissionAvailabilityTextColour({ currentTheme }),
        textAlign: 'center',
        paddingLeft: '4px',
        paddingRight: '4px',
        ...(params.rowIndex && params.rowIndex % 2 === 1 && !params.node.group
          ? DEFAULT_ALTERNATING_ROW_STYLE[currentTheme]
          : null),
      }),
      filter: true,
      floatingFilter: true,
      resizable: true,
      sortable: true,
      menuTabs: [],
      tooltipValueGetter: (params: ITooltipParams) =>
        params.valueFormatted ?? params.value,
      field: column.field,
      filterParams: {
        newRowsAction: 'keep',
      },
      // TODO: Apply AG Grid recommended fix, if any
      // Disabled this to adress the column filter not being clickable in certain browsers
      // floatingFilterComponentParams: { suppressFilterButton: true },
      headerClass: column.headerClass,
      headerName: column.headerName,
      maxWidth: column.maxWidth,
      minWidth: column.minWidth,
      rowGroup: column.rowGroup,
      showRowGroup: column.showRowGroup,
      hide:
        column.field === 'he_02_extra' && showExtraHourColumn
          ? false
          : column.hide,
      sort: column.sort,
      aggFunc: column.aggFunc,
      valueFormatter: column.valueFormatter,
    };

    switch (column.type) {
      case EColumnType.Date:
      case EColumnType.DateTime: {
        if (column.type === EColumnType.Date) {
          columnDefinition.valueGetter = (params: ValueGetterParams) =>
            toFormattedDateStringHandleNull(
              params.data[column.field],
              timeZone,
            );
        }
        if (column.type === EColumnType.DateTime) {
          columnDefinition.valueGetter = (params: ValueGetterParams) =>
            toFormattedDateTimeStringHandleNull(
              params.data[column.field],
              timeZone,
            );
        }
        columnDefinition.chartDataType = 'time';
        columnDefinition.filter = 'agMultiColumnFilter';
        columnDefinition.filterParams = {
          ...columnDefinition.filterParams,
          filters: [
            {
              filter: 'agDateColumnFilter',
              filterParams: {
                comparator: function (filterDate: Date, cellValue: string) {
                  if (cellValue === null) {
                    return -1;
                  }
                  return (
                    new Date(
                      ZonedDateTime.parse(cellValue, 'UTC', DATE_FORMAT).format(
                        'M/D/YYYY',
                      ),
                    ).getTime() -
                    new Date(
                      ZonedDateTime.fromDate(filterDate, 'UTC').format(
                        'M/D/YYYY',
                      ),
                    ).getTime()
                  );
                },
                newRowsAction: 'keep',
              },
            },
            {
              filter: 'agSetColumnFilter',
            },
          ],
        };
        columnDefinition.floatingFilterComponentParams = {
          suppressFilterButton: false,
        };
        break;
      }
      case EColumnType.Enumeration:
      case EColumnType.String:
      case EColumnType.Group: {
        break;
      }
      case EColumnType.HourEndings:
      case EColumnType.Integer: {
        columnDefinition.filter = 'agNumberColumnFilter';
        columnDefinition.floatingFilterComponentParams = {
          suppressFilterButton: false,
        };
        columnDefinition.cellRenderer = (params: ICellRendererParams) => {
          if (Number.isNaN(params.value)) {
            return <></>;
          } else {
            return <>{params.value as string}</>;
          }
        };
        break;
      }
      case EColumnType.DetailLink: {
        columnDefinition.cellRenderer = (params: ICellRendererParams) => {
          if (!params.value) {
            return null;
          }
          if (params.data.data_type !== 'Used') {
            return params.value;
          }
          let idparams: TDetailPageLocationIdParameter | undefined;

          if (
            params.data.data_name !== null &&
            params.data.data_name !== 'Used'
          ) {
            if (params.data.tag_primary_key) {
              idparams = { tagPrimaryKey: params.data.tag_primary_key };
            } else {
              idparams = undefined;
            }
          } else {
            idparams = undefined;
          }

          if (idparams === undefined) {
            return params.value;
          } else {
            return (
              // eslint-disable-next-line react/jsx-no-target-blank
              <a
                href={
                  window.ETAG_DEPLOYMENT.reactConfig.baseDir +
                  detailPageLocationString({
                    ...idparams,
                    defaultTimeZone: timeZone,
                    mode: EPageMode.Review,
                    toEntity: toEntityId,
                  })
                }
                target='_blank'
              >
                {params.value}
              </a>
            );
          }
        };
        break;
      }
      default: {
        throw new Error(`Invalid column type: ${columnDefinition.type}`);
      }
    }

    transformed.push(columnDefinition);
  });

  return transformed;
};

// Returns the compiled data set along with a boolean flag showing if there is the extra HE_02 data for long DST days
export const getTransmissionAvailabilityData = (
  transmissionCapacityDataArray: ITransmissionCapacityAvailabilityReportViewData[],
  startDate: ZonedDateTime,
  endDate: ZonedDateTime,
): [ITransmissionAvailabilityDataSet[], boolean] => {
  let transmissionAvailabilityDataSets: ITransmissionAvailabilityDataSet[] = [];
  let hasExtraColumn: boolean = false;
  try {
    transmissionCapacityDataArray.forEach(
      (
        transmissionCapacityData: ITransmissionCapacityAvailabilityReportViewData,
      ) => {
        let dayCount = 0;
        let hourOffset = 0; // Can become 1 or -1 if we encounter a long or short date due to DST
        for (
          let dataDate = startDate;
          dataDate < endDate;
          dataDate = dataDate.add(1, 'day')
        ) {
          let availableCapacityRow: ITransmissionAvailabilityDataSet = {
            aref: transmissionCapacityData.aref,
            pod: transmissionCapacityData.pod,
            por: transmissionCapacityData.por,
            request_type: transmissionCapacityData.request_type,
            offer_price: transmissionCapacityData.offer_price,
            bid_price: transmissionCapacityData.bid_price,
            ts_type: transmissionCapacityData.ts_type,
            ts_class: transmissionCapacityData.ts_class,
            service_increment: transmissionCapacityData.service_increment,
            nerc_curtailment_priority:
              transmissionCapacityData.nerc_curtailment_priority,
            status: transmissionCapacityData.status,
            path: transmissionCapacityData.path,
            date: dataDate.format(DATE_FORMAT),
            data_type: 'Available',
            data_name: 'Available',
            transmission_provider:
              transmissionCapacityData.transmission_provider,
            start_date: transmissionCapacityData.start_date,
            end_date: transmissionCapacityData.end_date,
          };
          let grantedCapacityRow: ITransmissionAvailabilityDataSet = {
            ...availableCapacityRow,
            data_type: 'Granted',
            data_name: 'Granted',
          };
          let customCapacityRow: ITransmissionAvailabilityDataSet = {
            ...availableCapacityRow,
            data_type: 'CustomCapacity',
            data_name: 'CustomCapacity',
          };

          // Default row used to populate 'Used' group row with zeros if there is no usage for this date/aref combo
          let dayArefDefaultUsedRow: ITransmissionAvailabilityDataSet = {
            ...defaultUsedRow,
            aref: transmissionCapacityData.aref,
            por: transmissionCapacityData.por,
            pod: transmissionCapacityData.pod,
            path: transmissionCapacityData.path,
            date: dataDate.format(DATE_FORMAT),
          };
          let tagCapacityRows: ITransmissionAvailabilityDataSet[] = [];
          if (transmissionCapacityData.tags) {
            transmissionCapacityData.tags.forEach(
              (
                transmissionCapacityTag: ITransmissionCapacityAvailabilityReportViewTags,
              ) => {
                tagCapacityRows.push({
                  ...availableCapacityRow,
                  data_type: 'Used',
                  data_name: transmissionCapacityTag.ui_tag_id,
                  tag_primary_key: transmissionCapacityTag.tag_primary_key,
                  has_hour_data: false,
                });
              },
            );
          }

          const currentDayDstType: EDateTimeRangeDST = getDateTimeRangeDstType(
            dataDate,
            dataDate.add(1, 'day'),
          );
          // Add transmission data hour by hour
          let currentHour = dayCount * 24 + hourOffset;
          Object.values(EHourlyTransmissionFields).forEach(
            (hourlyTransmissionField: string) => {
              // Check for long or short day to add or skip field
              if (
                hourlyTransmissionField === 'he_02_extra' &&
                currentDayDstType !== EDateTimeRangeDST.Longer
              ) {
                return;
              }
              if (
                currentDayDstType === EDateTimeRangeDST.Shorter &&
                hourlyTransmissionField === 'he_03'
              ) {
                return;
              }
              availableCapacityRow = {
                ...availableCapacityRow,
                [hourlyTransmissionField]:
                  transmissionCapacityData.available_capacity
                    ? transmissionCapacityData.available_capacity[currentHour]
                    : null,
              };

              grantedCapacityRow = {
                ...grantedCapacityRow,
                [hourlyTransmissionField]:
                  transmissionCapacityData.capacity_granted
                    ? transmissionCapacityData.capacity_granted[currentHour]
                    : null,
              };

              customCapacityRow = {
                ...customCapacityRow,
                [hourlyTransmissionField]:
                  transmissionCapacityData.custom_capacity_granted
                    ? transmissionCapacityData.custom_capacity_granted[
                        currentHour
                      ]
                    : null,
              };

              tagCapacityRows.forEach(
                (
                  tagCapacityRow: ITransmissionAvailabilityDataSet,
                  index: number,
                ) => {
                  const newTagCapacityRow = {
                    ...tagCapacityRow,
                    [hourlyTransmissionField]: transmissionCapacityData.tags
                      ? transmissionCapacityData.tags[index].transmission[
                          currentHour
                        ]
                      : null,
                    has_hour_data:
                      (transmissionCapacityData.tags
                        ? transmissionCapacityData.tags[index].transmission[
                            currentHour
                          ]
                        : 0) >= 0 || tagCapacityRow.has_hour_data === true,
                  };
                  tagCapacityRows[index] = newTagCapacityRow;
                },
              );
              currentHour++;
            },
          );

          transmissionAvailabilityDataSets.push(availableCapacityRow);
          transmissionAvailabilityDataSets.push(grantedCapacityRow);
          transmissionAvailabilityDataSets.push(customCapacityRow);

          let hasUsedCapacity: boolean = false;
          tagCapacityRows.forEach(
            (tagCapacityRow: ITransmissionAvailabilityDataSet) => {
              if (tagCapacityRow.has_hour_data) {
                transmissionAvailabilityDataSets.push(tagCapacityRow);
                hasUsedCapacity = true;
              }
            },
          );
          if (!hasUsedCapacity) {
            transmissionAvailabilityDataSets.push(dayArefDefaultUsedRow);
          }
          if (currentDayDstType === EDateTimeRangeDST.Longer) {
            hourOffset = 1;
            hasExtraColumn = true;
          } else if (currentDayDstType === EDateTimeRangeDST.Shorter) {
            hourOffset = -1;
          }
          dayCount++;
        }
      },
    );
  } catch (error: any) {
    captureError(error, 'Failed to load transmission capacity data');
  }
  return [transmissionAvailabilityDataSets, hasExtraColumn];
};
