// In order to support web workers we need to be able to load in an external JS
// file separate to the React build. This is due to web workers operating on
// separate JS scripts as described here:
// https://developer.mozilla.org/en-US/docs/Web/API/Web_Workers_API
// This uses webpack and inline loader syntax to bring in the correct file.
// eslint-disable-next-line import/no-webpack-loader-syntax
import * as webSocketWorker from 'file-loader?name=[name].js!./webSocketWorker';
import usePrevious from 'hooks/usePrevious';
import {
  EWebSocketWorkerMessageType,
  IWebSocketWorkerMessage,
} from 'hooks/useWebSocket/types';
import { useEffect, useRef, useState } from 'react';
import { TErrorMessage } from 'types/Error';
import { captureError } from 'utils/error';

// imported webSocketWorker could be either a path string or an object representing a module
const webSocketWorkerPath =
  typeof webSocketWorker === 'object'
    ? (webSocketWorker as { default: string }).default
    : typeof webSocketWorker === 'string'
    ? webSocketWorker
    : undefined;

const ECHO_WEB_SOCKET_URL = 'wss://echo.websocket.org';

export interface IwebSocketConfig<T> {
  messageDeserializer: (data: string) => T;
  messageSerializer: (message: T) => string;
  useEcho?: boolean;
  webSocketUrl?: string;
}

export interface IuseWebSocket<T> {
  error: TErrorMessage;
  isConnected: boolean;
  postMessage: (message: T, sendDelayInMilliseconds?: number) => void;
  setCloseHandler: (
    closeHandler: (closeCode: number, closeReason: string) => void,
  ) => void;
  setMessageHandler: (messageHandler: (message: T) => void) => void;
  setSentHandler: (sentHandler: () => void) => void;
}

const useWebSocket = <T>(
  webSocketConfig: IwebSocketConfig<T>,
): IuseWebSocket<T> => {
  // All our web socket functionality is handled on a separate worker 'thread'
  // so that we don't block the single JS thread during message processing and
  // vice versa.
  const { messageDeserializer, messageSerializer, useEcho, webSocketUrl } =
    webSocketConfig;
  const currentWebSocketUrl: string | undefined = useEcho
    ? ECHO_WEB_SOCKET_URL
    : webSocketUrl;
  const [webSocketWorker, setWebSocketWorker] = useState<Worker | undefined>();
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [error, setError] = useState<TErrorMessage>(null);
  const previousWebSocketUrl = usePrevious(currentWebSocketUrl);
  const previousIsConnected = usePrevious(isConnected);
  const closeHandler = useRef<(code: number, reason: string) => void>();
  const messageHandler = useRef<(message: T) => void>();
  const sentHandler = useRef<() => void>();
  const isMountedRef = useRef<boolean>(false);

  useEffect(() => {
    isMountedRef.current = true;

    return () => {
      isMountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    let currentWebSocketWorker: Worker | undefined = webSocketWorker;

    if (webSocketWorkerPath === undefined) {
      throw new Error('Unable to load WebSocketWorker');
    }

    if (currentWebSocketWorker === undefined) {
      currentWebSocketWorker = new Worker(webSocketWorkerPath);
      setWebSocketWorker(currentWebSocketWorker);
    }

    if (
      currentWebSocketUrl !== previousWebSocketUrl ||
      (previousIsConnected !== isConnected && isConnected === false)
    ) {
      currentWebSocketWorker.postMessage({
        type: EWebSocketWorkerMessageType.Connect,
        url: currentWebSocketUrl,
      });
    }
  }, [
    currentWebSocketUrl,
    isConnected,
    previousIsConnected,
    previousWebSocketUrl,
    webSocketWorker,
  ]);

  useEffect(() => {
    if (webSocketWorker !== undefined) {
      webSocketWorker.onmessage = (messageEvent: MessageEvent) => {
        const webSocketWorkerMessage =
          messageEvent.data as IWebSocketWorkerMessage<T>;
        switch (webSocketWorkerMessage.type) {
          case EWebSocketWorkerMessageType.Connected: {
            if (isMountedRef.current === true) {
              setIsConnected(true);
            }

            return;
          }
          case EWebSocketWorkerMessageType.Closed: {
            if (closeHandler.current !== undefined) {
              closeHandler.current(
                messageEvent.data.closeCode,
                messageEvent.data.closeReason,
              );
            }

            if (isMountedRef.current === true) {
              setIsConnected(false);
            }

            return;
          }
          case EWebSocketWorkerMessageType.Message: {
            if (messageHandler.current !== undefined) {
              try {
                messageHandler.current(
                  messageDeserializer(messageEvent.data.message),
                );
              } catch (error: any) {
                captureError(error);

                if (isMountedRef.current === true) {
                  setError(`Deserialization error: ${error.message}`);
                }
              }
            }
            return;
          }
          case EWebSocketWorkerMessageType.Error: {
            if (isMountedRef.current === true) {
              setError(webSocketWorkerMessage.error!);
            }

            return;
          }
          case EWebSocketWorkerMessageType.Sent: {
            if (sentHandler.current !== undefined) {
              sentHandler.current();
            }

            if (isMountedRef.current === true) {
              setError(null);
            }

            return;
          }
          default: {
            return;
          }
        }
      };
    }
  }, [error, messageDeserializer, webSocketWorker]);

  useEffect(() => {
    // We only do this on component unmount
    return () => {
      if (webSocketWorker !== undefined) {
        webSocketWorker.postMessage({
          type: EWebSocketWorkerMessageType.Close,
        });

        webSocketWorker.terminate();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setCloseHandler = (
    newCloseHandler: (closeCode: number, closeReason: string) => void,
  ) => {
    closeHandler.current = newCloseHandler;
  };

  const setMessageHandler = (newMessageHandler: (message: T) => void) => {
    messageHandler.current = newMessageHandler;
  };

  const setSentHandler = (newSentHandler: () => void) => {
    sentHandler.current = newSentHandler;
  };

  const postMessage = (message: T, sendDelayInMilliseconds?: number) => {
    if (webSocketWorker !== undefined) {
      try {
        const sendData: string = messageSerializer(message);
        webSocketWorker.postMessage({
          sendData,
          sendDelayInMilliseconds,
          type: EWebSocketWorkerMessageType.Send,
        });
      } catch {
        setError(`Message serialization error: ${error}`);
      }
    }
  };

  return {
    error,
    isConnected,
    postMessage,
    setCloseHandler,
    setMessageHandler,
    setSentHandler,
  };
};

export default useWebSocket;
