import {
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import { ESeverity } from 'enums/General';

export interface IProps {
  severity: ESeverity;
}

const SeverityIcon = (props: IProps): JSX.Element => {
  const { severity } = props;
  let icon: JSX.Element;

  switch (severity) {
    case ESeverity.Error: {
      icon = (
        <CloseCircleOutlined className='anticon ant-notification-notice-icon-error' />
      );
      break;
    }
    case ESeverity.Info: {
      icon = (
        <InfoCircleOutlined className='anticon ant-notification-notice-icon-info' />
      );
      break;
    }
    case ESeverity.Warning: {
      icon = (
        <ExclamationCircleOutlined className='anticon ant-notification-notice-icon-warning' />
      );
      break;
    }
    default: {
      icon = <></>;
      break;
    }
  }
  return icon;
};

export default SeverityIcon;
