import { Radio, RadioChangeEvent, Space } from 'antd';
import SeparatedRowLayout from 'components/atoms/SeparatedRowLayout/SeparatedRowLayout';
import CustomTimeRangeSelector from 'components/molecules/ToEntityValidationReportCreate/CustomTimeRangeSelector';
import { useEffect, useState } from 'react';

interface IProps {
  defaultRangeExpression?: string;
  isDisabled?: boolean;
  onDataRangeChange: (range_expression: string | undefined) => void;
}

const RangeOfDataSelect = ({
  defaultRangeExpression,
  isDisabled,
  onDataRangeChange,
}: IProps): JSX.Element => {
  const [selectedRadioValue, setSelectedRadioValue] = useState<
    'hour' | 'day' | 'week' | 'month' | 'custom' | undefined
  >();
  const [customInputValue, setCustomInputValue] = useState<
    number | undefined
  >();
  const [defaultUnit, setDefaultUnit] = useState<
    'hour' | 'day' | 'week' | 'month' | undefined
  >();

  useEffect(() => {
    // parse the range expression to set state of input components
    if (!defaultRangeExpression) {
      setDefaultUnit(undefined);
      setCustomInputValue(undefined);
      setSelectedRadioValue(undefined);
      return;
    }
    // example [-1h,1h] or [-6d,6d]
    // strip off the non-alphanumeric pieces of the range expression
    // to get 1h1h or 6d6d
    let string = defaultRangeExpression
      .replace('[', '')
      .replace('-', '')
      .replace(',', '')
      .replace(']', '');
    // first and second half are the same
    string = string.substring(0, string.length / 2);

    // unit character is only the last character
    const unitChar: string = string.charAt(string.length - 1);
    // value is everything but the last character
    const value: number = parseInt(string.substring(0, string.length - 1));
    const unit: 'hour' | 'day' | 'week' | 'month' | undefined =
      unitChar === 'h'
        ? 'hour'
        : unitChar === 'd'
        ? 'day'
        : unitChar === 'w'
        ? 'week'
        : unitChar === 'm'
        ? 'month'
        : undefined;

    // if value isn't 1, we set the state of the custom input component
    if (value !== 1 && unit) {
      setSelectedRadioValue('custom');
      setCustomInputValue(value);
      setDefaultUnit(unit);
    }

    //  if value is 1, we use a simple radio button
    if (value === 1 && unit) {
      setSelectedRadioValue(unit);
      setDefaultUnit(unit);
    }
  }, [defaultRangeExpression]);

  /**
   * We parse the values of the component to create the range expression
   */
  const getRangeExpressionFromUnitAndValue = (
    unit: 'hour' | 'day' | 'week' | 'month' | undefined,
    value: number | undefined,
  ): string | undefined => {
    if (value === undefined || unit === undefined) {
      return undefined;
    }
    switch (unit) {
      case 'hour':
        return `[-${value}h,${value}h]`;
      case 'day':
        return `[-${value}d,${value}d]`;
      case 'week':
        return `[-${value}w,${value}w]`;
      case 'month':
        return `[-${value}m,${value}m]`;
    }
  };

  const handleDataRangeRadioChange = (event: RadioChangeEvent) => {
    setSelectedRadioValue(event.target.value);
    if (event.target.value !== 'custom') {
      // custom radio button has its own handler, don't handle that change here
      onDataRangeChange(
        getRangeExpressionFromUnitAndValue(event.target.value, 1),
      );
    }
  };

  const handleDataRangeChange = (
    unit: 'hour' | 'day' | 'week' | 'month' | undefined,
    value: number | undefined,
  ) => {
    if (selectedRadioValue === 'custom') {
      onDataRangeChange(getRangeExpressionFromUnitAndValue(unit, value));
    }
  };

  return (
    <Radio.Group
      disabled={isDisabled}
      onChange={handleDataRangeRadioChange}
      value={selectedRadioValue}
    >
      <Space direction='vertical' size={'small'}>
        <Radio value={'hour'}>±1 Hour</Radio>
        <Radio value={'day'}>±1 Day</Radio>
        <Radio value={'week'}>±1 Week</Radio>
        <Radio value={'month'}>±1 Month</Radio>
        <SeparatedRowLayout marginRight={0}>
          <Radio value={'custom'}>Custom: ±</Radio>
          {
            <CustomTimeRangeSelector
              defaultUnit={defaultUnit}
              defaultValue={customInputValue}
              onChange={handleDataRangeChange}
              isDisabled={
                selectedRadioValue !== 'custom' || isDisabled === true
              }
            />
          }
        </SeparatedRowLayout>
      </Space>
    </Radio.Group>
  );
};

export default RangeOfDataSelect;
