import { Modal as AntDesignModal } from 'antd';
import { ReactNode } from 'react';

interface IModalProps {
  children?: ReactNode;
  closable?: boolean;
  footer?: ReactNode;
  isVisible: boolean;
  onCancel?: () => void;
  title: ReactNode;
  width?: string | number;
}

const Modal = ({
  children,
  closable,
  footer,
  isVisible,
  onCancel,
  title,
  width,
}: IModalProps): JSX.Element => {
  return (
    <AntDesignModal
      centered={true}
      closable={closable}
      destroyOnClose={true}
      footer={footer}
      maskClosable={false}
      onCancel={onCancel}
      title={title}
      visible={isVisible}
      width={width}
      wrapClassName='modal'
    >
      {children}
    </AntDesignModal>
  );
};

export default Modal;
