import { SwapRightOutlined } from '@ant-design/icons';
import { InputNumber as AntDesignInputNumber } from 'antd';
import {
  EXPANDED_INPUT_HEIGHT_VALUE,
  EXPANSION_TRANSITION_IN_SECONDS_VALUE,
  FLOAT_BOX_SHADOW,
  INPUT_HEIGHT_VALUE,
} from 'constants/styles';
import { IExpandableProps } from 'interfaces/Component';
import { useState } from 'react';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import styled from 'styled-components';
import { filterSpecialCharacters, isEmptyValue } from 'utils/general';
import { inputBackground, inputBackgroundExpand } from 'utils/styles';

type TNumbers = [number | undefined, number | undefined];

interface IExpandableInputNumberRangeProps extends IExpandableProps {
  hideArrows?: boolean;
}

const Wrapper = styled.div<IExpandableInputNumberRangeProps>`
  height: ${INPUT_HEIGHT_VALUE}px;
  min-width: 23px;
  width: 100%;

  .ant-picker {
    ${(props) => inputBackground(props)}
    padding: 0;
  }

  .ant-input-number {
    align-items: center;
    border: 0;
    display: flex;
    flex-direction: row;
  }

  .ant-input-number-focused {
    box-shadow: unset;
  }

  ${(props) =>
    props.shouldExpand
      ? `
    .ant-picker {
      ${inputBackgroundExpand(props)}
      transition: ${EXPANSION_TRANSITION_IN_SECONDS_VALUE}s;
      transition-timing-function: ease;
    }

    .ant-picker-focused {
      box-shadow: ${FLOAT_BOX_SHADOW};
      height: ${EXPANDED_INPUT_HEIGHT_VALUE}px;
      width: 200%;
    }
  `
      : ''}

  ${(props) =>
    props.hideArrows
      ? `
    .ant-input-number-handler-wrap {
      display: none;
    }
  `
      : ''}
`;

const Container = styled.div`
  width: 100%;
`;

export interface IInputNumberRangeProps {
  className?: string;
  hideArrows?: boolean;
  max?: number;
  min?: number;
  preventSpecialCharacters?: boolean;
  onChange?: (numbers: [number | null, number | null]) => void;
  shouldExpand?: boolean;
  step?: number;
  values?: [number | null, number | null] | null;
}

const InputNumberRange = (props: IInputNumberRangeProps): JSX.Element => {
  const { currentTheme } = useThemeSwitcher();
  const {
    className,
    hideArrows,
    max,
    min,
    onChange,
    preventSpecialCharacters,
    shouldExpand,
    step,
    values,
  } = props;
  const [numbers, setNumbers] = useState<TNumbers>(
    values === undefined || values === null
      ? ([undefined, undefined] as TNumbers)
      : (values.map((value: number | null) =>
          value === null ? undefined : value,
        ) as TNumbers),
  );
  const [focused, setFocused] = useState<boolean>(false);

  const handleBlur = () => {
    setFocused(false);
  };

  const handleFocus = () => {
    setFocused(true);
  };

  const handleNumberInput =
    (index: number) => (_number: number | string | null | undefined) => {
      const validatedNumber: number | undefined = isEmptyValue(_number)
        ? undefined
        : Number(_number);

      setNumbers((previousNumbers: TNumbers): TNumbers => {
        const newNumbers: TNumbers = [...previousNumbers] as TNumbers;
        newNumbers[index] = validatedNumber;

        if (onChange) {
          onChange(
            newNumbers.map((value: number | undefined): number | null =>
              value === undefined ? null : value,
            ) as [number | null, number | null],
          );
        }

        return newNumbers;
      });
    };

  const [lowerValue, upperValue] = numbers;

  return (
    <Wrapper
      className={className}
      currentTheme={currentTheme!}
      hideArrows={hideArrows}
      shouldExpand={shouldExpand}
    >
      <Container
        className={`ant-picker ant-picker-range ${
          focused ? 'ant-picker-focused' : ''
        }`}
      >
        <AntDesignInputNumber
          max={max}
          min={min}
          onBlur={handleBlur}
          onChange={handleNumberInput(0)}
          onFocus={handleFocus}
          onKeyDown={
            preventSpecialCharacters ? filterSpecialCharacters : undefined
          }
          step={step}
          type='number'
          value={lowerValue === null ? undefined : lowerValue}
        />
        <div className='ant-picker-range-separator'>
          <span className='ant-picker-separator'>
            <SwapRightOutlined />
          </span>
        </div>
        <AntDesignInputNumber
          max={max}
          min={min}
          onBlur={handleBlur}
          onChange={handleNumberInput(1)}
          onFocus={handleFocus}
          onKeyDown={
            preventSpecialCharacters ? filterSpecialCharacters : undefined
          }
          step={step}
          type='number'
          value={upperValue === null ? undefined : upperValue}
        />
      </Container>
    </Wrapper>
  );
};

export default InputNumberRange;
