import { AxiosResponse } from 'axios';
import { DEFAULT_SUMMARY_PROFILES_BATCH_LOAD_SIZE } from 'constants/Summary';
import { EProfileSegment } from 'enums/ETag';
import { ERetreiveState } from 'enums/General';
import {
  IETagIdentifier,
  IETagsSummaryDealLinkageResponse,
  IETagSummaryAttributeDataSet,
  IETagSummaryAttributeRecord,
  IETagSummaryAttributeResponse,
  IETagSummaryDealLinkageDataSet,
  IETagSummaryDealLinkageResponseRecord,
  IETagSummaryProfile,
  IETagSummaryProfilesBatch,
  IETagSummaryProfilesBatchResponse,
  IETagSummaryProfilesRecord,
  IETagSummaryProfilesResponse,
} from 'interfaces/ETag';
import {
  IToEntitiesRecord,
  IToEntity,
  IToEntityRecord,
} from 'interfaces/ToEntity';
import Redux from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import {
  eTagIdentifiersToIdList,
  idToETagIdentifier,
  retrieveEtagSummaryAttribute,
  transformRetrievedETagSummaryProfiles,
} from 'reduxes/Summary/helpers';
import {
  ESummaryAction,
  IETagsSummaryAttributeRequest,
  ITenantETagsSummaryAttributeRequest,
  IETagsSummaryDealLinkageRequest,
  IETagsSummaryProfilesReply,
  IETagsSummaryProfilesRequest,
  IETagSummaryAttributeReply,
  IETagSummaryProfilesBatchReply,
  IETagSummaryProfilesBatchRequest,
  IETagSummaryProfilesReply,
  IRetrieveToEntityETagsSummaryAttributeReply,
  IRetrieveTenantETagsSummaryAttributeReply,
  IRetrieveToEntityETagsSummaryDealLinkageReply,
  IRetrieveToEntityETagsSummaryProfilesError,
  ISummaryActionPayload,
  IUpdateToEntityETagDraftsRequest,
  IUpdateToEntityETagsRequest,
  TCombinedSummaryAction,
  TETagSummaryAttributeRequest,
  TETagSummaryProfilesRequest,
  TSummaryAction,
  ITenantETagsSummaryProfilesRequest,
  ITenantETagsSummaryProfilesReply,
  ICombinedSummaryActionPayload,
  IRetrieveTenantETagsSummaryProfilesError,
  ITenantETagSummaryProfilesBatchRequest,
  ITenantETagSummaryProfilesBatchReply,
  IUpdateTenantETagsRequest,
  IUpdateTenantETagDraftsRequest,
  TETagCombinedSummaryAttributeRequest,
  TTenantETagSummaryProfilesRequest,
} from 'reduxes/Summary/types';
import {
  retrieveETagDistributedSummaryAttribute,
  retrieveETagDistributedSummaryProfiles,
} from 'services/agent/tags/distributed';
import {
  retrieveETagDraftSummaryAttribute,
  retrieveETagDraftSummaryProfiles,
} from 'services/agent/tags/drafts';
import {
  retrieveETagSummaryDealLinkageList,
  retrieveETagSummaryProfilesBatch,
} from 'services/agent/tags/summaryLoader';
import { TTimeZone } from 'types/DateTime';
import {
  TETagRecordKey,
  TETagSummaryAttributeMap,
  TETagSummaryDealLinkageMap,
  TETagSummaryDealLinkageRecord,
  TETagSummaryProfilesMap,
  TETagTagPrimaryKey,
} from 'types/ETag';
import { TFilterId } from 'types/Filter';
import { TRootState } from 'types/Redux';
import { captureError } from 'utils/error';
import {
  eTagSummaryAttributeToDataSet,
  eTagSummaryDealLinkageToDataSet,
  getRecordKeyForETagIdentifier,
} from 'utils/eTag';
import { isSuccessStatus } from 'utils/general';
import { ZonedDateTime } from 'utils/zonedDateTime';

// ETag Attribute

export const summaryRetrieveToEntityETagSummaryAttributeStart = (
  eTagSummaryAttributeRequest: TETagSummaryAttributeRequest,
): TSummaryAction => ({
  payload: eTagSummaryAttributeRequest,
  type: ESummaryAction.RetrieveToEntityETagSummaryAttributeStart,
});

export const summaryRetrieveToEntityETagSummaryAttributeSuccess = (
  eTagSummaryAttributeResponse: IETagSummaryAttributeReply,
): TSummaryAction => ({
  payload: eTagSummaryAttributeResponse,
  type: ESummaryAction.RetrieveToEntityETagSummaryAttributeSuccess,
});

export const summaryRetrieveToEntityETagSummaryAttributeFailure = (
  eTagSummaryAttributeResponse: IETagSummaryAttributeReply,
): TSummaryAction => ({
  payload: eTagSummaryAttributeResponse,
  type: ESummaryAction.RetrieveToEntityETagSummaryAttributeFailure,
});

export const summaryRetrieveToEntityETagSummaryAttribute = (
  eTagSummaryAttributeRequest: TETagSummaryAttributeRequest,
  start: ZonedDateTime,
  end: ZonedDateTime,
): ThunkAction<void, TRootState, unknown, TSummaryAction> => {
  return async (
    dispatch: ThunkDispatch<TRootState, unknown, TSummaryAction>,
    getState: () => TRootState,
  ): Promise<void> => {
    let rootState: TRootState = getState();
    let toEntityRecord: IToEntityRecord =
      rootState.summary.toEntities[
        eTagSummaryAttributeRequest.toEntity.to_entity
      ];

    if (toEntityRecord) {
      const recordKey: TETagRecordKey = getRecordKeyForETagIdentifier(
        eTagSummaryAttributeRequest,
      );

      dispatch(
        summaryRetrieveToEntityETagSummaryAttributeStart(
          eTagSummaryAttributeRequest,
        ),
      );

      try {
        const response: AxiosResponse<IETagSummaryAttributeResponse> =
          eTagSummaryAttributeRequest.draft_id === null
            ? await retrieveETagDistributedSummaryAttribute(
                toEntityRecord.toEntity.to_entity,
                eTagSummaryAttributeRequest.tag_primary_key,
                start,
                end,
              )
            : await retrieveETagDraftSummaryAttribute(
                toEntityRecord.toEntity.to_entity,
                eTagSummaryAttributeRequest.draft_id,
                start,
                end,
              );

        rootState = getState();
        toEntityRecord =
          rootState.summary.toEntities[
            eTagSummaryAttributeRequest.toEntity.to_entity
          ];

        const eTagSummaryAttributeRecord: IETagSummaryAttributeRecord =
          toEntityRecord.eTagsSummaryAttributeMap[recordKey];

        if (
          eTagSummaryAttributeRequest.requestedAt >=
          eTagSummaryAttributeRecord.eTagSummaryAttributeLastRequestedAt
        ) {
          const eTagSummaryAttributeResponse: IETagSummaryAttributeResponse =
            response.data;

          if (!isSuccessStatus(response.status)) {
            throw new Error(eTagSummaryAttributeResponse.errorMessage!);
          }

          dispatch(
            summaryRetrieveToEntityETagSummaryAttributeSuccess({
              toEntity: eTagSummaryAttributeRequest.toEntity,
              recordKey,
              summaryAttributeRecord: {
                eTagSummaryAttributeDataSet: eTagSummaryAttributeToDataSet(
                  eTagSummaryAttributeResponse.response,
                ),
                eTagSummaryAttributeError: null,
                eTagSummaryAttributeRetrieving:
                  ERetreiveState.RetrievingCompleted,
                eTagSummaryAttributeLastRequestedAt:
                  eTagSummaryAttributeRequest.requestedAt,
              },
              timeZone: start.timeZone(),
            }),
          );
        }
      } catch (error: any) {
        rootState = getState();
        toEntityRecord =
          rootState.summary.toEntities[
            eTagSummaryAttributeRequest.toEntity.to_entity
          ];

        const eTagSummaryAttributeRecord: IETagSummaryAttributeRecord =
          toEntityRecord.eTagsSummaryAttributeMap[recordKey];

        captureError(
          error,
          `Failed to retrieve ${
            eTagSummaryAttributeRequest.draft_id === null
              ? `E-Tag with tag_primary_key: ${eTagSummaryAttributeRequest.tag_primary_key}`
              : `Draft with draft_id: ${eTagSummaryAttributeRequest.draft_id}`
          }`,
        );

        dispatch(
          summaryRetrieveToEntityETagSummaryAttributeFailure({
            toEntity: eTagSummaryAttributeRequest.toEntity,
            recordKey,
            summaryAttributeRecord: {
              ...eTagSummaryAttributeRecord,
              eTagSummaryAttributeError: error,
              eTagSummaryAttributeRetrieving: ERetreiveState.NotRetrieving,
            },
            timeZone: start.timeZone(),
          }),
        );
      }
    }
  };
};

export const summaryRemoveToEntityETagSummaryAttribute = (
  eTagSummaryAttributeRequest: TETagSummaryAttributeRequest,
): TSummaryAction => ({
  payload: eTagSummaryAttributeRequest,
  type: ESummaryAction.RemoveToEntityETagSummaryAttribute,
});

export const summaryRemoveTenantETagSummaryAttribute = (
  eTagSummaryAttributeRequest: TETagCombinedSummaryAttributeRequest,
): TCombinedSummaryAction => ({
  payload: eTagSummaryAttributeRequest,
  type: ESummaryAction.RemoveTenantETagSummaryAttribute,
});

// ETags Attribute

export const summaryRetrieveToEntityETagsSummaryAttributeStart = (
  eTagsSummaryAttributeRequest: IETagsSummaryAttributeRequest,
): TSummaryAction => ({
  payload: eTagsSummaryAttributeRequest,
  type: ESummaryAction.RetrieveToEntityETagsSummaryAttributeStart,
});

export const summaryRetrieveToEntityETagsSummaryAttributeSuccess = (
  retrieveToEntityETagsSummaryAttributeReply: IRetrieveToEntityETagsSummaryAttributeReply,
): TSummaryAction => ({
  payload: retrieveToEntityETagsSummaryAttributeReply,
  type: ESummaryAction.RetrieveToEntityETagsSummaryAttributeSuccess,
});

export const summaryRetrieveTenantETagsSummaryAttributeStart = (
  eTagsSummaryAttributeRequest: ITenantETagsSummaryAttributeRequest,
): TCombinedSummaryAction => ({
  payload: eTagsSummaryAttributeRequest,
  type: ESummaryAction.RetrieveTenantETagsSummaryAttributeStart,
});

export const summaryRetrieveTenantETagsSummaryAttributeSuccess = (
  retrieveToEntityETagsSummaryAttributeReplies: IRetrieveTenantETagsSummaryAttributeReply,
): TCombinedSummaryAction => ({
  payload: retrieveToEntityETagsSummaryAttributeReplies,
  type: ESummaryAction.RetrieveTenantETagsSummaryAttributeSuccess,
});

export const summaryRetrieveToEntityETagsSummaryAttributeFailure = (
  retrieveToEntityETagsSummaryAttributeReply: IRetrieveToEntityETagsSummaryAttributeReply,
): TSummaryAction => ({
  payload: retrieveToEntityETagsSummaryAttributeReply,
  type: ESummaryAction.RetrieveToEntityETagsSummaryAttributeFailure,
});

export const summaryRetrieveTenantETagsSummaryAttributeFailure = (
  retrieveToEntityETagsSummaryAttributeReply: IRetrieveTenantETagsSummaryAttributeReply,
): TCombinedSummaryAction => ({
  payload: retrieveToEntityETagsSummaryAttributeReply,
  type: ESummaryAction.RetrieveTenantETagsSummaryAttributeFailure,
});

export const summaryRetrieveToEntitiesETagsSummaryAttribute = (
  toEntities: IToEntity[],
  start: ZonedDateTime,
  end: ZonedDateTime,
  batchSize?: number,
  filterId?: TFilterId,
  customFilters?: Map<string, any[]>,
  selectedCustomFilters?: any[],
): ThunkAction<void, TRootState, unknown, TCombinedSummaryAction> => {
  return async (
    dispatch: Redux.Dispatch<TCombinedSummaryAction>,
    getState: () => TRootState,
  ): Promise<void> => {
    let rootState: TRootState = getState();
    let eTagsSummaryAttributeMapCollection: TETagSummaryAttributeMap[] = [];
    let eTagsDraftTagPrimaryKeysCollection: Record<
      TETagTagPrimaryKey,
      TETagRecordKey
    >[] = [];
    let toEntitiesRecord: IToEntitiesRecord;
    if (toEntities) {
      let toEntityRecord: IToEntitiesRecord = {
        ...rootState.tenantSummary.toEntities['tenant'],
      };
      const eTagsSummaryAttributeRequest: ITenantETagsSummaryAttributeRequest =
        {
          requestedAt: ZonedDateTime.now(start.timeZone()),
          toEntities,
        };
      dispatch(
        summaryRetrieveTenantETagsSummaryAttributeStart(
          eTagsSummaryAttributeRequest,
        ),
      );
      toEntitiesRecord = toEntityRecord;
      const promises: Promise<any>[] = [];
      for (const toEntity of toEntities) {
        const filters = customFilters
          ? customFilters.get(toEntity.to_entity)?.map((customFilter) => {
              return {
                filter_name: customFilter.filter_name,
                filter_id: customFilter.filter_id,
              };
            })
          : null;

        const entityFilter = filters?.filter((e) =>
          selectedCustomFilters?.map((r) => r.label).includes(e.filter_name),
        );

        const entityFilterId =
          entityFilter && entityFilter.length > 0
            ? entityFilter[0].filter_id
            : undefined;

        rootState = getState();
        let toEntityRecord: IToEntitiesRecord = {
          ...rootState.tenantSummary.toEntities['tenant'],
        };

        try {
          if (selectedCustomFilters?.length === 0 || entityFilterId) {
            const retrieveEtagSummaryAttributePromise =
              retrieveEtagSummaryAttribute(
                toEntity,
                start,
                end,
                eTagsSummaryAttributeRequest,
                toEntityRecord.eTagsSummaryAttributeLastRequestedAt,
                batchSize,
                entityFilterId,
              );
            promises.push(retrieveEtagSummaryAttributePromise);
          }
        } catch (error: any) {
          captureError(error);

          const rootState: TRootState = getState();

          dispatch(
            summaryRetrieveTenantETagsSummaryAttributeFailure({
              toEntities,
              toEntitiesRecord: {
                ...rootState.tenantSummary.toEntities['tenant'],
                eTagsSummaryAttributeError: error,
                eTagsSummaryAttributeRetrieving: ERetreiveState.NotRetrieving,
              },
            }),
          );
        }
      }

      const responses = await Promise.all(promises);
      eTagsSummaryAttributeMapCollection = responses
        .slice()
        .map((res) => res.eTagsSummaryAttributeMap);
      eTagsDraftTagPrimaryKeysCollection = responses
        .slice()
        .map((res) => res.eTagsDraftTagPrimaryKeys);

      const newETagsSummaryAttributeMap: TETagSummaryAttributeMap = {};
      eTagsSummaryAttributeMapCollection.forEach((eTagsSummaryAttributeMap) => {
        for (const [key, value] of Object.entries(eTagsSummaryAttributeMap)) {
          newETagsSummaryAttributeMap[key] = value;
        }
      });

      const newETagsDraftTagPrimaryKeys: Record<
        TETagTagPrimaryKey,
        TETagRecordKey
      > = {};
      eTagsDraftTagPrimaryKeysCollection.forEach((eTagsDraftTagPrimaryKeys) => {
        for (const [key, value] of Object.entries(eTagsDraftTagPrimaryKeys)) {
          newETagsDraftTagPrimaryKeys[key] = value;
        }
      });

      toEntitiesRecord.eTagsSummaryAttributeMap = newETagsSummaryAttributeMap;
      toEntitiesRecord.eTagsDraftTagPrimaryKeys = newETagsDraftTagPrimaryKeys;
      toEntitiesRecord.eTagsSummaryAttributeRetrieving =
        ERetreiveState.RetrievingCompleted;

      dispatch(
        summaryRetrieveTenantETagsSummaryAttributeSuccess({
          toEntities,
          toEntitiesRecord,
        }),
      );
    }
  };
};

export const summaryRetrieveToEntityETagsSummaryAttribute = (
  toEntity: IToEntity,
  start: ZonedDateTime,
  end: ZonedDateTime,
  batchSize?: number,
  filterId?: TFilterId,
): ThunkAction<void, TRootState, unknown, TSummaryAction> => {
  return async (
    dispatch: Redux.Dispatch<TSummaryAction>,
    getState: () => TRootState,
  ): Promise<void> => {
    const eTagsSummaryAttributeRequest: IETagsSummaryAttributeRequest = {
      requestedAt: ZonedDateTime.now(start.timeZone()),
      toEntity,
    };
    dispatch(
      summaryRetrieveToEntityETagsSummaryAttributeStart(
        eTagsSummaryAttributeRequest,
      ),
    );

    try {
      let rootState: TRootState = getState();
      let toEntityRecord: IToEntityRecord = {
        ...rootState.summary.toEntities[toEntity.to_entity],
      };

      const { eTagsSummaryAttributeMap, eTagsDraftTagPrimaryKeys } =
        await retrieveEtagSummaryAttribute(
          toEntity,
          start,
          end,
          eTagsSummaryAttributeRequest,
          toEntityRecord.eTagsSummaryAttributeLastRequestedAt,
          batchSize,
          filterId,
        );

      toEntityRecord.eTagsSummaryAttributeMap = eTagsSummaryAttributeMap;
      toEntityRecord.eTagsDraftTagPrimaryKeys = eTagsDraftTagPrimaryKeys;

      toEntityRecord.eTagsSummaryAttributeRetrieving =
        ERetreiveState.RetrievingCompleted;

      dispatch(
        summaryRetrieveToEntityETagsSummaryAttributeSuccess({
          toEntity,
          toEntityRecord,
        }),
      );
    } catch (error: any) {
      captureError(error);

      const rootState: TRootState = getState();

      dispatch(
        summaryRetrieveToEntityETagsSummaryAttributeFailure({
          toEntity,
          toEntityRecord: {
            ...rootState.summary.toEntities[toEntity.to_entity],
            eTagsSummaryAttributeError: error,
            eTagsSummaryAttributeRetrieving: ERetreiveState.NotRetrieving,
          },
        }),
      );
    }
  };
};

// ETags Deal Linkage

export const summaryRetrieveToEntityETagsSummaryDealLinkageStart = (
  eTagsSummaryDealLinkageRequest: IETagsSummaryDealLinkageRequest,
): TSummaryAction => ({
  payload: eTagsSummaryDealLinkageRequest,
  type: ESummaryAction.RetrieveToEntityETagsSummaryDealLinkageStart,
});

export const summaryRetrieveToEntityETagsSummaryDealLinkageSuccess = (
  retrieveToEntityETagsSummaryDealLinkageReply: IRetrieveToEntityETagsSummaryDealLinkageReply,
): TSummaryAction => ({
  payload: retrieveToEntityETagsSummaryDealLinkageReply,
  type: ESummaryAction.RetrieveToEntityETagsSummaryDealLinkageSuccess,
});

export const summaryRetrieveToEntityETagsSummaryDealLinkageFailure = (
  retrieveToEntityETagsSummaryDealLinkageReply: IRetrieveToEntityETagsSummaryDealLinkageReply,
): TSummaryAction => ({
  payload: retrieveToEntityETagsSummaryDealLinkageReply,
  type: ESummaryAction.RetrieveToEntityETagsSummaryDealLinkageFailure,
});

// Deals
export const summaryRetrieveToEntityETagsSummaryDealLinkage = (
  toEntity: IToEntity,
  start: ZonedDateTime,
  end: ZonedDateTime,
  timeZone: TTimeZone,
): ThunkAction<void, TRootState, unknown, TSummaryAction> => {
  return async (
    dispatch: Redux.Dispatch<TSummaryAction>,
    getState: () => TRootState,
  ): Promise<void> => {
    const eTagsSummaryDealLinkageRequest: IETagsSummaryDealLinkageRequest = {
      requestedAt: ZonedDateTime.now(start.timeZone()),
      toEntity,
    };

    dispatch(
      summaryRetrieveToEntityETagsSummaryDealLinkageStart(
        eTagsSummaryDealLinkageRequest,
      ),
    );

    try {
      let rootState: TRootState = getState();
      let toEntityRecord: IToEntityRecord = {
        ...rootState.summary.toEntities[toEntity.to_entity],
      };

      if (
        eTagsSummaryDealLinkageRequest.requestedAt >=
        toEntityRecord.eTagsSummaryDealLinkageLastRequestedAt
      ) {
        const retrieveETagSummaryDealLinkageListResponse: AxiosResponse<IETagsSummaryDealLinkageResponse> =
          await retrieveETagSummaryDealLinkageList(
            toEntity.to_entity,
            start,
            end,
            timeZone,
          );
        const eTagsSummaryDealLinkageListResponse: IETagsSummaryDealLinkageResponse =
          retrieveETagSummaryDealLinkageListResponse.data;

        if (
          !isSuccessStatus(retrieveETagSummaryDealLinkageListResponse.status)
        ) {
          throw new Error(eTagsSummaryDealLinkageListResponse.errorMessage!);
        }

        const eTagsSummaryDealLinkageData: TETagSummaryDealLinkageRecord =
          eTagsSummaryDealLinkageListResponse.response;

        const updatedETagsSummaryDealLinkageMap: TETagSummaryDealLinkageMap =
          {};

        Object.keys(eTagsSummaryDealLinkageData).forEach(
          (recordKey: string): void => {
            const linkageRecords: IETagSummaryDealLinkageResponseRecord[] =
              eTagsSummaryDealLinkageData[recordKey];
            if (linkageRecords) {
              const linkageDataSets: IETagSummaryDealLinkageDataSet[] =
                linkageRecords.map(
                  (linkageRecord: IETagSummaryDealLinkageResponseRecord) =>
                    eTagSummaryDealLinkageToDataSet(linkageRecord),
                );
              updatedETagsSummaryDealLinkageMap[recordKey] = {
                eTagSummaryDealLinkageDataSets: linkageDataSets,
                eTagSummaryDealLinkageError: null,
                eTagSummaryDealLinkageRetrieving:
                  ERetreiveState.RetrievingCompleted,
                eTagSummaryDealLinkageLastRequestedAt:
                  eTagsSummaryDealLinkageRequest.requestedAt,
              };
            }
          },
        );

        rootState = getState();
        toEntityRecord = {
          ...rootState.summary.toEntities[toEntity.to_entity],
        };
        toEntityRecord.eTagsSummaryDealLinkageMap =
          updatedETagsSummaryDealLinkageMap;

        toEntityRecord.eTagsSummaryDealLinkageRetrieving =
          ERetreiveState.RetrievingCompleted;

        dispatch(
          summaryRetrieveToEntityETagsSummaryDealLinkageSuccess({
            toEntity,
            toEntityRecord,
          }),
        );
      }
    } catch (error: any) {
      captureError(error);

      const rootState: TRootState = getState();

      dispatch(
        summaryRetrieveToEntityETagsSummaryDealLinkageFailure({
          toEntity,
          toEntityRecord: {
            ...rootState.summary.toEntities[toEntity.to_entity],
            eTagsSummaryDealLinkageError: error,
            eTagsSummaryDealLinkageRetrieving: ERetreiveState.NotRetrieving,
          },
        }),
      );
    }
  };
};

// ETag Profiles

export const summaryRetrieveToEntityETagSummaryProfilesStart = (
  eTagSummaryProfileRequest: TETagSummaryProfilesRequest,
): TSummaryAction => ({
  payload: eTagSummaryProfileRequest,
  type: ESummaryAction.RetrieveToEntityETagSummaryProfilesStart,
});

export const summaryRetrieveToEntityETagSummaryProfilesSuccess = (
  eTagSummaryProfileResponse: IETagSummaryProfilesReply,
): TSummaryAction => ({
  payload: eTagSummaryProfileResponse,
  type: ESummaryAction.RetrieveToEntityETagSummaryProfilesSuccess,
});

export const summaryRetrieveToEntityETagSummaryProfilesFailure = (
  eTagSummaryProfileResponse: IETagSummaryProfilesReply,
): TSummaryAction => ({
  payload: eTagSummaryProfileResponse,
  type: ESummaryAction.RetrieveToEntityETagSummaryProfilesFailure,
});

export const summaryRetrieveToEntityETagSummaryProfiles = (
  eTagSummaryProfilesRequest: TETagSummaryProfilesRequest,
  timeZone: TTimeZone,
  start: ZonedDateTime,
  end: ZonedDateTime,
  profileSegment: EProfileSegment,
): ThunkAction<void, TRootState, unknown, TSummaryAction> => {
  return async (
    dispatch: ThunkDispatch<TRootState, unknown, TSummaryAction>,
    getState: () => TRootState,
  ): Promise<void> => {
    let rootState: TRootState = getState();
    let toEntityRecord: IToEntityRecord =
      rootState.summary.toEntities[
        eTagSummaryProfilesRequest.toEntity.to_entity
      ];

    if (toEntityRecord) {
      const recordKey: TETagRecordKey = getRecordKeyForETagIdentifier(
        eTagSummaryProfilesRequest,
      );
      let eTagProfileRecord: IETagSummaryProfilesRecord | undefined =
        toEntityRecord.eTagsSummaryProfilesMap[recordKey];

      dispatch(
        summaryRetrieveToEntityETagSummaryProfilesStart(
          eTagSummaryProfilesRequest,
        ),
      );

      try {
        const response: AxiosResponse<IETagSummaryProfilesResponse> =
          eTagSummaryProfilesRequest.draft_id === null
            ? await retrieveETagDistributedSummaryProfiles(
                toEntityRecord.toEntity.to_entity,
                eTagSummaryProfilesRequest.tag_primary_key,
                timeZone,
                start,
                end,
                profileSegment,
              )
            : await retrieveETagDraftSummaryProfiles(
                toEntityRecord.toEntity.to_entity,
                eTagSummaryProfilesRequest.draft_id,
                timeZone,
                start,
                end,
                profileSegment,
              );

        rootState = getState();
        toEntityRecord =
          rootState.summary.toEntities[
            eTagSummaryProfilesRequest.toEntity.to_entity
          ];

        eTagProfileRecord = toEntityRecord.eTagsSummaryProfilesMap[recordKey];

        if (eTagProfileRecord === undefined) {
          throw new Error(
            `Missing eTagProfileRecord for recordKey: ${recordKey}`,
          );
        } else if (
          eTagProfileRecord.eTagSummaryProfilesLastRequestedAt === undefined ||
          eTagSummaryProfilesRequest.requestedAt >=
            eTagProfileRecord.eTagSummaryProfilesLastRequestedAt
        ) {
          const eTagSummaryProfilesResponse: IETagSummaryProfilesResponse =
            response.data;

          if (!isSuccessStatus(response.status)) {
            throw new Error(eTagSummaryProfilesResponse.errorMessage!);
          }

          dispatch(
            summaryRetrieveToEntityETagSummaryProfilesSuccess({
              toEntity: eTagSummaryProfilesRequest.toEntity,
              recordKey,
              summaryProfileRecord: {
                eTagSummaryProfiles: transformRetrievedETagSummaryProfiles(
                  eTagSummaryProfilesResponse.response,
                  eTagProfileRecord.eTagSummaryProfiles,
                  timeZone,
                ),
                eTagSummaryProfilesError: null,
                eTagSummaryProfilesRetrieving:
                  ERetreiveState.RetrievingCompleted,
                eTagSummaryProfilesLastRequestedAt:
                  eTagSummaryProfilesRequest.requestedAt,
              },
            }),
          );
        }
      } catch (error: any) {
        rootState = getState();
        toEntityRecord =
          rootState.summary.toEntities[
            eTagSummaryProfilesRequest.toEntity.to_entity
          ];

        const eTagProfileRecord: IETagSummaryProfilesRecord | undefined =
          toEntityRecord.eTagsSummaryProfilesMap[recordKey];

        captureError(
          error,
          `Failed to retrieve Summary Profiles for ${
            eTagSummaryProfilesRequest.draft_id === null
              ? `tag_primary_key: ${eTagSummaryProfilesRequest.tag_primary_key}`
              : `draft_id: ${eTagSummaryProfilesRequest.draft_id}`
          }`,
        );

        dispatch(
          summaryRetrieveToEntityETagSummaryProfilesFailure({
            toEntity: eTagSummaryProfilesRequest.toEntity,
            recordKey,
            summaryProfileRecord:
              eTagProfileRecord === undefined
                ? {
                    eTagSummaryProfiles: [],
                    eTagSummaryProfilesError: error,
                    eTagSummaryProfilesRetrieving: ERetreiveState.NotRetrieving,
                  }
                : {
                    ...eTagProfileRecord,
                    eTagSummaryProfilesError: error,
                    eTagSummaryProfilesRetrieving: ERetreiveState.NotRetrieving,
                  },
          }),
        );
      }
    }
  };
};

export const summaryRemoveToEntityETagSummaryProfiles = (
  eTagSummaryProfilesRequest: TETagSummaryProfilesRequest,
): TSummaryAction => ({
  payload: eTagSummaryProfilesRequest,
  type: ESummaryAction.RemoveToEntityETagSummaryProfiles,
});

export const summaryRemoveTenantETagSummaryProfiles = (
  eTagSummaryProfilesRequest: TTenantETagSummaryProfilesRequest,
): TCombinedSummaryAction => ({
  payload: eTagSummaryProfilesRequest,
  type: ESummaryAction.RemoveTenantETagSummaryProfiles,
});

// ETag Profiles Batch

export const summaryRetrieveToEntityETagSummaryProfilesBatchStart = (
  eTagSummaryProfilesBatchRequest: IETagSummaryProfilesBatchRequest,
): TSummaryAction => ({
  payload: eTagSummaryProfilesBatchRequest,
  type: ESummaryAction.RetrieveToEntityETagSummaryProfilesBatchStart,
});

export const summaryRetrieveToEntityETagSummaryProfilesBatchSuccess = (
  eTagSummaryProfileBatchResponse: IETagSummaryProfilesBatchReply,
): TSummaryAction => ({
  payload: eTagSummaryProfileBatchResponse,
  type: ESummaryAction.RetrieveToEntityETagSummaryProfilesBatchSuccess,
});

export const summaryRetrieveToEntityETagSummaryProfilesBatchFailure = (
  eTagSummaryProfileBatchResponse: IETagSummaryProfilesBatchReply,
): TSummaryAction => ({
  payload: eTagSummaryProfileBatchResponse,
  type: ESummaryAction.RetrieveToEntityETagSummaryProfilesBatchFailure,
});

export const summaryRetrieveTenantETagSummaryProfilesBatchStart = (
  eTagSummaryProfilesBatchRequest: ITenantETagSummaryProfilesBatchRequest,
): TCombinedSummaryAction => ({
  payload: eTagSummaryProfilesBatchRequest,
  type: ESummaryAction.RetrieveTenantETagSummaryProfilesBatchStart,
});

export const summaryRetrieveTenantETagSummaryProfilesBatchSuccess = (
  eTagSummaryProfileBatchResponse: ITenantETagSummaryProfilesBatchReply,
): TCombinedSummaryAction => ({
  payload: eTagSummaryProfileBatchResponse,
  type: ESummaryAction.RetrieveTenantETagSummaryProfilesBatchSuccess,
});

export const summaryRetrieveTenantETagSummaryProfilesBatchFailure = (
  eTagSummaryProfileBatchResponse: ITenantETagSummaryProfilesBatchReply,
): TCombinedSummaryAction => ({
  payload: eTagSummaryProfileBatchResponse,
  type: ESummaryAction.RetrieveTenantETagSummaryProfilesBatchFailure,
});

export const summaryRetrieveTenantETagSummaryProfilesBatch = (
  eTagSummaryProfilesBatchRequest: ITenantETagSummaryProfilesBatchRequest,
  timeZone: TTimeZone,
  start: ZonedDateTime,
  end: ZonedDateTime,
  profileSegment: EProfileSegment,
): ThunkAction<void, TRootState, unknown, TCombinedSummaryAction> => {
  return async (
    dispatch: ThunkDispatch<TRootState, unknown, TCombinedSummaryAction>,
    getState: () => TRootState,
  ): Promise<void> => {
    const { eTagIdentifiers, requestedAt, toEntities } =
      eTagSummaryProfilesBatchRequest;
    let rootState: TRootState = getState();
    let toEntityRecord: IToEntitiesRecord =
      rootState.tenantSummary.toEntities['tenant'];

    if (toEntityRecord === undefined) {
      throw new Error(`Missing toEntityRecord for toEntityId: `);
    }

    if (eTagIdentifiers.length > 0) {
      dispatch(
        summaryRetrieveTenantETagSummaryProfilesBatchStart(
          eTagSummaryProfilesBatchRequest,
        ),
      );

      const tenantEtagSummaryProfilesBatchResponse: IETagSummaryProfilesBatchResponse =
        {
          errorMessage: undefined,
          response: { erroredIds: [], notFoundIds: [], summaryProfiles: {} },
        };
      let status = 0;

      const promises: Promise<
        AxiosResponse<IETagSummaryProfilesBatchResponse>
      >[] = [];

      try {
        for (const entity of toEntities) {
          const entityETagIdentifiers = eTagIdentifiers
            .slice()
            .filter((id: any) => id.to_entity === entity.to_entity);
          promises.push(
            retrieveETagSummaryProfilesBatch(
              entity.to_entity,
              eTagIdentifiersToIdList(entityETagIdentifiers),
              timeZone,
              start,
              end,
              profileSegment,
            ),
          );
        }

        const responses = await Promise.all(promises);
        responses.forEach((response) => {
          status = response.status;
          tenantEtagSummaryProfilesBatchResponse.response.erroredIds =
            tenantEtagSummaryProfilesBatchResponse.response.erroredIds.concat(
              response.data.response.erroredIds,
            );
          tenantEtagSummaryProfilesBatchResponse.response.notFoundIds =
            tenantEtagSummaryProfilesBatchResponse.response.notFoundIds.concat(
              response.data.response.notFoundIds,
            );
          for (const [key, value] of Object.entries(
            response.data.response.summaryProfiles,
          )) {
            tenantEtagSummaryProfilesBatchResponse.response.summaryProfiles[
              key
            ] = value;
          }
        });

        rootState = getState();
        toEntityRecord = rootState.tenantSummary.toEntities['tenant'];

        if (toEntityRecord === undefined) {
          throw new Error(`Missing toEntityRecord for toEntityId: `);
        } else {
          // Only need to grab the first record of a batch to see when it was
          // last requested
          const eTagSummaryProfilesRecord:
            | IETagSummaryProfilesRecord
            | undefined =
            toEntityRecord.eTagsSummaryProfilesMap[
              getRecordKeyForETagIdentifier(eTagIdentifiers[0])
            ];

          if (
            eTagSummaryProfilesRecord === undefined ||
            eTagSummaryProfilesRecord.eTagSummaryProfilesLastRequestedAt ===
              undefined ||
            requestedAt >=
              eTagSummaryProfilesRecord.eTagSummaryProfilesLastRequestedAt
          ) {
            const eTagSummaryProfilesBatchResponse: IETagSummaryProfilesBatchResponse =
              tenantEtagSummaryProfilesBatchResponse;

            if (!isSuccessStatus(status)) {
              throw new Error(eTagSummaryProfilesBatchResponse.errorMessage!);
            }

            const eTagSummaryProfilesMap: TETagSummaryProfilesMap = {};
            const eTagSummaryProfilesBatch: IETagSummaryProfilesBatch =
              eTagSummaryProfilesBatchResponse.response;
            const { erroredIds, notFoundIds, summaryProfiles } =
              eTagSummaryProfilesBatch;

            Object.keys(summaryProfiles).forEach((id: string) => {
              const eTagIdentifier: IETagIdentifier = idToETagIdentifier(id);
              const recordKey: TETagRecordKey =
                getRecordKeyForETagIdentifier(eTagIdentifier);
              const eTagProfileRecord: IETagSummaryProfilesRecord | undefined =
                toEntityRecord!.eTagsSummaryProfilesMap[recordKey];
              const eTagSummaryProfiles: IETagSummaryProfile[] =
                summaryProfiles[id];

              eTagSummaryProfilesMap[recordKey] = {
                ...eTagProfileRecord,
                eTagSummaryProfiles: transformRetrievedETagSummaryProfiles(
                  eTagSummaryProfiles,
                  eTagProfileRecord === undefined
                    ? []
                    : eTagProfileRecord.eTagSummaryProfiles,
                  timeZone,
                ),
                eTagSummaryProfilesError: null,
                eTagSummaryProfilesLastRequestedAt: requestedAt,
                eTagSummaryProfilesRetrieving:
                  ERetreiveState.RetrievingCompleted,
              };
            });

            notFoundIds.forEach((id: string) => {
              const eTagIdentifier: IETagIdentifier = idToETagIdentifier(id);
              const recordKey: TETagRecordKey =
                getRecordKeyForETagIdentifier(eTagIdentifier);
              const eTagProfileRecord: IETagSummaryProfilesRecord | undefined =
                toEntityRecord!.eTagsSummaryProfilesMap[recordKey];

              eTagSummaryProfilesMap[recordKey] = {
                ...eTagProfileRecord,
                eTagSummaryProfiles: [],
                eTagSummaryProfilesError: 'Not Found',
                eTagSummaryProfilesLastRequestedAt: requestedAt,
                eTagSummaryProfilesRetrieving: ERetreiveState.NotRetrieving,
              };

              captureError(
                new Error(
                  `Summary profiles not found for ${
                    eTagIdentifier.draft_id === null
                      ? `tag_primary_key: ${eTagIdentifier.tag_primary_key}`
                      : `draft_id: ${eTagIdentifier.draft_id}`
                  }`,
                ),
              );
            });

            erroredIds.forEach((id: string) => {
              const eTagIdentifier: IETagIdentifier = idToETagIdentifier(id);
              const recordKey: TETagRecordKey =
                getRecordKeyForETagIdentifier(eTagIdentifier);
              const eTagProfileRecord: IETagSummaryProfilesRecord | undefined =
                toEntityRecord!.eTagsSummaryProfilesMap[recordKey];

              eTagSummaryProfilesMap[recordKey] = {
                ...eTagProfileRecord,
                eTagSummaryProfiles: [],
                eTagSummaryProfilesError: 'Errored',
                eTagSummaryProfilesLastRequestedAt: requestedAt,
                eTagSummaryProfilesRetrieving: ERetreiveState.NotRetrieving,
              };

              captureError(
                new Error(
                  `Failed to load summary profiles for ${
                    eTagIdentifier.draft_id === null
                      ? `tag_primary_key: ${eTagIdentifier.tag_primary_key}`
                      : `draft_id: ${eTagIdentifier.draft_id}`
                  }`,
                ),
              );
            });

            dispatch(
              summaryRetrieveTenantETagSummaryProfilesBatchSuccess({
                eTagSummaryProfilesMap,
                toEntities,
              }),
            );
          }
        }
      } catch (error: any) {
        captureError(
          error,
          `Failed to retrieve summary profiles batch: ${JSON.stringify(
            eTagSummaryProfilesBatchRequest,
          )}`,
        );

        rootState = getState();
        toEntityRecord = rootState.tenantSummary.toEntities['tenant'];

        if (toEntityRecord !== undefined) {
          const eTagSummaryProfilesMap: TETagSummaryProfilesMap = {};

          eTagIdentifiers.forEach((eTagIdentifier: IETagIdentifier) => {
            const recordKey: TETagRecordKey =
              getRecordKeyForETagIdentifier(eTagIdentifier);
            const eTagProfileRecord: IETagSummaryProfilesRecord | undefined =
              toEntityRecord!.eTagsSummaryProfilesMap[recordKey];

            eTagSummaryProfilesMap[recordKey] =
              eTagProfileRecord === undefined
                ? {
                    eTagSummaryProfiles: [],
                    eTagSummaryProfilesError: error,
                    eTagSummaryProfilesRetrieving: ERetreiveState.NotRetrieving,
                  }
                : {
                    ...eTagProfileRecord,
                    eTagSummaryProfilesError: error,
                    eTagSummaryProfilesRetrieving: ERetreiveState.NotRetrieving,
                  };
          });

          dispatch(
            summaryRetrieveTenantETagSummaryProfilesBatchFailure({
              error,
              eTagSummaryProfilesMap,
              toEntities,
            }),
          );
        }
      }
    }
  };
};

export const summaryRetrieveToEntityETagSummaryProfilesBatch = (
  eTagSummaryProfilesBatchRequest: IETagSummaryProfilesBatchRequest,
  timeZone: TTimeZone,
  start: ZonedDateTime,
  end: ZonedDateTime,
  profileSegment: EProfileSegment,
): ThunkAction<void, TRootState, unknown, TSummaryAction> => {
  return async (
    dispatch: ThunkDispatch<TRootState, unknown, TSummaryAction>,
    getState: () => TRootState,
  ): Promise<void> => {
    const { eTagIdentifiers, requestedAt, toEntity } =
      eTagSummaryProfilesBatchRequest;
    let rootState: TRootState = getState();
    let toEntityRecord: IToEntityRecord | undefined =
      rootState.summary.toEntities[toEntity.to_entity];

    if (toEntityRecord === undefined) {
      throw new Error(
        `Missing toEntityRecord for toEntityId: ${toEntity.to_entity}`,
      );
    }

    if (eTagIdentifiers.length > 0) {
      dispatch(
        summaryRetrieveToEntityETagSummaryProfilesBatchStart(
          eTagSummaryProfilesBatchRequest,
        ),
      );

      try {
        const retrieveETagSummaryProfilesBatchResponse: AxiosResponse<IETagSummaryProfilesBatchResponse> =
          await retrieveETagSummaryProfilesBatch(
            toEntity.to_entity,
            eTagIdentifiersToIdList(eTagIdentifiers),
            timeZone,
            start,
            end,
            profileSegment,
          );

        rootState = getState();
        toEntityRecord = rootState.summary.toEntities[toEntity.to_entity];

        if (toEntityRecord === undefined) {
          throw new Error(
            `Missing toEntityRecord for toEntityId: ${toEntity.to_entity}`,
          );
        } else {
          // Only need to grab the first record of a batch to see when it was
          // last requested
          const eTagSummaryProfilesRecord:
            | IETagSummaryProfilesRecord
            | undefined =
            toEntityRecord.eTagsSummaryProfilesMap[
              getRecordKeyForETagIdentifier(eTagIdentifiers[0])
            ];

          if (
            eTagSummaryProfilesRecord === undefined ||
            eTagSummaryProfilesRecord.eTagSummaryProfilesLastRequestedAt ===
              undefined ||
            requestedAt >=
              eTagSummaryProfilesRecord.eTagSummaryProfilesLastRequestedAt
          ) {
            const eTagSummaryProfilesBatchResponse: IETagSummaryProfilesBatchResponse =
              retrieveETagSummaryProfilesBatchResponse.data;

            if (
              !isSuccessStatus(retrieveETagSummaryProfilesBatchResponse.status)
            ) {
              throw new Error(eTagSummaryProfilesBatchResponse.errorMessage!);
            }

            const eTagSummaryProfilesMap: TETagSummaryProfilesMap = {};
            const eTagSummaryProfilesBatch: IETagSummaryProfilesBatch =
              eTagSummaryProfilesBatchResponse.response;
            const { erroredIds, notFoundIds, summaryProfiles } =
              eTagSummaryProfilesBatch;

            Object.keys(summaryProfiles).forEach((id: string) => {
              const eTagIdentifier: IETagIdentifier = idToETagIdentifier(id);
              const recordKey: TETagRecordKey =
                getRecordKeyForETagIdentifier(eTagIdentifier);
              const eTagProfileRecord: IETagSummaryProfilesRecord | undefined =
                toEntityRecord!.eTagsSummaryProfilesMap[recordKey];
              const eTagSummaryProfiles: IETagSummaryProfile[] =
                summaryProfiles[id];

              eTagSummaryProfilesMap[recordKey] = {
                ...eTagProfileRecord,
                eTagSummaryProfiles: transformRetrievedETagSummaryProfiles(
                  eTagSummaryProfiles,
                  eTagProfileRecord === undefined
                    ? []
                    : eTagProfileRecord.eTagSummaryProfiles,
                  timeZone,
                ),
                eTagSummaryProfilesError: null,
                eTagSummaryProfilesLastRequestedAt: requestedAt,
                eTagSummaryProfilesRetrieving:
                  ERetreiveState.RetrievingCompleted,
              };
            });

            notFoundIds.forEach((id: string) => {
              const eTagIdentifier: IETagIdentifier = idToETagIdentifier(id);
              const recordKey: TETagRecordKey =
                getRecordKeyForETagIdentifier(eTagIdentifier);
              const eTagProfileRecord: IETagSummaryProfilesRecord | undefined =
                toEntityRecord!.eTagsSummaryProfilesMap[recordKey];

              eTagSummaryProfilesMap[recordKey] = {
                ...eTagProfileRecord,
                eTagSummaryProfiles: [],
                eTagSummaryProfilesError: 'Not Found',
                eTagSummaryProfilesLastRequestedAt: requestedAt,
                eTagSummaryProfilesRetrieving: ERetreiveState.NotRetrieving,
              };

              captureError(
                new Error(
                  `Summary profiles not found for ${
                    eTagIdentifier.draft_id === null
                      ? `tag_primary_key: ${eTagIdentifier.tag_primary_key}`
                      : `draft_id: ${eTagIdentifier.draft_id}`
                  }`,
                ),
              );
            });

            erroredIds.forEach((id: string) => {
              const eTagIdentifier: IETagIdentifier = idToETagIdentifier(id);
              const recordKey: TETagRecordKey =
                getRecordKeyForETagIdentifier(eTagIdentifier);
              const eTagProfileRecord: IETagSummaryProfilesRecord | undefined =
                toEntityRecord!.eTagsSummaryProfilesMap[recordKey];

              eTagSummaryProfilesMap[recordKey] = {
                ...eTagProfileRecord,
                eTagSummaryProfiles: [],
                eTagSummaryProfilesError: 'Errored',
                eTagSummaryProfilesLastRequestedAt: requestedAt,
                eTagSummaryProfilesRetrieving: ERetreiveState.NotRetrieving,
              };

              captureError(
                new Error(
                  `Failed to load summary profiles for ${
                    eTagIdentifier.draft_id === null
                      ? `tag_primary_key: ${eTagIdentifier.tag_primary_key}`
                      : `draft_id: ${eTagIdentifier.draft_id}`
                  }`,
                ),
              );
            });

            dispatch(
              summaryRetrieveToEntityETagSummaryProfilesBatchSuccess({
                eTagSummaryProfilesMap,
                toEntity,
              }),
            );
          }
        }
      } catch (error: any) {
        captureError(
          error,
          `Failed to retrieve summary profiles batch: ${JSON.stringify(
            eTagSummaryProfilesBatchRequest,
          )}`,
        );

        rootState = getState();
        toEntityRecord = rootState.summary.toEntities[toEntity.to_entity];

        if (toEntityRecord !== undefined) {
          const eTagSummaryProfilesMap: TETagSummaryProfilesMap = {};

          eTagIdentifiers.forEach((eTagIdentifier: IETagIdentifier) => {
            const recordKey: TETagRecordKey =
              getRecordKeyForETagIdentifier(eTagIdentifier);
            const eTagProfileRecord: IETagSummaryProfilesRecord | undefined =
              toEntityRecord!.eTagsSummaryProfilesMap[recordKey];

            eTagSummaryProfilesMap[recordKey] =
              eTagProfileRecord === undefined
                ? {
                    eTagSummaryProfiles: [],
                    eTagSummaryProfilesError: error,
                    eTagSummaryProfilesRetrieving: ERetreiveState.NotRetrieving,
                  }
                : {
                    ...eTagProfileRecord,
                    eTagSummaryProfilesError: error,
                    eTagSummaryProfilesRetrieving: ERetreiveState.NotRetrieving,
                  };
          });

          dispatch(
            summaryRetrieveToEntityETagSummaryProfilesBatchFailure({
              error,
              eTagSummaryProfilesMap,
              toEntity,
            }),
          );
        }
      }
    }
  };
};

// ETags Profiles

export const summaryRetrieveToEntityETagsSummaryProfilesStart = (
  eTagsSummaryProfilesRequest: IETagsSummaryProfilesRequest,
): TSummaryAction => ({
  payload: eTagsSummaryProfilesRequest,
  type: ESummaryAction.RetrieveToEntityETagsSummaryProfilesStart,
});

export const summaryRetrieveToEntityETagsSummaryProfilesSuccess = (
  summaryActionPayload: ISummaryActionPayload,
): TSummaryAction => ({
  payload: summaryActionPayload,
  type: ESummaryAction.RetrieveToEntityETagsSummaryProfilesSuccess,
});

export const summaryRetrieveToEntityETagsSummaryProfilesFailure = (
  eTagsProfilesError: IRetrieveToEntityETagsSummaryProfilesError,
): TSummaryAction => ({
  payload: eTagsProfilesError,
  type: ESummaryAction.RetrieveToEntityETagsSummaryProfilesFailure,
});

export const summaryRetrieveTenantETagsSummaryProfilesStart = (
  eTagsSummaryProfilesRequest: ITenantETagsSummaryProfilesRequest,
): TCombinedSummaryAction => ({
  payload: eTagsSummaryProfilesRequest,
  type: ESummaryAction.RetrieveTenantETagsSummaryProfilesStart,
});

export const summaryRetrieveTenantETagsSummaryProfilesSuccess = (
  summaryActionPayload: ICombinedSummaryActionPayload,
): TCombinedSummaryAction => ({
  payload: summaryActionPayload,
  type: ESummaryAction.RetrieveTenantETagsSummaryProfilesSuccess,
});

export const summaryRetrieveTenantETagsSummaryProfilesFailure = (
  eTagsProfilesError: IRetrieveTenantETagsSummaryProfilesError,
): TCombinedSummaryAction => ({
  payload: eTagsProfilesError,
  type: ESummaryAction.RetrieveTenantETagsSummaryProfilesFailure,
});

export const summaryRetrieveTenantETagsSummaryProfiles = (
  toEntities: IToEntity[],
  timeZone: TTimeZone,
  start: ZonedDateTime,
  end: ZonedDateTime,
  profileSegment: EProfileSegment,
  batchSize?: number,
): ThunkAction<void, TRootState, unknown, TCombinedSummaryAction> => {
  return async (
    dispatch: ThunkDispatch<TRootState, unknown, TCombinedSummaryAction>,
    getState: () => TRootState,
  ): Promise<void> => {
    const rootState: TRootState = getState();
    const toEntityRecord: IToEntitiesRecord =
      rootState.tenantSummary.toEntities['tenant'];

    if (
      toEntityRecord !== undefined &&
      (toEntityRecord.eTagsSummaryProfilesRetrieving ===
        ERetreiveState.NotRetrieving ||
        toEntityRecord.eTagsSummaryProfilesRetrieving ===
          ERetreiveState.RetrievingCompleted)
    ) {
      const eTagsSummaryProfilesRequest: ITenantETagsSummaryProfilesRequest = {
        toEntities,
        loadCount: Object.keys(toEntityRecord.eTagsSummaryAttributeMap).length,
      };

      dispatch(
        summaryRetrieveTenantETagsSummaryProfilesStart(
          eTagsSummaryProfilesRequest,
        ),
      );

      try {
        const eTagsSummaryProfilesReply: ITenantETagsSummaryProfilesReply = {
          toEntities,
        };

        const eTagSummaryAttributeRecords: IETagSummaryAttributeRecord[] =
          Object.values(toEntityRecord.eTagsSummaryAttributeMap).filter(
            (
              eTagSummaryAttributeRecord: IETagSummaryAttributeRecord,
            ): boolean =>
              eTagSummaryAttributeRecord.eTagSummaryAttributeDataSet !==
              undefined,
          );

        if (eTagSummaryAttributeRecords.length === 0) {
          eTagsSummaryProfilesReply.isCompleted = true;
        } else {
          const eTagSummaryAttributeDataSets: IETagSummaryAttributeDataSet[] =
            eTagSummaryAttributeRecords.map(
              (
                eTagSummaryAttributeRecord: IETagSummaryAttributeRecord,
              ): IETagSummaryAttributeDataSet =>
                eTagSummaryAttributeRecord.eTagSummaryAttributeDataSet!,
            );
          const loadBatchSize: number =
            batchSize === undefined
              ? DEFAULT_SUMMARY_PROFILES_BATCH_LOAD_SIZE
              : batchSize;
          const numBatches: number = Math.ceil(
            eTagSummaryAttributeDataSets.length / loadBatchSize,
          );

          for (let i: number = 0; i < numBatches; i += 1) {
            const eTagIdentifiers: IETagIdentifier[] = [];

            for (let j: number = 0; j < loadBatchSize; j += 1) {
              const eTagIdentifiersIndex: number = i * loadBatchSize + j;

              if (eTagIdentifiersIndex < eTagSummaryAttributeDataSets.length) {
                eTagIdentifiers.push(
                  eTagSummaryAttributeDataSets[eTagIdentifiersIndex],
                );
              } else {
                break;
              }
            }

            // Filter the entities used in the profile batch calls
            // to the ones that actually have tags in the dataset
            const validEntities = toEntities.filter((entity) =>
              Array.from(
                new Set(
                  eTagIdentifiers.map((id: any) => id.to_entity),
                ).values(),
              ).includes(entity.to_entity),
            );

            const summaryProfilesBatchRequest: ITenantETagSummaryProfilesBatchRequest =
              {
                eTagIdentifiers,
                requestedAt: ZonedDateTime.now(timeZone),
                toEntities: validEntities,
              };

            dispatch(
              summaryRetrieveTenantETagSummaryProfilesBatch(
                summaryProfilesBatchRequest,
                timeZone,
                start,
                end,
                profileSegment,
              ),
            );
          }
        }

        dispatch(
          summaryRetrieveTenantETagsSummaryProfilesSuccess(
            eTagsSummaryProfilesReply,
          ),
        );
      } catch (error: any) {
        captureError(error);

        const eTagsProfilesError: IRetrieveTenantETagsSummaryProfilesError = {
          toEntities,
          error,
        };

        dispatch(
          summaryRetrieveTenantETagsSummaryProfilesFailure(eTagsProfilesError),
        );
      }
    }
  };
};

export const summaryRetrieveToEntityETagsSummaryProfiles = (
  toEntity: IToEntity,
  timeZone: TTimeZone,
  start: ZonedDateTime,
  end: ZonedDateTime,
  profileSegment: EProfileSegment,
  batchSize?: number,
): ThunkAction<void, TRootState, unknown, TSummaryAction> => {
  return async (
    dispatch: ThunkDispatch<TRootState, unknown, TSummaryAction>,
    getState: () => TRootState,
  ): Promise<void> => {
    const rootState: TRootState = getState();
    const toEntityRecord: IToEntityRecord =
      rootState.summary.toEntities[toEntity.to_entity];

    if (toEntityRecord !== undefined) {
      const eTagsSummaryProfilesRequest: IETagsSummaryProfilesRequest = {
        toEntity,
        loadCount: Object.keys(toEntityRecord.eTagsSummaryAttributeMap).length,
      };

      dispatch(
        summaryRetrieveToEntityETagsSummaryProfilesStart(
          eTagsSummaryProfilesRequest,
        ),
      );

      try {
        const eTagsSummaryProfilesReply: IETagsSummaryProfilesReply = {
          toEntity,
        };

        const eTagSummaryAttributeRecords: IETagSummaryAttributeRecord[] =
          Object.values(toEntityRecord.eTagsSummaryAttributeMap).filter(
            (
              eTagSummaryAttributeRecord: IETagSummaryAttributeRecord,
            ): boolean =>
              eTagSummaryAttributeRecord.eTagSummaryAttributeDataSet !==
              undefined,
          );

        if (eTagSummaryAttributeRecords.length === 0) {
          eTagsSummaryProfilesReply.isCompleted = true;
        } else {
          const eTagSummaryAttributeDataSets: IETagSummaryAttributeDataSet[] =
            eTagSummaryAttributeRecords.map(
              (
                eTagSummaryAttributeRecord: IETagSummaryAttributeRecord,
              ): IETagSummaryAttributeDataSet =>
                eTagSummaryAttributeRecord.eTagSummaryAttributeDataSet!,
            );
          const loadBatchSize: number =
            batchSize === undefined
              ? DEFAULT_SUMMARY_PROFILES_BATCH_LOAD_SIZE
              : batchSize;
          const numBatches: number = Math.ceil(
            eTagSummaryAttributeDataSets.length / loadBatchSize,
          );

          for (let i: number = 0; i < numBatches; i += 1) {
            const eTagIdentifiers: IETagIdentifier[] = [];

            for (let j: number = 0; j < loadBatchSize; j += 1) {
              const eTagIdentifiersIndex: number = i * loadBatchSize + j;

              if (eTagIdentifiersIndex < eTagSummaryAttributeDataSets.length) {
                eTagIdentifiers.push(
                  eTagSummaryAttributeDataSets[eTagIdentifiersIndex],
                );
              } else {
                break;
              }
            }

            const summaryProfilesBatchRequest: IETagSummaryProfilesBatchRequest =
              {
                eTagIdentifiers,
                requestedAt: ZonedDateTime.now(timeZone),
                toEntity,
              };

            dispatch(
              summaryRetrieveToEntityETagSummaryProfilesBatch(
                summaryProfilesBatchRequest,
                timeZone,
                start,
                end,
                profileSegment,
              ),
            );
          }
        }

        dispatch(
          summaryRetrieveToEntityETagsSummaryProfilesSuccess(
            eTagsSummaryProfilesReply,
          ),
        );
      } catch (error: any) {
        captureError(error);

        const eTagsProfilesError: IRetrieveToEntityETagsSummaryProfilesError = {
          toEntity,
          error,
        };

        dispatch(
          summaryRetrieveToEntityETagsSummaryProfilesFailure(
            eTagsProfilesError,
          ),
        );
      }
    }
  };
};

// ETag

export const summaryUpdateToEntityETags = (
  updateToEntityETagsRequest: IUpdateToEntityETagsRequest,
): TSummaryAction => ({
  payload: updateToEntityETagsRequest,
  type: ESummaryAction.UpdateToEntityETags,
});

export const summaryUpdateToEntityETagDrafts = (
  updateToEntityETagDraftsRequest: IUpdateToEntityETagDraftsRequest,
): TSummaryAction => ({
  payload: updateToEntityETagDraftsRequest,
  type: ESummaryAction.UpdateToEntityETagDrafts,
});

export const summaryUpdateTenantETags = (
  updateTenantETagsRequest: IUpdateTenantETagsRequest,
): TCombinedSummaryAction => ({
  payload: updateTenantETagsRequest,
  type: ESummaryAction.UpdateTenantETags,
});

export const summaryUpdateTenantETagDrafts = (
  updateTenantETagDraftsRequest: IUpdateTenantETagDraftsRequest,
): TCombinedSummaryAction => ({
  payload: updateTenantETagDraftsRequest,
  type: ESummaryAction.UpdateTenantETagDrafts,
});
