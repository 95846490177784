import { DRAGGABLE_TYPE } from 'components/molecules/ReorderableList/DraggableListRow/constants';
import { IIndexedDraggable } from 'components/molecules/ReorderableList/DraggableListRow/types';
import { HIGHLIGHT_BLUE, LIST_ROW_SHARED_STYLES } from 'constants/styles';
import { IThemedProps } from 'interfaces/Component';
import { HTMLAttributes, MutableRefObject, useRef } from 'react';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import {
  DragSourceMonitor,
  DropTargetMonitor,
  useDrag,
  useDrop,
} from 'react-dnd';
import styled from 'styled-components';
import { hoverBackground } from 'utils/styles';

interface IListRowProps extends HTMLAttributes<HTMLLIElement>, IThemedProps {
  isBefore?: boolean;
  isOver?: boolean;
}

const ListRow = styled.li<IListRowProps>`
  ${LIST_ROW_SHARED_STYLES}

  align-items: center;
  cursor: move;
  display: flex;
  flex-direction: row;

  :hover {
    ${(props) => hoverBackground(props)}
  }

  ${(props) =>
    props.isOver
      ? props.isBefore
        ? `border-bottom: 2px dashed ${HIGHLIGHT_BLUE};`
        : `border-top: 2px dashed ${HIGHLIGHT_BLUE};`
      : ''}
`;

interface IDragableListRowProps {
  className?: string;
  content: JSX.Element;
  index: number;
  moveRow: (dragIndex: number, hoverIndex: number) => void;
}

const DragableListRow = ({
  className,
  content,
  index,
  moveRow,
}: IDragableListRowProps) => {
  const { currentTheme } = useThemeSwitcher();
  const liRef = useRef<HTMLLIElement>() as MutableRefObject<HTMLLIElement>;
  const [{ isBefore, isOver }, drop] = useDrop({
    accept: DRAGGABLE_TYPE,
    collect: (monitor: DropTargetMonitor) => {
      const { index: dragIndex } = monitor.getItem<IIndexedDraggable>() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isBefore: dragIndex < index,
        isOver: monitor.isOver(),
      };
    },
    drop: (item: IIndexedDraggable) => {
      moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    item: { index },
    collect: (monitor: DragSourceMonitor) => ({
      isDragging: monitor.isDragging(),
    }),
    type: DRAGGABLE_TYPE,
  });

  drop(drag(liRef));

  return (
    <ListRow
      className={className}
      currentTheme={currentTheme!}
      isBefore={isBefore}
      isOver={isOver}
      ref={liRef}
    >
      {content}
    </ListRow>
  );
};

export default DragableListRow;
