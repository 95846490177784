import Select, { ISelectProps } from 'components/molecules/Select/Select';
import Tooltip from 'components/molecules/Tooltip/Tooltip';
import { STANDARD_SPACING } from 'constants/styles';
import { COLUMN_DATA_MAP } from 'constants/Summary';
import { EColumnPosition } from 'enums/ETag';
import { ESortDirection } from 'enums/Sort';
import { IOption, IThemedProps } from 'interfaces/Component';
import { IETagColumnData } from 'interfaces/ETag';
import {
  IColumnConfiguration,
  IColumnSort,
  IEditableColumnData,
} from 'interfaces/Summary';
import { useEffect, useState } from 'react';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import styled from 'styled-components';
import { textDisabledColour } from 'utils/styles';

const Container = styled.span`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Actions = styled.span`
  display: flex;
  flex-direction: row;

  > :not(:last-child) {
    margin-right: 4px;
  }
`;

const Label = styled.div<IThemedProps>`
  margin-right: ${STANDARD_SPACING};

  ${(props) => (props.disabled ? `color: ${textDisabledColour(props)};` : '')};
`;

// Specialize the Select components
const StyleIndexSelect = styled((props: ISelectProps<string | undefined>) =>
  Select<string | undefined>(props),
)`
  width: 173px;
`;

const ColumnPositionSelect = styled(
  (props: ISelectProps<EColumnPosition | undefined>) =>
    Select<EColumnPosition | undefined>(props),
)`
  width: 79px;
`;

const SortDirectionSelect = styled(
  (props: ISelectProps<ESortDirection | undefined>) =>
    Select<ESortDirection | undefined>(props),
)`
  width: 151px;
`;

const SortOrderSelect = styled((props: ISelectProps<number>) =>
  Select<number>(props),
)`
  width: 91px;
`;

// Generate Select options
const styleIndexOptions: IOption<string | undefined>[] = Object.values(
  COLUMN_DATA_MAP,
).map((value: IETagColumnData | undefined) => ({
  label: value!.displayName,
  value: value!.dataIndex,
}));

const columnPositionOptions: IOption<EColumnPosition | undefined>[] = [
  { label: 'Left', value: EColumnPosition.Left },
  { label: 'Right', value: EColumnPosition.Right },
];

const sortDirectionOptions: IOption<ESortDirection | undefined>[] = [
  { label: 'Ascending Sort', value: ESortDirection.Ascending },
  { label: 'Descending Sort', value: ESortDirection.Descending },
  { label: 'Disable Column Sort', value: ESortDirection.Disabled },
];

const reducedSortDirectionOptions: IOption<ESortDirection | undefined>[] = [
  { label: 'Disable Column Sort', value: ESortDirection.Disabled },
];

interface IProps {
  defaultSort?: Record<string, IColumnSort>;
  dimmedLabel?: boolean;
  editableColumnData: IEditableColumnData;
  isDisabled?: boolean;
  onColumnConfigurationChange: (
    columnConfiguration: IColumnConfiguration,
  ) => void;
  onColumnSortChange: (dataIndex: string, columnSort?: IColumnSort) => void;
}

const ToEntityColumnConfiguration = (props: IProps): JSX.Element => {
  const { currentTheme } = useThemeSwitcher();
  const {
    defaultSort,
    dimmedLabel,
    editableColumnData,
    isDisabled,
    onColumnConfigurationChange,
    onColumnSortChange,
  } = props;
  const [columnConfiguration, setColumnConfiguration] =
    useState<IColumnConfiguration>({
      dataIndex: editableColumnData.dataIndex,
      fixed: editableColumnData.fixed,
      styleIndex: editableColumnData.styleIndex,
    });
  const [columnSort, setColumnSort] = useState<IColumnSort | undefined>();
  const [sortOrderOptions, setSortOrderOptions] = useState<IOption<number>[]>(
    [],
  );

  useEffect(() => {
    setColumnConfiguration({
      dataIndex: editableColumnData.dataIndex,
      fixed: editableColumnData.fixed,
      styleIndex: editableColumnData.styleIndex,
    });
  }, [editableColumnData]);

  useEffect(() => {
    let maxSortOrder: number = 0;

    if (defaultSort === undefined) {
      setColumnSort(undefined);
    } else {
      setColumnSort(defaultSort[editableColumnData.dataIndex]);

      Object.values(defaultSort).forEach((columnSort: IColumnSort) => {
        if (
          columnSort.sortDirection !== ESortDirection.Disabled &&
          columnSort.sortOrder! > maxSortOrder
        ) {
          maxSortOrder = columnSort.sortOrder!;
        }
      });
    }

    // We use 1 indexing for human readability
    const updatedSortOrderOptions: IOption<number>[] = [];
    for (let i: number = 1; i <= maxSortOrder; i += 1) {
      updatedSortOrderOptions.push({
        label: i.toString(),
        value: i,
      });
    }

    setSortOrderOptions(updatedSortOrderOptions);
  }, [defaultSort, editableColumnData.dataIndex]);

  const handleStyleIndexSelect = (styleIndex: string | undefined) => {
    const updatedColumnConfiguration: IColumnConfiguration = {
      ...columnConfiguration,
      styleIndex,
    };

    setColumnConfiguration(updatedColumnConfiguration);

    onColumnConfigurationChange(updatedColumnConfiguration);
  };

  const handleColumnPositionSelect = (
    columnPosition: EColumnPosition | undefined,
  ) => {
    const updatedColumnConfiguration: IColumnConfiguration = {
      ...columnConfiguration,
      fixed: columnPosition,
    };

    setColumnConfiguration(updatedColumnConfiguration);

    onColumnConfigurationChange(updatedColumnConfiguration);
  };

  const handleSortDirectionSelect = (
    sortDirection: ESortDirection | undefined,
  ) => {
    let updatedColumnSort: IColumnSort | undefined;

    if (sortDirection === undefined) {
      updatedColumnSort = undefined;
    } else {
      updatedColumnSort = { sortDirection };

      if (columnSort === undefined) {
        updatedColumnSort.sortOrder = sortOrderOptions.length + 1;
      } else if (
        columnSort.sortDirection === ESortDirection.Disabled &&
        updatedColumnSort.sortDirection !== ESortDirection.Disabled
      ) {
        updatedColumnSort.sortOrder = sortOrderOptions.length + 1;
      } else if (
        !(
          columnSort.sortDirection !== ESortDirection.Disabled &&
          updatedColumnSort.sortDirection === ESortDirection.Disabled
        )
      ) {
        updatedColumnSort.sortOrder = columnSort.sortOrder;
      }
    }

    setColumnSort(updatedColumnSort);

    onColumnSortChange(columnConfiguration.dataIndex, updatedColumnSort);
  };

  const handleSortOrderSelect = (sortOrder: number | undefined) => {
    if (columnSort !== undefined && sortOrder !== undefined) {
      const updatedColumnSort: IColumnSort = { ...columnSort, sortOrder };

      setColumnSort(updatedColumnSort);

      onColumnSortChange(columnConfiguration.dataIndex, updatedColumnSort);
    }
  };

  return (
    <Container>
      <Label currentTheme={currentTheme!} disabled={dimmedLabel}>
        {editableColumnData.displayName}
      </Label>
      <Actions>
        <Tooltip title={`Use Another Column's Styling Information`}>
          <StyleIndexSelect
            allowClear={true}
            isDisabled={isDisabled}
            onChange={handleStyleIndexSelect}
            options={styleIndexOptions}
            placeholder='Style Index'
            value={columnConfiguration.styleIndex}
            valueToUid={(value: string | undefined): string =>
              value === undefined ? '' : value
            }
          />
        </Tooltip>
        <Tooltip title='Fix a Column to the Left or Right'>
          <ColumnPositionSelect
            allowClear={true}
            isDisabled={isDisabled}
            onChange={handleColumnPositionSelect}
            options={columnPositionOptions}
            placeholder='Fixture'
            value={columnConfiguration.fixed}
            valueToUid={(value: EColumnPosition | undefined): string =>
              value === undefined ? '' : (value as string)
            }
          />
        </Tooltip>
        <Tooltip title={`A Column's Default Sort Direction`}>
          <SortDirectionSelect
            allowClear={true}
            isDisabled={isDisabled}
            onChange={handleSortDirectionSelect}
            options={
              editableColumnData.expandTo === undefined
                ? sortDirectionOptions
                : reducedSortDirectionOptions
            }
            placeholder='Sort Direction'
            value={columnSort?.sortDirection}
            valueToUid={(value: ESortDirection | undefined): string =>
              value === undefined ? '' : (value as string)
            }
          />
        </Tooltip>
        <Tooltip title='Sorting Priority Relative to Other Columns'>
          <SortOrderSelect
            isDisabled={
              isDisabled ||
              columnSort === undefined ||
              columnSort.sortDirection === ESortDirection.Disabled
            }
            onChange={handleSortOrderSelect}
            options={sortOrderOptions}
            placeholder='Sort Order'
            value={
              columnSort?.sortDirection === ESortDirection.Disabled
                ? undefined
                : columnSort?.sortOrder
            }
            valueToUid={(value: number): string => value.toString()}
          />
        </Tooltip>
      </Actions>
    </Container>
  );
};

export default ToEntityColumnConfiguration;
