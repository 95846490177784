import Button from 'components/atoms/Button/Button';
import Checkbox from 'components/atoms/Checkbox/Checkbox';
import ErrorMessage from 'components/atoms/ErrorMessage/ErrorMessage';
import SeparatedRowLayout from 'components/atoms/SeparatedRowLayout/SeparatedRowLayout';
import { StyledLabel } from 'components/organisms/ToEntitySummaryUiConfiguration/styledComponents';
import { EActionState } from 'enums/General';
import { IToEntity } from 'interfaces/ToEntity';
import { useEffect, useState } from 'react';
import { TErrorMessage } from 'types/Error';
import { isEmptyValue } from 'utils/general';

interface ISaveButtonProps {
  actionState: EActionState;
  errorMessage: TErrorMessage;
  onSave: () => void;
  saveableToEntities: IToEntity[];
  setToEntitiesForSaving: (toEntities: IToEntity[]) => void;
  toEntity?: IToEntity;
}

export const SaveButton = (props: ISaveButtonProps): JSX.Element => {
  const {
    actionState,
    errorMessage,
    onSave,
    saveableToEntities,
    setToEntitiesForSaving,
    toEntity,
  } = props;

  const [saveToAll, setSaveToAll] = useState<boolean>(false);

  useEffect(() => {
    if (saveToAll) {
      setToEntitiesForSaving(saveableToEntities);
    } else {
      setToEntitiesForSaving(toEntity ? [toEntity] : []);
    }
  }, [saveToAll, saveableToEntities, setToEntitiesForSaving, toEntity]);

  return (
    <>
      <StyledLabel>Save all summary options:</StyledLabel>
      <SeparatedRowLayout>
        <Button
          actionState={actionState}
          label='Save configuration'
          onClick={onSave}
        />
        {saveableToEntities.length > 1 && toEntity && (
          <Checkbox
            checked={saveToAll}
            onChange={(checked: boolean) => setSaveToAll(checked)}
            label={`Also save to ${saveableToEntities
              .filter((te: IToEntity) => te.to_entity !== toEntity.to_entity)
              .map((te: IToEntity) => te.entity_code)
              .join(', ')}
                `}
          />
        )}
      </SeparatedRowLayout>
      {!isEmptyValue(errorMessage) && (
        <ErrorMessage>{errorMessage}</ErrorMessage>
      )}
    </>
  );
};
