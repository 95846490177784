import { Tag as AntDesignTag } from 'antd';
import {
  CURRENT_PENDING_REQUEST_KEY,
  CURRENT_REQUEST_KEY,
} from 'constants/Detail';
import { INPUT_HEIGHT, STANDARD_SPACING_VALUE } from 'constants/styles';
import styled from 'styled-components';

const StyledDesignTag = styled(AntDesignTag)`
  height: ${INPUT_HEIGHT};
  padding: ${STANDARD_SPACING_VALUE / 2}px;
`;

interface IRequestIndicatorProps {
  requestKey: string | undefined;
}

const RequestIndicator = ({
  requestKey,
}: IRequestIndicatorProps): JSX.Element => {
  return (
    <StyledDesignTag
      color={
        requestKey === CURRENT_REQUEST_KEY
          ? 'green'
          : requestKey === CURRENT_PENDING_REQUEST_KEY
          ? 'orange'
          : 'blue'
      }
    >
      Request ID:{' '}
      {requestKey === CURRENT_REQUEST_KEY
        ? 'Current Level'
        : requestKey === CURRENT_PENDING_REQUEST_KEY
        ? 'Current Pending Level'
        : requestKey}
    </StyledDesignTag>
  );
};

export default RequestIndicator;
