import { AxiosResponse } from 'axios';
import { ENTITY_TYPE_TO_REGISTRY_TYPE_MAP } from 'constants/Entity';
import { ERegistryType, ERetreiveState } from 'enums/General';
import {
  IGetTenantToEntitiesResponse,
  ITenantToEntities,
  IToEntityUiConfiguration,
  IUserUiConfiguration,
} from 'interfaces/Config';
import { IEntityIdentifierInfo, IRegistryEntity } from 'interfaces/Entity';
import {
  IToEntity,
  IUserInfoResponse,
  IUserInfoToEntity,
} from 'interfaces/ToEntity';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import {
  getToEntityUiConfigurationFor,
  getUserUiConfigurationFor,
} from 'reduxes/User/helpers';
import {
  EUserAction,
  ISetTenantDefaultTimeZoneRequest,
  ISetTenantSelectedToEntitiesRequest,
  ISetTenantSelectedToEntityIdRequest,
  ISetTenantSelectedToTimeZoneRequest,
  ITenantUserConfigsFailureReply,
  IToEntityUiConfigFailureReply,
  IToEntityUiConfigRequest,
  IToEntityUiConfigSuccessReply,
  IUserContactInfo,
  IUserInfoFailureReply,
  IUserInfoRequest,
  IUserInfoSuccessReply,
  IUserSelectedTimeZone,
  IUserSelectedToEntity,
  TTenantUSerAction,
  TUserAction,
} from 'reduxes/User/types';
import { retrieveUserInfo } from 'services/auth/userInfo';
import {
  retrieveTenantUserConfigs,
  retrieveToEntitiesForPCISupport,
  retrieveToEntityUiConfig,
  retrieveUserUiConfig,
} from 'services/configclient/config';
import { retrieveRegistryEntitiesByRegistryType } from 'services/naesb-registry/registry';
import { TRootState } from 'types/Redux';
import { TToEntityId } from 'types/ToEntity';
import { parseToEntityId } from 'utils/entity';
import { captureError } from 'utils/error';
import { isSuccessStatus } from 'utils/general';

export const userRetrieveUserInfoSuccess = (
  userInfoSuccessReply: IUserInfoSuccessReply,
): TUserAction => ({
  payload: userInfoSuccessReply,
  type: EUserAction.RetrieveUserInfoSuccess,
});

interface IRetrieveRegistryEntitiesByTypeParams {
  registryType: ERegistryType;
  taggingEntityIds: number[];
  toEntityId: TToEntityId;
}

const findCode = (
  entityIdentifierInfo: IEntityIdentifierInfo,
  responses: AxiosResponse<IRegistryEntity[]>[],
): string | undefined => {
  const { entity_type, tagging_entity_id } = entityIdentifierInfo;

  for (let response of responses) {
    for (let registryEntity of response.data) {
      const registryType = ENTITY_TYPE_TO_REGISTRY_TYPE_MAP[entity_type];
      if (
        registryEntity.TaggingEntityID === String(tagging_entity_id) &&
        registryEntity['Registry Type'] === registryType
      ) {
        return registryEntity.Code;
      }
    }
  }
  return undefined;
};

const groupIntoRegistryParams = (
  userInfoToEntities: IUserInfoToEntity[],
): IRetrieveRegistryEntitiesByTypeParams[] => {
  // group the IUserInfoToEntity records by registry type, accruing all
  // tagging entity ids so we can get more than one id from a single api call
  const registryTypeTaggingEntityIdsMapping: Partial<
    Record<ERegistryType, IRetrieveRegistryEntitiesByTypeParams>
  > = {};

  userInfoToEntities.forEach((toEntity: IUserInfoToEntity) => {
    const { to_entity } = toEntity;
    const { entity_type, tagging_entity_id } = parseToEntityId(to_entity);
    const registryType: ERegistryType =
      ENTITY_TYPE_TO_REGISTRY_TYPE_MAP[entity_type];
    const params: IRetrieveRegistryEntitiesByTypeParams | undefined =
      registryTypeTaggingEntityIdsMapping[registryType];

    if (params === undefined) {
      registryTypeTaggingEntityIdsMapping[registryType] = {
        registryType: registryType,
        toEntityId: to_entity,
        taggingEntityIds: [tagging_entity_id],
      };
    } else {
      params.taggingEntityIds.push(tagging_entity_id);
    }
  });

  const returnValue: IRetrieveRegistryEntitiesByTypeParams[] = [];

  for (const params of Object.values(registryTypeTaggingEntityIdsMapping)) {
    if (params !== undefined) {
      returnValue.push(params);
    }
  }

  return returnValue;
};

const groupTenantToEntitiesIntoRegistryParams = (
  tenantToEntities: ITenantToEntities[],
): IRetrieveRegistryEntitiesByTypeParams[] => {
  // group the IUserInfoToEntity records by registry type, accruing all
  // tagging entity ids so we can get more than one id from a single api call
  const registryTypeTaggingEntityIdsMapping: Partial<
    Record<ERegistryType, IRetrieveRegistryEntitiesByTypeParams>
  > = {};

  tenantToEntities.forEach((datum: ITenantToEntities) => {
    datum.to_entities.forEach((toEntityId: TToEntityId) => {
      const { entity_type, tagging_entity_id } = parseToEntityId(toEntityId);
      const registryType: ERegistryType =
        ENTITY_TYPE_TO_REGISTRY_TYPE_MAP[entity_type];
      const params: IRetrieveRegistryEntitiesByTypeParams | undefined =
        registryTypeTaggingEntityIdsMapping[registryType];

      if (params === undefined) {
        registryTypeTaggingEntityIdsMapping[registryType] = {
          registryType: registryType,
          toEntityId: toEntityId,
          taggingEntityIds: [tagging_entity_id],
        };
      } else {
        params.taggingEntityIds.push(tagging_entity_id);
      }
    });
  });

  const returnValue: IRetrieveRegistryEntitiesByTypeParams[] = [];

  for (const params of Object.values(registryTypeTaggingEntityIdsMapping)) {
    if (params !== undefined) {
      returnValue.push(params);
    }
  }

  return returnValue;
};

const retrieveAllRegistryEntities = async (
  paramsArr: IRetrieveRegistryEntitiesByTypeParams[],
): Promise<AxiosResponse<IRegistryEntity[]>[]> => {
  const promises: Promise<AxiosResponse<IRegistryEntity[]>>[] = [];

  paramsArr.forEach(
    (params: IRetrieveRegistryEntitiesByTypeParams | undefined) => {
      if (params !== undefined) {
        let { registryType, taggingEntityIds, toEntityId } = params;
        promises.push(
          retrieveRegistryEntitiesByRegistryType(
            toEntityId,
            registryType,
            taggingEntityIds,
          ),
        );
      }
    },
  );

  return Promise.all(promises);
};

export const userRetrieveUserInfo = (): ThunkAction<
  void,
  TRootState,
  unknown,
  TUserAction
> => {
  const userInfoRequest: IUserInfoRequest = {};

  return async (
    dispatch: ThunkDispatch<TRootState, unknown, TUserAction>,
    getState: () => TRootState,
  ): Promise<void> => {
    const {
      user: { toEntities },
    } = getState();
    if (
      toEntities !== null &&
      toEntities !== undefined &&
      toEntities.length > 0
    ) {
      // already loaded. no need to reload. user info does not change, so it is safe to cache
      return;
    }

    dispatch(userRetrieveUserInfoStart(userInfoRequest));

    try {
      const response: AxiosResponse<IUserInfoResponse> =
        await retrieveUserInfo();
      const userInfoResponse: IUserInfoResponse = response.data;

      if (!isSuccessStatus(response.status)) {
        throw new Error(
          'Retrieve User Info failed with status ' + response.status,
        );
      }

      const userInfoToEntities: IUserInfoToEntity[] = userInfoResponse.response;

      // we consider the empty list an error state
      // this is a condition that usually means the token is not
      // valid, so retrying will not help, it will only create
      // an infinite loop of retries
      if (userInfoToEntities.length === 0) {
        throw new Error('Retrieve user info found no entities');
      }
      // if this condition is satisfied, we are logged in with a PCI Support user
      if (
        userInfoToEntities.length === 1 &&
        userInfoToEntities[0].to_entity === 'PCI'
      ) {
        const response: AxiosResponse<IGetTenantToEntitiesResponse> =
          await retrieveToEntitiesForPCISupport();
        const toEntitiesResponse: IGetTenantToEntitiesResponse = response.data;

        if (!isSuccessStatus(response.status)) {
          throw new Error(
            'Retrieve PCI support tenant toEntities failed with status ' +
              response.status,
          );
        }
        const tenantToEntities: ITenantToEntities[] =
          toEntitiesResponse.response;
        const paramsArr =
          groupTenantToEntitiesIntoRegistryParams(tenantToEntities);

        const responses = await retrieveAllRegistryEntities(paramsArr);
        // first check if a user has logged in and chosen to entities already
        // doing this, we can force the user to log out to switch tenants
        const storedToEntitiesJSON: string | null =
          localStorage.getItem('pciSupportToEntities') ?? '[]';
        const storedToEntities: string[] =
          JSON.parse(storedToEntitiesJSON) ?? [];
        if (storedToEntities.length > 0) {
          const toEntities: IToEntity[] = [];
          storedToEntities.forEach((toEntityId: TToEntityId) => {
            const entityIdentifierInfo: IEntityIdentifierInfo =
              parseToEntityId(toEntityId);
            // fall back to the entity id if the code is not found
            const code =
              findCode(entityIdentifierInfo, responses) ?? toEntityId;
            const entity: IToEntity = {
              entity_type: entityIdentifierInfo.entity_type,
              tagging_entity_id: entityIdentifierInfo.tagging_entity_id,
              to_entity_type_role_abbreviation:
                userInfoToEntities[0].to_entity_type_role_abbreviation,
              entity_code: code,
              to_entity: toEntityId,
            };
            toEntities.push(entity);
          });

          dispatch(
            userRetrieveUserInfoSuccess({
              toEntities: toEntities,
              tenantToEntities: [],
            }),
          );
        } else {
          const toEntities: IToEntity[] = [];
          tenantToEntities.forEach((toEntity: ITenantToEntities) => {
            toEntity.to_entities.forEach((toEntityId: TToEntityId) => {
              const entityIdentifierInfo: IEntityIdentifierInfo =
                parseToEntityId(toEntityId);
              // fall back to the entity id if the code is not found
              const code =
                findCode(entityIdentifierInfo, responses) ?? toEntityId;
              const entity: IToEntity = {
                entity_type: entityIdentifierInfo.entity_type,
                tagging_entity_id: entityIdentifierInfo.tagging_entity_id,
                to_entity_type_role_abbreviation:
                  userInfoToEntities[0].to_entity_type_role_abbreviation,
                entity_code: code,
                to_entity: toEntityId,
              };
              toEntities.push(entity);
            });
          });

          dispatch(
            userRetrieveUserInfoSuccess({
              toEntities: toEntities,
              tenantToEntities: tenantToEntities,
            }),
          );
        }
      } else {
        const paramsArr = groupIntoRegistryParams(userInfoToEntities);
        const responses = await retrieveAllRegistryEntities(paramsArr);
        const toEntities: IToEntity[] = userInfoToEntities.map(
          (toEntity: IUserInfoToEntity): IToEntity => {
            const entityIdentifierInfo: IEntityIdentifierInfo = parseToEntityId(
              toEntity.to_entity,
            );
            // fall back to the entity id if the code is not found
            const code =
              findCode(entityIdentifierInfo, responses) ?? toEntity.to_entity;
            return {
              ...toEntity,
              ...entityIdentifierInfo,
              entity_code: code,
            };
          },
        );

        dispatch(
          userRetrieveUserInfoSuccess({
            toEntities: toEntities,
            tenantToEntities: [],
          }),
        );
      }
    } catch (error: any) {
      captureError(error);

      dispatch(
        userRetrieveUserInfoFailure({
          errorMessage:
            'An error occurred loading user info. Please try again later.',
        }),
      );
    }
  };
};

export const setToEntitiesAndRemoveOthers = (
  userInfoToEntities: IUserInfoToEntity[],
) => {
  return async (
    dispatch: ThunkDispatch<TRootState, unknown, TUserAction>,
  ): Promise<void> => {
    const paramsArr = groupIntoRegistryParams(userInfoToEntities);
    const responses = await retrieveAllRegistryEntities(paramsArr);
    const toEntities: IToEntity[] = userInfoToEntities.map(
      (toEntity: IUserInfoToEntity): IToEntity => {
        const entityIdentifierInfo: IEntityIdentifierInfo = parseToEntityId(
          toEntity.to_entity,
        );
        // fall back to the entity id if the code is not found
        const code =
          findCode(entityIdentifierInfo, responses) ?? toEntity.to_entity;
        return {
          ...toEntity,
          ...entityIdentifierInfo,
          entity_code: code,
        };
      },
    );
    dispatch(
      userRetrieveUserInfoSuccess({
        toEntities,
        tenantToEntities: [],
      }),
    );
  };
};

export const userRetrieveUserInfoStart = (
  userInfoRequest: IUserInfoRequest,
): TUserAction => ({
  payload: userInfoRequest,
  type: EUserAction.RetrieveUserInfoStart,
});

export const userRetrieveUserInfoFailure = (
  userInfoFailureReply: IUserInfoFailureReply,
): TUserAction => ({
  payload: userInfoFailureReply,
  type: EUserAction.RetrieveUserInfoFailure,
});

export const userRetrieveTenantUserConfigs = (): ThunkAction<
  void,
  TRootState,
  unknown,
  TTenantUSerAction
> => {
  return async (
    dispatch: ThunkDispatch<TRootState, unknown, TTenantUSerAction>,
    getState: () => TRootState,
  ): Promise<void> => {
    const {
      tenantUser: { retrievingTenantConfigs },
    } = getState();

    if (
      retrievingTenantConfigs === ERetreiveState.RetrievingStarted ||
      retrievingTenantConfigs === ERetreiveState.RetrievingCompleted
    ) {
      return;
    }

    dispatch(userRetrieveTenantUserConfigsStart());

    try {
      const [retrieveTenantUserConfigsResponse] = await Promise.all([
        retrieveTenantUserConfigs(),
      ]);

      const tenantUserConfigsResponse = retrieveTenantUserConfigsResponse.data;

      dispatch(userRetrieveTenantUserConfigsSuccess(tenantUserConfigsResponse));
    } catch (error: any) {
      captureError(error);

      dispatch(
        userRetrieveTenantUserConfigsFailure({
          errorMessage: `An error occurred loading Tenants UI configurations. Please try again later.`,
        }),
      );
    }
  };
};

export const userRetrieveToEntityUiConfig = (
  toEntityId: TToEntityId,
): ThunkAction<void, TRootState, unknown, TUserAction> => {
  const toEntityUiConfigRequest: IToEntityUiConfigRequest = {
    toEntityId,
  };

  return async (
    dispatch: ThunkDispatch<TRootState, unknown, TUserAction>,
    getState: () => TRootState,
  ): Promise<void> => {
    const {
      user: { toEntityUserStates },
    } = getState();
    const userStateForToEntity = toEntityUserStates[toEntityId];
    if (
      userStateForToEntity?.retrieving === ERetreiveState.RetrievingStarted ||
      userStateForToEntity?.retrieving === ERetreiveState.RetrievingCompleted
    ) {
      // already loaded or started loading for this to-entity
      // ui config info does not change often, so it is safe to cache
      // app reload will force loading of to entity ui config
      return;
    }

    dispatch(userRetrieveToEntityUiConfigStart(toEntityUiConfigRequest));

    try {
      const [toEntityUiConfigurationResponse, userUiConfigurationResponse] =
        await Promise.all([
          retrieveToEntityUiConfig(toEntityId),
          retrieveUserUiConfig(toEntityId),
        ]);

      const toEntityUiConfiguration: IToEntityUiConfiguration =
        getToEntityUiConfigurationFor(
          toEntityUiConfigurationResponse,
          toEntityId,
        );

      const userUiConfiguration: IUserUiConfiguration =
        getUserUiConfigurationFor(userUiConfigurationResponse, toEntityId);

      dispatch(
        userRetrieveToEntityUiConfigSuccess({
          toEntityId,
          toEntityUiConfiguration,
          userUiConfiguration,
        }),
      );
    } catch (error: any) {
      captureError(error);

      dispatch(
        userRetrieveToEntityUiConfigFailure({
          toEntityId: toEntityId,
          errorMessage: `An error occurred loading ToEntity Ui Configuration for ${toEntityId}. Please try again later.`,
        }),
      );
    }
  };
};

export const userRetrieveToEntityUiConfigStart = (
  toEntityUiConfigRequest: IToEntityUiConfigRequest,
): TUserAction => ({
  payload: toEntityUiConfigRequest,
  type: EUserAction.RetrieveToEntityUiConfigStart,
});

export const userRetrieveTenantUserConfigsStart = (): TTenantUSerAction => ({
  payload: {},
  type: EUserAction.RetrieveTenantUserConfigsStart,
});

export const userRetrieveToEntityUiConfigSuccess = (
  toEntityUiConfigSuccessReply: IToEntityUiConfigSuccessReply,
): TUserAction => ({
  payload: toEntityUiConfigSuccessReply,
  type: EUserAction.RetrieveToEntityUiConfigSuccess,
});

export const userRetrieveTenantUserConfigsSuccess = (
  tenantUserConfig: any,
): TTenantUSerAction => ({
  payload: tenantUserConfig,
  type: EUserAction.RetrieveTenantUserConfigsSuccess,
});

export const userRetrieveToEntityUiConfigFailure = (
  toEntityUiConfigFailureReply: IToEntityUiConfigFailureReply,
): TUserAction => ({
  payload: toEntityUiConfigFailureReply,
  type: EUserAction.RetrieveToEntityUiConfigFailure,
});

export const userRetrieveTenantUserConfigsFailure = (
  tenantUserConfigsFailureReply: ITenantUserConfigsFailureReply,
): TTenantUSerAction => ({
  payload: tenantUserConfigsFailureReply,
  type: EUserAction.RetrieveTenantUserConfigsFailure,
});

export const userSetSelectedTimeZone = (
  userSelectedTimeZone: IUserSelectedTimeZone,
): TUserAction => ({
  payload: userSelectedTimeZone,
  type: EUserAction.SetSelectedTimeZone,
});

export const userSetSelectedToEntity = (
  userSelectedToEntity: IUserSelectedToEntity,
): TUserAction => ({
  payload: userSelectedToEntity,
  type: EUserAction.SetSelectedToEntity,
});

export const userSetContactInfo = (
  contactInfo: IUserContactInfo,
): TUserAction => ({
  payload: contactInfo,
  type: EUserAction.SetContactInfo,
});

export const setTenantSelectedToEntityId = (
  setTenantSelectedToEntityIdRequest: ISetTenantSelectedToEntityIdRequest,
): TTenantUSerAction => ({
  payload: setTenantSelectedToEntityIdRequest,
  type: EUserAction.SetTenantSelectedEntityId,
});

export const setTenantSelectedToEntities = (
  setTenantSelectedToEntitiesRequest: ISetTenantSelectedToEntitiesRequest,
): TTenantUSerAction => ({
  payload: setTenantSelectedToEntitiesRequest,
  type: EUserAction.SetTenantSelectedEntities,
});

export const setTenantSelectedToTimeZone = (
  setTenantSelectedToTimeZoneRequest: ISetTenantSelectedToTimeZoneRequest,
): TTenantUSerAction => ({
  payload: setTenantSelectedToTimeZoneRequest,
  type: EUserAction.SetTenantSelectedTimeZone,
});

export const setTenantDefaultTimeZone = (
  setTenantTenantTimeZoneRequest: ISetTenantDefaultTimeZoneRequest,
): TTenantUSerAction => ({
  payload: setTenantTenantTimeZoneRequest,
  type: EUserAction.SetTenantDefaultTimeZone,
});
