import {
  AutoComplete as AntDesignAutoComplete,
  Input as AntDesignInput,
} from 'antd';
import { AxiosResponse } from 'axios';
import Input from 'components/atoms/Input/Input';
import SeparatedRowLayout from 'components/atoms/SeparatedRowLayout/SeparatedRowLayout';
import ToEntityETagPopover from 'components/molecules/ToEntityETagPopover/ToEntityETagPopover';
import { ERROR_MESSAGE_MAX_WIDTH } from 'components/molecules/ToEntityETagTemplateCreate/constants';
import { filterTemplateGroups } from 'components/molecules/ToEntityETagTemplateCreate/helpers';
import { ITemplateGroupValue } from 'components/molecules/ToEntityETagTemplateCreate/types';
import { TEMPLATE_GROUP_NAME_REGEX } from 'constants/ETag';
import { EActionState } from 'enums/General';
import { EPageMode } from 'enums/Page';
import { IOption } from 'interfaces/Component';
import {
  IETagTemplate,
  IETagTemplateCopyResponse,
  IETagTemplateGroup,
  IETagTemplateGroupsResponse,
} from 'interfaces/ETag';
import { OptionData, OptionGroupData } from 'rc-select/lib/interface';
import { ChangeEvent, useMemo, useState } from 'react';
import {
  copyETagTemplate,
  retrieveTemplateGroups,
} from 'services/agent/templates';
import styled from 'styled-components';
import { TTimeZone } from 'types/DateTime';
import { TErrorMessage } from 'types/Error';
import { TToEntityId } from 'types/ToEntity';
import useAsyncEffect from 'use-async-effect';
import { sortByOptionLabel } from 'utils/component';
import { detailPageLocationString } from 'utils/detail';
import { captureError } from 'utils/error';
import { isEmptyValue, isSuccessStatus } from 'utils/general';

const { TextArea: AntDesignTextArea } = AntDesignInput;

const NameLabel = styled.span`
  max-width: 92px;
  width: 100%;
`;

const GroupNameLabel = styled.span`
  max-width: 94px;
  width: 100%;
`;

const DescriptionLabel = styled.span`
  max-width: 124px;
  width: 100%;
`;

// Specialize the Select component
const TemplateGroupAutoComplete = styled((props) => (
  <AntDesignAutoComplete {...props} />
))`
  width: 204px;
`;

interface IToEntityETagTemplateCopyPopoverProps {
  eTagTemplate: IETagTemplate | undefined;
  timeZone: TTimeZone;
  toEntityId: TToEntityId;
}

const ToEntityETagTemplateCopyPopover = (
  props: IToEntityETagTemplateCopyPopoverProps,
): JSX.Element => {
  const { eTagTemplate, timeZone, toEntityId } = props;
  const [actionState, setActionState] = useState<EActionState>(
    EActionState.NoAction,
  );
  const [name, setName] = useState<string>('');
  const [groupName, setGroupName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<TErrorMessage>(null);

  const [templateGroupOptions, setTemplateGroupOptions] = useState<
    ITemplateGroupValue[]
  >([]);
  const [currentTemplateGroupValue, setCurrentTemplateGroupValue] =
    useState<ITemplateGroupValue>({ value: '' });

  useAsyncEffect(async () => {
    try {
      const groupsResponse: AxiosResponse = await retrieveTemplateGroups(
        toEntityId,
      );

      const eTagTemplateGroupsResponse: IETagTemplateGroupsResponse =
        groupsResponse.data;

      if (!isSuccessStatus(groupsResponse.status)) {
        throw new Error(eTagTemplateGroupsResponse.errorMessage!);
      }

      const incomingTemplateGroups: IETagTemplateGroup[] =
        eTagTemplateGroupsResponse.response;

      const incomingTemplateGroupOptions: ITemplateGroupValue[] =
        incomingTemplateGroups.map(
          (option: IETagTemplateGroup): ITemplateGroupValue => ({
            value: option.group_name,
          }),
        );

      setTemplateGroupOptions(incomingTemplateGroupOptions);
    } catch (error: any) {
      captureError(error, 'Failed to load list of template groups');
    }
  }, [toEntityId]);

  const handleTemplateNameInput = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleTemplateGroupNameInput = (value: string) => {
    // Only empty string, letters, number, _ and - are allowed!
    // remove spaces from value in input box
    const valueWithoutSpaces: string = value.replace(' ', '');
    setCurrentTemplateGroupValue({ value: valueWithoutSpaces });
    if (
      valueWithoutSpaces.match(TEMPLATE_GROUP_NAME_REGEX) !== null ||
      valueWithoutSpaces === ''
    ) {
      setGroupName(valueWithoutSpaces);
    }
  };

  const handleTemplateDescriptionInput = (
    event: ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setDescription(event.target.value);
  };

  const handleCopy = async () => {
    // Add the option to the dropdown if it isn't there already.
    // Covers the case where the user creates two templates within one opening of the dropdown
    if (eTagTemplate !== undefined) {
      if (
        templateGroupOptions.find(
          (element) => element.value === currentTemplateGroupValue.value,
        ) === undefined
      ) {
        setTemplateGroupOptions(
          templateGroupOptions.concat(currentTemplateGroupValue),
        );
      }

      try {
        setActionState(EActionState.Actioning);

        setErrorMessage(null);

        let response: AxiosResponse<IETagTemplateCopyResponse> | undefined =
          undefined;

        if (eTagTemplate === null) {
          throw new Error('Cannot copy a template with a null template_id');
        }

        response = await copyETagTemplate(
          toEntityId,
          eTagTemplate,
          name,
          groupName,
          description,
        );

        const eTagTemplateCopyResponse: IETagTemplateCopyResponse =
          response.data;

        if (!isSuccessStatus(response.status)) {
          throw new Error(
            eTagTemplateCopyResponse.errorMessage === null
              ? 'Unknown error'
              : eTagTemplateCopyResponse.errorMessage,
          );
        }

        setActionState(EActionState.Succeeded);

        if (eTagTemplateCopyResponse.response.templateId !== null) {
          const href: string = detailPageLocationString({
            defaultTimeZone: timeZone,
            templateId: eTagTemplateCopyResponse.response.templateId,
            mode: EPageMode.Edit,
            toEntity: toEntityId,
          });
          window.open(`${href}`, '_blank');
        } else {
          throw new Error(
            'No templateId in response, cannot open template edit page',
          );
        }
      } catch (error: any) {
        captureError(error);

        if (error instanceof Error) {
          setErrorMessage(error.message);
        } else {
          setErrorMessage(
            'An error occurred during template copying. Please try again later.',
          );
        }

        setActionState(EActionState.Failed);
      }
    }
  };

  const sortedTemplateGroupOptions: IOption<string | null>[] = useMemo(
    () =>
      templateGroupOptions
        .map((option: ITemplateGroupValue) => ({
          label: option.value,
          value: option.value,
        }))
        .sort(sortByOptionLabel),
    [templateGroupOptions],
  );

  return (
    <ToEntityETagPopover
      actionState={actionState}
      errorMessage={errorMessage}
      errorMessageMaxWidth={ERROR_MESSAGE_MAX_WIDTH}
      isPrimaryActionDisabled={isEmptyValue(name)}
      onPrimaryAction={handleCopy}
      primaryActionLabel='Save as'
      timeZone={timeZone}
      title={`Copy template ${eTagTemplate?.name}`}
    >
      <SeparatedRowLayout>
        <NameLabel>Template Name:</NameLabel>
        <Input
          onChange={handleTemplateNameInput}
          placeholder='Template Name'
          value={name}
        />
      </SeparatedRowLayout>
      <SeparatedRowLayout>
        <GroupNameLabel>
          Template Group:
          <br />
          (optional)
        </GroupNameLabel>
        <TemplateGroupAutoComplete
          options={sortedTemplateGroupOptions}
          placeholder={'Select Group Filter'}
          filterOption={(
            value: string,
            option: OptionData | OptionGroupData | undefined,
          ): boolean => filterTemplateGroups(value, option)}
          onChange={handleTemplateGroupNameInput}
          value={groupName}
        />
      </SeparatedRowLayout>
      <SeparatedRowLayout>
        <DescriptionLabel>
          Template Description:
          <br />
          (optional)
        </DescriptionLabel>
        <AntDesignTextArea
          onChange={handleTemplateDescriptionInput}
          placeholder='Template Description'
          rows={3}
          value={description}
        />
      </SeparatedRowLayout>
    </ToEntityETagPopover>
  );
};

export default ToEntityETagTemplateCopyPopover;
