import Button from 'components/atoms/Button/Button';
import ErrorMessage from 'components/atoms/ErrorMessage/ErrorMessage';
import SeparatedRowLayout from 'components/atoms/SeparatedRowLayout/SeparatedRowLayout';
import { EActionState } from 'enums/General';
import styled from 'styled-components';
import { TErrorMessage } from 'types/Error';
import { encodeIds, isEmptyValue } from 'utils/general';

const FooterLayout = styled(SeparatedRowLayout)`
  justify-content: space-between;
`;

const ActionGroup = styled.div`
  display: flex;
  flex-direction: row;
`;

interface IProps {
  additionalActions?: JSX.Element;
  confirmActionState?: EActionState;
  confirmLabel: string;
  errorMessage?: TErrorMessage;
  encodedPermissionsId: string;
  isCancelDisabled?: boolean;
  isConfirmDisabled?: boolean;
  onConfirmActionIconRemoved?: () => void;
  onCancel?: () => Promise<void>;
  onConfirm: () => Promise<void>;
}

const EditorFooter = (props: IProps): JSX.Element => {
  const {
    additionalActions,
    confirmActionState,
    confirmLabel,
    encodedPermissionsId,
    errorMessage,
    isCancelDisabled,
    isConfirmDisabled,
    onConfirmActionIconRemoved,
    onCancel,
    onConfirm,
  } = props;

  return (
    <FooterLayout>
      <ActionGroup>{additionalActions}</ActionGroup>
      {isEmptyValue(errorMessage) ? null : (
        <ErrorMessage maxWidth='100%' topMargin={0}>
          {errorMessage}
        </ErrorMessage>
      )}
      <ActionGroup>
        {onCancel === undefined ? null : (
          <Button
            isDisabled={isCancelDisabled}
            label='Cancel'
            onClick={onCancel}
          />
        )}
        <Button
          actionState={confirmActionState}
          encodedPermissionsId={encodeIds([encodedPermissionsId, `confirm`])}
          isDisabled={isConfirmDisabled}
          isPrimary={true}
          label={confirmLabel}
          onActionIconRemoved={onConfirmActionIconRemoved}
          onClick={onConfirm}
        />
      </ActionGroup>
    </FooterLayout>
  );
};

export default EditorFooter;
