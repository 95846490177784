import { TRootState } from '../types/Redux';
import { useSelector } from 'react-redux';
import { shallowObjectCompare } from '../utils/general';
import { TTimeZone } from '../types/DateTime';
import { TToEntityId } from '../types/ToEntity';
import { IToEntity } from '../interfaces/ToEntity';

export interface ITenantInfo {
  selectedTimeZone: TTimeZone;
  selectedTenantToEntityId: TToEntityId;
  defaultTimeZone?: TTimeZone;
  toEntities?: IToEntity[];
}

const retrieveTenantUserState = (state: TRootState) => state.tenantUser;
const useTenantInfo = (): ITenantInfo => {
  const {
    selectedTimeZone,
    selectedTenantToEntityId,
    defaultTimeZone,
    toEntities,
  } = useSelector(retrieveTenantUserState, shallowObjectCompare);

  return {
    defaultTimeZone,
    selectedTimeZone,
    selectedTenantToEntityId,
    toEntities,
  };
};

export default useTenantInfo;
