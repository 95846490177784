import LinkBox from 'components/pages/LandingPage/LinkBox';
import NameAndDescription from 'components/pages/LandingPage/NameAndDescription';
import PageLink from 'components/pages/LandingPage/PageLink';
import { IStyledLinkProps } from 'components/pages/LandingPage/types';

const PageLinkBox = ({
  currentTheme,
  description,
  icon,
  name,
  permissionId,
  target,
  toEntities,
}: IStyledLinkProps) => {
  return (
    <PageLink
      permissionId={permissionId}
      target={target}
      toEntities={toEntities}
    >
      <LinkBox implemented={true} currentTheme={currentTheme}>
        {icon}
        <NameAndDescription description={description} name={name} />
      </LinkBox>
    </PageLink>
  );
};

export default PageLinkBox;
