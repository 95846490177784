import { IProfileColumns } from 'components/pages/DetailPrintPage/DetailExportView/types';
import {
  GENERATION_CURRENT_LEVEL_KEY_REG_EXP,
  GENERATION_MARKET_LEVEL_KEY_REG_EXP,
  GENERATION_RELIABILITY_KEY_REG_EXP,
  LOAD_CURRENT_LEVEL_KEY_REG_EXP,
  LOAD_MARKET_LEVEL_KEY_REG_EXP,
  LOAD_RELIABILITY_KEY_REG_EXP,
  PROFILE_PATH_RAMP_START_KEY,
  PROFILE_PATH_RAMP_STOP_KEY,
  TRANSMISSION_ALLOC_TOTALS_KEY_REG_EXP,
  TRANSMISSION_KEY_REG_EXP,
  TRANSMISSION_LOSS_KEY_REG_EXP,
  TRANSMISSION_LOSS_KEY_SUFFIX,
  TRANSMISSION_POD_KEY_REG_EXP,
  TRANSMISSION_POD_KEY_SUFFIX,
  TRANSMISSION_POR_KEY_REG_EXP,
} from 'constants/Detail';
import { EProfileDataGridCellType } from 'enums/Detail';
import { IProfileDataGridCell } from 'interfaces/Detail';
import { TProfileDataGridColumn, TProfileDataGridRow } from 'types/Detail';
import { isEmptyValue } from 'utils/general';

export const getProfileColumns = (
  profileInformationColumns: TProfileDataGridColumn[],
  profileInformationDataSet: TProfileDataGridRow[],
): IProfileColumns => {
  const profileColumns: IProfileColumns = {
    energyProfileColumns: [],
    transmissionAllocationProfileColumns: [],
  };
  const noneZeroLossPODKeys: Set<string> = new Set<string>();

  profileInformationDataSet.forEach(
    (profileDataGridRow: TProfileDataGridRow) => {
      const lossKeys: string[] = Object.keys(profileDataGridRow).filter(
        (key: string): boolean => TRANSMISSION_LOSS_KEY_REG_EXP.test(key),
      );

      lossKeys.forEach((lossKey: string) => {
        const profileDataGridCell: IProfileDataGridCell | null | undefined =
          profileDataGridRow[lossKey];

        if (!isEmptyValue(profileDataGridCell)) {
          const { type, value } = profileDataGridCell!;

          if (type !== EProfileDataGridCellType.Number) {
            throw new Error(
              `Invalid profileDataGridCell type: ${type} for key: ${lossKey}`,
            );
          }

          if ((value as number) !== 0) {
            noneZeroLossPODKeys.add(
              lossKey.replace(
                TRANSMISSION_LOSS_KEY_SUFFIX,
                TRANSMISSION_POD_KEY_SUFFIX,
              ),
            );
          }
        }
      });
    },
  );

  profileInformationColumns.forEach(
    (profileInformationColumn: TProfileDataGridColumn) => {
      const { key } = profileInformationColumn;

      if (
        !TRANSMISSION_KEY_REG_EXP.test(key) ||
        (TRANSMISSION_POD_KEY_REG_EXP.test(key) && noneZeroLossPODKeys.has(key))
      ) {
        profileColumns.energyProfileColumns.push(profileInformationColumn);
      }

      if (
        key !== PROFILE_PATH_RAMP_START_KEY &&
        key !== PROFILE_PATH_RAMP_STOP_KEY &&
        !GENERATION_CURRENT_LEVEL_KEY_REG_EXP.test(key) &&
        !GENERATION_MARKET_LEVEL_KEY_REG_EXP.test(key) &&
        !GENERATION_RELIABILITY_KEY_REG_EXP.test(key) &&
        !LOAD_CURRENT_LEVEL_KEY_REG_EXP.test(key) &&
        !LOAD_RELIABILITY_KEY_REG_EXP.test(key) &&
        !LOAD_MARKET_LEVEL_KEY_REG_EXP.test(key) &&
        !TRANSMISSION_POD_KEY_REG_EXP.test(key) &&
        !TRANSMISSION_POR_KEY_REG_EXP.test(key) &&
        !TRANSMISSION_LOSS_KEY_REG_EXP.test(key) &&
        !TRANSMISSION_ALLOC_TOTALS_KEY_REG_EXP.test(key)
      ) {
        profileColumns.transmissionAllocationProfileColumns.push(
          profileInformationColumn,
        );
      }
    },
  );

  return profileColumns;
};
