interface ICurrentPendingIconProps {
  className?: string;
}

const CurrentPendingIcon = ({
  className,
}: ICurrentPendingIconProps): JSX.Element => (
  <svg
    aria-hidden='true'
    className={className}
    clipRule='evenodd'
    fill='currentColor'
    fillRule='evenodd'
    height='1em'
    strokeLinejoin='round'
    strokeMiterlimit='2'
    viewBox='0 0 1000 1000'
    width='1em'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g transform='matrix(83.3333,0,0,83.3333,0,0)'>
      <path d='M6,0C2.687,0 0,2.687 0,6C0,9.313 2.687,12 6,12C9.313,12 12,9.313 12,6C12,2.687 9.313,0 6,0ZM6,10.982C3.249,10.982 1.018,8.751 1.018,6C1.018,3.249 3.249,1.018 6,1.018C8.751,1.018 10.982,3.249 10.982,6C10.982,8.751 8.751,10.982 6,10.982Z' />
    </g>
    <g transform='matrix(802.718,0,0,694.444,269.728,750)'>
      <path d='M0.1,-0.693C0.125,-0.699 0.153,-0.703 0.182,-0.705C0.211,-0.707 0.24,-0.708 0.269,-0.708C0.3,-0.708 0.331,-0.705 0.363,-0.699C0.394,-0.693 0.423,-0.682 0.449,-0.666C0.475,-0.65 0.496,-0.628 0.513,-0.6C0.529,-0.572 0.537,-0.536 0.537,-0.493C0.537,-0.45 0.529,-0.414 0.514,-0.385C0.499,-0.356 0.478,-0.332 0.453,-0.314C0.428,-0.295 0.399,-0.282 0.366,-0.274C0.333,-0.266 0.3,-0.262 0.265,-0.262L0.249,-0.262C0.241,-0.262 0.233,-0.262 0.225,-0.263C0.216,-0.263 0.208,-0.263 0.2,-0.264C0.192,-0.265 0.186,-0.265 0.183,-0.266L0.183,-0L0.1,-0L0.1,-0.693ZM0.271,-0.636C0.254,-0.636 0.237,-0.636 0.221,-0.635C0.205,-0.634 0.192,-0.633 0.183,-0.63L0.183,-0.34C0.186,-0.339 0.192,-0.338 0.199,-0.338C0.206,-0.337 0.214,-0.337 0.222,-0.337C0.23,-0.336 0.238,-0.336 0.245,-0.336L0.261,-0.336C0.284,-0.336 0.306,-0.338 0.329,-0.343C0.351,-0.347 0.371,-0.355 0.389,-0.366C0.407,-0.377 0.422,-0.393 0.433,-0.414C0.444,-0.435 0.449,-0.461 0.449,-0.493C0.449,-0.52 0.444,-0.543 0.434,-0.562C0.423,-0.58 0.409,-0.595 0.393,-0.606C0.376,-0.616 0.356,-0.624 0.335,-0.629C0.314,-0.634 0.292,-0.636 0.271,-0.636Z' />
    </g>
  </svg>
);

export default CurrentPendingIcon;
