import { forwardRef, useImperativeHandle, useState } from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';

export default forwardRef((props: ICellRendererParams, ref) => {
  const style = {
    display: 'flex',
    alignItems: 'center',
  };
  const rowCount = props.node.rowPinned
    ? props.api.getDisplayedRowCount()
    : props.api.getDisplayedRowCount() - 1;

  const [count, setCount] = useState(rowCount);
  useImperativeHandle(ref, () => {
    return {
      refresh(params: ICellRendererParams) {
        setCount(rowCount);
      },
    };
  });

  return <span style={style}>Tag Count: {count}</span>;
});
