import {
  Dropdown as AntDesignDropdown,
  Menu as AntDesignMenu,
  Tag as AntDesignTag,
} from 'antd';
import {
  INPUT_HEIGHT,
  STANDARD_SPACING,
  STANDARD_SPACING_VALUE,
} from 'constants/styles';
import { IOption } from 'interfaces/Component';
import { MenuInfo } from 'rc-menu/lib/interface';
import { useCallback, useMemo } from 'react';
import styled from 'styled-components';

const StyledTag = styled(AntDesignTag)`
  height: ${INPUT_HEIGHT};
  padding: ${STANDARD_SPACING_VALUE / 2}px ${STANDARD_SPACING};
`;

interface ITaggedSelectorProps<T> {
  isDisabled?: boolean;
  onChange?: (value: T) => void;
  options: IOption<T>[];
  uidToValue: (uid: string) => T;
  value?: T;
  valueToColour?: (value: T) => string | undefined;
  valueToDisplayString: (value: T, options: IOption<T>[]) => string;
  valueToUid: (value: T) => string;
}

const TaggedSelector = <T extends any>({
  isDisabled,
  onChange,
  options,
  uidToValue,
  value,
  valueToColour,
  valueToDisplayString,
  valueToUid,
}: ITaggedSelectorProps<T>): JSX.Element => {
  const handleOptionSelect = useCallback(
    (menuInfo: MenuInfo) => {
      onChange?.(uidToValue(menuInfo.key));
    },
    [onChange, uidToValue],
  );

  const optionsMenu = useMemo(
    (): JSX.Element => (
      <AntDesignMenu onClick={handleOptionSelect}>
        {options.map(
          (option: IOption<T>): JSX.Element => (
            <AntDesignMenu.Item key={valueToUid(option.value)}>
              {option.label}
            </AntDesignMenu.Item>
          ),
        )}
      </AntDesignMenu>
    ),
    [handleOptionSelect, options, valueToUid],
  );

  const tagColour: string | undefined = useMemo(
    () => (value === undefined ? undefined : valueToColour?.(value)),
    [value, valueToColour],
  );

  const displayString: string | undefined = useMemo(
    (): string | undefined =>
      value === undefined ? undefined : valueToDisplayString?.(value, options),
    [options, value, valueToDisplayString],
  );

  return (
    <AntDesignDropdown
      disabled={isDisabled}
      overlay={optionsMenu}
      trigger={['click']}
    >
      <StyledTag color={tagColour}>{displayString}</StyledTag>
    </AntDesignDropdown>
  );
};

export default TaggedSelector;
