import { ERetreiveState } from 'enums/General';
import {
  ITenantToEntities,
  IToEntityUiConfiguration,
  IUserUiConfiguration,
} from 'interfaces/Config';
import { IContactInfo } from 'interfaces/General';
import { IToEntity } from 'interfaces/ToEntity';
import { TTimeZone } from 'types/DateTime';
import { TErrorMessage } from 'types/Error';
import { TToEntityId } from 'types/ToEntity';

export enum EUserAction {
  RetrieveUserInfoStart = 'RETRIEVE_USER_INFO_START',
  RetrieveUserInfoSuccess = 'RETRIEVE_USER_INFO_SUCCESS',
  RetrieveUserInfoFailure = 'RETRIEVE_USER_INFO_FAILURE',
  RetrieveToEntityUiConfigStart = 'RETRIEVE_TO_ENTITY_UI_CONFIG_START',
  RetrieveTenantUserConfigsStart = 'RETRIEVE_TENANT_USER_CONFIG_START',
  RetrieveToEntityUiConfigSuccess = 'RETRIEVE_TO_ENTITY_UI_CONFIG_SUCCESS',
  RetrieveTenantUserConfigsSuccess = 'RETRIEVE_TENANT_USER_CONFIG_SUCCESS',
  RetrieveToEntityUiConfigFailure = 'RETRIEVE_TO_ENTITY_UI_CONFIG_FAILURE',
  RetrieveTenantUserConfigsFailure = 'RETRIEVE_TENANT_USER_CONFIG_FAILURE',
  SetSelectedTimeZone = 'SET_SELECTED_TIME_ZONE',
  SetSelectedToEntity = 'SET_SELECTED_TO_ENTITY',
  SetContactInfo = 'SET_CONTACT_INFO',
  SetTenantSelectedEntityId = 'SET_TENANT_SELECTED_ENTITY_ID',
  SetTenantSelectedEntities = 'SET_TENANT_SELECTED_ENTITIES',
  SetTenantSelectedTimeZone = 'SET_TENANT_SELECTED_TIME_ZONE',
  SetTenantDefaultTimeZone = 'SET_TENANT_DEFAULT_TIME_ZONE',
}

export type TToEntityUserStates = Partial<
  Record<TToEntityId, IToEntityUserState>
>;

export interface IUserState {
  errorMessage: TErrorMessage;
  retrievingUserInfo: ERetreiveState;
  toEntities: IToEntity[];
  selectedToEntity: IToEntity | undefined;
  tenantToEntities: ITenantToEntities[];
  toEntityUserStates: TToEntityUserStates;
}

export interface ICombinedUserState {
  errorMessage: TErrorMessage;
  retrievingTenantConfigs: ERetreiveState;
  tenantUserConfigs: any;
  selectedTenantToEntityId: TToEntityId;
  selectedTimeZone: TTimeZone;
  defaultTimeZone?: TTimeZone;
  toEntities?: IToEntity[];
}

export interface IToEntityUserState {
  errorMessage?: TErrorMessage;
  retrieving: ERetreiveState;
  selectedTimeZone?: TTimeZone;
  toEntityUiConfig?: IToEntityUiConfiguration;
  userUiConfig?: IUserUiConfiguration;
}

export interface IUserActionPayload {}

export interface IUserInfoRequest extends IUserActionPayload {}

interface IRetrieveUserInfoStartAction {
  payload: IUserInfoRequest;
  type: EUserAction.RetrieveUserInfoStart;
}

export interface IUserInfoSuccessReply extends IUserActionPayload {
  toEntities: IToEntity[];
  tenantToEntities: ITenantToEntities[];
}

interface IRetrieveUserInfoSuccessAction {
  payload: IUserInfoSuccessReply;
  type: EUserAction.RetrieveUserInfoSuccess;
}

export interface IUserInfoFailureReply extends IUserActionPayload {
  errorMessage: string;
}

interface IRetrieveUserInfoFailureAction {
  payload: IUserInfoFailureReply;
  type: EUserAction.RetrieveUserInfoFailure;
}

export interface IUserToEntityUiActionPayload extends IUserActionPayload {
  toEntityId: TToEntityId;
}

export interface IToEntityUiConfigRequest
  extends IUserToEntityUiActionPayload {}

interface IRetrieveToEntityUiConfigStartAction {
  payload: IToEntityUiConfigRequest;
  type: EUserAction.RetrieveToEntityUiConfigStart;
}

interface IRetrieveTenantUserConfigsStartAction {
  payload: {};
  type: EUserAction.RetrieveTenantUserConfigsStart;
}

export interface IToEntityUiConfigSuccessReply
  extends IUserToEntityUiActionPayload {
  toEntityUiConfiguration: IToEntityUiConfiguration;
  userUiConfiguration: IUserUiConfiguration;
}

interface IRetrieveToEntityUiConfigSuccessAction {
  payload: IToEntityUiConfigSuccessReply;
  type: EUserAction.RetrieveToEntityUiConfigSuccess;
}

interface IRetrieveTenantUserConfigsSuccessAction {
  payload: any;
  type: EUserAction.RetrieveTenantUserConfigsSuccess;
}

export interface IToEntityUiConfigFailureReply
  extends IUserToEntityUiActionPayload {
  errorMessage: string;
}

export interface ITenantUserConfigsFailureReply {
  errorMessage: string;
}

interface IRetrieveToEntityUiConfigFailureAction {
  payload: IToEntityUiConfigFailureReply;
  type: EUserAction.RetrieveToEntityUiConfigFailure;
}

interface IRetrieveTenantUserConfigsFailureAction {
  payload: ITenantUserConfigsFailureReply;
  type: EUserAction.RetrieveTenantUserConfigsFailure;
}

export interface IUserSelectedTimeZone extends IUserToEntityUiActionPayload {
  selectedTimeZone: TTimeZone;
}

interface ISetUserInfoSelectedTimeZoneAction {
  payload: IUserSelectedTimeZone;
  type: EUserAction.SetSelectedTimeZone;
}

export interface IUserSelectedToEntity extends IUserActionPayload {
  selectedToEntity: IToEntity | undefined;
}

interface ISetUserInfoSelectedToEntityAction {
  payload: IUserSelectedToEntity;
  type: EUserAction.SetSelectedToEntity;
}

export interface IUserContactInfo extends IUserActionPayload {
  contactInfo: IContactInfo;
}

export interface IToEntityLossesConfig {
  toEntity: string;
  showLosses: boolean;
}

interface ISetUserInfoContactInfoAction {
  payload: IUserContactInfo;
  type: EUserAction.SetContactInfo;
}

export interface ISetTenantSelectedToEntityIdRequest {
  toEntityId: TToEntityId;
}

export interface ISetTenantSelectedToEntitiesRequest {
  toEntities?: IToEntity[];
}

export interface ISetTenantSelectedToTimeZoneRequest {
  timeZone: TTimeZone;
}
export interface ISetTenantDefaultTimeZoneRequest {
  timeZone: TTimeZone;
}

interface ISetTenantSelectedToEntityId {
  payload: ISetTenantSelectedToEntityIdRequest;
  type: EUserAction.SetTenantSelectedEntityId;
}

interface ISetTenantSelectedToEntities {
  payload: ISetTenantSelectedToEntitiesRequest;
  type: EUserAction.SetTenantSelectedEntities;
}

interface ISetTenantSelectedToTimeZone {
  payload: ISetTenantSelectedToTimeZoneRequest;
  type: EUserAction.SetTenantSelectedTimeZone;
}

interface ISetTenantDefaultTimeZone {
  payload: ISetTenantDefaultTimeZoneRequest;
  type: EUserAction.SetTenantDefaultTimeZone;
}

// Actions
export type TUserAction =
  | IRetrieveUserInfoStartAction
  | IRetrieveUserInfoSuccessAction
  | IRetrieveUserInfoFailureAction
  | IRetrieveToEntityUiConfigStartAction
  | IRetrieveToEntityUiConfigSuccessAction
  | IRetrieveToEntityUiConfigFailureAction
  | ISetUserInfoSelectedTimeZoneAction
  | ISetUserInfoSelectedToEntityAction
  | ISetUserInfoContactInfoAction;

export type TTenantUSerAction =
  | ISetTenantSelectedToEntityId
  | ISetTenantSelectedToEntities
  | ISetTenantSelectedToTimeZone
  | ISetTenantDefaultTimeZone
  | IRetrieveTenantUserConfigsStartAction
  | IRetrieveTenantUserConfigsSuccessAction
  | IRetrieveTenantUserConfigsFailureAction;
