import Select, { ISelectProps } from 'components/molecules/Select/Select';
import { toEntityToUid } from 'components/molecules/ToEntitySelector/helpers';
import Tooltip, { ITooltipProps } from 'components/molecules/Tooltip/Tooltip';
import { TO_ENTITY_TAG_WIDTH_VALUE } from 'constants/styles';
import { IOption } from 'interfaces/Component';
import { IToEntity } from 'interfaces/ToEntity';
import { useCallback, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { setTenantSelectedToEntities } from '../../../reduxes/User/actions';
import { useDispatch } from 'react-redux';

interface IStyledTooltipProps {
  width: number;
}

const StyledTooltip = styled(Tooltip)<ITooltipProps & IStyledTooltipProps>`
  width: ${(props) => props.width}px;
`;

// Specialize the Select component
const ToEntitySelect = (props: ISelectProps<IToEntity>) =>
  Select<IToEntity>(props);

export interface IToEntitySelectorProps {
  onChange?: (toEntities: IToEntity[]) => void;
  selectedToEntities?: IToEntity[];
  toEntities: IToEntity[];
}

const ToEntitySelector = ({
  onChange,
  selectedToEntities,
  toEntities,
}: IToEntitySelectorProps): JSX.Element => {
  const dispatch = useDispatch();
  const handleToEntityChange = useCallback(
    (toEntities: IToEntity[] | undefined) => {
      const selectedToEntities: IToEntity[] =
        toEntities === undefined ? [] : toEntities;

      dispatch(setTenantSelectedToEntities({ toEntities }));

      onChange?.(selectedToEntities);
    },
    [dispatch, onChange],
  );

  useEffect(() => {
    dispatch(setTenantSelectedToEntities({ toEntities }));
  }, [dispatch, toEntities]);

  const toEntityOptions: IOption<IToEntity>[] = useMemo(
    () =>
      toEntities.map(
        (toEntity: IToEntity): IOption<IToEntity> => ({
          label: toEntity.entity_code,
          value: toEntity,
          tooltipLabel: `${toEntity.entity_code} (${toEntity.to_entity})`,
        }),
      ),
    [toEntities],
  );

  return (
    <StyledTooltip
      title=''
      width={toEntities.length * TO_ENTITY_TAG_WIDTH_VALUE}
    >
      <ToEntitySelect
        allowMultiple={true}
        dropdownMatchSelectWidth={false}
        maxTagCount='responsive'
        onChangeMultiple={handleToEntityChange}
        options={toEntityOptions}
        placeholder='Select ToEntities'
        values={selectedToEntities}
        valueToUid={toEntityToUid}
        showOptionTooltip={true}
        useTooltipLabel={true}
      />
    </StyledTooltip>
  );
};

export default ToEntitySelector;
