import {
  ColDef,
  Column,
  ColumnApi,
  GridApi,
  RowNode,
  IAggFunc as IAGGridAggFunc,
} from 'ag-grid-community';

export interface IAggFuncParams {
  // Values to aggregate
  values: any[];
  // Column the aggregation function is working on
  column: Column;
  // ColDef of the aggregation column
  colDef: ColDef;
  // The parent RowNode, where the aggregation result will be shown
  rowNode: RowNode;
  // data (if any) of the parent RowNode
  data: any;
  api: GridApi;
  columnApi: ColumnApi;
  // The context as provided on `gridOptions.context`
  context: any;
}

export interface IAggFunc {
  (params: IAggFuncParams): any;
}

export interface IAggFuncs {
  [_: string]: IAggFunc;
}

const calcFixed = (
  a: number,
  b: number,
  p = 0,
  operation: '+' | '-',
): number => {
  if ([a, b].some(isNaN)) {
    return 0;
  }
  const toFixed = (n: number): number => Number(n.toFixed(p));
  const countDecimal = (a: number): number => String(a).split('.')[1].length;
  const dec =
    isNaN(p) && p !== 0 ? Math.max(countDecimal(a), countDecimal(b)) : p;
  const t = Number(`1${'0'.repeat(dec)}`);
  const aVal = toFixed(a);
  const bVal = toFixed(b);
  if (operation === '+') {
    return (aVal * t + bVal * t) / t;
  } else if (operation === '-') {
    return (aVal * t - bVal * t) / t;
  } else {
    return NaN;
  }
};

const addFixed = (a: number, b: number, p = 0): number =>
  calcFixed(a, b, p, '+');

const sumValues = (values: number[], precision = 0) => {
  if (values.length === 0) {
    return null;
  } else {
    return values.reduce(
      (sum: number, row: number) => addFixed(sum, row, precision),
      0,
    );
  }
};

export const SummaryTotal: IAGGridAggFunc = (params: any) => {
  const filteredParamValues = params.values.filter(
    (element: any) => element !== undefined,
  );

  if (
    filteredParamValues.length === 0 ||
    typeof filteredParamValues[0] === 'undefined'
  ) {
    return undefined;
  }

  const values = filteredParamValues
    .filter((e: any) => e != null)
    .map((num: number) => Number(num));
  // const precision = params.context?.pageConfig?.precision || 0;
  return sumValues(values);
};
