import { DownOutlined } from '@ant-design/icons';
import {
  Dropdown as AntDesignDropdown,
  Empty as AntDesignEmpty,
  List as AntDesignList,
  Menu as AntDesignMenu,
  Spin as AntDesignSpin,
} from 'antd';
import Checkbox from 'components/atoms/Checkbox/Checkbox';
import {
  getAllDataItemKeys,
  getItemKey,
  isAllSelected,
} from 'components/molecules/InfiniteSelectableList/helpers';
import { STANDARD_SPACING } from 'constants/styles';
import { IIndexable } from 'interfaces/General';
import { RefObject, useEffect, useRef } from 'react';
import {
  AutoSizer,
  Index,
  IndexRange,
  InfiniteLoader,
  InfiniteLoaderChildProps,
  List as VirtualizedList,
  ListRowProps,
  Size,
} from 'react-virtualized';
import styled from 'styled-components';

const HEADER_HEIGHT_VALUE = 37;

const List = styled(AntDesignList)`
  height: 100%;

  > .ant-spin-nested-loading {
    height: calc(100% - ${HEADER_HEIGHT_VALUE}px);

    > .ant-spin-container {
      height: 100%;
    }
  }
`;

const ListItem = styled(AntDesignList.Item)`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  > :not(:last-child) {
    margin-right: ${STANDARD_SPACING};
  }
`;

const EmtpyContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;

  > div {
    margin: 0;
  }
`;

enum EPlacement {
  Bottom = 'bottom',
  Center = 'center',
}

interface ISpinProps {
  placement: EPlacement;
}

const Spin = styled(AntDesignSpin)<ISpinProps>`
  &&& {
    ${(props) =>
      props.placement === EPlacement.Center
        ? `
        max-height: unset;
      `
        : props.placement === EPlacement.Bottom
        ? `
        bottom: 32px;
        height: auto;
        max-height: unset;
        top: unset;
      `
        : ''}
  }
`;

const HeaderLayout = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;

  > :not(:last-child) {
    margin-right: 4px;
  }
`;

export interface IInfiniteSelectableListProps<T> {
  data: T[];
  isLoading?: boolean;
  isRowLoaded: (rowIndex: Index) => boolean;
  itemRenderer: (item: T, index: number) => JSX.Element;
  loadData: (indexRange?: IndexRange) => Promise<void>;
  minimumBatchSize?: number;
  onSelectChange: (selectedKeys: string[]) => void;
  overscanRowCount?: number;
  rowDisabled?: (row: T) => boolean;
  rowHeight: ((params: Index) => number) | number;
  rowKey?: ((row: T) => string) | string;
  selectedKeys: string[];
  threshold?: number;
}

const InfiniteSelectableList = <T extends IIndexable>(
  props: IInfiniteSelectableListProps<T>,
): JSX.Element => {
  const {
    data,
    isLoading,
    isRowLoaded,
    itemRenderer,
    loadData,
    minimumBatchSize,
    onSelectChange,
    overscanRowCount,
    rowDisabled,
    rowHeight,
    rowKey,
    selectedKeys,
    threshold,
  } = props;
  const virtualizedListRef =
    useRef<VirtualizedList>() as RefObject<VirtualizedList>;

  useEffect(() => {
    virtualizedListRef.current?.recomputeRowHeights();
  }, [rowHeight, virtualizedListRef]);

  useEffect(() => {
    const dataKeys: string[] = data.map((item: T): string =>
      getItemKey<T>(item, rowKey),
    );
    const filteredSelectedKeys: string[] = selectedKeys.filter(
      (selectedKey: string): boolean => dataKeys.includes(selectedKey),
    );

    if (filteredSelectedKeys.length !== selectedKeys.length) {
      onSelectChange(filteredSelectedKeys);
    }
  }, [data, onSelectChange, rowKey, selectedKeys]);

  const handleInfiniteOnLoad = async (indexRange: IndexRange) => {
    await loadData(indexRange);
  };

  const handleSelectAllChange = () => {
    onSelectChange(
      isAllSelected<T>(data, selectedKeys, rowKey, rowDisabled)
        ? []
        : getAllDataItemKeys<T>(data, rowKey, rowDisabled),
    );
  };

  const handleSelectItem = (itemKey: string) => () => {
    const itemKeyIndex: number = selectedKeys.indexOf(itemKey);

    onSelectChange(
      itemKeyIndex === -1
        ? selectedKeys.concat(itemKey)
        : selectedKeys.filter((key: string): boolean => key !== itemKey),
    );
  };

  const Header = (): JSX.Element => {
    const handleSelectAll = () => {
      onSelectChange(getAllDataItemKeys<T>(data, rowKey, rowDisabled));
    };

    const handleInvertSelection = () => {
      onSelectChange(
        data
          .filter(
            (item: T): boolean =>
              !selectedKeys.includes(getItemKey<T>(item, rowKey)) &&
              (rowDisabled === undefined || !rowDisabled(item)),
          )
          .map((item: T): string => getItemKey<T>(item, rowKey)),
      );
    };

    const menu: JSX.Element = (
      <AntDesignMenu>
        <AntDesignMenu.Item onClick={handleSelectAll}>
          Select all data
        </AntDesignMenu.Item>
        <AntDesignMenu.Item onClick={handleInvertSelection}>
          Invert current selection
        </AntDesignMenu.Item>
      </AntDesignMenu>
    );

    return (
      <HeaderLayout>
        <Checkbox
          checked={isAllSelected<T>(data, selectedKeys, rowKey, rowDisabled)}
          onChange={handleSelectAllChange}
        />
        <AntDesignDropdown overlay={menu}>
          <DownOutlined />
        </AntDesignDropdown>
        <span>
          {selectedKeys.length > 0 ? `${selectedKeys.length}/` : ''}
          {data.length} items
        </span>
      </HeaderLayout>
    );
  };

  const renderItem = (props: ListRowProps): JSX.Element => {
    const { index, style } = props;
    const item: T = data[index];
    const itemKey: string = getItemKey<T>(item, rowKey);
    const itemElement: JSX.Element = itemRenderer(item, index);
    const itemDisabled: boolean =
      rowDisabled === undefined ? false : rowDisabled(item);

    return (
      <ListItem key={itemKey} style={style}>
        <Checkbox
          checked={!itemDisabled && selectedKeys.includes(itemKey)}
          isDisabled={itemDisabled}
          onChange={handleSelectItem(itemKey)}
        />
        {itemElement}
      </ListItem>
    );
  };

  const noRowsRenderer = (): JSX.Element => (
    <EmtpyContainer>
      <AntDesignEmpty image={AntDesignEmpty.PRESENTED_IMAGE_SIMPLE} />
    </EmtpyContainer>
  );

  return (
    <List bordered={true} header={<Header />}>
      <InfiniteLoader
        isRowLoaded={isRowLoaded}
        loadMoreRows={handleInfiniteOnLoad}
        minimumBatchSize={minimumBatchSize}
        rowCount={Number.MAX_SAFE_INTEGER}
        threshold={threshold}
      >
        {(infiniteLoaderChildProps: InfiniteLoaderChildProps) => (
          <AutoSizer>
            {(size: Size) => (
              <VirtualizedList
                height={size.height}
                noRowsRenderer={isLoading ? undefined : noRowsRenderer}
                onRowsRendered={infiniteLoaderChildProps.onRowsRendered}
                overscanRowCount={overscanRowCount}
                ref={virtualizedListRef}
                rowCount={data.length}
                rowHeight={rowHeight}
                rowRenderer={renderItem}
                width={size.width}
              />
            )}
          </AutoSizer>
        )}
      </InfiniteLoader>
      {isLoading ? (
        <Spin
          placement={data.length === 0 ? EPlacement.Center : EPlacement.Bottom}
        />
      ) : null}
    </List>
  );
};

export default InfiniteSelectableList;
