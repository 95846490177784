import EditableDataTable from 'components/molecules/EditableDataTable/EditableDataTable';
import { IEditMarketPathInformation } from 'components/organisms/MarketPathView/types';
import {
  BUTTON_ICON_DIMENSION_VALUE,
  VIEW_DATA_TABLE_COLUMN_CONTACT_INFO_COLUMN_WIDTH,
  VIEW_DATA_TABLE_COLUMN_CONTRACTS_COLUMN_WIDTH,
  VIEW_DATA_TABLE_COLUMN_ID_COLUMN_WIDTH,
  VIEW_DATA_TABLE_COLUMN_PRODUCT_SELECT_COLUMN_WIDTH,
  VIEW_DATA_TABLE_COLUMN_PSE_SELECT_COLUMN_WIDTH,
} from 'constants/styles';
import { EUpdateState } from 'enums/General';
import { EViewMode } from 'enums/View';
import useMarketEditColumns from 'hooks/useMarketEditColumns/useMarketEditColumns';
import usePrevious from 'hooks/usePrevious';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { detailSetValidation } from 'reduxes/Detail/actions';
import { IDetailMarketSegment } from 'reduxes/Detail/types';
import { TRootState } from 'types/Redux';
import { getInitialMarketSegment } from 'utils/detail';

const ADD_BUTTON_OFFSET = {
  left: `calc(-1 * (68px + ${VIEW_DATA_TABLE_COLUMN_ID_COLUMN_WIDTH} + ${VIEW_DATA_TABLE_COLUMN_PSE_SELECT_COLUMN_WIDTH} + ${VIEW_DATA_TABLE_COLUMN_PRODUCT_SELECT_COLUMN_WIDTH} + ${VIEW_DATA_TABLE_COLUMN_CONTRACTS_COLUMN_WIDTH} + ${VIEW_DATA_TABLE_COLUMN_CONTACT_INFO_COLUMN_WIDTH}))`,
  top: 'calc(100% - 2px)',
};

const REMOVE_BUTTON_OFFSET = {
  right: '0',
  top: `calc(50% - ${BUTTON_ICON_DIMENSION_VALUE / 2}px)`,
};

interface IMarketPathEditProps {
  initialMarketSegment: IDetailMarketSegment[] | undefined;
  isDisabled?: boolean;
  isUnconstrained?: boolean;
  marketSegments: IDetailMarketSegment[];
  onChange: (editMarketPathInformation: IEditMarketPathInformation) => void;
  previousIsDetailUpdating: boolean | undefined;
}

const retrieveMarketPathEditState = (state: TRootState) => {
  const {
    focusKey,
    isDetailDeleted,
    isDetailValidating,
    registryEntities,
    updatingDetail,
    validations,
    viewMode,
  } = state.detail.present;
  const isDetailUpdating: boolean =
    updatingDetail !== EUpdateState.NotUpdating &&
    updatingDetail !== EUpdateState.UpdateCompleted;

  return {
    focusKey,
    isDetailDeleted,
    isDetailUpdating,
    isDetailValidating,
    registryEntities,
    validations,
    viewMode,
  };
};

const MarketPathEdit = ({
  initialMarketSegment,
  isDisabled,
  isUnconstrained = false,
  marketSegments,
  onChange,
  previousIsDetailUpdating,
}: IMarketPathEditProps): JSX.Element => {
  const {
    focusKey,
    isDetailDeleted,
    isDetailUpdating,
    isDetailValidating,
    registryEntities,
    validations,
    viewMode,
  } = useSelector(retrieveMarketPathEditState);
  const dispatch = useDispatch();
  const previousIsDetailDeleted: boolean | undefined =
    usePrevious(isDetailDeleted);
  const previousIsDetailValidating: boolean | undefined =
    usePrevious(isDetailValidating);
  const previousValidations: Record<string, boolean> | undefined =
    usePrevious(validations);

  const setValidation = useCallback(
    (id: string, isValid: boolean) =>
      dispatch(detailSetValidation({ id, isValid })),
    [dispatch],
  );

  const marketSegmentEditColumns = useMarketEditColumns(
    validations,
    focusKey,
    initialMarketSegment,
    isDetailDeleted,
    isDetailUpdating,
    isDetailValidating,
    isUnconstrained,
    onChange,
    previousIsDetailDeleted,
    previousIsDetailUpdating,
    previousIsDetailValidating,
    previousValidations,
    registryEntities,
    setValidation,
    viewMode,
  );

  // Market segment ids are 1-indexed
  const initialiser = useCallback(() => getInitialMarketSegment(1), []);

  const handleAdd = useCallback(
    (value: unknown, record: IDetailMarketSegment) => {
      onChange({ addAfterMarketSegmentId: record.market_segment_id });
    },
    [onChange],
  );

  const handleRemove = useCallback(
    (record: IDetailMarketSegment) => {
      onChange({ removeMarketSegmentId: record.market_segment_id });
    },
    [onChange],
  );

  const allowAdd: boolean =
    viewMode === EViewMode.EditETagDraft ||
    viewMode === EViewMode.EditETagTemplate;
  const allowRemove: boolean = allowAdd;

  return (
    <EditableDataTable<IDetailMarketSegment>
      addButtonOffset={ADD_BUTTON_OFFSET}
      columns={marketSegmentEditColumns}
      data={marketSegments}
      initialiser={initialiser}
      isDisabled={isDisabled}
      maximumAllowableAdds={-1}
      onAdd={allowAdd ? handleAdd : undefined}
      onRemove={allowRemove ? handleRemove : undefined}
      pagination={false}
      removeButtonOffset={REMOVE_BUTTON_OFFSET}
      rowKey='market_segment_id'
      tableLayout='fixed'
    />
  );
};

export default MarketPathEdit;
