import { forwardRef, useImperativeHandle, useState } from 'react';
import { ICellRendererParams } from 'ag-grid-enterprise';

export default forwardRef((props: any, ref) => {
  const style = {
    display: 'flex',
    alignItems: 'center',
  };

  const colId = props.colDef?.colId;
  const colTotal = props.node.data.data[colId!] || 0;

  const [total, setTotal] = useState(colTotal);
  useImperativeHandle(ref, () => {
    return {
      refresh(params: ICellRendererParams) {
        setTotal(colTotal);
      },
    };
  });

  return (
    <div style={style} id={`colId_${colId!.replaceAll('.', '-')}`}>
      {total}
    </div>
  );
});
