export const SUCCESS_STATUS_CODES = [200];

export const FORBIDDEN_STATUS_CODE = 403;
export const NOT_FOUND_STATUS_CODE = 404;

export const TOOLTIP_MOUSE_ENTER_DELAY_IN_SECONDS = 0.75;

export const TOOLTIP_MOUSE_LEAVE_DELAY_IN_SECONDS = 0.15;

export const BOOLEAN_TRUE_LABEL = 'Yes';

export const BOOLEAN_FALSE_LABEL = 'No';

export const TENANT_LABEL = 'Combined View';
