import { Input as AntDesignInput } from 'antd';
import {
  US_PHONE_NUMBER_EMPTY_REGEX,
  US_PHONE_NUMBER_VALIDATION_REGEX,
} from 'constants/input';

export const verifyPhoneNumber = (
  phoneNumber: string | null | undefined,
): boolean =>
  phoneNumber?.match(US_PHONE_NUMBER_EMPTY_REGEX) !== null ||
  phoneNumber.match(US_PHONE_NUMBER_VALIDATION_REGEX) !== null;

export const autoFocusAndSelectInput = (input: AntDesignInput | null) => {
  input?.focus();
  input?.select();
};
