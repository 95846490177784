import Button from 'components/atoms/Button/Button';
import Input from 'components/atoms/Input/Input';
import SeparatedRowLayout from 'components/atoms/SeparatedRowLayout/SeparatedRowLayout';
import {
  transmissionAvailabilityColumnDatasToCsvHeaderData,
  transmissionAvailabilityToCsvData,
} from 'components/molecules/TransmissionCapacityAvailabilityCsvExport/helpers';
import {
  ICsvHeader,
  TCsvData,
} from 'components/molecules/TransmissionCapacityAvailabilityCsvExport/types';
import { STANDARD_SPACING } from 'constants/styles';
import {
  FILENAME_DATE_TIME_FORMAT,
  FILENAME_SELECTED_DATE_TIME_FORMAT,
} from 'constants/time';
import { EActionState } from 'enums/General';
import {
  ITransmissionAvailabilityColumnDataAgGrid,
  ITransmissionAvailabilityDataSet,
} from 'interfaces/ETag';
import { ChangeEvent, useState } from 'react';
import { CSVLink } from 'react-csv';
import styled from 'styled-components';
import { TTimeZone } from 'types/DateTime';
import { captureError } from 'utils/error';
import { isEmptyValue } from 'utils/general';
import { ZonedDateTime } from 'utils/zonedDateTime';

const Container = styled.div`
  padding: ${STANDARD_SPACING};
`;

const FilenameLabel = styled.span`
  width: 82px;
`;

const FilenameInput = styled(Input)`
  width: 321px;
`;

const Actions = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 24px;
`;

interface ITransmissionCapacityAvailabilityExportPanelProps {
  allOptionalColumns: string[];
  onSuccessfulExport?: () => void;
  selectedEndDate: ZonedDateTime | null;
  selectedOptionalColumns: string[] | undefined;
  selectedStartDate: ZonedDateTime | null;
  showExtraHourColumn: boolean;
  toEntityUiName: string;
  timeZone: TTimeZone;
  transmissionAvailabilityColumnDatas: ITransmissionAvailabilityColumnDataAgGrid[];
  transmissionAvailabilityDataSets: ITransmissionAvailabilityDataSet[];
}

const TransmissionCapacityAvailabilityExportPanel = (
  props: ITransmissionCapacityAvailabilityExportPanelProps,
): JSX.Element => {
  const {
    allOptionalColumns,
    onSuccessfulExport,
    selectedEndDate,
    selectedOptionalColumns,
    selectedStartDate,
    showExtraHourColumn,
    timeZone,
    toEntityUiName,
    transmissionAvailabilityDataSets,
    transmissionAvailabilityColumnDatas,
  } = props;
  const [csvHeaders, setCsvHeaders] = useState<ICsvHeader[]>([]);
  const [csvData, setCsvData] = useState<TCsvData>([]);
  const [filename, setFilename] = useState<string>(
    `Transmission_Availability_${toEntityUiName}_${ZonedDateTime.now(
      timeZone,
    ).format(FILENAME_DATE_TIME_FORMAT)}_${selectedStartDate?.format(
      FILENAME_SELECTED_DATE_TIME_FORMAT,
    )}-${selectedEndDate?.format(FILENAME_SELECTED_DATE_TIME_FORMAT)}`,
  );
  const [actionState, setActionState] = useState<EActionState>(
    EActionState.NoAction,
  );
  const handleFilenameInput = (event: ChangeEvent<HTMLInputElement>) => {
    setFilename(event.target.value);
  };

  const handleExport = async () => {
    try {
      setActionState(EActionState.Actioning);

      const { csvHeaders, transmissionAvailabilityColumnDataMap } =
        transmissionAvailabilityColumnDatasToCsvHeaderData(
          transmissionAvailabilityColumnDatas,
          allOptionalColumns,
          selectedOptionalColumns,
          showExtraHourColumn,
        );

      const csvData: TCsvData = transmissionAvailabilityToCsvData(
        transmissionAvailabilityDataSets,
        transmissionAvailabilityColumnDataMap,
        timeZone,
      );
      setCsvHeaders(csvHeaders);

      setCsvData(csvData);

      setActionState(EActionState.Succeeded);
    } catch (error: any) {
      captureError(error);

      setActionState(EActionState.Failed);
    }
  };

  const handleActionIconRemoved = () => {
    if (
      actionState === EActionState.Succeeded &&
      onSuccessfulExport !== undefined
    ) {
      onSuccessfulExport();
    }
  };

  return (
    <Container>
      <SeparatedRowLayout>
        <FilenameLabel>CSV Filename:</FilenameLabel>
        <FilenameInput
          onChange={handleFilenameInput}
          placeholder='Filename'
          value={filename}
        />
      </SeparatedRowLayout>
      <Actions>
        <CSVLink
          asyncOnClick={true}
          data={csvData}
          filename={`${filename}.csv`}
          headers={csvHeaders}
          onClick={handleExport}
        >
          <Button
            actionState={actionState}
            label='Export to CSV'
            isDisabled={isEmptyValue(filename)}
            isPrimary={true}
            onActionIconRemoved={handleActionIconRemoved}
          />
        </CSVLink>
      </Actions>
    </Container>
  );
};

export default TransmissionCapacityAvailabilityExportPanel;
