import ContainedText from 'components/molecules/ContainedText/ContainedText';
import PopoverDataTable from 'components/molecules/PopoverDataTable/PopoverDataTable';
import { IEditMarketPathInformation } from 'components/organisms/MarketPathView/types';
import {
  GENERATION_ENERGY_PRODUCT_OPTIONS,
  LOAD_ENERGY_PRODUCT_OPTIONS,
  TRANSMISSION_ENERGY_PRODUCT_OPTIONS,
} from 'constants/ETag';
import {
  ICON_BUTTON_SIZE_VALUE,
  VIEW_DATA_TABLE_COLUMN_CONTACT_INFO_COLUMN_WIDTH,
  VIEW_DATA_TABLE_COLUMN_CONTRACTS_COLUMN_WIDTH,
  VIEW_DATA_TABLE_COLUMN_ID_COLUMN_WIDTH,
  VIEW_DATA_TABLE_COLUMN_MISC_INFO_WIDTH,
  VIEW_DATA_TABLE_COLUMN_MISC_INFO_WIDTH_VALUE,
  VIEW_DATA_TABLE_COLUMN_PRODUCT_SELECT_COLUMN_WIDTH,
  VIEW_DATA_TABLE_COLUMN_PSE_SELECT_COLUMN_WIDTH,
} from 'constants/styles';
import { ERegistryType } from 'enums/General';
import { EViewMode } from 'enums/View';
import {
  getContactInfoEditId,
  getContactInfoFaxEditId,
  getContactInfoInitialEditKey,
  getContactInfoPhoneEditId,
  getContractsEditId,
  getContractsInitialEditKey,
  getMiscInfoEditId,
  getMiscInfoInitialEditKey,
} from 'hooks/useMarketEditColumns/helpers';
import usePrevious from 'hooks/usePrevious';
import { IEntityInfo, IRegistryEntity } from 'interfaces/Entity';
import {
  IContactInfo,
  IContract,
  IEnergyProductInfo,
  IMiscInfo,
  IPseContactInfo,
} from 'interfaces/General';
import { IViewDataTableColumn } from 'interfaces/View';
import { ChangeEvent, useCallback, useMemo } from 'react';
import { IDetailMarketSegment } from 'reduxes/Detail/types';
import { TDetailValidations } from 'types/Detail';
import { getSplitEditInfoKey, validateEditInfo } from 'utils/detail';
import {
  entityInfoToUid,
  pseEntityInfoEqual,
  registryEntityToEntityInfoOption,
} from 'utils/entity';
import {
  contactInfosEqual,
  contractsEqual,
  energyProductInfoEqual,
  energyProductInfoToUid,
  eventToStringOrNull,
  isEmptyValue,
  miscInfosEqual,
  selectOptionLabelFilter,
} from 'utils/general';
import {
  getColumnContactInfoEditRender,
  getColumnContactInfoRender,
  getColumnContractsEditRender,
  getColumnMiscInfoEditRender,
  getColumnRender,
  getColumnSelectRender,
} from 'utils/views';

const ENERGY_PRODUCT_OPTIONS = [...GENERATION_ENERGY_PRODUCT_OPTIONS]
  .concat(...TRANSMISSION_ENERGY_PRODUCT_OPTIONS)
  .concat(...LOAD_ENERGY_PRODUCT_OPTIONS);

const getPseContactInfoColumns = (
  isUnconstrained: boolean,
): IViewDataTableColumn<IPseContactInfo>[] => [
  {
    dataIndex: 'hr24_contact_name',
    render: getColumnRender(isUnconstrained),
    title: 'Contact',
  },
  {
    dataIndex: 'hr24_contact_phone_nbr',
    render: getColumnRender(isUnconstrained),
    title: 'Phone',
  },
  {
    dataIndex: 'hr24_contact_fax_nbr',
    render: getColumnRender(isUnconstrained),
    title: 'Fax',
  },
];

export const getColumnDetailMarketSegmentPseRender =
  (isUnconstrained: boolean) =>
  (value: unknown, record: IDetailMarketSegment): JSX.Element => {
    const entityInfo: IEntityInfo | null = value as IEntityInfo | null;
    const pseName: string =
      entityInfo === null || entityInfo.entity_code === null
        ? ''
        : entityInfo.entity_code;

    if (isUnconstrained) {
      return <>{pseName}</>;
    }

    return (
      <PopoverDataTable<IViewDataTableColumn<IPseContactInfo>, IPseContactInfo>
        columns={getPseContactInfoColumns(isUnconstrained)}
        data={
          isEmptyValue(record.pse_contact_info)
            ? []
            : [record.pse_contact_info!]
        }
        pagination={false}
        title={`${pseName} 24 Hour Contact Info`}
      >
        {/*
         * We need to wrap the ContainedText component with a div so that the
         * Popover table has a HTML element to listen to for triggering the
         * visibility of the data table. ContainedText is wrapped by the
         * Tooltip component which does not provide this wrapper HTML element.
         */}
        <div>
          <ContainedText maxWidth='100%' text={pseName} />
        </div>
      </PopoverDataTable>
    );
  };

const pseSelectRender = getColumnSelectRender<
  IEntityInfo | null,
  IDetailMarketSegment
>(
  '100%',
  undefined,
  (value: (IEntityInfo | null)[] | (IEntityInfo | null) | undefined): string =>
    isEmptyValue(value) ? '' : (value as IEntityInfo).entity_code,
);

const energyProductSelectRender = getColumnSelectRender<
  IEnergyProductInfo | null,
  IDetailMarketSegment
>(
  '100%',
  undefined,
  (
    value:
      | (IEnergyProductInfo | null)[]
      | (IEnergyProductInfo | null)
      | undefined,
  ): string =>
    isEmptyValue(value) ? '' : (value as IEnergyProductInfo).product_name,
);

const entityToPSEOption = registryEntityToEntityInfoOption(false, false);

const getContactInfoTitle = (record: IDetailMarketSegment) =>
  `Market Path ${record.market_segment_id} Contact Info`;

const useMarketEditColumns = (
  detailValidations: TDetailValidations,
  focusKey: string | null,
  initialMarketSegments: IDetailMarketSegment[] | undefined,
  isDetailDeleted: boolean,
  isDetailUpdating: boolean,
  isDetailValidating: boolean,
  isUnconstrained: boolean,
  onChange: (editMarketPathInformation: IEditMarketPathInformation) => void,
  previousIsDetailDeleted: boolean | undefined,
  previousIsDetailUpdating: boolean | undefined,
  previousIsDetailValidating: boolean | undefined,
  previousDetailValidations: Record<string, boolean> | undefined,
  registryEntities: IRegistryEntity[],
  setValidation: (id: string, isValid: boolean) => void,
  viewMode: EViewMode,
  isDisabled?: boolean,
) => {
  const hasDetailChanged: boolean =
    isDetailDeleted !== previousIsDetailDeleted ||
    isDetailUpdating !== previousIsDetailUpdating;
  const hasDetailValidated: boolean =
    isDetailValidating !== previousIsDetailValidating ||
    detailValidations !== previousDetailValidations;
  const isDetailDisabled: boolean = isDetailDeleted || isDetailUpdating;

  const getMarketSegmentForEditInfoKey = useCallback(
    (editInfoKey: string): IDetailMarketSegment | undefined => {
      if (initialMarketSegments !== undefined) {
        const { primaryId: market_segment_id } =
          getSplitEditInfoKey(editInfoKey);

        return initialMarketSegments.find(
          (marketSegment: IDetailMarketSegment): boolean =>
            marketSegment.market_segment_id === market_segment_id,
        );
      }

      return undefined;
    },
    [initialMarketSegments],
  );

  const getPseOptions = useCallback(
    async () =>
      registryEntities
        .filter(
          (registryEntity: IRegistryEntity): boolean =>
            registryEntity['Registry Type'] === ERegistryType.PSE,
        )
        .map(entityToPSEOption),
    [registryEntities],
  );

  const previousGetPseOptions = usePrevious(getPseOptions);

  const getInitialPseSelectValue = useCallback(
    (record: IDetailMarketSegment): IEntityInfo | null | undefined => {
      if (initialMarketSegments !== undefined) {
        const foundMarketSegment: IDetailMarketSegment | undefined =
          initialMarketSegments.find(
            (marketSegment: IDetailMarketSegment): boolean =>
              marketSegment.market_segment_id === record.market_segment_id,
          );

        return foundMarketSegment === undefined ? null : foundMarketSegment.pse;
      }

      return undefined;
    },
    [initialMarketSegments],
  );

  const pseSelectOnChange = useCallback(
    (value: IEntityInfo | null | undefined, record: IDetailMarketSegment) => {
      onChange({
        editMarketSegmentMap: {
          [record.market_segment_id]: {
            pse: value === undefined ? null : value,
          },
        },
      });
    },
    [onChange],
  );

  const getEnergyProductOptions = useCallback(
    async () => ENERGY_PRODUCT_OPTIONS,
    [],
  );

  const getInitialEnergyProductSelectValue = useCallback(
    (record: IDetailMarketSegment): IEnergyProductInfo | null | undefined => {
      if (initialMarketSegments !== undefined) {
        const foundMarketSegment: IDetailMarketSegment | undefined =
          initialMarketSegments.find(
            (marketSegment: IDetailMarketSegment): boolean =>
              marketSegment.market_segment_id === record.market_segment_id,
          );

        return foundMarketSegment === undefined
          ? null
          : foundMarketSegment.energy_product_ref;
      }

      return undefined;
    },
    [initialMarketSegments],
  );

  const energyProductSelectOnChange = useCallback(
    (
      value: IEnergyProductInfo | null | undefined,
      record: IDetailMarketSegment,
    ) => {
      onChange({
        editMarketSegmentMap: {
          [record.market_segment_id]: {
            energy_product_ref: value === undefined ? null : value,
          },
        },
      });
    },
    [onChange],
  );

  const contractsEditRender = useMemo(
    () => getColumnContractsEditRender<IDetailMarketSegment>(hasDetailChanged),
    [hasDetailChanged],
  );

  const getInitialContractData = useCallback(
    (record: IDetailMarketSegment): IContract[] | null | undefined => {
      if (initialMarketSegments !== undefined) {
        const initialMarketSegment: IDetailMarketSegment | undefined =
          initialMarketSegments.find(
            (marketSegment: IDetailMarketSegment): boolean =>
              marketSegment.market_segment_id === record.market_segment_id,
          );

        return initialMarketSegment === undefined
          ? null
          : initialMarketSegment.contracts;
      }

      return undefined;
    },
    [initialMarketSegments],
  );

  const contractOnAdd = useCallback(
    (value: unknown, contractRecord: IContract) => {
      const { primaryId: market_segment_id } = getSplitEditInfoKey(
        contractRecord.key,
      );

      onChange({
        editMarketSegmentMap: {
          [market_segment_id]: {
            editContracts: {
              addAfterContract: contractRecord.key,
            },
          },
        },
      });
    },
    [onChange],
  );

  const contractOnRemove = useCallback(
    (contractRecord: IContract) => {
      const { primaryId: market_segment_id } = getSplitEditInfoKey(
        contractRecord.key,
      );

      onChange({
        editMarketSegmentMap: {
          [market_segment_id]: {
            editContracts: {
              removeContract: contractRecord.key,
            },
          },
        },
      });
    },
    [onChange],
  );

  const getInitialContractInputValue = useCallback(
    (contractRecord: IContract): string | null => {
      const initialMarketSegment: IDetailMarketSegment | undefined =
        getMarketSegmentForEditInfoKey(contractRecord.key);

      if (
        initialMarketSegment === undefined ||
        initialMarketSegment.contracts === null
      ) {
        return null;
      }

      const initialContract: IContract | undefined =
        initialMarketSegment.contracts.find(
          (contract: IContract): boolean => contract.key === contractRecord.key,
        );

      return initialContract === undefined ? null : initialContract.contract;
    },
    [getMarketSegmentForEditInfoKey],
  );

  const contractInputOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, contractRecord: IContract) => {
      const { primaryId: market_segment_id } = getSplitEditInfoKey(
        contractRecord.key,
      );

      onChange({
        editMarketSegmentMap: {
          [market_segment_id]: {
            editContracts: {
              editContractMap: {
                [contractRecord.key]: { contract: eventToStringOrNull(event) },
              },
            },
          },
        },
      });
    },
    [onChange],
  );

  const contactInfoEditRender = useMemo(
    () =>
      getColumnContactInfoEditRender<IDetailMarketSegment>(
        hasDetailChanged || hasDetailValidated,
      ),
    [hasDetailChanged, hasDetailValidated],
  );

  const getInitialContactData = useCallback(
    (record: IDetailMarketSegment): IContactInfo[] | null | undefined => {
      if (initialMarketSegments !== undefined) {
        const foundMarketSegment: IDetailMarketSegment | undefined =
          initialMarketSegments.find(
            (marketSegment: IDetailMarketSegment): boolean =>
              marketSegment.market_segment_id === record.market_segment_id,
          );

        return foundMarketSegment === undefined
          ? null
          : foundMarketSegment.contactInfos;
      }

      return undefined;
    },
    [initialMarketSegments],
  );

  const contactOnRemove = useCallback(
    (contactInfoRecord: IContactInfo) => {
      const { primaryId: market_segment_id } = getSplitEditInfoKey(
        contactInfoRecord.key,
      );

      onChange({
        editMarketSegmentMap: {
          [market_segment_id]: {
            editContactInfos: { editContactInfo: null },
          },
        },
      });
    },
    [onChange],
  );

  const getInitialContactInputValue = useCallback(
    (contactInfoRecord: IContactInfo): string | null => {
      const initialMarketSegment: IDetailMarketSegment | undefined =
        getMarketSegmentForEditInfoKey(contactInfoRecord.key);

      if (
        initialMarketSegment === undefined ||
        initialMarketSegment.contactInfos === null
      ) {
        return null;
      }

      const initialContactInfo: IContactInfo | undefined =
        initialMarketSegment.contactInfos[0];

      return initialContactInfo === undefined
        ? null
        : initialContactInfo.contact;
    },
    [getMarketSegmentForEditInfoKey],
  );

  const contactInputOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, contactInfoRecord: IContactInfo) => {
      const { primaryId: market_segment_id } = getSplitEditInfoKey(
        contactInfoRecord.key,
      );

      onChange({
        editMarketSegmentMap: {
          [market_segment_id]: {
            editContactInfos: {
              editContactInfo: { contact: eventToStringOrNull(event) },
            },
          },
        },
      });
    },
    [onChange],
  );

  const getInitialPhoneInputPhoneNumberValue = useCallback(
    (contactInfoRecord: IContactInfo): string | null => {
      const initialMarketSegment: IDetailMarketSegment | undefined =
        getMarketSegmentForEditInfoKey(contactInfoRecord.key);

      if (
        initialMarketSegment === undefined ||
        initialMarketSegment.contactInfos === null
      ) {
        return null;
      }

      const initialContactInfo: IContactInfo | undefined =
        initialMarketSegment.contactInfos[0];

      return initialContactInfo === undefined ? null : initialContactInfo.phone;
    },
    [getMarketSegmentForEditInfoKey],
  );

  const phoneInputOnChange = useCallback(
    (value: string | null, contactInfoRecord: IContactInfo) => {
      const { primaryId: market_segment_id } = getSplitEditInfoKey(
        contactInfoRecord.key,
      );

      onChange({
        editMarketSegmentMap: {
          [market_segment_id]: {
            editContactInfos: { editContactInfo: { phone: value } },
          },
        },
      });
    },
    [onChange],
  );

  const getInitialFaxInputPhoneNumberValue = useCallback(
    (contactInfoRecord: IContactInfo): string | null => {
      const initialMarketSegment: IDetailMarketSegment | undefined =
        getMarketSegmentForEditInfoKey(contactInfoRecord.key);

      if (
        initialMarketSegment === undefined ||
        initialMarketSegment.contactInfos === null
      ) {
        return null;
      }

      const initialContactInfo: IContactInfo | undefined =
        initialMarketSegment.contactInfos[0];

      return initialContactInfo === undefined ? null : initialContactInfo.fax;
    },
    [getMarketSegmentForEditInfoKey],
  );

  const faxInputOnChange = useCallback(
    (value: string | null, contactInfoRecord: IContactInfo) => {
      const { primaryId: market_segment_id } = getSplitEditInfoKey(
        contactInfoRecord.key,
      );

      onChange({
        editMarketSegmentMap: {
          [market_segment_id]: {
            editContactInfos: { editContactInfo: { fax: value } },
          },
        },
      });
    },
    [onChange],
  );

  const miscInfoEditRender = getColumnMiscInfoEditRender<IDetailMarketSegment>(
    isDisabled,
    hasDetailChanged,
  );

  const getInitialMiscInfoData = useCallback(
    (record: IDetailMarketSegment): IMiscInfo[] | null | undefined => {
      if (initialMarketSegments !== undefined) {
        const initialMarketSegment: IDetailMarketSegment | undefined =
          initialMarketSegments.find(
            (marketSegment: IDetailMarketSegment): boolean =>
              marketSegment.market_segment_id === record.market_segment_id,
          );

        return initialMarketSegment === undefined
          ? null
          : initialMarketSegment.misc_infos;
      }

      return undefined;
    },
    [initialMarketSegments],
  );

  const miscInfoOnAdd = useCallback(
    (value: unknown, miscInfoRecord: IMiscInfo) => {
      const { primaryId: market_segment_id } = getSplitEditInfoKey(
        miscInfoRecord.key,
      );

      onChange({
        editMarketSegmentMap: {
          [market_segment_id]: {
            editMiscInfos: {
              addAfterMiscInfo: miscInfoRecord.key,
            },
          },
        },
      });
    },
    [onChange],
  );

  const miscInfoOnRemove = useCallback(
    (miscInfoRecord: IMiscInfo) => {
      const { primaryId: market_segment_id } = getSplitEditInfoKey(
        miscInfoRecord.key,
      );

      onChange({
        editMarketSegmentMap: {
          [market_segment_id]: {
            editMiscInfos: {
              removeMiscInfo: miscInfoRecord.key,
            },
          },
        },
      });
    },
    [onChange],
  );

  const getInitialTokenInputValue = useCallback(
    (miscInfoRecord: IMiscInfo): string | null => {
      const initialMarketSegment: IDetailMarketSegment | undefined =
        getMarketSegmentForEditInfoKey(miscInfoRecord.key);

      if (
        initialMarketSegment === undefined ||
        initialMarketSegment.misc_infos === null
      ) {
        return null;
      }

      const initialMiscInfo: IMiscInfo | undefined =
        initialMarketSegment.misc_infos.find(
          (miscInfo: IMiscInfo): boolean => miscInfo.key === miscInfoRecord.key,
        );

      return initialMiscInfo === undefined ? null : initialMiscInfo.token;
    },
    [getMarketSegmentForEditInfoKey],
  );

  const tokenInputOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, miscInfoRecord: IMiscInfo) => {
      const { primaryId: market_segment_id } = getSplitEditInfoKey(
        miscInfoRecord.key,
      );

      onChange({
        editMarketSegmentMap: {
          [market_segment_id]: {
            editMiscInfos: {
              editMiscInfoMap: {
                [miscInfoRecord.key]: { token: eventToStringOrNull(event) },
              },
            },
          },
        },
      });
    },
    [onChange],
  );

  const getInitialValueInputValue = useCallback(
    (miscInfoRecord: IMiscInfo): string | null => {
      const initialMarketSegment: IDetailMarketSegment | undefined =
        getMarketSegmentForEditInfoKey(miscInfoRecord.key);

      if (
        initialMarketSegment === undefined ||
        initialMarketSegment.misc_infos === null
      ) {
        return null;
      }

      const initialMiscInfo: IMiscInfo | undefined =
        initialMarketSegment.misc_infos.find(
          (miscInfo: IMiscInfo): boolean => miscInfo.key === miscInfoRecord.key,
        );

      return initialMiscInfo === undefined ? null : initialMiscInfo.value;
    },
    [getMarketSegmentForEditInfoKey],
  );

  const valueInputOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, miscInfoRecord: IMiscInfo) => {
      const { primaryId: market_segment_id } = getSplitEditInfoKey(
        miscInfoRecord.key,
      );

      onChange({
        editMarketSegmentMap: {
          [market_segment_id]: {
            editMiscInfos: {
              editMiscInfoMap: {
                [miscInfoRecord.key]: { value: eventToStringOrNull(event) },
              },
            },
          },
        },
      });
    },
    [onChange],
  );

  const getIsPhoneValid = useCallback(
    (record: IContactInfo) => (isValid: boolean) => {
      setValidation(getContactInfoPhoneEditId(record), isValid);
    },
    [setValidation],
  );

  const getIsFaxValid = useCallback(
    (record: IContactInfo) => (isValid: boolean) => {
      setValidation(getContactInfoFaxEditId(record), isValid);
    },
    [setValidation],
  );

  const getIsContactInfoValid = useCallback(
    (record: IDetailMarketSegment): boolean =>
      validateEditInfo(getContactInfoEditId(record), detailValidations),
    [detailValidations],
  );

  return useMemo(() => {
    const pseOptionsChanged: boolean = getPseOptions !== previousGetPseOptions;

    return [
      {
        dataIndex: 'market_segment_id',
        render: getColumnRender(isUnconstrained),
        title: 'MID',
        width: VIEW_DATA_TABLE_COLUMN_ID_COLUMN_WIDTH,
      },

      {
        dataIndex: 'pse',
        render:
          viewMode === EViewMode.EditETagCorrection
            ? getColumnDetailMarketSegmentPseRender(isUnconstrained)
            : pseSelectRender({
                allowClear: true,
                equalityChecker: pseEntityInfoEqual,
                filter: selectOptionLabelFilter,
                getInitialValue: getInitialPseSelectValue,
                getOptions: getPseOptions,
                isDisabled: isDetailDisabled,
                onChange: pseSelectOnChange,
                showSearch: true,
                valueToUid: entityInfoToUid,
              }),
        shouldCellUpdate:
          viewMode === EViewMode.EditETagCorrection
            ? undefined
            : (
                record: IDetailMarketSegment,
                previousRecord: IDetailMarketSegment,
              ): boolean =>
                hasDetailChanged ||
                pseOptionsChanged ||
                (record.pse === null
                  ? previousRecord.pse !== null
                  : previousRecord.pse === null
                  ? true
                  : !(
                      record.pse.tagging_entity_id ===
                        previousRecord.pse.tagging_entity_id &&
                      record.pse.entity_type === previousRecord.pse.entity_type
                    )),
        title: 'PSE',
        width: VIEW_DATA_TABLE_COLUMN_PSE_SELECT_COLUMN_WIDTH,
      },
      {
        dataIndex: 'energy_product_ref',
        render: energyProductSelectRender({
          allowClear: true,
          equalityChecker: energyProductInfoEqual,
          filter: selectOptionLabelFilter,
          getInitialValue: getInitialEnergyProductSelectValue,
          getOptions: getEnergyProductOptions,
          isDisabled: isDetailDisabled,
          onChange: energyProductSelectOnChange,
          showSearch: true,
          valueToUid: energyProductInfoToUid,
        }),
        shouldCellUpdate: (
          record: IDetailMarketSegment,
          previousRecord: IDetailMarketSegment,
        ): boolean =>
          hasDetailChanged ||
          (record.energy_product_ref === null
            ? previousRecord.energy_product_ref !== null
            : previousRecord.energy_product_ref === null
            ? true
            : record.energy_product_ref.product_ref !==
              previousRecord.energy_product_ref.product_ref),
        title: 'Product',
        width: VIEW_DATA_TABLE_COLUMN_PRODUCT_SELECT_COLUMN_WIDTH,
      },
      {
        dataIndex: 'contracts',
        render: contractsEditRender({
          contractInputColumnConfig: {
            getInitialValue: getInitialContractInputValue,
            getKey: (contractRecord: IContract): string => contractRecord.key,
            isDisabled: isDetailDisabled,
            onChange: contractInputOnChange,
            shouldFocusOn: (contractRecord: IContract): boolean =>
              contractRecord.key === focusKey,
          },
          equalityChecker: contractsEqual,
          getId: getContractsEditId,
          getInitialData: getInitialContractData,
          getInitialEditKey: getContractsInitialEditKey,
          getTableTitle: (record: IDetailMarketSegment): string =>
            `Edit Market Segment ${record.market_segment_id} Contracts`,
          onAdd: contractOnAdd,
          onRemove: contractOnRemove,
        }),
        shouldCellUpdate: (
          record: IDetailMarketSegment,
          previousRecord: IDetailMarketSegment,
        ): boolean =>
          hasDetailChanged ||
          (record.contracts === null
            ? previousRecord.contracts !== null
            : previousRecord.contracts === null
            ? true
            : !contractsEqual(record.contracts, previousRecord.contracts)),
        title: 'Contracts',
        width: VIEW_DATA_TABLE_COLUMN_CONTRACTS_COLUMN_WIDTH,
      },
      {
        dataIndex: 'contactInfos',
        render:
          viewMode === EViewMode.EditETagCorrection
            ? getColumnContactInfoRender(
                isUnconstrained,
                undefined,
                getContactInfoTitle,
              )
            : contactInfoEditRender({
                contactInputColumnConfig: {
                  getInitialValue: getInitialContactInputValue,
                  getKey: (contactInfoRecord: IContactInfo): string =>
                    contactInfoRecord.key,
                  isDisabled: isDetailDisabled,
                  onChange: contactInputOnChange,
                  shouldFocusOn: (contactInfoRecord: IContactInfo): boolean =>
                    contactInfoRecord.key === focusKey,
                },
                equalityChecker: contactInfosEqual,
                faxInputPhoneNumberColumnConfig: {
                  getInitialValue: getInitialFaxInputPhoneNumberValue,
                  getIsValid: getIsFaxValid,
                  isDisabled: isDetailDisabled,
                  onChange: faxInputOnChange,
                },
                getId: getContactInfoEditId,
                getInitialData: getInitialContactData,
                getInitialEditKey: getContactInfoInitialEditKey,
                getIsValid: getIsContactInfoValid,
                getTableTitle: (record: IDetailMarketSegment): string =>
                  `Edit Market Segment ${record.market_segment_id} Contact Info`,
                showErrorMessageWhenInvalid: isDetailValidating,
                onRemove: contactOnRemove,
                phoneInputPhoneNumberColumnConfig: {
                  getInitialValue: getInitialPhoneInputPhoneNumberValue,
                  getIsValid: getIsPhoneValid,
                  isDisabled: isDetailDisabled,
                  onChange: phoneInputOnChange,
                },
              }),
        shouldCellUpdate:
          viewMode === EViewMode.EditETagCorrection
            ? undefined
            : (
                record: IDetailMarketSegment,
                previousRecord: IDetailMarketSegment,
              ): boolean =>
                hasDetailChanged ||
                hasDetailValidated ||
                (record.contactInfos === null
                  ? previousRecord.contactInfos !== null
                  : previousRecord.contactInfos === null
                  ? true
                  : !contactInfosEqual(
                      record.contactInfos,
                      previousRecord.contactInfos,
                    )),
        title: 'Contact',
        width: VIEW_DATA_TABLE_COLUMN_CONTACT_INFO_COLUMN_WIDTH,
      },
      {
        dataIndex: 'misc_infos',
        render: miscInfoEditRender({
          editButtonWidth:
            viewMode === EViewMode.EditETagCorrection
              ? undefined
              : `${
                  VIEW_DATA_TABLE_COLUMN_MISC_INFO_WIDTH_VALUE -
                  ICON_BUTTON_SIZE_VALUE
                }px`,
          equalityChecker: miscInfosEqual,
          getId: getMiscInfoEditId,
          getInitialData: getInitialMiscInfoData,
          getInitialEditKey: getMiscInfoInitialEditKey,
          getTableTitle: (record: IDetailMarketSegment): string =>
            `Edit Market Segment ${record.market_segment_id} Misc Info`,
          onAdd: miscInfoOnAdd,
          onRemove: miscInfoOnRemove,
          tokenInputColumnConfig: {
            getInitialValue: getInitialTokenInputValue,
            getKey: (miscInfoRecord: IMiscInfo): string => miscInfoRecord.key,
            isDisabled: isDetailDisabled,
            onChange: tokenInputOnChange,
            shouldFocusOn: (miscInfoRecord: IMiscInfo): boolean =>
              miscInfoRecord.key === focusKey,
          },
          valueInputColumnConfig: {
            getInitialValue: getInitialValueInputValue,
            getKey: (miscInfoRecord: IMiscInfo): string => miscInfoRecord.key,
            isDisabled: isDetailDisabled,
            onChange: valueInputOnChange,
          },
        }),
        shouldCellUpdate: (
          record: IDetailMarketSegment,
          previousRecord: IDetailMarketSegment,
        ): boolean =>
          hasDetailChanged ||
          (record.misc_infos === null
            ? previousRecord.misc_infos !== null
            : previousRecord.misc_infos === null
            ? true
            : !miscInfosEqual(record.misc_infos, previousRecord.misc_infos)),
        title: 'Misc Info',
        width: VIEW_DATA_TABLE_COLUMN_MISC_INFO_WIDTH,
      },
    ];
  }, [
    contactInfoEditRender,
    contractsEditRender,
    contactInputOnChange,
    contactOnRemove,
    contractInputOnChange,
    contractOnAdd,
    contractOnRemove,
    energyProductSelectOnChange,
    faxInputOnChange,
    focusKey,
    getEnergyProductOptions,
    getInitialContactData,
    getInitialContactInputValue,
    getInitialContractData,
    getInitialContractInputValue,
    getInitialPseSelectValue,
    getInitialEnergyProductSelectValue,
    getInitialFaxInputPhoneNumberValue,
    getInitialMiscInfoData,
    getInitialPhoneInputPhoneNumberValue,
    getInitialTokenInputValue,
    getInitialValueInputValue,
    getIsContactInfoValid,
    getIsFaxValid,
    getIsPhoneValid,
    getPseOptions,
    hasDetailChanged,
    hasDetailValidated,
    isDetailDisabled,
    isDetailValidating,
    isUnconstrained,
    miscInfoEditRender,
    miscInfoOnAdd,
    miscInfoOnRemove,
    phoneInputOnChange,
    previousGetPseOptions,
    pseSelectOnChange,
    tokenInputOnChange,
    valueInputOnChange,
    viewMode,
  ]);
};

export default useMarketEditColumns;
