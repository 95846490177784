import { EMPTY_CELLS_SELECTION } from 'constants/Component';
import {
  IDataGridPosition,
  IDataGridSelectionContext,
} from 'interfaces/Component';
import { IuseStateCallback } from 'interfaces/General';
import { Context, ReactNode, useState } from 'react';
import { TSelectedCellsMap } from 'types/Component';

interface IDataGridSelectionProviderProps {
  children: ReactNode;
  DataGridSelectionContext: Context<IDataGridSelectionContext>;
}

const DataGridSelectionProvider = ({
  children,
  DataGridSelectionContext,
}: IDataGridSelectionProviderProps) => {
  const [dataGridHasFocus, setDataGridHasFocus] = useState<boolean>(false);
  const [onExitEditCallback, setOnExitEditCallback] = useState<
    IuseStateCallback<(key?: string) => void> | undefined
  >(undefined);
  const [selectedCells, setSelectedCells] = useState<TSelectedCellsMap>(
    EMPTY_CELLS_SELECTION,
  );
  const [selectedPrimaryCell, setSelectedPrimaryCell] = useState<
    IDataGridPosition | undefined
  >(undefined);
  const [selectedSourceCells, setSelectedSourceCells] =
    useState<TSelectedCellsMap>(EMPTY_CELLS_SELECTION);

  return (
    <DataGridSelectionContext.Provider
      value={{
        dataGridHasFocus,
        onExitEditCallback,
        selectedCells,
        selectedPrimaryCell,
        selectedSourceCells,
        setDataGridHasFocus,
        setOnExitEditCallback,
        setSelectedCells,
        setSelectedPrimaryCell,
        setSelectedSourceCells,
      }}
    >
      {children}
    </DataGridSelectionContext.Provider>
  );
};

export default DataGridSelectionProvider;
