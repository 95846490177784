import ContainedText from 'components/molecules/ContainedText/ContainedText';
import Connector from 'components/organisms/PathDiagramView/Connector';
import {
  INDICATOR_RIGHT_OFFSET_VALUE,
  NODE_LABEL_MAX_WIDTH_VALUE,
  SHORT_CONNECTOR_WIDTH_VALUE,
  TRANSMISSION_BLOCK_WIDTH_VALUE,
} from 'components/organisms/PathDiagramView/constants';
import Node from 'components/organisms/PathDiagramView/Node';
import {
  ENodePosition,
  ITransmissionMW,
} from 'components/organisms/PathDiagramView/types';
import { IETagTransmissionAllocation } from 'interfaces/ETag';
import styled from 'styled-components';
import { isEmptyValue } from 'utils/general';

const Layout = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  position: relative;
`;

const Connectors = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const LossMW = styled.div`
  position: absolute;
  right: 4px;
  top: calc(100% + 4px);
  white-space: nowrap;
`;

interface ILossAccountingMethodNamesProps {
  rightOffset: number;
}

const LossAccounting = styled.div<ILossAccountingMethodNamesProps>`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  position: absolute;
  right: ${(props) => props.rightOffset}px;
  top: 97%;
`;

interface IProps {
  leftNodeLabel?: string | null;
  lossAccountingMethodNames?: string[];
  nodeSize: number;
  oasisInfos?: IETagTransmissionAllocation[];
  rightNodeLabel?: string | null;
  transmissionMW?: ITransmissionMW;
}

const TransmissionDiagram = (props: IProps): JSX.Element => {
  const {
    leftNodeLabel,
    lossAccountingMethodNames,
    nodeSize,
    oasisInfos,
    rightNodeLabel,
    transmissionMW,
  } = props;
  const indicatorIndex: number =
    oasisInfos === undefined ? 0 : Math.floor(oasisInfos.length / 2);

  return (
    <Layout>
      <Connector connectorWidth={SHORT_CONNECTOR_WIDTH_VALUE} />
      <Node
        nodeLabel={leftNodeLabel}
        nodeLabelMaxWidth={
          isEmptyValue(rightNodeLabel)
            ? 2 * NODE_LABEL_MAX_WIDTH_VALUE
            : NODE_LABEL_MAX_WIDTH_VALUE
        }
        nodePosition={ENodePosition.Left}
        nodeSize={nodeSize}
      />
      <Connectors>
        {oasisInfos === undefined
          ? null
          : oasisInfos.map(
              (
                eTagTransmissionAllocation: IETagTransmissionAllocation,
                index: number,
              ): JSX.Element => (
                <Connector
                  connectorWidth={
                    TRANSMISSION_BLOCK_WIDTH_VALUE -
                    2 * (SHORT_CONNECTOR_WIDTH_VALUE + nodeSize)
                  }
                  includeIndicator={index === indicatorIndex}
                  key={eTagTransmissionAllocation.trans_alloc_id}
                  transmissionAllocation={eTagTransmissionAllocation}
                />
              ),
            )}
        {transmissionMW === undefined ||
        transmissionMW.lossMW === null ? null : (
          <LossMW>{transmissionMW.lossMW} MW</LossMW>
        )}
      </Connectors>
      <Node
        nodeLabel={rightNodeLabel}
        nodeLabelMaxWidth={
          isEmptyValue(leftNodeLabel)
            ? 2 * NODE_LABEL_MAX_WIDTH_VALUE
            : NODE_LABEL_MAX_WIDTH_VALUE
        }
        nodePosition={ENodePosition.Right}
        nodeSize={nodeSize}
      />
      <Connector connectorWidth={SHORT_CONNECTOR_WIDTH_VALUE} />
      <LossAccounting
        rightOffset={
          SHORT_CONNECTOR_WIDTH_VALUE + nodeSize + INDICATOR_RIGHT_OFFSET_VALUE
        }
      >
        {lossAccountingMethodNames === undefined
          ? null
          : lossAccountingMethodNames.map(
              (value: string, index: number): JSX.Element => (
                <ContainedText
                  key={`id_${index}`}
                  maxWidth={`${NODE_LABEL_MAX_WIDTH_VALUE}px`}
                  text={value}
                />
              ),
            )}
        {transmissionMW === undefined ||
        transmissionMW.podMW === null ||
        transmissionMW.totalMW === null ? null : (
          <div>
            {transmissionMW.podMW} MW / {transmissionMW.totalMW} MW
          </div>
        )}
      </LossAccounting>
    </Layout>
  );
};

export default TransmissionDiagram;
