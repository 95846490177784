import { EAlertMessageAttribute } from 'enums/Alert';
import { TTimeZone } from 'types/DateTime';
import { isEmptyValue } from 'utils/general';
import { toFormattedDateTimeString } from 'utils/time';

export const transformAlertMessage = (
  message: string | null | undefined,
  alertMessageAttribute: EAlertMessageAttribute,
  timeZone: TTimeZone,
): string => {
  if (
    (alertMessageAttribute === EAlertMessageAttribute.Start ||
      alertMessageAttribute === EAlertMessageAttribute.End) &&
    !isEmptyValue(message)
  ) {
    return toFormattedDateTimeString(message!, timeZone);
  }

  if (message === null || message === undefined) {
    return '';
  }

  return message;
};
