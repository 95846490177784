import { IETagCheckoutReportDataSet } from 'interfaces/ETag';
import { IViewDataTableColumn } from 'interfaces/View';
import { TTimeZone } from 'types/DateTime';
import {
  getColumnRender,
  getDateTimeColumnRender,
  getReportColumnRender,
} from 'utils/views';

export const rowKey = (record: IETagCheckoutReportDataSet): string =>
  `${record.file.fileUrl}-${record.requested_by}-${record.request_time}`;

export const getReportReviewColumns = (
  timeZone: TTimeZone,
  isUnconstrained: boolean,
): IViewDataTableColumn<IETagCheckoutReportDataSet>[] => {
  const reportColumns = [
    {
      dataIndex: 'file',
      render: getReportColumnRender(),
      title: 'File Name',
      width: '260px',
    },
    {
      dataIndex: 'requested_by',
      render: getColumnRender(isUnconstrained),
      title: 'Executed By',
      width: '80px',
    },
    {
      dataIndex: 'request_time',
      render: getDateTimeColumnRender(timeZone, isUnconstrained),
      title: 'Executed On',
      width: '65px',
    },
  ];

  return reportColumns;
};
