import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import Spinner from 'components/atoms/Spinner/Spinner';
import {
  CURRENT_LEVEL_COLOUR,
  CURRENT_LEVEL_WITH_RELIABILITY_LIMIT_COLOUR,
  CURRENT_LEVEL_WITH_RELOAD_COLOUR,
  CURRENT_TIME_COLOR,
  RANGE_HIGHLIGHT_BLUE_COLOR,
  TRANSMISSION_LIMIT_COLOR,
  TRANSMISSION_LIMIT_COLOUR,
} from 'components/organisms/ProfileGraphView/constants';
import {
  am4themes_dark,
  getCurrentLevelHtml,
  getCurrentLevelSeriesKey,
  getTransmissionLimitHtml,
  getTransmissionLimitSeriesKey,
  updateCurrentTimeIndicator,
  updateUserTimeIndicator,
} from 'components/organisms/ProfileGraphView/helpers';
import LegendKey from 'components/organisms/ProfileGraphView/LegendKey';
import {
  EBulletShape,
  IChartData,
  IHighlightRange,
} from 'components/organisms/ProfileGraphView/types';
import { STANDARD_SPACING_VALUE } from 'constants/styles';
import { DAY_FORMAT } from 'constants/time';
import { ETheme } from 'enums/Style';
import { useLayoutEffect, useRef } from 'react';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import styled from 'styled-components';
import { TTimeZone } from 'types/DateTime';
import { ZonedDateTime } from 'utils/zonedDateTime';

am4core.useTheme(am4themes_animated);

const LEGEND_HEIGHT = '28px';
const CURRENT_LEVEL_BULLET_RADIUS = 4;
const TRANSMISSION_LIMIT_BULLET_SIZE = 6;

const Layout = styled.div`
  height: 100%;
`;

const Legend = styled.div`
  display: flex;
  flex-direction: row;
  height: ${LEGEND_HEIGHT};
  justify-content: space-around;
`;

const Chart = styled.div`
  height: calc(100% - ${LEGEND_HEIGHT});
  width: 100%;
`;

interface IProps {
  chartData: IChartData[];
  chartStart: ZonedDateTime | undefined;
  chartStop: ZonedDateTime | undefined;
  eTagStart: ZonedDateTime | undefined;
  eTagStop: ZonedDateTime | undefined;
  highlightRanges: IHighlightRange[];
  isLoading: boolean;
  numberOfCurrentLevelSeries: number;
  numberOfTransmissionLimitSeries: number;
  setSelectedPlotTime: (selectedPlotTime: Date) => void;
  timeZone: TTimeZone;
  valueMax: number;
}

const ProfileGraph = (props: IProps): JSX.Element => {
  const { currentTheme } = useThemeSwitcher();
  const {
    chartData,
    chartStart,
    chartStop,
    eTagStart,
    eTagStop,
    highlightRanges,
    isLoading,
    numberOfCurrentLevelSeries,
    numberOfTransmissionLimitSeries,
    setSelectedPlotTime,
    timeZone,
    valueMax,
  } = props;
  const chartRef = useRef<am4charts.XYChart>();
  const currentTimeIndicatorTimeoutRef = useRef<number | undefined>();
  const userTimeIndicatorRef = useRef<am4core.Line | undefined>();
  const userSelectedTimeRef = useRef<Date | undefined>();
  const zoomOutButtonHitRef = useRef<boolean>(false);

  useLayoutEffect(
    () => {
      let chart: am4charts.XYChart | undefined = undefined;

      if (!isLoading) {
        const dayStart: ZonedDateTime | undefined = chartStart?.add(1, 'hours');
        const dayStop: ZonedDateTime | undefined = chartStop?.subtract(
          1,
          'hours',
        );
        let currentTimeLine: am4core.Line | undefined;
        let rangeFillOpacity: number;

        if (currentTheme === ETheme.Dark) {
          am4core.useTheme(am4themes_dark);
          rangeFillOpacity = 0.09;
        } else {
          am4core.unuseTheme(am4themes_dark);
          rangeFillOpacity = 0.03;
        }

        // Chart
        chart = am4core.create('chartDiv', am4charts.XYChart);
        chart.data = chartData;
        chart.dateFormatter.timezone = timeZone;
        chart.marginBottom = STANDARD_SPACING_VALUE;

        // Cursor
        chart.cursor = new am4charts.XYCursor();
        chart.cursor.lineY.disabled = true;
        chart.cursor.maxTooltipDistance = 10;

        // Axes
        const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.baseInterval = { count: 1, timeUnit: 'minute' };
        dateAxis.dateFormats.setKey('millisecond', 'HH:mm:ss');
        dateAxis.dateFormats.setKey('second', 'HH:mm:ss');
        dateAxis.dateFormats.setKey('minute', 'HH:mm');
        dateAxis.dateFormats.setKey('hour', 'HH:mm');
        dateAxis.max =
          chartStop === undefined ? undefined : chartStop.epochMillis();
        dateAxis.min =
          chartStart === undefined ? undefined : chartStart.epochMillis();
        dateAxis.periodChangeDateFormats.setKey('millisecond', 'HH:mm:ss');
        dateAxis.periodChangeDateFormats.setKey('second', 'HH:mm:ss');
        dateAxis.periodChangeDateFormats.setKey('minute', 'HH:mm');
        dateAxis.periodChangeDateFormats.setKey('hour', 'HH:mm');
        dateAxis.renderer.grid.template.location = 0;
        dateAxis.renderer.labels.template.location = 0.0000001;
        dateAxis.renderer.labels.template.rotation = 90;
        dateAxis.renderer.labels.template.verticalCenter = 'middle';
        dateAxis.renderer.labels.template.horizontalCenter = 'middle';
        dateAxis.renderer.minGridDistance = 30;
        dateAxis.strictMinMax = true;
        dateAxis.title.text = 'Date Time';
        dateAxis.tooltip!.disabled = true;

        const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;
        valueAxis.title.text = 'MW';
        valueAxis.tooltip!.disabled = true;

        // Adjust valueAxis 'step' by forcing larger grid distances for smaller
        // values. This ensures that the steps used for the valueAxis are nice
        // decimal values such as 0.5, 1.0 etc instead of values such as 0.601,
        // 1.002 etc.
        if (valueMax < 1) {
          valueAxis.renderer.minGridDistance = 140;
        } else if (valueMax < 2) {
          valueAxis.renderer.minGridDistance = 100;
        } else if (valueMax < 3) {
          valueAxis.renderer.minGridDistance = 60;
        }

        if (dayStart !== undefined && dayStop !== undefined) {
          // Ranges
          const range = dateAxis.axisRanges.create();
          range.axisFill.fill = RANGE_HIGHLIGHT_BLUE_COLOR;
          range.axisFill.fillOpacity = rangeFillOpacity;
          range.date = dayStart.asDate();
          range.endDate = dayStop.asDate();
          range.label.rotation = 0;
          range.label.text = dayStart.format(DAY_FORMAT);
          range.label.dy = 13;

          const endRange = dateAxis.axisRanges.create();
          endRange.date = range.endDate;
          endRange.label.rotation = range.label.rotation;
          endRange.label.text = dayStop.format(DAY_FORMAT);
          endRange.label.dy = range.label.dy;

          // Current Time Indicator
          currentTimeLine = chart.plotContainer.createChild(am4core.Line);
          currentTimeLine.isMeasured = false;
          currentTimeLine.stroke = CURRENT_TIME_COLOR;
        }

        // Ranges
        highlightRanges.forEach((highlightRange: IHighlightRange) => {
          const { color, opacity, start, stop } = highlightRange;
          const range = dateAxis.axisRanges.create();

          range.axisFill.fill = color;
          range.axisFill.fillOpacity = opacity;
          range.date = start.asDate();
          range.endDate = stop.asDate();
        });

        // Transmission Series
        for (let i: number = 0; i < numberOfTransmissionLimitSeries; i += 1) {
          const transmissionLimitSeries = chart.series.push(
            new am4charts.StepLineSeries(),
          );
          transmissionLimitSeries.dataFields.dateX = 'dateTime';
          transmissionLimitSeries.dataFields.valueY =
            getTransmissionLimitSeriesKey(i);
          transmissionLimitSeries.fill = TRANSMISSION_LIMIT_COLOR;
          transmissionLimitSeries.name = 'Transmission Limit';
          transmissionLimitSeries.stroke = TRANSMISSION_LIMIT_COLOR;
          transmissionLimitSeries.strokeDasharray = '4 3';

          // Bullets
          const transmissionLimitSeriesBullet =
            transmissionLimitSeries.bullets.push(new am4charts.Bullet());
          transmissionLimitSeriesBullet.locationY = 0;
          transmissionLimitSeriesBullet.propertyFields.locationX =
            'bulletLocationX';

          const transmissionSeriesBulletRectangle: am4core.Rectangle =
            transmissionLimitSeriesBullet.createChild(am4core.Rectangle);
          transmissionSeriesBulletRectangle.fill = TRANSMISSION_LIMIT_COLOR;
          transmissionSeriesBulletRectangle.height =
            TRANSMISSION_LIMIT_BULLET_SIZE;
          transmissionSeriesBulletRectangle.horizontalCenter = 'middle';
          transmissionSeriesBulletRectangle.rotation = 45;
          transmissionSeriesBulletRectangle.strokeWidth = 0;
          transmissionSeriesBulletRectangle.verticalCenter = 'middle';
          transmissionSeriesBulletRectangle.width =
            TRANSMISSION_LIMIT_BULLET_SIZE;

          // Tooltips
          transmissionLimitSeries.tooltip!.background.stroke =
            TRANSMISSION_LIMIT_COLOR;
          transmissionLimitSeries.tooltipHTML = getTransmissionLimitHtml(i);
        }

        // Current Level Series
        for (let i: number = 0; i < numberOfCurrentLevelSeries; i += 1) {
          const currentLevelSeries = chart.series.push(
            new am4charts.StepLineSeries(),
          );
          currentLevelSeries.dataFields.dateX = 'dateTime';
          currentLevelSeries.dataFields.valueY = getCurrentLevelSeriesKey(i);
          currentLevelSeries.name = 'Current Level';
          currentLevelSeries.propertyFields.stroke = 'lineColour';

          // Bullets
          const currentLevelBullet = currentLevelSeries.bullets.push(
            new am4charts.CircleBullet(),
          );
          currentLevelBullet.circle.radius = CURRENT_LEVEL_BULLET_RADIUS;
          currentLevelBullet.propertyFields.fill = 'bulletColour';
          currentLevelBullet.propertyFields.locationX = 'bulletLocationX';
          currentLevelBullet.strokeWidth = 0;

          // Tooltips
          currentLevelSeries.tooltip!.background.propertyFields.fill =
            'bulletColour';
          currentLevelSeries.tooltip!.background.propertyFields.stroke =
            'bulletColour';
          currentLevelSeries.tooltip!.getFillFromObject = false;
          currentLevelSeries.tooltipHTML = getCurrentLevelHtml(i);
        }

        // Events
        const updateCurrentTimeIndicatorWrapper = () =>
          updateCurrentTimeIndicator(
            dayStart,
            dayStop,
            eTagStart,
            eTagStop,
            chart!,
            currentTimeLine,
            dateAxis,
            valueAxis,
            setSelectedPlotTime,
            userSelectedTimeRef,
            currentTimeIndicatorTimeoutRef,
            timeZone,
          );
        const updateUserTimeIndicatorWrapper = () =>
          updateUserTimeIndicator(
            undefined,
            chartStart,
            chartStop,
            eTagStart,
            eTagStop,
            chart!,
            dateAxis,
            valueAxis,
            undefined,
            userSelectedTimeRef,
            userTimeIndicatorRef,
            zoomOutButtonHitRef,
            timeZone,
            updateCurrentTimeIndicatorWrapper,
          );

        chart.events.on('ready', () => {
          updateCurrentTimeIndicatorWrapper();
          updateUserTimeIndicatorWrapper();
        });

        chart.zoomOutButton.events.on('hit', (_event) => {
          zoomOutButtonHitRef.current = true;
        });

        chart.plotContainer.events.on('hit', (event) => {
          updateUserTimeIndicator(
            event,
            chartStart,
            chartStop,
            eTagStart,
            eTagStop,
            chart!,
            dateAxis,
            valueAxis,
            setSelectedPlotTime,
            userSelectedTimeRef,
            userTimeIndicatorRef,
            zoomOutButtonHitRef,
            timeZone,
            updateCurrentTimeIndicatorWrapper,
          );
        });

        // Detect if chart scale has changed
        dateAxis.events.on('selectionextremeschanged', () => {
          updateCurrentTimeIndicatorWrapper();
          updateUserTimeIndicatorWrapper();
        });

        // Ref
        chartRef.current = chart;
      }

      return () => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        clearTimeout(currentTimeIndicatorTimeoutRef.current);

        if (chart !== undefined) {
          chart.dispose();
        }
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      chartData,
      chartStart,
      chartStop,
      currentTheme,
      highlightRanges,
      isLoading,
      numberOfCurrentLevelSeries,
      numberOfTransmissionLimitSeries,
      timeZone,
      valueMax,
    ],
  );

  return (
    <Layout>
      <Legend>
        <LegendKey
          colour={CURRENT_LEVEL_WITH_RELIABILITY_LIMIT_COLOUR}
          label='Current Level with Reliability'
          shape={EBulletShape.Circle}
        />
        <LegendKey
          colour={CURRENT_LEVEL_WITH_RELOAD_COLOUR}
          label='Current Level with Reload'
          shape={EBulletShape.Circle}
        />
        <LegendKey
          colour={CURRENT_LEVEL_COLOUR}
          label='Current Level'
          shape={EBulletShape.Circle}
        />
        <LegendKey
          colour={TRANSMISSION_LIMIT_COLOUR}
          label='Transmission Limit'
          shape={EBulletShape.Diamond}
        />
      </Legend>
      {isLoading ? <Spinner /> : <Chart id='chartDiv' />}
    </Layout>
  );
};

export default ProfileGraph;
