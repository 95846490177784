import {
  CaretDownOutlined,
  CaretUpOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
} from '@ant-design/icons';
import { Card as AntDesignCard } from 'antd';
import { CardProps } from 'antd/lib/card';
import IconButton from 'components/atoms/IconButton/IconButton';
import SeparatedRowLayout from 'components/atoms/SeparatedRowLayout/SeparatedRowLayout';
import Spinner from 'components/atoms/Spinner/Spinner';
import DetailViewTitle from 'components/molecules/DetailView/DetailViewTitle';
import DetectableOverflow, {
  IDetectableOverflowProps,
} from 'components/services/DetectableOverflow/DetectableOverflow';
import {
  BUTTON_ICON_DIMENSIONS,
  DETAIL_VIEW_HEAD_COLOUR_DARK,
  DETAIL_VIEW_HEAD_COLOUR_LIGHT,
  ICON_BUTTON_SIZE_VALUE,
  SCROLLBAR_WIDTH_VALUE,
  SMALL_SPACING_VALUE,
  STANDARD_SPACING,
  STANDARD_SPACING_VALUE,
  VIEW_EXPAND_BUTTON_Z_INDEX,
} from 'constants/styles';
import { ETheme } from 'enums/Style';
import { EViewResize } from 'enums/View';
import {
  ILayoutGrid,
  IThemedProps,
  IValidationMessage,
} from 'interfaces/Component';
import { forwardRef, ReactNode, Ref, useEffect, useState } from 'react';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import styled from 'styled-components';
import { componentBackground } from 'utils/styles';

const TITLE_HEIGHT: number =
  ICON_BUTTON_SIZE_VALUE + SMALL_SPACING_VALUE + SMALL_SPACING_VALUE;

const ExpandFullIcon = styled(FullscreenOutlined)`
  ${BUTTON_ICON_DIMENSIONS}
`;

const ExpandDownIcon = styled(CaretDownOutlined)`
  ${BUTTON_ICON_DIMENSIONS}
`;

const ShrinkFullIcon = styled(FullscreenExitOutlined)`
  ${BUTTON_ICON_DIMENSIONS}
`;

const ShrinkDownIcon = styled(CaretUpOutlined)`
  ${BUTTON_ICON_DIMENSIONS}
`;

const TitleSpan = styled.span`
  display: flex;
  flex-direction: row;
`;

const Title = styled.div`
  padding-left: 25vw;
`;

interface IExpandDownButtonProps extends IThemedProps {
  bottomOffset?: number;
}

const ExpandDownButton = styled(IconButton)<IExpandDownButtonProps>`
  bottom: ${(props) =>
    `${props.bottomOffset === undefined ? 0 : props.bottomOffset}`}px;
  left: ${STANDARD_SPACING_VALUE * 2}px;
  position: absolute;
  width: calc(100% - 4 * ${STANDARD_SPACING} - 4px);
  z-index: ${VIEW_EXPAND_BUTTON_Z_INDEX};

  ${(props) => componentBackground(props)}

  ${(props) => `
    :active, :focus, :hover {
      ${componentBackground(props)}
    }
  `}
`;

interface IContainerProps {
  layoutGrid: ILayoutGrid;
}

const Container = styled.div<IContainerProps>`
  position: relative;

  ${(props) => `grid-column: ${props.layoutGrid.gridColumn};`}
  ${(props) => `grid-row: ${props.layoutGrid.gridRow};`}
  ${(props) => `z-index: ${props.layoutGrid.zIndex};`}
`;

interface ICardProps extends CardProps {
  currentTheme: string;
}

const Card = styled(({ currentTheme, ...rest }: ICardProps) => (
  <AntDesignCard {...rest} />
))<ICardProps>`
  height: 100%;

  > .ant-card-head {
    background-color: ${(props) =>
      props.currentTheme === ETheme.Dark
        ? DETAIL_VIEW_HEAD_COLOUR_DARK
        : DETAIL_VIEW_HEAD_COLOUR_LIGHT};

    > .ant-card-head-wrapper {
      min-height: 34px;

      > .ant-card-head-title {
        flex: unset;
        margin-right: ${STANDARD_SPACING};
      }

      > .ant-card-extra {
        flex: 1;
      }

      > .ant-card-head-title,
      .ant-card-extra {
        font-size: 15px;
        padding: 2px 0;
      }
    }
  }
`;

const Actions = styled(SeparatedRowLayout)`
  height: ${ICON_BUTTON_SIZE_VALUE}px;
  justify-content: space-between;
`;

const ActionsLeft = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

const ActionsRight = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

interface IOverflowableContentProps extends IDetectableOverflowProps {
  bottomPadding?: number;
  expandsDown?: boolean;
}

const OverflowableContent = styled(
  ({ bottomPadding, expandsDown, ...rest }: IOverflowableContentProps) => (
    <DetectableOverflow {...rest}>{rest.children}</DetectableOverflow>
  ),
)<IOverflowableContentProps>`
  .ScrollbarsCustom {
    &-Content {
      ${(props) =>
        props.bottomPadding === undefined
          ? ''
          : `padding-bottom:${props.bottomPadding}px !important;`}
      ${(props) => (props.expandsDown ? '' : 'height: 100%')}
    }
  }
`;

interface IDetailViewProps {
  alwaysOfferResize?: boolean;
  children?: ReactNode;
  className?: string;
  isLoading?: boolean;
  isOverflowedX?: boolean;
  isOverflowedY?: boolean;
  layoutGrid: ILayoutGrid;
  leftActions?: JSX.Element;
  resize: (viewId: string, viewResize: EViewResize) => void;
  rightActions?: JSX.Element;
  title?: ReactNode;
  unformattedTitle?: ReactNode;
  validationMessages?: IValidationMessage[];
  viewId: string;
  viewResizeSetting?: EViewResize;
  fullMode?: string;
}

const DetailView = forwardRef(
  (
    {
      alwaysOfferResize,
      children,
      className,
      isLoading,
      isOverflowedX,
      isOverflowedY,
      layoutGrid,
      leftActions,
      resize,
      rightActions,
      title,
      unformattedTitle,
      validationMessages,
      viewId,
      viewResizeSetting,
      fullMode,
    }: IDetailViewProps,
    ref: Ref<HTMLDivElement>,
  ): JSX.Element => {
    useEffect(() => {
      if (fullMode === 'requests') {
        setViewResize(EViewResize.Full);
      }
    }, [fullMode]);
    const { currentTheme } = useThemeSwitcher();
    const [viewResize, setViewResize] = useState<EViewResize>(
      EViewResize.Initial,
    );
    const [hasOverflowedX, setHasOverflowedX] = useState<boolean>(false);
    const [hasOverflowedY, setHasOverflowedY] = useState<boolean>(false);

    const handleResize = (updateViewResize: EViewResize) => () => {
      const newViewResize: EViewResize =
        viewResize === EViewResize.Initial
          ? updateViewResize
          : EViewResize.Initial;
      setViewResize(newViewResize);
      resize(viewId, newViewResize);
    };

    const expandsDown: boolean =
      viewResizeSetting === EViewResize.Down &&
      (isOverflowedY || hasOverflowedY || viewResize === EViewResize.Down);

    return (
      <Container
        className={`detail-view${className ? ` ${className}` : ''}`}
        layoutGrid={layoutGrid}
        ref={ref}
      >
        <Card
          bodyStyle={{
            height: `calc(100% - ${TITLE_HEIGHT}px)`,
          }}
          currentTheme={currentTheme!}
          extra={
            <Actions>
              <ActionsLeft>{leftActions}</ActionsLeft>
              <ActionsRight>
                {rightActions}
                {viewResizeSetting === EViewResize.Full &&
                (isOverflowedX ||
                  isOverflowedY ||
                  hasOverflowedX ||
                  hasOverflowedY ||
                  viewResize === EViewResize.Full ||
                  alwaysOfferResize) ? (
                  <IconButton
                    icon={
                      viewResize === EViewResize.Initial ? (
                        <ExpandFullIcon />
                      ) : (
                        <ShrinkFullIcon />
                      )
                    }
                    onClick={handleResize(EViewResize.Full)}
                  />
                ) : null}
              </ActionsRight>
            </Actions>
          }
          size='small'
          title={
            <TitleSpan>
              <DetailViewTitle
                title={title}
                validationMessages={validationMessages}
              />
              <Title>{unformattedTitle}</Title>
            </TitleSpan>
          }
        >
          <>
            <OverflowableContent
              bottomPadding={
                viewResizeSetting === EViewResize.Down &&
                (isOverflowedY || hasOverflowedY)
                  ? 6 * STANDARD_SPACING_VALUE +
                    (isOverflowedX || hasOverflowedX
                      ? SCROLLBAR_WIDTH_VALUE
                      : 0)
                  : 0
              }
              className='overflowable-content'
              expandsDown={expandsDown}
              onOverflowedX={setHasOverflowedX}
              onOverflowedY={setHasOverflowedY}
            >
              {isLoading ? <Spinner /> : children}
            </OverflowableContent>
            {expandsDown ? (
              <ExpandDownButton
                bottomOffset={
                  3 * STANDARD_SPACING_VALUE -
                  (isOverflowedX || hasOverflowedX ? 0 : SCROLLBAR_WIDTH_VALUE)
                }
                className='expand-down-button'
                currentTheme={currentTheme!}
                icon={
                  viewResize === EViewResize.Initial ? (
                    <ExpandDownIcon />
                  ) : (
                    <ShrinkDownIcon />
                  )
                }
                onClick={handleResize(EViewResize.Down)}
              />
            ) : null}
          </>
        </Card>
      </Container>
    );
  },
);

export default DetailView;
