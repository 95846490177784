import { CloudSyncOutlined } from '@ant-design/icons';
import Button from 'components/atoms/Button/Button';
import IconButton from 'components/atoms/IconButton/IconButton';
import EditorFooter from 'components/molecules/EditorFooter/EditorFooter';
import Modal from 'components/molecules/Modal/Modal';
import Tooltip from 'components/molecules/Tooltip/Tooltip';
import { TO_ENTITY_QUERY_ETAG_AUTHORITY_MODAL_WIDTH_VALUE } from 'components/organisms/ToEntityQueryETagAuthority/constants';
import {
  queryResultsToJSONString,
  queryTypeToDisplayString,
} from 'components/organisms/ToEntityQueryETagAuthority/helpers';
import QueryETagAuthority from 'components/organisms/ToEntityQueryETagAuthority/QueryETagAuthority';
import {
  IModalHandlers,
  ISubmitActionResponse,
} from 'components/organisms/ToEntityQueryETagAuthority/types';
import { BUTTON_ICON_DIMENSIONS } from 'constants/styles';
import { EActionState } from 'enums/General';
import { IETagExtendedIdentifier, IETagTagId } from 'interfaces/ETag';
import { IQueryResults } from 'interfaces/Query';
import { IToEntity } from 'interfaces/ToEntity';
import React, {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import { TTimeZone } from 'types/DateTime';
import { TErrorMessage } from 'types/Error';
import { encodeIds, isEmptyValue } from 'utils/general';
import { ZonedDateTime } from 'utils/zonedDateTime';
import MenuItemActionButton from '../../atoms/MenuActionItem/MenuActionItem';
import { useDispatch, useSelector } from 'react-redux';
import { TRootState } from '../../../types/Redux';
import { EMenuActions, TMenuAction } from '../../../reduxes/Menu/actions';
import { MultipleEntityWrapper } from '../../molecules/MultipleEntityWrapper/MultipleEntityWrapperElement';
import { setTenantSelectedToEntityId } from '../../../reduxes/User/actions';

const QueryETagAuthorityIcon = styled(CloudSyncOutlined)`
  ${BUTTON_ICON_DIMENSIONS}
`;

const HiddenAnchor = styled.a`
  display: none;
`;

interface IProps {
  encodedPermissionsId: string;
  eTagExtendedIdentifier: IETagExtendedIdentifier | undefined;
  isDisabled?: boolean;
  isMenuItem?: boolean;
  timeZone: TTimeZone;
  toEntities: IToEntity[];
  tagID?: IETagTagId;
  toEntity?: IToEntity;
  rowSelected?: boolean;
}

const ToEntityQueryETagAuthoriy = (props: IProps): JSX.Element => {
  const {
    encodedPermissionsId,
    eTagExtendedIdentifier,
    isDisabled,
    isMenuItem,
    timeZone,
    toEntity,
    toEntities,
    tagID,
    rowSelected,
  } = props;
  const [showModal, setShowModal] = useState<boolean>(false);
  const [handlers, setHandlers] = useState<IModalHandlers>({});
  const [disableActions, setDisableActions] = useState<boolean>(false);
  const [submitActionState, setSubmitActionState] = useState<EActionState>(
    EActionState.NoAction,
  );
  const [errorMessage, setErrorMessage] = useState<TErrorMessage>(null);
  const [queryResults, setQueryResults] = useState<IQueryResults | undefined>(
    undefined,
  );
  const [queryResultsFilename, setQueryResultsFilename] = useState<
    string | undefined
  >(undefined);
  const [fileDownloadUrl, setFileDownloadUrl] = useState<string | undefined>(
    undefined,
  );
  const hiddenAnchorRef =
    useRef<HTMLAnchorElement>() as MutableRefObject<HTMLAnchorElement>;

  const menuState = useSelector((state: TRootState) => state.menu);
  const dispatch = useDispatch();

  const setShowQueryEtagAuthorityMenuItemClicked = (
    showQueryETagAuthority: boolean,
  ): TMenuAction => ({
    payload: showQueryETagAuthority,
    type: EMenuActions.ShowQueryEtagAuthorityItemClicked,
  });

  useEffect(() => {
    if (menuState.showQueryEtagAuthority && !showModal) {
      setShowModal(true);
      dispatch(setShowQueryEtagAuthorityMenuItemClicked(false));
    }
  }, [dispatch, menuState, showModal]);

  useEffect(() => {
    if (fileDownloadUrl !== undefined && hiddenAnchorRef.current) {
      hiddenAnchorRef.current.click();

      URL.revokeObjectURL(fileDownloadUrl);

      setQueryResultsFilename(undefined);
      setFileDownloadUrl(undefined);
    }
  }, [fileDownloadUrl]);

  useEffect(() => {
    setQueryResults(undefined);
    setErrorMessage(null);
  }, [handlers]);

  const handleShow = useCallback(() => {
    setSubmitActionState(EActionState.NoAction);

    setErrorMessage(null);

    setShowModal(true);
  }, []);

  const handleCancel = useCallback(async () => {
    setShowModal(false);

    setQueryResults(undefined);
    dispatch(setTenantSelectedToEntityId({ toEntityId: '' }));
  }, [dispatch]);

  const handleSubmit = useCallback(async () => {
    if (handlers.submitHandler !== undefined) {
      try {
        setDisableActions(true);

        setSubmitActionState(EActionState.Actioning);

        setErrorMessage(null);

        const submitActionResponse: ISubmitActionResponse =
          await handlers.submitHandler();

        setSubmitActionState(submitActionResponse.actionState);

        if (submitActionResponse.actionState === EActionState.Failed) {
          setQueryResults(undefined);

          throw new Error(
            isEmptyValue(submitActionResponse.errorMessage)
              ? 'An error occurred during send. Please try again later.'
              : submitActionResponse.errorMessage!,
          );
        }

        if (submitActionResponse.actionState === EActionState.Succeeded) {
          setQueryResults(submitActionResponse.queryResults);
        }
      } catch (error: any) {
        setErrorMessage(error.message);
      } finally {
        setDisableActions(false);
      }
    }
  }, [handlers]);

  const handleDownloadQueryResultsClick = useCallback(() => {
    if (queryResults !== undefined) {
      const jsonString: string = queryResultsToJSONString(queryResults);

      if (!isEmptyValue(jsonString)) {
        const blob: Blob = new Blob([jsonString]);
        const fileDownloadUrl: string = URL.createObjectURL(blob);

        setQueryResultsFilename(
          `Query${queryTypeToDisplayString(
            queryResults.type,
          )}Response_${ZonedDateTime.now(timeZone).isoFormat()}.json`,
        );
        setFileDownloadUrl(fileDownloadUrl);
      }
    }
  }, [queryResults, timeZone]);

  const additionalActions: JSX.Element | undefined = useMemo(() => {
    if (queryResults === undefined) {
      return undefined;
    } else {
      const { type } = queryResults;

      return (
        <Button
          label={`Download Query ${queryTypeToDisplayString(type)} Results`}
          onClick={handleDownloadQueryResultsClick}
        />
      );
    }
  }, [handleDownloadQueryResultsClick, queryResults]);

  return (
    <>
      <HiddenAnchor
        download={queryResultsFilename}
        href={fileDownloadUrl}
        ref={hiddenAnchorRef}
      />
      <Tooltip isDisabled={isDisabled} title='Query e-tag authority'>
        {isMenuItem ? (
          <MenuItemActionButton
            encodedPermissionsId={
              toEntity
                ? encodeIds([encodedPermissionsId], toEntity.to_entity)
                : undefined
            }
            onClick={handleShow}
            type={'query'}
          />
        ) : (
          <IconButton
            icon={<QueryETagAuthorityIcon />}
            encodedPermissionsId={
              toEntity
                ? encodeIds([encodedPermissionsId], toEntity.to_entity)
                : undefined
            }
            isDisabled={isDisabled}
            onClick={handleShow}
          />
        )}
      </Tooltip>
      <Modal
        footer={
          <EditorFooter
            additionalActions={additionalActions}
            confirmActionState={submitActionState}
            confirmLabel='Submit Query'
            encodedPermissionsId={encodeIds([encodedPermissionsId, 'footer'])}
            errorMessage={errorMessage}
            isCancelDisabled={disableActions}
            isConfirmDisabled={
              disableActions || handlers.submitHandler === undefined
            }
            onConfirm={handleSubmit}
          />
        }
        isVisible={showModal}
        onCancel={disableActions ? undefined : handleCancel}
        title={`${
          toEntity && rowSelected ? toEntity.entity_code : ''
        } Query E-Tag Authority`}
        width={TO_ENTITY_QUERY_ETAG_AUTHORITY_MODAL_WIDTH_VALUE}
      >
        <MultipleEntityWrapper
          byPassEntityCheck={
            !!toEntity && (rowSelected || rowSelected === undefined)
          }
        >
          <QueryETagAuthority
            eTagExtendedIdentifier={eTagExtendedIdentifier}
            isDisabled={isDisabled || disableActions}
            setErrorMessage={setErrorMessage}
            setHandlers={setHandlers}
            timeZone={timeZone}
            toEntity={toEntity}
            toEntities={toEntities}
            tagID={tagID}
          />
        </MultipleEntityWrapper>
      </Modal>
    </>
  );
};

export default ToEntityQueryETagAuthoriy;
