import { MISO_MARKET_OPTION, SPP_MARKET_OPTION } from 'constants/ETag';
import {
  STATE_BLUE,
  STATE_GREEN,
  STATE_GREY,
  STATE_ORANGE,
  STATE_RED,
} from 'constants/styles';
import { DATE_FORMAT } from 'constants/time';
import { ECompositeState, EMarketInfoMarket } from 'enums/ETag';
import { IOption } from 'interfaces/Component';
import {
  IETagDataSet,
  IETagIdentifier,
  IETagMarketDetail,
  IETagMarketInfoPrice,
  IETagMisoMarketData,
  IETagNotification,
  IETagSppMarketData,
  IETagSummaryAttribute,
  IETagSummaryAttributeDataSet,
  IETagSummaryDealLinkageDataSet,
  IETagSummaryDealLinkageResponseRecord,
  IETagTagId,
  IETagTemplateDataSet,
  IETagValidationResult,
  ITransmissionAvailabilityDataSet,
} from 'interfaces/ETag';
import { IIndexable } from 'interfaces/General';
import { TTimeZone } from 'types/DateTime';
import { TETagRecordKey } from 'types/ETag';
import { TDirectionalArraySorter } from 'types/Sort';
import { isEmptyValue } from 'utils/general';
import { ZonedDateTime } from 'utils/zonedDateTime';

export const getRecordKeyForETagIdentifier = (
  eTagIdentifier: IETagIdentifier,
): TETagRecordKey =>
  eTagIdentifier.draft_id
    ? eTagIdentifier.draft_id
    : eTagIdentifier.tag_primary_key;

export const getRecordKeyForETagNotification = (
  eTagNotification: IETagNotification,
): TETagRecordKey | undefined =>
  isEmptyValue(eTagNotification.draft_id)
    ? eTagNotification.tag_primary_key
    : eTagNotification.draft_id!;

export const getKeyForETagDataSet = (
  eTagDataSet: IETagDataSet,
  timeZone: TTimeZone | undefined,
): string =>
  `${getRecordKeyForETagIdentifier(eTagDataSet)}${
    eTagDataSet.day === undefined ||
    timeZone === undefined ||
    eTagDataSet.isPrimary
      ? ''
      : `-${ZonedDateTime.parseIso(eTagDataSet.day, timeZone).format(
          DATE_FORMAT,
        )}`
  }`;

export const getKeyForETagTemplateDataSet = (
  eTagTemplateDataSet: IETagTemplateDataSet,
): string => {
  return eTagTemplateDataSet.id;
};

export const getKeyForTransmissionAvailabilityDataSet = (
  transmissionAvailabilityDataSet: ITransmissionAvailabilityDataSet,
): string => {
  return `${transmissionAvailabilityDataSet.aref}${transmissionAvailabilityDataSet.date}${transmissionAvailabilityDataSet.data_name}`;
};

export const eTagSort =
  <T>(sort: TDirectionalArraySorter<T>) =>
  (dataIndex: string) =>
  (ascending: boolean) =>
  (a: IETagDataSet & IIndexable, b: IETagDataSet & IIndexable): number =>
    sort(ascending)(a[dataIndex], b[dataIndex]);

export const eTagTemplateSort =
  <T>(sort: TDirectionalArraySorter<T>) =>
  (dataIndex: string) =>
  (ascending: boolean) =>
  (
    a: IETagTemplateDataSet & IIndexable,
    b: IETagTemplateDataSet & IIndexable,
  ): number =>
    sort(ascending)(a[dataIndex], b[dataIndex]);

export const eTagSummaryAttributeToDataSet = (
  eTagSummaryAttribute: IETagSummaryAttribute | undefined,
): IETagSummaryAttributeDataSet | undefined =>
  eTagSummaryAttribute === undefined
    ? undefined
    : {
        ...eTagSummaryAttribute,
        tag_code: eTagSummaryAttribute.tag_id.tag_code,
        ui_cpse:
          eTagSummaryAttribute.tag_id.pse === null
            ? null
            : eTagSummaryAttribute.tag_id.pse.entity_code,
        ui_gca:
          eTagSummaryAttribute.tag_id.gca === null
            ? null
            : eTagSummaryAttribute.tag_id.gca.entity_code,
        ui_lca:
          eTagSummaryAttribute.tag_id.lca === null
            ? null
            : eTagSummaryAttribute.tag_id.lca.entity_code,
        ui_sink:
          eTagSummaryAttribute.sink === null
            ? null
            : eTagSummaryAttribute.sink.point_name,
        ui_source:
          eTagSummaryAttribute.source === null
            ? null
            : eTagSummaryAttribute.source.point_name,
        ui_tag_id: eTagSummaryAttribute.tag_id.ui_tag_id,
        misc_infos: Array.isArray(eTagSummaryAttribute.misc_infos)
          ? eTagSummaryAttribute.misc_infos
              .map((miscInfo) => `${miscInfo.token}: ${miscInfo.value}`)
              .join('|')
          : '',
      };

export const eTagCombinedSummaryAttributeToDataSet = (
  eTagSummaryAttribute: IETagSummaryAttribute | undefined,
): IETagSummaryAttributeDataSet | undefined =>
  eTagSummaryAttribute === undefined
    ? undefined
    : {
        ...eTagSummaryAttribute,
        tag_code: eTagSummaryAttribute.tag_id.tag_code,
        ui_cpse:
          eTagSummaryAttribute.tag_id.pse === null
            ? null
            : eTagSummaryAttribute.tag_id.pse.entity_code,
        ui_gca:
          eTagSummaryAttribute.tag_id.gca === null
            ? null
            : eTagSummaryAttribute.tag_id.gca.entity_code,
        ui_lca:
          eTagSummaryAttribute.tag_id.lca === null
            ? null
            : eTagSummaryAttribute.tag_id.lca.entity_code,
        ui_sink:
          eTagSummaryAttribute.sink === null
            ? null
            : eTagSummaryAttribute.sink.point_name,
        ui_source:
          eTagSummaryAttribute.source === null
            ? null
            : eTagSummaryAttribute.source.point_name,
        ui_tag_id: eTagSummaryAttribute.tag_id.ui_tag_id,
        misc_infos: Array.isArray(eTagSummaryAttribute.misc_infos)
          ? eTagSummaryAttribute.misc_infos
              .map((miscInfo) => `${miscInfo.token}: ${miscInfo.value}`)
              .join('|')
          : '',
      };

export const eTagSummaryDealLinkageToDataSet = (
  eTagSummaryDealLinkage: IETagSummaryDealLinkageResponseRecord,
): IETagSummaryDealLinkageDataSet => ({
  linkageDay: eTagSummaryDealLinkage.day,
  ui_deal_count: isEmptyValue(eTagSummaryDealLinkage.schedule_count)
    ? null
    : eTagSummaryDealLinkage.schedule_count,
  ui_deal_parent_name: !eTagSummaryDealLinkage.parent_deals
    ? null
    : eTagSummaryDealLinkage.parent_deals,
  ui_deal_schedule_keys: !eTagSummaryDealLinkage.schedule_keys
    ? null
    : eTagSummaryDealLinkage.schedule_keys.join(','),
  ui_deal_status: !eTagSummaryDealLinkage.status
    ? null
    : eTagSummaryDealLinkage.status,
});

export const compositeStateToColour = (
  composite_state: ECompositeState | null,
): string => {
  switch (composite_state) {
    case ECompositeState.Draft:
      return STATE_BLUE;
    case ECompositeState.Pending:
      return STATE_ORANGE;
    case ECompositeState.Confirmed:
    case ECompositeState.Implemented:
      return STATE_GREEN;
    case ECompositeState.Expired:
    case ECompositeState.Withdrawn:
    case ECompositeState.Denied:
    case ECompositeState.Cancelled:
      return STATE_RED;
    case ECompositeState.Terminated:
      return STATE_GREY;
    default:
      return 'inherit';
  }
};

export const copyETagTagId = (eTagTagId: IETagTagId): IETagTagId => {
  const { gca, key, lca, pse, tag_code, tag_primary_key, ui_tag_id } =
    eTagTagId;

  return {
    gca: gca === null ? null : { ...gca },
    key,
    lca: lca === null ? null : { ...lca },
    pse: pse === null ? null : { ...pse },
    tag_code,
    tag_primary_key,
    ui_tag_id,
  };
};

export const extractValidationFailureMessages = (
  eTagValidationResults: IETagValidationResult[] | null | undefined,
): string[] =>
  eTagValidationResults === null || eTagValidationResults === undefined
    ? []
    : eTagValidationResults
        .filter(
          (eTagValidationResult: IETagValidationResult) =>
            !eTagValidationResult.is_valid &&
            !isEmptyValue(eTagValidationResult.error_message),
        )
        .map(
          (eTagValidationResult: IETagValidationResult) =>
            eTagValidationResult.error_message!,
        );

export const extractValidationWarningMessages = (
  eTagValidationResults: IETagValidationResult[] | null | undefined,
): string[] =>
  eTagValidationResults === null || eTagValidationResults === undefined
    ? []
    : eTagValidationResults
        .filter(
          (eTagValidationResult: IETagValidationResult) =>
            !isEmptyValue(eTagValidationResult.warning_message),
        )
        .map(
          (eTagValidationResult: IETagValidationResult) =>
            eTagValidationResult.warning_message!,
        );

export const parseSecurityKeyFromFullTagPrimaryKey = (
  fullTagPrimaryKey: string | undefined | null,
): string | undefined => {
  if (fullTagPrimaryKey === undefined || fullTagPrimaryKey === null) {
    return undefined;
  }
  return fullTagPrimaryKey.split('|')[4];
};

const appendUnlessUndefined = (
  tagPkBuilder: string | undefined,
  next: string | number | null | undefined,
  suffix: string,
): string | undefined => {
  if (tagPkBuilder === undefined || next === undefined || next === null) {
    return undefined;
  }
  return `${tagPkBuilder}${next}${suffix}`;
};

export const computeFullTagPrimaryKey = (
  tag_id: IETagTagId | null,
  security_key: string | null,
): string | undefined => {
  // tag_id.tag_primary_key should be {gca}|{pse}|{tag_code}|{lca}, but it is harder to validate
  // that string than to validate the individual pieces
  let tagPrimaryKeyBuilder: string | undefined = '';
  tagPrimaryKeyBuilder = appendUnlessUndefined(
    tagPrimaryKeyBuilder,
    tag_id?.gca?.tagging_entity_id,
    '|',
  );
  tagPrimaryKeyBuilder = appendUnlessUndefined(
    tagPrimaryKeyBuilder,
    tag_id?.pse?.tagging_entity_id,
    '|',
  );
  tagPrimaryKeyBuilder = appendUnlessUndefined(
    tagPrimaryKeyBuilder,
    tag_id?.tag_code,
    '|',
  );
  tagPrimaryKeyBuilder = appendUnlessUndefined(
    tagPrimaryKeyBuilder,
    tag_id?.lca?.tagging_entity_id,
    '|',
  );
  tagPrimaryKeyBuilder = appendUnlessUndefined(
    tagPrimaryKeyBuilder,
    security_key,
    '',
  );
  return tagPrimaryKeyBuilder;
};

export const getMarketDetailOptionForMarket = (
  market: EMarketInfoMarket,
): IOption<IETagMarketDetail> => {
  switch (market) {
    case EMarketInfoMarket.MISO: {
      return MISO_MARKET_OPTION;
    }
    case EMarketInfoMarket.SPP: {
      return SPP_MARKET_OPTION;
    }
    default: {
      throw new Error(`Invalid market: ${market}`);
    }
  }
};

export const copyETagMarketInfoPrice = (
  eTagMarketInfoPrice: IETagMarketInfoPrice,
): IETagMarketInfoPrice => ({
  hour: eTagMarketInfoPrice.hour,
  mw: eTagMarketInfoPrice.mw,
  price: eTagMarketInfoPrice.price,
});

export const copyETagMisoMarketData = (
  eTagMisoMarketData: IETagMisoMarketData,
): IETagMisoMarketData => ({
  key: eTagMisoMarketData.key,
  miso_create_fin_schedule: eTagMisoMarketData.miso_create_fin_schedule,
  miso_market_date: eTagMisoMarketData.miso_market_date,
  miso_market_type: eTagMisoMarketData.miso_market_type,
  miso_price_list: eTagMisoMarketData.miso_price_list.map(
    copyETagMarketInfoPrice,
  ),
  miso_transaction_type: eTagMisoMarketData.miso_transaction_type,
});

export const copyETagSppMarketData = (
  eTagSppMarketData: IETagSppMarketData,
): IETagSppMarketData => ({
  key: eTagSppMarketData.key,
  spp_market_date: eTagSppMarketData.spp_market_date,
  spp_market_type: eTagSppMarketData.spp_market_type,
  spp_price_list: eTagSppMarketData.spp_price_list.map(copyETagMarketInfoPrice),
  spp_transaction_type: eTagSppMarketData.spp_transaction_type,
});
