import EnergyProfileSnapshotsDateColumnHeader from 'components/organisms/ProfileInformationView/EnergyProfileSnapshotsDateColumnHeader';
import EnergyProfileSnapshotsRequestCell from 'components/organisms/ProfileInformationView/EnergyProfileSnapshotsRequestCell';
import EnergyProfileSnapshotsRequestColumnHeader from 'components/organisms/ProfileInformationView/EnergyProfileSnapshotsRequestColumnHeader';
import { checkRowId } from 'components/organisms/ProfileInformationView/helpers';
import {
  IEditProfileDataGrid,
  IEditProfileDataGridRow,
} from 'components/organisms/ProfileInformationView/types';
import {
  CURRENT_PENDING_REQUEST_KEY,
  CURRENT_REQUEST_KEY,
  EDIT_ETAG_PHYSICAL_SEGMENTS_PROFILE_LABEL,
  ENERGY_PROFILE_SNAPSHOT_EDIT_KEY_LABEL,
  ENERGY_PROFILE_SNAPSHOTS_KEY_PREFIX,
  GENERATION_MARKET_LEVEL_KEY_REG_EXP,
  LOAD_MARKET_LEVEL_KEY_REG_EXP,
  PROFILE_PATH_RAMP_START_KEY,
  PROFILE_PATH_RAMP_STOP_KEY,
  PROFILE_PATH_START_KEY,
  PROFILE_PATH_STOP_KEY,
  TRANSMISSION_ALLOC_TOTALS_KEY_REG_EXP,
  TRANSMISSION_LOSS_KEY_REG_EXP,
  TRANSMISSION_POD_KEY_REG_EXP,
  TRANSMISSION_POR_KEY_REG_EXP,
} from 'constants/Detail';
import { RIGHT_SEPARATOR_CLASS } from 'constants/General';
import {
  DATA_GRID_DATA_COLUMN_WIDTH_VALUE,
  DATA_GRID_DATE_TIME_COLUMN_WIDTH_VALUE,
} from 'constants/styles';
import { EProfileDataGridCellType, EProfileFormat } from 'enums/Detail';
import { ERequestType } from 'enums/ETag';
import { IOption } from 'interfaces/Component';
import {
  IAdHocProfile,
  IDetailRequest,
  IProfileDataGridCell,
  IProfileInterval,
  IProfileTransmission,
} from 'interfaces/Detail';
import {
  IETagPhysicalSegmentProfile,
  IETagPhysicalSegmentsProfile,
  IETagPhysicalSegmentsProfiles,
  IETagTransmissionAllocation,
  IETagTransmissionAllocationProfile,
  IETagTransmissionPhysicalSegment,
  IETagTransmissionSegment,
} from 'interfaces/ETag';
import { IEnergyProfile } from 'interfaces/General';
import { FormatterProps } from 'react-data-grid';
import {
  IDetailEnergyProfileSnapshot,
  IDetailEnergyProfileSnapshotInterval,
  IDetailEnergyProfileSnapshots,
  IDetailGenerationPhysicalSegment,
  IDetailLoadPhysicalSegment,
  IDetailState,
  IDetailTransactionStatuses,
} from 'reduxes/Detail/types';
import { TTimeZone } from 'types/DateTime';
import {
  TProfileDataGridColumn,
  TProfileDataGridRow,
  TProfileDataGridSummaryRow,
} from 'types/Detail';
import { TStateTransform } from 'types/General';
import {
  copyEnergyProfile,
  copyGenerationEnergyProfileToTransmissionSegmentsEnergyProfile,
  copyPointInfo,
  getBackgroundColourForLastRequestType,
  getEditInfoKey,
  getInitialEnergyProfile,
  getInitialGenerationPhysicalSegmentProfile,
  getInitialLoadPhysicalSegmentProfile,
  getInitialPhysicalSegmentsProfile,
  getInitialPhysicalSegmentsProfiles,
  getInitialTransmissionPhysicalSegmentProfile,
  getInitialTransmissionSegment,
  getInitialTransmissionSegments,
  getLoadProfileRef,
  getNextLoadPhysicalSegmentId,
  getPhysicalSegmentRefForTransmissionPodkey,
  getPODProfileRef,
  getPORProfileRef,
  getProfileTransmissionForKey,
  getRequestKey,
  getSplitEditInfoKey,
  getStartStopDateTimesForAdHocProfile,
  requestsSorter,
} from 'utils/detail';
import { captureError } from 'utils/error';
import { isEmptyValue } from 'utils/general';
import { toFormattedUtcString } from 'utils/time';
import { ZonedDateTime } from 'utils/zonedDateTime';

const SnapshotFormatter = (
  props: FormatterProps<TProfileDataGridRow, TProfileDataGridSummaryRow>,
): JSX.Element => (
  <EnergyProfileSnapshotsRequestCell
    {...props}
    getBackgroundColour={getBackgroundColourForLastRequestType}
  />
);

const getEnergyProfileRowKey = (requestId: number): string =>
  `${ENERGY_PROFILE_SNAPSHOTS_KEY_PREFIX}${requestId}`;

export const getEnergyProfileSnapshots = (
  energyProfileSnapshots: IDetailEnergyProfileSnapshots | null,
) => {
  const energyProfileSnapshotsDataSet: TProfileDataGridRow[] = [];
  const energyProfileSnapshotsColumns: TProfileDataGridColumn[] = [];

  if (energyProfileSnapshots !== null) {
    const { max_request_id, min_request_id, snapshotIntervals } =
      energyProfileSnapshots;
    const lastRequestTypeMap: Record<string, ERequestType | undefined> = {};

    // Snapshots data
    snapshotIntervals.forEach(
      (
        detailEnergyProfileSnapshotInterval: IDetailEnergyProfileSnapshotInterval,
        index: number,
      ) => {
        const { snapshots, start, stop } = detailEnergyProfileSnapshotInterval;
        const energyProfileRow: TProfileDataGridRow = {
          id: {
            type: EProfileDataGridCellType.String,
            value: getEditInfoKey(
              ENERGY_PROFILE_SNAPSHOT_EDIT_KEY_LABEL,
              1,
              index,
            ),
          },
          start: {
            type: EProfileDataGridCellType.DateTimeString,
            value: start === null ? '' : start,
          },
          stop: {
            type: EProfileDataGridCellType.DateTimeString,
            value: stop === null ? '' : stop,
          },
        };

        snapshots.forEach(
          (detailEnergyProfileSnapshot: IDetailEnergyProfileSnapshot) => {
            const { last_request_type, mw, request_id } =
              detailEnergyProfileSnapshot;
            const energyProfileRowKey: string =
              getEnergyProfileRowKey(request_id);

            energyProfileRow[energyProfileRowKey] =
              mw === null
                ? null
                : {
                    type: EProfileDataGridCellType.EnergyProfileSnapshot,
                    value: detailEnergyProfileSnapshot,
                  };

            if (last_request_type !== ERequestType.None) {
              lastRequestTypeMap[energyProfileRowKey] = last_request_type;
            }
          },
        );

        energyProfileSnapshotsDataSet.push(energyProfileRow);
      },
    );

    // Snapshots columns
    energyProfileSnapshotsColumns.push(
      {
        frozen: true,
        getFormatter: () => EnergyProfileSnapshotsRequestCell,
        headerRenderer: EnergyProfileSnapshotsDateColumnHeader,
        key: 'start',
        name: 'Start',
        width: DATA_GRID_DATE_TIME_COLUMN_WIDTH_VALUE,
      },
      {
        cellClass: RIGHT_SEPARATOR_CLASS,
        getFormatter: () => EnergyProfileSnapshotsRequestCell,
        headerCellClass: RIGHT_SEPARATOR_CLASS,
        frozen: true,
        headerRenderer: EnergyProfileSnapshotsDateColumnHeader,
        key: 'stop',
        name: 'Stop',
        width: DATA_GRID_DATE_TIME_COLUMN_WIDTH_VALUE,
      },
    );

    for (let i: number = min_request_id; i <= max_request_id; i += 1) {
      const energyProfileRowKey: string = getEnergyProfileRowKey(i);

      energyProfileSnapshotsColumns.push({
        getFormatter: () => SnapshotFormatter,
        headerRenderer: EnergyProfileSnapshotsRequestColumnHeader,
        key: energyProfileRowKey,
        name: `${i}`,
        resizable: true,
        width: DATA_GRID_DATA_COLUMN_WIDTH_VALUE,
      });
    }
  }

  return {
    energyProfileSnapshotsColumns,
    energyProfileSnapshotsDataSet,
  };
};

// maximumRequestId is a zero indexed value
export const getDisplayCountOptions = (maximumRequestId: number) => {
  const displayCountOptions: IOption<number>[] = [];

  if (maximumRequestId >= 5) {
    displayCountOptions.push({
      label: '5',
      value: 5,
    });
  }

  if (maximumRequestId >= 9) {
    displayCountOptions.push({
      label: '9',
      value: 9,
    });
  }

  if (maximumRequestId >= 15) {
    displayCountOptions.push({
      label: '15',
      value: 15,
    });
  }

  if (maximumRequestId >= 19) {
    displayCountOptions.push({
      label: '19',
      value: 19,
    });
  } else {
    displayCountOptions.push({
      label: 'All',
      value: maximumRequestId + 1,
    });
  }

  return {
    defaultDisplayCount: maximumRequestId < 9 ? maximumRequestId + 1 : 9,
    displayCountOptions,
  };
};

export const getRequestOptions = (
  transactionStatuses: IDetailTransactionStatuses[],
) => {
  const requestIdOptions: IOption<string>[] = [];
  const detailRequests: IDetailRequest[] = transactionStatuses.map(
    (
      detailTransactionStatuses: IDetailTransactionStatuses,
    ): IDetailRequest => ({
      correction_id: detailTransactionStatuses.correction_id,
      request_id: detailTransactionStatuses.request_id,
      requestor:
        detailTransactionStatuses.requestor === null
          ? null
          : { ...detailTransactionStatuses.requestor },
      resolution_status: detailTransactionStatuses.resolution_status,
      ui_transaction_message_type:
        detailTransactionStatuses.ui_transaction_message_type,
      request_timestamp: detailTransactionStatuses.request_timestamp,
    }),
  );

  detailRequests.sort(requestsSorter);

  let upperRequestId: number = Number.MIN_SAFE_INTEGER;
  detailRequests.forEach((detailRequest: IDetailRequest) => {
    if (detailRequest.request_id > upperRequestId) {
      upperRequestId = detailRequest.request_id;
    }

    const requestKey: string = getRequestKey(detailRequest);

    requestIdOptions.push({
      label: `Request ID: ${requestKey} (${
        detailRequest && detailRequest.resolution_status?.charAt(0)
      }) - ${detailRequest.ui_transaction_message_type} - ${
        detailRequest.requestor === null
          ? ''
          : detailRequest.requestor.entity_code
      }`,
      value: requestKey,
    });
  });

  requestIdOptions.unshift({
    label: 'Request ID: Current Pending Level',
    value: CURRENT_PENDING_REQUEST_KEY,
  });

  requestIdOptions.unshift({
    label: 'Request ID: Current Level',
    value: CURRENT_REQUEST_KEY,
  });

  return { upperRequestId, requestIdOptions };
};

const addRow = (
  row: TProfileDataGridRow,
  after: boolean,
  physicalSegmentsProfiles: IETagPhysicalSegmentsProfile[],
  generationPhysicalSegment: IDetailGenerationPhysicalSegment | null,
  loadPhysicalSegment: IDetailLoadPhysicalSegment | null,
  transmissionPhysicalSegments: IETagTransmissionPhysicalSegment[] | null,
  transmissionAllocations: IETagTransmissionAllocation[] | null,
  timeZone: TTimeZone,
  useUniqueProfiles: boolean,
  initialRowData?: TProfileDataGridRow,
): IETagPhysicalSegmentsProfile[] => {
  checkRowId(row);

  let foundIndex: number = -1;
  let highestPrimaryId: number = 0;

  physicalSegmentsProfiles.forEach(
    (
      eTagPhysicalSegmentsProfile: IETagPhysicalSegmentsProfile,
      index: number,
    ) => {
      const { primaryId } = getSplitEditInfoKey(
        eTagPhysicalSegmentsProfile.key,
      );

      if (primaryId === undefined) {
        throw new Error(
          `Invalid eTagPhysicalSegmentsProfile.key: ${eTagPhysicalSegmentsProfile.key}`,
        );
      }

      if (primaryId > highestPrimaryId) {
        highestPrimaryId = primaryId;
      }

      if (eTagPhysicalSegmentsProfile.key === (row.id!.value as string)) {
        foundIndex = index;
      }
    },
  );

  if (foundIndex !== -1) {
    const updatedPhysicalSegmentsProfiles: IETagPhysicalSegmentsProfile[] = [
      ...physicalSegmentsProfiles,
    ];

    const newEditInfoKey: string = getEditInfoKey(
      EDIT_ETAG_PHYSICAL_SEGMENTS_PROFILE_LABEL,
      highestPrimaryId + 1,
      0,
    );

    let newETagPhysicalSegmentsProfile: IETagPhysicalSegmentsProfile =
      getInitialPhysicalSegmentsProfile(
        newEditInfoKey,
        null,
        null,
        generationPhysicalSegment,
        loadPhysicalSegment,
        transmissionPhysicalSegments,
        transmissionAllocations,
      );

    if (initialRowData !== undefined) {
      Object.keys(initialRowData).forEach((key: string) => {
        newETagPhysicalSegmentsProfile = editPhysicalSegmentsProfile(
          newETagPhysicalSegmentsProfile,
          key,
          initialRowData,
          false,
          generationPhysicalSegment,
          loadPhysicalSegment,
          transmissionPhysicalSegments,
          transmissionAllocations,
          useUniqueProfiles,
          timeZone,
        );
      });
    }

    updatedPhysicalSegmentsProfiles.splice(
      after ? foundIndex + 1 : foundIndex,
      0,
      newETagPhysicalSegmentsProfile,
    );

    return updatedPhysicalSegmentsProfiles;
  }

  return physicalSegmentsProfiles;
};

const editRampValue = (
  updatedCell: IProfileDataGridCell | null,
  physicalSegmentsProfiles: IETagPhysicalSegmentsProfiles,
  editStartRamp: boolean,
  generationPhysicalSegment: IDetailGenerationPhysicalSegment | null,
  loadPhysicalSegment: IDetailLoadPhysicalSegment | null,
  transmissionPhysicalSegments: IETagTransmissionPhysicalSegment[] | null,
  transmissionAllocations: IETagTransmissionAllocation[] | null,
): IETagPhysicalSegmentsProfiles => {
  if (
    updatedCell === null ||
    updatedCell.type === EProfileDataGridCellType.Empty ||
    updatedCell.type === EProfileDataGridCellType.Number
  ) {
    const rampDur: number | null =
      updatedCell === null ||
      updatedCell.type === EProfileDataGridCellType.Empty
        ? null
        : (updatedCell.value as number);
    const updatedPhysicalSegmentsProfiles: IETagPhysicalSegmentsProfiles = {
      ...physicalSegmentsProfiles,
    };

    if (updatedPhysicalSegmentsProfiles.generation === null) {
      updatedPhysicalSegmentsProfiles.generation =
        getInitialGenerationPhysicalSegmentProfile(generationPhysicalSegment);
    }

    if (updatedPhysicalSegmentsProfiles.load === null) {
      updatedPhysicalSegmentsProfiles.load =
        getInitialLoadPhysicalSegmentProfile(
          loadPhysicalSegment,
          transmissionPhysicalSegments,
        );
    }

    if (updatedPhysicalSegmentsProfiles.transmission === null) {
      updatedPhysicalSegmentsProfiles.transmission =
        getInitialTransmissionPhysicalSegmentProfile(
          transmissionPhysicalSegments,
          transmissionAllocations,
        );
    }

    if (updatedPhysicalSegmentsProfiles.generation.profile === null) {
      updatedPhysicalSegmentsProfiles.generation = {
        ...updatedPhysicalSegmentsProfiles.generation,
        // Generation physical segment always takes the first profile
        profile: getInitialEnergyProfile(1),
      };
    } else {
      updatedPhysicalSegmentsProfiles.generation = {
        ...updatedPhysicalSegmentsProfiles.generation,
        profile: copyEnergyProfile(
          updatedPhysicalSegmentsProfiles.generation.profile,
        ),
      };
    }

    if (editStartRamp) {
      updatedPhysicalSegmentsProfiles.generation!.profile!.start_ramp_dur =
        rampDur;
    } else {
      updatedPhysicalSegmentsProfiles.generation!.profile!.stop_ramp_dur =
        rampDur;
    }

    if (updatedPhysicalSegmentsProfiles.load.profile === null) {
      updatedPhysicalSegmentsProfiles.load = {
        ...updatedPhysicalSegmentsProfiles.load,
        profile: getInitialEnergyProfile(
          getNextLoadPhysicalSegmentId(transmissionPhysicalSegments),
        ),
      };
    } else {
      updatedPhysicalSegmentsProfiles.load = {
        ...updatedPhysicalSegmentsProfiles.load,
        profile: copyEnergyProfile(
          updatedPhysicalSegmentsProfiles.load.profile,
        ),
      };
    }

    if (editStartRamp) {
      updatedPhysicalSegmentsProfiles.load!.profile!.start_ramp_dur = rampDur;
    } else {
      updatedPhysicalSegmentsProfiles.load!.profile!.stop_ramp_dur = rampDur;
    }

    if (
      updatedPhysicalSegmentsProfiles.transmission!.transmission_segments ===
      null
    ) {
      updatedPhysicalSegmentsProfiles.transmission = {
        ...updatedPhysicalSegmentsProfiles.transmission!,
        transmission_segments: getInitialTransmissionSegments(
          transmissionPhysicalSegments,
          transmissionAllocations,
        ),
      };
    } else {
      const updatedTransmissionSegments: IETagTransmissionSegment[] = [
        ...updatedPhysicalSegmentsProfiles.transmission!.transmission_segments,
      ];
      let hasUpatedTransmission: boolean = false;

      updatedTransmissionSegments.forEach(
        (transmissionSegment: IETagTransmissionSegment, index: number) => {
          const updatedTransmissionSegment: IETagTransmissionSegment = {
            ...transmissionSegment,
          };
          if (updatedTransmissionSegment.physical_segment_id === null) {
            throw new Error();
          }

          let hasUpdatedProfile: boolean = false;

          if (updatedTransmissionSegment.pod_energy_profile === null) {
            updatedTransmissionSegment.pod_energy_profile =
              getInitialEnergyProfile(
                getPODProfileRef(
                  updatedTransmissionSegment.physical_segment_id,
                ),
              );
            hasUpdatedProfile = true;
            hasUpatedTransmission = true;
          }

          if (updatedTransmissionSegment.por_energy_profile === null) {
            updatedTransmissionSegment.por_energy_profile =
              getInitialEnergyProfile(
                getPORProfileRef(
                  updatedTransmissionSegment.physical_segment_id,
                ),
              );
            hasUpdatedProfile = true;
            hasUpatedTransmission = true;
          }

          if (hasUpdatedProfile) {
            updatedTransmissionSegments[index] = updatedTransmissionSegment;
          }
        },
      );

      if (hasUpatedTransmission) {
        updatedPhysicalSegmentsProfiles.transmission = {
          ...updatedPhysicalSegmentsProfiles.transmission!,
          transmission_segments: updatedTransmissionSegments,
        };
      }
    }

    if (
      updatedPhysicalSegmentsProfiles.transmission.transmission_segments !==
      null
    ) {
      updatedPhysicalSegmentsProfiles.transmission.transmission_segments.forEach(
        (transmissionSegment: IETagTransmissionSegment, _index: number) => {
          if (editStartRamp) {
            transmissionSegment.pod_energy_profile!.start_ramp_dur = rampDur;
            transmissionSegment.por_energy_profile!.start_ramp_dur = rampDur;
          } else {
            transmissionSegment.pod_energy_profile!.stop_ramp_dur = rampDur;
            transmissionSegment.por_energy_profile!.stop_ramp_dur = rampDur;
          }
        },
      );
    }

    return updatedPhysicalSegmentsProfiles;
  } else {
    throw new Error(`Invalid data grid cell ${JSON.stringify(updatedCell)}`);
  }
};

const copyEnergyProfileToPORForPhysicalSegmentId = (
  energyProfile: IEnergyProfile,
  physicalSegmentId: number,
  physicalSegmentsProfiles: IETagPhysicalSegmentsProfiles,
): IETagPhysicalSegmentsProfiles => {
  if (
    physicalSegmentsProfiles.transmission !== null &&
    physicalSegmentsProfiles.transmission.transmission_segments !== null
  ) {
    const transmissionSegmentIndex: number =
      physicalSegmentsProfiles.transmission.transmission_segments.findIndex(
        (eTagTransmissionSegment: IETagTransmissionSegment): boolean =>
          eTagTransmissionSegment.physical_segment_id === physicalSegmentId,
      );

    if (transmissionSegmentIndex !== -1) {
      const updatedPhysicalSegmentsProfiles: IETagPhysicalSegmentsProfiles = {
        ...physicalSegmentsProfiles,
      };

      updatedPhysicalSegmentsProfiles.transmission = {
        ...updatedPhysicalSegmentsProfiles.transmission!,
      };

      updatedPhysicalSegmentsProfiles.transmission.transmission_segments = [
        ...updatedPhysicalSegmentsProfiles.transmission.transmission_segments!,
      ];

      updatedPhysicalSegmentsProfiles.transmission.transmission_segments[
        transmissionSegmentIndex
      ] = {
        ...updatedPhysicalSegmentsProfiles.transmission.transmission_segments[
          transmissionSegmentIndex
        ],
        por_energy_profile: copyEnergyProfile(energyProfile),
      };

      return updatedPhysicalSegmentsProfiles;
    }

    // Typically we would expect the load profile to refer to the POD of the
    // previous transmission and is indicated by the profile_id being the same
    // as the previous transmission's physical_segment_id. However, if the load
    // profile is unique then its profile_id will be the same as the load's
    // physical_segment_id and should not be updated here
    if (
      physicalSegmentsProfiles.load !== null &&
      physicalSegmentsProfiles.load.profile !== null &&
      physicalSegmentsProfiles.load.profile.profile_id !==
        physicalSegmentsProfiles.load.physical_segment_id &&
      physicalSegmentsProfiles.load.profile.profile_id === physicalSegmentId
    ) {
      const updatedPhysicalSegmentsProfiles: IETagPhysicalSegmentsProfiles = {
        ...physicalSegmentsProfiles,
      };

      updatedPhysicalSegmentsProfiles.load = {
        ...updatedPhysicalSegmentsProfiles.load!,
        profile: copyEnergyProfile(energyProfile),
      };

      return updatedPhysicalSegmentsProfiles;
    }
  }

  return physicalSegmentsProfiles;
};

const editGenerationMarketLevelValue = (
  updatedCell: IProfileDataGridCell | null,
  physicalSegmentsProfiles: IETagPhysicalSegmentsProfiles,
  generationPhysicalSegment: IDetailGenerationPhysicalSegment | null,
): IETagPhysicalSegmentsProfiles => {
  if (
    updatedCell === null ||
    updatedCell.type === EProfileDataGridCellType.Empty ||
    updatedCell.type === EProfileDataGridCellType.Number
  ) {
    const marketLevelValue: number | null =
      updatedCell === null ||
      updatedCell.type === EProfileDataGridCellType.Empty
        ? null
        : (updatedCell.value as number);
    let updatedPhysicalSegmentsProfiles: IETagPhysicalSegmentsProfiles = {
      ...physicalSegmentsProfiles,
    };

    if (updatedPhysicalSegmentsProfiles.generation === null) {
      updatedPhysicalSegmentsProfiles.generation =
        getInitialGenerationPhysicalSegmentProfile(generationPhysicalSegment);
    }

    if (updatedPhysicalSegmentsProfiles.generation.profile === null) {
      updatedPhysicalSegmentsProfiles.generation = {
        ...updatedPhysicalSegmentsProfiles.generation,
        // Generation physical segment always takes the first profile
        profile: getInitialEnergyProfile(1),
      };
    } else {
      updatedPhysicalSegmentsProfiles.generation = {
        ...updatedPhysicalSegmentsProfiles.generation,
        profile: copyEnergyProfile(
          updatedPhysicalSegmentsProfiles.generation.profile,
        ),
      };
    }

    updatedPhysicalSegmentsProfiles.generation!.profile!.market_level =
      marketLevelValue;
    updatedPhysicalSegmentsProfiles.generation!.profile!.mw = marketLevelValue;

    updatedPhysicalSegmentsProfiles =
      copyEnergyProfileToPORForPhysicalSegmentId(
        updatedPhysicalSegmentsProfiles.generation!.profile!,
        2,
        updatedPhysicalSegmentsProfiles,
      );

    return updatedPhysicalSegmentsProfiles;
  } else {
    throw new Error(`Invalid data grid cell ${JSON.stringify(updatedCell)}`);
  }
};

const editLoadMarketLevelValue = (
  updatedCell: IProfileDataGridCell | null,
  physicalSegmentsProfiles: IETagPhysicalSegmentsProfiles,
  loadPhysicalSegment: IDetailLoadPhysicalSegment | null,
  transmissionPhysicalSegments: IETagTransmissionPhysicalSegment[] | null,
): IETagPhysicalSegmentsProfiles => {
  if (
    updatedCell === null ||
    updatedCell.type === EProfileDataGridCellType.Empty ||
    updatedCell.type === EProfileDataGridCellType.Number
  ) {
    const marketLevelValue: number | null =
      updatedCell === null ||
      updatedCell.type === EProfileDataGridCellType.Empty
        ? null
        : (updatedCell.value as number);
    const updatedPhysicalSegmentsProfiles: IETagPhysicalSegmentsProfiles = {
      ...physicalSegmentsProfiles,
    };

    if (updatedPhysicalSegmentsProfiles.load === null) {
      updatedPhysicalSegmentsProfiles.load =
        getInitialLoadPhysicalSegmentProfile(
          loadPhysicalSegment,
          transmissionPhysicalSegments,
        );
    }

    if (updatedPhysicalSegmentsProfiles.load.profile === null) {
      const physical_segment_id: number = getNextLoadPhysicalSegmentId(
        transmissionPhysicalSegments,
      );

      updatedPhysicalSegmentsProfiles.load = {
        ...updatedPhysicalSegmentsProfiles.load,
        // Generation physical segment always takes the first profile
        profile: getInitialEnergyProfile(
          getLoadProfileRef(physical_segment_id),
        ),
      };
    } else {
      updatedPhysicalSegmentsProfiles.load = {
        ...updatedPhysicalSegmentsProfiles.load,
        profile: copyEnergyProfile(
          updatedPhysicalSegmentsProfiles.load.profile,
        ),
      };
    }

    updatedPhysicalSegmentsProfiles.load!.profile!.market_level =
      marketLevelValue;
    updatedPhysicalSegmentsProfiles.load!.profile!.mw = marketLevelValue;

    return updatedPhysicalSegmentsProfiles;
  } else {
    throw new Error(`Invalid data grid cell ${JSON.stringify(updatedCell)}`);
  }
};

const insertInitialTransmissionSegment = (
  physicalSegmentId: number,
  generationPhysicalSegmentProfile: IETagPhysicalSegmentProfile | null,
  transmissionPhysicalSegments: IETagTransmissionPhysicalSegment[] | null,
  updatedTransmissionSegments: IETagTransmissionSegment[],
) => {
  const previousPhysicalSegmentId: number = physicalSegmentId - 1;
  let initialTransmissionSegment: IETagTransmissionSegment | undefined =
    undefined;
  let initialTransmissionSegmentIndex: number | undefined = undefined;
  let foundTransmissionPhysicalSegment:
    | IETagTransmissionPhysicalSegment
    | undefined = undefined;
  let foundPreviousTransmissionPhysicalSegment:
    | IETagTransmissionPhysicalSegment
    | undefined = undefined;
  let foundPreviousTransmissionSegment: IETagTransmissionSegment | undefined =
    undefined;

  if (transmissionPhysicalSegments !== null) {
    for (let i: number = 0; i < transmissionPhysicalSegments.length; i += 1) {
      const transmissionPhysicalSegment: IETagTransmissionPhysicalSegment =
        transmissionPhysicalSegments[i];

      if (
        transmissionPhysicalSegment.physical_segment_id ===
        previousPhysicalSegmentId
      ) {
        foundPreviousTransmissionSegment = updatedTransmissionSegments.find(
          (eTagTranmissionSegment: IETagTransmissionSegment): boolean =>
            eTagTranmissionSegment.physical_segment_id ===
            previousPhysicalSegmentId,
        );

        foundPreviousTransmissionPhysicalSegment = transmissionPhysicalSegment;
      }

      if (
        transmissionPhysicalSegment.physical_segment_id === physicalSegmentId
      ) {
        foundTransmissionPhysicalSegment = transmissionPhysicalSegment;
        break;
      }
    }
  }

  if (foundTransmissionPhysicalSegment !== undefined) {
    initialTransmissionSegment = getInitialTransmissionSegment(
      physicalSegmentId,
      copyPointInfo(foundTransmissionPhysicalSegment.pod),
      undefined,
      // POR is assumed to be the previous physical segment's POD or in the case
      // of the generation, it is generation_source
      physicalSegmentId === 2 && generationPhysicalSegmentProfile !== null
        ? copyPointInfo(generationPhysicalSegmentProfile.source_sink)
        : foundPreviousTransmissionPhysicalSegment !== undefined
        ? copyPointInfo(foundPreviousTransmissionPhysicalSegment.pod)
        : undefined,
      physicalSegmentId === 2 && generationPhysicalSegmentProfile !== null
        ? copyEnergyProfile(generationPhysicalSegmentProfile.profile)
        : foundPreviousTransmissionPhysicalSegment !== undefined &&
          foundPreviousTransmissionSegment !== undefined
        ? copyEnergyProfile(foundPreviousTransmissionSegment.pod_energy_profile)
        : undefined,
    );

    const transmissionSegmentIndex: number =
      updatedTransmissionSegments.findIndex(
        (transmissionSegment: IETagTransmissionSegment): boolean =>
          transmissionSegment.physical_segment_id !== null &&
          transmissionSegment.physical_segment_id > physicalSegmentId,
      );

    if (transmissionSegmentIndex === -1) {
      updatedTransmissionSegments.push(initialTransmissionSegment);

      initialTransmissionSegmentIndex = updatedTransmissionSegments.length - 1;
    } else {
      updatedTransmissionSegments.splice(
        transmissionSegmentIndex,
        0,
        initialTransmissionSegment,
      );

      initialTransmissionSegmentIndex = transmissionSegmentIndex;
    }
  }

  return {
    initialTransmissionSegment,
    initialTransmissionSegmentIndex,
  };
};

const editTransmissionValue = (
  physicalSegmentId: number,
  transAllocId: number,
  adjustedContractNumber: string,
  updatedCell: IProfileDataGridCell | null,
  physicalSegmentsProfiles: IETagPhysicalSegmentsProfiles,
  generationPhysicalSegmentProfile: IETagPhysicalSegmentProfile | null,
  transmissionPhysicalSegments: IETagTransmissionPhysicalSegment[] | null,
  transmissionAllocations: IETagTransmissionAllocation[] | null,
): IETagPhysicalSegmentsProfiles => {
  if (
    updatedCell === null ||
    updatedCell.type === EProfileDataGridCellType.Empty ||
    updatedCell.type === EProfileDataGridCellType.Number
  ) {
    const transmissionValue: number | null =
      updatedCell === null ||
      updatedCell.type === EProfileDataGridCellType.Empty
        ? null
        : (updatedCell.value as number);
    const updatedPhysicalSegmentsProfiles: IETagPhysicalSegmentsProfiles = {
      ...physicalSegmentsProfiles,
    };
    let hasUpdatedProfile: boolean = false;

    if (updatedPhysicalSegmentsProfiles.transmission === null) {
      updatedPhysicalSegmentsProfiles.transmission =
        getInitialTransmissionPhysicalSegmentProfile(
          transmissionPhysicalSegments,
          transmissionAllocations,
        );
    }

    if (
      updatedPhysicalSegmentsProfiles.transmission.transmission_segments !==
      null
    ) {
      const updatedTransmissionSegments: IETagTransmissionSegment[] = [
        ...updatedPhysicalSegmentsProfiles.transmission.transmission_segments,
      ];
      let updatedTransmissionSegmentIndex: number =
        updatedTransmissionSegments.findIndex(
          (transmissionSegment: IETagTransmissionSegment): boolean =>
            physicalSegmentId === transmissionSegment.physical_segment_id,
        );
      let updatedTransmissionSegment: IETagTransmissionSegment | undefined =
        undefined;

      if (updatedTransmissionSegmentIndex === -1) {
        const { initialTransmissionSegment, initialTransmissionSegmentIndex } =
          insertInitialTransmissionSegment(
            physicalSegmentId,
            generationPhysicalSegmentProfile,
            transmissionPhysicalSegments,
            updatedTransmissionSegments,
          );

        if (
          initialTransmissionSegment !== undefined &&
          initialTransmissionSegmentIndex !== undefined
        ) {
          updatedTransmissionSegment = initialTransmissionSegment;
          updatedTransmissionSegmentIndex = initialTransmissionSegmentIndex;
        }
      } else {
        updatedTransmissionSegment = {
          ...updatedTransmissionSegments[updatedTransmissionSegmentIndex],
        };
      }

      if (updatedTransmissionSegment !== undefined) {
        if (updatedTransmissionSegment.trans_alloc_profiles === null) {
          if (transmissionValue !== null) {
            const transmissionAllocation:
              | IETagTransmissionAllocation
              | undefined = transmissionAllocations?.find(
              (
                eTagTransmissionAllocation: IETagTransmissionAllocation,
              ): boolean =>
                eTagTransmissionAllocation.trans_alloc_id === transAllocId,
            );

            updatedTransmissionSegment.trans_alloc_profiles = [
              {
                contract_number: adjustedContractNumber,
                last_request_type: null,
                profile_mw: transmissionValue,
                trans_alloc_id:
                  transmissionAllocation === undefined
                    ? null
                    : transmissionAllocation.trans_alloc_id,
              },
            ];

            hasUpdatedProfile = true;
          }
        } else {
          updatedTransmissionSegment.trans_alloc_profiles = [
            ...updatedTransmissionSegment.trans_alloc_profiles,
          ];
          const transAllocProfileIndex: number =
            updatedTransmissionSegment.trans_alloc_profiles.findIndex(
              (
                transmissionAllocationProfile: IETagTransmissionAllocationProfile,
              ): boolean =>
                transmissionAllocationProfile.trans_alloc_id === transAllocId,
            );

          if (transAllocProfileIndex === -1) {
            if (transmissionValue !== null) {
              const transmissionAllocation:
                | IETagTransmissionAllocation
                | undefined = transmissionAllocations?.find(
                (
                  eTagTransmissionAllocation: IETagTransmissionAllocation,
                ): boolean =>
                  eTagTransmissionAllocation.trans_alloc_id === transAllocId,
              );

              updatedTransmissionSegment.trans_alloc_profiles.push({
                contract_number: adjustedContractNumber,
                last_request_type: null,
                profile_mw: transmissionValue,
                trans_alloc_id:
                  transmissionAllocation === undefined
                    ? null
                    : transmissionAllocation.trans_alloc_id,
              });
            }
          } else {
            const updatedTransAllocProfile: IETagTransmissionAllocationProfile =
              {
                ...updatedTransmissionSegment.trans_alloc_profiles[
                  transAllocProfileIndex
                ],
              };

            if (transmissionValue === null) {
              updatedTransmissionSegment.trans_alloc_profiles.splice(
                transAllocProfileIndex,
                1,
              );
            } else {
              updatedTransAllocProfile.profile_mw = transmissionValue;

              updatedTransmissionSegment.trans_alloc_profiles[
                transAllocProfileIndex
              ] = updatedTransAllocProfile;
            }
          }

          hasUpdatedProfile = true;
        }

        updatedTransmissionSegments[updatedTransmissionSegmentIndex] =
          updatedTransmissionSegment;
      }

      if (hasUpdatedProfile) {
        updatedPhysicalSegmentsProfiles.transmission = {
          ...updatedPhysicalSegmentsProfiles.transmission,
          transmission_segments: updatedTransmissionSegments,
        };
      }
    }

    return hasUpdatedProfile
      ? updatedPhysicalSegmentsProfiles
      : physicalSegmentsProfiles;
  } else if (
    updatedCell.type === EProfileDataGridCellType.EnergyProfile ||
    updatedCell.type === EProfileDataGridCellType.EnergyProfileSnapshot
  ) {
    return physicalSegmentsProfiles;
  } else {
    throw new Error(`Invalid data grid cell ${JSON.stringify(updatedCell)}`);
  }
};

const editTransmissionPODValue = (
  physicalSegmentId: number,
  updatedCell: IProfileDataGridCell | null,
  physicalSegmentsProfiles: IETagPhysicalSegmentsProfiles,
  generationPhysicalSegmentProfile: IETagPhysicalSegmentProfile | null,
  transmissionPhysicalSegments: IETagTransmissionPhysicalSegment[] | null,
): IETagPhysicalSegmentsProfiles => {
  if (
    updatedCell === null ||
    updatedCell.type === EProfileDataGridCellType.Empty ||
    updatedCell.type === EProfileDataGridCellType.Number ||
    updatedCell.type === EProfileDataGridCellType.EnergyProfile
  ) {
    const transmissionPODValue: number | null =
      updatedCell === null
        ? null
        : updatedCell.type === EProfileDataGridCellType.EnergyProfile
        ? (updatedCell.value as IEnergyProfile).mw
        : updatedCell.type === EProfileDataGridCellType.Number
        ? (updatedCell.value as number)
        : null;

    let updatedPhysicalSegmentsProfiles = physicalSegmentsProfiles;

    if (updatedPhysicalSegmentsProfiles.transmission !== null) {
      if (
        updatedPhysicalSegmentsProfiles.transmission.transmission_segments !==
        null
      ) {
        let updatedTransmissionSegments: IETagTransmissionSegment[] =
          updatedPhysicalSegmentsProfiles.transmission.transmission_segments;
        let updatedTransmissionSegmentIndex: number =
          updatedTransmissionSegments.findIndex(
            (eTagTransmissionSegment: IETagTransmissionSegment): boolean =>
              eTagTransmissionSegment.physical_segment_id === physicalSegmentId,
          );

        if (updatedTransmissionSegmentIndex === -1) {
          updatedTransmissionSegments = [...updatedTransmissionSegments];

          const {
            initialTransmissionSegment,
            initialTransmissionSegmentIndex,
          } = insertInitialTransmissionSegment(
            physicalSegmentId,
            generationPhysicalSegmentProfile,
            transmissionPhysicalSegments,
            updatedTransmissionSegments,
          );

          if (
            initialTransmissionSegment !== undefined &&
            initialTransmissionSegmentIndex !== undefined
          ) {
            updatedTransmissionSegmentIndex = initialTransmissionSegmentIndex;
          }
        }

        if (updatedTransmissionSegmentIndex !== -1) {
          updatedTransmissionSegments = [...updatedTransmissionSegments];

          let updatedTransmissionSegment: IETagTransmissionSegment =
            updatedTransmissionSegments[updatedTransmissionSegmentIndex];
          let updatedPODEnergyProfile: IEnergyProfile;

          if (updatedTransmissionSegment.pod_energy_profile === null) {
            updatedPODEnergyProfile =
              getInitialEnergyProfile(physicalSegmentId);
          } else {
            updatedPODEnergyProfile = copyEnergyProfile(
              updatedTransmissionSegment.pod_energy_profile,
            )!;
          }

          updatedPODEnergyProfile.market_level = transmissionPODValue;
          updatedPODEnergyProfile.mw = updatedPODEnergyProfile.market_level;

          updatedTransmissionSegment = {
            ...updatedTransmissionSegment,
            pod_energy_profile: updatedPODEnergyProfile,
          };

          updatedTransmissionSegments[updatedTransmissionSegmentIndex] =
            updatedTransmissionSegment;

          updatedPhysicalSegmentsProfiles = {
            ...updatedPhysicalSegmentsProfiles,
            transmission: {
              ...updatedPhysicalSegmentsProfiles.transmission,
              transmission_segments: updatedTransmissionSegments,
            },
          };

          updatedPhysicalSegmentsProfiles =
            copyEnergyProfileToPORForPhysicalSegmentId(
              updatedPODEnergyProfile,
              physicalSegmentId + 1,
              updatedPhysicalSegmentsProfiles,
            );
        }
      }
    }

    return updatedPhysicalSegmentsProfiles;
  } else if (
    updatedCell.type === EProfileDataGridCellType.EnergyProfileSnapshot
  ) {
    return physicalSegmentsProfiles;
  } else {
    throw new Error(`Invalid data grid cell ${JSON.stringify(updatedCell)}`);
  }
};

const editPhysicalSegmentsProfile = (
  physicalSegmentsProfile: IETagPhysicalSegmentsProfile,
  key: string,
  updatedRow: TProfileDataGridRow,
  isSameTransAllocProfile: boolean,
  generationPhysicalSegment: IDetailGenerationPhysicalSegment | null,
  loadPhysicalSegment: IDetailLoadPhysicalSegment | null,
  transmissionPhysicalSegments: IETagTransmissionPhysicalSegment[] | null,
  transmissionAllocations: IETagTransmissionAllocation[] | null,
  useUniqueProfiles: boolean,
  timeZone: TTimeZone,
): IETagPhysicalSegmentsProfile => {
  const updatedCell: IProfileDataGridCell | null | undefined = updatedRow[key];

  if (updatedCell === undefined) {
    throw new Error(
      `Invalid key: ${key} for updatedRow: ${JSON.stringify(updatedRow)}`,
    );
  }

  const updatedPhysicalSegmentsProfile: IETagPhysicalSegmentsProfile = {
    ...physicalSegmentsProfile,
  };
  const isRampStartKey: boolean = key === PROFILE_PATH_RAMP_START_KEY;

  if (key === PROFILE_PATH_START_KEY) {
    updatedPhysicalSegmentsProfile.start =
      updatedCell === null ||
      (updatedCell.type === EProfileDataGridCellType.DateTimeString &&
        isEmptyValue(updatedCell.value as string))
        ? null
        : updatedCell.type === EProfileDataGridCellType.DateTimeString
        ? (updatedCell.value as string)
        : updatedPhysicalSegmentsProfile.start;

    const stopCell: IProfileDataGridCell | null | undefined =
      updatedRow[PROFILE_PATH_STOP_KEY];

    if (stopCell === undefined) {
      throw new Error(
        `Missing key: ${PROFILE_PATH_STOP_KEY} for updatedRow: ${JSON.stringify(
          updatedRow,
        )}`,
      );
    }

    if (stopCell !== null) {
      if (stopCell.type !== EProfileDataGridCellType.DateTimeString) {
        throw new Error(
          `Invalid profile stop cell for profile row: ${JSON.stringify(
            updatedRow,
          )}`,
        );
      }

      const stopCellValue: string = stopCell.value as string;

      if (
        !isEmptyValue(stopCellValue) &&
        !isEmptyValue(updatedPhysicalSegmentsProfile.start)
      ) {
        const startDateTime: ZonedDateTime = ZonedDateTime.parseIso(
          updatedPhysicalSegmentsProfile.start!,
          timeZone,
        );
        const stopDateTime: ZonedDateTime = ZonedDateTime.parseIso(
          stopCellValue,
          timeZone,
        );

        if (startDateTime.isSameOrAfter(stopDateTime)) {
          updatedPhysicalSegmentsProfile.stop = null;
        }
      }
    }
  } else if (key === PROFILE_PATH_STOP_KEY) {
    updatedPhysicalSegmentsProfile.stop =
      updatedCell === null ||
      (updatedCell.type === EProfileDataGridCellType.DateTimeString &&
        isEmptyValue(updatedCell.value as string))
        ? null
        : updatedCell.type === EProfileDataGridCellType.DateTimeString
        ? (updatedCell.value as string)
        : updatedPhysicalSegmentsProfile.stop;
  } else if (isRampStartKey || key === PROFILE_PATH_RAMP_STOP_KEY) {
    updatedPhysicalSegmentsProfile.physical_segments_profiles = editRampValue(
      updatedCell,
      updatedPhysicalSegmentsProfile.physical_segments_profiles!,
      isRampStartKey,
      generationPhysicalSegment,
      loadPhysicalSegment,
      transmissionPhysicalSegments,
      transmissionAllocations,
    );
  } else if (GENERATION_MARKET_LEVEL_KEY_REG_EXP.test(key)) {
    updatedPhysicalSegmentsProfile.physical_segments_profiles =
      editGenerationMarketLevelValue(
        updatedCell,
        updatedPhysicalSegmentsProfile.physical_segments_profiles!,
        generationPhysicalSegment,
      );

    if (!useUniqueProfiles) {
      updatedPhysicalSegmentsProfile.physical_segments_profiles =
        copyGenerationEnergyProfileToTransmissionSegmentsEnergyProfile(
          updatedPhysicalSegmentsProfile.physical_segments_profiles,
        );
    }

    if (isSameTransAllocProfile) {
      let previousPhysicalSegmentRef: number = -1;
      let previousAdjustedContractNumber = '';

      Object.keys(updatedRow).forEach((key: string) => {
        if (
          !key.match(TRANSMISSION_POD_KEY_REG_EXP) &&
          !key.match(TRANSMISSION_POR_KEY_REG_EXP) &&
          !key.match(TRANSMISSION_LOSS_KEY_REG_EXP) &&
          !key.match(TRANSMISSION_ALLOC_TOTALS_KEY_REG_EXP)
        ) {
          const profileTransmission: IProfileTransmission | undefined =
            getProfileTransmissionForKey(key);

          if (profileTransmission !== undefined) {
            const { adjustedContractNumber, physicalSegmentRef, transAllocId } =
              profileTransmission;

            if (physicalSegmentRef !== null) {
              updatedPhysicalSegmentsProfile.physical_segments_profiles =
                editTransmissionValue(
                  physicalSegmentRef,
                  transAllocId,
                  adjustedContractNumber,
                  previousPhysicalSegmentRef === physicalSegmentRef &&
                    previousAdjustedContractNumber === adjustedContractNumber
                    ? null
                    : updatedCell,
                  updatedPhysicalSegmentsProfile.physical_segments_profiles!,
                  updatedPhysicalSegmentsProfile.physical_segments_profiles!
                    .generation,
                  transmissionPhysicalSegments,
                  transmissionAllocations,
                );

              previousPhysicalSegmentRef = physicalSegmentRef;
              previousAdjustedContractNumber = adjustedContractNumber;
            }
          }
        }
      });
    }
  } else if (LOAD_MARKET_LEVEL_KEY_REG_EXP.test(key)) {
    updatedPhysicalSegmentsProfile.physical_segments_profiles =
      editLoadMarketLevelValue(
        updatedCell,
        updatedPhysicalSegmentsProfile.physical_segments_profiles!,
        loadPhysicalSegment,
        transmissionPhysicalSegments,
      );
  } else {
    const physicalSegmentRef: number | null | undefined =
      getPhysicalSegmentRefForTransmissionPodkey(key);

    if (physicalSegmentRef !== undefined) {
      if (physicalSegmentRef !== null) {
        updatedPhysicalSegmentsProfile.physical_segments_profiles =
          editTransmissionPODValue(
            physicalSegmentRef,
            updatedCell,
            updatedPhysicalSegmentsProfile.physical_segments_profiles!,
            updatedPhysicalSegmentsProfile.physical_segments_profiles!
              .generation,
            transmissionPhysicalSegments,
          );
      }
    } else {
      const profileTransmission: IProfileTransmission | undefined =
        getProfileTransmissionForKey(key);

      if (profileTransmission !== undefined) {
        const { adjustedContractNumber, physicalSegmentRef, transAllocId } =
          profileTransmission;

        if (physicalSegmentRef !== null) {
          updatedPhysicalSegmentsProfile.physical_segments_profiles =
            editTransmissionValue(
              physicalSegmentRef,
              transAllocId,
              adjustedContractNumber,
              updatedCell,
              updatedPhysicalSegmentsProfile.physical_segments_profiles!,
              updatedPhysicalSegmentsProfile.physical_segments_profiles!
                .generation,
              transmissionPhysicalSegments,
              transmissionAllocations,
            );
        }
      }
    }
  }

  return updatedPhysicalSegmentsProfile;
};

const getUpdatedDetailStateForEditProfileDataGridRows = (
  editProfileDataGridRow: IEditProfileDataGridRow,
  detailState: IDetailState,
  timeZone: TTimeZone,
): IDetailState => {
  const { isSameTransAllocProfile, row, updatedRow } = editProfileDataGridRow;

  checkRowId(row);

  const {
    generationPhysicalSegment,
    loadPhysicalSegment,
    physicalSegmentsProfiles,
    transmission_physical_segments,
    transmissionAllocations,
    useUniqueProfiles,
  } = detailState;

  if (physicalSegmentsProfiles === null) {
    return detailState;
  }

  const foundIndex: number = physicalSegmentsProfiles.findIndex(
    (eTagPhysicalSegmentsProfile: IETagPhysicalSegmentsProfile): boolean =>
      eTagPhysicalSegmentsProfile.key === row.id!.value,
  );
  const updatedDetailState: IDetailState = { ...detailState };
  const updatedPhysicalSegmentsProfiles: IETagPhysicalSegmentsProfile[] = [
    ...physicalSegmentsProfiles,
  ];
  let updatedPhysicalSegmentsProfile: IETagPhysicalSegmentsProfile;

  if (foundIndex === -1) {
    // Edits must always occur on existing rows. The only exception is
    // when there are no existing physicalSegmentsProfiles, so we must
    // create an initial one.
    if (updatedPhysicalSegmentsProfiles.length > 0) {
      throw new Error(
        `Invalid editProfileDataGridRow: ${JSON.stringify(
          editProfileDataGridRow,
        )} for physicalSegmentsProfiles: ${JSON.stringify(
          updatedPhysicalSegmentsProfiles,
        )}`,
      );
    }

    const newEditInfoKey: string = getEditInfoKey(
      EDIT_ETAG_PHYSICAL_SEGMENTS_PROFILE_LABEL,
      1,
      0,
    );
    updatedPhysicalSegmentsProfile = getInitialPhysicalSegmentsProfile(
      newEditInfoKey,
      null,
      null,
      generationPhysicalSegment,
      loadPhysicalSegment,
      transmission_physical_segments,
      transmissionAllocations,
    );

    Object.keys(updatedRow).forEach((key: string) => {
      updatedPhysicalSegmentsProfile = editPhysicalSegmentsProfile(
        updatedPhysicalSegmentsProfile,
        key,
        updatedRow,
        isSameTransAllocProfile,
        generationPhysicalSegment,
        loadPhysicalSegment,
        transmission_physical_segments,
        transmissionAllocations,
        useUniqueProfiles,
        timeZone,
      );
    });

    updatedPhysicalSegmentsProfiles.push(updatedPhysicalSegmentsProfile);
  } else {
    const physicalSegmentsProfile: IETagPhysicalSegmentsProfile | undefined =
      updatedPhysicalSegmentsProfiles[foundIndex];
    updatedPhysicalSegmentsProfile = physicalSegmentsProfile;

    if (updatedPhysicalSegmentsProfile.physical_segments_profiles === null) {
      updatedPhysicalSegmentsProfile = {
        ...updatedPhysicalSegmentsProfile,
        physical_segments_profiles: getInitialPhysicalSegmentsProfiles(
          generationPhysicalSegment,
          loadPhysicalSegment,
          transmission_physical_segments,
          transmissionAllocations,
        ),
      };
    }

    Object.keys(updatedRow).forEach((key: string) => {
      try {
        if (row[key] !== updatedRow[key]) {
          updatedPhysicalSegmentsProfile = editPhysicalSegmentsProfile(
            updatedPhysicalSegmentsProfile,
            key,
            updatedRow,
            isSameTransAllocProfile,
            generationPhysicalSegment,
            loadPhysicalSegment,
            transmission_physical_segments,
            transmissionAllocations,
            useUniqueProfiles,
            timeZone,
          );
        }
      } catch (error: any) {
        captureError(error);

        throw new Error(
          `Invalid data grid cell type for row with key: ${
            row.id!.value
          } and cell with key: ${key}`,
        );
      }
    });

    updatedPhysicalSegmentsProfiles[foundIndex] =
      updatedPhysicalSegmentsProfile;
  }

  updatedDetailState.physicalSegmentsProfiles = updatedPhysicalSegmentsProfiles;

  // Handle Hourly Integrated mode switch when start to stop interval
  // is not an hour
  if (updatedDetailState.selectedProfileFormat === EProfileFormat.Hourly) {
    let shouldUpdateSelectedProfileFormat: boolean = false;

    if (
      updatedPhysicalSegmentsProfile.start === null ||
      updatedPhysicalSegmentsProfile.stop === null
    ) {
      shouldUpdateSelectedProfileFormat = true;
    } else {
      const start: ZonedDateTime = ZonedDateTime.parseIso(
        updatedPhysicalSegmentsProfile.start,
        timeZone,
      );
      const stop: ZonedDateTime = ZonedDateTime.parseIso(
        updatedPhysicalSegmentsProfile.stop,
        timeZone,
      );

      if (
        start.getMinute() !== 0 ||
        stop.getMinute() !== 0 ||
        stop.diff(start, 'minutes') !== 60
      ) {
        shouldUpdateSelectedProfileFormat = true;
      }
    }

    if (shouldUpdateSelectedProfileFormat) {
      updatedDetailState.selectedProfileFormat = EProfileFormat.StartStop;
    }
  }

  return updatedDetailState;
};

const updateDetailStateStartEnd = (detailState: IDetailState) => {
  if (
    detailState.physicalSegmentsProfiles !== null &&
    detailState.physicalSegmentsProfiles.length > 0
  ) {
    const start: string | null = detailState.physicalSegmentsProfiles[0].start;
    const stop: string | null =
      detailState.physicalSegmentsProfiles[
        detailState.physicalSegmentsProfiles.length - 1
      ].stop;

    if (!isEmptyValue(start) && start !== detailState.start_date) {
      detailState.start_date = start;
    }

    if (!isEmptyValue(stop) && stop !== detailState.end_date) {
      detailState.end_date = stop;
    }
  }
};

export const editProfileDataGridToDetailState = (
  editProfileDataGrid: IEditProfileDataGrid,
  timeZone: TTimeZone,
): TStateTransform<IDetailState> => {
  return (detailState: IDetailState): IDetailState => {
    const {
      generationPhysicalSegment,
      loadPhysicalSegment,
      transmission_physical_segments,
      transmissionAllocations,
      useUniqueProfiles,
    } = detailState;
    const {
      addAfterRow,
      addBeforeRow,
      addExtraRows,
      editProfileDataGridRows,
      removeAfterRow,
      removeBeforeRow,
      removeRows,
    } = editProfileDataGrid;
    let updatedDetailState: IDetailState = detailState;

    if (updatedDetailState.physicalSegmentsProfiles !== null) {
      if (addAfterRow !== undefined) {
        updatedDetailState = { ...detailState };

        updatedDetailState.physicalSegmentsProfiles = addRow(
          addAfterRow,
          true,
          updatedDetailState.physicalSegmentsProfiles!,
          generationPhysicalSegment,
          loadPhysicalSegment,
          transmission_physical_segments,
          transmissionAllocations,
          timeZone,
          useUniqueProfiles,
        );
      }

      if (addBeforeRow !== undefined) {
        updatedDetailState = { ...detailState };

        updatedDetailState.physicalSegmentsProfiles = addRow(
          addBeforeRow,
          false,
          updatedDetailState.physicalSegmentsProfiles!,
          generationPhysicalSegment,
          loadPhysicalSegment,
          transmission_physical_segments,
          transmissionAllocations,
          timeZone,
          useUniqueProfiles,
        );
      }

      if (addExtraRows !== undefined) {
        updatedDetailState = { ...detailState };

        let updatedPhysicalSegmentsProfiles: IETagPhysicalSegmentsProfile[] = [
          ...updatedDetailState.physicalSegmentsProfiles!,
        ];
        let addAfterRow: TProfileDataGridRow = {
          id: {
            type: EProfileDataGridCellType.String,
            value:
              updatedPhysicalSegmentsProfiles[
                updatedPhysicalSegmentsProfiles.length - 1
              ].key,
          },
        };

        addExtraRows.forEach((profileDataGridRow: TProfileDataGridRow) => {
          updatedPhysicalSegmentsProfiles = addRow(
            addAfterRow,
            true,
            updatedPhysicalSegmentsProfiles,
            generationPhysicalSegment,
            loadPhysicalSegment,
            transmission_physical_segments,
            transmissionAllocations,
            timeZone,
            useUniqueProfiles,
            profileDataGridRow,
          );

          addAfterRow = {
            id: {
              type: EProfileDataGridCellType.String,
              value:
                updatedPhysicalSegmentsProfiles[
                  updatedPhysicalSegmentsProfiles.length - 1
                ].key,
            },
          };
        });

        updatedDetailState.physicalSegmentsProfiles =
          updatedPhysicalSegmentsProfiles;
      }

      if (editProfileDataGridRows !== undefined) {
        for (let i: number = 0; i < editProfileDataGridRows.length; i += 1) {
          updatedDetailState = getUpdatedDetailStateForEditProfileDataGridRows(
            editProfileDataGridRows[i],
            updatedDetailState,
            timeZone,
          );
        }
      }

      if (removeRows !== undefined) {
        const { fromRow, toRow } = removeRows;

        updatedDetailState = { ...detailState };

        const updatedPhysicalSegmentsProfiles: IETagPhysicalSegmentsProfile[] =
          [...updatedDetailState.physicalSegmentsProfiles!];

        updatedPhysicalSegmentsProfiles.splice(fromRow, toRow - fromRow + 1);

        updatedDetailState.physicalSegmentsProfiles =
          updatedPhysicalSegmentsProfiles;
      }

      if (removeAfterRow !== undefined) {
        updatedDetailState = { ...detailState };

        const updatedPhysicalSegmentsProfiles: IETagPhysicalSegmentsProfile[] =
          [];

        for (
          let i: number = 0;
          i < updatedDetailState.physicalSegmentsProfiles!.length;
          i += 1
        ) {
          const physicalSegmentsProfile: IETagPhysicalSegmentsProfile =
            updatedDetailState.physicalSegmentsProfiles![i];

          updatedPhysicalSegmentsProfiles.push(physicalSegmentsProfile);

          if (physicalSegmentsProfile.key === removeAfterRow.id!.value) {
            break;
          }
        }

        updatedDetailState.physicalSegmentsProfiles =
          updatedPhysicalSegmentsProfiles;
      }

      if (removeBeforeRow !== undefined) {
        updatedDetailState = { ...detailState };

        const updatedPhysicalSegmentsProfiles: IETagPhysicalSegmentsProfile[] =
          [];
        let includePhysicalSegmentsProfile: boolean = false;

        for (
          let i: number = 0;
          i < updatedDetailState.physicalSegmentsProfiles!.length;
          i += 1
        ) {
          const physicalSegmentsProfile: IETagPhysicalSegmentsProfile =
            updatedDetailState.physicalSegmentsProfiles![i];

          if (physicalSegmentsProfile.key === removeBeforeRow.id!.value) {
            includePhysicalSegmentsProfile = true;
          }

          if (includePhysicalSegmentsProfile) {
            updatedPhysicalSegmentsProfiles.push(physicalSegmentsProfile);
          }
        }

        updatedDetailState.physicalSegmentsProfiles =
          updatedPhysicalSegmentsProfiles;
      }
    }

    updateDetailStateStartEnd(updatedDetailState);

    return updatedDetailState;
  };
};

export const adHocProfilesToDetailState = (
  adHocProfile: IAdHocProfile,
  isSameTransAllocProfile: boolean,
): TStateTransform<IDetailState> => {
  return (detailState: IDetailState): IDetailState => {
    const {
      generationPhysicalSegment,
      loadPhysicalSegment,
      transmission_physical_segments,
      transmissionAllocations,
    } = detailState;
    const { genValues, startStopDateTimes } =
      getStartStopDateTimesForAdHocProfile(adHocProfile);
    const physicalSegmentsProfiles: IETagPhysicalSegmentsProfile[] = [];
    // We start off at 1 since the first index (0) is the INITIAL_RECORD_ID
    let primaryId: number = 1;

    for (
      let i: number = 0, j: number = 0;
      i < startStopDateTimes.length;
      i += 2, j += 1
    ) {
      const start: ZonedDateTime = startStopDateTimes[i];
      const stop: ZonedDateTime = startStopDateTimes[i + 1];
      const physicalSegmentsProfile: IETagPhysicalSegmentsProfile =
        getInitialPhysicalSegmentsProfile(
          getEditInfoKey(
            EDIT_ETAG_PHYSICAL_SEGMENTS_PROFILE_LABEL,
            primaryId,
            0,
          ),
          toFormattedUtcString(start),
          toFormattedUtcString(stop),
          generationPhysicalSegment,
          loadPhysicalSegment,
          transmission_physical_segments,
          transmissionAllocations,
        );
      const genValue: number | null =
        genValues.length > 0 ? genValues[j] : null;

      if (genValue !== null) {
        if (physicalSegmentsProfile.physical_segments_profiles === null) {
          throw new Error(
            `Invalid physical_segments_profiles for physicalSegmentsProfile: ${JSON.stringify(
              physicalSegmentsProfile,
            )}`,
          );
        }

        const { generation, transmission } =
          physicalSegmentsProfile.physical_segments_profiles;

        if (generation === null) {
          throw new Error(
            `Invalid generation for physicalSegmentsProfile: ${JSON.stringify(
              physicalSegmentsProfile,
            )}`,
          );
        }

        if (generation.profile === null) {
          throw new Error(
            `Invalid profile for generation: ${JSON.stringify(generation)}`,
          );
        }

        generation.profile.market_level = genValue;
        generation.profile.mw = genValue;

        if (isSameTransAllocProfile) {
          if (
            transmission === null ||
            transmission.transmission_segments === null
          ) {
            throw new Error(
              `Invalid transmission for physicalSegmentsProfile: ${JSON.stringify(
                physicalSegmentsProfile,
              )}`,
            );
          }

          transmission.transmission_segments.forEach(
            (eTagTransmissionSegment: IETagTransmissionSegment) => {
              if (eTagTransmissionSegment.trans_alloc_profiles !== null) {
                eTagTransmissionSegment.trans_alloc_profiles.forEach(
                  (
                    eTagTransmissionAllocationProfile: IETagTransmissionAllocationProfile,
                  ) => {
                    eTagTransmissionAllocationProfile.profile_mw = genValue;
                  },
                );
              }
            },
          );
        }
      }

      physicalSegmentsProfiles.push(physicalSegmentsProfile);

      primaryId += 1;
    }

    const updatedDetailState: IDetailState = {
      ...detailState,
      physicalSegmentsProfiles,
    };

    updateDetailStateStartEnd(updatedDetailState);

    return updatedDetailState;
  };
};

export const productProfilesToDetailState = (
  combinedIntervals: IProfileInterval[],
  isSameTransAllocProfile: boolean,
): TStateTransform<IDetailState> => {
  return (detailState: IDetailState): IDetailState => {
    const {
      generationPhysicalSegment,
      loadPhysicalSegment,
      transmission_physical_segments,
      transmissionAllocations,
    } = detailState;

    const physicalSegmentsProfiles: IETagPhysicalSegmentsProfile[] = [];
    // We start off at 1 since the first index (0) is the INITIAL_RECORD_ID
    let primaryId: number = 1;

    for (let i: number = 0; i < combinedIntervals.length; i += 1) {
      const start: ZonedDateTime = combinedIntervals[i].startDateTime;
      const stop: ZonedDateTime = combinedIntervals[i].stopDateTime;
      const genValue: number | null = combinedIntervals[i].genRequest;
      const physicalSegmentsProfile: IETagPhysicalSegmentsProfile =
        getInitialPhysicalSegmentsProfile(
          getEditInfoKey(
            EDIT_ETAG_PHYSICAL_SEGMENTS_PROFILE_LABEL,
            primaryId,
            0,
          ),
          toFormattedUtcString(start),
          toFormattedUtcString(stop),
          generationPhysicalSegment,
          loadPhysicalSegment,
          transmission_physical_segments,
          transmissionAllocations,
        );

      if (genValue !== null) {
        if (physicalSegmentsProfile.physical_segments_profiles === null) {
          throw new Error(
            `Invalid physical_segments_profiles for physicalSegmentsProfile: ${JSON.stringify(
              physicalSegmentsProfile,
            )}`,
          );
        }

        const { generation, transmission } =
          physicalSegmentsProfile.physical_segments_profiles;

        if (generation === null) {
          throw new Error(
            `Invalid generation for physicalSegmentsProfile: ${JSON.stringify(
              physicalSegmentsProfile,
            )}`,
          );
        }

        if (generation.profile === null) {
          throw new Error(
            `Invalid profile for generation: ${JSON.stringify(generation)}`,
          );
        }

        generation.profile.market_level = genValue;
        generation.profile.mw = genValue;

        if (isSameTransAllocProfile) {
          if (
            transmission === null ||
            transmission.transmission_segments === null
          ) {
            throw new Error(
              `Invalid transmission for physicalSegmentsProfile: ${JSON.stringify(
                physicalSegmentsProfile,
              )}`,
            );
          }

          transmission.transmission_segments.forEach(
            (eTagTransmissionSegment: IETagTransmissionSegment) => {
              if (eTagTransmissionSegment.trans_alloc_profiles !== null) {
                eTagTransmissionSegment.trans_alloc_profiles.forEach(
                  (
                    eTagTransmissionAllocationProfile: IETagTransmissionAllocationProfile,
                  ) => {
                    eTagTransmissionAllocationProfile.profile_mw = genValue;
                  },
                );
              }
            },
          );
        }
      }

      physicalSegmentsProfiles.push(physicalSegmentsProfile);

      primaryId += 1;
    }

    const updatedDetailState: IDetailState = {
      ...detailState,
      physicalSegmentsProfiles,
    };

    updateDetailStateStartEnd(updatedDetailState);

    return updatedDetailState;
  };
};

export const updatePhysicalSegmentsProfiles = (
  physicalSegmentsProfiles: IETagPhysicalSegmentsProfile[] | null,
): TStateTransform<IDetailState> => {
  return (detailState: IDetailState): IDetailState => {
    return { ...detailState, physicalSegmentsProfiles };
  };
};
