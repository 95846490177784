import { Badge as AntDesignBadge } from 'antd';
import { BADGE_Z_INDEX } from 'constants/styles';
import useIsDocumentVisible from 'hooks/useIsDocumentVisible';
import { ReactNode } from 'react';
import styled from 'styled-components';

interface IWrapperProps {
  isDocumentVisible?: boolean;
}

const Wrapper = styled.div<IWrapperProps>`
  ${(props) => (props.isDocumentVisible ? '' : 'display: none;')}

  .ant-badge-count {
    z-index: ${BADGE_Z_INDEX};
  }
`;

interface IBadgeProps {
  children?: ReactNode;
  count?: number;
  offset?: [number, number];
}

const Badge = ({ children, count, offset }: IBadgeProps): JSX.Element => {
  // Note: The AntDesignBadge has an issue in which the count value can become
  // stuck when a tab/window loses visibility. Here we force a re-render of the
  // DOM element in order to get the correct count value to appear.
  const isDocumentVisible: boolean = useIsDocumentVisible();

  return (
    <Wrapper isDocumentVisible={isDocumentVisible}>
      <AntDesignBadge count={count} offset={offset}>
        {children}
      </AntDesignBadge>
    </Wrapper>
  );
};

export default Badge;
