import {
  IEditMarketPathInformation,
  IEditMarketSegment,
} from 'components/organisms/MarketPathView/types';
import {
  EDIT_MARKET_SEGMENT_CONTACT_INFO_LABEL,
  EDIT_MARKET_SEGMENT_CONTRACTS_LABEL,
  EDIT_MARKET_SEGMENT_MISC_INFO_LABEL,
} from 'constants/Detail';
import { IETagTransmissionPhysicalSegment } from 'interfaces/ETag';
import { IContract, IMiscInfo } from 'interfaces/General';
import { IDetailMarketSegment, IDetailState } from 'reduxes/Detail/types';
import { TStateTransform } from 'types/General';
import {
  copyDetailGenerationPhysicalSegment,
  copyDetailLoadPhysicalSegment,
  copyDetailMarketSegment,
  copyTransmissionPhysicalSegment,
  getEditInfoKey,
  getInitialContactInfo,
  getInitialContract,
  getInitialMarketSegment,
  getInitialMiscInfo,
  getSplitEditInfoKey,
} from 'utils/detail';

export const editMarketPathInformationToDetailState = (
  editMarketPathInformation: IEditMarketPathInformation,
): TStateTransform<IDetailState> => {
  return (detailState: IDetailState): IDetailState => {
    const updatedDetailState: IDetailState = { ...detailState };
    const updatedMarketSegments: IDetailMarketSegment[] =
      updatedDetailState.marketSegments === null
        ? []
        : [...updatedDetailState.marketSegments];
    const {
      addAfterMarketSegmentId,
      editMarketSegmentMap,
      removeMarketSegmentId,
    } = editMarketPathInformation;

    if (addAfterMarketSegmentId !== undefined) {
      let indexOfMatching: number = -1;

      updatedMarketSegments.forEach(
        (detailMarketSegment: IDetailMarketSegment, index: number) => {
          if (
            detailMarketSegment.market_segment_id === addAfterMarketSegmentId
          ) {
            indexOfMatching = index;
          }

          if (detailMarketSegment.market_segment_id > addAfterMarketSegmentId) {
            updatedMarketSegments[index] =
              copyDetailMarketSegment(detailMarketSegment);

            updatedMarketSegments[index].market_segment_id += 1;
          }
        },
      );

      updatedMarketSegments.splice(
        indexOfMatching + 1,
        0,
        getInitialMarketSegment(addAfterMarketSegmentId + 1),
      );

      updatedDetailState.marketSegments = updatedMarketSegments;

      // Update market segment references
      const {
        generationPhysicalSegment,
        loadPhysicalSegment,
        transmission_physical_segments,
      } = updatedDetailState;

      if (generationPhysicalSegment !== null) {
        if (
          generationPhysicalSegment.market_segment_id !== null &&
          generationPhysicalSegment.market_segment_id > addAfterMarketSegmentId
        ) {
          updatedDetailState.generationPhysicalSegment =
            copyDetailGenerationPhysicalSegment(generationPhysicalSegment);
          updatedDetailState.generationPhysicalSegment!.market_segment_id! += 1;
        }
      }

      if (loadPhysicalSegment !== null) {
        if (
          loadPhysicalSegment.market_segment_id !== null &&
          loadPhysicalSegment.market_segment_id > addAfterMarketSegmentId
        ) {
          updatedDetailState.loadPhysicalSegment =
            copyDetailLoadPhysicalSegment(loadPhysicalSegment);
          updatedDetailState.loadPhysicalSegment!.market_segment_id! += 1;
        }
      }

      if (transmission_physical_segments !== null) {
        updatedDetailState.transmission_physical_segments = [];

        transmission_physical_segments.forEach(
          (transmissionPhysicalSegment: IETagTransmissionPhysicalSegment) => {
            if (
              transmissionPhysicalSegment.market_segment_id !== null &&
              transmissionPhysicalSegment.market_segment_id >
                addAfterMarketSegmentId
            ) {
              const updatedTransmissionPhysicalSegment: IETagTransmissionPhysicalSegment =
                copyTransmissionPhysicalSegment(transmissionPhysicalSegment);

              updatedTransmissionPhysicalSegment.market_segment_id! += 1;
              updatedDetailState.transmission_physical_segments!.push(
                updatedTransmissionPhysicalSegment,
              );
            } else {
              updatedDetailState.transmission_physical_segments!.push(
                transmissionPhysicalSegment,
              );
            }
          },
        );
      }
    }

    if (editMarketSegmentMap !== undefined) {
      for (let marketSegmentIdKey in editMarketSegmentMap) {
        const editMarketSegment: IEditMarketSegment =
          editMarketSegmentMap[marketSegmentIdKey];
        const market_segment_id: number = parseInt(marketSegmentIdKey, 10);
        const marketSegmentIndex: number = updatedMarketSegments.findIndex(
          (detailMarketSegment: IDetailMarketSegment): boolean =>
            detailMarketSegment.market_segment_id === market_segment_id,
        );
        const updatedMarketSegment: IDetailMarketSegment | undefined =
          marketSegmentIndex === -1
            ? getInitialMarketSegment(market_segment_id)
            : copyDetailMarketSegment(
                updatedMarketSegments[marketSegmentIndex],
              );

        const {
          editContactInfos,
          editContracts,
          editMiscInfos,
          energy_product_ref,
          pse,
        } = editMarketSegment;

        if (editContactInfos !== undefined) {
          const { editContactInfo } = editContactInfos;

          if (editContactInfo === null) {
            updatedMarketSegment.contactInfos = null;
          } else {
            updatedMarketSegment.contactInfos = [
              {
                ...(updatedMarketSegment.contactInfos === null
                  ? getInitialContactInfo(
                      getEditInfoKey(
                        EDIT_MARKET_SEGMENT_CONTACT_INFO_LABEL,
                        market_segment_id,
                        0,
                      ),
                    )
                  : updatedMarketSegment.contactInfos[0]),
                ...editContactInfo,
              },
            ];
          }

          updatedDetailState.focusKey = null;
        }

        if (editContracts !== undefined) {
          const { addAfterContract, editContractMap, removeContract } =
            editContracts;

          if (updatedMarketSegment.contracts === null) {
            updatedMarketSegment.contracts = [];
          } else {
            updatedMarketSegment.contracts = [
              ...updatedMarketSegment.contracts,
            ];
          }

          if (addAfterContract !== undefined) {
            let foundIndex: number = -1;
            let highestIndex: number = -1;

            updatedMarketSegment.contracts.forEach(
              (contract: IContract, index: number) => {
                const { editIndex } = getSplitEditInfoKey(contract.key);

                if (editIndex === undefined) {
                  throw new Error(`Invalid contract.key: ${contract.key}`);
                }

                if (editIndex > highestIndex) {
                  highestIndex = editIndex;
                }

                if (contract.key === addAfterContract) {
                  foundIndex = index;
                }
              },
            );

            if (foundIndex !== -1) {
              const newEditInfoKey: string = getEditInfoKey(
                EDIT_MARKET_SEGMENT_CONTRACTS_LABEL,
                market_segment_id,
                highestIndex + 1,
              );

              updatedMarketSegment.contracts.splice(
                foundIndex + 1,
                0,
                getInitialContract(newEditInfoKey),
              );

              updatedDetailState.focusKey = newEditInfoKey;
            }
          }

          if (editContractMap !== undefined) {
            for (let contractKey in editContractMap) {
              let contractIndex: number =
                updatedMarketSegment.contracts.findIndex(
                  (contract: IContract): boolean =>
                    contract.key === contractKey,
                );
              let updatedContract: IContract;

              if (contractIndex === -1) {
                updatedContract = getInitialContract(contractKey);
                contractIndex = updatedMarketSegment.contracts.length;
              } else {
                updatedContract = updatedMarketSegment.contracts[contractIndex];
              }

              updatedMarketSegment.contracts[contractIndex] = {
                ...updatedContract,
                ...editContractMap[contractKey],
              };
            }

            updatedDetailState.focusKey = null;
          }

          if (removeContract !== undefined) {
            const foundIndex: number = updatedMarketSegment.contracts.findIndex(
              (contract: IContract): boolean => contract.key === removeContract,
            );

            if (foundIndex !== -1) {
              updatedMarketSegment.contracts.splice(foundIndex, 1);
            }

            if (updatedMarketSegment.contracts.length === 0) {
              updatedMarketSegment.contracts = null;
            }
          }
        }

        if (editMiscInfos !== undefined) {
          const { addAfterMiscInfo, editMiscInfoMap, removeMiscInfo } =
            editMiscInfos;

          if (updatedMarketSegment.misc_infos === null) {
            updatedMarketSegment.misc_infos = [];
          } else {
            updatedMarketSegment.misc_infos = [
              ...updatedMarketSegment.misc_infos,
            ];
          }

          if (addAfterMiscInfo !== undefined) {
            let foundIndex: number = -1;
            let highestIndex: number = -1;

            updatedMarketSegment.misc_infos.forEach(
              (miscInfo: IMiscInfo, index: number) => {
                const { editIndex } = getSplitEditInfoKey(miscInfo.key);

                if (editIndex === undefined) {
                  throw new Error(`Invalid miscInfo.key: ${miscInfo.key}`);
                }

                if (editIndex > highestIndex) {
                  highestIndex = editIndex;
                }

                if (miscInfo.key === addAfterMiscInfo) {
                  foundIndex = index;
                }
              },
            );

            if (foundIndex !== -1) {
              const newEditInfoKey: string = getEditInfoKey(
                EDIT_MARKET_SEGMENT_MISC_INFO_LABEL,
                market_segment_id,
                highestIndex + 1,
              );

              updatedMarketSegment.misc_infos.splice(
                foundIndex + 1,
                0,
                getInitialMiscInfo(newEditInfoKey),
              );

              updatedDetailState.focusKey = newEditInfoKey;
            }
          }

          if (editMiscInfoMap !== undefined) {
            for (let miscInfoKey in editMiscInfoMap) {
              let miscInfoIndex: number =
                updatedMarketSegment.misc_infos.findIndex(
                  (miscInfo: IMiscInfo): boolean =>
                    miscInfo.key === miscInfoKey,
                );
              let updatedMiscInfo: IMiscInfo;

              if (miscInfoIndex === -1) {
                updatedMiscInfo = getInitialMiscInfo(miscInfoKey);
                miscInfoIndex = updatedMarketSegment.misc_infos.length;
              } else {
                updatedMiscInfo =
                  updatedMarketSegment.misc_infos[miscInfoIndex];
              }

              updatedMarketSegment.misc_infos[miscInfoIndex] = {
                ...updatedMiscInfo,
                ...editMiscInfoMap[miscInfoKey],
              };
            }

            updatedDetailState.focusKey = null;
          }

          if (removeMiscInfo !== undefined) {
            const foundIndex: number =
              updatedMarketSegment.misc_infos.findIndex(
                (miscInfo: IMiscInfo): boolean =>
                  miscInfo.key === removeMiscInfo,
              );

            if (foundIndex !== -1) {
              updatedMarketSegment.misc_infos.splice(foundIndex, 1);
            }

            if (updatedMarketSegment.misc_infos.length === 0) {
              updatedMarketSegment.misc_infos = null;
            }
          }
        }

        if (energy_product_ref !== undefined) {
          updatedMarketSegment.energy_product_ref = energy_product_ref;
        }

        if (pse !== undefined) {
          updatedMarketSegment.pse = pse;

          // Update physical segment references
          const {
            generationPhysicalSegment,
            loadPhysicalSegment,
            transmission_physical_segments,
          } = updatedDetailState;

          if (
            generationPhysicalSegment !== null &&
            generationPhysicalSegment.market_segment_id === market_segment_id
          ) {
            updatedDetailState.generationPhysicalSegment =
              copyDetailGenerationPhysicalSegment(
                updatedDetailState.generationPhysicalSegment,
              );

            updatedDetailState.generationPhysicalSegment!.pse = pse;
          }

          if (
            loadPhysicalSegment !== null &&
            loadPhysicalSegment.market_segment_id === market_segment_id
          ) {
            updatedDetailState.loadPhysicalSegment =
              copyDetailLoadPhysicalSegment(
                updatedDetailState.loadPhysicalSegment,
              );

            updatedDetailState.loadPhysicalSegment!.pse = pse;
          }

          if (transmission_physical_segments !== null) {
            const updatedTransmissionPhysicalSegments: IETagTransmissionPhysicalSegment[] =
              [];

            transmission_physical_segments.forEach(
              (
                transmissionPhysicalSegment: IETagTransmissionPhysicalSegment,
              ) => {
                if (
                  transmissionPhysicalSegment.market_segment_id ===
                  market_segment_id
                ) {
                  const updatedTransmissionPhysicalSegment: IETagTransmissionPhysicalSegment =
                    copyTransmissionPhysicalSegment(
                      transmissionPhysicalSegment,
                    );

                  updatedTransmissionPhysicalSegment.pse = pse;

                  updatedTransmissionPhysicalSegments.push(
                    updatedTransmissionPhysicalSegment,
                  );
                } else {
                  updatedTransmissionPhysicalSegments.push(
                    transmissionPhysicalSegment,
                  );
                }
              },
            );

            updatedDetailState.transmission_physical_segments =
              updatedTransmissionPhysicalSegments;
          }
        }

        updatedMarketSegments[
          marketSegmentIndex === -1
            ? updatedMarketSegments.length
            : marketSegmentIndex
        ] = updatedMarketSegment;
      }

      updatedDetailState.marketSegments = updatedMarketSegments;
    }

    if (removeMarketSegmentId !== undefined) {
      const newUpdatedMarketSegments: IDetailMarketSegment[] = [];

      updatedMarketSegments.forEach(
        (detailMarketSegment: IDetailMarketSegment) => {
          if (detailMarketSegment.market_segment_id > removeMarketSegmentId) {
            const updatedMarketSegment: IDetailMarketSegment =
              copyDetailMarketSegment(detailMarketSegment);

            updatedMarketSegment.market_segment_id -= 1;

            newUpdatedMarketSegments.push(updatedMarketSegment);
          } else if (
            detailMarketSegment.market_segment_id !== removeMarketSegmentId
          ) {
            newUpdatedMarketSegments.push(detailMarketSegment);
          }
        },
      );

      updatedDetailState.marketSegments = newUpdatedMarketSegments;

      // Update market segment references
      const {
        generationPhysicalSegment,
        loadPhysicalSegment,
        transmission_physical_segments,
      } = updatedDetailState;

      if (generationPhysicalSegment !== null) {
        if (generationPhysicalSegment.market_segment_id !== null) {
          if (
            generationPhysicalSegment.market_segment_id ===
            removeMarketSegmentId
          ) {
            updatedDetailState.generationPhysicalSegment =
              copyDetailGenerationPhysicalSegment(generationPhysicalSegment);
            updatedDetailState.generationPhysicalSegment!.market_segment_id =
              null;
            updatedDetailState.generationPhysicalSegment!.pse = null;
          } else if (
            generationPhysicalSegment.market_segment_id > removeMarketSegmentId
          ) {
            updatedDetailState.generationPhysicalSegment =
              copyDetailGenerationPhysicalSegment(generationPhysicalSegment);
            updatedDetailState.generationPhysicalSegment!.market_segment_id! -= 1;
          }
        }
      }

      if (loadPhysicalSegment !== null) {
        if (loadPhysicalSegment.market_segment_id !== null) {
          if (loadPhysicalSegment.market_segment_id === removeMarketSegmentId) {
            updatedDetailState.loadPhysicalSegment =
              copyDetailLoadPhysicalSegment(loadPhysicalSegment);
            updatedDetailState.loadPhysicalSegment!.market_segment_id = null;
            updatedDetailState.loadPhysicalSegment!.pse = null;
          } else if (
            loadPhysicalSegment.market_segment_id > removeMarketSegmentId
          ) {
            updatedDetailState.loadPhysicalSegment =
              copyDetailLoadPhysicalSegment(loadPhysicalSegment);
            updatedDetailState.loadPhysicalSegment!.market_segment_id! -= 1;
          }
        }
      }

      if (transmission_physical_segments !== null) {
        updatedDetailState.transmission_physical_segments = [];

        transmission_physical_segments.forEach(
          (transmissionPhysicalSegment: IETagTransmissionPhysicalSegment) => {
            if (
              transmissionPhysicalSegment.market_segment_id === null ||
              transmissionPhysicalSegment.market_segment_id <
                removeMarketSegmentId
            ) {
              updatedDetailState.transmission_physical_segments!.push(
                transmissionPhysicalSegment,
              );
            } else if (
              transmissionPhysicalSegment.market_segment_id ===
              removeMarketSegmentId
            ) {
              const updatedTransmissionPhysicalSegment: IETagTransmissionPhysicalSegment =
                copyTransmissionPhysicalSegment(transmissionPhysicalSegment);

              updatedTransmissionPhysicalSegment.market_segment_id = null;
              updatedTransmissionPhysicalSegment.pse = null;
              updatedDetailState.transmission_physical_segments!.push(
                updatedTransmissionPhysicalSegment,
              );
            } else if (
              transmissionPhysicalSegment.market_segment_id >
              removeMarketSegmentId
            ) {
              const updatedTransmissionPhysicalSegment: IETagTransmissionPhysicalSegment =
                copyTransmissionPhysicalSegment(transmissionPhysicalSegment);

              updatedTransmissionPhysicalSegment.market_segment_id! -= 1;
              updatedDetailState.transmission_physical_segments!.push(
                updatedTransmissionPhysicalSegment,
              );
            }
          },
        );
      }
    }

    return updatedDetailState;
  };
};
