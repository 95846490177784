import { TAB_KEY } from 'constants/General';

export const MARKET_INFO_PRICES_DATA_GRID_CELL_HOUR_KEY = 'hour';
export const MARKET_INFO_PRICES_DATA_GRID_CELL_MW_KEY = 'mw';
export const MARKET_INFO_PRICES_DATA_GRID_CELL_PRICE_KEY = 'price';

export const HOUR_EXTRA_SUFFIX = 'd';

export const HOUR_KEY_REGEX = new RegExp(
  `^HE-(\\d{2}(${HOUR_EXTRA_SUFFIX}?))$`,
);

export const EDITOR_OPTIONS = {
  onNavigation: (keyboardEvent: React.KeyboardEvent) =>
    keyboardEvent.key === TAB_KEY,
};
