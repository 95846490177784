import {
  ICsvHeaderData,
  TCsvData,
  TETagColumnDataMap,
} from 'components/molecules/ToEntityCsvExport/types';
import { BOOLEAN_FALSE_LABEL, BOOLEAN_TRUE_LABEL } from 'constants/misc';
import { CSV_DATE_FORMAT, CSV_DATE_TIME_FORMAT } from 'constants/time';
import { EColumnType } from 'enums/ETag';
import { IETagColumnData, IETagDataSet, IHeData } from 'interfaces/ETag';
import { IIndexable } from 'interfaces/General';
import {
  TToEntityDataTableSummary,
  TToEntityDataTableSummaryDataSet,
} from 'types/Component';
import { TTimeZone } from 'types/DateTime';
import { TMap } from 'types/General';
import {
  getKeyAndValueForToEntityDataTableSummary,
  getTagCountMessage,
} from 'utils/component';
import { isEmptyValue } from 'utils/general';
import { ZonedDateTime } from 'utils/zonedDateTime';

export const eTagColumnDatasToCsvHeaderData = (
  eTagColumnDatas: IETagColumnData[],
): ICsvHeaderData => {
  const csvHeaderData: ICsvHeaderData = {
    csvHeaders: [],
    eTagColumnDataMap: {},
  };

  eTagColumnDatas.forEach((eTagColumnData: IETagColumnData) => {
    csvHeaderData.csvHeaders.push({
      label: eTagColumnData.displayName,
      key: eTagColumnData.dataIndex,
    });

    csvHeaderData.eTagColumnDataMap[eTagColumnData.dataIndex] = eTagColumnData;
  });

  return csvHeaderData;
};

export const eTagDataSetsToCsvData = (
  eTagDataSets: IETagDataSet[],
  eTagColumnDataMap: TETagColumnDataMap,
  timeZone: TTimeZone,
  summaryDataSets: TToEntityDataTableSummaryDataSet[],
): TCsvData => {
  const csvData: TCsvData = eTagDataSets.map(
    (dataSet: IETagDataSet & IIndexable): Record<string, any> => {
      const flattenedDataSet: Record<string, any> = {};

      Object.keys(dataSet).forEach((key: string) => {
        const eTagColumnData: IETagColumnData | undefined =
          eTagColumnDataMap[key];
        if (eTagColumnData !== undefined) {
          switch (eTagColumnData.type) {
            case EColumnType.Boolean: {
              const value: boolean | null | undefined = dataSet[key];

              if (value === true) {
                flattenedDataSet[key] = BOOLEAN_TRUE_LABEL;
              } else if (value === false) {
                flattenedDataSet[key] = BOOLEAN_FALSE_LABEL;
              }
              break;
            }
            case EColumnType.Date: {
              const value: string | null | undefined = dataSet[key];

              if (!isEmptyValue(value)) {
                flattenedDataSet[key] = ZonedDateTime.parseIso(
                  value!,
                  timeZone,
                ).format(CSV_DATE_FORMAT);
              }
              break;
            }
            case EColumnType.DateTime: {
              const value: string | null | undefined = dataSet[key];

              if (!isEmptyValue(value)) {
                flattenedDataSet[key] = ZonedDateTime.parseIso(
                  value!,
                  timeZone,
                ).format(CSV_DATE_TIME_FORMAT);
              }
              break;
            }
            case EColumnType.HourEndings: {
              const value: IHeData | null | undefined = dataSet[key];

              if (!isEmptyValue(value) && !isEmptyValue(value!.energy)) {
                flattenedDataSet[key] = value!.energy!.mw;
              }
              break;
            }
            default: {
              flattenedDataSet[key] = dataSet[key];
              break;
            }
          }
        }
      });

      return flattenedDataSet;
    },
  ) as TCsvData;

  summaryDataSets.forEach(
    (summaryDataSet: TToEntityDataTableSummaryDataSet) => {
      const summaryRow: TMap<string, string | number> = {};

      summaryDataSet.forEach(
        (toEntityDataTableSummary: TToEntityDataTableSummary) => {
          const { key, value } = getKeyAndValueForToEntityDataTableSummary(
            toEntityDataTableSummary,
          );
          if (key !== undefined && value !== undefined) {
            summaryRow[key] = value.isTagCount
              ? getTagCountMessage(value.value as number)
              : value.value;
          }
        },
      );

      csvData.push(summaryRow);
    },
  );

  return csvData;
};
