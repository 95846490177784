interface IWithdrawnIconProps {
  className?: string;
}

const WithdrawnIcon = ({ className }: IWithdrawnIconProps) => (
  <svg
    aria-hidden='true'
    className={className}
    clipRule='evenodd'
    fillRule='evenodd'
    height='1em'
    strokeLinejoin='round'
    strokeMiterlimit='2'
    viewBox='0 0 1000 1000'
    width='1em'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle
      cx='413.804'
      cy='336.526'
      r='238.771'
      fill='#c33333'
      transform='translate(-366.528 -204.703) scale(2.09405)'
    />
    <path
      d='M499.175 381.88c-9.95 45.92-21.43 94.135-33.674 143.88L408.867 750h-91.073L159.372 250.245h97.196l60.46 198.984c18.368 62.756 32.91 119.39 43.624 169.901h2.296c11.48-54.338 23.725-110.206 38.266-167.605l52.041-201.28h94.135l51.276 201.28c14.542 56.634 26.787 112.502 38.267 167.605h2.295c13.776-60.46 28.317-116.329 43.624-169.901l60.46-198.984h97.196L682.086 750h-91.073L534.38 525.76c-12.245-50.51-23.724-98.725-32.908-143.88h-2.296z'
      fill='#fff'
      fillRule='nonzero'
    />
  </svg>
);

export default WithdrawnIcon;
