interface IApprovedIconProps {
  className?: string;
}

const ApprovedIcon = ({ className }: IApprovedIconProps): JSX.Element => (
  <svg
    aria-hidden='true'
    className={className}
    clipRule='evenodd'
    fillRule='evenodd'
    height='1em'
    strokeLinejoin='round'
    strokeMiterlimit='2'
    viewBox='0 0 1000 1000'
    width='1em'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle
      cx='413.804'
      cy='336.526'
      r='238.771'
      fill='#228b22'
      transform='translate(-366.528 -204.703) scale(2.09405)'
    />
    <path
      d='M594.43 622.191H406.161L357.181 750h-97.196l198.218-499.755h83.42L740.606 750h-97.195l-48.98-127.809zm-94.9-251.79c-17.602 52.042-32.909 95.665-46.684 130.87l-16.837 44.388h129.339l-17.602-44.388c-13.776-35.205-29.083-78.828-46.685-130.87h-1.53z'
      fill='#fff'
      fillRule='nonzero'
    />
  </svg>
);

export default ApprovedIcon;
