import * as am4core from '@amcharts/amcharts4/core';
import { EProfileSegment } from 'enums/ETag';
import { ZonedDateTime } from 'utils/zonedDateTime';

export interface IChartData {
  [index: string]: boolean | number | string | undefined;
  bulletColour: string | undefined;
  bulletLocationX: number;
  dateTime: number;
  lineColour: string | undefined;
  start: string;
  stop: string;
}

export enum EBulletShape {
  Circle = 'circle',
  Diamond = 'diamond',
}

export interface IHighlightRange {
  color: am4core.Color;
  opacity: number;
  start: ZonedDateTime;
  stop: ZonedDateTime;
}

export interface IProfileSegment {
  physicalSegmentRef?: number;
  profileSegment: EProfileSegment;
}
