import { ZonedDateTime } from '../utils/zonedDateTime';
import { TTimeZone, TZonedDateTimeRange } from '../types/DateTime';
import { useState } from 'react';

const useProfileDateTimeRange = (
  startDate: string | null | undefined,
  endDate: string | null | undefined,
  timeZone: TTimeZone,
) => {
  const currentDate = ZonedDateTime.now(timeZone);

  const parseDate = (date: string | null) =>
    date ? ZonedDateTime.parseIso(date, timeZone) : null;

  const getDateTimeRange = (): TZonedDateTimeRange => {
    const parsedStartDate = parseDate(startDate ? startDate : null);
    const parsedEndDate = parseDate(endDate ? endDate : null);

    if (!parsedStartDate) {
      return null;
    }

    if (currentDate.isAfter(parsedStartDate)) {
      return [currentDate, parsedEndDate];
    }

    return [parsedStartDate, parsedEndDate];
  };

  return useState<TZonedDateTimeRange>(getDateTimeRange());
};

export default useProfileDateTimeRange;
