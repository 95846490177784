import { Button as AntDesignButton } from 'antd';
import usePermissions, { IusePermissionsProps } from 'hooks/usePermissions';
import { IHighlightSeverity } from 'interfaces/Component';
import { forwardRef, HTMLAttributes, MouseEvent, ReactNode, Ref } from 'react';
import styled from 'styled-components';
import { buttonHighlightSeverity } from 'utils/styles';

interface IStyledButtonProps
  extends HTMLAttributes<HTMLButtonElement>,
    IHighlightSeverity {
  icon?: ReactNode;
  isContained?: boolean;
  isDisabled?: boolean;
  noBorder?: boolean;
  size?: 'large' | 'middle' | 'small';
  transparentBackground?: boolean;
}

// styled always forwards every received prop by default. This causes warnings
// due to html elements receiving non-html attributes. Here we capture just
// the noBorder prop by explicitly listing it within the destructure and forward
// the other props (via rest) to the AntDesignButton. We then use the noBorder
// prop within the template literal.
const StyledButton = styled(
  forwardRef(
    (
      {
        highlightSeverity,
        isContained,
        isDisabled,
        noBorder,
        transparentBackground,
        ...rest
      }: IStyledButtonProps,
      ref: Ref<HTMLElement>,
    ) => <AntDesignButton disabled={isDisabled} {...rest} ref={ref} />,
  ),
)<IStyledButtonProps>`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  ${(props) => buttonHighlightSeverity(props)}
  ${(props) => (props.noBorder ? 'border: unset; box-shadow: unset;' : '')}
  ${(props) => (props.isContained ? 'padding: 0;' : '')}
  ${(props) =>
    props.transparentBackground
      ? `
        background-color: transparent;

        :focus, :hover, :active {
          background-color: transparent;
        }
      `
      : ''}
`;

export interface IIconButtonConfig
  extends IusePermissionsProps,
    IHighlightSeverity {
  borderColour?: string;
  buttonRef?: Ref<HTMLElement>;
  icon?: ReactNode;
  isContained?: boolean;
  isDisabled?: boolean;
  noBorder?: boolean;
  size?: 'large' | 'middle' | 'small';
  stopPropagation?: boolean;
  tabIndex?: number;
  transparentBackground?: boolean;
  encodedTenantPermissionsId?: string;
}

export interface IIconButtonProps extends IIconButtonConfig {
  className?: string;
  onClick?: () => void;
}

const IconButton = (props: IIconButtonProps): JSX.Element | null => {
  const {
    buttonRef,
    className,
    encodedPermissionsId,
    encodedTenantPermissionsId,
    highlightSeverity,
    icon,
    isContained,
    isDisabled,
    noBorder,
    onClick,
    size,
    stopPropagation,
    tabIndex,
    transparentBackground,
  } = props;
  const permissions = usePermissions(
    encodedPermissionsId,
    encodedTenantPermissionsId,
  );

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    if (stopPropagation) {
      event.stopPropagation();
    }

    if (onClick !== undefined) {
      onClick();
    }
  };

  if (permissions.isDisplayable) {
    return (
      <StyledButton
        className={className}
        highlightSeverity={highlightSeverity}
        isContained={isContained}
        isDisabled={isDisabled || !permissions.isExecutable}
        icon={icon}
        noBorder={noBorder}
        onClick={handleClick}
        ref={buttonRef}
        size={size}
        tabIndex={tabIndex}
        transparentBackground={transparentBackground}
      />
    );
  }

  return null;
};

export default IconButton;
