import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { Popover as AntDesignPopover } from 'antd';
import ErrorMessage from 'components/atoms/ErrorMessage/ErrorMessage';
import IconButton from 'components/atoms/IconButton/IconButton';
import Tooltip from 'components/molecules/Tooltip/Tooltip';
import { MESSAGE_DISPLAY_TIME_IN_MILLISECONDS } from 'constants/Component';
import { BUTTON_ICON_DIMENSIONS } from 'constants/styles';
import { EPageMode } from 'enums/Page';
import useDisplayTimedMessage from 'hooks/useDisplayTimedMessage';
import usePrevious from 'hooks/usePrevious';
import { useCallback, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { TErrorMessage } from 'types/Error';

const ReviewIcon = styled(EyeOutlined)`
  ${BUTTON_ICON_DIMENSIONS}
`;

const EditIcon = styled(EditOutlined)`
  ${BUTTON_ICON_DIMENSIONS}
`;

interface IPageModeButtonProps {
  encodedPermissionsId: string;
  isDisabled?: boolean;
  onPageModeChange: (pageMode: EPageMode) => void;
  pageMode: EPageMode;
  viewModeErrorMessage: TErrorMessage;
}

const PageModeButton = ({
  encodedPermissionsId,
  isDisabled,
  onPageModeChange,
  pageMode,
  viewModeErrorMessage,
}: IPageModeButtonProps): JSX.Element => {
  const { displayTimedMessage, timedMessage, showTimedMessage } =
    useDisplayTimedMessage();
  const previousViewModeErrorMessage: TErrorMessage =
    usePrevious(viewModeErrorMessage);
  const adjustedIsDisabled: boolean =
    isDisabled || viewModeErrorMessage !== null;

  const handleIconButtonClick = useCallback(() => {
    if (pageMode === EPageMode.Edit) {
      onPageModeChange(EPageMode.Review);
    } else if (pageMode === EPageMode.Review) {
      onPageModeChange(EPageMode.Edit);
    }
  }, [onPageModeChange, pageMode]);

  const { icon, title } = useMemo(() => {
    if (pageMode === EPageMode.Edit) {
      return {
        icon: <ReviewIcon />,
        title: 'Review details',
      };
    } else if (pageMode === EPageMode.Review) {
      return {
        icon: <EditIcon />,
        title: 'Edit details',
      };
    }

    return { icon: undefined, title: undefined };
  }, [pageMode]);

  useEffect(() => {
    if (
      previousViewModeErrorMessage !== viewModeErrorMessage &&
      viewModeErrorMessage !== null
    ) {
      displayTimedMessage(
        <ErrorMessage>{viewModeErrorMessage}</ErrorMessage>,
        MESSAGE_DISPLAY_TIME_IN_MILLISECONDS,
      );
    }
  }, [displayTimedMessage, previousViewModeErrorMessage, viewModeErrorMessage]);

  return (
    <Tooltip isDisabled={showTimedMessage} title={title}>
      <AntDesignPopover
        content={timedMessage}
        placement='topLeft'
        visible={showTimedMessage}
      >
        <IconButton
          encodedPermissionsId={encodedPermissionsId}
          icon={icon}
          isDisabled={adjustedIsDisabled}
          onClick={handleIconButtonClick}
        />
      </AntDesignPopover>
    </Tooltip>
  );
};

export default PageModeButton;
