import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import thunk from 'redux-thunk';
import { undoableDetailReducer } from 'reduxes/Detail/reducers';
import { tenantSummaryReducer, summaryReducer } from 'reduxes/Summary/reducers';
import { tenantUserReducer, userReducer } from 'reduxes/User/reducers';
import { menuReducer } from './Menu/reducers';
import { configReducer } from './Config/reducers';

const enhancers = [applyMiddleware(thunk)];

export const rootReducer = combineReducers({
  detail: undoableDetailReducer,
  summary: summaryReducer,
  tenantSummary: tenantSummaryReducer,
  user: userReducer,
  tenantUser: tenantUserReducer,
  menu: menuReducer,
  config: configReducer,
});

export const store = createStore(
  rootReducer,
  composeWithDevTools(...enhancers),
);
