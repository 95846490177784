import {
  IETagData,
  IETagIdentifier,
  IETagSummaryAttributeRecord,
  IETagSummaryProfilesRecord,
} from 'interfaces/ETag';
import {
  IToEntitiesRecord,
  IToEntity,
  IToEntityRecord,
} from 'interfaces/ToEntity';
import { TTimeZone } from 'types/DateTime';
import { TErrorMessage } from 'types/Error';
import {
  TETagDraftId,
  TETagRecordKey,
  TETagSummaryProfilesMap,
  TETagTagPrimaryKey,
} from 'types/ETag';
import { TToEntityId } from 'types/ToEntity';
import { ZonedDateTime } from 'utils/zonedDateTime';

export enum ESummaryAction {
  RetrieveToEntityETagSummaryAttributeStart = 'RETRIEVE_TO_ENTITY_ETAG_SUMMARY_ATTRIBUTE_START',
  RetrieveToEntityETagSummaryAttributeSuccess = 'RETRIEVE_TO_ENTITY_ETAG_SUMMARY_ATTRIBUTE_SUCCESS',
  RetrieveToEntityETagSummaryAttributeFailure = 'RETRIEVE_TO_ENTITY_ETAG_SUMMARY_ATTRIBUTE_FAILURE',
  RemoveToEntityETagSummaryAttribute = 'REMOVE_TO_ENTITY_ETAG_SUMMARY_ATTRIBUTE',
  RetrieveToEntityETagsSummaryAttributeStart = 'RETRIEVE_TO_ENTITY_ETAGS_SUMMARY_ATTRIBUTE_START',
  RetrieveToEntityETagsSummaryAttributeSuccess = 'RETRIEVE_TO_ENTITY_ETAGS_SUMMARY_ATTRIBUTE_SUCCESS',
  RetrieveToEntityETagsSummaryAttributeFailure = 'RETRIEVE_TO_ENTITY_ETAGS_SUMMARY_ATTRIBUTE_FAILURE',
  RetrieveToEntityETagsSummaryDealLinkageStart = 'RETRIEVE_TO_ENTITY_ETAGS_SUMMARY_DEAL_LINKAGE_START',
  RetrieveToEntityETagsSummaryDealLinkageSuccess = 'RETRIEVE_TO_ENTITY_ETAGS_SUMMARY_DEAL_LINKAGE_SUCCESS',
  RetrieveToEntityETagsSummaryDealLinkageFailure = 'RETRIEVE_TO_ENTITY_ETAGS_SUMMARY_DEAL_LINKAGE_FAILURE',
  RetrieveToEntityETagSummaryProfilesStart = 'RETRIEVE_TO_ENTITY_ETAG_SUMMARY_PROFILES_START',
  RetrieveToEntityETagSummaryProfilesSuccess = 'RETRIEVE_TO_ENTITY_ETAG_SUMMARY_PROFILES_SUCCESS',
  RetrieveToEntityETagSummaryProfilesFailure = 'RETRIEVE_TO_ENTITY_ETAG_SUMMARY_PROFILES_FAILURE',
  RetrieveToEntityETagSummaryProfilesBatchStart = 'RETRIEVE_TO_ENTITY_ETAG_SUMMARY_PROFILES_BATCH_START',
  RetrieveToEntityETagSummaryProfilesBatchSuccess = 'RETRIEVE_TO_ENTITY_ETAG_SUMMARY_PROFILES_BATCH_SUCCESS',
  RetrieveToEntityETagSummaryProfilesBatchFailure = 'RETRIEVE_TO_ENTITY_ETAG_SUMMARY_PROFILES_BATCH_FAILURE',
  RetrieveToEntityETagsSummaryProfilesStart = 'RETRIEVE_TO_ENTITY_ETAGS_SUMMARY_PROFILES_START',
  RetrieveToEntityETagsSummaryProfilesSuccess = 'RETRIEVE_TO_ENTITY_ETAGS_SUMMARY_PROFILES_SUCCESS',
  RetrieveToEntityETagsSummaryProfilesFailure = 'RETRIEVE_TO_ENTITY_ETAGS_SUMMARY_PROFILES_FAILURE',
  RemoveToEntityETagSummaryProfiles = 'REMOVE_TO_ENTITY_ETAG_SUMMARY_PROFILES',
  UpdateToEntityETagDrafts = 'UPDATE_TO_ENTITY_ETAG_DRAFTS',
  UpdateToEntityETags = 'UPDATE_TO_ENTITY_ETAGS',
  UpdateToEntityPermissionExclusions = 'UPDATE_TO_ENTITY_PERMISSION_EXCLUSIONS',
  RetrieveTenantETagSummaryAttributeStart = 'RETRIEVE_TENANT_ETAG_SUMMARY_ATTRIBUTE_START',
  RetrieveTenantETagsSummaryAttributeStart = 'RETRIEVE_TENANT_ETAGS_SUMMARY_ATTRIBUTE_START',
  RetrieveTenantETagsSummaryAttributeSuccess = 'RETRIEVE_TENANT_ETAGS_SUMMARY_ATTRIBUTE_SUCCESS',
  RetrieveTenantETagsSummaryAttributeFailure = 'RETRIEVE_TENANT_ETAGS_SUMMARY_ATTRIBUTE_FAILURE',
  RetrieveTenantETagsSummaryProfilesStart = 'RETRIEVE_TENANT_ETAGS_SUMMARY_PROFILES_START',
  RetrieveTenantETagsSummaryProfilesSuccess = 'RETRIEVE_TENANT_ETAGS_SUMMARY_PROFILES_SUCCESS',
  RetrieveTenantETagsSummaryProfilesFailure = 'RETRIEVE_TENANT_ETAGS_SUMMARY_PROFILES_FAILURE',
  RetrieveTenantETagSummaryProfilesBatchStart = 'RETRIEVE_TENANT_ETAG_SUMMARY_PROFILES_BATCH_START',
  RetrieveTenantETagSummaryProfilesBatchSuccess = 'RETRIEVE_TENANT_ETAG_SUMMARY_PROFILES_BATCH_SUCCESS',
  RetrieveTenantETagSummaryProfilesBatchFailure = 'RETRIEVE_TENANT_ETAG_SUMMARY_PROFILES_BATCH_FAILURE',
  UpdateTenantETags = 'UPDATE_TENANT_ETAGS',
  UpdateTenantETagDrafts = 'UPDATE_TENANT_ETAG_DRAFTS',
  RemoveTenantETagSummaryProfiles = 'REMOVE_TENANT_ETAG_SUMMARY_PROFILES',
  RemoveTenantETagSummaryAttribute = 'REMOVE_TENANT_ETAG_SUMMARY_ATTRIBUTE',
}

export interface ISummaryState {
  toEntities: Record<TToEntityId, IToEntityRecord>;
}

export interface ICombinedSummaryState {
  toEntities: Record<string, IToEntitiesRecord>;
}

export interface ISummaryActionPayload {
  toEntity: IToEntity;
}

export interface ICombinedSummaryActionPayload {
  toEntities: IToEntity[];
}

// ETag Attribute

interface IETagSummaryAttributeRequest extends ISummaryActionPayload {
  requestedAt: ZonedDateTime;
}

export type TETagSummaryAttributeRequest = IETagIdentifier &
  IETagSummaryAttributeRequest;

export type TETagCombinedSummaryAttributeRequest = IETagIdentifier &
  ITenantETagsSummaryAttributeRequest;

interface IRetrieveToEntityETagSummaryAttributeStartAction {
  payload: TETagSummaryAttributeRequest;
  type: ESummaryAction.RetrieveToEntityETagSummaryAttributeStart;
}

interface IRetrieveTenantETagSummaryAttributeStartAction {
  payload: TETagCombinedSummaryAttributeRequest;
  type: ESummaryAction.RetrieveTenantETagSummaryAttributeStart;
}

export interface IETagSummaryAttributeReply extends ISummaryActionPayload {
  recordKey: TETagRecordKey;
  summaryAttributeRecord: IETagSummaryAttributeRecord;
  timeZone: TTimeZone;
}

interface IRetrieveToEntityETagSummaryAttributeSuccessAction {
  payload: IETagSummaryAttributeReply;
  type: ESummaryAction.RetrieveToEntityETagSummaryAttributeSuccess;
}

interface IRetrieveToEntityETagSummaryAttributeFailureAction {
  payload: IETagSummaryAttributeReply;
  type: ESummaryAction.RetrieveToEntityETagSummaryAttributeFailure;
}

interface IRemoveToEntityETagSummaryAttributeAction {
  payload: TETagSummaryAttributeRequest;
  type: ESummaryAction.RemoveToEntityETagSummaryAttribute;
}

interface IRemoveTenantETagSummaryAttributeAction {
  payload: TETagCombinedSummaryAttributeRequest;
  type: ESummaryAction.RemoveTenantETagSummaryAttribute;
}

// ETags Attribute

export interface IETagsSummaryAttributeRequest extends ISummaryActionPayload {
  requestedAt: ZonedDateTime;
}

export interface ITenantETagsSummaryAttributeRequest
  extends ICombinedSummaryActionPayload {
  requestedAt: ZonedDateTime;
}
interface IRetrieveToEntityETagsSummaryAttributeStartAction {
  payload: IETagsSummaryAttributeRequest;
  type: ESummaryAction.RetrieveToEntityETagsSummaryAttributeStart;
}

interface IRetrieveTenantETagsSummaryAttributeStartAction {
  payload: ITenantETagsSummaryAttributeRequest;
  type: ESummaryAction.RetrieveTenantETagsSummaryAttributeStart;
}

export interface IRetrieveToEntityETagsSummaryAttributeReply
  extends ISummaryActionPayload {
  toEntityRecord: IToEntityRecord;
}

export interface IRetrieveTenantETagsSummaryAttributeReply
  extends ICombinedSummaryActionPayload {
  toEntitiesRecord: IToEntitiesRecord;
}

interface IRetrieveToEntityETagsSummaryAttributeSuccessAction {
  payload: IRetrieveToEntityETagsSummaryAttributeReply;
  type: ESummaryAction.RetrieveToEntityETagsSummaryAttributeSuccess;
}

interface IRetrieveTenantETagsSummaryAttributeSuccessAction {
  payload: IRetrieveTenantETagsSummaryAttributeReply;
  type: ESummaryAction.RetrieveTenantETagsSummaryAttributeSuccess;
}

interface IRetrieveToEntityETagsSummaryAttributeFailureAction {
  payload: IRetrieveToEntityETagsSummaryAttributeReply;
  type: ESummaryAction.RetrieveToEntityETagsSummaryAttributeFailure;
}

interface IRetrieveTenantETagsSummaryAttributeFailureAction {
  payload: IRetrieveTenantETagsSummaryAttributeReply;
  type: ESummaryAction.RetrieveTenantETagsSummaryAttributeFailure;
}

// ETags Deal Linkage

export interface IETagsSummaryDealLinkageRequest extends ISummaryActionPayload {
  requestedAt: ZonedDateTime;
}

interface IRetrieveToEntityETagsSummaryDealLinkageStartAction {
  payload: IETagsSummaryDealLinkageRequest;
  type: ESummaryAction.RetrieveToEntityETagsSummaryDealLinkageStart;
}

export interface IRetrieveToEntityETagsSummaryDealLinkageReply
  extends ISummaryActionPayload {
  toEntityRecord: IToEntityRecord;
  isCompleted?: true;
}

interface IRetrieveToEntityETagsSummaryDealLinkageSuccessAction {
  payload: IRetrieveToEntityETagsSummaryDealLinkageReply;
  type: ESummaryAction.RetrieveToEntityETagsSummaryDealLinkageSuccess;
}

interface IRetrieveToEntityETagsSummaryDealLinkageFailureAction {
  payload: IRetrieveToEntityETagsSummaryDealLinkageReply;
  type: ESummaryAction.RetrieveToEntityETagsSummaryDealLinkageFailure;
}
// ETag Profiles

interface IETagSummaryProfilesRequest extends ISummaryActionPayload {
  requestedAt: ZonedDateTime;
}

interface IETagTenantSummaryProfilesRequest
  extends ICombinedSummaryActionPayload {
  requestedAt: ZonedDateTime;
}

export type TETagSummaryProfilesRequest = IETagIdentifier &
  IETagSummaryProfilesRequest;

export type TTenantETagSummaryProfilesRequest = IETagIdentifier &
  IETagTenantSummaryProfilesRequest;

interface IRetrieveToEntityETagSummaryProfilesStartAction {
  payload: TETagSummaryProfilesRequest;
  type: ESummaryAction.RetrieveToEntityETagSummaryProfilesStart;
}

export interface IETagSummaryProfilesReply extends ISummaryActionPayload {
  recordKey: TETagRecordKey;
  summaryProfileRecord: IETagSummaryProfilesRecord;
}

interface IRetrieveToEntityETagSummaryProfilesSuccessAction {
  payload: IETagSummaryProfilesReply;
  type: ESummaryAction.RetrieveToEntityETagSummaryProfilesSuccess;
}

interface IRetrieveToEntityETagSummaryProfilesFailureAction {
  payload: IETagSummaryProfilesReply;
  type: ESummaryAction.RetrieveToEntityETagSummaryProfilesFailure;
}

interface IRemoveToEntityETagSummaryProfilesAction {
  payload: TETagSummaryProfilesRequest;
  type: ESummaryAction.RemoveToEntityETagSummaryProfiles;
}

interface IRemoveTenantETagSummaryProfilesAction {
  payload: TETagCombinedSummaryAttributeRequest;
  type: ESummaryAction.RemoveTenantETagSummaryProfiles;
}

// ETag Profiles Batch

export interface IETagSummaryProfilesBatchRequest
  extends ISummaryActionPayload {
  eTagIdentifiers: IETagIdentifier[];
  requestedAt: ZonedDateTime;
}

export interface ITenantETagSummaryProfilesBatchRequest
  extends ICombinedSummaryActionPayload {
  eTagIdentifiers: IETagIdentifier[];
  requestedAt: ZonedDateTime;
}

interface IRetrieveToEntityETagSummaryProfilesBatchStartAction {
  payload: IETagSummaryProfilesBatchRequest;
  type: ESummaryAction.RetrieveToEntityETagSummaryProfilesBatchStart;
}

export interface IETagSummaryProfilesBatchReply extends ISummaryActionPayload {
  error?: TErrorMessage;
  eTagSummaryProfilesMap: TETagSummaryProfilesMap;
}

interface IRetrieveToEntityETagSummaryProfilesBatchSuccessAction {
  payload: IETagSummaryProfilesBatchReply;
  type: ESummaryAction.RetrieveToEntityETagSummaryProfilesBatchSuccess;
}

interface IRetrieveToEntityETagSummaryProfilesBatchFailureAction {
  payload: IETagSummaryProfilesBatchReply;
  type: ESummaryAction.RetrieveToEntityETagSummaryProfilesBatchFailure;
}

interface IRetrieveTenantETagSummaryProfilesBatchStartAction {
  payload: ITenantETagSummaryProfilesBatchRequest;
  type: ESummaryAction.RetrieveTenantETagSummaryProfilesBatchStart;
}

export interface IETagSummaryProfilesBatchReply extends ISummaryActionPayload {
  error?: TErrorMessage;
  eTagSummaryProfilesMap: TETagSummaryProfilesMap;
}

export interface ITenantETagSummaryProfilesBatchReply
  extends ICombinedSummaryActionPayload {
  error?: TErrorMessage;
  eTagSummaryProfilesMap: TETagSummaryProfilesMap;
}

interface IRetrieveTenantETagSummaryProfilesBatchSuccessAction {
  payload: ITenantETagSummaryProfilesBatchReply;
  type: ESummaryAction.RetrieveTenantETagSummaryProfilesBatchSuccess;
}

interface IRetrieveTenantETagSummaryProfilesBatchFailureAction {
  payload: ITenantETagSummaryProfilesBatchReply;
  type: ESummaryAction.RetrieveTenantETagSummaryProfilesBatchFailure;
}

// ETags Profiles

export interface IETagsSummaryProfilesRequest extends ISummaryActionPayload {
  loadCount: number;
}

export interface ITenantETagsSummaryProfilesRequest
  extends ICombinedSummaryActionPayload {
  loadCount: number;
}

interface IRetrieveToEntityETagsSummaryProfilesStartAction {
  payload: IETagsSummaryProfilesRequest;
  type: ESummaryAction.RetrieveToEntityETagsSummaryProfilesStart;
}

export interface IETagsSummaryProfilesReply extends ISummaryActionPayload {
  isCompleted?: true;
}

export interface ITenantETagsSummaryProfilesReply
  extends ICombinedSummaryActionPayload {
  isCompleted?: true;
}

export interface IETagsSummaryDealLinkageReply extends ISummaryActionPayload {
  isCompleted?: true;
}

interface IRetrieveToEntityETagsSummaryProfilesSuccessAction {
  payload: IETagsSummaryProfilesReply;
  type: ESummaryAction.RetrieveToEntityETagsSummaryProfilesSuccess;
}

export interface IRetrieveToEntityETagsSummaryProfilesError
  extends ISummaryActionPayload {
  error: string;
}

export interface IRetrieveTenantETagsSummaryProfilesError
  extends ICombinedSummaryActionPayload {
  error: string;
}

interface IRetrieveToEntityETagsSummaryProfilesFailureAction {
  payload: IRetrieveToEntityETagsSummaryProfilesError;
  type: ESummaryAction.RetrieveToEntityETagsSummaryProfilesFailure;
}

interface IRetrieveTenantETagsSummaryProfilesStartAction {
  payload: ITenantETagsSummaryProfilesRequest;
  type: ESummaryAction.RetrieveTenantETagsSummaryProfilesStart;
}

interface IRetrieveTenantETagsSummaryProfilesSuccessAction {
  payload: ITenantETagsSummaryProfilesReply;
  type: ESummaryAction.RetrieveTenantETagsSummaryProfilesSuccess;
}

interface IRetrieveTenantETagsSummaryProfilesFailureAction {
  payload: IRetrieveTenantETagsSummaryProfilesError;
  type: ESummaryAction.RetrieveTenantETagsSummaryProfilesFailure;
}

// ETag

export interface IUpdateToEntityETagDraftsRequest
  extends ISummaryActionPayload {
  draft_id: TETagDraftId;
  tag_primary_key: TETagTagPrimaryKey;
}

interface IUpdateToEntityETagDrafts {
  payload: IUpdateToEntityETagDraftsRequest;
  type: ESummaryAction.UpdateToEntityETagDrafts;
}

interface IUpdateTenantETagDrafts {
  payload: IUpdateTenantETagDraftsRequest;
  type: ESummaryAction.UpdateTenantETagDrafts;
}

export interface IUpdateToEntityETagsRequest extends ISummaryActionPayload {
  eTagsData: IETagData[];
  timeZone: TTimeZone;
}

export interface IUpdateTenantETagDraftsRequest
  extends ICombinedSummaryActionPayload {
  draft_id: TETagDraftId;
  tag_primary_key: TETagTagPrimaryKey;
}

export interface IUpdateTenantETagsRequest
  extends ICombinedSummaryActionPayload {
  eTagsData: IETagData[];
  timeZone: TTimeZone;
}

export type TSummaryAction =
  | IRetrieveToEntityETagSummaryAttributeStartAction
  | IRetrieveToEntityETagSummaryAttributeSuccessAction
  | IRetrieveToEntityETagSummaryAttributeFailureAction
  | IRemoveToEntityETagSummaryAttributeAction
  | IRetrieveToEntityETagsSummaryAttributeStartAction
  | IRetrieveToEntityETagsSummaryAttributeSuccessAction
  | IRetrieveToEntityETagsSummaryAttributeFailureAction
  | IRetrieveToEntityETagsSummaryDealLinkageStartAction
  | IRetrieveToEntityETagsSummaryDealLinkageSuccessAction
  | IRetrieveToEntityETagsSummaryDealLinkageFailureAction
  | IRetrieveToEntityETagSummaryProfilesStartAction
  | IRetrieveToEntityETagSummaryProfilesSuccessAction
  | IRetrieveToEntityETagSummaryProfilesFailureAction
  | IRemoveToEntityETagSummaryProfilesAction
  | IRetrieveToEntityETagSummaryProfilesBatchStartAction
  | IRetrieveToEntityETagSummaryProfilesBatchSuccessAction
  | IRetrieveToEntityETagSummaryProfilesBatchFailureAction
  | IRetrieveToEntityETagsSummaryProfilesStartAction
  | IRetrieveToEntityETagsSummaryProfilesSuccessAction
  | IRetrieveToEntityETagsSummaryProfilesFailureAction
  | IUpdateToEntityETagDrafts
  | IUpdateToEntityETags;

export type TCombinedSummaryAction =
  | IRetrieveTenantETagSummaryAttributeStartAction
  | IRetrieveTenantETagsSummaryAttributeSuccessAction
  | IRetrieveTenantETagsSummaryAttributeStartAction
  | IRetrieveTenantETagsSummaryAttributeFailureAction
  | IRetrieveTenantETagsSummaryProfilesStartAction
  | IRetrieveTenantETagsSummaryProfilesSuccessAction
  | IRetrieveTenantETagsSummaryProfilesFailureAction
  | IRetrieveTenantETagSummaryProfilesBatchStartAction
  | IRetrieveTenantETagSummaryProfilesBatchSuccessAction
  | IRetrieveTenantETagSummaryProfilesBatchFailureAction
  | IRemoveTenantETagSummaryProfilesAction
  | IRemoveTenantETagSummaryAttributeAction
  | IUpdateTenantETags
  | IUpdateTenantETagDrafts;

interface IUpdateToEntityETags {
  payload: IUpdateToEntityETagsRequest;
  type: ESummaryAction.UpdateToEntityETags;
}

interface IUpdateTenantETags {
  payload: IUpdateTenantETagsRequest;
  type: ESummaryAction.UpdateTenantETags;
}
// Actions
