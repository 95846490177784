import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { AxiosResponse } from 'axios';
import IconButton, {
  IIconButtonProps,
} from 'components/atoms/IconButton/IconButton';
import InputNumber from 'components/atoms/InputNumber/InputNumber';
import SeparatedRowLayout from 'components/atoms/SeparatedRowLayout/SeparatedRowLayout';
import DateTimePicker from 'components/molecules/DateTimePicker/DateTimePicker';
import ProductProfileDataTable from 'components/molecules/ProductProfileDataTable/ProductProfileDataTable';
import Select, { ISelectProps } from 'components/molecules/Select/Select';
import { BUTTON_ICON_DIMENSIONS, STANDARD_SPACING } from 'constants/styles';
import { DATE_TIME_FORMAT, TIME_FORMAT } from 'constants/time';
import { IOption } from 'interfaces/Component';
import {
  ICustomHolidayListResponse,
  IDailyProductProfile,
} from 'interfaces/Config';
import {
  IProductProfile,
  IProfileInterval,
  IProfileIntervalGridData,
} from 'interfaces/Detail';
import { IOffset } from 'interfaces/General';
import { useCallback, useEffect, useRef, useState } from 'react';
import { retrieveCustomHolidayList } from 'services/configclient/config';
import styled from 'styled-components';
import { TTimeZone } from 'types/DateTime';
import { TToEntityId } from 'types/ToEntity';
import useAsyncEffect from 'use-async-effect';
import { getIntervalsForProductProfile } from 'utils/detail';
import { isEmptyValue } from 'utils/general';
import { ZonedDateTime } from 'utils/zonedDateTime';

const Wrapper = styled.div`
  align-items: left;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  width: 320px;

  > :not(:last-child):not(:first-child) {
    margin-bottom: ${STANDARD_SPACING};
  }
`;

const DateTimePickerLabel = styled.div`
  align-items: left;
  height: 100%;
  width: 85px;
`;

const ProductSelect = styled((props: ISelectProps<string>) =>
  Select<string>(props),
)`
  width: 100%;
`;

const StyledDateTimePicker = styled(DateTimePicker)`
  width: 100%;
  > .ant-picker {
    width: 100%;
  }
`;

const StyledInputNumber = styled(InputNumber)`
  .ant-input-number {
    width: 69px;

    > .ant-input-number-input-wrap input {
      text-align: right;
    }
  }
`;

const GenInputWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

const GenLabelWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
`;

interface IEditButtonProps extends IIconButtonProps {
  offset?: IOffset;
}

const AddIcon = styled(PlusCircleOutlined)`
  ${BUTTON_ICON_DIMENSIONS}
`;

const RemoveIcon = styled(MinusCircleOutlined)`
  ${BUTTON_ICON_DIMENSIONS}
`;

const AddButton = styled(IconButton)<IEditButtonProps>`
  position: absolute;
  top: 4px;
  right: -18px;

  ${BUTTON_ICON_DIMENSIONS}
  ${(props) => (props.offset === undefined ? '' : { ...props.offset })}
`;

const RemoveButton = styled(IconButton)<IEditButtonProps>`
  position: absolute;
  top: 29px;
  right: -18px;

  ${BUTTON_ICON_DIMENSIONS}
  ${(props) => (props.offset === undefined ? '' : { ...props.offset })}
`;

const AddRemoveButtonWrapper = styled.div`
  position: relative;
`;

interface IProps {
  isAddDisabled?: boolean;
  isDisabled?: boolean;
  onAdd: (addIndex: number) => void;
  onRemove: (index: number) => void;
  onIntervalsChange: (
    productProfileIntervals: IProfileInterval[],
    index: number,
  ) => void;
  onProfileChange: (productProfile: IProductProfile, index: number) => void;
  productProfile?: IProductProfile;
  productOptions: IOption<string>[];
  productProfilesMap: Map<string, IDailyProductProfile> | undefined;
  selectorIndex: number;
  setParentConfirmDisabled: (isDisabled: boolean) => void;
  timeZone: TTimeZone;
  toEntityId: TToEntityId;
}

const ProductProfileSelector = (props: IProps): JSX.Element => {
  const {
    isDisabled,
    onAdd,
    onRemove,
    onIntervalsChange,
    onProfileChange,
    productProfile,
    productOptions,
    productProfilesMap,
    selectorIndex,
    setParentConfirmDisabled,
    timeZone,
    toEntityId,
  } = props;
  const [isDataTableLoading, setIsDataTableLoading] = useState<boolean>(false);
  const [selectedProductProfileId, setselectedProductProfileId] = useState<
    string | undefined
  >(
    productProfile?.selectedProductProfileId
      ? productProfile.selectedProductProfileId
      : undefined,
  );
  const [profileStartDateTime, setProfileStartDateTime] =
    useState<ZonedDateTime | null>(
      isEmptyValue(productProfile?.startDateTime)
        ? null
        : ZonedDateTime.parseIso(productProfile!.startDateTime!, timeZone),
    );
  const prevStartDateTime = useRef<ZonedDateTime>();
  const [profileStopDateTime, setProfileStopDateTime] =
    useState<ZonedDateTime | null>(
      isEmptyValue(productProfile?.stopDateTime)
        ? null
        : ZonedDateTime.parseIso(productProfile!.stopDateTime!, timeZone),
    );
  const prevStopDateTime = useRef<ZonedDateTime>();
  const [genRequestValue, setGenRequestValue] = useState<number | null>(
    productProfile?.genRequest ? productProfile.genRequest : null,
  );
  const [customHolidayList, setCustomHolidayList] = useState<string[]>([]);
  const [profileIntervals, setProfileIntervals] = useState<IProfileInterval[]>(
    [],
  );
  const [profileIntervalGridData, setProfileIntervalGridData] = useState<
    IProfileIntervalGridData[]
  >([]);

  const genValueState = useRef<number>();
  genValueState.current = genRequestValue ?? undefined;

  const handleGenRequestChange = (genRequest: number | undefined) => {
    setGenRequestValue(genRequest === undefined ? null : genRequest);
  };

  const disabledStartDate = (dateTime: ZonedDateTime | null): boolean => {
    if (!profileStopDateTime || !dateTime) {
      return false;
    } else if (dateTime > profileStopDateTime) {
      return true;
    }
    return false;
  };

  const disabledEndDate = (dateTime: ZonedDateTime | null): boolean => {
    if (!profileStartDateTime || !dateTime) {
      return true;
    } else if (dateTime < profileStartDateTime) {
      return true;
    }
    return false;
  };

  const createProfileData = (updatedHolidayList?: string[]) => {
    setProfileIntervals(
      getIntervalsForProductProfile(
        productProfilesMap?.get(selectedProductProfileId!),
        profileStartDateTime,
        profileStopDateTime,
        genValueState.current ?? null,
        updatedHolidayList ? updatedHolidayList : customHolidayList,
      ),
    );
  };

  const selfAsProductProfile = useCallback((): IProductProfile => {
    return {
      selectedProductProfileId: selectedProductProfileId,
      selectedProductProfileName: productProfilesMap?.get(
        selectedProductProfileId ? selectedProductProfileId : ' ',
      )?.daily_profile_template_name,
      startDateTime: profileStartDateTime?.toIsoString(),
      stopDateTime: profileStopDateTime?.toIsoString(),
      genRequest: genValueState.current ?? null,
    };
  }, [
    productProfilesMap,
    profileStartDateTime,
    profileStopDateTime,
    selectedProductProfileId,
  ]);

  const handleRemove = () => {
    onRemove(selectorIndex);
  };

  const handleAdd = () => {
    onAdd(selectorIndex + 1);
  };

  useAsyncEffect(async () => {
    setParentConfirmDisabled(true);
    setIsDataTableLoading(true);
    onProfileChange(selfAsProductProfile(), selectorIndex);
    if (
      selectedProductProfileId &&
      profileStartDateTime &&
      profileStopDateTime &&
      genRequestValue
    ) {
      // For the holiday list we have to query using only the date, not time,
      // so we slice of the part of the ISO string after T. Updates only if one of
      // the selected dates has changed
      if (
        profileStartDateTime !== prevStartDateTime.current ||
        profileStopDateTime !== prevStopDateTime.current
      ) {
        const customHolidaysResponse: AxiosResponse<ICustomHolidayListResponse> =
          await retrieveCustomHolidayList(
            toEntityId,
            profileStartDateTime!.format('YYYY-MM-DD'),
            profileStopDateTime!.format('YYYY-MM-DD'),
          );
        setCustomHolidayList(customHolidaysResponse.data.response);
        prevStartDateTime.current = profileStartDateTime;
        prevStopDateTime.current = profileStopDateTime;
        // Need to pass in updated holiday list explicitly to avoid race
        // condition from using the asynchronous api call
        try {
          createProfileData(customHolidaysResponse.data.response);
        } catch (error) {
          console.error(error);
        }
      } else {
        try {
          createProfileData();
        } catch (error) {
          console.error(error);
        }
      }
    } else {
      setProfileIntervals([]);
    }
    setParentConfirmDisabled(false);
    setIsDataTableLoading(false);
  }, [
    genRequestValue,
    profileStartDateTime,
    profileStopDateTime,
    selectedProductProfileId,
    productProfilesMap,
  ]);

  useEffect(() => {
    const updatedProfileIntervalGridData: IProfileIntervalGridData[] = [];
    for (const interval of profileIntervals) {
      updatedProfileIntervalGridData.push({
        genRequest: interval.genRequest ? interval.genRequest?.toString() : '',
        startDateTime: interval.startDateTime.format(DATE_TIME_FORMAT),
        stopDateTime: interval.stopDateTime.format(DATE_TIME_FORMAT),
        isHoliday:
          customHolidayList.includes(
            interval.startDateTime.format('YYYY-MM-DD'),
          ) ||
          customHolidayList.includes(
            interval.stopDateTime.format('YYYY-MM-DD'),
          ),
        key:
          interval.startDateTime.format(DATE_TIME_FORMAT) +
          interval.stopDateTime.format(DATE_TIME_FORMAT) +
          (interval.genRequest ? interval.genRequest?.toString() : ''),
      });
    }
    setProfileIntervalGridData(updatedProfileIntervalGridData);
  }, [profileIntervals, customHolidayList]);

  useEffect(() => {
    onIntervalsChange(profileIntervals, selectorIndex);
  }, [onIntervalsChange, profileIntervals, selectorIndex]);

  return (
    <Wrapper>
      <AddRemoveButtonWrapper>
        <AddButton
          icon={<AddIcon />}
          isContained={true}
          isDisabled={isDisabled}
          noBorder={true}
          offset={{}}
          onClick={handleAdd}
          tabIndex={-1}
        />
        <RemoveButton
          icon={<RemoveIcon />}
          isContained={true}
          isDisabled={isDisabled}
          noBorder={true}
          offset={{}}
          onClick={handleRemove}
          tabIndex={-1}
        />
      </AddRemoveButtonWrapper>
      <SeparatedRowLayout>
        <ProductSelect
          allowClear={true}
          isDisabled={isDisabled}
          onChange={setselectedProductProfileId}
          options={productOptions}
          showOptionTooltip={true}
          value={selectedProductProfileId}
          valueToUid={(value: string): string => value}
        />
      </SeparatedRowLayout>
      <SeparatedRowLayout>
        <DateTimePickerLabel>Start Date:</DateTimePickerLabel>
        <StyledDateTimePicker
          allowClear={true}
          onChange={setProfileStartDateTime}
          selectHourOnly={false}
          format={DATE_TIME_FORMAT}
          placeholder='Profile Start'
          disabledDate={disabledStartDate}
          showTime={{
            format: TIME_FORMAT,
            defaultValue: ZonedDateTime.now(timeZone).startOf('day'),
          }}
          timeZone={timeZone}
          value={profileStartDateTime}
        />
      </SeparatedRowLayout>
      <SeparatedRowLayout>
        <DateTimePickerLabel>Stop Date:</DateTimePickerLabel>
        <StyledDateTimePicker
          allowClear={true}
          onChange={setProfileStopDateTime}
          defaultPickerValue={
            profileStartDateTime?.add(1, 'day') ??
            ZonedDateTime.now(timeZone).startOf('day')
          }
          selectHourOnly={false}
          format={DATE_TIME_FORMAT}
          placeholder='Profile Stop'
          disabledDate={disabledEndDate}
          showTime={{
            format: TIME_FORMAT,
            defaultValue: ZonedDateTime.now(timeZone).startOf('day'),
          }}
          timeZone={timeZone}
          value={profileStopDateTime}
        />
      </SeparatedRowLayout>
      <SeparatedRowLayout>
        <GenLabelWrapper>Generation MW Profile:</GenLabelWrapper>
        <GenInputWrapper>
          <StyledInputNumber
            hideArrows={true}
            min={0}
            onChange={handleGenRequestChange}
            placeholder='Gen Value'
            preventSpecialCharacters={true}
            step={1}
            value={genRequestValue}
          />
        </GenInputWrapper>
      </SeparatedRowLayout>
      <ProductProfileDataTable
        data={profileIntervalGridData}
        isUnconstrained={false}
        isCombinedIntervalTable={false}
        isLoading={isDataTableLoading}
      />
    </Wrapper>
  );
};

export default ProductProfileSelector;
