import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Button as AntDesignButton } from 'antd';
import { BUTTON_ICON_DIMENSIONS } from 'constants/styles';
import { EActionState } from 'enums/General';
import usePermissions, { IusePermissionsProps } from 'hooks/usePermissions';
import { MouseEvent, useCallback, useEffect, useRef, useState } from 'react';
import styled, { keyframes, Keyframes } from 'styled-components';

const ANIMATION_TIME_MS = 3333;
const ANIMATION_REMOVE_TIME_MS = ANIMATION_TIME_MS;

const fadeInOut: Keyframes = keyframes`
  0% { opacity: 0 }
  20% { opacity: 1 }
  80% { opacity: 1 }
  100% { opacity: 0 }
`;

const Filler = styled.span`
  height: 1px;
  width: 1px;
`;

const FailureIcon = styled(CloseOutlined)`
  animation-name: ${fadeInOut};
  animation-duration: ${ANIMATION_TIME_MS}ms;
  animation-iteration-count: 1;

  ${BUTTON_ICON_DIMENSIONS}
`;

const SuccessIcon = styled(CheckOutlined)`
  animation-name: ${fadeInOut};
  animation-duration: ${ANIMATION_TIME_MS}ms;
  animation-iteration-count: 1;

  ${BUTTON_ICON_DIMENSIONS}
`;

interface IButtonProps extends IusePermissionsProps {
  actionState?: EActionState;
  className?: string;
  isDisabled?: boolean;
  isPrimary?: boolean;
  label?: string;
  onActionIconRemoved?: () => void;
  onClick?: () => void;
  stopPropagation?: boolean;
}

const Button = ({
  actionState,
  className,
  encodedPermissionsId,
  isDisabled,
  isPrimary,
  label,
  onActionIconRemoved,
  onClick,
  stopPropagation,
}: IButtonProps): JSX.Element | null => {
  const permissions = usePermissions(encodedPermissionsId);
  const removeActionIconTimeoutId = useRef<number | undefined>();
  const [actionIcon, setActionIcon] = useState<JSX.Element | undefined>();
  const isMountedRef = useRef<boolean>(false);

  useEffect(() => {
    isMountedRef.current = true;

    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const updateActionIcon = useCallback(
    (icon: JSX.Element) => {
      setActionIcon(icon);

      clearTimeout(removeActionIconTimeoutId.current);

      removeActionIconTimeoutId.current = window.setTimeout(() => {
        setActionIcon(<Filler />);

        if (onActionIconRemoved !== undefined) {
          onActionIconRemoved();
        }
      }, ANIMATION_REMOVE_TIME_MS);
    },
    [onActionIconRemoved],
  );

  useEffect(() => {
    if (isMountedRef.current === true) {
      switch (actionState) {
        case EActionState.NoAction:
        case EActionState.Actioning: {
          setActionIcon(<Filler />);
          return;
        }
        case EActionState.Failed: {
          updateActionIcon(<FailureIcon />);
          return;
        }
        case EActionState.Succeeded: {
          updateActionIcon(<SuccessIcon />);
          return;
        }
        default: {
          setActionIcon(undefined);
          return;
        }
      }
    }
  }, [actionState, updateActionIcon]);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    if (stopPropagation) {
      event.stopPropagation();
    }

    if (onClick !== undefined) {
      onClick();
    }
  };

  if (permissions.isDisplayable) {
    return (
      <AntDesignButton
        className={className}
        disabled={isDisabled || !permissions.isExecutable}
        icon={actionIcon}
        loading={actionState === EActionState.Actioning}
        onClick={handleClick}
        type={isPrimary ? 'primary' : undefined}
      >
        {label}
      </AntDesignButton>
    );
  }

  return null;
};

export default Button;
