import withAlertsQueue, {
  IwithAlertsQueueProps,
} from 'components/hocs/withAlertsQueue/withAlertsQueue';
import withFloatOver from 'components/hocs/withFloatOver/withFloatOver';
import ToEntitySelector from 'components/molecules/ToEntitySelector/ToEntitySelector';
import { IPageContentProps } from 'components/organisms/Page/Page';
import TransmissionCapacityAvailabilityMonitor from 'components/organisms/TransmissionCapacityAvailabilityMonitor/TransmissionCapacityAvailabilityMonitor';
import { PAGE_LAYOUT_STYLES, STANDARD_SPACING_VALUE } from 'constants/styles';
import AnimationProvider from 'contexts/Animation/Animation';
import ETagFilteringProvider from 'contexts/ETagFiltering/ETagFiltering';
import ETagSortingProvider from 'contexts/ETagSorting/ETagSorting';
import { IToEntity } from 'interfaces/ToEntity';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { encodeIds } from 'utils/general';

const Layout = styled.div`
  ${PAGE_LAYOUT_STYLES}
`;

const Actions = styled.div`
  padding: 8px 4px 0 4px;
`;

const ToEntity = styled.div`
  height: 100%;
`;

interface ITransmissionCapacityAvailabilityPageContentProps
  extends IPageContentProps<undefined>,
    IwithAlertsQueueProps {}

const TransmissionCapactiyAvailablityPageContent = (
  props: ITransmissionCapacityAvailabilityPageContentProps,
): JSX.Element => {
  const { userInfo } = props;
  const { toEntities, toEntityUserStates } = userInfo;
  const [selectedToEntities, setSelectedToEntities] = useState<IToEntity[]>([]);

  useEffect(() => {
    setSelectedToEntities(toEntities);
  }, [toEntities]);

  const hasMultipleToEntities: boolean = toEntities.length > 1;
  const toEntityMonitorHeight: string = `calc((100vh - ${
    2 * STANDARD_SPACING_VALUE
  }px) / ${Math.max(Math.min(selectedToEntities.length, 3), 1)})`;

  return (
    <Layout>
      {hasMultipleToEntities ? (
        <Actions>
          <ToEntitySelector
            onChange={setSelectedToEntities}
            selectedToEntities={selectedToEntities}
            toEntities={toEntities}
          />
        </Actions>
      ) : null}
      {selectedToEntities.map(
        (toEntity: IToEntity): JSX.Element => (
          <ToEntity key={toEntity.to_entity}>
            <AnimationProvider>
              <ETagFilteringProvider>
                <ETagSortingProvider>
                  <TransmissionCapacityAvailabilityMonitor
                    encodedPermissionsId={encodeIds(
                      ['transmissionAvailabilityMonitor'],
                      toEntity.to_entity,
                    )}
                    isEmbeddedTitle={hasMultipleToEntities}
                    maxHeight={toEntityMonitorHeight}
                    toEntity={toEntity}
                    toEntityUserState={toEntityUserStates[toEntity.to_entity]}
                  />
                </ETagSortingProvider>
              </ETagFilteringProvider>
            </AnimationProvider>
          </ToEntity>
        ),
      )}
    </Layout>
  );
};

export default withFloatOver(
  withAlertsQueue(TransmissionCapactiyAvailablityPageContent),
);
