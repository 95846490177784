import { ECompositeState } from 'enums/ETag';
import { ERetreiveState } from 'enums/General';
import {
  IETagData,
  IETagIdentifier,
  IETagsSummaryAttributeBatchResponse,
  IETagsSummaryAttributeIdListResponse,
  IETagSummaryAttributeDataSet,
  IETagSummaryAttributeRecord,
  IETagSummaryProfile,
  IETagSummaryProfileDataSet,
  IETagSummaryProfilesRecord,
} from 'interfaces/ETag';
import {
  IToEntitiesRecord,
  IToEntity,
  IToEntityRecord,
} from 'interfaces/ToEntity';
import { TTimeZone } from 'types/DateTime';
import {
  TETagDraftId,
  TETagRecordKey,
  TETagSummaryAttributeMap,
  TETagTagPrimaryKey,
} from 'types/ETag';
import {
  eTagSummaryAttributeToDataSet,
  getRecordKeyForETagIdentifier,
} from 'utils/eTag';
import { ZonedDateTime } from 'utils/zonedDateTime';
import { AxiosResponse } from 'axios';
import {
  retrieveETagsSummaryAttributeBatch,
  retrieveETagsSummaryAttributeIdList,
} from '../../services/agent/tags/summaryLoader';
import { TFilterId } from '../../types/Filter';
import {
  IETagsSummaryAttributeRequest,
  ITenantETagsSummaryAttributeRequest,
} from './types';
import { isSuccessStatus } from '../../utils/general';
import { DEFAULT_SUMMARY_ATTRIBUTES_BATCH_LOAD_SIZE } from '../../constants/Summary';

export const transformRetrievedETagSummaryProfiles = (
  retrievedETagSummaryProfiles: IETagSummaryProfile[],
  eTagSummaryProfiles: IETagSummaryProfile[],
  timeZone: TTimeZone,
): IETagSummaryProfileDataSet[] => {
  let transformedETagSummaryProfiles: IETagSummaryProfileDataSet[] = [];

  if (eTagSummaryProfiles.length > 0) {
    eTagSummaryProfiles.forEach((summaryProfile: IETagSummaryProfile) => {
      const foundSummaryProfile: IETagSummaryProfile | undefined =
        retrievedETagSummaryProfiles.find(
          (retrievedSummaryProfile: IETagSummaryProfile) =>
            ZonedDateTime.parseIso(
              retrievedSummaryProfile.day,
              timeZone,
            ).isSame(
              ZonedDateTime.parseIso(summaryProfile.day, timeZone),
              'day',
            ),
        );

      if (foundSummaryProfile === undefined) {
        transformedETagSummaryProfiles.push({
          ...summaryProfile,
          summaryProfileRemoved: true,
        });
      } else {
        transformedETagSummaryProfiles.push(foundSummaryProfile);
      }
    });

    retrievedETagSummaryProfiles.forEach(
      (retrievedSummaryProfile: IETagSummaryProfile) => {
        if (
          eTagSummaryProfiles.find((summaryProfile: IETagSummaryProfile) =>
            ZonedDateTime.parseIso(summaryProfile.day, timeZone).isSame(
              ZonedDateTime.parseIso(retrievedSummaryProfile.day, timeZone),
              'day',
            ),
          ) === undefined
        ) {
          transformedETagSummaryProfiles.push(retrievedSummaryProfile);
        }
      },
    );
  } else {
    transformedETagSummaryProfiles = retrievedETagSummaryProfiles;
  }

  // Keep Summary Profiles sorted by day
  transformedETagSummaryProfiles.sort(
    (a: IETagSummaryProfile, b: IETagSummaryProfile): number =>
      a.day < b.day ? -1 : a.day > b.day ? 1 : 0,
  );

  return transformedETagSummaryProfiles;
};

export const createOrUpdateTenantSummaryAttributeRecord = (
  state: IToEntitiesRecord,
  recordKey: TETagRecordKey,
  updatedSummaryAttributeRecord: IETagSummaryAttributeRecord,
  timeZone: TTimeZone,
): IToEntitiesRecord => {
  let summaryAttributeRecord: IETagSummaryAttributeRecord =
    state.eTagsSummaryAttributeMap[recordKey];

  if (summaryAttributeRecord === undefined) {
    summaryAttributeRecord = {
      eTagSummaryAttributeDataSet: undefined,
      eTagSummaryAttributeError: null,
      eTagSummaryAttributeRetrieving: ERetreiveState.RetrievingCompleted,
      eTagSummaryAttributeLastRequestedAt: ZonedDateTime.now(timeZone),
    };
  }

  let newSummaryAttributeRecord: IETagSummaryAttributeRecord;

  if (updatedSummaryAttributeRecord.eTagSummaryAttributeDataSet === undefined) {
    if (summaryAttributeRecord.eTagSummaryAttributeDataSet === undefined) {
      newSummaryAttributeRecord = summaryAttributeRecord;
    } else {
      newSummaryAttributeRecord = {
        ...summaryAttributeRecord,
        eTagSummaryAttributeDataSet: {
          ...summaryAttributeRecord.eTagSummaryAttributeDataSet,
          summaryAttributeRemoved: true,
        },
      };
    }
  } else {
    newSummaryAttributeRecord = {
      ...summaryAttributeRecord,
      ...updatedSummaryAttributeRecord,
    };
  }

  const newState: IToEntitiesRecord = {
    ...state,
    eTagsSummaryAttributeMap: {
      ...state.eTagsSummaryAttributeMap,
      [recordKey]: newSummaryAttributeRecord,
    },
  };

  // Check to see if an ETag summary attribute has a pre-existing draft
  // ETag and therefore should be removed.
  if (
    newSummaryAttributeRecord.eTagSummaryAttributeDataSet !== undefined &&
    !newSummaryAttributeRecord.eTagSummaryAttributeDataSet
      ?.summaryAttributeRemoved
  ) {
    if (
      newSummaryAttributeRecord.eTagSummaryAttributeDataSet.composite_state ===
      ECompositeState.Draft
    ) {
      newState.eTagsDraftTagPrimaryKeys = cleanUpETagsDraftTagPrimaryKeys(
        recordKey,
        newState.eTagsDraftTagPrimaryKeys,
      );
      newState.eTagsDraftTagPrimaryKeys[
        newSummaryAttributeRecord.eTagSummaryAttributeDataSet.tag_primary_key
      ] = recordKey;
    } else {
      const foundRecordKey: TETagRecordKey | undefined =
        newState.eTagsDraftTagPrimaryKeys[
          newSummaryAttributeRecord.eTagSummaryAttributeDataSet.tag_primary_key
        ];

      if (foundRecordKey !== undefined) {
        newState.eTagsDraftTagPrimaryKeys = {};

        // Clear all old tagPrimaryKeys associated with the foundRecordKey
        Object.keys(state.eTagsDraftTagPrimaryKeys).forEach(
          (tagPrimaryKey: string) => {
            const recordKey: TETagRecordKey =
              state.eTagsDraftTagPrimaryKeys[tagPrimaryKey];

            if (recordKey !== foundRecordKey) {
              newState.eTagsDraftTagPrimaryKeys[tagPrimaryKey] = recordKey;
            }
          },
        );

        const eTagSummaryAttributeDataSet:
          | IETagSummaryAttributeDataSet
          | undefined =
          newState.eTagsSummaryAttributeMap[foundRecordKey] &&
          newState.eTagsSummaryAttributeMap[foundRecordKey]
            .eTagSummaryAttributeDataSet;

        if (
          eTagSummaryAttributeDataSet !== undefined &&
          newState.eTagsSummaryAttributeMap[foundRecordKey] !== undefined
        ) {
          newState.eTagsSummaryAttributeMap[
            foundRecordKey
          ].eTagSummaryAttributeDataSet = {
            ...eTagSummaryAttributeDataSet,
            summaryAttributeRemoved: true,
          };
        }
      }
    }
  }

  return newState;
};

export const createOrUpdateSummaryAttributeRecord = (
  state: IToEntityRecord,
  recordKey: TETagRecordKey,
  updatedSummaryAttributeRecord: IETagSummaryAttributeRecord,
  timeZone: TTimeZone,
): IToEntityRecord => {
  let summaryAttributeRecord: IETagSummaryAttributeRecord =
    state.eTagsSummaryAttributeMap[recordKey];

  if (summaryAttributeRecord === undefined) {
    summaryAttributeRecord = {
      eTagSummaryAttributeDataSet: undefined,
      eTagSummaryAttributeError: null,
      eTagSummaryAttributeRetrieving: ERetreiveState.RetrievingCompleted,
      eTagSummaryAttributeLastRequestedAt: ZonedDateTime.now(timeZone),
    };
  }

  let newSummaryAttributeRecord: IETagSummaryAttributeRecord;

  if (updatedSummaryAttributeRecord.eTagSummaryAttributeDataSet === undefined) {
    if (summaryAttributeRecord.eTagSummaryAttributeDataSet === undefined) {
      newSummaryAttributeRecord = summaryAttributeRecord;
    } else {
      newSummaryAttributeRecord = {
        ...summaryAttributeRecord,
        eTagSummaryAttributeDataSet: {
          ...summaryAttributeRecord.eTagSummaryAttributeDataSet,
          summaryAttributeRemoved: true,
        },
      };
    }
  } else {
    newSummaryAttributeRecord = {
      ...summaryAttributeRecord,
      ...updatedSummaryAttributeRecord,
    };
  }

  const newState: IToEntityRecord = {
    ...state,
    eTagsSummaryAttributeMap: {
      ...state.eTagsSummaryAttributeMap,
      [recordKey]: newSummaryAttributeRecord,
    },
  };

  // Check to see if an ETag summary attribute has a pre-existing draft
  // ETag and therefore should be removed.
  if (
    newSummaryAttributeRecord.eTagSummaryAttributeDataSet !== undefined &&
    !newSummaryAttributeRecord.eTagSummaryAttributeDataSet
      ?.summaryAttributeRemoved
  ) {
    if (
      newSummaryAttributeRecord.eTagSummaryAttributeDataSet.composite_state ===
      ECompositeState.Draft
    ) {
      newState.eTagsDraftTagPrimaryKeys = cleanUpETagsDraftTagPrimaryKeys(
        recordKey,
        newState.eTagsDraftTagPrimaryKeys,
      );
      newState.eTagsDraftTagPrimaryKeys[
        newSummaryAttributeRecord.eTagSummaryAttributeDataSet.tag_primary_key
      ] = recordKey;
    } else {
      const foundRecordKey: TETagRecordKey | undefined =
        newState.eTagsDraftTagPrimaryKeys[
          newSummaryAttributeRecord.eTagSummaryAttributeDataSet.tag_primary_key
        ];

      if (foundRecordKey !== undefined) {
        newState.eTagsDraftTagPrimaryKeys = {};

        // Clear all old tagPrimaryKeys associated with the foundRecordKey
        Object.keys(state.eTagsDraftTagPrimaryKeys).forEach(
          (tagPrimaryKey: string) => {
            const recordKey: TETagRecordKey =
              state.eTagsDraftTagPrimaryKeys[tagPrimaryKey];

            if (recordKey !== foundRecordKey) {
              newState.eTagsDraftTagPrimaryKeys[tagPrimaryKey] = recordKey;
            }
          },
        );

        const eTagSummaryAttributeDataSet:
          | IETagSummaryAttributeDataSet
          | undefined =
          newState.eTagsSummaryAttributeMap[foundRecordKey] &&
          newState.eTagsSummaryAttributeMap[foundRecordKey]
            .eTagSummaryAttributeDataSet;

        if (
          eTagSummaryAttributeDataSet !== undefined &&
          newState.eTagsSummaryAttributeMap[foundRecordKey] !== undefined
        ) {
          newState.eTagsSummaryAttributeMap[
            foundRecordKey
          ].eTagSummaryAttributeDataSet = {
            ...eTagSummaryAttributeDataSet,
            summaryAttributeRemoved: true,
          };
        }
      }
    }
  }

  return newState;
};

export const createOrUpdateETagData = (
  state: IToEntityRecord,
  eTagData: IETagData,
  timeZone: TTimeZone,
  tenantState?: IToEntitiesRecord,
): IToEntityRecord => {
  const { recordKey, summaryAttribute, summaryProfiles } = eTagData;

  const newState: IToEntityRecord = createOrUpdateSummaryAttributeRecord(
    state,
    recordKey,
    {
      eTagSummaryAttributeDataSet:
        eTagSummaryAttributeToDataSet(summaryAttribute),
      eTagSummaryAttributeError: null,
      eTagSummaryAttributeRetrieving: ERetreiveState.RetrievingCompleted,
      eTagSummaryAttributeLastRequestedAt: ZonedDateTime.now(timeZone),
    },
    timeZone,
  );

  let currentSummaryProfilesRecord: IETagSummaryProfilesRecord | undefined =
    state.eTagsSummaryProfilesMap[recordKey];

  if (currentSummaryProfilesRecord === undefined) {
    currentSummaryProfilesRecord = {
      eTagSummaryProfiles: [],
      eTagSummaryProfilesError: null,
      eTagSummaryProfilesRetrieving: ERetreiveState.RetrievingCompleted,
      eTagSummaryProfilesLastRequestedAt: ZonedDateTime.now(timeZone),
    };
  }

  return {
    ...newState,
    eTagsSummaryProfilesMap: {
      ...state.eTagsSummaryProfilesMap,
      [recordKey]: {
        ...currentSummaryProfilesRecord,
        eTagSummaryProfiles: transformRetrievedETagSummaryProfiles(
          summaryProfiles,
          currentSummaryProfilesRecord.eTagSummaryProfiles,
          timeZone,
        ),
      },
    },
  };
};

export const createOrUpdateTenantETagData = (
  state: IToEntitiesRecord,
  eTagData: IETagData,
  timeZone: TTimeZone,
): IToEntitiesRecord => {
  const { recordKey, summaryAttribute, summaryProfiles } = eTagData;

  const newState: IToEntitiesRecord =
    createOrUpdateTenantSummaryAttributeRecord(
      state,
      recordKey,
      {
        eTagSummaryAttributeDataSet:
          eTagSummaryAttributeToDataSet(summaryAttribute),
        eTagSummaryAttributeError: null,
        eTagSummaryAttributeRetrieving: ERetreiveState.RetrievingCompleted,
        eTagSummaryAttributeLastRequestedAt: ZonedDateTime.now(timeZone),
      },
      timeZone,
    );

  let currentSummaryProfilesRecord: IETagSummaryProfilesRecord | undefined =
    state.eTagsSummaryProfilesMap[recordKey];

  if (currentSummaryProfilesRecord === undefined) {
    currentSummaryProfilesRecord = {
      eTagSummaryProfiles: [],
      eTagSummaryProfilesError: null,
      eTagSummaryProfilesRetrieving: ERetreiveState.RetrievingCompleted,
      eTagSummaryProfilesLastRequestedAt: ZonedDateTime.now(timeZone),
    };
  }

  return {
    ...newState,
    eTagsSummaryProfilesMap: {
      ...state.eTagsSummaryProfilesMap,
      [recordKey]: {
        ...currentSummaryProfilesRecord,
        eTagSummaryProfiles: transformRetrievedETagSummaryProfiles(
          summaryProfiles,
          currentSummaryProfilesRecord.eTagSummaryProfiles,
          timeZone,
        ),
      },
    },
  };
};

export const cleanUpETagsDraftTagPrimaryKeys = (
  draftId: TETagDraftId,
  eTagsDraftTagPrimaryKeys: Record<TETagTagPrimaryKey, TETagRecordKey>,
): Record<TETagTagPrimaryKey, TETagRecordKey> => {
  const updatedETagsDraftTagPrimaryKeys: Record<
    TETagTagPrimaryKey,
    TETagRecordKey
  > = {
    ...eTagsDraftTagPrimaryKeys,
  };
  for (let key in updatedETagsDraftTagPrimaryKeys) {
    if (updatedETagsDraftTagPrimaryKeys[key] === draftId) {
      delete updatedETagsDraftTagPrimaryKeys[key];
    }
  }
  return updatedETagsDraftTagPrimaryKeys;
};

export const eTagIdentifiersToIdList = (
  eTagIdentifiers: IETagIdentifier[],
): string[] =>
  eTagIdentifiers.map((eTagIdentifier: IETagIdentifier): string =>
    eTagIdentifier.draft_id === null
      ? eTagIdentifier.tag_primary_key
      : `d:${eTagIdentifier.draft_id}`,
  );

// It's important to know that if there is a draft_id then the tag_primary_key
// is not a valid tag_primary_key since we don't have enough information to
// correctly determine its value
export const idToETagIdentifier = (id: string): IETagIdentifier => ({
  draft_id: id.startsWith('d:') ? id.substring(2) : null,
  tag_primary_key: id,
});

export const retrieveEtagSummaryAttribute = async (
  toEntity: IToEntity,
  start: ZonedDateTime,
  end: ZonedDateTime,
  eTagsSummaryAttributeRequest:
    | IETagsSummaryAttributeRequest
    | ITenantETagsSummaryAttributeRequest,
  eTagsSummaryAttributeLastRequestedAt: ZonedDateTime,
  batchSize?: number,
  filterId?: TFilterId,
) => {
  let idBatches: AxiosResponse<IETagsSummaryAttributeBatchResponse>[] = [];
  let eTagsDraftTagPrimaryKeys: Record<TETagTagPrimaryKey, TETagRecordKey> = {};
  let eTagsSummaryAttributeMap: TETagSummaryAttributeMap = {};

  const retrieveETagsSummaryAttributeIdListResponse: AxiosResponse<IETagsSummaryAttributeIdListResponse> =
    await retrieveETagsSummaryAttributeIdList(
      toEntity.to_entity,
      start,
      end,
      filterId,
    );

  if (
    eTagsSummaryAttributeRequest.requestedAt >=
    eTagsSummaryAttributeLastRequestedAt
  ) {
    const eTagsSummaryAttributeIdListResponse: IETagsSummaryAttributeIdListResponse =
      retrieveETagsSummaryAttributeIdListResponse.data;

    if (!isSuccessStatus(retrieveETagsSummaryAttributeIdListResponse.status)) {
      throw new Error(eTagsSummaryAttributeIdListResponse.errorMessage!);
    }

    const newETagsSummaryAttributeMap: TETagSummaryAttributeMap = {};
    const { id_list } = eTagsSummaryAttributeIdListResponse.response;
    const loadBatchSize: number =
      batchSize === undefined
        ? DEFAULT_SUMMARY_ATTRIBUTES_BATCH_LOAD_SIZE
        : batchSize;
    const numBatches: number = Math.ceil(id_list.length / loadBatchSize);

    const idBatchPromises: Promise<
      AxiosResponse<IETagsSummaryAttributeBatchResponse>
    >[] = [];
    for (let i: number = 0; i < numBatches; i += 1) {
      const idBatch: string[] = [];

      for (let j: number = 0; j < loadBatchSize; j += 1) {
        const idListIndex: number = i * loadBatchSize + j;

        if (idListIndex < id_list.length) {
          idBatch.push(id_list[idListIndex]);
        } else {
          break;
        }
      }
      idBatchPromises.push(
        retrieveETagsSummaryAttributeBatch(toEntity.to_entity, idBatch),
      );
    }

    idBatches = await Promise.all(idBatchPromises);

    if (
      eTagsSummaryAttributeRequest.requestedAt >=
      eTagsSummaryAttributeLastRequestedAt
    ) {
      for (let retrieveETagsSummaryAttributeBatchResponse of idBatches) {
        const eTagsSummaryAttributeBatchResponse: IETagsSummaryAttributeBatchResponse =
          retrieveETagsSummaryAttributeBatchResponse.data;

        if (
          !isSuccessStatus(retrieveETagsSummaryAttributeBatchResponse.status)
        ) {
          throw new Error(eTagsSummaryAttributeBatchResponse.errorMessage!);
        }

        eTagsSummaryAttributeBatchResponse.response =
          eTagsSummaryAttributeBatchResponse.response.map((obj) => ({
            ...obj,
            entity_code: toEntity.entity_code,
            to_entity: toEntity.to_entity,
          }));

        for (const eTagSummaryAttribute of eTagsSummaryAttributeBatchResponse.response) {
          const recordKey: TETagRecordKey =
            getRecordKeyForETagIdentifier(eTagSummaryAttribute);
          newETagsSummaryAttributeMap[recordKey] = {
            eTagSummaryAttributeDataSet:
              eTagSummaryAttributeToDataSet(eTagSummaryAttribute),
            eTagSummaryAttributeError: null,
            eTagSummaryAttributeRetrieving: ERetreiveState.RetrievingCompleted,
            eTagSummaryAttributeLastRequestedAt:
              eTagsSummaryAttributeRequest.requestedAt,
          };

          // Track all draft ETags, so that we can remove them if we find a
          // corresponding non-draft ETag later on.
          if (eTagSummaryAttribute.composite_state === ECompositeState.Draft) {
            eTagsDraftTagPrimaryKeys = cleanUpETagsDraftTagPrimaryKeys(
              recordKey,
              eTagsDraftTagPrimaryKeys,
            );
            eTagsDraftTagPrimaryKeys[eTagSummaryAttribute.tag_primary_key] =
              recordKey;
          }
        }
      }
      eTagsSummaryAttributeMap = newETagsSummaryAttributeMap;
    }
  }

  return { eTagsDraftTagPrimaryKeys, eTagsSummaryAttributeMap };
};
