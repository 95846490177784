import { Popover as AntDesignPopover } from 'antd';
import { DatePickerProps } from 'antd/lib/date-picker';
import ErrorMessage from 'components/atoms/ErrorMessage/ErrorMessage';
import {
  injectContainer,
  injectDateTimeShortcuts,
} from 'components/molecules/DateTimePicker/helpers';
import { IDateTimeShortcut } from 'components/molecules/DateTimePicker/types';
import ZonedDateTimePicker, {
  IZonedDateTimePickerProps,
} from 'components/molecules/DateTimePicker/ZonedDateTimePicker';
import { ENTER_KEY, TAB_KEY } from 'constants/General';
import {
  EXPANDED_INPUT_HEIGHT_VALUE,
  EXPANSION_TRANSITION_IN_SECONDS_VALUE,
  FLOAT_BOX_SHADOW,
  INPUT_HEIGHT_VALUE,
} from 'constants/styles';
import useDisplayTimedMessage from 'hooks/useDisplayTimedMessage';
import {
  IExpandableProps,
  IShowTime,
  IValueChanged,
} from 'interfaces/Component';
import React, {
  Component,
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import styled from 'styled-components';
import { TTimeZone } from 'types/DateTime';
import {
  TDateTimeInputValidator,
  TDisabledDateHandler,
  TPlacement,
} from 'types/General';
import { isEmptyValue } from 'utils/general';
import {
  inputBackground,
  inputBackgroundExpand,
  inputValueChanged,
} from 'utils/styles';
import {
  setTimeZonePreserveLocal,
  setTimeZonePreserveLocalSafe,
} from 'utils/time';
import { ZonedDateTime } from 'utils/zonedDateTime';

const MESSAGE_DISPLAY_TIME_IN_MILLISECONDS = 11497;

export const Wrapper = styled.div<IExpandableProps & IValueChanged>`
  height: ${INPUT_HEIGHT_VALUE}px;

  .ant-picker {
    ${(props) => inputBackground(props)}
  }

  ${(props) =>
    props.shouldExpand
      ? `
    .ant-picker {
      ${inputBackgroundExpand(props)}
      transition: ${EXPANSION_TRANSITION_IN_SECONDS_VALUE}s;
      transition-timing-function: ease;
      width: 100%;
    }

    .ant-picker-focused {
      box-shadow: ${FLOAT_BOX_SHADOW};
      height: ${EXPANDED_INPUT_HEIGHT_VALUE}px;
      width: 200%;
    }
  `
      : ''}

  .ant-picker-input input {
    ${(props) => inputValueChanged(props)}
  }
`;

export type TDateTimePickerRef = Component<DatePickerProps, any> & {
  blur?: () => void;
  focus?: () => void;
};

export interface IDateTimePickerConfig {
  allowClear?: boolean;
  dateTimeInputValidator?: TDateTimeInputValidator;
  dateTimeShortcuts?: IDateTimeShortcut[];
  defaultPickerValue?: ZonedDateTime;
  errorMessagePlacement?: TPlacement;
  format?: string;
  initialValue?: ZonedDateTime | null;
  isDisabled?: boolean;
  open?: boolean;
  placeholder?: string;
  selectHourOnly?: boolean;
  showNow?: boolean;
  showTime?: IShowTime;
  shouldExpand?: boolean;
  suffixIcon?: JSX.Element | null;
  timeZone: TTimeZone;
  value?: ZonedDateTime | null;
}

export interface IDateTimePickerProps extends IDateTimePickerConfig {
  className?: string;
  disabledDate?: TDisabledDateHandler;
  onChange?: (date: ZonedDateTime | null) => void;
  onOk?: () => void;
}

const DateTimePicker = (props: IDateTimePickerProps): JSX.Element => {
  const {
    cancelDisplayTimedMessage,
    displayTimedMessage,
    timedMessage,
    showTimedMessage,
  } = useDisplayTimedMessage();
  const { currentTheme } = useThemeSwitcher();
  const {
    allowClear,
    className,
    dateTimeInputValidator,
    dateTimeShortcuts,
    defaultPickerValue,
    disabledDate,
    errorMessagePlacement,
    format,
    initialValue,
    isDisabled,
    onChange,
    onOk,
    open,
    placeholder,
    selectHourOnly,
    showNow,
    showTime,
    shouldExpand,
    suffixIcon,
    timeZone,
    value,
  } = props;
  const [dropdownContainer, setDropdownContainer] = useState<
    HTMLElement | undefined
  >();
  const selectedDateTimeRef = useRef<ZonedDateTime | undefined | null>(
    undefined,
  );
  const wrapperRef =
    useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;
  const inputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    if (dateTimeInputValidator !== undefined && wrapperRef.current) {
      const inputElements: HTMLCollectionOf<HTMLInputElement> =
        wrapperRef.current.getElementsByTagName('input');

      if (inputElements.length === 1) {
        inputRef.current = inputElements[0];
      } else {
        throw new Error('Invalid number of input elements found');
      }
    }
  }, [dateTimeInputValidator]);

  useEffect(() => {
    if (value !== null && value !== undefined) {
      if (value.timeZone() !== timeZone) {
        selectedDateTimeRef.current = setTimeZonePreserveLocalSafe(
          value,
          timeZone,
        );

        if (onChange !== undefined) {
          onChange(selectedDateTimeRef.current);
        }
      }
    } else {
      selectedDateTimeRef.current = null;
    }
  }, [onChange, timeZone, value]);

  const handleKeyDownCapture = useCallback(
    (keyboardEvent: React.KeyboardEvent) => {
      if (
        dateTimeInputValidator !== undefined &&
        timeZone !== undefined &&
        inputRef.current
      ) {
        if (keyboardEvent.key === ENTER_KEY || keyboardEvent.key === TAB_KEY) {
          cancelDisplayTimedMessage();

          if (isEmptyValue(inputRef.current.value)) {
            selectedDateTimeRef.current = null;

            if (onChange !== undefined) {
              onChange(selectedDateTimeRef.current);
            }
          } else {
            const { errorMessage, isValid } = dateTimeInputValidator(
              ZonedDateTime.parseIso(inputRef.current.value, timeZone),
            );

            if (!isValid) {
              keyboardEvent.preventDefault();
              keyboardEvent.stopPropagation();

              displayTimedMessage(
                <ErrorMessage>{errorMessage}</ErrorMessage>,
                MESSAGE_DISPLAY_TIME_IN_MILLISECONDS,
              );
            }
          }
        }
      }
    },
    [
      cancelDisplayTimedMessage,
      displayTimedMessage,
      dateTimeInputValidator,
      onChange,
      timeZone,
    ],
  );

  const handleSelect = (dateTime: ZonedDateTime) => {
    selectedDateTimeRef.current = setTimeZonePreserveLocal(dateTime, timeZone);
  };

  const handleChange = (dateTime: ZonedDateTime | null) => {
    cancelDisplayTimedMessage();

    if (onChange !== undefined) {
      if (selectHourOnly && dateTime !== null) {
        onChange(setTimeZonePreserveLocal(dateTime, timeZone).startOf('hour'));
      } else {
        onChange(setTimeZonePreserveLocalSafe(dateTime, timeZone));
      }
    }
  };

  useEffect(
    () => {
      if (dropdownContainer !== undefined) {
        const handleShortcut = (dateTimeShortcut: IDateTimeShortcut) => {
          if (selectedDateTimeRef.current) {
            handleChange(
              setTimeZonePreserveLocal(dateTimeShortcut.dateTime, timeZone)
                .startOf('day')
                .withHour(selectedDateTimeRef.current.getHour()),
            );
          } else {
            handleChange(
              setTimeZonePreserveLocal(dateTimeShortcut.dateTime, timeZone),
            );
          }
        };

        injectDateTimeShortcuts(
          dropdownContainer,
          handleShortcut,
          dateTimeShortcuts,
        );
      }
    },
    // We are ignoring changes to dateTimeShortcuts and handleChange because the
    // injection should only happen once during the setting of the
    // dropdownContainer.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dropdownContainer],
  );

  const dropdownClasses: string[] = useMemo(() => {
    const dropdownClasses: string[] = [];

    if (dateTimeShortcuts !== undefined) {
      dropdownClasses.push('date-time-picker-dropdown-shortcuts');
    }

    if (showTime !== undefined && !showNow) {
      dropdownClasses.push('date-time-picker-dropdown-hide-now');
    }

    if (selectHourOnly) {
      dropdownClasses.push('date-time-picker-dropdown-hour-only');
    }

    return dropdownClasses;
  }, [dateTimeShortcuts, selectHourOnly, showNow, showTime]);

  const valueChanged: boolean | undefined = useMemo(() => {
    if (initialValue !== undefined) {
      if (value === null && initialValue === null) {
        return false;
      } else if (value !== null && initialValue === null) {
        return true;
      } else {
        return !initialValue!.isSame(value);
      }
    }

    return false;
  }, [initialValue, value]);

  const handleDisabledDate = useCallback(
    (dateTime: ZonedDateTime | null): boolean => {
      if (disabledDate !== undefined) {
        return disabledDate(
          dateTime,
          !isEmptyValue(selectedDateTimeRef.current),
        );
      }

      return false;
    },
    [disabledDate],
  );

  const handleOpenChange = useCallback((open: boolean) => {
    if (!open) {
      selectedDateTimeRef.current = null;
    }
  }, []);

  // In order to be able correctly hide or show the suffix icon, we need to
  // ensure that the suffixIcon property does not appear as part of the props
  // passed to AntDesignDatePicker if its value is undefined when passed to
  // this DateTimePicker component.
  const datePickerProps: IZonedDateTimePickerProps = {
    allowClear,
    defaultPickerValueZoned: defaultPickerValue,
    disabled: isDisabled,
    disabledDate: handleDisabledDate,
    dropdownClassName:
      dropdownClasses.length > 0 ? dropdownClasses.join(' ') : undefined,
    format,
    getPopupContainer: injectContainer(setDropdownContainer),
    onChange: handleChange,
    onOk,
    onOpenChange: handleOpenChange,
    onSelect: handleSelect,
    open,
    placeholder,
    showTime,
    timeZone,
    value: value ?? null,
  };

  if (suffixIcon !== undefined) {
    datePickerProps.suffixIcon = suffixIcon;
  }

  return (
    <Wrapper
      className={className}
      currentTheme={currentTheme!}
      onKeyDownCapture={handleKeyDownCapture}
      ref={wrapperRef}
      shouldExpand={shouldExpand}
      valueChanged={valueChanged}
    >
      <AntDesignPopover
        content={timedMessage}
        placement={errorMessagePlacement}
        visible={showTimedMessage}
      >
        <ZonedDateTimePicker {...datePickerProps} />
      </AntDesignPopover>
    </Wrapper>
  );
};

export default DateTimePicker;
