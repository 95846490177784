import Button from 'components/atoms/Button/Button';
import Select, { ISelectProps } from 'components/molecules/Select/Select';
import {
  generateHourOptions,
  getDateRanges,
  injectMouseDownInElementCallback,
  isRangeWithinLimit,
} from 'components/molecules/ToEntityDateTimePicker/helpers';
import { STANDARD_SPACING } from 'constants/styles';
import { IOption } from 'interfaces/Component';
import { IDateRange } from 'interfaces/Summary';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { TTimeZone } from 'types/DateTime';
import { ZonedDateTime } from 'utils/zonedDateTime';

const RANGES_COLUMN_MAXIMUM_ITEMS = 7;
const RANGE_BUTTON_WIDTH_VALUE = 126;
const CALENDAR_PANEL_WIDTH_VALUE = 488;

// These values should be synchronised with those in ToEntityDateTimePicker.less
const CALENDAR_PANEL_HEIGHT_VALUE = 242;
const ACTIONS_MINIMUM_HEIGHT_VALUE = 62;

interface IRangesProps {
  numberOfColumns: number;
}

const Ranges = styled.div<IRangesProps>`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  /* First 32px derived from 28px RangeButton height + 4px bottom margin */
  /* Second 32px derived from 16px Ranges top padding + 16px Ranges bottom padding */
  max-height: calc((${RANGES_COLUMN_MAXIMUM_ITEMS} * 32px) + 32px);
  padding: 16px;
  /* 36px derived from 16px Ranges left padding + 16px Ranges right padding + 4px RangeButton right margin = 36px */
  width: ${(props) =>
    `calc((${props.numberOfColumns} * ${RANGE_BUTTON_WIDTH_VALUE}px) + 36px)`};

  > button {
    margin: 0 4px 4px 0;
  }
`;

const RangeButton = styled(Button)`
  width: ${RANGE_BUTTON_WIDTH_VALUE}px;
`;

const Actions = styled.div`
  align-items: flex-end;
  bottom: 0;
  display: flex;
  flex-direction: row;
  height: calc(100% - ${CALENDAR_PANEL_HEIGHT_VALUE}px);
  justify-content: space-between;
  min-height: ${ACTIONS_MINIMUM_HEIGHT_VALUE}px;
  padding: 16px 16px 16px 0;
  position: absolute;
  right: 0;
  width: 100%;
`;

const TimeSelection = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  max-width: ${CALENDAR_PANEL_WIDTH_VALUE}px;
  width: 100%;
`;

const RowLayout = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;

  > :not(:last-child) {
    margin-right: ${STANDARD_SPACING};
  }
`;

// Specialize the Select component
const TimeSelect = styled((props: ISelectProps<number>) =>
  Select<number>(props),
)`
  width: 67px;
`;

interface IProps {
  endDate: ZonedDateTime | null;
  endTime: number;
  includeRanges?: boolean;
  mouseDownInSelectCallback: (context: string) => (isInside: boolean) => void;
  onCancel: () => void;
  onEndTimeChange: (endTime: number) => void;
  onOk: () => void;
  onRangeButton: (range: IDateRange) => () => void;
  onStartTimeChange: (startTime: number) => void;
  rangeLimitInHours?: number;
  startDate: ZonedDateTime | null;
  startTime: number;
  timeZone: TTimeZone;
}

const ToEntityDateTimePickerFooter = (props: IProps): JSX.Element => {
  const {
    endDate,
    endTime,
    includeRanges,
    mouseDownInSelectCallback,
    onCancel,
    onEndTimeChange,
    onOk,
    onRangeButton,
    onStartTimeChange,
    rangeLimitInHours,
    startDate,
    startTime,
    timeZone,
  } = props;

  const [startTimeOptions] = useState<IOption<number>[]>(generateHourOptions());
  const [endTimeOptions, setEndTimeOptions] = useState<IOption<number>[]>(
    generateHourOptions(),
  );
  const [ranges, setRanges] = useState<IDateRange[]>([]);

  useEffect(() => {
    if (includeRanges && timeZone !== undefined) {
      setRanges(getDateRanges(timeZone));
    }
  }, [includeRanges, timeZone]);

  useEffect(() => {
    if (
      startDate !== null &&
      endDate !== null &&
      rangeLimitInHours !== undefined
    ) {
      const startDateTime: ZonedDateTime = startDate
        .startOf('day')
        .withHour(startTime);

      setEndTimeOptions((previousEndTimeOptions: IOption<number>[]) =>
        previousEndTimeOptions.map((endTimeOption: IOption<number>) => {
          const endDateTime: ZonedDateTime = endDate
            .startOf('day')
            .withHour(endTimeOption.value);

          return {
            ...endTimeOption,
            isDisabled:
              endDateTime.diff(startDateTime, 'hours') > rangeLimitInHours,
          };
        }),
      );
    }
  }, [endDate, endTime, rangeLimitInHours, startDate, startTime]);

  const handleStartTimeChange = (startTime: number | undefined) => {
    onStartTimeChange(startTime!);
  };

  const handleEndTimeChange = (endTime: number | undefined) => {
    onEndTimeChange(endTime!);
  };

  return (
    <div>
      {ranges !== undefined && ranges.length > 0 ? (
        <Ranges
          numberOfColumns={Math.ceil(
            ranges.length / RANGES_COLUMN_MAXIMUM_ITEMS,
          )}
        >
          {ranges!.map(
            (range: IDateRange): JSX.Element => (
              <RangeButton
                isDisabled={!isRangeWithinLimit(range, rangeLimitInHours)}
                label={range.label}
                key={range.label}
                onClick={onRangeButton(range)}
              />
            ),
          )}
        </Ranges>
      ) : null}
      <Actions>
        <TimeSelection>
          <RowLayout>
            <div>Start time:</div>
            <TimeSelect
              getPopupContainer={injectMouseDownInElementCallback(
                mouseDownInSelectCallback('startTimeSelect'),
              )}
              onChange={handleStartTimeChange}
              options={startTimeOptions}
              value={startTime}
              valueToUid={(value: number) => value.toString()}
            />
          </RowLayout>
          <RowLayout>
            <div>End time:</div>
            <TimeSelect
              getPopupContainer={injectMouseDownInElementCallback(
                mouseDownInSelectCallback('endTimeSelect'),
              )}
              onChange={handleEndTimeChange}
              options={endTimeOptions}
              value={endTime}
              valueToUid={(value: number) => value.toString()}
            />
          </RowLayout>
        </TimeSelection>
        <RowLayout>
          <Button label='Cancel' onClick={onCancel} />
          <Button
            isDisabled={startDate === null || endDate === null}
            isPrimary={true}
            label='Ok'
            onClick={onOk}
          />
        </RowLayout>
      </Actions>
    </div>
  );
};

export default ToEntityDateTimePickerFooter;
