import { DownloadOutlined } from '@ant-design/icons';
import Tooltip from 'components/molecules/Tooltip/Tooltip';
import { ETheme } from 'enums/Style';
import React, { useCallback, useState } from 'react';
import { TToEntityUserStates } from 'reduxes/User/types';
import DownloadButtonPopover from './DownloadButtonPopover';
import Modal from '../Modal/Modal';
import { IETagTagId } from '../../../interfaces/ETag';
import { TToEntityId } from '../../../types/ToEntity';
import { TTimeZone } from '../../../types/DateTime';
import { MultipleEntityWrapper } from '../MultipleEntityWrapper/MultipleEntityWrapperElement';
import { useDispatch } from 'react-redux';
import { setTenantSelectedToEntityId } from '../../../reduxes/User/actions';

interface UserButtonProps {
  currentTheme?: ETheme | string;
  userState?: TToEntityUserStates;
  toEntityId?: string | TToEntityId;
  tagID?: IETagTagId;
  timeZones?: TTimeZone[];
  primaryKey?: string;
}

const DownloadTagButton = (props: UserButtonProps): JSX.Element => {
  const {
    currentTheme: theme,
    userState,
    tagID,
    toEntityId,
    timeZones,
    primaryKey,
  } = props;
  const [isVisible] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState<boolean>(false);

  const handleShowModal = useCallback(() => setShowModal(true), [setShowModal]);
  const handleCloseModal = useCallback(() => {
    setShowModal(false);
    dispatch(setTenantSelectedToEntityId({ toEntityId: '' }));
  }, [dispatch, setShowModal]);

  return (
    <Tooltip
      isDisabled={isVisible}
      placement='bottomLeft'
      title='Download eTag'
    >
      <DownloadOutlined onClick={handleShowModal} />
      <Modal
        isVisible={showModal}
        title={'Download eTag'}
        closable={true}
        children={
          <MultipleEntityWrapper byPassEntityCheck={!!toEntityId}>
            <DownloadButtonPopover
              userState={userState}
              currentTheme={theme || ETheme.Light}
              tagID={tagID}
              toEntityId={toEntityId}
              timeZones={timeZones}
              primaryKey={primaryKey}
            />
          </MultipleEntityWrapper>
        }
        onCancel={handleCloseModal}
        footer={<></>}
      />
    </Tooltip>
  );
};

export default DownloadTagButton;
