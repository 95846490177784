import { Input as AntDesignInput } from 'antd';
import { AxiosResponse } from 'axios';
import ErrorMessage from 'components/atoms/ErrorMessage/ErrorMessage';
import InputNumber from 'components/atoms/InputNumber/InputNumber';
import SeparatedRowLayout from 'components/atoms/SeparatedRowLayout/SeparatedRowLayout';
import { ButtonWithResult } from 'components/organisms/ToEntitySummaryUiConfiguration/ButtonWithResult';
import ETagCodeSchemeAtoms from 'components/organisms/ToEntitySummaryUiConfiguration/ETagCodeSchemeAtoms';
import { StyledLabel } from 'components/organisms/ToEntitySummaryUiConfiguration/styledComponents';
import { EActionState } from 'enums/General';
import usePermissions, { IusePermissionsProps } from 'hooks/usePermissions';
import { IToEntity } from 'interfaces/ToEntity';
import { useState } from 'react';
import {
  putETagCodeSchemeDefault as saveETagCodeSchemeDefault,
  retrieveDefaultETagCodeScheme,
  retrieveETagCodeSchemeTestResult,
} from 'services/agent/tagCodeSchemes/tagCodeSchemes';
import styled from 'styled-components';
import { TTimeZone } from 'types/DateTime';
import useAsyncEffect from 'use-async-effect';
import { captureError } from 'utils/error';
import { isSuccessStatus } from 'utils/general';
import { ZonedDateTime } from 'utils/zonedDateTime';

const StyledSequenceStartValueInputNumber = styled(InputNumber)`
  width: 5em;
`;
interface ITagCodeSchemeAtom {
  value: string;
  index: number;
}

interface IETagCodeSchemeProps extends IusePermissionsProps {
  timeZone: TTimeZone;
  toEntity: IToEntity;
}

const ETagCodeSchemeForm = (props: IETagCodeSchemeProps): JSX.Element => {
  const { encodedPermissionsId, timeZone, toEntity } = props;
  const permissions = usePermissions(encodedPermissionsId);
  // initial choice of tag code scheme is full length 7 sequence
  const [tagCodeSchemeAtoms, setTagCodeSchemeAtoms] = useState<
    ITagCodeSchemeAtom[]
  >([]);
  // set by button click action for the "test tag code scheme button"
  // displayed next to the button
  const [tagCodeSchemeTestResult, setTagCodeSchemeTestResult] = useState<
    string | undefined
  >(undefined);
  // set by button click action for the "save default tag code scheme button"
  // displayed next to the button
  const [tagCodeSchemeSaveResult, setTagCodeSchemeSaveResult] = useState<
    string | undefined
  >(undefined);
  // useful to show the current default tag code scheme
  // this is stored in the agent service
  const [defaultTagCodeScheme, setDefaultTagCodeScheme] = useState<
    string | undefined
  >(undefined);
  // the sequence start value is used for testing the scheme as well as for
  // initializing the default scheme sequence number
  const [sequenceStartValue, setSequenceStartValue] = useState<number>(0);
  const [sequenceStartValueError, setSequenceStartValueError] = useState<
    string | undefined
  >(undefined);
  const [defaultSequenceStartValue, setDefaultSequenceStartValue] = useState<
    number | undefined
  >(undefined);
  const [schemeDefaultActionState, setSchemeDefaultActionState] =
    useState<EActionState>(EActionState.NoAction);
  const [testSchemeActionState, setTestSchemeActionState] =
    useState<EActionState>(EActionState.NoAction);

  // helper function to smash the atom values together in a single string
  const getTagCodeSchemeAsString = () => {
    return tagCodeSchemeAtoms
      .map((atom: ITagCodeSchemeAtom) => atom.value)
      .join('');
  };

  // call the backend api to test the given tag code schemes
  // results or validation errors will be shown
  const handleClickTestTagCodeScheme = (toEntity: IToEntity) => async () => {
    setTestSchemeActionState(EActionState.Actioning);
    // we have to reset the other action state so that the animation does not restart
    // this happens if the other action state has been set to EActionState.Succeeded
    setSchemeDefaultActionState(EActionState.NoAction);
    const tagCodeScheme: string = getTagCodeSchemeAsString();
    setTagCodeSchemeTestResult('Testing...');
    if (tagCodeScheme.length > 0) {
      try {
        const response: AxiosResponse = await retrieveETagCodeSchemeTestResult(
          toEntity.to_entity,
          tagCodeScheme,
          sequenceStartValue,
          ZonedDateTime.now(timeZone),
          undefined,
        );
        if (isSuccessStatus(response.status)) {
          setTagCodeSchemeTestResult(`With the tag code scheme ${tagCodeScheme}
            the next tag codes starting at ${sequenceStartValue} are
            ${response.data.response.next_tag_codes.join(', ')}
          `);
          setTestSchemeActionState(EActionState.Succeeded);
        } else {
          setTagCodeSchemeTestResult(response.data.response.join('\n'));
          setTestSchemeActionState(EActionState.Failed);
        }
      } catch (error: any) {
        captureError(error);

        setTagCodeSchemeTestResult(
          'Error testing tag code scheme. Please try again later.',
        );
        setTestSchemeActionState(EActionState.Failed);
      }
    } else {
      setTagCodeSchemeTestResult('No tag code scheme to test');
      setTestSchemeActionState(EActionState.NoAction);
    }
  };

  // call the backend api to store the given tag code scheme as the default
  // for the to-entity
  // results or validation errors will be shown
  const handleClickSaveTagCodeSchemeAsDefault =
    (toEntity: IToEntity) => async () => {
      setSchemeDefaultActionState(EActionState.Actioning);
      // we have to reset the other action state so that the animation does not restart
      // this happens if the other action state has been set to EActionState.Succeeded
      setTestSchemeActionState(EActionState.NoAction);
      const tagCodeScheme: string = getTagCodeSchemeAsString();
      setTagCodeSchemeSaveResult('Saving...');
      if (tagCodeScheme.length > 0) {
        try {
          const response: AxiosResponse = await saveETagCodeSchemeDefault(
            toEntity.to_entity,
            tagCodeScheme,
            sequenceStartValue,
          );
          if (!isSuccessStatus(response.status)) {
            if (Array.isArray(response.data.response)) {
              setTagCodeSchemeSaveResult(
                `Errors saving scheme: [${response.data.response.join(', ')}]`,
              );
            } else {
              setTagCodeSchemeSaveResult(response.data.errorMessage);
            }
            setSchemeDefaultActionState(EActionState.Failed);
          } else {
            const scheme: string =
              response.data.response.default_tag_code_scheme;
            const seqValue: number = response.data.response.sequence_value;
            setDefaultTagCodeScheme(scheme);
            setDefaultSequenceStartValue(seqValue);
            setSequenceStartValue(seqValue);
            setTagCodeSchemeSaveResult(
              `Saved tag code scheme
             ${scheme}
             sequence value ${response.data.response.sequence_value}`,
            );
            setSchemeDefaultActionState(EActionState.Succeeded);
          }
        } catch (error: any) {
          captureError(error);

          setTagCodeSchemeSaveResult(
            'Error saving default tag code scheme. Please try again later.',
          );
          setSchemeDefaultActionState(EActionState.Failed);
        }
      } else {
        setTagCodeSchemeSaveResult('No tag code scheme to save');
        setSchemeDefaultActionState(EActionState.NoAction);
      }
    };

  // load the default tag code scheme just once for the lifetime of this component
  useAsyncEffect(async () => {
    try {
      const response: AxiosResponse = await retrieveDefaultETagCodeScheme(
        toEntity.to_entity,
      );

      if (!isSuccessStatus(response.status)) {
        setDefaultTagCodeScheme('{seq:7}');
      } else {
        setDefaultTagCodeScheme(response.data.response.tag_code_scheme);
        setDefaultSequenceStartValue(
          response.data.response.initial_sequence_value,
        );
        setSequenceStartValue(response.data.response.initial_sequence_value);
        setTagCodeSchemeTestResult(`
            The next tag codes starting at ${
              response.data.response.initial_sequence_value
            } are
            ${response.data.response.next_tag_codes.join(', ')}
          `);
      }
    } catch (error: any) {
      if (toEntity) {
        captureError(
          error,
          `Error getting default tag code scheme for entity: ${toEntity.to_entity}`,
        );
      }

      setDefaultTagCodeScheme('--');

      setDefaultSequenceStartValue(0);
    }
  }, [toEntity.to_entity]);

  const handleSequenceStartValueChange = (
    seqValue: number | string | undefined,
  ) => {
    let seqValueNumber: number | undefined;
    try {
      seqValueNumber = Number(seqValue);
    } catch (error: any) {
      seqValueNumber = undefined;
    }
    if (seqValueNumber === undefined) {
      setSequenceStartValueError(`Invalid sequence start value: ${seqValue}`);
    } else {
      setSequenceStartValue(seqValueNumber);
      setSequenceStartValueError(undefined);
    }
  };

  return (
    <>
      {
        // for now we only check 'isExecutable' and don't render at all if not
        // executable. There is currently no reason to see these settings if you
        // cannot change them
        !permissions.isExecutable && toEntity ? (
          <ErrorMessage>{`You do not have permissions to view the default
         tag code scheme for the ToEntity ${toEntity.entity_code}`}</ErrorMessage>
        ) : (
          <>
            <ETagCodeSchemeAtoms
              toEntity={toEntity}
              defaultTagCodeScheme={defaultTagCodeScheme}
              tagCodeSchemeAtoms={tagCodeSchemeAtoms}
              setTagCodeSchemeAtoms={setTagCodeSchemeAtoms}
            />
            <StyledLabel>Select sequence start:</StyledLabel>
            <SeparatedRowLayout>
              <AntDesignInput
                addonBefore='Current'
                disabled={true}
                style={{ width: '21em' }}
                value={defaultSequenceStartValue ?? '...loading'}
              />
              <StyledSequenceStartValueInputNumber
                hideArrows={true}
                min={0}
                max={9999999}
                onChange={handleSequenceStartValueChange}
                value={sequenceStartValue}
              />
            </SeparatedRowLayout>
            <StyledLabel>Test selected scheme:</StyledLabel>
            <ButtonWithResult
              actionState={testSchemeActionState}
              label='Get next 4 codes'
              onClick={handleClickTestTagCodeScheme(toEntity)}
              result={tagCodeSchemeTestResult}
            />
            <StyledLabel>Save selected scheme:</StyledLabel>
            <ButtonWithResult
              actionState={schemeDefaultActionState}
              label='Save as default'
              onClick={handleClickSaveTagCodeSchemeAsDefault(toEntity)}
              result={tagCodeSchemeSaveResult}
            />
            {sequenceStartValueError !== undefined && (
              <div key={`sequence-start-value-error`}>
                <ErrorMessage>{sequenceStartValueError}</ErrorMessage>
              </div>
            )}
          </>
        )
      }
    </>
  );
};

export default ETagCodeSchemeForm;
