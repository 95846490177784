import {
  BellOutlined,
  CaretRightOutlined,
  FilterOutlined,
  MenuUnfoldOutlined,
  PauseOutlined,
  StopOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import IconButton from 'components/atoms/IconButton/IconButton';
import SeparatedRowLayout from 'components/atoms/SeparatedRowLayout/SeparatedRowLayout';
import ToggleSwitch from 'components/atoms/ToggleSwitch/ToggleSwitch';
import Badge from 'components/molecules/Badge/Badge';
import ConfigurationSelector from 'components/molecules/ConfigurationSelector/ConfigurationSelector';
import PageLegend from 'components/molecules/PageLegend/PageLegend';
import ToEntityConfiguration from 'components/molecules/ToEntityConfiguration/ToEntityConfiguration';
import ToEntityCsvExport from 'components/molecules/ToEntityCsvExport/ToEntityCsvExport';
import ToEntityDateTimePicker from 'components/molecules/ToEntityDateTimePicker/ToEntityDateTimePicker';
import ToEntityETagCopy from 'components/molecules/ToEntityETagCopy/ToEntityETagCopy';
import ToEntityETagCreate from 'components/molecules/ToEntityETagCreate/ToEntityETagCreate';
import ToEntityETagDelete from 'components/molecules/ToEntityETagDelete/ToEntityETagDelete';
import ToEntityETagSearch from 'components/molecules/ToEntityETagSearch/ToEntityETagSearch';
import ToEntityETagTemplateCreate from 'components/molecules/ToEntityETagTemplateCreate/ToEntityETagTemplateCreate';
import ToEntityReportModal from 'components/molecules/ToEntityReportCreateModal/ToEntityReportModal';
import Tooltip from 'components/molecules/Tooltip/Tooltip';
import ToEntityCustomFilterConfigurator, {
  IToEntityCustomFilterConfigurationProps,
} from 'components/organisms/ToEntityCustomFilterConfigurator/ToEntityCustomFilterConfigurator';
import {
  TO_ENTITY_CUSTOM_FILTER_CONFIGURATOR_MODAL_WIDTH_VALUE,
  TO_ENTITY_MONITOR_CONFIGURATOR_MODAL_WIDTH_VALUE,
} from 'components/organisms/ToEntityMonitor/constants';
import UserTimeZoneSelector from 'components/organisms/UserTimeZoneSelector/UserTimeZoneSelector';
import {
  ACTION_BAR_MARGIN_RIGHT_VALUE,
  BUTTON_ICON_DIMENSIONS,
  HIGHLIGHT_BLUE,
  INPUT_HEIGHT_VALUE,
  TABLE_CONFIGURATION_SELECT_WIDTH_VALUE,
} from 'constants/styles';
import { IConfiguredAlert } from 'interfaces/Alert';
import { IOption } from 'interfaces/Component';
import { IETagColumnData, IETagDataSet } from 'interfaces/ETag';
import { ICustomFilter } from 'interfaces/Filter';
import {
  ITableConfiguration,
  IToEntityMonitorConfiguration,
} from 'interfaces/Summary';
import { IToEntity } from 'interfaces/ToEntity';
import styled from 'styled-components';
import { TAlertRulesMap } from 'types/Alert';
import { TToEntityDataTableSummaryDataSet } from 'types/Component';
import { TTimeZone } from 'types/DateTime';
import { TTableConfigurationOptions } from 'types/Summary';
import { customFilterToUid } from 'utils/filter';
import { encodeIds } from 'utils/general';
import { ZonedDateTime } from 'utils/zonedDateTime';
import MultipleConfigurationSelector from '../../molecules/TenantConfigurationSelector/MultipleConfigurationSelector';
import React, { useEffect, useState } from 'react';
import { TToEntityId } from '../../../types/ToEntity';
import { getEtagCopyPayload } from '../ToEntityMonitor/helpers';
import ToEntityMonitorConfigurator, {
  IToEntityMonitorConfigurationProps,
  ToEntityMonitorConfiguratorIcon,
} from '../ToEntityMonitorConfigurator/ToEntityMonitorConfigurator';
import ToEntityAlertHistory from '../../molecules/ToEntityAlertHistory/ToEntityAlertHistory';
import ToEntityQueryETagAuthoriy from '../ToEntityQueryETagAuthority/ToEntityQueryETagAuthority';
import ToEntityRequestsInformation from '../../molecules/ToEntityRequestsInformation/ToEntityRequestsInformation';

const ActionsBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

interface IFixedProfileIconProps {
  isActivated?: boolean;
}

const FixedProfileIcon = styled(({ isActivated, ...rest }) => (
  <MenuUnfoldOutlined {...rest} />
))<IFixedProfileIconProps>`
  color: ${(props) => (props.isActivated ? HIGHLIGHT_BLUE : 'inherit')};
`;

const FilterIcon = styled(FilterOutlined)`
  height: ${INPUT_HEIGHT_VALUE / 2}px;
  width: ${INPUT_HEIGHT_VALUE / 2}px;
`;

const ClearAllFiltersIcon = styled(StopOutlined)`
  ${BUTTON_ICON_DIMENSIONS}
`;

const RefreshIcon = styled(SyncOutlined)`
  ${BUTTON_ICON_DIMENSIONS}
`;

const PauseIcon = styled(PauseOutlined)`
  ${BUTTON_ICON_DIMENSIONS}
`;

const PlayIcon = styled(CaretRightOutlined)`
  color: ${HIGHLIGHT_BLUE};
  ${BUTTON_ICON_DIMENSIONS}
`;

// Props
interface IProps {
  alertRulesMap: TAlertRulesMap;
  allowAlerts: boolean;
  closeAllAlerts: (closedCallback: () => void) => void;
  createCustomFilterOption: (
    customFilterOption: IOption<ICustomFilter>,
  ) => Promise<IOption<ICustomFilter>>;
  customFilterOptions: IOption<ICustomFilter>[];
  encodedPermissionsId: string;
  eTagDataSets: IETagDataSet[];
  expandedColumns: IETagColumnData[];
  fixedProfile: boolean;
  isDisplayingProfiles: boolean;
  isEmbeddedTitle?: boolean;
  isPaused: boolean;
  isPlayPauseDisabled: boolean;
  isUnconstrained: boolean;
  legendContent: JSX.Element | null;
  onClearAllFilters: () => void;
  onPlayPause: () => void;
  onRefresh: () => void;
  overrideOnAlert: (
    onAlert: ((configuredAlert: IConfiguredAlert) => void) | undefined,
  ) => void;
  pausedCount: number;
  removeCustomFilterOption: (
    customFilterOption: IOption<ICustomFilter>,
  ) => Promise<void>;
  selectedCustomFilter: ICustomFilter | undefined;
  selectedEndDate: ZonedDateTime | null;
  selectedETagDataSet: IETagDataSet | undefined;
  selectedStartDate: ZonedDateTime | null;
  selectedTableConfiguration: ITableConfiguration | undefined;
  setAllowAlerts: (allowAlerts: boolean) => void;
  setFixedProfile: (fixedProfile: boolean) => void;
  setSelectedCustomFilter: (
    selectedCustomFilter: ICustomFilter | undefined,
  ) => void;
  setSelectedEndDate: (selectedEndDate: ZonedDateTime | null) => void;
  setSelectedStartDate: (selectedStartDate: ZonedDateTime | null) => void;
  setSelectedTableConfiguration: (
    selectedTableConfiguration: ITableConfiguration | undefined,
  ) => void;
  summaryDataSets: TToEntityDataTableSummaryDataSet[];
  tableConfigurationOptions: TTableConfigurationOptions;
  timeZone: TTimeZone | undefined;
  toEntityMonitorConfiguration: IToEntityMonitorConfiguration;
  updateCustomFilterOption: (
    customFilterOption: IOption<ICustomFilter>,
  ) => Promise<void>;
  updateToEntityMonitorConfiguration: (
    toEntityMonitorConfiguration: IToEntityMonitorConfiguration,
  ) => Promise<void>;
  //Combined view props
  customFilterOptionsMap?: Map<string, any[]> | undefined;
  setSelectedCustomFilters?: (selectedCustomFilter: ICustomFilter[]) => void;
  toEntities: IToEntity[];
  setCustomFilterOptionsMap: (value: Map<string, any[]>) => void;
  setCustomFilterOptions: (value: IOption<ICustomFilter>[]) => void;
  setSelectedCustomFilterOption: (value: string) => void;
  selectedCustomFilterOption?: string;
  selectedTagToEntityId?: TToEntityId;
  toEntity?: IToEntity;
  rowSelected?: boolean;
}

const TenantMonitorActionBar = (props: IProps): JSX.Element => {
  const {
    alertRulesMap,
    allowAlerts,
    closeAllAlerts,
    createCustomFilterOption,
    customFilterOptions,
    encodedPermissionsId,
    eTagDataSets,
    expandedColumns,
    fixedProfile,
    isDisplayingProfiles,
    isEmbeddedTitle,
    isPaused,
    isPlayPauseDisabled,
    legendContent,
    onClearAllFilters,
    onPlayPause,
    onRefresh,
    pausedCount,
    removeCustomFilterOption,
    selectedCustomFilter,
    selectedEndDate,
    selectedETagDataSet,
    selectedStartDate,
    selectedTableConfiguration,
    setAllowAlerts,
    setFixedProfile,
    setSelectedCustomFilter,
    setSelectedEndDate,
    setSelectedStartDate,
    setSelectedTableConfiguration,
    summaryDataSets,
    tableConfigurationOptions,
    timeZone,
    toEntity,
    toEntityMonitorConfiguration,
    updateCustomFilterOption,
    updateToEntityMonitorConfiguration,
    customFilterOptionsMap,
    setSelectedCustomFilters,
    toEntities,
    selectedTagToEntityId,
    overrideOnAlert,
    setCustomFilterOptionsMap,
    setCustomFilterOptions,
    selectedCustomFilterOption,
    setSelectedCustomFilterOption,
    rowSelected,
  } = props;

  const handleFixedProfile = () => {
    setFixedProfile(!fixedProfile);
  };

  const [selectedEntity, setSelectedEntity] = useState<IToEntity | undefined>(
    undefined,
  );
  useEffect(() => {
    const entity = toEntities.find(
      (entity) => entity.to_entity === selectedTagToEntityId,
    );
    setSelectedEntity(entity);
  }, [toEntities, selectedTagToEntityId]);

  return (
    <ActionsBar>
      <SeparatedRowLayout marginRight={ACTION_BAR_MARGIN_RIGHT_VALUE}>
        <PageLegend
          content={legendContent}
          id='tableLegend'
          tooltipPlacement={isEmbeddedTitle ? undefined : 'topLeft'}
        />
        <ToEntityConfiguration<IToEntityMonitorConfigurationProps>
          Configurator={ToEntityMonitorConfigurator}
          configuratorProps={{
            alertRulesMap,
            currentTableConfiguration: selectedTableConfiguration,
            customFilterOptions,
            encodedPermissionsId: encodeIds([
              encodedPermissionsId,
              'monitorConfigurator',
            ]),
            toEntity,
            toEntityMonitorConfiguration,
            updateToEntityMonitorConfiguration,
          }}
          encodedPermissionsId={encodeIds([
            encodedPermissionsId,
            'monitorConfiguration',
          ])}
          icon={<ToEntityMonitorConfiguratorIcon />}
          isDisabled={toEntityMonitorConfiguration === undefined}
          label=''
          modalWidth={TO_ENTITY_MONITOR_CONFIGURATOR_MODAL_WIDTH_VALUE}
          toEntity={toEntity}
          tooltipPlacement={isEmbeddedTitle ? undefined : 'topLeft'}
          byPassEntityCheck={true}
        />
        {timeZone === undefined ? null : (
          <>
            <ToEntityCsvExport
              eTagColumnDatas={expandedColumns}
              eTagDataSets={eTagDataSets}
              panelPlacement='bottomLeft'
              selectedEndDate={selectedEndDate}
              selectedStartDate={selectedStartDate}
              summaryDataSets={summaryDataSets}
              timeZone={timeZone}
            />
            <ToEntityReportModal
              customFilterOptions={customFilterOptions}
              encodedPermissionsId={encodeIds([
                encodedPermissionsId,
                'eTagCreateCheckoutReport',
              ])}
              tableConfigurationOptions={tableConfigurationOptions}
              timeZone={timeZone}
            />
          </>
        )}
        <Tooltip title='Table Configuration Selection'>
          <ConfigurationSelector<ITableConfiguration>
            onChange={setSelectedTableConfiguration}
            options={tableConfigurationOptions}
            value={selectedTableConfiguration}
            valueToUid={(value: ITableConfiguration): string =>
              value.id.toString()
            }
            width={TABLE_CONFIGURATION_SELECT_WIDTH_VALUE}
          />
        </Tooltip>
        <Tooltip
          isDisabled={!isDisplayingProfiles}
          title='Profile Data Fixed to Right of Table'
        >
          <IconButton
            icon={<FixedProfileIcon isActivated={fixedProfile} />}
            isDisabled={!isDisplayingProfiles}
            onClick={handleFixedProfile}
          />
        </Tooltip>
        <UserTimeZoneSelector
          defaultTimeZone={toEntityMonitorConfiguration.defaultTimeZone}
          timeZoneOptions={toEntityMonitorConfiguration.timeZoneOptions}
        />
        {timeZone === undefined ? null : (
          <Tooltip title='Date Time Range Selection'>
            <ToEntityDateTimePicker
              end={selectedEndDate}
              includeRanges={true}
              rangeLimitInHours={
                isDisplayingProfiles
                  ? toEntityMonitorConfiguration.profilesMaxHourRange
                  : undefined
              }
              setEnd={setSelectedEndDate}
              setStart={setSelectedStartDate}
              start={selectedStartDate}
              timeZone={timeZone}
            />
          </Tooltip>
        )}
        <Tooltip title='Refresh Table Contents'>
          <IconButton icon={<RefreshIcon />} onClick={onRefresh} />
        </Tooltip>
        {timeZone === undefined ? null : (
          <ToEntityETagSearch
            encodedPermissionsId={encodeIds([
              encodedPermissionsId,
              'eTagSearch',
            ])}
            timeZone={timeZone}
          />
        )}
        <ToEntityConfiguration<IToEntityCustomFilterConfigurationProps>
          Configurator={ToEntityCustomFilterConfigurator}
          configuratorProps={{
            createCustomFilterOption,
            currentCustomFilter: selectedCustomFilter,
            customFilterOptions,
            encodedPermissionsId: encodeIds([
              encodedPermissionsId,
              'customFilterConfigurator',
            ]),
            removeCustomFilterOption,
            updateCustomFilterOption,
            optionsMap: customFilterOptionsMap,
            setCustomFilterOptionsMap,
            setCustomFilterOptions,
          }}
          encodedPermissionsId={encodeIds([
            encodedPermissionsId,
            'customFilterConfiguration',
          ])}
          icon={<FilterIcon />}
          isDisabled={toEntityMonitorConfiguration === undefined}
          label='Custom Filter'
          modalWidth={TO_ENTITY_CUSTOM_FILTER_CONFIGURATOR_MODAL_WIDTH_VALUE}
          toEntity={toEntity}
        />
        <Tooltip title='Custom Filter Selection'>
          {toEntities.length > 1 && setSelectedCustomFilters ? (
            <MultipleConfigurationSelector<ICustomFilter>
              allowClear={true}
              onChange={setSelectedCustomFilters}
              options={customFilterOptions}
              optionsMap={customFilterOptionsMap}
              placeholder='Select Custom Filter'
              value={selectedCustomFilterOption}
              setValue={setSelectedCustomFilterOption}
              valueToUid={customFilterToUid}
              width={TABLE_CONFIGURATION_SELECT_WIDTH_VALUE}
              toEntities={toEntities}
            />
          ) : (
            <ConfigurationSelector<ICustomFilter>
              allowClear={true}
              onChange={setSelectedCustomFilter}
              options={customFilterOptions}
              placeholder='Select Custom Filter'
              value={selectedCustomFilter}
              valueToUid={customFilterToUid}
              width={TABLE_CONFIGURATION_SELECT_WIDTH_VALUE}
            />
          )}
        </Tooltip>
        <Tooltip title='Clear Custom Filter and All Column Filters'>
          <IconButton
            icon={<ClearAllFiltersIcon />}
            onClick={onClearAllFilters}
          />
        </Tooltip>
        <Tooltip
          title={
            isPaused
              ? 'Resume Table Content Update'
              : 'Pause Table Content Update'
          }
        >
          <Badge count={pausedCount}>
            <IconButton
              icon={isPaused ? <PlayIcon /> : <PauseIcon />}
              isDisabled={isPlayPauseDisabled}
              onClick={onPlayPause}
            />
          </Badge>
        </Tooltip>
        {timeZone !== undefined ? (
          <ToEntityRequestsInformation
            eTagExtendedIdentifier={selectedETagDataSet}
            timeZone={timeZone}
            toEntity={selectedEntity}
            encodedPermissionsId={encodeIds([encodedPermissionsId])}
            tagId={selectedETagDataSet?.tag_id}
          />
        ) : null}
        {timeZone === undefined ? null : (
          <ToEntityETagTemplateCreate
            encodedPermissionsId={encodeIds([
              encodedPermissionsId,
              'eTagTemplateCreate',
            ])}
            eTagExtendedIdentifier={selectedETagDataSet}
            timeZone={timeZone}
            toEntityId={selectedTagToEntityId}
          />
        )}
        {timeZone === undefined ? null : (
          <ToEntityETagCreate
            contactInfo={toEntityMonitorConfiguration.contactInfo}
            encodedPermissionsId={encodeIds([
              encodedPermissionsId,
              'eTagCreate',
            ])}
            timeZone={timeZone}
            toEntityId={selectedTagToEntityId}
            showEntitySelector={true}
          />
        )}
        {timeZone === undefined ? null : (
          <ToEntityETagCopy
            contactInfo={toEntityMonitorConfiguration.contactInfo}
            encodedPermissionsId={encodeIds([encodedPermissionsId, 'eTagCopy'])}
            eTagCopyPayload={getEtagCopyPayload(selectedETagDataSet)}
            timeZone={timeZone}
            toEntityId={selectedTagToEntityId}
          />
        )}
        <ToEntityETagDelete
          encodedPermissionsId={encodeIds([encodedPermissionsId, 'eTagDelete'])}
          eTagExtendedIdentifier={selectedETagDataSet}
          toEntityId={selectedTagToEntityId}
        />
        {timeZone === undefined ? null : (
          <>
            {/*<ToEntityETagReviewPendingRequests
              encodedPermissionsId={encodeIds([
                encodedPermissionsId,
                'eTagReviewPendingRequests',
              ])}
              eTagDataSets={eTagDataSets}
              eTagExtendedIdentifier={selectedETagDataSet}
              isUnconstrained={isUnconstrained}
              timeZone={timeZone}
              toEntityId={toEntity.to_entity}
            />*/}
            <ToEntityQueryETagAuthoriy
              encodedPermissionsId={encodeIds([
                encodedPermissionsId,
                'queryETagAuthority',
              ])}
              eTagExtendedIdentifier={selectedETagDataSet}
              timeZone={timeZone}
              toEntity={
                toEntities.filter(
                  (entity) => entity.to_entity === selectedTagToEntityId,
                )[0]
              }
              toEntities={toEntities}
              tagID={selectedETagDataSet?.tag_id}
              rowSelected={rowSelected}
            />
            <ToEntityAlertHistory
              alertRulesMap={alertRulesMap}
              alertConfigurations={
                toEntityMonitorConfiguration.alertConfigurations
              }
              closeAllAlerts={closeAllAlerts}
              overrideOnAlert={overrideOnAlert}
              timeZone={timeZone}
            />
            {/*<ReviewScheduledSubmissions
              encodedPermissionsId={encodeIds([
                encodedPermissionsId,
                'eTagReviewScheduledSubmissions',
              ])}
              isDisabled={false}
              timeZone={timeZone}
              toEntityId={toEntity.to_entity}
            />*/}
          </>
        )}
      </SeparatedRowLayout>
      <SeparatedRowLayout marginRight={ACTION_BAR_MARGIN_RIGHT_VALUE}>
        <Tooltip placement='topRight' title='Allow Alerts'>
          <ToggleSwitch
            checkedChildren={<BellOutlined />}
            isChecked={allowAlerts}
            onChange={setAllowAlerts}
            unCheckedChildren={<BellOutlined />}
          />
        </Tooltip>
      </SeparatedRowLayout>
    </ActionsBar>
  );
};

export default TenantMonitorActionBar;
