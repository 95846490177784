import {
  CONTACT_INFO_REMOVE_BUTTON_OFFSET,
  getEditContactInfoColumns,
  IContactInfoEditableProps,
} from 'components/molecules/ContactInfoEditable/ContactInfoEditable';
import EditDataTable from 'components/molecules/EditDataTable/EditDataTable';
import { DETAIL_POPOVER_DATA_TABLE_MAXIMUM_HEIGHT } from 'constants/styles';
import { IContactInfo } from 'interfaces/General';
import { IViewDataTableColumn } from 'interfaces/View';
import { useCallback, useMemo } from 'react';
import { getInitialContactInfo } from 'utils/detail';

export interface IContactInfoEditProps extends IContactInfoEditableProps {
  editButtonWidth?: string;
  equalityChecker?: (a: IContactInfo[], b: IContactInfo[]) => boolean;
  highlightButton?: boolean;
  id: string;
  initialData?: IContactInfo[] | null;
  tableTitle?: string;
}

const ContactInfoEdit = (props: IContactInfoEditProps): JSX.Element => {
  const {
    contactInputColumnConfig,
    data,
    editButtonWidth,
    equalityChecker,
    faxInputPhoneNumberColumnConfig,
    hasChanged,
    highlightButton,
    id,
    initialData,
    initialEditKey,
    isDisabled,
    onRemove,
    phoneInputPhoneNumberColumnConfig,
    tableTitle,
  } = props;

  const editContactInfoColumns: IViewDataTableColumn<IContactInfo>[] = useMemo(
    () =>
      getEditContactInfoColumns(
        contactInputColumnConfig,
        phoneInputPhoneNumberColumnConfig,
        faxInputPhoneNumberColumnConfig,
        hasChanged,
      ),
    [
      contactInputColumnConfig,
      faxInputPhoneNumberColumnConfig,
      hasChanged,
      phoneInputPhoneNumberColumnConfig,
    ],
  );

  const initialiser = useCallback(
    () => getInitialContactInfo(initialEditKey),
    [initialEditKey],
  );

  return (
    <EditDataTable<IContactInfo>
      columns={editContactInfoColumns}
      data={data}
      editButtonWidth={editButtonWidth}
      equalityChecker={equalityChecker}
      highlightButton={highlightButton}
      id={id}
      initialData={initialData}
      initialiser={initialiser}
      isDisabled={isDisabled}
      maximumAllowableAdds={1}
      maximumHeight={DETAIL_POPOVER_DATA_TABLE_MAXIMUM_HEIGHT}
      onRemove={onRemove}
      pagination={false}
      removeButtonOffset={CONTACT_INFO_REMOVE_BUTTON_OFFSET}
      showDataCount={true}
      tableTitle={tableTitle}
      tooltipTitle='Edit Contact Info'
    />
  );
};

export default ContactInfoEdit;
