import {
  DATA_GRID_CONTEXT_MENU_INSERT_ABOVE_ID,
  DATA_GRID_CONTEXT_MENU_INSERT_BELOW_ID,
  DATA_GRID_CONTEXT_MENU_REMOVE_ABOVE_ID,
  DATA_GRID_CONTEXT_MENU_REMOVE_BELOW_ID,
  DATA_GRID_CONTEXT_MENU_REMOVE_ID,
} from 'components/organisms/ProfileInformationView/constants';
import { IDataGridMenuItemProps } from 'components/organisms/ProfileInformationView/types';
import { DATA_GRID_CONTEXT_MENU_ID } from 'constants/Detail';
import { ISelectedCellsMapDimensions } from 'interfaces/Component';
import { useCallback } from 'react';
import { Item, ItemParams, Menu, Separator } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.css';
import { TRow } from 'types/Component';
import { TProfileDataGridRow } from 'types/Detail';
import { getSelectedCellsMapDimensions } from 'utils/component';

interface IDataGridContextMenuProps {
  isDisabled?: boolean;
  onDeleteRowsAbove: (profileDataGridRow: TProfileDataGridRow) => void;
  onDeleteRowsBelow: (profileDataGridRow: TProfileDataGridRow) => void;
  onDeleteSelectedRows: (fromRow: TRow, toRow: TRow) => void;
  onInsertAboveRow: (profileDataGridRow: TProfileDataGridRow) => void;
  onInsertBelowRow: (profileDataGridRow: TProfileDataGridRow) => void;
}

const DataGridContextMenu = ({
  isDisabled,
  onDeleteRowsAbove,
  onDeleteRowsBelow,
  onDeleteSelectedRows,
  onInsertAboveRow,
  onInsertBelowRow,
}: IDataGridContextMenuProps): JSX.Element => {
  const handleInsertAboveRowItemClick = useCallback(
    (itemParams: ItemParams<IDataGridMenuItemProps>) => {
      if (itemParams.props !== undefined) {
        const { profileDataGridRow } = itemParams.props;

        onInsertAboveRow(profileDataGridRow);
      }
    },
    [onInsertAboveRow],
  );

  const handleInsertBelowRowItemClick = useCallback(
    (itemParams: ItemParams<IDataGridMenuItemProps>) => {
      if (itemParams.props !== undefined) {
        const { profileDataGridRow } = itemParams.props;

        onInsertBelowRow(profileDataGridRow);
      }
    },
    [onInsertBelowRow],
  );

  const handleDeleteSelectedRowsItemClick = useCallback(
    (itemParams: ItemParams<IDataGridMenuItemProps>) => {
      if (itemParams.props !== undefined) {
        const { selectedCells } = itemParams.props;
        const { numSelectedRows, startPosition }: ISelectedCellsMapDimensions =
          getSelectedCellsMapDimensions(selectedCells);
        const { rowIdx } = startPosition;
        const fromRow: TRow = rowIdx;
        const toRow: TRow = fromRow + numSelectedRows - 1;

        onDeleteSelectedRows(fromRow, toRow);
      }
    },
    [onDeleteSelectedRows],
  );

  const handleDeleteRowsAboveItemClick = useCallback(
    (itemParams: ItemParams<IDataGridMenuItemProps>) => {
      if (itemParams.props !== undefined) {
        const { profileDataGridRow } = itemParams.props;

        onDeleteRowsAbove(profileDataGridRow);
      }
    },
    [onDeleteRowsAbove],
  );

  const handleDeleteRowsBelowItemClick = useCallback(
    (itemParams: ItemParams<IDataGridMenuItemProps>) => {
      if (itemParams.props !== undefined) {
        const { profileDataGridRow } = itemParams.props;

        onDeleteRowsBelow(profileDataGridRow);
      }
    },
    [onDeleteRowsBelow],
  );

  return (
    <Menu
      animation={false}
      className='data-grid-context-menu'
      id={DATA_GRID_CONTEXT_MENU_ID}
    >
      <Item
        disabled={isDisabled}
        id={DATA_GRID_CONTEXT_MENU_INSERT_ABOVE_ID}
        onClick={handleInsertAboveRowItemClick}
      >
        Insert Above Row
      </Item>
      <Item
        disabled={isDisabled}
        id={DATA_GRID_CONTEXT_MENU_INSERT_BELOW_ID}
        onClick={handleInsertBelowRowItemClick}
      >
        Insert Below Row
      </Item>
      <Separator />
      <Item
        disabled={isDisabled}
        id={DATA_GRID_CONTEXT_MENU_REMOVE_ID}
        onClick={handleDeleteSelectedRowsItemClick}
      >
        Delete Selected Rows
      </Item>
      <Separator />
      <Item
        disabled={isDisabled}
        id={DATA_GRID_CONTEXT_MENU_REMOVE_ABOVE_ID}
        onClick={handleDeleteRowsAboveItemClick}
      >
        Delete All Rows Above
      </Item>
      <Item
        disabled={isDisabled}
        id={DATA_GRID_CONTEXT_MENU_REMOVE_BELOW_ID}
        onClick={handleDeleteRowsBelowItemClick}
      >
        Delete All Rows Below
      </Item>
    </Menu>
  );
};

export default DataGridContextMenu;
