import { Dropdown as AntDesignDropdown, Menu as AntDesignMenu } from 'antd';
import Button from 'components/atoms/Button/Button';
import Tooltip from 'components/molecules/Tooltip/Tooltip';
import { FILTER_BUTTON_WIDTH_VALUE, HIGHLIGHT_BLUE } from 'constants/styles';
import { ESearchStringType } from 'enums/Search';
import { ISearchStringDetail } from 'interfaces/Search';
import { MenuInfo } from 'rc-menu/lib/interface';
import { HTMLAttributes, useCallback, useMemo } from 'react';
import styled from 'styled-components';

interface IButtonWrapperProps extends HTMLAttributes<HTMLButtonElement> {
  active?: boolean;
}

const ButtonWrapper = styled.span<IButtonWrapperProps>`
  > button {
    font-size: 9px;
    padding: 0;
    width: ${FILTER_BUTTON_WIDTH_VALUE}px;

    ${(props) =>
      props.active
        ? `
        color: ${HIGHLIGHT_BLUE};
        font-weight: bold;
      `
        : ''};
  }
`;

interface ISearchKindButtonProps {
  isDisabled?: boolean;
  kinds: Record<ESearchStringType, ISearchStringDetail>;
  onChange?: (searchKind: ESearchStringType) => void;
  searchKind: ESearchStringType;
}

const SearchKindButton = ({
  isDisabled,
  kinds,
  onChange,
  searchKind,
}: ISearchKindButtonProps): JSX.Element => {
  const handleSearchKindSelect = useCallback(
    (menuInfo: MenuInfo) => {
      if (onChange) {
        onChange(menuInfo.key as ESearchStringType);
      }
    },
    [onChange],
  );

  const SearchKindMenu: JSX.Element = useMemo(
    () => (
      <AntDesignMenu onClick={handleSearchKindSelect}>
        {Object.keys(kinds).map((key: string) => {
          const kind: ESearchStringType = key as ESearchStringType;
          const { description } = kinds[kind];

          return (
            <AntDesignMenu.Item key={key}>{description}</AntDesignMenu.Item>
          );
        })}
      </AntDesignMenu>
    ),
    [handleSearchKindSelect, kinds],
  );

  const searchStringDetail: ISearchStringDetail = useMemo(
    () => kinds[searchKind],
    [kinds, searchKind],
  );

  return (
    <Tooltip isDisabled={isDisabled} title={searchStringDetail.description}>
      <AntDesignDropdown
        disabled={isDisabled}
        overlay={SearchKindMenu}
        trigger={['click']}
      >
        <ButtonWrapper active={true}>
          <Button isDisabled={isDisabled} label={searchStringDetail.label} />
        </ButtonWrapper>
      </AntDesignDropdown>
    </Tooltip>
  );
};

export default SearchKindButton;
