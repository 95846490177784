import { Table as AntDesignTable } from 'antd';
import { PROFILE_PATH_START_KEY } from 'constants/Detail';
import { SUMMARY_PROFILE_TOTAL_LABEL } from 'constants/Summary';
import { DATE_TIME_FORMAT } from 'constants/time';
import { EProfileDataGridCellType } from 'enums/Detail';
import { IProfileDataGridCell } from 'interfaces/Detail';
import { IETagEnergyProfileSnapshot } from 'interfaces/ETag';
import { IEnergyProfile } from 'interfaces/General';
import { IViewDataTableColumn } from 'interfaces/View';
import { TTimeZone } from 'types/DateTime';
import {
  TProfileDataGridColumn,
  TProfileDataGridSummaryRow,
} from 'types/Detail';
import { getBackgroundColourForEnergyProfile } from 'utils/detail';
import { getColumnRender } from 'utils/views';
import { ZonedDateTime } from 'utils/zonedDateTime';

const AntDesignSummaryRow = AntDesignTable.Summary.Row;
const AntDesignSummaryCell = AntDesignTable.Summary.Cell;

const getValueForProfileDataGridCell =
  (timeZone: TTimeZone) =>
  (profileDataGridCell: IProfileDataGridCell | null): string => {
    if (profileDataGridCell !== null) {
      const { type, value } = profileDataGridCell;
      if (type === EProfileDataGridCellType.DateTimeString) {
        return ZonedDateTime.parseIso(value as string, timeZone).format(
          DATE_TIME_FORMAT,
        );
      } else if (type === EProfileDataGridCellType.EnergyProfile) {
        const { mw } = value as IEnergyProfile;
        return mw === null ? '' : mw.toString();
      } else if (type === EProfileDataGridCellType.EnergyProfileSnapshot) {
        const { mw } = value as IETagEnergyProfileSnapshot;
        return mw === null ? '' : mw.toString();
      } else if (type === EProfileDataGridCellType.Number) {
        return (value as Number).toString();
      }
    }

    return '';
  };

const getBackgroundColourForProfileDataGridCell =
  (selectedRequestKey: string) =>
  (profileDataGridCell: IProfileDataGridCell | null): string | undefined => {
    if (profileDataGridCell !== null) {
      const { type, value } = profileDataGridCell;

      if (type === EProfileDataGridCellType.EnergyProfile) {
        return getBackgroundColourForEnergyProfile(
          value as IEnergyProfile,
          selectedRequestKey,
        );
      }
    }

    return undefined;
  };

export const getProfileDataTableDataFor = (
  profileInformationColumns: TProfileDataGridColumn[],
  profileInformationSummaryRows: TProfileDataGridSummaryRow[],
  selectedRequestKey: string,
  timeZone: TTimeZone,
) => {
  const profileColumns: IViewDataTableColumn<IProfileDataGridCell | null>[] =
    profileInformationColumns.map(
      (profileDataGridColumn: TProfileDataGridColumn) => {
        return {
          dataIndex: profileDataGridColumn.key,
          render: getColumnRender(
            true,
            getValueForProfileDataGridCell(timeZone),
            undefined,
            getBackgroundColourForProfileDataGridCell(selectedRequestKey),
          ),
          title: profileDataGridColumn.name,
        };
      },
    );

  const profileSummary = (): JSX.Element => {
    return (
      <>
        {profileInformationSummaryRows.map(
          (
            profileDataGridSummaryRow: TProfileDataGridSummaryRow,
            summaryRowIndex: number,
          ) => (
            <AntDesignSummaryRow key={`summary-row-${summaryRowIndex}`}>
              {profileColumns.map(
                (
                  profileColumn: IViewDataTableColumn<IProfileDataGridCell | null>,
                  columnIndex: number,
                ): JSX.Element => {
                  const key: string = profileColumn.dataIndex as string;
                  const value: number | undefined =
                    profileDataGridSummaryRow[key];

                  return (
                    <AntDesignSummaryCell index={columnIndex} key={key}>
                      {key === PROFILE_PATH_START_KEY
                        ? SUMMARY_PROFILE_TOTAL_LABEL
                        : null}
                      {value === undefined ? null : value}
                    </AntDesignSummaryCell>
                  );
                },
              )}
            </AntDesignSummaryRow>
          ),
        )}
      </>
    );
  };

  return {
    profileColumns,
    profileSummary,
  };
};
