import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal as AntDesignModal } from 'antd';
import { AxiosResponse } from 'axios';
import ErrorMessage from 'components/atoms/ErrorMessage/ErrorMessage';
import IconButton from 'components/atoms/IconButton/IconButton';
import Tooltip from 'components/molecules/Tooltip/Tooltip';
import { BUTTON_ICON_DIMENSIONS } from 'constants/styles';
import { IETagTemplate, IETagTemplateResponse } from 'interfaces/ETag';
import { useCallback, useState } from 'react';
import { deleteETagTemplate } from 'services/agent/templates';
import styled from 'styled-components';
import { TToEntityId } from 'types/ToEntity';
import { encodeIds, isEmptyValue, isSuccessStatus } from 'utils/general';

const { confirm } = AntDesignModal;

const DeleteTemplateIcon = styled(DeleteOutlined)`
  ${BUTTON_ICON_DIMENSIONS}
`;

interface IProps {
  encodedPermissionsId: string;
  isDisabled?: boolean;
  onSuccess?: () => void;
  template: IETagTemplate | undefined;
  toEntityId: TToEntityId;
}

const ToEntityETagTemplateDelete = (props: IProps): JSX.Element => {
  const { encodedPermissionsId, isDisabled, onSuccess, template, toEntityId } =
    props;
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const handleDelete = useCallback(() => {
    setIsVisible(true);
    if (template === undefined) {
      throw new Error('No template selected');
    }
    const modalRef = confirm({
      title: `Are you sure you want to permanently delete template ${
        isEmptyValue(template.name)
          ? 'with no Template ID'
          : `"${template.name}"`
      }?`,
      icon: <ExclamationCircleOutlined />,
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk: async () => {
        try {
          modalRef.update({ content: undefined });

          if (isEmptyValue(template.name)) {
            throw new Error('Invalid templateId');
          }

          const response: AxiosResponse<IETagTemplateResponse> =
            await deleteETagTemplate(toEntityId, template.id);
          const eTagTemplateDeleteResponse: IETagTemplateResponse =
            response.data;

          if (!isSuccessStatus(response.status)) {
            throw new Error(eTagTemplateDeleteResponse.errorMessage!);
          }
        } catch (error: any) {
          modalRef.update({
            content: (
              <ErrorMessage>
                An error occurred during template tag deletion. Please try again
                later.
              </ErrorMessage>
            ),
          });

          // Propagate the error to prevent the modal from closing
          throw error;
        } finally {
          setIsVisible(false);
          if (onSuccess) {
            onSuccess();
          }
        }
      },
      onCancel: () => {
        setIsVisible(false);
      },
    });
  }, [template, onSuccess, toEntityId]);

  const asjustedIsDisabled: boolean = isDisabled || template === undefined;

  return (
    <Tooltip isDisabled={isDisabled || isVisible} title='Delete Template'>
      <IconButton
        encodedPermissionsId={encodeIds([encodedPermissionsId], toEntityId)}
        icon={<DeleteTemplateIcon />}
        isDisabled={asjustedIsDisabled}
        onClick={handleDelete}
      />
    </Tooltip>
  );
};

export default ToEntityETagTemplateDelete;
