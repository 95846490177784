import { AxiosResponse } from 'axios';
import DataTable, {
  IDataTableProps,
  TDataTableKey,
} from 'components/molecules/DataTable/DataTable';
import {
  EDIT_ETAG_PENDING_REQUEST_LABEL,
  pendingRequestsSorter,
  SCROLL_CONFIGURATION,
} from 'components/organisms/ToEntityETagReviewPendingRequests/constants';
import {
  editPendingRequestsToPendingRequests,
  getCustomComponents,
  getPendingRequestForKey,
} from 'components/organisms/ToEntityETagReviewPendingRequests/helpers';
import { IPendingRequestRowProps } from 'components/organisms/ToEntityETagReviewPendingRequests/PendingRequestRow';
import {
  IEditPendingRequests,
  IModalHandlers,
  IPendingRequest,
  ISendResponse,
} from 'components/organisms/ToEntityETagReviewPendingRequests/types';
import useReviewPendingRequestsColumns from 'components/organisms/ToEntityETagReviewPendingRequests/useReviewPendingRequestsColumns';
import { DEFAULT_REASONS } from 'constants/Approval';
import {
  VIEW_DATA_TABLE_CENTERED_CONTENT,
  VIEW_DATA_TABLE_SHARED_STYLES,
} from 'constants/styles';
import { EApprovalStatus } from 'enums/Approval';
import { EActionState } from 'enums/General';
import {
  IETagPendingRequest,
  IETagPendingRequestsResponse,
  IETagReasons,
  IETagReasonsResponse,
  IETagSetStateOnRequestResponse,
} from 'interfaces/ETag';
import { TableComponents } from 'rc-table/lib/interface';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  retrieveAllPendingRequests,
  retrievePendingRequests,
  retrieveReasons,
  setStateOnRequest,
} from 'services/approval/approval';
import styled from 'styled-components';
import { TTimeZone } from 'types/DateTime';
import { TErrorMessage } from 'types/Error';
import { TETagTagPrimaryKey } from 'types/ETag';
import { TToEntityId } from 'types/ToEntity';
import useAsyncEffect from 'use-async-effect';
import { getEditInfoKey } from 'utils/detail';
import { captureError } from 'utils/error';
import { isSuccessStatus } from 'utils/general';

// Specialize the DataTable component

const PendingRequestsDataTable = styled(
  (props: IDataTableProps<any, IPendingRequest>) => <DataTable {...props} />,
)`
  .ant-table-content {
    max-height: 460px;
    overflow: auto !important;
  }
  .ant-table-thead {
    > tr > th {
      padding: 2px;
      position: sticky !important;
      z-index: 999;
      text-align: center;
      top: 0px !important;
    }
  }

  ${VIEW_DATA_TABLE_SHARED_STYLES}
  ${VIEW_DATA_TABLE_CENTERED_CONTENT}
`;

const StyledModalContent = styled.div`
  max-height: 650px;
`;

export interface IReviewPendingRequestsProps {
  isDisabled?: boolean;
  isUnconstrained: boolean;
  setErrorMessage: (errorMessage: TErrorMessage) => void;
  setHandlers: (handlers: IModalHandlers) => void;
  setIsConfirmDisabled: (isConfirmDisabled: boolean) => void;
  selectedTagPrimaryKeys: TETagTagPrimaryKey[];
  refreshFlag: boolean;
  timeZone: TTimeZone;
  toEntityId: TToEntityId;
}

const ReviewPendingRequests = ({
  isDisabled,
  isUnconstrained,
  setErrorMessage,
  setHandlers,
  setIsConfirmDisabled,
  selectedTagPrimaryKeys,
  refreshFlag,
  timeZone,
  toEntityId,
}: IReviewPendingRequestsProps): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<IPendingRequest[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<TDataTableKey[]>([]);
  const [reasons, setReasons] = useState<IETagReasons>(DEFAULT_REASONS);

  const [allPendingRequests, setAllPendingRequests] = useState<
    IETagPendingRequest[]
  >([]);
  const [pendingRequests, setPendingRequests] = useState<IPendingRequest[]>([]);

  useEffect(() => {
    setIsConfirmDisabled(
      data.find((request: IPendingRequest) => request.action === null) !==
        undefined,
    );
  }, [data, setIsConfirmDisabled]);

  useEffect(() => {
    const pendingRequests: IPendingRequest[] = [];
    const selectedRowKeys: string[] = [];
    allPendingRequests.forEach(
      (eTagPendingRequest: IETagPendingRequest, index: number) => {
        const pendingRequest: IPendingRequest = {
          ...eTagPendingRequest,
          action:
            eTagPendingRequest.pci_suggested_action === null
              ? EApprovalStatus.Approved
              : eTagPendingRequest.pci_suggested_action ===
                EApprovalStatus.Nothing
              ? null
              : eTagPendingRequest.pci_suggested_action,
          errorMessage: null,
          key: getEditInfoKey(EDIT_ETAG_PENDING_REQUEST_LABEL, index, index),
          pci_suggested_note:
            eTagPendingRequest.pci_suggested_action === EApprovalStatus.Nothing
              ? null
              : eTagPendingRequest.pci_suggested_note,
        };
        pendingRequests.push(pendingRequest);
        if (eTagPendingRequest.last_action_taken === '') {
          selectedRowKeys.push(pendingRequest.key);
        }
      },
    );
    setPendingRequests(pendingRequests.sort(pendingRequestsSorter));
    setSelectedRowKeys(selectedRowKeys);
  }, [allPendingRequests]);

  useAsyncEffect(async () => {
    try {
      setIsLoading(true);

      setErrorMessage(null);
      if (selectedTagPrimaryKeys.length === 0) {
        const allPendingRequestsResponse: AxiosResponse<IETagPendingRequestsResponse> =
          await retrieveAllPendingRequests(toEntityId, timeZone);

        if (!isSuccessStatus(allPendingRequestsResponse.status)) {
          setErrorMessage(allPendingRequestsResponse.data.errorMessage);
        } else {
          setAllPendingRequests(allPendingRequestsResponse.data.response);
        }
      } else {
        const pendingRequestPromises: Promise<
          AxiosResponse<IETagPendingRequestsResponse>
        >[] = [];
        selectedTagPrimaryKeys.forEach((tagPrimaryKey: string) => {
          pendingRequestPromises.push(
            retrievePendingRequests(toEntityId, tagPrimaryKey, timeZone),
          );
        });

        const pendingRequestsResponses: PromiseSettledResult<
          AxiosResponse<IETagPendingRequestsResponse>
        >[] = await Promise.allSettled(pendingRequestPromises);

        const pendingRequestsFromResponse: IETagPendingRequest[] = [];

        pendingRequestsResponses.forEach(
          (
            pendingRequestsResponse: PromiseSettledResult<
              AxiosResponse<IETagPendingRequestsResponse>
            >,
          ) => {
            if (pendingRequestsResponse.status === 'fulfilled') {
              const eTagPendingRequestsResponse: IETagPendingRequestsResponse =
                pendingRequestsResponse.value.data;
              if (!isSuccessStatus(pendingRequestsResponse.value.status)) {
                throw new Error(
                  eTagPendingRequestsResponse.errorMessage === null
                    ? 'Unknown error'
                    : eTagPendingRequestsResponse.errorMessage,
                );
              }
              pendingRequestsFromResponse.push(
                ...eTagPendingRequestsResponse.response,
              );
            }
          },
        );
        setAllPendingRequests(pendingRequestsFromResponse);
      }
    } catch (error: any) {
      captureError(error);

      setErrorMessage(
        'An error occurred during loading. Please try again later.',
      );
    } finally {
      setIsLoading(false);
    }
  }, [selectedTagPrimaryKeys, refreshFlag]);

  useEffect(() => {
    setData(pendingRequests);
  }, [pendingRequests]);

  useAsyncEffect(async () => {
    try {
      const reasonsResponse: AxiosResponse<IETagReasonsResponse> =
        await retrieveReasons(toEntityId);

      if (isSuccessStatus(reasonsResponse.status)) {
        const eTagReasonsResponse: IETagReasonsResponse = reasonsResponse.data;

        setReasons(eTagReasonsResponse.response as IETagReasons);
      }
    } catch (error: any) {
      captureError(error);
    }
  }, []);

  useEffect(() => {
    const modalHandlers: IModalHandlers = {};
    const sendResponse: ISendResponse = {
      actionState: EActionState.NoAction,
      errorMessage: null,
      shouldClose: false,
    };

    if (selectedRowKeys.length > 0) {
      modalHandlers.sendHandler = async () => {
        const updatedData: IPendingRequest[] = [...data];
        const setStateOnRequests: Promise<
          AxiosResponse<IETagSetStateOnRequestResponse>
        >[] = [];
        const pendingRequestKeys: string[] = [];
        let updatedSelectedRowKeys: TDataTableKey[] = [...selectedRowKeys];

        for (let selectedRowKey of selectedRowKeys) {
          const key: string = selectedRowKey as string;
          const pendingRequest: IPendingRequest | undefined =
            getPendingRequestForKey(key, data);

          if (pendingRequest !== undefined && pendingRequest.action !== null) {
            pendingRequestKeys.push(key);

            setStateOnRequests.push(
              setStateOnRequest(
                toEntityId,
                pendingRequest.tag_primary_key,
                pendingRequest.request_id,
                pendingRequest.action,
                pendingRequest.pci_suggested_note,
              ),
            );
          }
        }

        const setStateOnRequestsResponses: PromiseSettledResult<
          AxiosResponse<IETagSetStateOnRequestResponse>
        >[] = await Promise.allSettled(setStateOnRequests);

        sendResponse.actionState = EActionState.Succeeded;

        setStateOnRequestsResponses.forEach(
          (
            setStateOnRequestsResponse: PromiseSettledResult<
              AxiosResponse<IETagSetStateOnRequestResponse>
            >,
            index: number,
          ) => {
            const key: string = pendingRequestKeys[index];
            const updatedPendingRequestIndex: number = updatedData.findIndex(
              (pendingRequest: IPendingRequest): boolean =>
                pendingRequest.key === key,
            );

            if (updatedPendingRequestIndex !== -1) {
              if (setStateOnRequestsResponse.status === 'fulfilled') {
                if (!isSuccessStatus(setStateOnRequestsResponse.value.status)) {
                  updatedData[updatedPendingRequestIndex] = {
                    ...updatedData[updatedPendingRequestIndex],
                    errorMessage:
                      setStateOnRequestsResponse.value.data.errorMessage,
                  };

                  sendResponse.actionState = EActionState.Failed;
                } else {
                  updatedData.splice(updatedPendingRequestIndex, 1);

                  updatedSelectedRowKeys = updatedSelectedRowKeys.filter(
                    (selectedRowKey: TDataTableKey): boolean =>
                      selectedRowKey !== key,
                  );
                }
              } else if (setStateOnRequestsResponse.status === 'rejected') {
                updatedData[updatedPendingRequestIndex] = {
                  ...updatedData[updatedPendingRequestIndex],
                  errorMessage: setStateOnRequestsResponse.reason,
                };

                sendResponse.actionState = EActionState.Failed;
              }
            }
          },
        );

        sendResponse.shouldClose = updatedData.length === 0;

        setData(updatedData);

        setSelectedRowKeys(updatedSelectedRowKeys);

        return sendResponse;
      };
    }
    setHandlers(modalHandlers);
  }, [data, selectedRowKeys, setHandlers, selectedTagPrimaryKeys, toEntityId]);

  const handleSelectChange = useCallback((selectedRowKeys: TDataTableKey[]) => {
    setSelectedRowKeys(selectedRowKeys.map((key: TDataTableKey) => `${key}`));
  }, []);

  const rowSelection = useMemo(
    () => ({
      onChange: isDisabled ? undefined : handleSelectChange,
      selectedRowKeys,
    }),
    [handleSelectChange, isDisabled, selectedRowKeys],
  );

  const handleChange = useCallback(
    (editPendingRequests: IEditPendingRequests) => {
      setData((previousData: IPendingRequest[]): IPendingRequest[] =>
        editPendingRequestsToPendingRequests(editPendingRequests, previousData),
      );
    },
    [],
  );

  const customComponents: TableComponents<IPendingRequest> = useMemo(
    () => getCustomComponents(),
    [],
  );

  const onRow = useCallback(
    (record: IPendingRequest): IPendingRequestRowProps => ({
      record,
    }),
    [],
  );

  const columns = useReviewPendingRequestsColumns(
    isDisabled,
    isUnconstrained,
    handleChange,
    reasons,
  );

  return (
    <StyledModalContent>
      <PendingRequestsDataTable
        columns={columns}
        components={customComponents}
        data={data}
        hasBorders={false}
        isLoading={isLoading}
        onRow={onRow}
        pagination={false}
        rowSelection={rowSelection}
        scrollConfig={SCROLL_CONFIGURATION}
        tableLayout='fixed'
      />
    </StyledModalContent>
  );
};

export default ReviewPendingRequests;
