import { Divider } from 'antd';
import { AxiosResponse } from 'axios';
import Button from 'components/atoms/Button/Button';
import EditorFooter from 'components/molecules/EditorFooter/EditorFooter';
import Modal from 'components/molecules/Modal/Modal';
import ProductProfileDataTable from 'components/molecules/ProductProfileDataTable/ProductProfileDataTable';
import Tooltip from 'components/molecules/Tooltip/Tooltip';
import ProductProfileSelector from 'components/organisms/ProductProfileSelection/ProductProfileSelector';
import { STANDARD_SPACING } from 'constants/styles';
import { DATE_TIME_FORMAT } from 'constants/time';
import { EActionState } from 'enums/General';
import { IOption } from 'interfaces/Component';
import {
  ICustomHolidayListResponse,
  IDailyProductProfile,
  IDailyProductProfileResponse,
  IProductProfileId,
  IProductProfileIdsResponse,
} from 'interfaces/Config';
import {
  IProductProfile,
  IProfileInterval,
  IProfileIntervalGridData,
} from 'interfaces/Detail';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  retrieveCustomHolidayList,
  retrieveDailyProfileTemplates,
  retrieveSingleDailyProfileTemplate,
} from 'services/configclient/config';
import styled from 'styled-components';
import { TTimeZone } from 'types/DateTime';
import { TErrorMessage } from 'types/Error';
import { TToEntityId } from 'types/ToEntity';
import useAsyncEffect from 'use-async-effect';
import { captureError } from 'utils/error';
import { encodeIds, isEmptyValue, isSuccessStatus } from 'utils/general';

const PRODUCT_PROFILE_MODAL_WIDTH = '1150px';

const ModalLayout = styled.div`
  width: 100%;
  height: 340px;
  overflow-y: auto;
  display: flex;
  > :not(:last-child) {
    margin-bottom: ${STANDARD_SPACING};
  }
`;

const ProductProfileButtonWrapper = styled.span`
  > button {
    font-size: 12px;
    width: 120px;
    margin-right: 4px;
  }
`;

const ProductProfileSelectorsLayout = styled.div`
  width: calc(${PRODUCT_PROFILE_MODAL_WIDTH} * 2 / 3);
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: 0;

  > :not(:last-child) {
    margin-right: 30px;
    margin-bottom: ${STANDARD_SPACING};
  }
`;

const CombinedIntervalsWrapper = styled.div`
  width: calc(${PRODUCT_PROFILE_MODAL_WIDTH} * 0.32);
  height: 100%;
  margin-top: 0;
  margin-right: 20px;
  padding-left: 30px;
`;

const CombinedIntervalLabel = styled.div`
  display: flex;
  justify-content: center;
  font-size: 14px;
  margin-bottom: ${STANDARD_SPACING};
`;

const StyledDivider = styled(Divider)`
  height: 340px;
  margin-bottom: 0 !important;
`;

interface IProductProfileModalProps {
  encodedPermissionsId: string;
  isDisabled?: boolean;
  onApply: (combinedIntervals: IProfileInterval[]) => void;
  startDate?: string | null;
  timeZone: TTimeZone;
  toEntityId: TToEntityId;
}

const ProductProfileModal = ({
  encodedPermissionsId,
  isDisabled,
  onApply,
  startDate,
  timeZone,
  toEntityId,
}: IProductProfileModalProps): JSX.Element => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isPrimary, setIsPrimary] = useState<boolean>(false);
  const [modalButtonActionState, setModalButtonActionState] =
    useState<EActionState>(EActionState.Actioning);

  const [clearButtonActionState, setClearButtonActionState] =
    useState<EActionState>(EActionState.NoAction);

  const [productProfileButtonLabel, setProductProfileButtonLabel] =
    useState<string>('Product Profiles');

  const [productProfileButtonTooltip, setProductProfileButtonTooltip] =
    useState<string>('No profiles applied');

  const [errorMessage, setErrorMessage] = useState<TErrorMessage>(null);
  const [isConfirmDisabled, setIsConfirmDisabled] = useState<boolean>(false);

  const [productProfileOptions, setProductProfileOptions] = useState<
    IOption<string>[]
  >([]);

  const [productProfilesMap, setProductProfileMap] =
    useState<Map<string, IDailyProductProfile>>();

  const [productProfileSelectors, setProductProfileSelectors] = useState<
    JSX.Element[]
  >([]);

  const [allProductProfileIntervals, setAllProductProfileIntervals] = useState<
    IProfileInterval[][]
  >([]);

  const [combinedIntervals, setCombinedIntervals] = useState<
    IProfileInterval[]
  >([]);

  const [isFinalDataTableLoading, setIsFinalDataTableLoading] =
    useState<boolean>(false);

  const [finalProfileGridData, setFinalProfileGridData] = useState<
    IProfileIntervalGridData[]
  >([]);

  const [productIntervalsChange, setProductIntervalsChange] = useState<
    | { incomingIntervals: IProfileInterval[] | undefined; index: number }
    | undefined
  >(undefined);

  const [productProfileChange, setProductProfileChange] = useState<
    | {
        incomingProductProfile: IProductProfile | undefined;
        index: number;
        isAdding?: boolean;
      }
    | undefined
  >(undefined);

  const [currentProductProfiles, setCurrentProductProfiles] = useState<
    IProductProfile[]
  >([]);

  const [productProfilesZeroed, setProductProfilesZeroed] =
    useState<boolean>(false);

  const prevProductProfilesLength = useRef<number>(0);
  const [modalIndexCounter, setModalIndexCounter] = useState<number>(0);

  useEffect(() => {
    if (productIntervalsChange === undefined) {
      return;
    }

    let updatedIntervalValues = [...allProductProfileIntervals];
    if (productIntervalsChange.incomingIntervals === undefined) {
      updatedIntervalValues.splice(productIntervalsChange.index, 1);
    } else {
      updatedIntervalValues[productIntervalsChange.index] =
        productIntervalsChange.incomingIntervals!;
    }
    setAllProductProfileIntervals(updatedIntervalValues);
    setProductIntervalsChange(undefined);
  }, [allProductProfileIntervals, productIntervalsChange]);

  const handleProductProfileChange = (
    productProfile: IProductProfile | undefined,
    index: number,
    isAdding?: boolean,
  ) => {
    setProductProfileChange({
      incomingProductProfile: productProfile,
      index,
      isAdding,
    });
  };

  const handleProductProfileIntervalsChange = (
    productProfileIntervals: IProfileInterval[] | undefined,
    index: number,
  ) => {
    setIsConfirmDisabled(true);
    setProductIntervalsChange({
      incomingIntervals: productProfileIntervals,
      index,
    });
  };

  const handleProductProfileRemove = useCallback((removeIndex: number) => {
    setProductProfileChange(undefined);
    setProductIntervalsChange(undefined);
    handleProductProfileChange(undefined, removeIndex);
    handleProductProfileIntervalsChange(undefined, removeIndex);
  }, []);

  const handleProductProfileAdd = useCallback(
    (addIndex: number) => {
      const newProfile: IProductProfile = {
        startDateTime: startDate,
        selectedProductProfileId: undefined,
        selectedProductProfileName: undefined,
        stopDateTime: undefined,
        genRequest: null,
      };
      handleProductProfileChange(newProfile, addIndex, true);
    },
    [startDate],
  );

  const buildSelectors = useCallback(() => {
    const updatedProductProfileSelectors: JSX.Element[] = [];

    currentProductProfiles.forEach(
      (productProfile: IProductProfile, index: number) => {
        const uniqueKey = (
          (productProfile.selectedProductProfileId ?? '') +
          (productProfile.startDateTime ?? '') +
          (productProfile.stopDateTime ?? '') +
          modalIndexCounter.toString() +
          index.toString()
        ).toString();
        updatedProductProfileSelectors.push(
          <ProductProfileSelector
            onAdd={handleProductProfileAdd}
            onRemove={handleProductProfileRemove}
            onIntervalsChange={handleProductProfileIntervalsChange}
            onProfileChange={handleProductProfileChange}
            productProfile={productProfile}
            productOptions={productProfileOptions}
            productProfilesMap={productProfilesMap}
            timeZone={timeZone}
            toEntityId={toEntityId}
            selectorIndex={index}
            setParentConfirmDisabled={setIsConfirmDisabled}
            key={uniqueKey}
          />,
        );
      },
    );
    setProductProfileSelectors(updatedProductProfileSelectors);
  }, [
    currentProductProfiles,
    handleProductProfileAdd,
    handleProductProfileRemove,
    modalIndexCounter,
    productProfileOptions,
    productProfilesMap,
    timeZone,
    toEntityId,
  ]);

  useEffect(() => {
    if (productProfileChange === undefined) {
      return;
    }

    let updatedProductProfiles = [...currentProductProfiles];
    if (productProfileChange.incomingProductProfile === undefined) {
      updatedProductProfiles.splice(productProfileChange.index, 1);
    } else {
      if (productProfileChange.isAdding) {
        updatedProductProfiles.splice(
          productProfileChange.index,
          0,
          productProfileChange.incomingProductProfile,
        );
      } else {
        updatedProductProfiles[productProfileChange.index] =
          productProfileChange.incomingProductProfile!;
      }
    }
    setCurrentProductProfiles(updatedProductProfiles);
    setProductProfileChange(undefined);
  }, [buildSelectors, currentProductProfiles, productProfileChange]);

  // Recomupute the selector elements if the list of current product profiles changes,
  // but only if the number of products has changed
  useEffect(() => {
    if (currentProductProfiles.length !== prevProductProfilesLength.current) {
      buildSelectors();
      setModalIndexCounter(modalIndexCounter + productProfileSelectors.length);
      prevProductProfilesLength.current = currentProductProfiles.length;
    }
  }, [
    buildSelectors,
    currentProductProfiles,
    modalIndexCounter,
    productProfileSelectors.length,
  ]);

  // Once per render get the list of product profile options and their
  // corresponding templates.
  useAsyncEffect(async () => {
    setModalButtonActionState(EActionState.Actioning);
    try {
      const response: AxiosResponse<IProductProfileIdsResponse> =
        await retrieveDailyProfileTemplates(toEntityId);

      if (!isSuccessStatus(response.status)) {
        throw new Error(response.statusText);
      }
      let updatedProductProfileOptions: IOption<string>[] = [];
      let updatedProductProfileMap: Map<string, IDailyProductProfile> =
        new Map();
      response.data.response.forEach(
        async (productProfileId: IProductProfileId) => {
          // retrieveDailyProfileTemplates returns an array of ProductProfileIds,
          // which each have a template name, template id, and time zone string
          // From thos values we build the list of options for the select component
          // and the map of template ids to their templates, so that we handle all
          // API queries just once, on component render.

          // We only add the options that match our time zone
          if (productProfileId.time_zone_id === timeZone) {
            let trimmedLabel: string =
              productProfileId.daily_profile_template_name.includes('OnPeak')
                ? 'OnPeak'
                : productProfileId.daily_profile_template_name.includes(
                    'OffPeak',
                  )
                ? 'OffPeak'
                : productProfileId.daily_profile_template_name.includes('Flat')
                ? 'Flat'
                : productProfileId.daily_profile_template_name;
            updatedProductProfileOptions.push({
              label: trimmedLabel,
              value: productProfileId.daily_profile_template_id,
            });

            try {
              const productProfileResponse: AxiosResponse<IDailyProductProfileResponse> =
                await retrieveSingleDailyProfileTemplate(
                  toEntityId,
                  productProfileId.daily_profile_template_id,
                );

              if (!isSuccessStatus(productProfileResponse.status)) {
                throw new Error(productProfileResponse.statusText);
              }
              updatedProductProfileMap.set(
                productProfileId.daily_profile_template_id,
                productProfileResponse.data.response,
              );
            } catch (error: any) {
              captureError(error);
            }
          }
        },
      );

      setProductProfileOptions(updatedProductProfileOptions);
      setProductProfileMap(updatedProductProfileMap);
    } catch (error: any) {
      captureError(error);
    }
    setModalButtonActionState(EActionState.Succeeded);
  }, []);

  useEffect(() => {
    // Helper function to transform the raw profile intervals to data usable by the table
    const profileIntervalsToProfileGridData = async (
      profileIntervals: IProfileInterval[],
    ) => {
      if (profileIntervals.length === 0) {
        setFinalProfileGridData([]);
      } else {
        const customHolidaysResponse: AxiosResponse<ICustomHolidayListResponse> =
          await retrieveCustomHolidayList(
            toEntityId,
            profileIntervals[0].startDateTime!.format('YYYY-MM-DD'),
            profileIntervals[profileIntervals.length - 1].stopDateTime!.format(
              'YYYY-MM-DD',
            ),
          );
        const customHolidayList = customHolidaysResponse.data.response;

        const updatedProfileIntervalGridData: IProfileIntervalGridData[] = [];
        for (const interval of [...profileIntervals]) {
          updatedProfileIntervalGridData.push({
            genRequest: interval.genRequest
              ? interval.genRequest?.toString()
              : '',
            startDateTime: interval.startDateTime.format(DATE_TIME_FORMAT),
            stopDateTime: interval.stopDateTime.format(DATE_TIME_FORMAT),
            isHoliday:
              customHolidayList.includes(
                interval.startDateTime.format('YYYY-MM-DD'),
              ) ||
              customHolidayList.includes(
                interval.stopDateTime.format('YYYY-MM-DD'),
              ),
            key:
              interval.startDateTime.format(DATE_TIME_FORMAT) +
              ' ' +
              interval.stopDateTime.format(DATE_TIME_FORMAT) +
              ' ' +
              (interval.genRequest ? interval.genRequest?.toString() : ''),
          });
        }
        setFinalProfileGridData(updatedProfileIntervalGridData);
      }
    };

    setIsFinalDataTableLoading(true);
    // Whenever the array of product profile intervals changes, recompute the combination of intervals;
    // The gen request of later products overwrites earlier products if there is overlap
    let combinedProfileIntervals: IProfileInterval[] = [];

    [...allProductProfileIntervals].forEach(
      (profileIntervals: IProfileInterval[]) => {
        if (combinedProfileIntervals.length === 0) {
          combinedProfileIntervals = [...profileIntervals];
        } else {
          [...profileIntervals].forEach((interval: IProfileInterval) => {
            const lastIndex = combinedProfileIntervals.length - 1;
            for (
              let currentIndex = 0;
              currentIndex <= lastIndex;
              currentIndex++
            ) {
              const currentIndexStartTime =
                combinedProfileIntervals[currentIndex].startDateTime;
              const currentIndexStopTime =
                combinedProfileIntervals[currentIndex].stopDateTime;
              const currentIndexGenRequest =
                combinedProfileIntervals[currentIndex].genRequest;

              if (interval.startDateTime >= currentIndexStopTime) {
                if (currentIndex === lastIndex) {
                  // Easy case of interval to add being later than the stop time of the last interval in the combined array
                  combinedProfileIntervals.push(interval);
                  break;
                }
              } else if (interval.startDateTime <= currentIndexStartTime) {
                if (interval.stopDateTime <= currentIndexStartTime) {
                  // Easy case of interval to add being completely between two existing intervals (or earlier than all existing intervals)
                  combinedProfileIntervals.splice(currentIndex, 0, interval);
                  break;
                } else {
                  // Start time is before or equal to current interval's start time, but end time is after the start, so
                  // we need to find where the overlap ends and splice the intervals accordingly
                  let endIndex = currentIndex;
                  for (endIndex; endIndex <= lastIndex; endIndex++) {
                    if (
                      combinedProfileIntervals[endIndex].stopDateTime >=
                      interval.stopDateTime
                    ) {
                      break;
                    }
                  }
                  if (endIndex > lastIndex) {
                    endIndex = lastIndex;
                  }
                  let intervalsToAdd: IProfileInterval[] = [{ ...interval }];

                  // Split the last overlapped interval at the point of overlap in order to get a new interval
                  // that is the portion not overlapped; don't do this if the end time of the new interval lines up
                  // perfectly with the final interval it's overwriting
                  if (
                    interval.stopDateTime.format(DATE_TIME_FORMAT) !==
                    combinedProfileIntervals[endIndex].stopDateTime.format(
                      DATE_TIME_FORMAT,
                    )
                  ) {
                    intervalsToAdd.push({
                      startDateTime: interval.stopDateTime,
                      stopDateTime:
                        combinedProfileIntervals[endIndex].stopDateTime,
                      genRequest: combinedProfileIntervals[endIndex].genRequest,
                    });
                  }
                  combinedProfileIntervals.splice(
                    currentIndex,
                    endIndex - currentIndex + 1,
                    ...intervalsToAdd,
                  );
                  break;
                }
              }
              // New interval start time is inside current interval start and stop times
              else {
                let intervalsToAdd: IProfileInterval[] = [];

                // Don't add the split interval before the new interval if they have the exact same start time
                if (
                  interval.startDateTime.format(DATE_TIME_FORMAT) !==
                  currentIndexStartTime.format(DATE_TIME_FORMAT)
                ) {
                  intervalsToAdd.push({
                    startDateTime: currentIndexStartTime,
                    stopDateTime: interval.startDateTime,
                    genRequest: currentIndexGenRequest,
                  });
                }

                intervalsToAdd.push(interval);

                // New interval is completely within current interval
                if (interval.stopDateTime <= currentIndexStopTime) {
                  // Don't add the split interval after the new interval if they have the exact same end time
                  if (
                    interval.stopDateTime.format(DATE_TIME_FORMAT) !==
                    currentIndexStopTime.format(DATE_TIME_FORMAT)
                  ) {
                    intervalsToAdd.push({
                      startDateTime: interval.stopDateTime,
                      stopDateTime: currentIndexStopTime,
                      genRequest: currentIndexGenRequest,
                    });
                  }

                  combinedProfileIntervals.splice(
                    currentIndex,
                    1,
                    ...intervalsToAdd,
                  );
                  break;
                }
                // New interval start time is within current interval, but end time extends beyond
                else {
                  // Iterate through current intervals until we either find the one that the new interval end time
                  // exists in or we get to the end of the current interval array; this lets us know how many current
                  // intervals we need to overwrite
                  let endIndex = currentIndex;
                  for (endIndex; endIndex <= lastIndex; endIndex++) {
                    if (
                      interval.stopDateTime >=
                      combinedProfileIntervals[endIndex].stopDateTime
                    ) {
                      continue;
                    } else {
                      break;
                    }
                  }
                  if (endIndex > lastIndex) {
                    endIndex = lastIndex;
                  }

                  // Split the last overlapped interval at the point of overlap in order to get a new interval
                  // that is the portion not overlapped; don't do this if the end time of the new interval lines up
                  // perfectly with the final interval it's overwriting
                  if (
                    interval.stopDateTime.format(DATE_TIME_FORMAT) !==
                    combinedProfileIntervals[endIndex].stopDateTime.format(
                      DATE_TIME_FORMAT,
                    )
                  ) {
                    intervalsToAdd.push({
                      startDateTime: interval.stopDateTime,
                      stopDateTime:
                        combinedProfileIntervals[endIndex].stopDateTime,
                      genRequest: combinedProfileIntervals[endIndex].genRequest,
                    });
                  }

                  combinedProfileIntervals.splice(
                    currentIndex,
                    endIndex - currentIndex + 1,
                    ...intervalsToAdd,
                  );
                  break;
                }
              }
            }
          });
        }
      },
    );
    setCombinedIntervals(combinedProfileIntervals);
    profileIntervalsToProfileGridData(combinedProfileIntervals);
    setIsFinalDataTableLoading(false);
    setIsConfirmDisabled(false);
  }, [allProductProfileIntervals, toEntityId]);

  const handleClick = () => {
    // Add a blank profile on initial click
    if (currentProductProfiles.length === 0) {
      handleProductProfileAdd(0);
    }
    buildSelectors();
    setShowModal(true);
  };

  const handleClearProfiles = () => {
    setClearButtonActionState(EActionState.Actioning);
    setCurrentProductProfiles([]);
    setProductProfileSelectors([]);
    setAllProductProfileIntervals([]);
  };

  // Add a single blank selector after the clear all button is pressed or if the list of products is empty
  useEffect(() => {
    if (currentProductProfiles.length === 0) {
      setProductProfilesZeroed(true);
      setClearButtonActionState(EActionState.NoAction);
    }
  }, [
    productProfileSelectors,
    currentProductProfiles,
    productProfileOptions,
    productProfilesMap,
    handleProductProfileAdd,
    clearButtonActionState,
  ]);

  useEffect(() => {
    if (productProfilesZeroed) {
      handleProductProfileAdd(0);
      setProductProfilesZeroed(false);
    }
  }, [handleProductProfileAdd, productProfilesZeroed]);

  const handleCancel = async () => {
    setShowModal(false);
  };

  const handleApply = async () => {
    try {
      setErrorMessage(null);
      onApply(combinedIntervals);
      setShowModal(false);

      if (combinedIntervals.length > 0) {
        setIsPrimary(true);
        let profileNames: string = '';
        let profilesAppliedNum = 0;
        currentProductProfiles.forEach((profile: IProductProfile) => {
          if (!isEmptyValue(profile.selectedProductProfileName)) {
            profileNames = profileNames.concat(
              profile.selectedProductProfileName! + '\n',
            );
            profilesAppliedNum++;
          }
        });
        if (profileNames !== '') {
          setProductProfileButtonLabel(
            profilesAppliedNum.toString() +
              (profilesAppliedNum > 1
                ? ' profiles applied'
                : ' profile applied'),
          );
          setProductProfileButtonTooltip(profileNames);
        }
      } else {
        setIsPrimary(false);
        setProductProfileButtonLabel('Product Profiles');
        setProductProfileButtonTooltip('No profiles applied');
      }
    } catch (error: any) {
      captureError(error);

      setErrorMessage(error.message);
    }
  };

  return (
    <>
      <Tooltip isDisabled={isDisabled} title={productProfileButtonTooltip}>
        <ProductProfileButtonWrapper>
          <Button
            actionState={modalButtonActionState}
            isPrimary={isPrimary}
            isDisabled={isDisabled}
            label={productProfileButtonLabel}
            onClick={handleClick}
          />
        </ProductProfileButtonWrapper>
      </Tooltip>
      <Modal
        footer={
          <EditorFooter
            additionalActions={
              <Button
                actionState={clearButtonActionState}
                label={`Clear All Profiles`}
                onClick={handleClearProfiles}
              />
            }
            confirmLabel='Apply'
            encodedPermissionsId={encodeIds([encodedPermissionsId, 'footer'])}
            errorMessage={errorMessage}
            isConfirmDisabled={isConfirmDisabled}
            onCancel={handleCancel}
            onConfirm={handleApply}
          />
        }
        isVisible={showModal}
        onCancel={handleCancel}
        title={'Product Profile Selection'}
        width={PRODUCT_PROFILE_MODAL_WIDTH}
      >
        <ModalLayout>
          <ProductProfileSelectorsLayout>
            {productProfileSelectors}
          </ProductProfileSelectorsLayout>
          <StyledDivider type='vertical' />
          <CombinedIntervalsWrapper>
            <CombinedIntervalLabel>Final Profile</CombinedIntervalLabel>
            <ProductProfileDataTable
              data={finalProfileGridData}
              isUnconstrained={false}
              isCombinedIntervalTable={true}
              isLoading={isFinalDataTableLoading}
            />
          </CombinedIntervalsWrapper>
        </ModalLayout>
      </Modal>
    </>
  );
};

export default ProductProfileModal;
