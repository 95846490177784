export enum ECustomFilterAttribute {
  ApprovalRight = 'approval_rights',
  CompositeState = 'composite_state',
  CPSE = 'ui_cpse',
  Curtailed = 'curtailed',
  GCA = 'ui_gca',
  LCA = 'ui_lca',
  LossesTag = 'is_losses_tag',
  MarketSegmentContractNumber = 'market_segment_contract_number',
  MarketSegmentEnergyProduct = 'market_segment_energy_product',
  MarketSegmentMiscInfo = 'market_segment_misc_info',
  MarketSegmentPSE = 'ui_market_segment_pse',
  MO = 'ui_mo',
  NITS = 'nits',
  PhysicalSegmentContractNumber = 'physical_segment_contract_number',
  PhysicalSegmentMiscInfo = 'physical_segment_misc_info',
  POD = 'ui_pod',
  POR = 'ui_por',
  SE = 'ui_se',
  Sink = 'ui_sink',
  Source = 'ui_source',
  TagNote = 'tag_note',
  TagType = 'tag_type',
  TestTag = 'test_tag',
  TimeClassification = 'time_classification',
  Token = 'token',
  TransAllocCustomer = 'ui_trans_alloc_customer',
  TransAllocMiscInfo = 'trans_alloc_misc_info',
  TransAllocProduct = 'trans_alloc_product',
  TSP = 'ui_tsp',
  TSR = 'tsr',
  Value = 'value',
}

export enum ECustomFilterType {
  AllMatch = 'AllMatch',
  And = 'And',
  AnyMatch = 'AnyMatch',
  Equals = 'Equals',
  FirstInList = 'FirstInList',
  LastInList = 'LastInList',
  Not = 'Not',
  Or = 'Or',
  StringBeginsWith = 'StringBeginsWith',
  StringContains = 'StringContains',
  StringEndsWith = 'StringEndsWith',
}

export enum EFilterType {
  Boolean = 'boolean',
  Enumeration = 'enumeration',
  Misc = 'misc',
  String = 'string',
}

export enum EFilterMiscKey {
  Token = 'token',
  Value = 'value',
}

export enum EFilterMiscType {
  AnyValue = 'AnyValue',
  ValueContains = 'ValueContains',
  ValueEquals = 'ValueEquals',
}

export enum EFilterStringType {
  BeginsWith = 'BeginsWith',
  Contains = 'Contains',
  EndsWith = 'EndsWith',
  Equals = 'Equals',
  NotBeginsWith = 'NotBeginsWith',
  NotContains = 'NotContains',
  NotEndsWith = 'NotEndsWith',
  NotEquals = 'NotEquals',
}

export enum ERequestFilterType {
  Contains = 'contains',
  DoesNotContain = 'notcontains',
  Equals = 'equals',
  DoesNotEqual = 'notequals',
  BeginsWith = 'beginswith',
  DoesNotBeginWith = 'notbeginswith',
  EndsWith = 'endswith',
  DoesNotEndWith = 'notendswith',
  And = 'and',
  Or = 'or',
  Not = 'not',
  True = 'true',
}
