import Tooltip from '../../molecules/Tooltip/Tooltip';
import Button from '../../atoms/Button/Button';
import { useState } from 'react';
import Modal from 'components/molecules/Modal/Modal';
import { DATE_FORMAT } from '../../../constants/time';
import { START_STOP_PLACEHOLDERS } from '../DetailAdHocSplitProfile/constants';
import DateTimeRangePicker from '../../molecules/DateTimeRangePicker/DateTimeRangePicker';
import { TTimeZone } from '../../../types/DateTime';
import SeparatedRowLayout from '../../atoms/SeparatedRowLayout/SeparatedRowLayout';
import { IProfileDateRange } from '../ProfileInformationView/types';
import useProfileDateTimeRange from '../../../hooks/useProfileDateTimeRange ';

interface IDetailProfileDateRangeProps {
  onApply: (profile: IProfileDateRange) => void;
  startDate?: string | null;
  endDate?: string | null;
  timeZone: TTimeZone;
  isDisabled?: boolean;
}
const DetailProfileDateRange = ({
  onApply,
  startDate,
  endDate,
  timeZone,
  isDisabled,
}: IDetailProfileDateRangeProps): JSX.Element => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [profileDateTimeRange, setProfileDateTimeRange] =
    useProfileDateTimeRange(startDate, endDate, timeZone);
  const [isPrimary, setIsPrimary] = useState<boolean>();
  const handleClick = () => {
    setShowModal(true);
  };

  const handleCancel = async () => {
    setShowModal(false);
  };

  const handleApply = () => {
    onApply({
      startDateTime: profileDateTimeRange![0]!,
      stopDateTime: profileDateTimeRange![1]!,
    });
    setIsPrimary(true);
    setShowModal(false);
  };

  return (
    <>
      <Tooltip title={'Copy Profile'}>
        <Button
          label={'Copy Profile'}
          onClick={handleClick}
          isPrimary={isPrimary}
          isDisabled={isDisabled}
        />
      </Tooltip>
      <Modal
        onCancel={handleCancel}
        title={'Copy Profile'}
        isVisible={showModal}
        footer={
          <Button
            isDisabled={
              profileDateTimeRange === null || !profileDateTimeRange[1]
            }
            isPrimary={true}
            label={'Apply'}
            onClick={handleApply}
          ></Button>
        }
        width={460}
      >
        <SeparatedRowLayout>
          <div>Select date range to apply changes to:</div>
          <DateTimeRangePicker
            allowClear={true}
            format={DATE_FORMAT}
            onChange={setProfileDateTimeRange}
            placeholder={START_STOP_PLACEHOLDERS}
            showTime={undefined}
            timeZone={timeZone}
            value={profileDateTimeRange}
          />
        </SeparatedRowLayout>
      </Modal>
    </>
  );
};

export default DetailProfileDateRange;
