export enum EProfileFormat {
  StartStop = 'startStop',
  Daily = 'daily',
  Hourly = 'hourly',
  FifteenMinute = '15min',
  FiveMinute = '5min',
}

export enum EProfileDataGridCellType {
  DateTimeString = 'dateTimeString',
  Empty = 'empty',
  EnergyProfile = 'energyProfile',
  EnergyProfileSnapshot = 'energyProfileSnapshot',
  Number = 'number',
  String = 'string',
}

export enum EProfileTimeSplit {
  CustomInterval = 'customInterval',
  Daily = 'daily',
  Fifteen = 'fifteen',
  Five = 'five',
  FixedDuration = 'fixedDuration',
  Hourly = 'hourly',
}

export enum EProfileTimeUnit {
  Day = 'day',
  Hour = 'hour',
  Minute = 'minute',
}

export enum EDetailIdType {
  DraftId = 'draftId',
  TagPrimaryKey = 'tagPrimaryKey',
  TemplateId = 'templateId',
  ProfileChangeId = 'profileChangeId',
}

export enum EDetailLossMethodsExpandedColumn {
  None = 'none',
  LossTagIds = 'lossTagIds',
}

export enum ETableConfiguration {
  All = 'all',
  NewTagRequest = 'newTagRequest',
  POD = 'pod',
  ProfileOnly = 'profileOnly',
  ProfileTotals = 'profileTotals',
}

export enum EMarketInfoPricesDataGridCellType {
  Empty = 'empty',
  Number = 'number',
  String = 'string',
}

export enum EDayOfWeek {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}
