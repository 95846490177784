import { FileAddOutlined } from '@ant-design/icons';
import { Popover as AntDesignPopover } from 'antd';
import IconButton from 'components/atoms/IconButton/IconButton';
import ToEntityETagTemplateCreatePopover from 'components/molecules/ToEntityETagTemplateCreate/ToEntityETagTemplateCreatePopover';
import Tooltip from 'components/molecules/Tooltip/Tooltip';
import { BUTTON_ICON_DIMENSIONS } from 'constants/styles';
import { IETagExtendedIdentifier } from 'interfaces/ETag';
import { useState } from 'react';
import styled from 'styled-components';
import { TTimeZone } from 'types/DateTime';
import { TToEntityId } from 'types/ToEntity';
import { encodeIds } from 'utils/general';

const CreateIcon = styled(FileAddOutlined)`
  ${BUTTON_ICON_DIMENSIONS}
`;

interface IToEntityETagTemplateCreateProps {
  encodedPermissionsId: string;
  eTagExtendedIdentifier: IETagExtendedIdentifier | undefined;
  isDisabled?: boolean;
  timeZone: TTimeZone;
  toEntityId?: TToEntityId;
}

const ToEntityETagTemplateCreate = (
  props: IToEntityETagTemplateCreateProps,
): JSX.Element => {
  const {
    encodedPermissionsId,
    eTagExtendedIdentifier,
    isDisabled,
    timeZone,
    toEntityId,
  } = props;
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const adjustedIsDisabled: boolean =
    isDisabled === true || eTagExtendedIdentifier === undefined;

  return (
    <Tooltip isDisabled={isDisabled || isVisible} title='Save as Template'>
      <AntDesignPopover
        content={
          toEntityId ? (
            <ToEntityETagTemplateCreatePopover
              eTagExtendedIdentifier={eTagExtendedIdentifier!}
              timeZone={timeZone}
              toEntityId={toEntityId}
            />
          ) : null
        }
        destroyTooltipOnHide={true}
        onVisibleChange={setIsVisible}
        placement='bottom'
        trigger='click'
        visible={isVisible}
      >
        <IconButton
          encodedPermissionsId={encodeIds([encodedPermissionsId], toEntityId)}
          icon={<CreateIcon />}
          isDisabled={adjustedIsDisabled}
        />
      </AntDesignPopover>
    </Tooltip>
  );
};

export default ToEntityETagTemplateCreate;
