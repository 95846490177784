import EditableDataTable from 'components/molecules/EditableDataTable/EditableDataTable';
import {
  BUTTON_ICON_DIMENSION_VALUE,
  VIEW_DATA_TABLE_COLUMN_CONTRACT_INPUT_WIDTH,
} from 'constants/styles';
import { IContract } from 'interfaces/General';
import { IInputColumnConfig, IViewDataTableColumn } from 'interfaces/View';
import { useCallback, useMemo } from 'react';
import { getInitialContract } from 'utils/detail';
import { contractInputRender } from 'utils/views';

export const CONTRACTS_ADD_BUTTON_OFFSET = {
  left: `-6px`,
  top: 'calc(100% - 4px)',
};

export const CONTRACTS_REMOVE_BUTTON_OFFSET = {
  right: '0',
  top: `calc(50% - ${BUTTON_ICON_DIMENSION_VALUE / 2}px)`,
};

export const getEditContractsColumns = (
  contractsInputColumnConfig: IInputColumnConfig<IContract>,
  hasChanged?: boolean,
): IViewDataTableColumn<IContract>[] => [
  {
    dataIndex: 'contract',
    render: contractInputRender(contractsInputColumnConfig),
    shouldCellUpdate: (record: IContract, previousRecord: IContract): boolean =>
      hasChanged || record.contract !== previousRecord.contract,
    title: 'Contract',
    width: VIEW_DATA_TABLE_COLUMN_CONTRACT_INPUT_WIDTH,
  },
];

export interface IContractsEditableProps {
  contractInputColumnConfig: IInputColumnConfig<IContract>;
  data: IContract[];
  hasChanged?: boolean;
  initialEditKey: string;
  isDisabled?: boolean;
  onAdd?: (value: unknown, record: IContract, index: number) => void;
  onRemove?: (record: IContract, index: number) => void;
}

const ContractsEditable = (props: IContractsEditableProps): JSX.Element => {
  const {
    contractInputColumnConfig,
    data,
    hasChanged,
    initialEditKey,
    isDisabled,
    onAdd,
    onRemove,
  } = props;

  const editContractsColumns = useMemo(
    () => getEditContractsColumns(contractInputColumnConfig, hasChanged),
    [contractInputColumnConfig, hasChanged],
  );

  const initialiser = useCallback(
    () => getInitialContract(initialEditKey),
    [initialEditKey],
  );

  return (
    <EditableDataTable<IContract>
      addButtonOffset={CONTRACTS_ADD_BUTTON_OFFSET}
      columns={editContractsColumns}
      data={data}
      initialiser={initialiser}
      isDisabled={isDisabled}
      maximumAllowableAdds={-1}
      onAdd={onAdd}
      onRemove={onRemove}
      pagination={false}
      removeButtonOffset={CONTRACTS_REMOVE_BUTTON_OFFSET}
    />
  );
};

export default ContractsEditable;
