import { TimePicker as AntDesignTimePicker } from 'antd';
import { TimePickerProps } from 'antd/lib/time-picker';
// this is one of only a few places we allow moment
// See (utils/zonedDateTime.ts) for why.
// eslint-disable-next-line no-restricted-imports
import { Moment } from 'moment';
import { useMemo } from 'react';
import { TTimeZone } from 'types/DateTime';
import {
  momentToZonedDateTime,
  ZonedDateTime,
  zonedDateTimetoMoment,
} from 'utils/zonedDateTime';

export interface IZonedTimePickerProps
  extends Omit<TimePickerProps, 'onChange' | 'value'> {
  onChange?: (zonedDateTime: ZonedDateTime | null) => void;
  timeZone: TTimeZone;
  value: ZonedDateTime | null;
}

const ZonedTimePicker = (props: IZonedTimePickerProps): JSX.Element => {
  const { onChange, timeZone, value, ...rest } = props;

  const onChangeMoment = useMemo(
    () =>
      onChange === undefined
        ? undefined
        : (m: Moment | null): void =>
            onChange(momentToZonedDateTime(timeZone, m)),
    [timeZone, onChange],
  );

  const valueMoment = useMemo(() => zonedDateTimetoMoment(value), [value]);

  return (
    <AntDesignTimePicker
      {...rest}
      onChange={onChangeMoment}
      value={valueMoment}
    />
  );
};

export default ZonedTimePicker;
