import Input from 'components/atoms/Input/Input';
import {
  ARROW_DOWN_KEY,
  ARROW_UP_KEY,
  ENTER_KEY,
  FLOAT_REGEX,
} from 'constants/General';
import { EMarketInfoPricesDataGridCellType } from 'enums/Detail';
import { IDataGridSelectionContext } from 'interfaces/Component';
import { IMarketInfoPricesDataGridCell } from 'interfaces/Detail';
import { IIndexable } from 'interfaces/General';
import {
  ChangeEvent,
  Context,
  MutableRefObject,
  useCallback,
  useContext,
  useMemo,
  useRef,
} from 'react';
import { EditorProps } from 'react-data-grid';
import styled from 'styled-components';
import { TMarketInfoPricesDataGridRow } from 'types/Detail';
import { getMarketInfoPricesRowCellData } from 'utils/detail';
import { isControlShortcut, isEmptyValue } from 'utils/general';
import { autoFocusAndSelectInput } from 'utils/input';

const Container = styled.div`
  height: 100%;
`;

const StyledInput = styled(Input)`
  height: 100%;
  text-align: right;
`;

interface IMarketInfoPricesDataGridCellEditProps
  extends EditorProps<TMarketInfoPricesDataGridRow, IIndexable> {
  DataGridSelectionContext: Context<IDataGridSelectionContext>;
  isDisabled?: boolean;
  isUnconstrained?: boolean;
}

const MarketInfoPricesDataGridCellEdit = ({
  column,
  DataGridSelectionContext,
  isDisabled,
  isUnconstrained = false,
  onRowChange,
  row,
}: IMarketInfoPricesDataGridCellEditProps): JSX.Element => {
  const { onExitEditCallback } = useContext<IDataGridSelectionContext>(
    DataGridSelectionContext,
  );
  const { key } = column;
  const divRef = useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;

  const { cellType, cellValue } = useMemo(() => {
    const cell: IMarketInfoPricesDataGridCell | null | undefined = row[key];

    if (cell === undefined) {
      throw new Error(`Invalid key: ${key} for row: ${JSON.stringify(row)}`);
    }

    return getMarketInfoPricesRowCellData(cell, isUnconstrained);
  }, [isUnconstrained, key, row]);

  const exitEditMode = useCallback(
    (key?: string) => {
      if (divRef.current) {
        // Fake a user 'Enter' key press to immediately exit editing mode when no
        // editing is allowed
        divRef.current.dispatchEvent(
          new KeyboardEvent('keydown', {
            bubbles: true,
            cancelable: true,
            code: ENTER_KEY,
            key: ENTER_KEY,
          }),
        );

        if (onExitEditCallback !== undefined) {
          onExitEditCallback.callback(key);
        }
      }
    },
    [divRef, onExitEditCallback],
  );

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      let updatedCell: IMarketInfoPricesDataGridCell | null = null;

      if (
        !isEmptyValue(event.target.value) &&
        FLOAT_REGEX.test(event.target.value)
      ) {
        updatedCell = {
          type: EMarketInfoPricesDataGridCellType.Number,
          value: event.target.value,
        };
      }

      onRowChange({
        ...row,
        [key]: updatedCell,
      });
    },
    [key, onRowChange, row],
  );

  const handleKeyDown = (keyboardEvent: React.KeyboardEvent) => {
    if (isControlShortcut(keyboardEvent.nativeEvent, 'c')) {
      exitEditMode();
    }

    if (
      keyboardEvent.key === ARROW_DOWN_KEY ||
      keyboardEvent.key === ARROW_UP_KEY
    ) {
      exitEditMode(keyboardEvent.key);
    }
  };

  const isEditable: boolean =
    cellType === EMarketInfoPricesDataGridCellType.Empty ||
    cellType === EMarketInfoPricesDataGridCellType.Number;

  return (
    <Container onKeyDown={handleKeyDown} ref={divRef}>
      {isEditable ? (
        <StyledInput
          inputRef={autoFocusAndSelectInput}
          isDisabled={isDisabled}
          onChange={handleInputChange}
          value={cellValue as string}
        />
      ) : null}
    </Container>
  );
};

export default MarketInfoPricesDataGridCellEdit;
