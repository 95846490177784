import { GetRowIdParams, RowClickedEvent } from 'ag-grid-community';
import { AxiosResponse } from 'axios';
import ErrorMessage from 'components/atoms/ErrorMessage/ErrorMessage';
import Spinner from 'components/atoms/Spinner/Spinner';
import ClearGridFilters from 'components/molecules/ClearGridFilters/ClearGridFilters';
import AGDataTable, {
  IBottomRowData,
} from 'components/molecules/DataTable/AGDataTable';
import Tooltip from 'components/molecules/Tooltip/Tooltip';
import {
  alertConfigurationSorterFor,
  createGridSorters,
  customFilterToCustomFilterOption,
  getETagDataSetForKey,
  manageAlert,
  updateDateTimeForTimeZone,
} from 'components/organisms/ToEntityMonitor/helpers';
import Legend from 'components/organisms/ToEntityMonitor/Legend';
import ToEntityMonitorActionBar from 'components/organisms/ToEntityMonitor/ToEntityMonitorActionBar';
import {
  TAgGridSortState,
  TETagUpdateMap,
} from 'components/organisms/ToEntityMonitor/types';
import ETagWebSocket from 'components/services/ETagWebSocket/ETagWebSocket';
import { NO_OP_HANDLER } from 'constants/General';
import {
  ACTION_BAR_HEIGHT_VALUE,
  COLUMN_LAYOUT_SHARED_STYLES,
  DETAIL_HEADER,
  LAYOUT_PADDING_VALUE,
  PUSH_RIGHT_VALUE,
  TO_ENTITY_TITLE_HEIGHT_VALUE,
} from 'constants/styles';
import {
  AnimationContext,
  IAnimationContext,
} from 'contexts/Animation/Animation';
import {
  ETagFilteringContext,
  IETagFilteringContext,
} from 'contexts/ETagFiltering/ETagFiltering';
import {
  ETagSortingContext,
  IETagSortingContext,
} from 'contexts/ETagSorting/ETagSorting';
import { EMessageType, EProfileSegment } from 'enums/ETag';
import { ENoticeSeverity, ERetreiveState } from 'enums/General';
import { EDefaultDateRange } from 'enums/Summary';
import usePrevious from 'hooks/usePrevious';
import {
  IAlert,
  IAlertConfiguration,
  IAlertConfigurationsResponse,
  IAlertRule,
  IAlertRulesResponse,
  IConfiguredAlert,
} from 'interfaces/Alert';
import { IOption } from 'interfaces/Component';
import {
  IUpdateUserUiConfigurationResponse,
  IUserUiConfiguration,
} from 'interfaces/Config';
import {
  IETagCheckoutReportNotification,
  IETagData,
  IETagDataSet,
  IETagIdentifier,
  IETagNotification,
} from 'interfaces/ETag';
import { ICustomFilter } from 'interfaces/Filter';
import { IIndexable } from 'interfaces/General';
import {
  IDateRange,
  ISummaryCustomFilterResponse,
  ISummaryFilterRemoveResponse,
  ISummaryFiltersV2Response,
  ITableConfiguration,
  IToEntityMonitorConfiguration,
} from 'interfaces/Summary';
import { IToEntity, IToEntityRecord } from 'interfaces/ToEntity';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import { connect, ConnectedProps, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';
import {
  summaryRemoveToEntityETagSummaryAttribute,
  summaryRemoveToEntityETagSummaryProfiles,
  summaryRetrieveToEntityETagsSummaryAttribute,
  summaryRetrieveToEntityETagsSummaryDealLinkage,
  summaryRetrieveToEntityETagsSummaryProfiles,
  summaryUpdateToEntityETagDrafts,
  summaryUpdateToEntityETags,
} from 'reduxes/Summary/actions';
import {
  IUpdateToEntityETagsRequest,
  TETagSummaryAttributeRequest,
  TETagSummaryProfilesRequest,
  TSummaryAction,
} from 'reduxes/Summary/types';
import {
  IToEntityLossesConfig,
  IToEntityUserState,
  TToEntityUserStates,
} from 'reduxes/User/types';
import {
  createSummaryCustomFilter,
  removeSummaryFilter,
  retrieveSummaryCustomFilters,
  updateSummaryCustomFilter,
} from 'services/agent/tags/summary';
import { retrieveAlertRules } from 'services/alert/rules';
import {
  retrieveAlertConfigurations,
  updateAlertConfigurations,
} from 'services/alert/userConfig';
import { updateUserUiConfig } from 'services/configclient/config';
import styled from 'styled-components';
import { TAlertRulesMap } from 'types/Alert';
import { TToEntityDataTableSummaryDataSet } from 'types/Component';
import { TTimeZone } from 'types/DateTime';
import { TErrorMessage } from 'types/Error';
import {
  TETagDraftId,
  TETagRecordKey,
  TETagSummaryAttributeMap,
  TETagSummaryDealLinkageMap,
  TETagSummaryProfilesMap,
  TETagTagPrimaryKey,
} from 'types/ETag';
import { TFilterId } from 'types/Filter';
import { TRootState } from 'types/Redux';
import { TTableConfigurationOptions } from 'types/Summary';
import useAsyncEffect from 'use-async-effect';
import { sortByOptionLabel } from 'utils/component';
import { captureError } from 'utils/error';
import {
  getKeyForETagDataSet,
  getRecordKeyForETagNotification,
} from 'utils/eTag';
import { copyCustomFilter, getCustomFilterId } from 'utils/filter';
import { encodeIds, isEmptyValue, isSuccessStatus } from 'utils/general';
import { defaultDateRangeToDateRange } from 'utils/summary';
import { copyUserUiConfiguration } from 'utils/user';
import { ZonedDateTime } from 'utils/zonedDateTime';
import MWTotalsTextRenderer from '../../organisms/DataGrid/renderers/MWTotalsTextRenderer';
import TagCountTextRenderer from '../../organisms/DataGrid/renderers/TagCountTextRenderer';
import TotalsRowRenderer from '../../organisms/DataGrid/renderers/TotalsRowRenderer';
import SeparatedRowLayout from '../../atoms/SeparatedRowLayout/SeparatedRowLayout';
import TopBarMenu from '../TopBarMenu/TopBarMenu';
import NavigationActions from 'components/atoms/NavigationActions/NavigationActions';
import ToEntityNoticesHistory from 'components/molecules/ToEntityNoticesHistory/ToEntityNoticesHistory';
import { ETheme } from 'enums/Style';
import DownloadTagButton from '../../molecules/DownloadTagButton/DownloadTagButton';
import { IUserInfo } from '../../../interfaces/User';
import useUserInfo from '../../../hooks/useUserInfo';
import {
  getActionBarETagDataSets,
  getAGBottomRowData,
  getColumnConfig,
  getEtagDataSets,
  getETagSummaryAttributeCount,
  getGridColumnConfig,
  getSummaryDataSets,
  handleUpdateToEntityETagsFrom,
} from '../../../shared/helpers/summary.helper.shared';
import { retrieveNotices } from '../../../services/notice/notices';
import { EAlertAcknowledged } from '../../../enums/Alert';

const Layout = styled.div`
  ${COLUMN_LAYOUT_SHARED_STYLES}

  height: 100%;
  padding: ${LAYOUT_PADDING_VALUE}px;
  padding-bottom: 0;
  position: relative;

  > :not(:last-child) {
    margin-bottom: 4px;
  }
`;

const Title = styled.div`
  ${DETAIL_HEADER}

  height: ${TO_ENTITY_TITLE_HEIGHT_VALUE}px;

  > div {
    padding-top: 6px;
  }
`;

const ToEntityClearGridFilters = styled(Tooltip)`
  top: 6px;
  right: 4px;
  position: absolute;
`;

const AGDataTableWrapper = styled.div`
  position: relative;
`;

// Props

interface IToEntityMonitorProps {
  closeAllAlerts: (closedCallback: () => void) => void;
  encodedPermissionsId: string;
  isEmbeddedTitle?: boolean;
  isUnconstrained?: boolean;
  maxHeight: string;
  onAlert: (configuredAlert: IConfiguredAlert) => void;
  toEntity: IToEntity;
  toEntityUserState: IToEntityUserState | undefined;
  toEntityLossesConfig: IToEntityLossesConfig[] | undefined;
  toEntityUserStates: TToEntityUserStates;
}

// Redux connections

// Since our mapStateToProps function has a dependency on props, we are not
// using the useSelector hook due to potential edge case bugs mentioned here:
// https://react-redux.js.org/api/hooks#usage-warnings
const mapStateToProps = (state: TRootState, props: IToEntityMonitorProps) => {
  const {
    summary: { toEntities },
  } = state;
  const {
    toEntity: { to_entity },
  } = props;
  let toEntityRecord: IToEntityRecord | undefined = undefined;

  if (toEntities[to_entity] !== undefined) {
    toEntityRecord = toEntities[to_entity];
  }

  return {
    toEntityRecord,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<TRootState, unknown, TSummaryAction>,
) => ({
  retrieveToEntityETagsAttribute: (
    toEntity: IToEntity,
    start: ZonedDateTime,
    end: ZonedDateTime,
    batchSize?: number,
    filterId?: TFilterId,
  ) =>
    dispatch(
      summaryRetrieveToEntityETagsSummaryAttribute(
        toEntity,
        start,
        end,
        batchSize,
        filterId,
      ),
    ),
  retrieveToEntityETagsDealLinkage: (
    toEntity: IToEntity,
    start: ZonedDateTime,
    end: ZonedDateTime,
    timeZone: TTimeZone,
  ) =>
    dispatch(
      summaryRetrieveToEntityETagsSummaryDealLinkage(
        toEntity,
        start,
        end,
        timeZone,
      ),
    ),
  retrieveToEntityETagsProfiles: (
    toEntity: IToEntity,
    timeZone: TTimeZone,
    start: ZonedDateTime,
    end: ZonedDateTime,
    profileSegment: EProfileSegment,
    batchSize?: number,
  ) =>
    dispatch(
      summaryRetrieveToEntityETagsSummaryProfiles(
        toEntity,
        timeZone,
        start,
        end,
        profileSegment,
        batchSize,
      ),
    ),
  removeToEntityETagSummaryAttribute: (
    toEntity: IToEntity,
    tag_primary_key: TETagTagPrimaryKey,
    timeZone: TTimeZone,
    draft_id?: TETagDraftId,
  ) => {
    const eTagSummaryAttributeRequest: TETagSummaryAttributeRequest = {
      draft_id,
      requestedAt: ZonedDateTime.now(timeZone),
      tag_primary_key,
      toEntity,
    } as TETagSummaryAttributeRequest;

    dispatch(
      summaryRemoveToEntityETagSummaryAttribute(eTagSummaryAttributeRequest),
    );
  },
  removeToEntityETagSummaryProfiles: (
    toEntity: IToEntity,
    tag_primary_key: TETagTagPrimaryKey,
    timeZone: TTimeZone,
    draft_id?: TETagDraftId,
  ) => {
    const eTagSummaryProfilesRequest: TETagSummaryProfilesRequest = {
      draft_id,
      requestedAt: ZonedDateTime.now(timeZone),
      tag_primary_key,
      toEntity,
    } as TETagSummaryProfilesRequest;

    dispatch(
      summaryRemoveToEntityETagSummaryProfiles(eTagSummaryProfilesRequest),
    );
  },
  updateToEntityETagDrafts: (
    toEntity: IToEntity,
    draft_id: TETagDraftId,
    tag_primary_key: TETagTagPrimaryKey,
  ) =>
    dispatch(
      summaryUpdateToEntityETagDrafts({ draft_id, tag_primary_key, toEntity }),
    ),
  updateToEntityETags: (
    toEntity: IToEntity,
    eTagsData: IETagData[],
    timeZone: TTimeZone,
  ) => {
    const updateToEntityETagsRequest: IUpdateToEntityETagsRequest = {
      toEntity,
      eTagsData,
      timeZone,
    } as IUpdateToEntityETagsRequest;

    dispatch(summaryUpdateToEntityETags(updateToEntityETagsRequest));
  },
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type TProps = ConnectedProps<typeof connector> & IToEntityMonitorProps;

const ToEntityMonitor = ({
  closeAllAlerts,
  encodedPermissionsId,
  isEmbeddedTitle,
  isUnconstrained = false,
  maxHeight,
  onAlert,
  removeToEntityETagSummaryAttribute,
  removeToEntityETagSummaryProfiles,
  retrieveToEntityETagsAttribute,
  retrieveToEntityETagsDealLinkage,
  retrieveToEntityETagsProfiles,
  toEntity,
  toEntityRecord,
  toEntityUserState,
  toEntityUserStates,
  updateToEntityETagDrafts,
  updateToEntityETags,
  toEntityLossesConfig,
}: TProps): JSX.Element => {
  const configState = useSelector((state: TRootState) => state.config);
  const { currentTheme } = useThemeSwitcher();
  const { setShouldAnimate } = useContext<IAnimationContext>(AnimationContext);
  // HeaderCell cannot readily get access to our arraySorters nor arrayFilters
  // in a compositional manner. This is due to the way the Ant Design Table
  // component manages the customisation of its various rendering components.
  // We therefore have to use contexts to allow our ToEntityMonitor component
  // AND our HeaderCell component to share state regarding the arraySorters and
  // arrayFilters.
  const { arraySorters, clearArraySorters } =
    useContext<IETagSortingContext>(ETagSortingContext);
  const { arrayFilters, clearArrayFilters } =
    useContext<IETagFilteringContext>(ETagFilteringContext);

  const [
    isLoadingToEntityMonitorConfiguration,
    setIsLoadingToEntityMonitorConfiguration,
  ] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<TErrorMessage>(null);
  const [toEntityMonitorConfiguration, setToEntityMonitorConfiguration] =
    useState<IToEntityMonitorConfiguration | undefined>();
  const [selectedTableConfiguration, setSelectedTableConfiguration] = useState<
    ITableConfiguration | undefined
  >();
  const [defaultDateRange, setDefaultDateRange] = useState<
    EDefaultDateRange | undefined
  >();
  const [attributeMap, setAttributeMap] = useState<TETagSummaryAttributeMap>(
    {},
  );
  const [dealLinkageMap, setDealLinkageMap] =
    useState<TETagSummaryDealLinkageMap>({});
  const [profilesMap, setProfilesMap] = useState<TETagSummaryProfilesMap>({});
  const [fixedProfile, setFixedProfile] = useState<boolean>(false);
  const [selectedStartDate, setSelectedStartDate] =
    useState<ZonedDateTime | null>(null);
  const [selectedEndDate, setSelectedEndDate] = useState<ZonedDateTime | null>(
    null,
  );
  const [customFilterOptions, setCustomFilterOptions] = useState<
    IOption<ICustomFilter>[]
  >([]);
  const [selectedCustomFilter, setSelectedCustomFilter] = useState<
    ICustomFilter | undefined
  >();
  const [dealRetrieveCount, setDealRetrieveCount] = useState<number>(0);
  const [profileRetrieveCount, setProfileRetrieveCount] = useState<number>(0);
  const [renderTrigger, setRenderTrigger] = useState<boolean>(false);
  const [pausedETagUpdatesMap, setPausedETagUpdatesMap] = useState<
    TETagUpdateMap | undefined
  >();
  const [selectedRowKey, setSelectedRowKey] = useState<string | undefined>();
  const [alertRulesMap, setAlertRulesMap] = useState<TAlertRulesMap>({});
  const [allowAlerts, setAllowAlerts] = useState<boolean>(true);
  const [onAlertOverride, setOnAlertOverride] = useState<{
    override: ((configuredAlert: IConfiguredAlert) => void) | undefined;
  }>({ override: undefined });

  // set to true to tell ag grid to clear filters, ag grid will set to false
  const [clearGridFilters, setClearGridFilters] = useState<boolean>(false);
  const [rowIds, setRowIds] = useState<string[]>([]);
  const [toEntityConfigShowLosses, setToEntityConfigShowLosses] = useState<
    boolean | undefined
  >(false);
  const [selectedTagPrimaryKey, setSelectedTagPrimaryKey] = useState<
    string | undefined
  >();
  const [hasUnreadNotices, setHasUnreadNotices] = useState<boolean>(false);

  const showBottomGridRow = useRef<boolean>(true);

  const isETagEventsPaused = useRef<boolean>(false);
  const eTagPausedUpdateQueue = useRef<IETagData[]>([]);
  const eTagPausedUpdateCount = useRef<number>(0);
  const eTagPausedUpdateMap = useRef<TETagUpdateMap>({});
  const eTagDataLoadingUpdateCount = useRef<number>(0);
  const eTagDataLoadingUpdateMap = useRef<TETagUpdateMap>({});
  const eTagDataUpdateCount = useRef<number>(0);
  const eTagDataUpdateMap = useRef<TETagUpdateMap>({});
  const isRetrievingETagsRef = useRef<boolean>(false);
  const onAlertRef =
    useRef<(configuredAlert: IConfiguredAlert) => void>(onAlert);
  const handleAlertRef = useRef<(alert: IAlert) => void>(NO_OP_HANDLER);
  /** This will be implemented in a further issue. */
  const handleETagReportNotificationRef =
    useRef<(eTagNotification: IETagCheckoutReportNotification) => void>(
      NO_OP_HANDLER,
    );
  const handleETagNotificationRef = useRef<
    (eTagNotification: IETagNotification) => void
  >((eTagNotification: IETagNotification) => {
    if (
      eTagNotification.message_type === EMessageType.DraftResolved &&
      !isEmptyValue(eTagNotification.draft_id)
    ) {
      if (eTagNotification.submitted_tag_primary_key !== undefined) {
        updateToEntityETagDrafts(
          toEntity,
          eTagNotification.draft_id!,
          eTagNotification.submitted_tag_primary_key,
        );
      }
    } else {
      const recordKey: TETagRecordKey | undefined =
        getRecordKeyForETagNotification(eTagNotification);

      if (recordKey !== undefined) {
        if (isETagEventsPaused.current) {
          eTagPausedUpdateCount.current += 1;
          eTagPausedUpdateMap.current[recordKey] =
            eTagNotification as IETagIdentifier;
        } else if (isRetrievingETagsRef.current) {
          eTagDataLoadingUpdateCount.current += 1;
          eTagDataLoadingUpdateMap.current[recordKey] =
            eTagNotification as IETagIdentifier;
        } else {
          eTagDataUpdateCount.current += 1;
          eTagDataUpdateMap.current[recordKey] =
            eTagNotification as IETagIdentifier;
        }

        // We need to manually trigger a render call (by setting some arbitrary
        // state), since the updates above are occuring on refs. Refs do not
        // trigger a render (by design). We use refs here as 'faux' instance
        // variables so that the onETagUpdate function remains unchanged between
        // render calls.
        setRenderTrigger(
          (previousRenderTrigger: boolean): boolean => !previousRenderTrigger,
        );
      }
    }
  });

  const isRetrievingSummaryAttributes: boolean = !(
    toEntityRecord === undefined ||
    toEntityRecord.eTagsSummaryAttributeRetrieving ===
      ERetreiveState.NotRetrieving ||
    toEntityRecord.eTagsSummaryAttributeRetrieving ===
      ERetreiveState.RetrievingCompleted
  );

  const isRetrievingSummaryProfiles: boolean =
    !(
      toEntityRecord === undefined ||
      toEntityRecord.eTagsSummaryProfilesRetrieving ===
        ERetreiveState.NotRetrieving ||
      toEntityRecord.eTagsSummaryProfilesRetrieving ===
        ERetreiveState.RetrievingCompleted
    ) || profileRetrieveCount > 0;

  const isRetrievingETags: boolean =
    isRetrievingSummaryAttributes || isRetrievingSummaryProfiles;

  isRetrievingETagsRef.current = isRetrievingETags;

  // User

  const timeZone: TTimeZone | undefined = toEntityUserState?.selectedTimeZone;
  const previousTimeZone = usePrevious<TTimeZone | undefined>(timeZone);

  // Development
  const { search } = useLocation();
  const [hideTenantTitle, setHideTenantTitle] = useState<boolean>(true);

  // Configurations

  const previousDefaultFilter: string | undefined = usePrevious<
    string | undefined
  >(toEntityMonitorConfiguration?.defaultFilter);

  useEffect(() => {
    if (configState.retrievingConfig === ERetreiveState.RetrievingCompleted) {
      const hideTenantTitleConfig =
        configState.hideTenantTitleConfig &&
        configState.hideTenantTitleConfig.get(toEntity.to_entity);
      setHideTenantTitle(hideTenantTitleConfig as boolean);
    }
  }, [configState, toEntity.to_entity, setHideTenantTitle]);

  useAsyncEffect(async () => {
    if (
      toEntityUserState?.toEntityUiConfig !== undefined &&
      toEntityUserState?.userUiConfig !== undefined
    ) {
      try {
        setIsLoadingToEntityMonitorConfiguration(true);
        setErrorMessage(null);

        const [
          retrieveEtagsSummaryFiltersV2Response,
          retrieveAlertConfigurationsResponse,
          retrieveAlertRulesResponse,
        ] = await Promise.all([
          retrieveSummaryCustomFilters(toEntity.to_entity),
          retrieveAlertConfigurations(toEntity.to_entity),
          retrieveAlertRules(toEntity.to_entity),
        ]);

        const summaryFiltersV2Response: ISummaryFiltersV2Response =
          retrieveEtagsSummaryFiltersV2Response.data;

        if (!isSuccessStatus(retrieveEtagsSummaryFiltersV2Response.status)) {
          throw new Error(summaryFiltersV2Response.errorMessage!);
        }

        const alertConfigurationsResponse: IAlertConfigurationsResponse =
          retrieveAlertConfigurationsResponse.data;

        const alertRulesResponse: IAlertRulesResponse =
          retrieveAlertRulesResponse.data;

        if (!isSuccessStatus(retrieveAlertRulesResponse.status)) {
          throw new Error(alertRulesResponse.errorMessage!);
        }

        const newAlertRulesMap: TAlertRulesMap = {};
        alertRulesResponse.response.forEach((alertRule: IAlertRule) => {
          newAlertRulesMap[alertRule.alert_rule_id] = alertRule;
        });

        setAlertRulesMap(newAlertRulesMap);
        setCustomFilterOptions(
          summaryFiltersV2Response.response
            .map(customFilterToCustomFilterOption)
            .sort(sortByOptionLabel),
        );

        const alertConfigurations: IAlertConfiguration[] =
          alertConfigurationsResponse.response.sort(
            alertConfigurationSorterFor(newAlertRulesMap),
          );

        const toEntityMonitorConfiguration: IToEntityMonitorConfiguration = {
          ...toEntityUserState.toEntityUiConfig,
          ...toEntityUserState.userUiConfig,
          alertConfigurations,
        };

        setToEntityMonitorConfiguration(toEntityMonitorConfiguration);
        const lossesConfig = toEntityLossesConfig?.find(
          (config) => config.toEntity === toEntity.to_entity,
        );
        if (lossesConfig) {
          setToEntityConfigShowLosses(lossesConfig.showLosses);
        }
      } catch (error: any) {
        captureError(error);

        setErrorMessage(
          'An error occurred during loading. Please try again later.',
        );
      } finally {
        setIsLoadingToEntityMonitorConfiguration(false);
      }
    }
  }, [
    toEntity.to_entity,
    toEntityUserState?.toEntityUiConfig,
    toEntityUserState?.userUiConfig,
    toEntityLossesConfig,
  ]);

  const updateToEntityMonitorConfiguration = useCallback(
    async (toEntityMonitorConfiguration: IToEntityMonitorConfiguration) => {
      // We won't try/catch this operation so that errors can propagate to the
      // callers of updateToEntityMonitorConfiguration
      const { alertConfigurations } = toEntityMonitorConfiguration;
      const updatedUserUiConfiguation: IUserUiConfiguration =
        copyUserUiConfiguration(toEntityMonitorConfiguration);

      const [updateUiUserConfigResponse, updateAlertConfigurationsResponse] =
        await Promise.all([
          updateUserUiConfig(toEntity.to_entity, updatedUserUiConfiguation),
          updateAlertConfigurations(toEntity.to_entity, alertConfigurations),
        ]);

      const updateUserUiConfigurationResponse: IUpdateUserUiConfigurationResponse =
        updateUiUserConfigResponse.data;

      if (!isSuccessStatus(updateUiUserConfigResponse.status)) {
        throw new Error(updateUserUiConfigurationResponse.errorMessage!);
      }

      const alertConfigurationsResponse: IAlertConfigurationsResponse =
        updateAlertConfigurationsResponse.data;

      if (!isSuccessStatus(updateAlertConfigurationsResponse.status)) {
        throw new Error(alertConfigurationsResponse.errorMessage!);
      }

      setToEntityMonitorConfiguration(toEntityMonitorConfiguration);
    },
    [toEntity.to_entity],
  );

  const tableConfigurationOptions: TTableConfigurationOptions = useMemo(
    () =>
      toEntityMonitorConfiguration?.tableConfigurations === undefined
        ? []
        : toEntityMonitorConfiguration?.tableConfigurations,
    [toEntityMonitorConfiguration?.tableConfigurations],
  );

  const handleSetSelectedTableConfiguration = useCallback(
    (tableConfiguration: ITableConfiguration | undefined) => {
      setSelectedTableConfiguration(tableConfiguration);

      clearArraySorters();

      clearArrayFilters();
    },
    // As an optimization, we don't need to trigger a retrieve for
    // clearArraySorters and clearArrayFilters since these do not change
    // between renders.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    const foundOption: IOption<ITableConfiguration> | undefined =
      tableConfigurationOptions.find(
        (option: IOption<ITableConfiguration>): boolean =>
          option.value.id ===
          toEntityMonitorConfiguration?.defaultTableConfiguration,
      );

    handleSetSelectedTableConfiguration(foundOption?.value);
  }, [
    handleSetSelectedTableConfiguration,
    tableConfigurationOptions,
    toEntityMonitorConfiguration?.defaultTableConfiguration,
  ]);

  useEffect(() => {
    setDefaultDateRange(toEntityMonitorConfiguration?.defaultDateRange);
  }, [toEntityMonitorConfiguration?.defaultDateRange]);

  const applyDefaultDateRange = useCallback(
    (defaultDateRange: EDefaultDateRange, timeZone: TTimeZone) => {
      const dateRange: IDateRange = defaultDateRangeToDateRange(
        defaultDateRange,
        timeZone,
      );

      setSelectedStartDate(dateRange.start);
      setSelectedEndDate(dateRange.end);

      setDefaultDateRange(undefined);
    },
    [],
  );

  // ?start=2021-11-13T16:00:00+08:00&end=2021-11-17T16:00:00+08:00
  // this will override the default range to load the summary data
  useEffect(() => {
    const query: URLSearchParams = new URLSearchParams(search);
    const start: string | null = query.get('start');
    const end: string | null = query.get('end');
    if (timeZone !== undefined) {
      if (start && end && timeZone) {
        setSelectedStartDate(
          ZonedDateTime.parseIso(start, timeZone as TTimeZone),
        );
        setSelectedEndDate(ZonedDateTime.parseIso(end, timeZone as TTimeZone));
      } else if (previousTimeZone !== timeZone) {
        if (defaultDateRange === undefined) {
          setSelectedStartDate(updateDateTimeForTimeZone(timeZone));
          setSelectedEndDate(updateDateTimeForTimeZone(timeZone));
        } else {
          applyDefaultDateRange(defaultDateRange, timeZone);
        }
      } else if (defaultDateRange !== undefined) {
        applyDefaultDateRange(defaultDateRange, timeZone);
      }
    }
  }, [
    applyDefaultDateRange,
    defaultDateRange,
    previousTimeZone,
    search,
    timeZone,
  ]);

  useEffect(() => {
    if (toEntityMonitorConfiguration?.defaultFilter !== previousDefaultFilter) {
      setSelectedCustomFilter(
        customFilterOptions.find(
          (option: IOption<ICustomFilter>) =>
            option.value.filter_id ===
            toEntityMonitorConfiguration?.defaultFilter,
        )?.value,
      );
    }
  }, [
    customFilterOptions,
    previousDefaultFilter,
    toEntityMonitorConfiguration?.defaultFilter,
  ]);

  const {
    columns,
    expandedColumns,
    isDisplayingProfiles,
    isDisplayingDeaLinkageData: isDisplayingDealLinkageData,
  } = useMemo(() => {
    return getColumnConfig(
      timeZone,
      selectedStartDate,
      selectedEndDate,
      fixedProfile,
      toEntity.to_entity,
      selectedTableConfiguration,
      toEntityMonitorConfiguration?.styleCoding,
    );
  }, [
    fixedProfile,
    selectedEndDate,
    selectedStartDate,
    selectedTableConfiguration,
    timeZone,
    toEntity.to_entity,
    toEntityMonitorConfiguration?.styleCoding,
  ]);

  const {
    gridColumns,
    isDisplayingProfilesAgGrid,
    isDisplayingDealLinkageDataAgGrid,
  } = useMemo(() => {
    return getGridColumnConfig(
      timeZone,
      selectedStartDate,
      selectedEndDate,
      fixedProfile,
      toEntity.to_entity,
      selectedTableConfiguration,
      toEntityMonitorConfiguration?.styleCoding,
      false,
      toEntityConfigShowLosses,
      currentTheme,
    );
  }, [
    timeZone,
    selectedEndDate,
    selectedStartDate,
    selectedTableConfiguration,
    fixedProfile,
    toEntity.to_entity,
    toEntityMonitorConfiguration?.styleCoding,
    currentTheme,
    toEntityConfigShowLosses,
  ]);

  // Data

  const retrieveETagsAttribute = useCallback(
    (
      toEntity: IToEntity,
      start: ZonedDateTime | null,
      end: ZonedDateTime | null,
      filterId?: TFilterId,
    ) => {
      if (start !== null && end !== null) {
        retrieveToEntityETagsAttribute(
          toEntity,
          start,
          end,
          toEntityMonitorConfiguration?.summaryAttributesBatchLoadSize,
          filterId,
        );
      }
    },
    // As an optimisation, we don't need to trigger a retrieve for
    // retrieveToEntityETagsAttribute since it does not change between renders.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [toEntityMonitorConfiguration?.summaryAttributesBatchLoadSize],
  );

  const handleProfileRetrieveCount = useCallback(() => {
    if (
      !toEntityMonitorConfiguration ||
      !selectedStartDate ||
      !selectedEndDate
    ) {
      return;
    }
    const shouldLoadProfile: boolean =
      isDisplayingProfilesAgGrid &&
      toEntityMonitorConfiguration &&
      selectedStartDate.diff(selectedEndDate, 'hours') <=
        toEntityMonitorConfiguration.profilesMaxHourRange;

    if (shouldLoadProfile) {
      setProfileRetrieveCount(
        (previousProfileRetrieveCount: number): number =>
          previousProfileRetrieveCount + 1,
      );
    }
  }, [
    isDisplayingProfilesAgGrid,
    selectedEndDate,
    selectedStartDate,
    toEntityMonitorConfiguration,
  ]);

  const handleDealsRetrieveCount = useCallback(() => {
    if (
      !toEntityMonitorConfiguration ||
      !selectedStartDate ||
      !selectedEndDate
    ) {
      return;
    }
    const shouldLoadDeals: boolean =
      isDisplayingDealLinkageDataAgGrid &&
      toEntityMonitorConfiguration &&
      selectedStartDate.diff(selectedEndDate, 'hours') <=
        toEntityMonitorConfiguration.profilesMaxHourRange;

    if (shouldLoadDeals) {
      setDealRetrieveCount(
        (previousDealRetrieveCount: number): number =>
          previousDealRetrieveCount + 1,
      );
    }
  }, [
    isDisplayingDealLinkageDataAgGrid,
    selectedEndDate,
    selectedStartDate,
    toEntityMonitorConfiguration,
  ]);

  const handleRefreshAttributes = useCallback(() => {
    retrieveETagsAttribute(
      toEntity,
      selectedStartDate,
      selectedEndDate,
      getCustomFilterId(selectedCustomFilter),
    );
  }, [
    retrieveETagsAttribute,
    selectedCustomFilter,
    selectedEndDate,
    selectedStartDate,
    toEntity,
  ]);

  useEffect(() => {
    handleRefreshAttributes();
    // We want to trigger a reload when we switch from AG Grid to Ant Design
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleRefreshAttributes]);

  useEffect(() => {
    handleDealsRetrieveCount();
  }, [handleDealsRetrieveCount]);

  useEffect(() => {
    handleProfileRetrieveCount();
  }, [handleProfileRetrieveCount]);

  const handleRefresh = useCallback(() => {
    handleRefreshAttributes();
    handleProfileRetrieveCount();
    handleDealsRetrieveCount();
  }, [
    handleDealsRetrieveCount,
    handleProfileRetrieveCount,
    handleRefreshAttributes,
  ]);

  useEffect(
    () => {
      if (
        isDisplayingProfiles &&
        profileRetrieveCount > 0 &&
        (toEntityRecord?.eTagsSummaryAttributeRetrieving ===
          ERetreiveState.NotRetrieving ||
          toEntityRecord?.eTagsSummaryAttributeRetrieving ===
            ERetreiveState.RetrievingCompleted) &&
        timeZone !== undefined &&
        selectedStartDate !== null &&
        selectedEndDate !== null &&
        toEntityMonitorConfiguration?.profileSegment !== undefined
      ) {
        retrieveToEntityETagsProfiles(
          toEntity,
          timeZone,
          selectedStartDate,
          selectedEndDate,
          toEntityMonitorConfiguration.profileSegment,
          toEntityMonitorConfiguration.summaryProfilesBatchLoadSize,
        );

        setProfileRetrieveCount(
          (previousProfileRetrieveCount: number): number =>
            previousProfileRetrieveCount - 1,
        );
      }
    },
    // As an optimization, we don't need to trigger a retrieve for
    // retrieveToEntityETagsProfiles since it does not change between renders.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      isDisplayingProfiles,
      profileRetrieveCount,
      selectedEndDate,
      selectedStartDate,
      timeZone,
      toEntity,
      toEntityMonitorConfiguration?.profileSegment,
      toEntityMonitorConfiguration?.summaryProfilesBatchLoadSize,
      toEntityRecord?.eTagsSummaryAttributeRetrieving,
    ],
  );

  useEffect(
    () => {
      if (
        (isDisplayingDealLinkageData || isDisplayingDealLinkageDataAgGrid) &&
        dealRetrieveCount > 0 &&
        (toEntityRecord?.eTagsSummaryAttributeRetrieving ===
          ERetreiveState.NotRetrieving ||
          toEntityRecord?.eTagsSummaryAttributeRetrieving ===
            ERetreiveState.RetrievingCompleted) &&
        timeZone !== undefined &&
        selectedStartDate !== null &&
        selectedEndDate !== null
      ) {
        retrieveToEntityETagsDealLinkage(
          toEntity,
          selectedStartDate,
          selectedEndDate,
          timeZone,
        );

        setDealRetrieveCount(
          (previousDealRetrieveCount: number): number =>
            previousDealRetrieveCount - 1,
        );
      }
    },
    // As an optimization, we don't need to trigger a retrieve for
    // retrieveETagsDealLinkage since it does not change between renders.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      dealRetrieveCount,
      isDisplayingDealLinkageData,
      isDisplayingDealLinkageDataAgGrid,
      selectedEndDate,
      selectedStartDate,
      timeZone,
      toEntity,
      toEntityRecord?.eTagsSummaryAttributeRetrieving,
    ],
  );

  useEffect(() => {
    if (
      toEntityRecord !== undefined &&
      toEntityRecord.eTagsSummaryAttributeMap !== attributeMap
    ) {
      setAttributeMap(toEntityRecord.eTagsSummaryAttributeMap);
    }
  }, [attributeMap, toEntityRecord]);

  useEffect(
    () => {
      if (
        toEntityRecord !== undefined &&
        Object.keys(attributeMap).length > 0 &&
        toEntityRecord.eTagsSummaryProfilesMap !== profilesMap
      ) {
        setProfilesMap(toEntityRecord.eTagsSummaryProfilesMap);

        if (
          !isDisplayingProfiles ||
          (toEntityRecord.eTagsSummaryProfilesRetrieving ===
            ERetreiveState.RetrievingCompleted &&
            toEntityRecord.eTagsSummaryProfilesLoadCount === 0)
        ) {
          setShouldAnimate(true);
        }
      }
    },
    // As an optimization, we don't need to trigger an update for
    // setShouldAnimate since it does not change between renders.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [attributeMap, isDisplayingProfiles, profilesMap, toEntityRecord],
  );

  useEffect(() => {
    if (
      toEntityRecord !== undefined &&
      Object.keys(attributeMap).length > 0 &&
      toEntityRecord.eTagsSummaryDealLinkageMap !== dealLinkageMap
    ) {
      setDealLinkageMap(toEntityRecord.eTagsSummaryDealLinkageMap);
    }
  }, [attributeMap, dealLinkageMap, toEntityRecord]);

  const eTagDataSets: IETagDataSet[] = useMemo(() => {
    return getEtagDataSets(
      expandedColumns,
      attributeMap,
      profilesMap,
      isDisplayingProfiles,
      dealLinkageMap,
      isDisplayingDealLinkageData,
      isDisplayingDealLinkageDataAgGrid,
      arrayFilters,
      arraySorters,
    );
  }, [
    arrayFilters,
    arraySorters,
    attributeMap,
    dealLinkageMap,
    expandedColumns,
    isDisplayingDealLinkageData,
    isDisplayingDealLinkageDataAgGrid,
    isDisplayingProfiles,
    profilesMap,
  ]);

  // ETag Message Queue

  const updateToEntityETagsFrom = useCallback(
    async (
      eTagUpdateMap: TETagUpdateMap,
      toEntity: IToEntity,
      start: ZonedDateTime,
      end: ZonedDateTime,
      timeZone: TTimeZone,
      profileSegment: EProfileSegment,
      filterId?: TFilterId,
    ) => {
      return handleUpdateToEntityETagsFrom(
        eTagUpdateMap,
        toEntity,
        start,
        end,
        timeZone,
        profileSegment,
        isETagEventsPaused,
        eTagPausedUpdateQueue,
        eTagPausedUpdateCount,
        updateToEntityETags,
        filterId,
      );
    },
    // As an optimization, we don't need to trigger updates for
    // updateToEntityETags since it does not change between renders.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(
    () => {
      if (
        selectedStartDate !== null &&
        selectedEndDate !== null &&
        timeZone !== undefined &&
        toEntityMonitorConfiguration?.profileSegment !== undefined
      ) {
        if (pausedETagUpdatesMap !== undefined) {
          updateToEntityETagsFrom(
            pausedETagUpdatesMap,
            toEntity,
            selectedStartDate,
            selectedEndDate,
            timeZone,
            toEntityMonitorConfiguration.profileSegment,
            getCustomFilterId(selectedCustomFilter),
          );

          setPausedETagUpdatesMap(undefined);
        }

        if (!isRetrievingETags && eTagDataLoadingUpdateCount.current > 0) {
          const currentETagDataLoadingUpdateMap =
            eTagDataLoadingUpdateMap.current;
          eTagDataLoadingUpdateMap.current = {};
          eTagDataLoadingUpdateCount.current = 0;

          updateToEntityETagsFrom(
            currentETagDataLoadingUpdateMap,
            toEntity,
            selectedStartDate,
            selectedEndDate,
            timeZone,
            toEntityMonitorConfiguration.profileSegment,
            getCustomFilterId(selectedCustomFilter),
          );
        }

        if (eTagDataUpdateCount.current > 0) {
          const currentETagDataUpdateMap = eTagDataUpdateMap.current;
          eTagDataUpdateMap.current = {};
          eTagDataUpdateCount.current = 0;

          updateToEntityETagsFrom(
            currentETagDataUpdateMap,
            toEntity,
            selectedStartDate,
            selectedEndDate,
            timeZone,
            toEntityMonitorConfiguration.profileSegment,
            getCustomFilterId(selectedCustomFilter),
          );
        }
      }
    },
    // As an optimization, we don't need to trigger updates for
    // updateToEntityEtagsFrom since it does not change between renders.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      isRetrievingETags,
      pausedETagUpdatesMap,
      renderTrigger,
      selectedCustomFilter,
      selectedEndDate,
      selectedStartDate,
      timeZone,
      toEntity,
      toEntityMonitorConfiguration?.profileSegment,
    ],
  );

  const handlePlayPause = useCallback(() => {
    if (timeZone !== undefined) {
      isETagEventsPaused.current = !isETagEventsPaused.current;

      if (isETagEventsPaused.current) {
        setRenderTrigger(
          (previousRenderTrigger: boolean): boolean => !previousRenderTrigger,
        );
      } else {
        if (eTagPausedUpdateQueue.current.length > 0) {
          updateToEntityETags(
            toEntity,
            eTagPausedUpdateQueue.current,
            timeZone,
          );
          eTagPausedUpdateQueue.current = [];
        }

        const currentETagPausedUpdateMap = eTagPausedUpdateMap.current;
        eTagPausedUpdateMap.current = {};
        eTagPausedUpdateCount.current = 0;
        setPausedETagUpdatesMap(currentETagPausedUpdateMap);
      }
    }
  }, [timeZone, toEntity, updateToEntityETags]);

  // Filters

  const createCustomFilterOption = useCallback(
    async (
      customFilterOption: IOption<ICustomFilter>,
    ): Promise<IOption<ICustomFilter>> => {
      // We won't try/catch this operation so that errors can propagate to the
      // callers of createCustomFilterOption
      const response: AxiosResponse<ISummaryCustomFilterResponse> =
        await createSummaryCustomFilter(
          toEntity.to_entity,
          customFilterOption.value,
        );

      const summaryCustomFilterResponse: ISummaryCustomFilterResponse =
        response.data;

      if (!isSuccessStatus(response.status)) {
        throw new Error(summaryCustomFilterResponse.errorMessage!);
      }

      if (!summaryCustomFilterResponse.response.filter_id) {
        throw new Error(
          `Incorrect put response for custom filter ${customFilterOption.value.filter_name}. Response jsonObject is ${summaryCustomFilterResponse.response}`,
        );
      }

      const newCustomFilterOption: IOption<ICustomFilter> = {
        label: customFilterOption.label,
        value: copyCustomFilter(customFilterOption.value),
      };
      newCustomFilterOption.value.filter_id =
        summaryCustomFilterResponse.response.filter_id;

      setCustomFilterOptions(
        customFilterOptions
          .concat(newCustomFilterOption)
          .sort(sortByOptionLabel),
      );

      return newCustomFilterOption;
    },
    [customFilterOptions, toEntity.to_entity],
  );

  const updateCustomFilterOption = useCallback(
    async (customFilterOption: IOption<ICustomFilter>) => {
      // We won't try/catch this operation so that errors can propagate to the
      // callers of updateCustomFilterOption
      const response: AxiosResponse<ISummaryCustomFilterResponse> =
        await updateSummaryCustomFilter(
          toEntity.to_entity,
          customFilterOption.value,
        );

      const summaryCustomFilterResponse: ISummaryCustomFilterResponse =
        response.data;

      if (!isSuccessStatus(response.status)) {
        throw new Error(summaryCustomFilterResponse.errorMessage!);
      }

      if (!summaryCustomFilterResponse.response.filter_id) {
        throw new Error(
          `Incorrect put response for custom filter ${customFilterOption.value.filter_name}. Response jsonObject is ${summaryCustomFilterResponse.response}`,
        );
      }

      const copyOfCustomFilter: ICustomFilter = copyCustomFilter(
        customFilterOption.value,
      );
      copyOfCustomFilter.filter_id =
        summaryCustomFilterResponse.response.filter_id;

      const updatedCustomFilterOptions: IOption<ICustomFilter>[] =
        customFilterOptions
          .map((option: IOption<ICustomFilter>) =>
            option.value.filter_id === customFilterOption.value.filter_id
              ? { label: customFilterOption.label, value: copyOfCustomFilter }
              : option,
          )
          .sort(sortByOptionLabel);

      setSelectedCustomFilter(
        (
          previousSelectedCustomFilter: ICustomFilter | undefined,
        ): ICustomFilter | undefined =>
          updatedCustomFilterOptions.find(
            (customFilterOption: IOption<ICustomFilter>): boolean =>
              customFilterOption.value.filter_id ===
              previousSelectedCustomFilter?.filter_id,
          )?.value,
      );

      setCustomFilterOptions(updatedCustomFilterOptions);
    },
    [customFilterOptions, toEntity.to_entity],
  );

  const removeCustomFilterOption = useCallback(
    async (customFilterOption: IOption<ICustomFilter>) => {
      if (customFilterOption.value.filter_id === null) {
        throw new Error(
          `Invalid filter_id for customFilterOption: ${JSON.stringify(
            customFilterOption,
          )}`,
        );
      }

      // We won't try/catch this operation so that errors can propagate to the
      // callers of removeCustomFilterOption
      const response: AxiosResponse<ISummaryFilterRemoveResponse> =
        await removeSummaryFilter(
          toEntity.to_entity,
          customFilterOption.value.filter_id,
        );
      const summaryFilterRemoveResponse: ISummaryFilterRemoveResponse =
        response.data;

      if (!isSuccessStatus(response.status)) {
        throw new Error(summaryFilterRemoveResponse.errorMessage!);
      }

      if (
        summaryFilterRemoveResponse.response.delete_count !== 1 ||
        summaryFilterRemoveResponse.response.filter_id !==
          customFilterOption.value.filter_id
      ) {
        throw new Error(
          `Incorrect delete response for custom filter ${customFilterOption.value.filter_id}. Response filter_id is ${summaryFilterRemoveResponse.response.filter_id} with delete_count ${summaryFilterRemoveResponse.response.delete_count}`,
        );
      }

      setCustomFilterOptions(
        customFilterOptions.filter(
          (option: IOption<ICustomFilter>) =>
            option.value.filter_id !== customFilterOption.value.filter_id,
        ),
      );
    },
    [customFilterOptions, toEntity.to_entity],
  );

  const handleClearAllFilters = useCallback(
    () => {
      clearArrayFilters();

      setSelectedCustomFilter(undefined);
      setClearGridFilters(true);
    },
    // As an optimization, we don't need to trigger a retrieve for
    // clearArrayFilters since it does not change between renders.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleClearGridFilters = useCallback(() => {
    setClearGridFilters(true);
  }, []);

  // Alerts

  useEffect(() => {
    if (onAlertOverride.override === undefined) {
      onAlertRef.current = onAlert;
    } else {
      onAlertRef.current = onAlertOverride.override;
    }
  }, [onAlert, onAlertOverride]);

  useEffect(() => {
    handleAlertRef.current = (alert: IAlert) => {
      manageAlert(
        alert,
        allowAlerts,
        onAlertRef,
        currentTheme!,
        alertRulesMap[alert.alert_rule_id],
        toEntityMonitorConfiguration?.alertConfigurations?.find(
          (alertConfiguration: IAlertConfiguration): boolean =>
            alertConfiguration.alert_rule_id === alert.alert_rule_id,
        ),
      );
    };
  }, [
    alertRulesMap,
    allowAlerts,
    currentTheme,
    toEntityMonitorConfiguration?.alertConfigurations,
  ]);

  const handleOverrideOnAlert = useCallback(
    (override: ((configuredAlert: IConfiguredAlert) => void) | undefined) => {
      setOnAlertOverride({ override });
    },
    [],
  );

  // Misc

  const frameworkComponents: IIndexable = useMemo(
    (): IIndexable => ({
      mwTotalsTextRenderer: MWTotalsTextRenderer,
      tagCountTextRenderer: TagCountTextRenderer,
      totalsRowRenderer: TotalsRowRenderer,
    }),
    [],
  );

  const gridOptions = {
    components: frameworkComponents,
    // Exclude totals dummy row from sorting
    postSortRows: (nodes: any[]) => {
      if (nodes.length > 0) {
        for (let x in nodes) {
          nodes[x].data.id === '_totals' &&
            nodes.push(nodes.splice(Number(x), 1)[0]);
        }
      }
    },
    getRowHeight: (params: any) => {
      return params.node.rowPinned === 'bottom' ? 33 : 20;
    },
  };

  const LegendContent: JSX.Element | null = useMemo(
    () =>
      toEntityMonitorConfiguration === undefined ? null : (
        <Legend
          columns={columns}
          styleCoding={toEntityMonitorConfiguration.styleCoding}
        />
      ),
    [columns, toEntityMonitorConfiguration],
  );

  // The key will always be unique with UTC. AG Grid requires unique IDs,
  // but we cannot use the timeZone variable here because it starts as undefined
  // which causes rows to have the same ID
  const rowKey = (eTagDataSet: IETagDataSet): string => {
    return getKeyForETagDataSet(eTagDataSet, 'UTC');
  };

  const actionBarETagDataSets: IETagDataSet[] = useMemo(() => {
    return getActionBarETagDataSets(eTagDataSets, rowIds);
  }, [eTagDataSets, rowIds]);

  const handleRowIdsChange = (ids: string[]) => {
    const idsEqual: boolean =
      ids.length === rowIds.length &&
      ids.every((id: string, index: number) => {
        return id === rowIds[index];
      });
    if (!idsEqual) {
      setRowIds(ids);
    }
  };

  const summaryDataSets: TToEntityDataTableSummaryDataSet[] = useMemo(() => {
    return getSummaryDataSets(
      actionBarETagDataSets,
      expandedColumns,
      isRetrievingSummaryAttributes,
      isLoadingToEntityMonitorConfiguration,
    );
  }, [
    actionBarETagDataSets,
    expandedColumns,
    isLoadingToEntityMonitorConfiguration,
    isRetrievingSummaryAttributes,
  ]);

  const agBottomRowData: IBottomRowData[] = useMemo(() => {
    return getAGBottomRowData(summaryDataSets);
  }, [summaryDataSets]);

  const handleRowClick = (event: RowClickedEvent) => {
    const rowSelected = (event.node as any).selected;
    if (rowSelected) {
      const key: string = rowKey(event.data);
      if (!event.data.draft_id) {
        setSelectedTagPrimaryKey(event.data.tag_primary_key);
      } else {
        setSelectedTagPrimaryKey('');
      }
      setSelectedRowKey((previousSelectedRowKey: string | undefined) => {
        return previousSelectedRowKey === key ? undefined : key;
      });
    } else {
      setSelectedTagPrimaryKey('');
      setSelectedRowKey(undefined);
    }
    return undefined;
  };

  const getRowId = (params: GetRowIdParams) => rowKey(params.data);

  // we want to know how many records we should be showing,
  // so we look through the map and count items that aren't
  // undefined. if eTagDataSets is less than this, we're still loading
  const eTagSummaryAttributeCount: number = useMemo(() => {
    return getETagSummaryAttributeCount(toEntityRecord);
  }, [toEntityRecord]);

  // !isRetrievingSummaryAttributes becomes false before eTagDataSets updates with eTagsSummaryAttributeMap.
  // To preserve filters in AG grid between datasets, we can't set data to [] between datasets,
  // so if datasets has no data after retrieving attributes is done, we check if the map is also empty
  // if the map were also empty, we are done loading, and there is actually no data
  // if the map isn't empty, there is data, and eTagDataSets just hasn't updated yet
  const isLoadingAttributes: boolean = toEntityRecord
    ? isRetrievingSummaryAttributes ||
      (eTagDataSets.length === 0 && eTagSummaryAttributeCount !== 0)
    : true;

  /**
   * The Ant Design Grid uses the onRow.handler animations
   * to allow an animation to occur before removing a row.
   * If we're not showing that grid, we will trigger the removal of those datasets here
   */
  useEffect(() => {
    if (timeZone) {
      eTagDataSets.forEach((record: IETagDataSet) => {
        const {
          tag_primary_key,
          draft_id,
          summaryAttributeRemoved,
          summaryProfileRemoved,
        } = record;

        if (summaryAttributeRemoved) {
          removeToEntityETagSummaryAttribute(
            toEntity,
            tag_primary_key,
            timeZone,
            draft_id,
          );
          // If the removed data is selected, unselect the row
          if (selectedRowKey === getKeyForETagDataSet(record, timeZone)) {
            setSelectedRowKey(undefined);
          }
        } else if (summaryProfileRemoved) {
          removeToEntityETagSummaryProfiles(
            toEntity,
            tag_primary_key,
            timeZone,
            draft_id,
          );
          if (selectedRowKey === getKeyForETagDataSet(record, timeZone)) {
            setSelectedRowKey(undefined);
          }
        }
      });
    }
  }, [
    eTagDataSets,
    removeToEntityETagSummaryAttribute,
    removeToEntityETagSummaryProfiles,
    selectedRowKey,
    timeZone,
    toEntity,
  ]);

  const gridSorters: TAgGridSortState[] = useMemo(() => {
    if (selectedTableConfiguration?.defaultSort) {
      return createGridSorters(selectedTableConfiguration.defaultSort);
    }
    return [];
  }, [selectedTableConfiguration?.defaultSort]);

  const userInfo: IUserInfo = useUserInfo();
  const { toEntities } = userInfo;

  useAsyncEffect(async () => {
    if (selectedStartDate && selectedEndDate && toEntities) {
      let noticesCount = 0;
      for (const entity of toEntities) {
        const response: AxiosResponse<any> = await retrieveNotices(
          entity.to_entity,
          selectedStartDate,
          selectedEndDate,
          [ENoticeSeverity.High, ENoticeSeverity.Medium, ENoticeSeverity.Low],
          EAlertAcknowledged.Unread,
          false,
        );
        noticesCount += response.data.response.length;
      }
      if (noticesCount > 0) {
        setHasUnreadNotices(true);
      }
    }
  }, [selectedEndDate, selectedStartDate, toEntities]);

  return (
    <Layout>
      <SeparatedRowLayout>
        {timeZone ? (
          <TopBarMenu
            encodedPermissionsId={encodedPermissionsId}
            timeZone={timeZone}
            toEntity={toEntity}
          />
        ) : null}
        {isEmbeddedTitle || hideTenantTitle ? null : (
          <Title>
            <div>{toEntity.entity_code}</div>
          </Title>
        )}
      </SeparatedRowLayout>
      <NavigationActions right={PUSH_RIGHT_VALUE}>
        <SeparatedRowLayout>
          <DownloadTagButton
            currentTheme={currentTheme || ETheme.Light}
            primaryKey={selectedTagPrimaryKey}
            toEntityId={toEntity.to_entity}
          />
          <ToEntityNoticesHistory
            permissionId='toEntityMonitor:displayNotices'
            timeZone={timeZone || ZonedDateTime.defaultTimeZone()}
            toEntities={[toEntity]}
            currentTheme={currentTheme || ETheme.Light}
            hasUnreadNotices={hasUnreadNotices}
          />
        </SeparatedRowLayout>
      </NavigationActions>
      {isLoadingToEntityMonitorConfiguration ? (
        <Spinner />
      ) : (
        <>
          <ErrorMessage maxWidth='100%' topMargin={0}>
            {errorMessage}
          </ErrorMessage>
          {toEntityMonitorConfiguration === undefined ? null : (
            <>
              <ToEntityMonitorActionBar
                alertRulesMap={alertRulesMap}
                allowAlerts={allowAlerts}
                closeAllAlerts={closeAllAlerts}
                createCustomFilterOption={createCustomFilterOption}
                customFilterOptions={customFilterOptions}
                encodedPermissionsId={encodeIds([
                  encodedPermissionsId,
                  'actionBar',
                ])}
                eTagDataSets={actionBarETagDataSets}
                expandedColumns={expandedColumns}
                fixedProfile={fixedProfile}
                isDisplayingProfiles={isDisplayingProfiles}
                isEmbeddedTitle={isEmbeddedTitle}
                isPaused={isETagEventsPaused.current}
                isPlayPauseDisabled={isRetrievingETags}
                isUnconstrained={isUnconstrained}
                legendContent={LegendContent}
                onClearAllFilters={handleClearAllFilters}
                onPlayPause={handlePlayPause}
                onRefresh={handleRefresh}
                overrideOnAlert={handleOverrideOnAlert}
                pausedCount={eTagPausedUpdateCount.current}
                removeCustomFilterOption={removeCustomFilterOption}
                selectedEndDate={selectedEndDate}
                selectedETagDataSet={getETagDataSetForKey(
                  selectedRowKey,
                  eTagDataSets,
                  timeZone,
                )}
                selectedCustomFilter={selectedCustomFilter}
                selectedStartDate={selectedStartDate}
                selectedTableConfiguration={selectedTableConfiguration}
                setAllowAlerts={setAllowAlerts}
                setFixedProfile={setFixedProfile}
                setSelectedEndDate={setSelectedEndDate}
                setSelectedCustomFilter={setSelectedCustomFilter}
                setSelectedStartDate={setSelectedStartDate}
                setSelectedTableConfiguration={
                  handleSetSelectedTableConfiguration
                }
                summaryDataSets={summaryDataSets}
                tableConfigurationOptions={tableConfigurationOptions}
                timeZone={timeZone}
                toEntity={toEntity}
                toEntityMonitorConfiguration={toEntityMonitorConfiguration}
                updateCustomFilterOption={updateCustomFilterOption}
                updateToEntityMonitorConfiguration={
                  updateToEntityMonitorConfiguration
                }
                toEntities={toEntities}
              />
              <AGDataTableWrapper>
                <AGDataTable
                  clearFilters={clearGridFilters}
                  columns={gridColumns}
                  data={eTagDataSets}
                  defaultSort={gridSorters}
                  gridOptions={gridOptions}
                  isLoading={isLoadingAttributes}
                  maxHeight={`calc(${maxHeight} - ${
                    TO_ENTITY_TITLE_HEIGHT_VALUE +
                    ACTION_BAR_HEIGHT_VALUE +
                    LAYOUT_PADDING_VALUE +
                    LAYOUT_PADDING_VALUE
                  }px)`}
                  onRowClick={handleRowClick}
                  getRowId={getRowId}
                  renderTrigger={renderTrigger}
                  setClearFilters={setClearGridFilters}
                  setRowIds={handleRowIdsChange}
                  pinnedBottomData={agBottomRowData}
                  showBottomRow={showBottomGridRow.current}
                  sideBarDef={{
                    defaultToolPanel: '',
                    toolPanels: ['filters'],
                  }}
                  timeZone={timeZone}
                />
                <ToEntityClearGridFilters
                  title='Clear All Column Filters'
                  placement='left'
                >
                  <ClearGridFilters
                    handleClearGridFilters={handleClearGridFilters}
                  />
                </ToEntityClearGridFilters>
              </AGDataTableWrapper>
              <ETagWebSocket
                handleAlertRef={handleAlertRef}
                handleETagNotificationRef={handleETagNotificationRef}
                handleETagCheckoutReportNotificationRef={
                  handleETagReportNotificationRef
                }
                toEntityId={toEntity.to_entity}
              />
            </>
          )}
        </>
      )}
    </Layout>
  );
};

export default connector(ToEntityMonitor);
