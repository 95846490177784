import { CheckSquareOutlined, CloseSquareOutlined } from '@ant-design/icons';
import { Popover as AntDesignPopover } from 'antd';
import { AxiosResponse } from 'axios';
import ErrorMessage from 'components/atoms/ErrorMessage/ErrorMessage';
import IconButton from 'components/atoms/IconButton/IconButton';
import Tooltip from 'components/molecules/Tooltip/Tooltip';
import { BUTTON_ICON_DIMENSIONS } from 'constants/styles';
import useDisplayTimedMessage from 'hooks/useDisplayTimedMessage';
import { IETagTemplate, IETagTemplateResponse } from 'interfaces/ETag';
import { useCallback, useState } from 'react';
import { updateETagTemplate } from 'services/agent/templates';
import styled from 'styled-components';
import { TToEntityId } from 'types/ToEntity';
import { encodeIds, isSuccessStatus } from 'utils/general';

const MESSAGE_DISPLAY_TIME_IN_MILLISECONDS = 7689;

const HideIcon = styled(CloseSquareOutlined)`
  ${BUTTON_ICON_DIMENSIONS}
`;

const UnhideIcon = styled(CheckSquareOutlined)`
  ${BUTTON_ICON_DIMENSIONS}
`;

interface IProps {
  encodedPermissionsId: string;
  isDisabled?: boolean;
  onSuccess?: (eTagTemplate: IETagTemplate) => void;
  template: IETagTemplate | undefined;
  toEntityId: TToEntityId;
}

const ToEntityETagTemplateActivate = (props: IProps): JSX.Element => {
  const {
    cancelDisplayTimedMessage,
    displayTimedMessage,
    timedMessage,
    showTimedMessage,
  } = useDisplayTimedMessage();
  const { encodedPermissionsId, isDisabled, onSuccess, template, toEntityId } =
    props;
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  const handleActivate = useCallback(async () => {
    try {
      setIsUpdating(true);

      cancelDisplayTimedMessage();

      if (template === undefined) {
        throw new Error('Missing template needed for updating');
      }

      const response: AxiosResponse<IETagTemplateResponse> =
        await updateETagTemplate(toEntityId, {
          ...template,
          active: !template.active,
        });
      const eTagTemplateResponse: IETagTemplateResponse = response.data;

      if (!isSuccessStatus(response.status)) {
        throw new Error(
          eTagTemplateResponse.errorMessage === null
            ? 'Unknown error'
            : eTagTemplateResponse.errorMessage,
        );
      }

      if (onSuccess) {
        onSuccess(eTagTemplateResponse.response);
      }
    } catch (error: any) {
      displayTimedMessage(
        <ErrorMessage>{error.message}</ErrorMessage>,
        MESSAGE_DISPLAY_TIME_IN_MILLISECONDS,
      );
    } finally {
      setIsUpdating(false);
    }
  }, [
    cancelDisplayTimedMessage,
    displayTimedMessage,
    onSuccess,
    template,
    toEntityId,
  ]);

  return (
    <Tooltip
      title={`${
        template?.active ? 'Inactivate Template' : 'Activate Template'
      }`}
    >
      <AntDesignPopover
        content={timedMessage}
        placement='topLeft'
        visible={showTimedMessage}
      >
        <IconButton
          encodedPermissionsId={encodeIds([encodedPermissionsId], toEntityId)}
          icon={template?.active ? <HideIcon /> : <UnhideIcon />}
          isDisabled={isDisabled || isUpdating}
          onClick={handleActivate}
        />
      </AntDesignPopover>
    </Tooltip>
  );
};

export default ToEntityETagTemplateActivate;
