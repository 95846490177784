import { SettingOutlined } from '@ant-design/icons';
import { TransferItem } from 'antd/lib/transfer';
import SeparatedRowLayout from 'components/atoms/SeparatedRowLayout/SeparatedRowLayout';
import Separator from 'components/atoms/Separator/Separator';
import ConfigurationSelector from 'components/molecules/ConfigurationSelector/ConfigurationSelector';
import Select, { ISelectProps } from 'components/molecules/Select/Select';
import ToEntityAlertConfigurator from 'components/molecules/ToEntityAlertConfigurator/ToEntityAlertConfigurator';
import ToEntityColumnConfiguration from 'components/molecules/ToEntityColumnConfiguration/ToEntityColumnConfiguration';
import { IToEntityConfiguratorProps } from 'components/molecules/ToEntityConfiguration/ToEntityConfiguration';
import ToEntityConfigurationEdit from 'components/molecules/ToEntityConfigurationEdit/ToEntityConfigurationEdit';
import ToEntityTransfer, {
  IToEntityTransferProps,
} from 'components/molecules/ToEntityTransfer/ToEntityTransfer';
import { DEFAULT_DATE_RANGE_OPTIONS } from 'components/organisms/ToEntityMonitorConfigurator/constants';
import {
  containsAllAlertRules,
  copyEditableTableConfigurationOption,
  copyTableConfiguration,
  defaultDateRangeToString,
  editableAlertConfigurationToAlertConfiguration,
  generateEditableTableConfigurationOption,
  generateNewTableConfiguration,
  getUpdatedEditableAlertConfigurations,
  hasUnsavedAlertConfiguration,
  tableConfigurationToUid,
  updateColumnSort,
} from 'components/organisms/ToEntityMonitorConfigurator/helpers';
import {
  COLUMN_LAYOUT_SHARED_STYLES,
  INPUT_HEIGHT_VALUE,
  TABLE_CONFIGURATION_SELECT_WIDTH_VALUE,
} from 'constants/styles';
import { COLUMN_DATA_MAP } from 'constants/Summary';
import { EActionState, ESeverity } from 'enums/General';
import { EDefaultDateRange } from 'enums/Summary';
import usePrevious from 'hooks/usePrevious';
import {
  IAlertConfiguration,
  IEditableAlertConfiguration,
} from 'interfaces/Alert';
import {
  IActionResponse,
  IEditableOption,
  IModalHandlers,
  IOption,
} from 'interfaces/Component';
import { IETagColumnData } from 'interfaces/ETag';
import { ICustomFilter } from 'interfaces/Filter';
import { IIndexable } from 'interfaces/General';
import {
  IColumnConfiguration,
  IColumnSort,
  IEditableColumnData,
  ITableConfiguration,
  IToEntityMonitorConfiguration,
} from 'interfaces/Summary';
import { IToEntity } from 'interfaces/ToEntity';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import styled from 'styled-components';
import { TAlertRulesMap } from 'types/Alert';
import {
  TEditableTableConfigurationOptions,
  TTableConfigurationId,
} from 'types/Summary';
import { captureError } from 'utils/error';
import { customFilterToUid, getCustomFilterId } from 'utils/filter';
import { encodeIds } from 'utils/general';
import { useDialog } from '../../../providers/DialogProvider';

const TRANSFER_HEIGHT_VALUE = 300;
const TRANSFER_LEFT_WIDTH_VALUE = 240;
const TRANSFER_RIGHT_WIDTH_VALUE = 738;

export const ToEntityMonitorConfiguratorIcon = styled(SettingOutlined)`
  height: ${INPUT_HEIGHT_VALUE / 2}px;
  width: ${INPUT_HEIGHT_VALUE / 2}px;
`;

const Layout = styled.div`
  ${COLUMN_LAYOUT_SHARED_STYLES}

  > :not(:last-child) {
    margin-bottom: 12px;
  }
`;

const StyledSeparator = styled(Separator)`
  margin-left: -16px;
  width: calc(100% + 32px);
`;

// Specialize the ToEntityTransfer component
const EditableColumnDataTransfer = (
  props: IToEntityTransferProps<IEditableColumnData>,
) => ToEntityTransfer<IEditableColumnData>(props);

// Specialize the Select components
const DefaultDateRangeSelect = styled(
  (props: ISelectProps<EDefaultDateRange>) => Select<EDefaultDateRange>(props),
)`
  width: 139px;
`;

const DefaultCustomFilterSelect = styled((props: ISelectProps<ICustomFilter>) =>
  Select<ICustomFilter>(props),
)`
  width: 175px;
`;

export interface IToEntityMonitorConfigurationProps {
  alertRulesMap: TAlertRulesMap;
  currentTableConfiguration: ITableConfiguration | undefined;
  customFilterOptions: IOption<ICustomFilter>[];
  encodedPermissionsId: string;
  toEntity?: IToEntity;
  toEntityMonitorConfiguration: IToEntityMonitorConfiguration;
  updateToEntityMonitorConfiguration: (
    toEntityMonitorConfiguration: IToEntityMonitorConfiguration,
  ) => void;
}

const ToEntityMonitorConfigurator = (
  props: IToEntityMonitorConfigurationProps & IToEntityConfiguratorProps,
): JSX.Element => {
  const { currentTheme } = useThemeSwitcher();
  const {
    alertRulesMap,
    currentTableConfiguration,
    customFilterOptions,
    encodedPermissionsId,
    isDisabled,
    setHandlers,
    toEntityMonitorConfiguration,
    updateToEntityMonitorConfiguration,
  } = props;
  const [selectedTableConfiguration, setSelectedTableConfiguration] = useState<
    ITableConfiguration | undefined
  >();
  const [
    internalTableConfigurationOptions,
    setInternalTableConfigurationOptions,
  ] = useState<TEditableTableConfigurationOptions>([]);
  const [editedTableConfigurationOption, setEditedTableConfigurationOption] =
    useState<IEditableOption<ITableConfiguration> | undefined>();
  const [availableColumns, setAvailableColumns] = useState<
    IEditableColumnData[]
  >([]);
  const [targetKeys, setTargetKeys] = useState<string[]>([]);
  const previousSelectedTableConfiguration: ITableConfiguration | undefined =
    usePrevious<ITableConfiguration | undefined>(selectedTableConfiguration);
  const [defaultTableConfiguration, setDefaultTableConfiguration] = useState<
    ITableConfiguration | undefined
  >();
  const [defaultDateRange, setDefaultDateRange] = useState<
    EDefaultDateRange | undefined
  >();
  const [defaultCustomFilter, setDefaultCustomFilter] = useState<
    ICustomFilter | undefined
  >();
  const [editedAlertConfigurations, setEditedAlertConfigurations] = useState<
    IEditableAlertConfiguration[]
  >([]);
  const previousAlertConfigurations: IAlertConfiguration[] | undefined =
    usePrevious(toEntityMonitorConfiguration.alertConfigurations);

  useEffect(() => {
    setSelectedTableConfiguration(currentTableConfiguration);
  }, [currentTableConfiguration]);

  const allAvailableColumnIndexes: string[] = useMemo(() => {
    const allAvailableColumnConfig: IOption<ITableConfiguration> | undefined =
      (toEntityMonitorConfiguration.tableConfigurations &&
        toEntityMonitorConfiguration.tableConfigurations.find(
          (config: IOption<ITableConfiguration>) =>
            config.label === 'All Available Columns',
        )) ||
      undefined;

    // Load all the columns if the table configuration is a user created one
    if (editedTableConfigurationOption) {
      if (editedTableConfigurationOption.value.userCreated) {
        return Object.keys(COLUMN_DATA_MAP);
      }
    }
    if (allAvailableColumnConfig) {
      return allAvailableColumnConfig.value.columns.map(
        (column: IColumnConfiguration) => column.dataIndex,
      );
    }
    return Object.keys(COLUMN_DATA_MAP);
  }, [editedTableConfigurationOption, toEntityMonitorConfiguration]);

  useEffect(() => {
    const tableConfigurationOptions: TEditableTableConfigurationOptions =
      toEntityMonitorConfiguration.tableConfigurations === undefined
        ? []
        : toEntityMonitorConfiguration.tableConfigurations.map(
            (tableConfigurationOption: IOption<ITableConfiguration>) =>
              generateEditableTableConfigurationOption(
                tableConfigurationOption,
              ),
          );

    setInternalTableConfigurationOptions(tableConfigurationOptions);

    setDefaultTableConfiguration(
      tableConfigurationOptions.find(
        (option: IOption<ITableConfiguration>) =>
          option.value.id ===
          toEntityMonitorConfiguration.defaultTableConfiguration,
      )?.value,
    );
  }, [
    toEntityMonitorConfiguration.defaultTableConfiguration,
    toEntityMonitorConfiguration.tableConfigurations,
  ]);

  useEffect(() => {
    setDefaultDateRange(toEntityMonitorConfiguration.defaultDateRange);
  }, [toEntityMonitorConfiguration.defaultDateRange]);

  useEffect(() => {
    if (toEntityMonitorConfiguration.defaultFilter === undefined) {
      setDefaultCustomFilter(undefined);
    } else {
      setDefaultCustomFilter(
        customFilterOptions.find(
          (option: IOption<ICustomFilter>) =>
            option.value.filter_id ===
            toEntityMonitorConfiguration.defaultFilter,
        )?.value,
      );
    }
  }, [customFilterOptions, toEntityMonitorConfiguration.defaultFilter]);

  useEffect(() => {
    if (
      previousAlertConfigurations !==
      toEntityMonitorConfiguration.alertConfigurations
    ) {
      if (toEntityMonitorConfiguration.alertConfigurations === undefined) {
        setEditedAlertConfigurations(
          getUpdatedEditableAlertConfigurations([], alertRulesMap),
        );
      } else {
        const editableAlertConfigurations: IEditableAlertConfiguration[] =
          toEntityMonitorConfiguration.alertConfigurations.map(
            (
              alertConfiguration: IAlertConfiguration,
            ): IEditableAlertConfiguration => ({
              ...alertConfiguration,
              isUnsaved: false,
            }),
          );

        if (containsAllAlertRules(editableAlertConfigurations, alertRulesMap)) {
          setEditedAlertConfigurations(editableAlertConfigurations);
        } else {
          setEditedAlertConfigurations(
            getUpdatedEditableAlertConfigurations(
              editableAlertConfigurations,
              alertRulesMap,
            ),
          );
        }
      }
    } else if (
      !containsAllAlertRules(editedAlertConfigurations, alertRulesMap)
    ) {
      setEditedAlertConfigurations(
        getUpdatedEditableAlertConfigurations(
          editedAlertConfigurations,
          alertRulesMap,
        ),
      );
    }
  }, [
    alertRulesMap,
    editedAlertConfigurations,
    previousAlertConfigurations,
    toEntityMonitorConfiguration.alertConfigurations,
  ]);

  useEffect(() => {
    if (previousSelectedTableConfiguration !== selectedTableConfiguration) {
      let copyOfTableConfigurationOption:
        | IEditableOption<ITableConfiguration>
        | undefined = undefined;

      if (selectedTableConfiguration !== undefined) {
        const tableConfigurationOption:
          | IEditableOption<ITableConfiguration>
          | undefined = internalTableConfigurationOptions.find(
          (value: IEditableOption<ITableConfiguration>): boolean =>
            value.value.id === selectedTableConfiguration.id,
        );

        if (tableConfigurationOption !== undefined) {
          copyOfTableConfigurationOption = copyEditableTableConfigurationOption(
            tableConfigurationOption,
          );
        }
      }

      setEditedTableConfigurationOption(copyOfTableConfigurationOption);
    }
  }, [
    internalTableConfigurationOptions,
    previousSelectedTableConfiguration,
    selectedTableConfiguration,
  ]);

  useEffect(() => {
    if (editedTableConfigurationOption === undefined) {
      setTargetKeys([]);
    } else {
      setAvailableColumns(
        allAvailableColumnIndexes.map((key: string) => {
          const columnData: IETagColumnData = COLUMN_DATA_MAP[key]!;
          const foundColumnConfiguration: IColumnConfiguration | undefined =
            editedTableConfigurationOption.value.columns.find(
              (columnConfiguration: IColumnConfiguration) =>
                columnConfiguration.dataIndex === key,
            );

          return foundColumnConfiguration === undefined
            ? { ...columnData, key }
            : { ...columnData, key, ...foundColumnConfiguration };
        }),
      );

      setTargetKeys(
        editedTableConfigurationOption.value.columns.map(
          (columnConfiguration: IColumnConfiguration) =>
            columnConfiguration.dataIndex,
        ),
      );

      setInternalTableConfigurationOptions(
        (
          previousInternalTableConfigurationOptions: IEditableOption<ITableConfiguration>[],
        ) =>
          previousInternalTableConfigurationOptions.map(
            (option: IEditableOption<ITableConfiguration>) =>
              editedTableConfigurationOption.value.id === option.value.id
                ? editedTableConfigurationOption
                : option,
          ),
      );
    }
  }, [allAvailableColumnIndexes, editedTableConfigurationOption]);

  const showDialog = useDialog();

  useEffect(
    () => {
      const modalHandlers: IModalHandlers = {};

      if (editedTableConfigurationOption !== undefined) {
        modalHandlers.cancelHandler = async () => {
          let returnVal = false;
          const actionResponse: IActionResponse = {
            actionState: EActionState.NoAction,
            errorMessage: null,
          };

          try {
            const unsavedChanges =
              defaultCustomFilter?.filter_id !==
                toEntityMonitorConfiguration.defaultFilter ||
              defaultDateRange !==
                toEntityMonitorConfiguration.defaultDateRange ||
              defaultTableConfiguration?.id !==
                toEntityMonitorConfiguration.defaultTableConfiguration ||
              editedTableConfigurationOption.isUnsaved ||
              alertConfigurationIsUnsaved;

            if (unsavedChanges) {
              const confirmed = await showDialog({
                title: 'Unsaved changes',
                severity: ESeverity.Warning,
                message: 'Discard them?',
              });
              if (confirmed) {
                returnVal = false;
              } else {
                returnVal = true;
              }
            }
          } catch (error: any) {
            captureError(error);

            actionResponse.actionState = EActionState.Failed;
            actionResponse.errorMessage = error;
          } finally {
            return returnVal;
          }
        };
        if (editedTableConfigurationOption.value.userCreated) {
          modalHandlers.deleteHandler = async () => {
            const updatedToEntityMonitorConfiguration: IToEntityMonitorConfiguration =
              {
                ...toEntityMonitorConfiguration,
                tableConfigurations:
                  toEntityMonitorConfiguration.tableConfigurations.filter(
                    (option: IOption<ITableConfiguration>) =>
                      option.value.id !==
                      editedTableConfigurationOption.value.id,
                  ),
              };

            if (
              editedTableConfigurationOption.value.id ===
              defaultTableConfiguration?.id
            ) {
              const nextTableConfigurationOption:
                | IOption<ITableConfiguration>
                | undefined = internalTableConfigurationOptions.find(
                (option: IOption<ITableConfiguration>) =>
                  option.value.id !== editedTableConfigurationOption.value.id,
              );
              updatedToEntityMonitorConfiguration.defaultTableConfiguration =
                nextTableConfigurationOption === undefined
                  ? -1
                  : nextTableConfigurationOption.value.id;
            }

            if (
              editedTableConfigurationOption.value.id ===
              selectedTableConfiguration?.id
            ) {
              setSelectedTableConfiguration(
                internalTableConfigurationOptions.find(
                  (option: IOption<ITableConfiguration>) =>
                    option.value.id !== editedTableConfigurationOption.value.id,
                )?.value,
              );
            }

            const actionResponse: IActionResponse = {
              actionState: EActionState.NoAction,
              errorMessage: null,
            };

            try {
              await updateToEntityMonitorConfiguration(
                updatedToEntityMonitorConfiguration,
              );

              actionResponse.actionState = EActionState.Succeeded;
            } catch (error: any) {
              captureError(error);

              actionResponse.actionState = EActionState.Failed;
              actionResponse.errorMessage = error;
            } finally {
              return actionResponse;
            }
          };
        }

        const alertConfigurationIsUnsaved: boolean =
          hasUnsavedAlertConfiguration(
            editedAlertConfigurations,
            toEntityMonitorConfiguration.alertConfigurations,
          );

        if (
          defaultCustomFilter?.filter_id !==
            toEntityMonitorConfiguration.defaultFilter ||
          defaultDateRange !== toEntityMonitorConfiguration.defaultDateRange ||
          defaultTableConfiguration?.id !==
            toEntityMonitorConfiguration.defaultTableConfiguration ||
          editedTableConfigurationOption.isUnsaved ||
          alertConfigurationIsUnsaved
        ) {
          modalHandlers.saveHandler = async () => {
            const updatedToEntityMonitorConfiguration: IToEntityMonitorConfiguration =
              {
                ...toEntityMonitorConfiguration,
              };

            if (defaultCustomFilter === undefined) {
              delete updatedToEntityMonitorConfiguration.defaultFilter;
            } else {
              updatedToEntityMonitorConfiguration.defaultFilter =
                getCustomFilterId(defaultCustomFilter);
            }

            if (defaultDateRange !== undefined) {
              updatedToEntityMonitorConfiguration.defaultDateRange =
                defaultDateRange;
            }

            if (defaultTableConfiguration === undefined) {
              delete updatedToEntityMonitorConfiguration.defaultTableConfiguration;
            } else {
              updatedToEntityMonitorConfiguration.defaultTableConfiguration =
                defaultTableConfiguration.id;
            }

            if (editedTableConfigurationOption.isUnsaved) {
              let existingOptionIndex: number = -1;
              if (toEntityMonitorConfiguration.tableConfigurations) {
                existingOptionIndex =
                  toEntityMonitorConfiguration.tableConfigurations.findIndex(
                    (option: IOption<ITableConfiguration>) =>
                      option.value.id ===
                      editedTableConfigurationOption.value.id,
                  );
              }

              if (updatedToEntityMonitorConfiguration.tableConfigurations) {
                updatedToEntityMonitorConfiguration.tableConfigurations = [
                  ...toEntityMonitorConfiguration.tableConfigurations,
                ];
              } else {
                updatedToEntityMonitorConfiguration.tableConfigurations = [];
              }

              const updatedToEntityTableConfigurationOption: IOption<ITableConfiguration> =
                {
                  label: editedTableConfigurationOption.label,
                  value: editedTableConfigurationOption.value,
                };

              if (existingOptionIndex === -1) {
                updatedToEntityMonitorConfiguration.tableConfigurations.push(
                  updatedToEntityTableConfigurationOption,
                );
              } else {
                updatedToEntityMonitorConfiguration.tableConfigurations[
                  existingOptionIndex
                ] = updatedToEntityTableConfigurationOption;
              }
            }

            if (alertConfigurationIsUnsaved) {
              updatedToEntityMonitorConfiguration.alertConfigurations =
                editedAlertConfigurations.map(
                  (
                    editedAlertConfiguration: IEditableAlertConfiguration,
                  ): IAlertConfiguration =>
                    editableAlertConfigurationToAlertConfiguration(
                      editedAlertConfiguration,
                    ),
                );
            }

            const actionResponse: IActionResponse = {
              actionState: EActionState.NoAction,
              errorMessage: null,
            };

            try {
              await updateToEntityMonitorConfiguration(
                updatedToEntityMonitorConfiguration,
              );

              if (editedTableConfigurationOption.isUnsaved) {
                setEditedTableConfigurationOption({
                  ...editedTableConfigurationOption,
                  isUnsaved: false,
                });
              }

              if (alertConfigurationIsUnsaved) {
                setEditedAlertConfigurations(
                  editedAlertConfigurations.map(
                    (
                      editedAlertConfiguration: IEditableAlertConfiguration,
                    ): IEditableAlertConfiguration => ({
                      ...editedAlertConfiguration,
                      isUnsaved: false,
                    }),
                  ),
                );
              }

              actionResponse.actionState = EActionState.Succeeded;
            } catch (error: any) {
              captureError(error);

              actionResponse.actionState = EActionState.Failed;
              actionResponse.errorMessage = error;
            } finally {
              return actionResponse;
            }
          };
        }
      }

      setHandlers(modalHandlers);
    },
    // As an optimisation, we don't need to trigger these updates for
    // setHandlers and updateToEntityMonitorConfiguration since these do not
    // change between renders.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      defaultDateRange,
      defaultCustomFilter,
      defaultTableConfiguration,
      editedAlertConfigurations,
      editedTableConfigurationOption,
      internalTableConfigurationOptions,
      selectedTableConfiguration,
      toEntityMonitorConfiguration,
      toEntityMonitorConfiguration.defaultDateRange,
      toEntityMonitorConfiguration.defaultFilter,
    ],
  );

  const handleAddTableConfigurationOption = useCallback(
    (tableConfigurationOption: IEditableOption<ITableConfiguration>) => {
      const updatedInternalTableConfigurationOptions: IEditableOption<ITableConfiguration>[] =
        [];
      let newId: TTableConfigurationId = 0;

      internalTableConfigurationOptions.forEach(
        (option: IEditableOption<ITableConfiguration>) => {
          updatedInternalTableConfigurationOptions.push(option);

          if (option.value.id >= newId) {
            newId = option.value.id + 1;
          }
        },
      );

      tableConfigurationOption.value.id = newId;
      updatedInternalTableConfigurationOptions.push(tableConfigurationOption);

      setInternalTableConfigurationOptions(
        updatedInternalTableConfigurationOptions,
      );
      setSelectedTableConfiguration(tableConfigurationOption.value);

      if (updatedInternalTableConfigurationOptions.length === 1) {
        setDefaultTableConfiguration(tableConfigurationOption.value);
      }
    },
    [internalTableConfigurationOptions],
  );

  const handleColumnReorder = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      if (editedTableConfigurationOption !== undefined) {
        const updatedEditedTableConfigurationOption: IEditableOption<ITableConfiguration> =
          copyEditableTableConfigurationOption(editedTableConfigurationOption);

        const dragRow: IColumnConfiguration =
          updatedEditedTableConfigurationOption.value.columns[dragIndex];
        updatedEditedTableConfigurationOption.value.columns.splice(
          dragIndex,
          1,
        );
        updatedEditedTableConfigurationOption.value.columns.splice(
          hoverIndex,
          0,
          dragRow,
        );

        updatedEditedTableConfigurationOption.isUnsaved = true;

        setEditedTableConfigurationOption(
          updatedEditedTableConfigurationOption,
        );
      }
    },
    [editedTableConfigurationOption],
  );

  const handleColumnConfigurationEdit = useCallback(
    (editedColumnConfiguration: IColumnConfiguration & IIndexable) => {
      if (editedTableConfigurationOption !== undefined) {
        const updatedEditedTableConfigurationOption: IEditableOption<ITableConfiguration> =
          copyEditableTableConfigurationOption(editedTableConfigurationOption);

        if (editedColumnConfiguration !== undefined) {
          // We assert using ! that we will always find a column configuration
          const foundColumnConfiguration: IColumnConfiguration & IIndexable =
            updatedEditedTableConfigurationOption.value.columns.find(
              (columnConfiguration: IColumnConfiguration) =>
                columnConfiguration.dataIndex ===
                editedColumnConfiguration.dataIndex,
            )!;

          Object.keys(editedColumnConfiguration).forEach((key: string) => {
            if (editedColumnConfiguration[key] === undefined) {
              delete foundColumnConfiguration[key];
            } else {
              foundColumnConfiguration[key] = editedColumnConfiguration[key];
            }
          });
        }

        updatedEditedTableConfigurationOption.isUnsaved = true;

        setEditedTableConfigurationOption(
          updatedEditedTableConfigurationOption,
        );
      }
    },
    [editedTableConfigurationOption],
  );

  const handleColumnSortEdit = useCallback(
    (dataIndex: string, editedColumnSort?: IColumnSort) => {
      if (editedTableConfigurationOption !== undefined) {
        const updatedEditedTableConfigurationOption: IEditableOption<ITableConfiguration> =
          copyEditableTableConfigurationOption(editedTableConfigurationOption);

        // Mutates updatedEditedTableConfigurationOption
        updateColumnSort(
          updatedEditedTableConfigurationOption,
          dataIndex,
          editedColumnSort,
        );

        updatedEditedTableConfigurationOption.isUnsaved = true;

        setEditedTableConfigurationOption(
          updatedEditedTableConfigurationOption,
        );
      }
    },
    [editedTableConfigurationOption],
  );

  const handleColumnTransfer = useCallback(
    (direction: string, moveKeys: string[]) => {
      if (editedTableConfigurationOption !== undefined) {
        if (direction === 'left') {
          const updatedEditedTableConfigurationOption: IEditableOption<ITableConfiguration> =
            copyEditableTableConfigurationOption(
              editedTableConfigurationOption,
            );

          // Ensure we correctly remove any sort settings when transfering since
          // sort priorities are dependent upon each other.
          moveKeys.forEach((moveKey: string) =>
            // Mutates updatedEditedTableConfigurationOption
            updateColumnSort(
              updatedEditedTableConfigurationOption,
              moveKey,
              undefined,
            ),
          );

          updatedEditedTableConfigurationOption.value.columns =
            updatedEditedTableConfigurationOption.value.columns.filter(
              (column: IColumnConfiguration) =>
                !moveKeys.includes(column.dataIndex),
            );

          updatedEditedTableConfigurationOption.isUnsaved = true;

          setEditedTableConfigurationOption(
            updatedEditedTableConfigurationOption,
          );
        } else if (direction === 'right') {
          const updatedEditedTableConfigurationOption: IEditableOption<ITableConfiguration> =
            copyEditableTableConfigurationOption(
              editedTableConfigurationOption,
            );
          moveKeys.forEach((moveKey: string) => {
            updatedEditedTableConfigurationOption.value.columns.push({
              dataIndex: moveKey,
            });
          });

          updatedEditedTableConfigurationOption.isUnsaved = true;

          setEditedTableConfigurationOption(
            updatedEditedTableConfigurationOption,
          );
        }
      }
    },
    [editedTableConfigurationOption],
  );

  const handleDefaultDateRangeSelection = useCallback(
    (defaultDateRange: EDefaultDateRange | undefined) => {
      // We are not allowing clear on the Select component, so value can safely
      // be asserted as never being undefined
      setDefaultDateRange(defaultDateRange!);
    },
    [],
  );

  const handleCustomFilterSelection = useCallback(
    (customFilter: ICustomFilter | undefined) => {
      setDefaultCustomFilter(customFilter);
    },
    [],
  );

  const handleChangeTableConfigurationOptionLabel = useCallback(
    (label: string) => {
      if (editedTableConfigurationOption !== undefined) {
        setEditedTableConfigurationOption({
          ...editedTableConfigurationOption,
          isUnsaved: true,
          label,
        });
      }
    },
    [editedTableConfigurationOption],
  );

  const getTableConfigurationOptionLabel = useCallback(
    (option: IOption<ITableConfiguration>): string =>
      option.value.userCreated === true
        ? option.label
        : `${option.label} (Non-Editable)`,
    [],
  );

  // if value or userCreated flag does not exist, default to false
  const userCreatedTableConfig: boolean =
    editedTableConfigurationOption?.value?.userCreated ?? false;
  const disableEdits: boolean = editedTableConfigurationOption === undefined;

  return (
    <Layout>
      <SeparatedRowLayout>
        <ConfigurationSelector<ITableConfiguration>
          isDisabled={isDisabled || disableEdits}
          label='Default Table Configuration:'
          onChange={setDefaultTableConfiguration}
          options={internalTableConfigurationOptions}
          value={defaultTableConfiguration}
          valueToUid={tableConfigurationToUid}
          width={TABLE_CONFIGURATION_SELECT_WIDTH_VALUE}
        />
        <SeparatedRowLayout>
          <div>Default Date Range:</div>
          <DefaultDateRangeSelect
            isDisabled={isDisabled}
            onChange={handleDefaultDateRangeSelection}
            options={DEFAULT_DATE_RANGE_OPTIONS}
            showSearch={true}
            value={defaultDateRange}
            valueToUid={defaultDateRangeToString}
          />
        </SeparatedRowLayout>
        <SeparatedRowLayout>
          <div>Default Custom Filter:</div>
          <DefaultCustomFilterSelect
            allowClear={true}
            isDisabled={isDisabled}
            onChange={handleCustomFilterSelection}
            options={customFilterOptions}
            placeholder='Custom Filter'
            showSearch={true}
            value={defaultCustomFilter}
            valueToUid={customFilterToUid}
          />
        </SeparatedRowLayout>
      </SeparatedRowLayout>
      <ToEntityAlertConfigurator
        alertRulesMap={alertRulesMap}
        editableAlertConfigurations={editedAlertConfigurations}
        setEditableAlertConfigurations={setEditedAlertConfigurations}
      />
      <StyledSeparator currentTheme={currentTheme!} />
      <ToEntityConfigurationEdit<ITableConfiguration>
        configurationToUid={(value: ITableConfiguration): string =>
          value.id.toString()
        }
        copyConfiguration={(
          tableConfiguration: ITableConfiguration,
        ): ITableConfiguration => ({
          ...copyTableConfiguration(tableConfiguration),
          // user created new configuration from existing
          userCreated: true,
        })}
        encodedPermissionsId={encodeIds([
          encodedPermissionsId,
          'toEntityConfigurationEdit',
        ])}
        getOptionLabel={getTableConfigurationOptionLabel}
        isDisabled={isDisabled}
        label='Selected Table Configuration:'
        newConfiguration={(): ITableConfiguration =>
          generateNewTableConfiguration()
        }
        onAddConfigurationOption={handleAddTableConfigurationOption}
        onConfigurationLabelChange={handleChangeTableConfigurationOptionLabel}
        onSelect={setSelectedTableConfiguration}
        options={internalTableConfigurationOptions}
        selectedConfigurationOption={editedTableConfigurationOption}
        selectPlaceholder='Select Table Configuration'
        selectWidth={TABLE_CONFIGURATION_SELECT_WIDTH_VALUE}
      />
      <EditableColumnDataTransfer
        data={availableColumns}
        height={TRANSFER_HEIGHT_VALUE}
        isDisabled={isDisabled || disableEdits || !userCreatedTableConfig}
        leftListRender={(transferItem: TransferItem) => (
          <>{(transferItem as IEditableColumnData).displayName}</>
        )}
        leftWidth={TRANSFER_LEFT_WIDTH_VALUE}
        rightListRender={(item: IEditableColumnData) => (
          <ToEntityColumnConfiguration
            defaultSort={editedTableConfigurationOption?.value.defaultSort}
            dimmedLabel={item.disabled}
            editableColumnData={item}
            isDisabled={isDisabled || disableEdits || !userCreatedTableConfig}
            onColumnConfigurationChange={handleColumnConfigurationEdit}
            onColumnSortChange={handleColumnSortEdit}
          />
        )}
        rightWidth={TRANSFER_RIGHT_WIDTH_VALUE}
        onReorder={handleColumnReorder}
        onTransfer={handleColumnTransfer}
        targetKeys={targetKeys}
        titles={[
          'Available Columns',
          'Displayed Columns (reorder by dragging and dropping)',
        ]}
      />
    </Layout>
  );
};

export default ToEntityMonitorConfigurator;
