import { CloseOutlined } from '@ant-design/icons';
import IconButton from 'components/atoms/IconButton/IconButton';
import ScrollableContent from 'components/molecules/ScrollableContent/ScrollableContent';
import {
  BORDER_RADIUS_BASE,
  FLOAT_BOX_SHADOW,
  LEGEND_FLOAT_OVER_Z_INDEX,
  STANDARD_SPACING,
} from 'constants/styles';
import usePrevious from 'hooks/usePrevious';
import { IThemedProps } from 'interfaces/Component';
import {
  MutableRefObject,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import styled from 'styled-components';
import { isEmptyValue } from 'utils/general';
import { floatBackground } from 'utils/styles';

interface ILayoutProps extends IThemedProps {
  isVisible: boolean;
  maximumHeight?: string;
}

const Layout = styled.div<ILayoutProps>`
  ${(props) => floatBackground(props)}
  ${(props) =>
    isEmptyValue(props.maximumHeight)
      ? ''
      : `max-height: ${props.maximumHeight};`};
  border-radius: ${BORDER_RADIUS_BASE};
  box-shadow: ${FLOAT_BOX_SHADOW};
  display: flex;
  flex-direction: column;
  padding: ${STANDARD_SPACING};
  position: absolute;
  visibility: ${(props) => (props.isVisible ? 'visible' : 'hidden')};
  z-index: ${LEGEND_FLOAT_OVER_Z_INDEX};
`;

const DragPanel = styled.div`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

const Header = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export enum EFloatOverPlacement {
  Left = 'left',
  Right = 'right',
}

export interface IFloatOverPosition {
  placement: EFloatOverPlacement;
  x: number;
  y: number;
}

interface IDraggableFloatOverProps {
  children?: ReactNode;
  defaultPosition: IFloatOverPosition;
  maximumHeight?: string;
  onClose?: () => void;
  useDragPanel?: boolean;
}

const DraggableFloatOver = (props: IDraggableFloatOverProps): JSX.Element => {
  const { currentTheme } = useThemeSwitcher();
  const { children, defaultPosition, maximumHeight, onClose, useDragPanel } =
    props;
  const [position, setPosition] = useState<IFloatOverPosition | undefined>();
  const [contentHeight, setContentHeight] = useState<number | undefined>(
    undefined,
  );
  const [contentWidth, setContentWidth] = useState<number | undefined>(
    undefined,
  );
  const contentRef =
    useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;
  const previousDefaultPosition: IFloatOverPosition | undefined =
    usePrevious(defaultPosition);

  useEffect(() => {
    if (
      defaultPosition.placement !== previousDefaultPosition?.placement ||
      defaultPosition.x !== previousDefaultPosition?.x ||
      defaultPosition.y !== previousDefaultPosition?.y
    ) {
      setPosition(undefined);
      setContentHeight(undefined);
      setContentWidth(undefined);
    }
  }, [defaultPosition, previousDefaultPosition]);

  useEffect(() => {
    if (
      contentRef.current &&
      position === undefined &&
      contentHeight !== undefined &&
      contentWidth !== undefined
    ) {
      const adjustedPosition: IFloatOverPosition = { ...defaultPosition };

      if (defaultPosition.placement === EFloatOverPlacement.Right) {
        const { width } = contentRef.current.getBoundingClientRect();
        adjustedPosition.x -= width;
      }

      setPosition(adjustedPosition);
    }
  }, [contentHeight, contentRef, contentWidth, defaultPosition, position]);

  const handleDrag = (_event: DraggableEvent, data: DraggableData) => {
    const { x, y } = data;
    setPosition({ placement: defaultPosition.placement, x, y });
  };

  const handleClose = () => {
    setPosition(undefined);

    if (onClose) {
      onClose();
    }
  };

  const handleResize = useCallback((width?: number, height?: number) => {
    setContentHeight(height);
    setContentWidth(width);
  }, []);

  return (
    <Draggable
      bounds='body'
      handle={useDragPanel ? '.drag-panel' : undefined}
      nodeRef={contentRef}
      onDrag={handleDrag}
      position={position}
    >
      <Layout
        currentTheme={currentTheme!}
        maximumHeight={maximumHeight}
        isVisible={position !== undefined}
        ref={contentRef}
      >
        <DragPanel className='drag-panel' />
        <Header>
          <IconButton
            icon={<CloseOutlined />}
            noBorder={true}
            onClick={handleClose}
          />
        </Header>
        <ScrollableContent onResize={handleResize}>
          {children}
        </ScrollableContent>
      </Layout>
    </Draggable>
  );
};

interface IFloatOverProps {
  children?: ReactNode;
  defaultPosition?: IFloatOverPosition;
  id: string;
  maximumHeight?: string;
  onClose?: () => void;
  useDragPanel?: boolean;
  visible: boolean;
}

const FloatOver = (props: IFloatOverProps): JSX.Element | null => {
  const {
    children,
    defaultPosition,
    id,
    maximumHeight,
    onClose,
    useDragPanel,
    visible,
  } = props;

  if (visible && defaultPosition !== undefined) {
    return (
      <DraggableFloatOver
        defaultPosition={defaultPosition}
        key={id}
        maximumHeight={maximumHeight}
        onClose={onClose}
        useDragPanel={useDragPanel}
      >
        {children}
      </DraggableFloatOver>
    );
  }

  return null;
};

export default FloatOver;
