import withFloatOver from 'components/hocs/withFloatOver/withFloatOver';
import ToEntitySelector from 'components/molecules/ToEntitySelector/ToEntitySelector';
import { IPageContentProps } from 'components/organisms/Page/Page';
import TemplateMonitor from 'components/pages/TemplatesPage/TemplateMonitor';
import { PAGE_LAYOUT_STYLES, STANDARD_SPACING_VALUE } from 'constants/styles';
import ETagTemplateFilteringProvider from 'contexts/ETagTemplateFiltering/ETagTemplateFiltering';
import ETagTemplateSortingProvider from 'contexts/ETagTemplateSorting/ETagTemplateSorting';
import { IToEntity } from 'interfaces/ToEntity';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { encodeIds } from 'utils/general';

const Layout = styled.div`
  ${PAGE_LAYOUT_STYLES}
`;

const Actions = styled.div`
  padding: 8px 4px 0 4px;
`;

const ToEntity = styled.div`
  height: 100%;
`;

interface ITemplatesPageContentProps extends IPageContentProps<undefined> {}

const TemplatesPageContent = (
  props: ITemplatesPageContentProps,
): JSX.Element => {
  const { userInfo } = props;
  const { toEntities, toEntityUserStates } = userInfo;
  const [selectedToEntities, setSelectedToEntities] = useState<IToEntity[]>([]);

  useEffect(() => {
    setSelectedToEntities(toEntities);
  }, [toEntities]);

  const hasMultipleToEntities: boolean = toEntities.length > 1;
  const templatesHeight: string = `calc((100vh - ${
    2 * STANDARD_SPACING_VALUE
  }px) / ${Math.max(Math.min(selectedToEntities.length, 3), 1)})`;

  return (
    <Layout>
      {hasMultipleToEntities ? (
        <Actions>
          <ToEntitySelector
            onChange={setSelectedToEntities}
            selectedToEntities={selectedToEntities}
            toEntities={toEntities}
          />
        </Actions>
      ) : null}
      {selectedToEntities.map(
        (toEntity: IToEntity): JSX.Element => (
          <ToEntity key={toEntity.to_entity}>
            <ETagTemplateFilteringProvider>
              <ETagTemplateSortingProvider>
                <TemplateMonitor
                  encodedPermissionsId={encodeIds(
                    ['templateMonitor'],
                    toEntity.to_entity,
                  )}
                  isEmbeddedTitle={hasMultipleToEntities}
                  maxHeight={templatesHeight}
                  toEntity={toEntity}
                  toEntityUserState={toEntityUserStates?.[toEntity.to_entity]}
                />
              </ETagTemplateSortingProvider>
            </ETagTemplateFilteringProvider>
          </ToEntity>
        ),
      )}
    </Layout>
  );
};

export default withFloatOver(TemplatesPageContent);
