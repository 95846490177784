import { IColumnWidthAdjustment } from 'components/organisms/PhysicalPathReview/types';
import {
  VIEW_DATA_TABLE_COLUMN_LOSS_METHODS_COLUMN_WIDTH,
  VIEW_DATA_TABLE_COLUMN_OASIS_INFO_COLUMN_WIDTH,
  VIEW_DATA_TABLE_COLUMN_POINT_COLUMN_WIDTH,
  VIEW_DATA_TABLE_COLUMN_SE_COLUMN_WIDTH,
} from 'constants/styles';

export const PHYSICAL_PATH_VIEW_REVIEW_BASE_WIDTH_VALUE = 748;

export const OASIS_INFO_COLUMN_INDEX = 8;
export const LOSS_METHODS_COLUMN_INDEX = 9;

// An array of structures for updating the column data which require dynamic
// adjustment based on width. These indexes are the same for all physical path column data
// because we align all columns together. The variable columns are either
// aligned fillers or one of Contracts, Contacts, SE(s), OASIS Info or Loss
// Methods
export const COLUMN_WIDTH_ADJUSTMENTS: IColumnWidthAdjustment[] = [
  {
    index: 5,
    minWidth: VIEW_DATA_TABLE_COLUMN_POINT_COLUMN_WIDTH,
    percentageWidth: 'calc((7900%/969))',
  },
  {
    index: 6,
    minWidth: VIEW_DATA_TABLE_COLUMN_POINT_COLUMN_WIDTH,
    percentageWidth: 'calc((7900%/969))',
  },
  {
    index: 7,
    minWidth: VIEW_DATA_TABLE_COLUMN_SE_COLUMN_WIDTH,
    percentageWidth: 'calc((9800%/969))',
  },
  {
    index: OASIS_INFO_COLUMN_INDEX,
    minWidth: VIEW_DATA_TABLE_COLUMN_OASIS_INFO_COLUMN_WIDTH,
    percentageWidth: 'calc((22400%/969))',
  },
  {
    index: LOSS_METHODS_COLUMN_INDEX,
    minWidth: VIEW_DATA_TABLE_COLUMN_LOSS_METHODS_COLUMN_WIDTH,
    percentageWidth: 'calc((5500%/323))',
  },
];

export const LOSS_METHODS_TITLE = 'Loss Methods';

export const OASIS_INFO_TITLE = 'OASIS Info';
