import { API } from 'aws-amplify';
import { AxiosResponse } from 'axios';
import {
  IETagAuditStatusesResponse,
  IETagCorrectionRequest,
  IETagDeleteScheduledDraftSubmissionResponse,
  IETagDraftScheduleSubmission,
  IETagProfileChangeRequest,
  IETagScheduledDraftResponse,
  IETagScheduleDraftSubmissionResponse,
  IETagTerminateTagRequest,
  IETagValidationResponse,
  IETagValidateProfileChangeResponse,
  IETagWithdrawTagRequest,
  IETagDraft,
} from 'interfaces/ETag';
import {
  TAGS_REQUESTS_AUDIT,
  TAGS_REQUESTS_CORRECTION_ROUTE,
  TAGS_REQUESTS_NEW_TAG_ROUTE,
  TAGS_REQUESTS_SAVE_NEW_TAG_ROUTE,
  TAGS_REQUESTS_SAVE_VALIDATION_NEW_TAG_ROUTE,
  TAGS_REQUESTS_PROFILE_CHANGE_ROUTE,
  TAGS_REQUESTS_TERMINATE_TAG_ROUTE,
  TAGS_REQUESTS_VALIDATION_CORRECTION_ROUTE,
  TAGS_REQUESTS_VALIDATION_NEW_TAG_ROUTE,
  TAGS_REQUESTS_VALIDATION_PROFILE_CHANGE_ROUTE,
  TAGS_REQUESTS_LATEST_VALIDATION_PROFILE_CHANGE_ROUTE,
  TAGS_REQUESTS_VALIDATION_TERMINATION_ROUTE,
  TAGS_REQUESTS_VALIDATION_WITHDRAW_ROUTE,
  TAGS_REQUESTS_WITHDRAW_ROUTE,
  TAGS_REQUESTS_NEW_TAG_SCHEDULE_ROUTE,
} from 'services/agent/constants';
import { AGENT_SERVICE_API_NAME, DEFAULT_OPTIONS } from 'services/constants';
import { TTimeZone } from 'types/DateTime';
import {
  TETagDraftId,
  TETagProfileChangeId,
  TETagTagPrimaryKey,
} from 'types/ETag';
import { TToEntityId } from 'types/ToEntity';

/**
 * status codes and return values:
 * 200 --> {response: "<response from authority>"}
 * 400 --> {errorMessage: "New Tag request post body failed validation",
 *          response: "json([{is_valid: boolean, error_message: string}])"}
 * 404 --> {errorMessage: "Failed response", response: "<response from authority>"}
 * 429 --> {errorMessage: "Duplicate response", response: "<response from authority>"}
 * other ---> {errorMessage: "<anything>"}
 * @param toEntityId e.g. PSECode-123
 * @param skipValidation submit directly without validating first?
 * @param draftId the id of the draft to submit for a new tag
 * @param timeZone datetimes in the request will be formatted using this time zone
 */
export const requestNewTag = async (
  toEntityId: TToEntityId,
  skipValidation: boolean,
  draftId: Exclude<TETagDraftId, null>,
  timeZone: TTimeZone,
): Promise<AxiosResponse<IETagValidationResponse>> => {
  return await API.post(
    AGENT_SERVICE_API_NAME,
    TAGS_REQUESTS_NEW_TAG_ROUTE(toEntityId, draftId, skipValidation, timeZone),
    DEFAULT_OPTIONS,
  );
};

/**
 * status codes and return values:
 * 200 --> {response: "<response from authority>"}
 * 400 --> case 1: {errorMessage: "Failed to put draft. Check log for details." | "Draft ID ({draft_tag.draft_id}) has already been successfully submitted"}
 * 400 --> case 2: {errorMessage: "Draft Tag Not Found" | "Bad parameters: please check draftId"}
 * 400 --> case 3: {errorMessage: "New Tag request post body failed validation", response: "json([{is_valid: boolean, error_message: string}])"}
 * 404 --> {errorMessage: "Failed response", response: "<response from authority>"}
 * 429 --> {errorMessage: "Duplicate response", response: "<response from authority>"}
 * other ---> {errorMessage: "<anything>"}
 * @param toEntityId e.g. PSECode-123
 * @param skipValidation submit directly without validating first?
 * @param draftId the id of the draft to submit for a new tag
 * @param timeZone datetimes in the request will be formatted using this time zone
 * @param eTagDraft draft of the tag being saved then submitted
 */
export const saveAndRequestNewTag = async (
  toEntityId: TToEntityId,
  skipValidation: boolean,
  draftId: Exclude<TETagDraftId, null>,
  timeZone: TTimeZone,
  eTagDraft: IETagDraft,
): Promise<AxiosResponse<IETagValidationResponse>> => {
  const options = {
    ...DEFAULT_OPTIONS,
    body: eTagDraft,
  };
  return await API.post(
    AGENT_SERVICE_API_NAME,
    TAGS_REQUESTS_SAVE_NEW_TAG_ROUTE(
      toEntityId,
      draftId,
      skipValidation,
      timeZone,
    ),
    options,
  );
};

export const requestAudit = async (
  toEntityId: TToEntityId,
  tagPrimaryKey: TETagTagPrimaryKey,
  requestId?: number,
): Promise<AxiosResponse<IETagAuditStatusesResponse>> => {
  return await API.get(
    AGENT_SERVICE_API_NAME,
    TAGS_REQUESTS_AUDIT(toEntityId, tagPrimaryKey, requestId),
    DEFAULT_OPTIONS,
  );
};

/**
 * status codes and return values:
 * 200 --> case1: {response: "json({valid: true})"}
 * 200 --> case2: {response: "json({valid: false, failure_list: [{is_valid:false, error_message: "..."}]})"}
 * 400 --> {errorMessage: "Draft Tag Not Found" | "Bad parameters: please check draftId"}
 * other ---> {errorMessage: "<anything>"}
 * @param toEntityId e.g. PSECode-123
 * @param draftId required, get 400 error if missing
 * @param timeZone datetimes in the request will be formatted using this time zone
 */
export const validateRequestNewTag = async (
  toEntityId: TToEntityId,
  draftId: Exclude<TETagDraftId, null>,
  timeZone: TTimeZone,
): Promise<AxiosResponse<IETagValidationResponse>> => {
  return await API.post(
    AGENT_SERVICE_API_NAME,
    TAGS_REQUESTS_VALIDATION_NEW_TAG_ROUTE(toEntityId, draftId, timeZone),
    DEFAULT_OPTIONS,
  );
};

/**
 * status codes and return values:
 * 200 --> case1: {response: "json({valid: true})"}
 * 200 --> case2: {response: "json({valid: false, failure_list: [{is_valid:false, error_message: "..."}]})"}
 * 400 --> {errorMessage: "Draft Tag Not Found" | "Bad parameters: please check draftId"}
 * other ---> {errorMessage: "<anything>"}
 * @param toEntityId e.g. PSECode-123
 * @param draftId required, get 400 error if missing
 * @param timeZone datetimes in the request will be formatted using this time zone
 * @param eTagDraft draft of the tag being saved then validated
 */
export const saveAndValidateNewTag = async (
  toEntityId: TToEntityId,
  draftId: Exclude<TETagDraftId, null>,
  timeZone: TTimeZone,
  eTagDraft: IETagDraft,
): Promise<AxiosResponse<IETagValidationResponse>> => {
  const options = {
    ...DEFAULT_OPTIONS,
    body: eTagDraft,
  };
  return await API.post(
    AGENT_SERVICE_API_NAME,
    TAGS_REQUESTS_SAVE_VALIDATION_NEW_TAG_ROUTE(toEntityId, draftId, timeZone),
    options,
  );
};

export const requestTerminateTag = async (
  toEntityId: TToEntityId,
  skipValidation: boolean,
  timeZone: TTimeZone,
  requestBody: IETagTerminateTagRequest,
): Promise<AxiosResponse<IETagValidationResponse>> => {
  const options = { ...DEFAULT_OPTIONS, body: requestBody };
  return await API.post(
    AGENT_SERVICE_API_NAME,
    TAGS_REQUESTS_TERMINATE_TAG_ROUTE(toEntityId, skipValidation, timeZone),
    options,
  );
};

export const validateRequestTerminateTag = async (
  toEntityId: TToEntityId,
  timeZone: TTimeZone,
  requestBody: IETagTerminateTagRequest,
): Promise<AxiosResponse<IETagValidationResponse>> => {
  const options = { ...DEFAULT_OPTIONS, body: requestBody };
  return await API.post(
    AGENT_SERVICE_API_NAME,
    TAGS_REQUESTS_VALIDATION_TERMINATION_ROUTE(toEntityId, timeZone),
    options,
  );
};

/**
 * status codes and return values:
 * 200 --> {response: "<response from authority>"}
 * 400 --> {errorMessage: "Withdraw request post body failed validation",
 *          response: "json([{is_valid: boolean, error_message: string}])"}
 * 404 --> {errorMessage: "Failed response", response: "<response from authority>"}
 * 429 --> {errorMessage: "Duplicate response", response: "<response from authority>"}
 * other ---> {errorMessage: "<anything>"}
 * @param toEntityId e.g. PSECode-123
 * @param skipValidation submit directly without validating first?
 * @param requestBody request_id and contact_info.contact are required
 */
export const requestWithdrawTag = async (
  toEntityId: TToEntityId,
  timeZone: TTimeZone,
  skipValidation: boolean,
  requestBody: IETagWithdrawTagRequest,
): Promise<AxiosResponse<IETagValidationResponse>> => {
  const options = { ...DEFAULT_OPTIONS, body: requestBody };
  return await API.post(
    AGENT_SERVICE_API_NAME,
    TAGS_REQUESTS_WITHDRAW_ROUTE(toEntityId, timeZone, skipValidation),
    options,
  );
};

/**
 * status codes and return values:
 * 200 --> {response: json({valid: boolean, failure_list: [{is_valid: false, error_message: ...}]})}
 * other ---> {errorMessage: "<anything>"}
 * @param toEntityId e.g. PSECode-123
 * @param requestBody request_id and contact_info.contact are required
 */
export const validateRequestWithdrawTag = async (
  toEntityId: TToEntityId,
  timeZone: TTimeZone,
  requestBody: IETagWithdrawTagRequest,
): Promise<AxiosResponse<IETagValidationResponse>> => {
  const options = { ...DEFAULT_OPTIONS, body: requestBody };
  return await API.post(
    AGENT_SERVICE_API_NAME,
    TAGS_REQUESTS_VALIDATION_WITHDRAW_ROUTE(toEntityId, timeZone),
    options,
  );
};

export const requestCorrection = async (
  toEntityId: TToEntityId,
  timeZone: TTimeZone,
  skipValidation: boolean,
  correctionRequest: IETagCorrectionRequest,
): Promise<AxiosResponse<IETagValidationResponse>> => {
  const options = { ...DEFAULT_OPTIONS, body: correctionRequest };

  return await API.post(
    AGENT_SERVICE_API_NAME,
    TAGS_REQUESTS_CORRECTION_ROUTE(toEntityId, timeZone, skipValidation),
    options,
  );
};

export const validateRequestCorrection = async (
  toEntityId: TToEntityId,
  timeZone: TTimeZone,
  correctionRequest: IETagCorrectionRequest,
): Promise<AxiosResponse<IETagValidationResponse>> => {
  const options = { ...DEFAULT_OPTIONS, body: correctionRequest };

  return await API.post(
    AGENT_SERVICE_API_NAME,
    TAGS_REQUESTS_VALIDATION_CORRECTION_ROUTE(toEntityId, timeZone),
    options,
  );
};

export const requestProfileChange = async (
  toEntityId: TToEntityId,
  timeZone: TTimeZone,
  skipValidation: boolean,
  profileChangeRequest: IETagProfileChangeRequest,
): Promise<AxiosResponse<IETagValidationResponse>> => {
  const options = { ...DEFAULT_OPTIONS, body: profileChangeRequest };

  return await API.post(
    AGENT_SERVICE_API_NAME,
    TAGS_REQUESTS_PROFILE_CHANGE_ROUTE(toEntityId, timeZone, skipValidation),
    options,
  );
};

export const validateRequestProfileChange = async (
  toEntityId: TToEntityId,
  timeZone: TTimeZone,
  profileChangeRequest: IETagProfileChangeRequest,
): Promise<AxiosResponse<IETagValidationResponse>> => {
  const options = { ...DEFAULT_OPTIONS, body: profileChangeRequest };

  return await API.post(
    AGENT_SERVICE_API_NAME,
    TAGS_REQUESTS_VALIDATION_PROFILE_CHANGE_ROUTE(toEntityId, timeZone),
    options,
  );
};

export const latestValidationRequestProfileChange = async (
  toEntityId: TToEntityId,
  profileChangeId: TETagProfileChangeId,
): Promise<AxiosResponse<IETagValidateProfileChangeResponse>> => {
  return await API.get(
    AGENT_SERVICE_API_NAME,
    TAGS_REQUESTS_LATEST_VALIDATION_PROFILE_CHANGE_ROUTE(
      toEntityId,
      profileChangeId,
    ),
    DEFAULT_OPTIONS,
  );
};

/**
 *
 * @param toEntityId
 * @param draftId Optional parameter. If left out, will return all currently schedule draft submissions
 * @returns
 */
export const getScheduledSubmissionForDraft = async (
  toEntityId: TToEntityId,
  draftId?: TETagDraftId,
): Promise<AxiosResponse<IETagScheduledDraftResponse>> => {
  return await API.get(
    AGENT_SERVICE_API_NAME,
    TAGS_REQUESTS_NEW_TAG_SCHEDULE_ROUTE(toEntityId, draftId),
    DEFAULT_OPTIONS,
  );
};

export const scheduleDraftSubmission = async (
  toEntityId: TToEntityId,
  draftSubmission: IETagDraftScheduleSubmission,
): Promise<AxiosResponse<IETagScheduleDraftSubmissionResponse>> => {
  const options = { ...DEFAULT_OPTIONS, body: draftSubmission };

  return await API.put(
    AGENT_SERVICE_API_NAME,
    TAGS_REQUESTS_NEW_TAG_SCHEDULE_ROUTE(toEntityId),
    options,
  );
};

export const cancelScheduledDraftSubmission = async (
  toEntityId: TToEntityId,
  draftId: TETagDraftId,
): Promise<AxiosResponse<IETagDeleteScheduledDraftSubmissionResponse>> => {
  return await API.del(
    AGENT_SERVICE_API_NAME,
    TAGS_REQUESTS_NEW_TAG_SCHEDULE_ROUTE(toEntityId, draftId),
    DEFAULT_OPTIONS,
  );
};
