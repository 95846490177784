import {
  CONTRACTS_ADD_BUTTON_OFFSET,
  CONTRACTS_REMOVE_BUTTON_OFFSET,
  getEditContractsColumns,
  IContractsEditableProps,
} from 'components/molecules/ContractsEditable/ContractsEditable';
import EditDataTable from 'components/molecules/EditDataTable/EditDataTable';
import { DETAIL_POPOVER_DATA_TABLE_MAXIMUM_HEIGHT } from 'constants/styles';
import { IContract } from 'interfaces/General';
import { useCallback, useMemo } from 'react';
import { getInitialContract } from 'utils/detail';

export interface IContractsEditProps extends IContractsEditableProps {
  editButtonWidth?: string;
  equalityChecker?: (a: IContract[], b: IContract[]) => boolean;
  hasChanged?: boolean;
  id: string;
  initialData?: IContract[] | null;
  tableTitle?: string;
}

const ContractsEdit = (props: IContractsEditProps): JSX.Element => {
  const {
    contractInputColumnConfig,
    data,
    editButtonWidth,
    equalityChecker,
    hasChanged,
    id,
    initialData,
    initialEditKey,
    isDisabled,
    onAdd,
    onRemove,
    tableTitle,
  } = props;

  const editContactInfoColumns = useMemo(
    () => getEditContractsColumns(contractInputColumnConfig, hasChanged),
    [contractInputColumnConfig, hasChanged],
  );

  const initialiser = useCallback(
    () => getInitialContract(initialEditKey),
    [initialEditKey],
  );

  return (
    <EditDataTable<IContract>
      addButtonOffset={CONTRACTS_ADD_BUTTON_OFFSET}
      columns={editContactInfoColumns}
      data={data}
      editButtonWidth={editButtonWidth}
      equalityChecker={equalityChecker}
      id={id}
      initialData={initialData}
      initialiser={initialiser}
      isDisabled={isDisabled}
      maximumAllowableAdds={-1}
      maximumHeight={DETAIL_POPOVER_DATA_TABLE_MAXIMUM_HEIGHT}
      onAdd={onAdd}
      onRemove={onRemove}
      pagination={false}
      removeButtonOffset={CONTRACTS_REMOVE_BUTTON_OFFSET}
      showDataCount={true}
      tableTitle={tableTitle}
      tooltipTitle='Edit All Contracts'
    />
  );
};

export default ContractsEdit;
