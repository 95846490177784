import Link from 'components/atoms/Link/Link';
import { checkPermissionsAndThen } from 'components/pages/LandingPage/helpers';
import { IToEntitiesPermissionId } from 'components/pages/LandingPage/types';

interface IPageLinkProps extends IToEntitiesPermissionId {
  target: string;
}

const PageLink = checkPermissionsAndThen(
  ({ children, target }: IPageLinkProps) => {
    return <Link to={target} children={children} />;
  },
);

export default PageLink;
