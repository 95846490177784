import Checkbox from 'components/atoms/Checkbox/Checkbox';
import TextAligned from 'components/atoms/TextAligned/TextAligned';
import { NO_OP_HANDLER } from 'constants/General';
import { CELL_CONTENT_HEIGHT, CELL_CONTENT_PADDING } from 'constants/styles';
import { DATE_FORMAT, DATE_TIME_FORMAT } from 'constants/time';
import { ISummaryThemedStyles } from 'interfaces/Summary';
import { TTimeZone } from 'types/DateTime';
import { ZonedDateTime } from 'utils/zonedDateTime';

interface ICellProps {
  summaryThemedStyles: ISummaryThemedStyles;
  minWidth: number;
}

// Typed Cells
interface IBooleanCellProps extends ICellProps {
  value: boolean;
}

interface IDateCellProps extends ICellProps {
  timeZone: TTimeZone;
  value: string;
}

interface IIntCellProps extends ICellProps {
  value: number;
}

interface IStringCellProps extends ICellProps {
  value: string;
}

export const BooleanCell = (props: IBooleanCellProps): JSX.Element => {
  const { minWidth, summaryThemedStyles, value } = props;

  return (
    <TextAligned
      height={CELL_CONTENT_HEIGHT}
      minWidth={minWidth}
      padding={CELL_CONTENT_PADDING}
      summaryThemedStyles={summaryThemedStyles}
      textAlign='center'
    >
      <Checkbox checked={value} isDisabled={true} onChange={NO_OP_HANDLER} />
    </TextAligned>
  );
};

export const DateCell = (props: IDateCellProps): JSX.Element => {
  const { minWidth, summaryThemedStyles, value, timeZone } = props;

  return (
    <TextAligned
      height={CELL_CONTENT_HEIGHT}
      minWidth={minWidth}
      padding={CELL_CONTENT_PADDING}
      summaryThemedStyles={summaryThemedStyles}
      textAlign='center'
    >
      {value === undefined || value === null
        ? ''
        : ZonedDateTime.parseIso(value, timeZone).format(DATE_FORMAT)}
    </TextAligned>
  );
};

export const DateTimeCell = (props: IDateCellProps): JSX.Element => {
  const { minWidth, summaryThemedStyles, value, timeZone } = props;

  return (
    <TextAligned
      height={CELL_CONTENT_HEIGHT}
      minWidth={minWidth}
      padding={CELL_CONTENT_PADDING}
      summaryThemedStyles={summaryThemedStyles}
      textAlign='center'
    >
      {value === undefined || value === null
        ? ''
        : ZonedDateTime.parseIso(value, timeZone).format(DATE_TIME_FORMAT)}
    </TextAligned>
  );
};

export const IntegerCell = (props: IIntCellProps): JSX.Element => {
  const { minWidth, summaryThemedStyles, value } = props;

  return (
    <TextAligned
      height={CELL_CONTENT_HEIGHT}
      minWidth={minWidth}
      padding={CELL_CONTENT_PADDING}
      summaryThemedStyles={summaryThemedStyles}
      textAlign='right'
    >
      {value}
    </TextAligned>
  );
};

export const StringCell = (props: IStringCellProps): JSX.Element => {
  const { minWidth, summaryThemedStyles, value } = props;

  return (
    <TextAligned
      height={CELL_CONTENT_HEIGHT}
      minWidth={minWidth}
      padding={CELL_CONTENT_PADDING}
      summaryThemedStyles={summaryThemedStyles}
      textAlign='center'
    >
      {value}
    </TextAligned>
  );
};
