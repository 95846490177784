import { EDIT_ETAG_PHYSICAL_SEGMENTS_PROFILE_LABEL } from 'constants/Detail';
import { EProfileFormat } from 'enums/Detail';
import { ERequestType } from 'enums/ETag';
import { IInterval } from 'interfaces/DateTime';
import { IEntityInfo } from 'interfaces/Entity';
import {
  IETagPhysicalSegmentsProfile,
  IETagPhysicalSegmentsProfiles,
  IETagTransmissionAllocationProfile,
  IETagTransmissionSegment,
} from 'interfaces/ETag';
import { ICurtailment, IEnergyProfile } from 'interfaces/General';
import { IPointInfo } from 'interfaces/Point';
import { TTimeZone } from 'types/DateTime';
import { getEditInfoKey } from 'utils/detail';
import { ZonedDateTime } from 'utils/zonedDateTime';

/**
 * Used to pass the original profile along with how much weight it should be considered with
 */
interface IWeightedEnergyProfile {
  profile: IEnergyProfile | null;
  weight: number;
}

/**
 * Used to pass the original profile along with how much weight it should be considered with
 */
interface IWeightedPhysicalSegmentProfile {
  profile: IETagDatedPhysicalSegmentsProfile;
  weight: number;
}

/**
 * Used to pass the original ta profile along with how much weight it should be considered with
 */
interface IWeightedTAProfile {
  profile: IETagTransmissionAllocationProfile | null;
  weight: number;
}

interface IRequestTypeAndId {
  lastRequestId: number | null;
  lastRequestType: ERequestType | null;
}

interface IETagDatedPhysicalSegmentsProfile {
  key: string;
  physical_segments_profiles: IETagPhysicalSegmentsProfiles | null;
  start: ZonedDateTime;
  stop: ZonedDateTime;
}

/**
 * Used to pass the original segment along with how much weight it should be considered with
 */
interface IWeightedSegments {
  weight: number;
  segments: IETagTransmissionSegment[];
}

/**
 * Returns the number of minutes that the intervals overlap
 *
 * @param intervalA An interval to be checked
 * @param intervalB An interval to be checked
 */
const getOverlappingMins = (
  intervalA: IInterval,
  intervalB: IInterval,
): number => {
  return Math.max(
    ZonedDateTime.min(intervalA.stop, intervalB.stop).diff(
      ZonedDateTime.max(intervalA.start, intervalB.start),
      'minutes',
    ),
    0,
  );
};

const parseDates = (
  profiles: IETagPhysicalSegmentsProfile[],
  timeZone: TTimeZone,
): IETagDatedPhysicalSegmentsProfile[] => {
  return profiles?.map((profile: IETagPhysicalSegmentsProfile) => {
    return {
      key: profile.key,
      physical_segments_profiles: profile.physical_segments_profiles,
      start: ZonedDateTime.strictParse(profile.start, timeZone),
      stop: ZonedDateTime.strictParse(profile.stop, timeZone),
    };
  });
};

const formatDates = (
  profiles: IETagDatedPhysicalSegmentsProfile[],
): IETagPhysicalSegmentsProfile[] => {
  return profiles?.map((profile: IETagDatedPhysicalSegmentsProfile) => {
    return {
      key: profile.key,
      physical_segments_profiles: profile.physical_segments_profiles,
      start: profile.start?.toIsoString() || null,
      stop: profile.stop?.toIsoString() || null,
    };
  });
};

/**
 *
 * @param profiles
 * @param profileFormat
 */
export const integrate = (
  profiles: IETagPhysicalSegmentsProfile[] | null,
  profileFormat: EProfileFormat = EProfileFormat.StartStop,
  timeZone: TTimeZone,
): IETagPhysicalSegmentsProfile[] | null => {
  if (
    profiles === null ||
    profiles.length === 0 ||
    profileFormat === EProfileFormat.StartStop
  ) {
    return profiles;
  }

  const zonedProfiles: IETagDatedPhysicalSegmentsProfile[] = parseDates(
    profiles,
    timeZone,
  );

  // the returned list of integrated profiles
  const integratedProfiles: IETagDatedPhysicalSegmentsProfile[] = [];

  // how many minutes in one time step
  let intervalMins: number;

  // we use the provided granularity to set intervalMins
  switch (profileFormat) {
    case EProfileFormat.Daily:
      intervalMins = 60;
      break;
    case EProfileFormat.Hourly:
      intervalMins = 60;
      break;
    case EProfileFormat.FifteenMinute:
      intervalMins = 15;
      break;
    case EProfileFormat.FiveMinute:
      intervalMins = 5;
      break;
    default:
      intervalMins = 60;
      break;
  }

  // Search for the earliest start and latest stop
  let start: ZonedDateTime | null = null;
  let stop: ZonedDateTime | null = null;

  zonedProfiles.forEach(
    (eTagPhysicalSegmentsProfile: IETagDatedPhysicalSegmentsProfile) => {
      if (start === null && eTagPhysicalSegmentsProfile.start !== null) {
        start = eTagPhysicalSegmentsProfile.start;
      }

      if (eTagPhysicalSegmentsProfile.stop !== null) {
        stop = eTagPhysicalSegmentsProfile.stop;
      }
    },
  );

  if (start === null || stop === null) {
    throw new Error('Missing profiles start and/or stop');
  }

  // Use the profiles start and stop and the intervalMins to get all intervals for the final integrated array
  const interval: IInterval = {
    start,
    stop,
  };
  const intervals: IInterval[] = divideInterval(interval, intervalMins);

  let primaryId: number = 1;

  // For every interval in the final array, get all overlapping profiles, then calculate and push new profile
  intervals.forEach((interval: IInterval) => {
    const chunk: IETagDatedPhysicalSegmentsProfile[] = getProfilesOnInterval(
      zonedProfiles,
      interval,
      timeZone,
    );

    if (chunk.length > 0) {
      integratedProfiles.push(
        getScaledProfile(chunk, primaryId, interval, timeZone),
      );

      primaryId += 1;
    }
  });

  // If the granularity is Daily, the switch statement integrated hourly
  // For daily integration we first calculate hourly and sum, this is where we do the final sum
  if (profileFormat === EProfileFormat.Daily) {
    const dailyProfiles: IETagDatedPhysicalSegmentsProfile[] = [];

    // Calculate all daily intervals in the output array
    const dailyIntervals = divideInterval(
      {
        start: integratedProfiles[0].start!,
        stop: integratedProfiles[integratedProfiles.length - 1].stop!,
      },
      24 * 60,
    );

    primaryId = 1;

    // Repeat the same process for these intervals with the previously integrated profiles
    dailyIntervals.forEach((interval: IInterval) => {
      const chunk: IETagDatedPhysicalSegmentsProfile[] = getProfilesOnInterval(
        integratedProfiles,
        interval,
        timeZone,
      );

      if (chunk.length > 0) {
        dailyProfiles.push(
          getScaledProfile(chunk, primaryId, interval, timeZone),
        );

        primaryId += 1;
      }
    });

    return formatDates(dailyProfiles);
  }

  return formatDates(integratedProfiles);
};

/**
 * Given an interval, it returns all overlapping intervals given a timestep.
 * Starts at the nearest multiple of the timestep given
 * ex (5:35-6:00) / 15 = {(5:30-5:45),(5:45-6:00)}
 *
 * @param start Earliest start time given
 * @param stop Latest stop given
 * @param stepMins The number of minutes in one step
 */
export const divideInterval = (
  interval: IInterval,
  stepMins: number,
): IInterval[] => {
  const returnVal: IInterval[] = [];

  // temporarily set this to the interval start
  let overallStart: ZonedDateTime = interval.start;

  // temporarily set this to the interval stop
  let overallStop: ZonedDateTime = interval.stop;

  switch (stepMins) {
    case 5:
    case 15:
    case 60:
      // if interval is under an hour, we can base start on minutes
      // for start, subtract enough minutes to reach a time for the selected time step
      overallStart = interval.start.subtract(
        interval.start.getMinute() % stepMins,
        'minutes',
      );
      // for the stop, add enough minutes to get to a time step
      overallStop = interval.stop.add(
        interval.stop.getMinute() % stepMins === 0
          ? 0
          : stepMins - (interval.stop.getMinute() % stepMins),
        'minutes',
      );
      break;
    case 1440:
      // for daily set start and stop to the start and end of the day respectively unless it is already there
      overallStart = interval.start.startOf('day');
      overallStop =
        interval.stop.getHour() ||
        interval.stop.getMinute() ||
        interval.stop.getSecond()
          ? interval.stop.add(1, 'day').startOf('day')
          : interval.stop.startOf('day');
      break;

    default:
      break;
  }
  // set an interval with a start of the earliest start and a stop by incrementing by stepmins
  let newestItem: IInterval =
    stepMins === 1440
      ? {
          start: overallStart,
          stop: overallStart.add(1, 'day').startOf('day'),
        }
      : {
          start: overallStart,
          stop: overallStart.add(stepMins, 'minutes'),
        };
  returnVal.push(newestItem);

  // repeat this until the new interval stop matches the overall stop
  while (newestItem.stop < overallStop) {
    newestItem =
      stepMins === 1440
        ? {
            start: returnVal[returnVal.length - 1].start
              .add(1, 'day')
              .startOf('day'),
            stop: returnVal[returnVal.length - 1].stop
              .add(1, 'day')
              .startOf('day'),
          }
        : {
            start: returnVal[returnVal.length - 1].start.add(
              stepMins,
              'minutes',
            ),
            stop: returnVal[returnVal.length - 1].stop.add(stepMins, 'minutes'),
          };
    returnVal.push(newestItem);
  }
  return returnVal;
};

/**
 * According to interval, get the smallest window of data containing the next chunk of time we need
 * @param profiles
 */
const getProfilesOnInterval = (
  profiles: IETagDatedPhysicalSegmentsProfile[],
  interval: IInterval,
  timeZone: TTimeZone,
): IETagDatedPhysicalSegmentsProfile[] => {
  // // add all items with stop later than the start of the interval and start earlier than the stop of the interval
  const profilesOnInterval = profiles.filter(
    (profile: IETagDatedPhysicalSegmentsProfile) => {
      if (profile.start === null && profile.stop === null) {
        // Ignore profile rows with no start and no stop since we can't determine
        // its interval
        return false;
      } else if (profile.start === null || profile.stop === null) {
        throw new Error('Missing profile start and/or stop');
      } else if (
        profile.stop.isAfter(interval.start) &&
        profile.start.isBefore(interval.stop)
      ) {
        return true;
      }
      return false;
    },
  );

  return profilesOnInterval;
};

/**
 * Combines profiles correctly into a 1 hour chunk
 * @param profilesRelevantToInterval Array of profiles to combine
 */
const getScaledProfile = (
  profilesRelevantToInterval: IETagDatedPhysicalSegmentsProfile[],
  primaryId: number,
  interval: IInterval,
  timeZone: TTimeZone,
): IETagDatedPhysicalSegmentsProfile => {
  let key: string = getEditInfoKey(
    EDIT_ETAG_PHYSICAL_SEGMENTS_PROFILE_LABEL,
    primaryId,
    0,
  );

  const adjustedProfilesandWeights: IWeightedPhysicalSegmentProfile[] =
    profilesRelevantToInterval.map(
      (
        profile: IETagDatedPhysicalSegmentsProfile,
      ): IWeightedPhysicalSegmentProfile => ({
        weight:
          getOverlappingMins(interval, {
            start: profile.start,
            stop: profile.stop,
          }) / 60,
        profile,
      }),
    );

  // format the start string into the proper format
  const start: ZonedDateTime = interval.start;
  // format the stop string into the proper format
  const stop: ZonedDateTime = interval.stop;

  // get the gen profiles that need to be combined from the array, and pass them to the function that combines them appropriately.
  const generationProfile: IEnergyProfile | null = energyProfileCombine(
    // we map adjusted profiles to the weight of each profile and its generation profile
    adjustedProfilesandWeights.map((profileandweight) => {
      if (profileandweight === null) {
        return { weight: 0, profile: null };
      }

      return {
        weight: profileandweight.weight,
        profile:
          profileandweight.profile.physical_segments_profiles?.generation
            ?.profile || null,
      };
    }),
    // this typecasting allows the conversion to occur.

    profilesRelevantToInterval[0].start || '',
    profilesRelevantToInterval[profilesRelevantToInterval.length - 1].stop ||
      '',
    interval.start,
    interval.stop,
  );

  // get the load profiles that need to be combined from the array, and pass them to the function that combines them appropriately.
  const loadProfile: IEnergyProfile | null = energyProfileCombine(
    // we map adjusted profiles to the weight of each profile and its load profile
    adjustedProfilesandWeights.map((profileandweight) => {
      return {
        weight: profileandweight.weight,
        profile:
          profileandweight.profile.physical_segments_profiles?.load?.profile ||
          null,
      };
    }),
    profilesRelevantToInterval[0].start,
    profilesRelevantToInterval[profilesRelevantToInterval.length - 1].stop,
    interval.start,
    interval.stop,
  );

  // get the segments that need to be combined from the array, and pass them to the function that combines them appropriately.
  const transmissionSegments: IETagTransmissionSegment[] =
    transmissionSegmentsCombine(
      // we map adjusted segments to the weight of each profile and its segment
      adjustedProfilesandWeights.map((profileandweight) => {
        return {
          weight: profileandweight.weight,
          segments:
            profileandweight.profile.physical_segments_profiles?.transmission
              ?.transmission_segments || [],
        };
      }),
      interval,
      profilesRelevantToInterval[0].start,
      profilesRelevantToInterval[profilesRelevantToInterval.length - 1].stop,
    );

  // Fanjing clarified that we don't need to compute this as it's not shown on the screen
  const transmissionLimit: number | null = null;

  // assemble the final object and return
  const returnVal: IETagDatedPhysicalSegmentsProfile = {
    key,
    physical_segments_profiles: {
      generation: {
        physical_segment_id:
          profilesRelevantToInterval[0].physical_segments_profiles?.generation
            ?.physical_segment_id!,
        profile: generationProfile,
        source_sink:
          profilesRelevantToInterval[0].physical_segments_profiles?.generation
            ?.source_sink || null,
        // source_sink are guaranteed to match, use first one
      },
      load: {
        physical_segment_id:
          profilesRelevantToInterval[0].physical_segments_profiles?.load
            ?.physical_segment_id!,
        profile: loadProfile,
        source_sink:
          profilesRelevantToInterval[0].physical_segments_profiles?.load
            ?.source_sink || null,
        // source_sink are guaranteed to match, use first one
      },
      transmission: {
        transmission_segments: transmissionSegments,
        transmission_limit: transmissionLimit,
      },
    },
    start: start,
    stop: stop,
  };

  return returnVal;
};

/**
 * Returns the most important request type in an array of profiles and its ID
 * @param profiles
 */
const getLastRequestTypeAndId = (
  profiles: IEnergyProfile[],
): IRequestTypeAndId => {
  // Initializes a return value to the first element.
  let returnVal: IRequestTypeAndId = {
    lastRequestType: ERequestType.None,
    lastRequestId: null,
  };
  let highestPriority: number = 0;
  profiles.forEach((profile: IEnergyProfile) => {
    let newPriority = 0;
    switch (profile?.last_request_type) {
      case ERequestType.Curtailment:
        newPriority = 6;
        break;
      case ERequestType.Reload:
        newPriority = 5;
        break;
      case ERequestType.Termination:
        newPriority = 4;
        break;
      case ERequestType.AtfAdjustment:
        newPriority = 3;
        break;
      case ERequestType.BtfAdjustment:
        newPriority = 2;
        break;
      case ERequestType.None:
        newPriority = 1;
    }
    // If this is the highest priority so far, update the return value
    if (newPriority > highestPriority) {
      highestPriority = newPriority;
      returnVal = {
        lastRequestId: profile.last_request_id,
        lastRequestType: profile.last_request_type,
      };
    }
  });

  // By this point, return value contains the higest priority request type and id
  return returnVal;
};

// TODO Consider combining the reducers into one reducer

/**
 * Sums an array of Energy Profile and gives a resulting Energy Profile
 * @param weightedEnergyProfiles An array of segments with the duration of the parent attached
 *
 */
const energyProfileCombine = (
  weightedEnergyProfiles: IWeightedEnergyProfile[],
  originalStart: ZonedDateTime,
  originalStop: ZonedDateTime,
  newStartTime: ZonedDateTime,
  newStopTime: ZonedDateTime,
): IEnergyProfile | null => {
  /* Find a valid profile, use it for the profile_id */
  let validProfile: IEnergyProfile = {} as IEnergyProfile;
  weightedEnergyProfiles.forEach((profile: IWeightedEnergyProfile) => {
    if (profile.profile !== null) {
      validProfile = profile.profile;
    }
  });
  const profileId: number = validProfile.profile_id;

  // if the profileId was undefined, then all profiles were null. Return null to save calculation time=]
  if (profileId === undefined) {
    return null;
  }

  let adjusted: boolean = weightedEnergyProfiles.some(
    (profile: IWeightedEnergyProfile) => profile.profile?.adjusted === true,
  );

  //  Simple roll up right now
  const curtailments = weightedEnergyProfiles.reduce(
    (accumulator: ICurtailment[], item: IWeightedEnergyProfile) => {
      if (
        item.profile?.curtailments.length !== undefined &&
        item.profile?.curtailments.length > 0
      ) {
        return accumulator.concat(item.profile?.curtailments);
      } else {
        return accumulator;
      }
    },
    [],
  );

  // Track whether or not to set mkt_lvl to null
  let mktLvlIsNull: boolean = true;
  /* Sum of market_level by weight of each profile*/
  let mktLvl: number | null = Math.round(
    weightedEnergyProfiles.reduce(
      (accumulator: number, item: IWeightedEnergyProfile) => {
        if (item.profile?.market_level !== null) {
          mktLvlIsNull = false;
        }
        return accumulator + (item.profile?.market_level || 0) * item.weight;
      },
      0,
    ),
  );
  // If our flag is still true, all values were null and the total should be null, not 0
  mktLvl = mktLvlIsNull ? null : mktLvl;

  // Track whether or not to set mw to null
  let mwIsNull: boolean = true;
  /* Sum of mw by weight of each profile*/
  let mw: number | null = Math.round(
    weightedEnergyProfiles.reduce(
      (accumulator, item: IWeightedEnergyProfile) => {
        if (item.profile?.mw !== null) {
          mwIsNull = false;
        }
        // We add this 000001 to mimic the rounding in summary
        return accumulator + (item.profile?.mw || 0) * item.weight + 0.000001;
      },
      0,
    ),
  );
  // If our flag is still true, all values were null and the total should be null, not 0
  mw = mwIsNull ? null : mw;

  // Track whether or not to set rl to null
  const rlIsNull: boolean = weightedEnergyProfiles.reduce(
    (accumulator: boolean, item: IWeightedEnergyProfile) => {
      return (
        accumulator &&
        (item.profile?.reliability_limit === null ||
          item.profile?.reliability_limit === undefined)
      );
    },
    true,
  );
  /* Sum of reliability_limit by weight of each profile*/
  let reliabilityLimit: number | null = Math.round(
    weightedEnergyProfiles.reduce(
      (accumulator, item: IWeightedEnergyProfile) => {
        return (
          accumulator + (item.profile?.reliability_limit || 0) * item.weight
        );
      },
      0,
    ),
  );

  // if (reliability_limit === null && )
  // If our flag is still true, all values were null and the total should be null, not 0
  reliabilityLimit = rlIsNull ? null : reliabilityLimit;

  const reloaders: IEntityInfo[] = weightedEnergyProfiles.reduce(
    (accumulator: IEntityInfo[], item: IWeightedEnergyProfile) => {
      if (item.profile === null || item.profile.reloaders.length === 0) {
        return accumulator;
      }
      return accumulator.concat(item.profile.reloaders);
    },
    [],
  );

  // If the profile time matches the time of the original profile, use its start ramp duration
  const startRampDur: number | null =
    (originalStart !== null && newStartTime.isSame(originalStart)
      ? weightedEnergyProfiles[0].profile?.start_ramp_dur
      : null) || null;

  // If the profile time matches the time of the original profile, use its stop ramp duration
  const stopRampDur: number | null =
    (originalStop !== null && newStopTime.isSame(originalStop)
      ? weightedEnergyProfiles[weightedEnergyProfiles.length - 1].profile
          ?.stop_ramp_dur
      : null) || null;

  const requestTypeAndId: IRequestTypeAndId = getLastRequestTypeAndId(
    weightedEnergyProfiles.reduce(
      (accumulator: IEnergyProfile[], item: IWeightedEnergyProfile) =>
        accumulator.concat(item.profile || []),
      [],
    ),
  );

  // assemble our result
  const newProfile: IEnergyProfile = {
    adjusted: adjusted,
    curtailments: curtailments,
    last_request_id: requestTypeAndId.lastRequestId,
    last_request_type: requestTypeAndId.lastRequestType,
    market_level: mktLvl,
    mw: mw,
    profile_id: profileId,
    reliability_limit: reliabilityLimit,
    reloaders: reloaders,
    start_ramp_dur: startRampDur,
    stop_ramp_dur: stopRampDur,
  };

  // If the profile ID is undefined, there were no non-null profiles in the input array, so the scaled profile will be null
  return newProfile.profile_id === undefined ? null : newProfile;
};

const transAllocCombine = (
  weightedTAProfiles: IWeightedTAProfile[],
): IETagTransmissionAllocationProfile | null => {
  let validProfile: IETagTransmissionAllocationProfile =
    {} as IETagTransmissionAllocationProfile;

  weightedTAProfiles.forEach((profile: IWeightedTAProfile) => {
    if (profile.profile !== null && profile.profile !== undefined) {
      validProfile = profile.profile;
    }
  });

  const contractNumber: string | null = validProfile.contract_number || null;

  // if the profileId was undefined, then all profiles were null. Return null to save calculation time=]
  if (contractNumber === undefined || contractNumber === null) {
    return null;
  }

  const transAllocId: number | null = validProfile.trans_alloc_id;
  let mwIsnull: boolean = true;
  const profileMw: number | null = weightedTAProfiles.reduce(
    (accumulator: number, profile: IWeightedTAProfile) => {
      if (
        profile.profile?.profile_mw !== null &&
        profile.profile?.profile_mw !== undefined
      ) {
        mwIsnull = false;
      }

      return accumulator + (profile.profile?.profile_mw || 0) * profile.weight;
    },
    0,
  );
  const lastRequestType: ERequestType | null = validProfile.last_request_type;

  return {
    contract_number: contractNumber,
    trans_alloc_id: transAllocId,
    profile_mw: mwIsnull ? null : Math.round(profileMw),
    last_request_type: lastRequestType,
  };
};

/**
 * Sums an array of Transmission Segment and gives a resulting Transmission Segment.
 */
const transmissionSegmentsCombine = (
  weightedSegments: IWeightedSegments[],
  interval: IInterval,
  originalStart: ZonedDateTime,
  originalStop: ZonedDateTime,
): IETagTransmissionSegment[] => {
  // All lengths are guaranteed to be the same, use the first one
  const newSegments: IETagTransmissionSegment[] = [];

  for (let i = 0; i < weightedSegments[0].segments?.length; i += 1) {
    const physicalSegmentId: number | null =
      weightedSegments[0].segments[i].physical_segment_id;
    const pod: IPointInfo | null = weightedSegments[0].segments[i].pod;
    const por: IPointInfo | null = weightedSegments[0].segments[i].por;
    const tpCode: IEntityInfo | null = weightedSegments[0].segments[i].tp_code;
    const transmissionLimit: IEntityInfo | null = null;

    const podEnergyProfile: IEnergyProfile | null = energyProfileCombine(
      weightedSegments.map((element) => {
        return {
          weight: element.weight,
          profile: element.segments[i].pod_energy_profile,
        };
      }),
      originalStart,
      originalStop,
      interval.start,
      interval.stop,
    );
    const porEnergyProfile: IEnergyProfile | null = energyProfileCombine(
      weightedSegments.map((element) => {
        return {
          weight: element.weight,
          profile: element.segments[i].por_energy_profile,
        };
      }),
      originalStart,
      originalStop,
      interval.start,
      interval.stop,
    );

    const transAllocProfiles: IETagTransmissionAllocationProfile[] | null = [];
    const transAllocIds: number[] = [];
    weightedSegments.forEach((segment: IWeightedSegments) => {
      segment.segments.forEach((segment: IETagTransmissionSegment) => {
        segment.trans_alloc_profiles?.forEach(
          (profile: IETagTransmissionAllocationProfile) => {
            if (
              profile.trans_alloc_id !== null &&
              transAllocIds.indexOf(profile.trans_alloc_id) === -1
            ) {
              transAllocIds.push(profile.trans_alloc_id);
            }
          },
        );
      });
    });

    for (const id of transAllocIds) {
      const tempProfiles: IWeightedTAProfile[] = weightedSegments
        .map((segment: IWeightedSegments) => {
          const relevantTAProfiles:
            | IETagTransmissionAllocationProfile[]
            | undefined = segment.segments[i].trans_alloc_profiles?.filter(
            (profile: IETagTransmissionAllocationProfile) =>
              profile.trans_alloc_id === id,
          );
          if (
            relevantTAProfiles !== undefined &&
            relevantTAProfiles.length === 1
          ) {
            return {
              profile: relevantTAProfiles[0],
              weight: segment.weight,
            };
          } else {
            return undefined;
          }
        })
        .filter((profile: IWeightedTAProfile | undefined) => {
          return profile !== undefined;
        }) as IWeightedTAProfile[];

      const newTransAllocProfile: IETagTransmissionAllocationProfile | null =
        transAllocCombine(tempProfiles);
      if (newTransAllocProfile) {
        transAllocProfiles.push(newTransAllocProfile);
      }
    }

    const newSegment: IETagTransmissionSegment = {
      physical_segment_id: physicalSegmentId,
      pod: pod,
      pod_energy_profile: podEnergyProfile,
      por: por,
      por_energy_profile: porEnergyProfile,
      tp_code: tpCode,
      transmission_limit: transmissionLimit,
      trans_alloc_profiles: transAllocProfiles,
    };
    newSegments.push(newSegment);
  }

  return newSegments;
};
