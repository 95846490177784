import {
  ICsvHeaderData,
  TCsvData,
  TTransmissionAvailabilityColumnDataMap,
} from 'components/molecules/TransmissionCapacityAvailabilityCsvExport/types';
import { BOOLEAN_FALSE_LABEL, BOOLEAN_TRUE_LABEL } from 'constants/misc';
import { CSV_DATE_FORMAT, CSV_DATE_TIME_FORMAT } from 'constants/time';
import { EColumnType } from 'enums/ETag';
import {
  ITransmissionAvailabilityColumnDataAgGrid,
  ITransmissionAvailabilityDataSet,
} from 'interfaces/ETag';
import { IIndexable } from 'interfaces/General';
import { TTimeZone } from 'types/DateTime';
import { isEmptyValue } from 'utils/general';
import { ZonedDateTime } from 'utils/zonedDateTime';

export const transmissionAvailabilityColumnDatasToCsvHeaderData = (
  transmissionAvailabilityColumnDatas: ITransmissionAvailabilityColumnDataAgGrid[],
  allOptionalColumns: string[],
  selectedOptionalColumns: string[] | undefined,
  showExtraHourColumn: boolean,
): ICsvHeaderData => {
  const csvHeaderData: ICsvHeaderData = {
    csvHeaders: [],
    transmissionAvailabilityColumnDataMap: {},
  };

  transmissionAvailabilityColumnDatas.forEach(
    (
      transmissionAvailabilityColumnData: ITransmissionAvailabilityColumnDataAgGrid,
    ) => {
      // Only include the column if it's not optional or is a selected optional column;
      // Also only include the he_02_extra column if it is being used (DST long day)
      if (
        (!allOptionalColumns.includes(
          transmissionAvailabilityColumnData.field,
        ) ||
          selectedOptionalColumns?.includes(
            transmissionAvailabilityColumnData.field,
          )) &&
        !(
          transmissionAvailabilityColumnData.field === 'he_02_extra' &&
          !showExtraHourColumn
        )
      ) {
        // AG Column fields should be unique, so field works as a unique key (similar to dataIndex in antd tables)
        csvHeaderData.csvHeaders.push({
          label: transmissionAvailabilityColumnData.headerName,
          key: transmissionAvailabilityColumnData.field,
        });

        csvHeaderData.transmissionAvailabilityColumnDataMap[
          transmissionAvailabilityColumnData.field
        ] = transmissionAvailabilityColumnData;
      }
    },
  );

  return csvHeaderData;
};

export const transmissionAvailabilityToCsvData = (
  transmissionAvailabilityDataSets: ITransmissionAvailabilityDataSet[],
  transmissionAvailabilityColumnDataMap: TTransmissionAvailabilityColumnDataMap,
  timeZone: TTimeZone,
): TCsvData => {
  const csvData: TCsvData = transmissionAvailabilityDataSets.map(
    (
      dataSet: ITransmissionAvailabilityDataSet & IIndexable,
    ): Record<string, any> => {
      const flattenedDataSet: Record<string, any> = {};

      Object.keys(dataSet).forEach((key: string) => {
        const transmissionAvailabilityColumnData:
          | ITransmissionAvailabilityColumnDataAgGrid
          | undefined = transmissionAvailabilityColumnDataMap[key];
        if (transmissionAvailabilityColumnData !== undefined) {
          switch (transmissionAvailabilityColumnData.type) {
            case EColumnType.Boolean: {
              const value: boolean | null | undefined = dataSet[key];

              if (value === true) {
                flattenedDataSet[key] = BOOLEAN_TRUE_LABEL;
              } else if (value === false) {
                flattenedDataSet[key] = BOOLEAN_FALSE_LABEL;
              }
              break;
            }
            case EColumnType.Date: {
              const value: string | null | undefined = dataSet[key];

              if (!isEmptyValue(value)) {
                flattenedDataSet[key] = ZonedDateTime.parseIso(
                  value!,
                  timeZone,
                ).format(CSV_DATE_FORMAT);
              }
              break;
            }
            case EColumnType.DateTime: {
              const value: string | null | undefined = dataSet[key];

              if (!isEmptyValue(value)) {
                flattenedDataSet[key] = ZonedDateTime.parseIso(
                  value!,
                  timeZone,
                ).format(CSV_DATE_TIME_FORMAT);
              }
              break;
            }
            default: {
              flattenedDataSet[key] = dataSet[key];
              break;
            }
          }
        }
      });

      return flattenedDataSet;
    },
  ) as TCsvData;

  return csvData;
};
