import {
  CARRIAGE_RETURN,
  NEW_LINE_CHARACTER,
  TAB_CHARACTER,
} from 'constants/General';
import {
  IDataGridPosition,
  ISelectedCellsMapDimensions,
} from 'interfaces/Component';
import { IIndexable } from 'interfaces/General';
import { Column } from 'react-data-grid';
import { TSelectedCellsMap, TSelectedCellsRow } from 'types/Component';
import { TDataGridMatrix } from 'types/General';
import { getSelectedCellsMapDimensions, updateColumns } from 'utils/component';
import { isEmptyValue } from 'utils/general';

export const isFocusedOnDataGrid = (
  dataGridElement: HTMLDivElement | null,
  eventTarget?: EventTarget | null,
): boolean =>
  dataGridElement !== null &&
  dataGridElement.contains(
    isEmptyValue(eventTarget)
      ? document.activeElement
      : (eventTarget as Element),
  );

export const getSelectedCellsMap = (
  start: IDataGridPosition,
  end: IDataGridPosition,
): TSelectedCellsMap => {
  const selectedCellsMap: TSelectedCellsMap = {};
  let startIdx: number = start.idx;
  let endIdx: number = end.idx;
  let startRowIdx: number = start.rowIdx;
  let endRowIdx: number = end.rowIdx;

  if (end.idx < start.idx) {
    startIdx = end.idx;
    endIdx = start.idx;
  }

  if (end.rowIdx < start.rowIdx) {
    startRowIdx = end.rowIdx;
    endRowIdx = start.rowIdx;
  }

  for (let i: number = startIdx; i <= endIdx; i += 1) {
    const row: TSelectedCellsRow = {};

    for (let j: number = startRowIdx; j <= endRowIdx; j += 1) {
      row[j] = true;
    }

    selectedCellsMap[i] = row;
  }

  return selectedCellsMap;
};

export const isPositionInSelection = (
  dataGridPosition: IDataGridPosition,
  selectedCellsMap: TSelectedCellsMap,
): boolean => {
  const {
    numSelectedColumns,
    numSelectedRows,
    startPosition,
  }: ISelectedCellsMapDimensions =
    getSelectedCellsMapDimensions(selectedCellsMap);

  return (
    dataGridPosition.idx >= startPosition.idx &&
    dataGridPosition.idx < startPosition.idx + numSelectedColumns &&
    dataGridPosition.rowIdx >= startPosition.rowIdx &&
    dataGridPosition.rowIdx < startPosition.rowIdx + numSelectedRows
  );
};

// Transforming a tab separated list will also remove any excess empty values
// creating the smallest possible matrix which can contain non-empty values.
export const transformTabSeparatedList = (
  tabSeparatedList: string,
): TDataGridMatrix | undefined => {
  const matrix: TDataGridMatrix = [];
  let columnDimension: number = -1;
  let adjustedColumnDimension: number = -1;
  let adjustedRowDimension: number = -1;
  let row: string[] = [];
  let rowContentLength: number = -1;
  let element: string = '';

  for (let i: number = 0; i <= tabSeparatedList.length; i += 1) {
    const character: string = tabSeparatedList[i];

    if (i === tabSeparatedList.length || character === NEW_LINE_CHARACTER) {
      row.push(element);

      if (row.length > columnDimension) {
        columnDimension = row.length;
      }

      if (!isEmptyValue(element)) {
        rowContentLength = row.length;
      }

      matrix.push(row);

      if (rowContentLength > 0) {
        adjustedRowDimension = matrix.length;
      }

      if (rowContentLength > adjustedColumnDimension) {
        adjustedColumnDimension = rowContentLength;
      }

      row = [];
      rowContentLength = -1;
      element = '';
    } else if (character === TAB_CHARACTER) {
      row.push(element);

      if (row.length > columnDimension) {
        columnDimension = row.length;
      }

      if (!isEmptyValue(element)) {
        rowContentLength = row.length;
      }

      element = '';
    } else if (character !== CARRIAGE_RETURN) {
      element += character;
    }
  }

  if (adjustedColumnDimension === -1 || adjustedRowDimension === -1) {
    return undefined;
  }

  const adjustedMatrix: TDataGridMatrix = new Array(adjustedRowDimension);

  for (let i: number = 0; i < adjustedRowDimension; i += 1) {
    const adjustedRow: string[] = new Array(adjustedColumnDimension).fill('');
    const row: string[] = matrix[i];

    for (let j: number = 0; j < adjustedColumnDimension; j += 1) {
      const value: string | undefined = row[j];
      adjustedRow[j] = value === undefined ? '' : value;
    }

    adjustedMatrix[i] = adjustedRow;
  }

  return adjustedMatrix;
};

export const transformDataGridMatrix = (
  dataGridMatrix: TDataGridMatrix,
): string => {
  if (dataGridMatrix.length === 0) {
    throw new Error('Invalid matrix dimensions');
  }

  const rowDimension: number = dataGridMatrix[0].length;
  let tabSeparatedList: string = '';

  dataGridMatrix.forEach((row: string[], rowIndex: number) => {
    if (row.length !== rowDimension) {
      throw new Error('Invalid matrix dimensions');
    }

    row.forEach((element: string, columnIndex: number) => {
      tabSeparatedList += element;

      if (columnIndex < row.length - 1) {
        tabSeparatedList += TAB_CHARACTER;
      }
    });

    if (rowIndex < dataGridMatrix.length - 1) {
      tabSeparatedList += NEW_LINE_CHARACTER;
    }
  });

  return tabSeparatedList;
};

export const transformSelectedCellsMap = <
  C extends Column<R, S>,
  R extends IIndexable,
  S extends IIndexable,
  T,
>(
  selectedCellsMap: TSelectedCellsMap,
  columns: C[],
  rows: R[],
  cellToString: (
    cell: T | null | undefined,
    useNullCellValue?: boolean,
  ) => string,
): TDataGridMatrix => {
  const matrix: TDataGridMatrix = [];
  const selectedColumnKeys: string[] = Object.keys(selectedCellsMap);
  let useNullCellValue: boolean = false;

  selectedColumnKeys.forEach(
    (selectedColumnKey: string, selectedColumnIndex: number) => {
      const selectedColumnKeyIndex: number = parseInt(selectedColumnKey, 10);

      if (selectedColumnKeyIndex < columns.length) {
        const columnKey: string = columns[selectedColumnKeyIndex].key;
        const selectedRow: TSelectedCellsRow =
          selectedCellsMap[selectedColumnKeyIndex];
        const selectedRowKeys: string[] = Object.keys(selectedRow);

        if (matrix.length === 0) {
          for (let i: number = 0; i < selectedRowKeys.length; i += 1) {
            matrix.push(new Array<string>(selectedColumnKeys.length));
          }

          useNullCellValue =
            selectedColumnKeys.length === 1 && selectedRowKeys.length === 1;
        }

        selectedRowKeys.forEach(
          (selectedRowKey: string, selectedRowIndex: number) => {
            const selectedRowKeyIndex: number = parseInt(selectedRowKey, 10);
            if (selectedRowKeyIndex < rows.length) {
              const row: R = rows[selectedRowKeyIndex];

              matrix[selectedRowIndex][selectedColumnIndex] = cellToString(
                row[columnKey],
                useNullCellValue,
              );
            }
          },
        );
      }
    },
  );

  // Ensure we always have a valid matrix
  if (matrix.length === 0) {
    matrix.push([]);
  }

  return matrix;
};

export const updateRowsWithDataGridMatrix = <
  C extends Column<R, S>,
  R extends IIndexable,
  S extends IIndexable,
>(
  matrix: TDataGridMatrix,
  selectedCells: TSelectedCellsMap,
  columns: C[],
  rows: R[],
  updateRowWithValue?: (
    value: string,
    targetColumnKey: string,
    targetRow: R,
  ) => R,
  updateExtraRows?: (
    updatedRowsLength: number,
    startPosition: IDataGridPosition,
    endPosition: IDataGridPosition,
    extraRowsEndIdx: number,
    columns: C[],
    matrix: TDataGridMatrix,
  ) => R[],
) => {
  const updatedRows: R[] = [...rows];
  const numMatrixRows: number = matrix.length;
  const numMatrixColumns: number = matrix[0].length;
  const updateSingle: string | undefined =
    numMatrixRows === 1 && numMatrixColumns === 1 ? matrix[0][0] : undefined;
  const {
    numSelectedColumns,
    numSelectedRows,
    startPosition,
  }: ISelectedCellsMapDimensions = getSelectedCellsMapDimensions(selectedCells);
  const endPosition: IDataGridPosition = {
    idx:
      startPosition.idx +
      (updateSingle ? numSelectedColumns : numMatrixColumns),
    rowIdx:
      startPosition.rowIdx + (updateSingle ? numSelectedRows : numMatrixRows),
  };
  let hasTooManyColumns: boolean = false;
  let hasTooManyRows: boolean = false;
  let extraRowsEndIdx: number = -1;
  let extraRows: R[] | undefined = undefined;

  if (endPosition.idx > columns.length) {
    hasTooManyColumns = true;
    endPosition.idx = columns.length;
  }

  if (endPosition.rowIdx > rows.length) {
    hasTooManyRows = true;
    extraRowsEndIdx = endPosition.rowIdx;
    endPosition.rowIdx = rows.length;
  }

  // Positions are 0-indexed
  endPosition.idx -= 1;
  endPosition.rowIdx -= 1;

  for (
    let rowIdx: number = startPosition.rowIdx;
    rowIdx <= endPosition.rowIdx;
    rowIdx += 1
  ) {
    updateColumns<C, R, S>(
      matrix,
      columns,
      updatedRows,
      rowIdx,
      startPosition.rowIdx,
      startPosition.idx,
      endPosition.idx,
      updateRowWithValue,
      updateSingle,
    );
  }

  if (hasTooManyRows && updateExtraRows !== undefined) {
    extraRows = updateExtraRows(
      updatedRows.length,
      startPosition,
      endPosition,
      extraRowsEndIdx,
      columns,
      matrix,
    );
  }

  return {
    endPosition,
    extraRows,
    hasTooManyColumns,
    hasTooManyRows,
    startPosition,
    updatedRows,
  };
};
