import { Dropdown as AntDesignDropdown, Menu as AntDesignMenu } from 'antd';
import Button from 'components/atoms/Button/Button';
import Input from 'components/atoms/Input/Input';
import Tooltip from 'components/molecules/Tooltip/Tooltip';
import { ETAG_FILTER_DETAIL_MAP } from 'constants/ETag';
import {
  COLUMN_LAYOUT_SHARED_STYLES,
  FILTER_BUTTON_WIDTH_VALUE,
  FILTER_LAYOUT_BOTTOM_MARGIN_VALUE,
  FILTER_LAYOUT_RIGHT_MARGIN_VALUE,
  HIGHLIGHT_BLUE,
} from 'constants/styles';
import {
  EFilterMiscKey,
  EFilterMiscType,
  EFilterStringType,
} from 'enums/Filter';
import { IFilterDetail, IFilterMisc } from 'interfaces/Filter';
import { MenuInfo } from 'rc-menu/lib/interface';
import { ChangeEvent, HTMLAttributes } from 'react';
import styled from 'styled-components';
import { isEmptyValue } from 'utils/general';

const RowLayout = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;

  > :not(:last-child) {
    margin-right: ${FILTER_LAYOUT_RIGHT_MARGIN_VALUE}px;
  }
`;

const Inputs = styled.div`
  ${COLUMN_LAYOUT_SHARED_STYLES}

  > :not(:last-child) {
    margin-bottom: ${FILTER_LAYOUT_BOTTOM_MARGIN_VALUE}px;
  }
`;

interface IStyledInputProps {
  width: number;
}

const StyledInput = styled(Input)<IStyledInputProps>`
  width: ${(props) => props.width}px;
`;

interface IFilterButtonWrapperProps extends HTMLAttributes<HTMLButtonElement> {
  active?: boolean;
}

const FilterButtonWrapper = styled.span<IFilterButtonWrapperProps>`
  > button {
    font-size: 9px;
    padding: 0;
    width: ${FILTER_BUTTON_WIDTH_VALUE}px;

    ${(props) =>
      props.active
        ? `
        color: ${HIGHLIGHT_BLUE};
        font-weight: bold;
      `
        : ''};
  }
`;

const OrButtonWrapper = styled.span`
  > button {
    font-size: 9px;
    padding: 0;
    width: ${FILTER_BUTTON_WIDTH_VALUE}px;
  }
`;

interface IETagFilterMiscProps {
  filterMisc: IFilterMisc;
  inputWidth: number;
  isAddDisabled?: boolean;
  isDisabled?: boolean;
  onAdd: () => void;
  onChange: (filterMisc: IFilterMisc) => void;
  onRemove: () => void;
}

const ETagFilterMisc = ({
  filterMisc,
  inputWidth,
  isAddDisabled,
  isDisabled,
  onAdd,
  onChange,
  onRemove,
}: IETagFilterMiscProps): JSX.Element => {
  const handleFilterSelect = (menuInfo: MenuInfo) => {
    const filter_type: EFilterMiscType = menuInfo.key as EFilterMiscType;
    const updatedFilterMisc: IFilterMisc = { ...filterMisc, filter_type };

    if (filter_type === EFilterMiscType.AnyValue) {
      updatedFilterMisc.value = null;
    }

    onChange(updatedFilterMisc);
  };

  const handleInput =
    (filterMiscKey: EFilterMiscKey) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      // NOTE: Since there is no clear handler on the Ant Design Input component
      // we have to rely on the internal workings of the component. There is a
      // slight distinction between the events that are passed to this handler.
      // If the user clicks the clear icon then the type of the event is 'click',
      // while if the user changes the text value then the type of the event is
      // 'change'. We use this distinction to determine if we should trigger an
      // onRemove call or an onChange call.
      if (event.type === 'click') {
        onRemove();
      } else {
        onChange({
          ...filterMisc,
          [filterMiscKey]: event.target.value,
        });
      }
    };

  const tokenFilterDetail: IFilterDetail =
    ETAG_FILTER_DETAIL_MAP[EFilterStringType.Equals];

  const TokenFilterMenu: JSX.Element = (
    <AntDesignMenu>
      <AntDesignMenu.Item>{tokenFilterDetail.description}</AntDesignMenu.Item>
    </AntDesignMenu>
  );

  const ValueFilterMenu: JSX.Element = (
    <AntDesignMenu onClick={handleFilterSelect}>
      {Object.keys(ETAG_FILTER_DETAIL_MAP)
        .filter((key: string): boolean => key in EFilterMiscType)
        .map(
          (key: string): JSX.Element => (
            <AntDesignMenu.Item key={key}>
              {ETAG_FILTER_DETAIL_MAP[key].description}
            </AntDesignMenu.Item>
          ),
        )}
    </AntDesignMenu>
  );

  const valueFitlerDetail: IFilterDetail =
    ETAG_FILTER_DETAIL_MAP[filterMisc.filter_type];

  const isEmptyToken: boolean = isEmptyValue(filterMisc.token);

  return (
    <RowLayout>
      <Inputs>
        <RowLayout>
          <Tooltip
            isDisabled={isDisabled}
            title={tokenFilterDetail.description}
          >
            <AntDesignDropdown
              disabled={isDisabled}
              overlay={TokenFilterMenu}
              trigger={['click']}
            >
              <FilterButtonWrapper active={true}>
                <Button
                  isDisabled={isDisabled}
                  label={tokenFilterDetail.label}
                />
              </FilterButtonWrapper>
            </AntDesignDropdown>
          </Tooltip>
          <StyledInput
            allowClear={true}
            isDisabled={isDisabled}
            onChange={handleInput(EFilterMiscKey.Token)}
            placeholder={isDisabled ? undefined : 'Token'}
            value={filterMisc.token === null ? undefined : filterMisc.token}
            width={inputWidth}
          />
        </RowLayout>
        <RowLayout>
          <Tooltip
            isDisabled={isDisabled || isEmptyToken}
            title={valueFitlerDetail.description}
          >
            <AntDesignDropdown
              disabled={isDisabled || isEmptyToken}
              overlay={ValueFilterMenu}
              trigger={['click']}
            >
              <FilterButtonWrapper active={true}>
                <Button
                  isDisabled={isDisabled || isEmptyToken}
                  label={valueFitlerDetail.label}
                />
              </FilterButtonWrapper>
            </AntDesignDropdown>
          </Tooltip>
          <StyledInput
            allowClear={true}
            isDisabled={
              isDisabled ||
              isEmptyToken ||
              filterMisc.filter_type === EFilterMiscType.AnyValue
            }
            onChange={handleInput(EFilterMiscKey.Value)}
            placeholder={isDisabled ? undefined : 'Value'}
            value={filterMisc.value === null ? undefined : filterMisc.value}
            width={inputWidth}
          />
        </RowLayout>
      </Inputs>
      {!isAddDisabled && isEmptyToken ? null : (
        <OrButtonWrapper>
          <Button
            isDisabled={isDisabled || isAddDisabled || isEmptyToken}
            label='or'
            onClick={onAdd}
          />
        </OrButtonWrapper>
      )}
    </RowLayout>
  );
};

export default ETagFilterMisc;
