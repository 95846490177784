import { ROUTES } from 'components/App/constants';
import { IRoute } from 'components/App/types';
import LandingPage from 'components/pages/LandingPage/LandingPage';
import { IExternalLink } from 'interfaces/General';
import { ReactNode } from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';

interface IRoutesProps {
  appActions: ReactNode;
  setIsLightMode: (isLightMode: boolean) => void;
  setUserGuideExternalLink: (
    userGuideExternalLinkLocation: IExternalLink | undefined,
  ) => void;
}

const Routes = ({
  appActions,
  setIsLightMode,
  setUserGuideExternalLink,
}: IRoutesProps): JSX.Element => {
  return (
    <Switch>
      {ROUTES.map(
        (route: IRoute): JSX.Element => (
          <Route
            exact={route.exact}
            key={route.name}
            path={route.path}
            render={(props: RouteComponentProps): JSX.Element => (
              <route.component
                {...props}
                appActions={appActions}
                setIsLightMode={setIsLightMode}
                setUserGuideExternalLink={setUserGuideExternalLink}
              />
            )}
          />
        ),
      )}
      <Route
        appActions={appActions}
        key='Landing'
        path='*'
        render={(props: RouteComponentProps): JSX.Element => (
          <LandingPage
            {...props}
            appActions={appActions}
            setIsLightMode={setIsLightMode}
            setUserGuideExternalLink={setUserGuideExternalLink}
          />
        )}
      />
    </Switch>
  );
};

export default Routes;
