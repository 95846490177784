import MeasureContent from 'components/molecules/ScrollableContent/MeasureContent';
import { CSSProperties, ReactNode, useState } from 'react';
import ScrollbarsCustom from 'react-scrollbars-custom';

interface IProps {
  children?: ReactNode;
  maximumHeight?: string | number;
  maximumWidth?: string | number;
  onResize?: (width?: number, height?: number) => void;
}

const ScrollableContent = (props: IProps): JSX.Element => {
  const { children, maximumHeight, maximumWidth, onResize } = props;
  const [contentHeight, setContentHeight] = useState<number | undefined>(
    undefined,
  );
  const [contentWidth, setContentWidth] = useState<number | undefined>(
    undefined,
  );

  const handleResize = (width?: number, height?: number) => {
    setContentWidth(width);
    setContentHeight(height);

    if (onResize) {
      onResize(width, height);
    }
  };

  // To prevent some straddle cases in scrollable content, we include an
  // addditional pixel to both the height and width.
  const scrollbarsCustomStyle: CSSProperties | undefined =
    contentHeight !== undefined && contentWidth !== undefined
      ? {
          height: contentHeight + 1,
          width: contentWidth + 1,
          maxHeight: maximumHeight,
          maxWidth: maximumWidth,
        }
      : undefined;

  return (
    <>
      <ScrollbarsCustom
        disableTracksWidthCompensation={true}
        noDefaultStyles={true}
        style={scrollbarsCustomStyle}
      >
        {children}
      </ScrollbarsCustom>
      <MeasureContent onResize={handleResize}>{children}</MeasureContent>
    </>
  );
};

export default ScrollableContent;
