import Button from 'components/atoms/Button/Button';
import Checkbox from 'components/atoms/Checkbox/Checkbox';
import { STANDARD_SPACING } from 'constants/styles';
import styled from 'styled-components';

const SkipValidation = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: ${STANDARD_SPACING};

  > :not(:last-child) {
    margin-right: ${STANDARD_SPACING};
  }
`;

interface IETagRequestFormFooterProps {
  isSaveAction?: boolean;
  handleValidate: () => Promise<void>;
  handleValidateAndSubmit: () => Promise<void>;
  isDisabled: boolean;
  isValidateAndSubmitDisabled?: boolean;
  isValidateDisabled?: boolean;
  isValidateHidden?: boolean;
  requestType: string;
  setSkipValidation: (skipValidation: boolean) => void;
  skipValidation: boolean;
  validationErrors: string[];
}

export const ETagRequestFormFooter = (
  props: IETagRequestFormFooterProps,
): JSX.Element => {
  const {
    isSaveAction,
    handleValidate,
    handleValidateAndSubmit,
    isDisabled,
    isValidateAndSubmitDisabled,
    isValidateDisabled,
    isValidateHidden,
    requestType,
    setSkipValidation,
    skipValidation,
  } = props;

  const validateLabel = `${
    isSaveAction ? 'Save and ' : ''
  }Validate ${requestType} Request`;

  const validateAndSubmitLabel = `${
    skipValidation
      ? isSaveAction
        ? 'Save and Submit'
        : 'Submit'
      : isSaveAction
      ? 'Save, Validate and Submit'
      : 'Validate and Submit'
  } ${requestType} Request`;

  return (
    <>
      <SkipValidation>
        <Checkbox
          checked={skipValidation}
          isDisabled={isDisabled}
          label='Skip validation'
          onChange={setSkipValidation}
        />
      </SkipValidation>
      <Actions>
        {!isValidateHidden ? (
          <Button
            isDisabled={isDisabled || isValidateDisabled}
            label={validateLabel}
            onClick={handleValidate}
          />
        ) : null}
        <Button
          isDisabled={isDisabled || isValidateAndSubmitDisabled}
          label={validateAndSubmitLabel}
          onClick={handleValidateAndSubmit}
        />
      </Actions>
    </>
  );
};
