import {
  Button as AntDesignButton,
  Input as AntDesignInput,
  InputNumber as AntDesignInputNumber,
  Popover as AntDesignPopover,
} from 'antd';
import SeparatedRowLayout from 'components/atoms/SeparatedRowLayout/SeparatedRowLayout';
import { COLUMN_LAYOUT_SHARED_STYLES } from 'constants/styles';
import { IOffset } from 'interfaces/General';
import { ChangeEvent, useCallback, useState } from 'react';
import styled from 'styled-components';
import { TErrorMessage } from 'types/Error';
import { TPlacement } from 'types/General';
import { sleep } from 'utils/general';

const { TextArea: AntDesignTextArea } = AntDesignInput;

export interface IEchoPopoverConfig {
  buttonLabel: string;
  popoverButtonOffset?: IOffset;
  popoverPlacement?: TPlacement;
}

const EchoArea = styled.div`
  ${COLUMN_LAYOUT_SHARED_STYLES}

  align-items: flex-end;
  padding: 10px;
  width: 600px;

  > :not(:last-child) {
    margin-bottom: 10px;
  }
`;

const ErrorSpan = styled.span`
  color: red;
`;

const FeedbackSpan = styled.span`
  color: green;
`;

interface IPopoverWrapperProps {
  positionOffset?: IOffset;
}

const PopoverWrapper = styled.div<IPopoverWrapperProps>`
  position: absolute;

  ${(props) =>
    props.positionOffset === undefined ? '' : { ...props.positionOffset }}
`;

interface IProps<T> {
  config: IEchoPopoverConfig;
  error: TErrorMessage;
  feedback: string | null;
  isConnected: boolean;
  messageDeserializer: (data: string) => T;
  messageHandler: (message: T) => void;
  postMessage: (message: T, sendDelayInMilliseconds?: number) => void;
}

const EchoPopover = <T extends any>(props: IProps<T>): JSX.Element => {
  const {
    config,
    error,
    feedback,
    isConnected,
    messageDeserializer,
    messageHandler,
    postMessage,
  } = props;
  const { buttonLabel, popoverButtonOffset, popoverPlacement } = config;
  const [echo, setEcho] = useState<string>('');
  const [sendDelayInMilliseconds, setSendDelayInMilliseconds] =
    useState<number>(0);
  const [sendRepeatCount, setSendRepeatCount] = useState<number>(1);
  const [localError, setLocalError] = useState<TErrorMessage>(null);

  const handleMessageChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      setEcho(event.target.value);
    },
    [],
  );

  const handleDelayChange = useCallback((value: string | number) => {
    let valueNumber: number = 0;

    if (typeof value === 'string') {
      valueNumber = parseInt(value, 10);
    } else {
      valueNumber = value;
    }

    setSendDelayInMilliseconds(valueNumber);
  }, []);

  const handleRepeatChange = useCallback((value: string | number) => {
    let valueNumber: number = 0;

    if (typeof value === 'string') {
      valueNumber = parseInt(value, 10);
    } else {
      valueNumber = value;
    }

    setSendRepeatCount(valueNumber);
  }, []);

  const handleSendDirect = useCallback(async () => {
    setLocalError(null);

    if (echo !== undefined) {
      for (let i: number = 0; i < sendRepeatCount; i += 1) {
        try {
          if (sendDelayInMilliseconds > 0) {
            await sleep(sendDelayInMilliseconds);
          }

          messageHandler(messageDeserializer(echo));
        } catch (error: any) {
          setLocalError(`Deserialization error: ${error}`);
        }
      }
    }
  }, [
    echo,
    messageDeserializer,
    messageHandler,
    sendDelayInMilliseconds,
    sendRepeatCount,
  ]);

  const handleSend = useCallback(async () => {
    setLocalError(null);

    if (echo !== undefined) {
      for (let i: number = 0; i < sendRepeatCount; i += 1) {
        try {
          if (sendDelayInMilliseconds > 0) {
            await sleep(sendDelayInMilliseconds);
          }

          postMessage(messageDeserializer(echo));
        } catch (error: any) {
          setLocalError(`Deserialization error: ${error}`);
        }
      }
    }
  }, [
    echo,
    messageDeserializer,
    postMessage,
    sendDelayInMilliseconds,
    sendRepeatCount,
  ]);

  return (
    <PopoverWrapper positionOffset={popoverButtonOffset}>
      <AntDesignPopover
        content={
          <EchoArea>
            <AntDesignTextArea
              onChange={handleMessageChange}
              placeholder='ETag Message'
              rows={11}
              value={echo}
            />
            <SeparatedRowLayout>
              <div>Send delay in milliseconds</div>
              <AntDesignInputNumber
                onChange={handleDelayChange}
                min={0}
                value={sendDelayInMilliseconds}
              />
              <div>Send repeat count</div>
              <AntDesignInputNumber
                onChange={handleRepeatChange}
                min={1}
                step={1}
                value={sendRepeatCount}
              />
            </SeparatedRowLayout>
            {localError === null ? null : <ErrorSpan>{localError}</ErrorSpan>}
            {error === null ? null : <ErrorSpan>{error}</ErrorSpan>}
            {feedback === null ? null : <FeedbackSpan>{feedback}</FeedbackSpan>}
            <SeparatedRowLayout>
              <AntDesignButton onClick={handleSendDirect}>
                Send Direct
              </AntDesignButton>
              <AntDesignButton
                disabled={!isConnected}
                loading={!isConnected}
                onClick={handleSend}
                type='primary'
              >
                {isConnected ? 'Send' : 'Connecting...'}
              </AntDesignButton>
            </SeparatedRowLayout>
          </EchoArea>
        }
        placement={popoverPlacement}
        trigger='click'
      >
        <AntDesignButton>{buttonLabel}</AntDesignButton>
      </AntDesignPopover>
    </PopoverWrapper>
  );
};

export default EchoPopover;
