import IconButton from 'components/atoms/IconButton/IconButton';
import EditorFooter from 'components/molecules/EditorFooter/EditorFooter';
import Modal from 'components/molecules/Modal/Modal';
import Tooltip from 'components/molecules/Tooltip/Tooltip';
import { IModalHandlers } from 'interfaces/Component';
import { IToEntity } from 'interfaces/ToEntity';
import { useEffect, useState } from 'react';
import { TErrorMessage } from 'types/Error';
import { TPlacement } from 'types/General';
import { encodeIds } from 'utils/general';

export interface ITemplateConfiguratorProps {
  isDisabled?: boolean;
  setHandlers: (handlers: IModalHandlers) => void;
}

// Props
interface IProps<T> {
  Configurator: (props: T & ITemplateConfiguratorProps) => JSX.Element;
  configuratorProps: T;
  encodedPermissionsId: string;
  icon: JSX.Element;
  isDisabled?: boolean;
  label: string;
  modalWidth: number;
  onSaveFilter: () => void;
  toEntity: IToEntity;
  tooltipPlacement?: TPlacement;
}

const TemplateConfiguration = <T extends any>(
  props: IProps<T>,
): JSX.Element => {
  const {
    Configurator,
    configuratorProps,
    encodedPermissionsId,
    icon,
    isDisabled,
    label,
    modalWidth,
    onSaveFilter,
    toEntity,
    tooltipPlacement,
  } = props;
  const [showModal, setShowModal] = useState<boolean>(false);
  const [handlers, setHandlers] = useState<IModalHandlers>({});

  const [errorMessage, setErrorMessage] = useState<TErrorMessage>(null);

  useEffect(() => {
    if (!showModal) {
      setErrorMessage(null);
    }
  }, [showModal]);

  const handleShow = () => {
    setShowModal(true);
    setHandlers({});
  };

  const handleCancel = async () => {
    if (handlers.cancelHandler !== undefined) {
      handlers.cancelHandler();
    }

    setShowModal(false);
  };

  const handleSave = async () => {
    onSaveFilter();
    setShowModal(false);
  };

  const title = `${toEntity.entity_code} ${label} configurator`;

  return (
    <>
      <Tooltip
        isDisabled={showModal}
        placement={tooltipPlacement}
        title={title}
      >
        <IconButton icon={icon} isDisabled={isDisabled} onClick={handleShow} />
      </Tooltip>
      <Modal
        footer={
          <EditorFooter
            confirmLabel='Apply'
            errorMessage={errorMessage}
            encodedPermissionsId={encodeIds([encodedPermissionsId, 'footer'])}
            onCancel={handleCancel}
            onConfirm={handleSave}
          />
        }
        isVisible={showModal}
        onCancel={handleCancel}
        title={title}
        width={modalWidth}
      >
        <Configurator
          {...configuratorProps}
          isDisabled={isDisabled}
          setHandlers={setHandlers}
        />
      </Modal>
    </>
  );
};

export default TemplateConfiguration;
