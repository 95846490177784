import { CopyOutlined } from '@ant-design/icons';
import { Popover as AntDesignPopover } from 'antd';
import IconButton from 'components/atoms/IconButton/IconButton';
import ToEntityETagTemplateCopyPopover from 'components/molecules/ToEntityETagTemplateCopy/ToEntityETagTemplateCopyPopover';
import Tooltip from 'components/molecules/Tooltip/Tooltip';
import { BUTTON_ICON_DIMENSIONS } from 'constants/styles';
import { IETagTemplate } from 'interfaces/ETag';
import { useState } from 'react';
import styled from 'styled-components';
import { TTimeZone } from 'types/DateTime';
import { TToEntityId } from 'types/ToEntity';
import { encodeIds } from 'utils/general';

const CopyIcon = styled(CopyOutlined)`
  ${BUTTON_ICON_DIMENSIONS}
`;

interface IToEntityETagTemplateCopyProps {
  encodedPermissionsId: string;
  eTagTemplate: IETagTemplate | undefined;
  timeZone: TTimeZone;
  toEntityId: TToEntityId;
}

const ToEntityETagTemplateCopy = (
  props: IToEntityETagTemplateCopyProps,
): JSX.Element => {
  const { encodedPermissionsId, eTagTemplate, timeZone, toEntityId } = props;
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const isDisabled: boolean = eTagTemplate === undefined;

  return (
    <Tooltip isDisabled={isDisabled || isVisible} title='Copy Template'>
      <AntDesignPopover
        content={
          <ToEntityETagTemplateCopyPopover
            eTagTemplate={eTagTemplate}
            timeZone={timeZone}
            toEntityId={toEntityId}
          />
        }
        destroyTooltipOnHide={true}
        onVisibleChange={setIsVisible}
        placement='bottom'
        trigger='click'
        visible={isVisible}
      >
        <IconButton
          encodedPermissionsId={encodeIds([encodedPermissionsId], toEntityId)}
          icon={<CopyIcon />}
          isDisabled={isDisabled}
        />
      </AntDesignPopover>
    </Tooltip>
  );
};

export default ToEntityETagTemplateCopy;
