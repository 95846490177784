import EditableDataTable from 'components/molecules/EditableDataTable/EditableDataTable';
import useExpandableMiscInfos from 'hooks/useExpandableMiscInfos';
import { IETagTransmissionAllocation } from 'interfaces/ETag';
import { IViewDataTableColumn } from 'interfaces/View';
import { useCallback, useMemo } from 'react';
import { getOasisInfoColumns } from 'utils/OasisInfo';

interface IOasisInfoDataTableProps {
  transmissionAllocations: IETagTransmissionAllocation[] | null;
}

const OasisInfoDataTable = ({
  transmissionAllocations,
}: IOasisInfoDataTableProps): JSX.Element => {
  const getOasisRowKey = useCallback(
    (record: IETagTransmissionAllocation): string =>
      record.trans_alloc_id.toString(),
    [],
  );

  const {
    expandableConfig: oasisExpandableConfig,
    updateRowMiscInfosExpandedKey: oasisUpdateRowMiscInfosExpandedKey,
  } = useExpandableMiscInfos(getOasisRowKey, true);

  const handleOasisExpandMiscInfos = useCallback(
    (record: IETagTransmissionAllocation) => {
      oasisUpdateRowMiscInfosExpandedKey(record);
    },
    [oasisUpdateRowMiscInfosExpandedKey],
  );

  const oasisInfoColumns: IViewDataTableColumn<IETagTransmissionAllocation>[] =
    useMemo(
      () => getOasisInfoColumns(true, handleOasisExpandMiscInfos),
      [handleOasisExpandMiscInfos],
    );

  const adjustedTransmissionAllocations: IETagTransmissionAllocation[] =
    useMemo(
      () => (transmissionAllocations === null ? [] : transmissionAllocations),
      [transmissionAllocations],
    );

  return (
    <EditableDataTable
      columns={oasisInfoColumns}
      data={adjustedTransmissionAllocations}
      expandable={oasisExpandableConfig}
      hideExpandIconColumn={true}
      maximumAllowableAdds={0}
      pagination={false}
      rowKey='trans_alloc_id'
    />
  );
};

export default OasisInfoDataTable;
