import { EFilterMiscType, EFilterStringType } from 'enums/Filter';
import { ICustomFilter, IFilterMisc, IFilterString } from 'interfaces/Filter';
import {
  TBlankFilter,
  TStringFilter,
  TThreeDateTimeFilter,
  TThreeNumberFilter,
  TTwoDateTimeFilter,
  TTwoNumberFilter,
} from 'types/AdHocFilter';
import { TGranularity } from 'types/DateTime';
import { TFilterId } from 'types/Filter';
import { ZonedDateTime } from 'utils/zonedDateTime';

export const containsFilter: TStringFilter =
  (content: string) => (value: string) =>
    value.toLocaleLowerCase().includes(content.toLocaleLowerCase());
export const doesNotContainFilter: TStringFilter =
  (content: string) => (value: string) =>
    !value.toLocaleLowerCase().includes(content.toLocaleLowerCase());

export const startsWithFilter: TStringFilter =
  (content: string) => (value: string) =>
    value.toLocaleLowerCase().startsWith(content.toLocaleLowerCase());
export const doesNotStartWithFilter: TStringFilter =
  (content: string) => (value: string) =>
    !value.toLocaleLowerCase().startsWith(content.toLocaleLowerCase());

export const endsWithFilter: TStringFilter =
  (content: string) => (value: string) =>
    value.toLocaleLowerCase().endsWith(content.toLocaleLowerCase());
export const doesNotEndWithFilter: TStringFilter =
  (content: string) => (value: string) =>
    !value.toLocaleLowerCase().endsWith(content.toLocaleLowerCase());

export const equalsFilter: TStringFilter =
  (content: string) => (value: string) =>
    value === content;
export const doesNotEqualFilter: TStringFilter =
  (content: string) => (value: string) =>
    value !== content;

export const caseInsensitiveEqualsFilter: TStringFilter =
  (content: string) => (value: string) =>
    value.toLocaleUpperCase() === content.toLocaleUpperCase();
export const caseInsensitiveDoesNotEqualFilter: TStringFilter =
  (content: string) => (value: string) =>
    value.toLocaleUpperCase() !== content.toLocaleUpperCase();

export const isOneOfFilter =
  <T>(options: T[]) =>
  (value: T) =>
    options.includes(value);

export const isNotOneOfFilter =
  <T>(options: T[]) =>
  (value: T) =>
    !options.includes(value);

export const isBlankFilter: TBlankFilter = (value: string | undefined | null) =>
  value === '' || value === undefined || value === null;
export const isNotBlankFilter: TBlankFilter = (
  value: string | undefined | null,
) => !(value === '' || value === undefined || value === null);

export const isLessThanFilter: TTwoNumberFilter =
  (valueB: number) => (valueA: number) =>
    valueA < valueB;
export const isLessThanOrEqualFilter: TTwoNumberFilter =
  (valueB: number) => (valueA: number) =>
    valueA <= valueB;

export const isGreaterThanFilter: TTwoNumberFilter =
  (valueB: number) => (valueA: number) =>
    valueA > valueB;
export const isGreaterThanOrEqualFilter: TTwoNumberFilter =
  (valueB: number) => (valueA: number) =>
    valueA >= valueB;

export const isBetweenFilter: TThreeNumberFilter =
  (valueB: number) => (valueC: number) => (valueA: number) =>
    valueB < valueA && valueA < valueC;
export const inclusiveIsBetweenFilter: TThreeNumberFilter =
  (valueB: number) => (valueC: number) => (valueA: number) =>
    valueB <= valueA && valueA <= valueC;

export const numberEqualsFilter: TTwoNumberFilter =
  (valueB: number) => (valueA: number) =>
    valueA === valueB;

export const numberDoesNotEqualToFilter: TTwoNumberFilter =
  (valueB: number) => (valueA: number) =>
    valueA !== valueB;

export const isSameFilter: TTwoDateTimeFilter =
  (valueB: ZonedDateTime, unit: TGranularity) => (valueA: string) =>
    valueB.isSame(ZonedDateTime.parseIso(valueA, valueB.timeZone()), unit);

export const isBeforeFilter: TTwoDateTimeFilter =
  (valueB: ZonedDateTime, unit: TGranularity) => (valueA: string) =>
    valueB.isAfter(ZonedDateTime.parseIso(valueA, valueB.timeZone()), unit);

export const isSameOrBeforeFilter: TTwoDateTimeFilter =
  (valueB: ZonedDateTime, unit: TGranularity) => (valueA: string) =>
    valueB.isSameOrAfter(
      ZonedDateTime.parseIso(valueA, valueB.timeZone()),
      unit,
    );

export const isAfterFilter: TTwoDateTimeFilter =
  (valueB: ZonedDateTime, unit: TGranularity) => (valueA: string) =>
    valueB.isBefore(ZonedDateTime.parseIso(valueA, valueB.timeZone()), unit);

export const isSameOrAfterFilter: TTwoDateTimeFilter =
  (valueB: ZonedDateTime, unit: TGranularity) => (valueA: string) =>
    valueB.isSameOrBefore(
      ZonedDateTime.parseIso(valueA, valueB.timeZone()),
      unit,
    );

export const isWithinFilter: TThreeDateTimeFilter =
  (valueB: ZonedDateTime, unit: TGranularity) =>
  (valueC: ZonedDateTime) =>
  (valueA: string) => {
    const valueAParsed = ZonedDateTime.parseIso(valueA, valueB.timeZone());
    return (
      valueB.isBefore(valueAParsed, unit) && valueC.isAfter(valueAParsed, unit)
    );
  };

export const inclusiveIsWithinFilter: TThreeDateTimeFilter =
  (valueB: ZonedDateTime, unit: TGranularity) =>
  (valueC: ZonedDateTime) =>
  (valueA: string) => {
    const valueAParsed = ZonedDateTime.parseIso(valueA, valueB.timeZone());
    return (
      valueB.isSameOrBefore(valueAParsed, unit) &&
      valueC.isSameOrAfter(valueAParsed, unit)
    );
  };

export const generateNewFilterString = (): IFilterString => ({
  filter_type: EFilterStringType.Contains,
  value: null,
});

export const generateNewFilterMisc = (): IFilterMisc => ({
  filter_type: EFilterMiscType.AnyValue,
  token: null,
  value: null,
});

export const getUpdatedInternalFilterMiscs = (
  internalFilterMiscs: IFilterMisc[],
  incomingFilterMiscs: IFilterMisc[] | null,
): IFilterMisc[] => {
  // We need to retain any internalFilterMiscs which have the empty
  // string because the user has added this, so check for their existence
  // and merge with filterConfiguration.filterMiscs as necessary.
  let updatedInternalFilterMiscs: IFilterMisc[] = [];

  if (incomingFilterMiscs === null) {
    updatedInternalFilterMiscs = internalFilterMiscs.filter(
      (filterMisc: IFilterMisc): boolean => filterMisc.token === '',
    );
  } else {
    let incomingFilterMiscsIndex: number = 0;

    internalFilterMiscs.forEach((internalFilterMisc: IFilterMisc) => {
      if (internalFilterMisc.token === '') {
        updatedInternalFilterMiscs.push(internalFilterMisc);
      } else if (incomingFilterMiscsIndex < incomingFilterMiscs.length) {
        const incomingFilterMisc: IFilterMisc =
          incomingFilterMiscs[incomingFilterMiscsIndex];

        if (
          incomingFilterMisc.filter_type === internalFilterMisc.filter_type &&
          incomingFilterMisc.token === internalFilterMisc.token &&
          incomingFilterMisc.value === internalFilterMisc.value
        ) {
          updatedInternalFilterMiscs.push(internalFilterMisc);
          incomingFilterMiscsIndex += 1;
        }
      }
    });

    while (incomingFilterMiscsIndex < incomingFilterMiscs.length) {
      updatedInternalFilterMiscs.push({
        ...incomingFilterMiscs[incomingFilterMiscsIndex],
      });
      incomingFilterMiscsIndex += 1;
    }
  }

  // Special case for including newly generated filter miscs which have
  // been added by the user. Since we generate a new filter misc when
  // there are no incoming filter miscs (see code below), we have to check
  // to make sure we don't include this in the case where the user has not
  // added it i.e. when there are incoming filter miscs.
  if (internalFilterMiscs.length > 1) {
    updatedInternalFilterMiscs = updatedInternalFilterMiscs.concat(
      internalFilterMiscs.filter(
        (filterMisc: IFilterMisc): boolean => filterMisc.token === null,
      ),
    );
  }

  // If there are no filter miscs, then include a new filter misc (who's
  // value is null) to allow for new filter miscs to be added.
  if (updatedInternalFilterMiscs.length === 0) {
    updatedInternalFilterMiscs = [generateNewFilterMisc()];
  }

  return updatedInternalFilterMiscs;
};

export const getUpdatedInternalFilterStrings = (
  internalFilterStrings: IFilterString[],
  incomingFilterStrings: IFilterString[] | null,
): IFilterString[] => {
  // We need to retain any internalFilterStrings which have the empty
  // string because the user has added this, so check for their existence
  // and merge with filterConfiguration.filterStrings as necessary.
  let updatedInternalFilterStrings: IFilterString[] = [];

  if (incomingFilterStrings === null) {
    updatedInternalFilterStrings = internalFilterStrings.filter(
      (filterString: IFilterString): boolean => filterString.value === '',
    );
  } else {
    let incomingFilterStringsIndex: number = 0;

    internalFilterStrings.forEach((internalFilterString: IFilterString) => {
      if (internalFilterString.value === '') {
        updatedInternalFilterStrings.push(internalFilterString);
      } else if (incomingFilterStringsIndex < incomingFilterStrings.length) {
        const incomingFilterString: IFilterString =
          incomingFilterStrings[incomingFilterStringsIndex];

        if (
          incomingFilterString.filter_type ===
            internalFilterString.filter_type &&
          incomingFilterString.value === internalFilterString.value
        ) {
          updatedInternalFilterStrings.push(internalFilterString);
          incomingFilterStringsIndex += 1;
        }
      }
    });

    while (incomingFilterStringsIndex < incomingFilterStrings.length) {
      updatedInternalFilterStrings.push({
        ...incomingFilterStrings[incomingFilterStringsIndex],
      });
      incomingFilterStringsIndex += 1;
    }
  }

  // Special case for including newly generated filter strings which have
  // been added by the user. Since we generate a new filter string when
  // there are no incoming filter strings (see code below), we have to
  // check to make sure we don't include this in the case where the user
  // has not added it i.e. when there are incoming filter strings.
  if (internalFilterStrings.length > 1) {
    updatedInternalFilterStrings = updatedInternalFilterStrings.concat(
      internalFilterStrings.filter(
        (filterString: IFilterString): boolean => filterString.value === null,
      ),
    );
  }

  // If there are no filter strings, then include a new filter string
  // (who's value is null) to allow for new filter strings to be added.
  if (updatedInternalFilterStrings.length === 0) {
    updatedInternalFilterStrings = [generateNewFilterString()];
  }

  return updatedInternalFilterStrings;
};

export const copyCustomFilter = (
  customFilter: ICustomFilter,
): ICustomFilter => ({
  ...customFilter,
  sub_filter:
    customFilter.sub_filter === null
      ? null
      : copyCustomFilter(customFilter.sub_filter),
  sub_filter_list:
    customFilter.sub_filter_list === null
      ? null
      : customFilter.sub_filter_list.map(copyCustomFilter),
});

export const customFilterToUid = (customFilter: ICustomFilter): string => {
  if (customFilter.filter_id === null) {
    throw new Error(
      `Invalid filter_id for customFilter: ${JSON.stringify(customFilter)}`,
    );
  }

  return customFilter.filter_id;
};

export const generateCustomFilter = (): ICustomFilter => ({
  attribute: null,
  filter_id: null,
  filter_name: null,
  sub_filter: null,
  sub_filter_list: null,
  type: null,
  value: null,
});

export const getCustomFilterId = (
  customFilter: ICustomFilter | undefined,
): TFilterId | undefined =>
  customFilter === undefined || customFilter?.filter_id === null
    ? undefined
    : customFilter.filter_id;
