import { Auth } from 'aws-amplify';
import { AxiosResponse } from 'axios';
import Button from 'components/atoms/Button/Button';
import ErrorMessage from 'components/atoms/ErrorMessage/ErrorMessage';
import Input from 'components/atoms/Input/Input';
import SeparatedRowLayout from 'components/atoms/SeparatedRowLayout/SeparatedRowLayout';
import InputPhoneNumber from 'components/molecules/InputPhoneNumber/InputPhoneNumber';
import { USER_CONTACT_KEY } from 'constants/input';
import { STANDARD_SPACING } from 'constants/styles';
import { EActionState } from 'enums/General';
import {
  IUpdateUserUiConfigurationResponse,
  IUserUiConfiguration,
} from 'interfaces/Config';
import { IContactInfo } from 'interfaces/General';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { userSetContactInfo } from 'reduxes/User/actions';
import { IToEntityUserState, TToEntityUserStates } from 'reduxes/User/types';
import { updateUserUiConfig } from 'services/configclient/config';
import styled from 'styled-components';
import { TErrorMessage } from 'types/Error';
import { getInitialContactInfo } from 'utils/detail';
import { captureError } from 'utils/error';
import { isEmptyValue, isSuccessStatus } from 'utils/general';
import { verifyPhoneNumber } from 'utils/input';

const Row = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: ${STANDARD_SPACING};
  width: 100%;
`;

interface IStyledInputProps {
  width: number;
}

const StyledInput = styled(Input)<IStyledInputProps>`
  width: ${(props) => props.width}px;
`;

const ContactItem = styled.div`
  right: 16px;
  margin-top: ${STANDARD_SPACING};
`;

const ContactLabel = styled.span`
  max-width: 92px;
  width: 100%;
`;

const PhoneLabel = styled.span`
  max-width: 94px;
  width: 100%;
`;

const FaxLabel = styled.span`
  max-width: 94px;
  width: 100%;
`;

interface IToEntityETagTemplateCreatePopoverProps {
  userState: TToEntityUserStates;
}

const UserButtonPopover = (
  props: IToEntityETagTemplateCreatePopoverProps,
): JSX.Element => {
  const { userState } = props;
  const [contactInfo, setContactInfo] = useState<IContactInfo>(
    getInitialContactInfo(USER_CONTACT_KEY),
  );
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState<TErrorMessage>(null);

  const [contact, setContact] = useState<string>('');
  const [fax, setFax] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [isFaxValid, setIsFaxValid] = useState<boolean>(false);
  const [isPhoneValid, setIsPhoneValid] = useState<boolean>(false);
  const [hasChanged, setHasChanged] = useState<boolean>(false);
  const disableUpdate: boolean =
    !hasChanged || // if the contact has not changed
    (!contact && (isEmptyValue(phone) || isEmptyValue(fax))) || // if the contact doesn't have a name, but has values for numbers
    (!isPhoneValid && isEmptyValue(phone)) || // if the contact has a valid phone number
    (!isFaxValid && isEmptyValue(fax));

  useEffect(() => {
    setIsFaxValid(verifyPhoneNumber(fax));
  }, [fax]);

  useEffect(() => {
    setIsPhoneValid(verifyPhoneNumber(phone));
  }, [phone]);

  // Sets initial info and tracks whether the current contact info is different than the user's
  useEffect(() => {
    const userStateKeys: string[] = Object.keys(userState);
    const toEntityUserState: IToEntityUserState | undefined =
      userState[userStateKeys[0]];
    const userUiConfig: IUserUiConfiguration | undefined =
      toEntityUserState?.userUiConfig;
    const userContact: IContactInfo | undefined = userUiConfig?.contactInfo;

    if (
      userContact?.contact !== contact ||
      userContact?.phone !== phone ||
      userContact?.fax !== fax
    ) {
      setHasChanged(true);
    } else {
      setHasChanged(false);
    }
  }, [contact, contactInfo, fax, phone, userState]);

  useEffect(() => {
    setContactInfo({
      contact,
      fax,
      key: USER_CONTACT_KEY,
      phone,
    });
  }, [contact, fax, phone]);

  useEffect(() => {
    const userStateKeys: string[] = Object.keys(userState);
    if (userStateKeys.length > 0) {
      const toEntityUserState: IToEntityUserState | undefined =
        userState[userStateKeys[0]];
      const userUiConfig: IUserUiConfiguration | undefined =
        toEntityUserState?.userUiConfig;
      const contactInfo: IContactInfo | undefined = userUiConfig?.contactInfo;
      if (contactInfo !== undefined) {
        setContact(contactInfo.contact || '');
        setFax(contactInfo.fax || '');
        setPhone(contactInfo.phone || '');
        setContactInfo(contactInfo);
      }
    }
  }, [userState]);

  const setUserContactInfo = useCallback(
    (contactInfo: IContactInfo) => {
      dispatch(userSetContactInfo({ contactInfo }));
    },
    [dispatch],
  );

  const handleUpdateContactInfo = async () => {
    if (
      contact !== undefined &&
      (isPhoneValid || phone === '') &&
      (isFaxValid || fax === '')
    ) {
      for (const toEntityId in userState) {
        const { userUiConfig } = userState[toEntityId]!;

        if (userUiConfig !== undefined) {
          const updatedUserUiConfig: IUserUiConfiguration = {
            ...userUiConfig,
            contactInfo,
          };

          try {
            const response: AxiosResponse<IUpdateUserUiConfigurationResponse> =
              await updateUserUiConfig(toEntityId, updatedUserUiConfig);
            if (!isSuccessStatus(response.status)) {
              throw new Error('Failed to update User UI Config');
            }
            setHasChanged(false);
            setUserContactInfo(contactInfo);
          } catch (error: any) {
            captureError(error);

            if (error instanceof Error) {
              setErrorMessage(error.message);
            } else {
              setErrorMessage(
                'An error occurred during contact info update. Please try again later.',
              );
            }
          }
        }
      }
    }
  };

  const handleContactChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setContact(event.target.value);
  };

  const handlePhoneChange = (value: string | null) => {
    setPhone(value || '');
  };

  const handleFaxChange = (value: string | null) => {
    setFax(value || '');
  };

  return (
    <>
      <ContactItem>
        <SeparatedRowLayout>
          <ContactLabel>Contact:</ContactLabel>
          <StyledInput
            width={184}
            onChange={handleContactChange}
            placeholder='Contact Name'
            value={contact || ''}
          />
        </SeparatedRowLayout>
      </ContactItem>
      <ContactItem>
        <SeparatedRowLayout>
          <PhoneLabel>Phone:</PhoneLabel>
          <InputPhoneNumber onChange={handlePhoneChange} value={phone || ''} />
        </SeparatedRowLayout>
      </ContactItem>
      <ContactItem>
        <SeparatedRowLayout>
          <FaxLabel>Fax:</FaxLabel>
          <InputPhoneNumber onChange={handleFaxChange} value={fax || ''} />
        </SeparatedRowLayout>
      </ContactItem>
      <SeparatedRowLayout>
        <Row>
          <ErrorMessage>{errorMessage}</ErrorMessage>
        </Row>
      </SeparatedRowLayout>
      <SeparatedRowLayout>
        <Row>
          <Button
            actionState={EActionState.NoAction}
            label={'Sign Out'}
            onClick={() => {
              localStorage.setItem('pciSupportToEntities', JSON.stringify([]));
              Auth.signOut();
            }}
          />
          <Button
            actionState={EActionState.NoAction}
            isDisabled={disableUpdate}
            isPrimary={true}
            label={'Update Contact Info'}
            onClick={handleUpdateContactInfo}
          />
        </Row>
      </SeparatedRowLayout>
    </>
  );
};

export default UserButtonPopover;
