import RowStylingConfigurator from 'components/molecules/RowStylingConfigurator/RowStylingConfigurator';
import SummaryStylePicker from 'components/organisms/ToEntitySummaryUiConfiguration/SummaryStylePicker';
import {
  ALTERNATING_ROW_STYLE_KEY,
  ATTRIBUTE_ROW_STYLE_KEY,
  DRAFT_ROW_STYLE_KEY,
  HOUR_ENDING_STYLE_KEY,
  PROFILES_ROW_STYLE_KEY,
  ROW_HIGHLIGHTING_STYLE_KEY,
} from 'constants/Summary';
import {
  ECompositeState,
  ELinkageStatus,
  ERequestStatus,
  ETransactionType,
} from 'enums/ETag';
import { IIndexable } from 'interfaces/General';
import {
  ISummaryStyleCoding,
  ISummaryStyles,
  ISummaryThemedStyles,
} from 'interfaces/Summary';
import { useEffect, useState } from 'react';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import styled from 'styled-components';

const SummaryStylePickerLabel = styled.div`
  font-weight: bold;
  width: 100%;
`;

const SummaryStylePickerGroupLabel = styled.div`
  font-size: 200%;
  font-weight: bold;
`;

const SummaryStylePickerGroupWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const SummaryStylePickerWrapper = styled.div`
  border: 1px dashed;
  margin: 2px;
  padding: 2px;
`;

export interface IProps {
  isDisabled: boolean;
  onChange: (summaryStyleCoding: ISummaryStyleCoding) => void;
  showDealConfiguration: boolean;
  summaryStyleCoding?: ISummaryStyleCoding;
}

const StyleCodingSelect = (props: IProps): JSX.Element => {
  const { isDisabled, onChange, showDealConfiguration, summaryStyleCoding } =
    props;

  const [summaryStyleCodingInternal, setSummaryStyleCodingInternal] = useState<
    (ISummaryStyleCoding & IIndexable) | undefined
  >(undefined);

  useEffect(() => {
    setSummaryStyleCodingInternal(summaryStyleCoding);
  }, [summaryStyleCoding]);

  const propagateSummaryStyleCodingChange = (
    summaryStyleCodingChange: ISummaryStyleCoding,
  ) => {
    setSummaryStyleCodingInternal(summaryStyleCodingChange);
    onChange(summaryStyleCodingChange);
  };

  const themeSwitcher = useThemeSwitcher();

  const LabeledSummaryStylePicker2Level = (
    label: string,
    level1: string,
    level2: string,
  ): JSX.Element => {
    return (
      <SummaryStylePickerWrapper>
        <SummaryStylePickerLabel>{label}</SummaryStylePickerLabel>
        <SummaryStylePicker
          isDisabled={isDisabled}
          summaryStyles={
            summaryStyleCodingInternal?.[level1]?.[level2]?.[
              themeSwitcher.currentTheme!
            ] as ISummaryStyles
          }
          onChange={(summaryStyles: ISummaryStyles) => {
            if (summaryStyleCodingInternal !== undefined) {
              const updated: ISummaryStyleCoding = {
                ...summaryStyleCodingInternal,
                [level1]: {
                  ...summaryStyleCodingInternal[level1],
                  [level2]: {
                    ...summaryStyleCodingInternal[level1][level2],
                    [themeSwitcher.currentTheme!]: summaryStyles,
                  },
                } as any,
              };
              propagateSummaryStyleCodingChange(updated);
            }
          }}
        />
      </SummaryStylePickerWrapper>
    );
  };
  const LabeledSummaryStylePicker3Level = (
    label: string,
    level1: string,
    level2: string,
    level3: string,
  ): JSX.Element => {
    return (
      <SummaryStylePickerWrapper>
        <SummaryStylePickerLabel>{label}</SummaryStylePickerLabel>
        <SummaryStylePicker
          isDisabled={isDisabled}
          summaryStyles={
            (
              (
                summaryStyleCodingInternal?.[level1]?.[level2] as Record<
                  string,
                  ISummaryThemedStyles
                >
              )?.[level3] as IIndexable
            )?.[themeSwitcher.currentTheme!] as ISummaryStyles
          }
          onChange={(summaryStyles: ISummaryStyles) => {
            if (summaryStyleCodingInternal !== undefined) {
              propagateSummaryStyleCodingChange({
                ...summaryStyleCodingInternal,
                [level1]: {
                  ...summaryStyleCodingInternal[level1],
                  [level2]: {
                    ...summaryStyleCodingInternal[level1][level2],
                    [level3]: {
                      ...(summaryStyleCodingInternal[level1][level2][
                        level3
                      ] as ISummaryThemedStyles),
                      [themeSwitcher.currentTheme!]: summaryStyles,
                    },
                  },
                } as any,
              });
            }
          }}
        />
      </SummaryStylePickerWrapper>
    );
  };

  const handleChange = (
    alternatingRowStyle: ISummaryThemedStyles,
    rowHighlightingStyle: ISummaryThemedStyles,
  ) => {
    if (summaryStyleCodingInternal !== undefined) {
      propagateSummaryStyleCodingChange({
        ...summaryStyleCodingInternal,
        [ALTERNATING_ROW_STYLE_KEY]: alternatingRowStyle,
        [ROW_HIGHLIGHTING_STYLE_KEY]: rowHighlightingStyle,
      });
    }
  };

  return (
    <>
      <SummaryStylePickerGroupLabel>
        Base styling for table rows
      </SummaryStylePickerGroupLabel>
      <SummaryStylePickerGroupWrapper>
        <RowStylingConfigurator
          alternatingRowStyle={summaryStyleCoding?.alternatingRowStyle}
          onChange={handleChange}
          rowHighlightingStyle={summaryStyleCoding?.rowHighlightingStyle}
        />
      </SummaryStylePickerGroupWrapper>
      <SummaryStylePickerGroupLabel>
        Styling based on composite state
      </SummaryStylePickerGroupLabel>
      <SummaryStylePickerGroupWrapper>
        {LabeledSummaryStylePicker2Level(
          ECompositeState.Cancelled,
          'composite_state',
          ECompositeState.Cancelled,
        )}
        {LabeledSummaryStylePicker2Level(
          ECompositeState.Confirmed,
          'composite_state',
          ECompositeState.Confirmed,
        )}
        {LabeledSummaryStylePicker2Level(
          ECompositeState.Denied,
          'composite_state',
          ECompositeState.Denied,
        )}
        {LabeledSummaryStylePicker2Level(
          ECompositeState.Expired,
          'composite_state',
          ECompositeState.Expired,
        )}
        {LabeledSummaryStylePicker2Level(
          ECompositeState.Implemented,
          'composite_state',
          ECompositeState.Implemented,
        )}
        {LabeledSummaryStylePicker2Level(
          ECompositeState.Pending,
          'composite_state',
          ECompositeState.Pending,
        )}
        {LabeledSummaryStylePicker2Level(
          ECompositeState.Terminated,
          'composite_state',
          ECompositeState.Terminated,
        )}
        {LabeledSummaryStylePicker2Level(
          ECompositeState.Withdrawn,
          'composite_state',
          ECompositeState.Withdrawn,
        )}
      </SummaryStylePickerGroupWrapper>
      <SummaryStylePickerGroupLabel>
        Styling based on special conditions on E-Tag
      </SummaryStylePickerGroupLabel>
      <SummaryStylePickerGroupWrapper>
        {LabeledSummaryStylePicker2Level(
          'Curtailed profile',
          'curtailed',
          'true',
        )}
        {LabeledSummaryStylePicker2Level(
          'Denied or withdrawn profile',
          'denied_withdrawn',
          'true',
        )}
        {LabeledSummaryStylePicker2Level('Denied profile', 'denied', 'true')}
        {LabeledSummaryStylePicker2Level(
          'Withdrawn profile',
          'withdrawn',
          'true',
        )}
        {LabeledSummaryStylePicker2Level(
          'Subhourly profile in tag',
          'is_subhourly_tag',
          'true',
        )}
        {LabeledSummaryStylePicker2Level(
          'Terminated or cancelled tag',
          'terminated_cancelled',
          'true',
        )}
        {LabeledSummaryStylePicker2Level('Test tag', 'test_flag', 'true')}
      </SummaryStylePickerGroupWrapper>
      {showDealConfiguration ? (
        <>
          <SummaryStylePickerGroupLabel>
            Styling based on E-Tag deal linkage information
          </SummaryStylePickerGroupLabel>
          <SummaryStylePickerGroupWrapper>
            {LabeledSummaryStylePicker2Level(
              ELinkageStatus.Unlinked,
              'ui_deal_status',
              ELinkageStatus.Unlinked,
            )}
            {LabeledSummaryStylePicker2Level(
              ELinkageStatus.PartiallyLinked,
              'ui_deal_status',
              ELinkageStatus.PartiallyLinked,
            )}
            {LabeledSummaryStylePicker2Level(
              ELinkageStatus.FullyLinked,
              'ui_deal_status',
              ELinkageStatus.FullyLinked,
            )}
            {LabeledSummaryStylePicker2Level(
              ELinkageStatus.OverLinked,
              'ui_deal_status',
              ELinkageStatus.OverLinked,
            )}
          </SummaryStylePickerGroupWrapper>
        </>
      ) : null}
      <SummaryStylePickerGroupLabel>
        Styling based on last request status
      </SummaryStylePickerGroupLabel>
      <SummaryStylePickerGroupWrapper>
        {LabeledSummaryStylePicker2Level(
          ERequestStatus.Approved,
          'last_request_status',
          ERequestStatus.Approved,
        )}
        {LabeledSummaryStylePicker2Level(
          ERequestStatus.Denied,
          'last_request_status',
          ERequestStatus.Denied,
        )}
        {LabeledSummaryStylePicker2Level(
          ERequestStatus.Expired,
          'last_request_status',
          ERequestStatus.Expired,
        )}
        {LabeledSummaryStylePicker2Level(
          ERequestStatus.Pending,
          'last_request_status',
          ERequestStatus.Pending,
        )}
        {LabeledSummaryStylePicker2Level(
          ERequestStatus.Withdrawn,
          'last_request_status',
          ERequestStatus.Withdrawn,
        )}
      </SummaryStylePickerGroupWrapper>
      <SummaryStylePickerGroupLabel>
        Styling based on transaction type
      </SummaryStylePickerGroupLabel>
      <SummaryStylePickerGroupWrapper>
        {LabeledSummaryStylePicker2Level(
          ETransactionType.Capacity,
          'transaction_type',
          ETransactionType.Capacity,
        )}
        {LabeledSummaryStylePicker2Level(
          ETransactionType.Dynamic,
          'transaction_type',
          ETransactionType.Dynamic,
        )}
        {LabeledSummaryStylePicker2Level(
          ETransactionType.Emergency,
          'transaction_type',
          ETransactionType.Emergency,
        )}
        {LabeledSummaryStylePicker2Level(
          ETransactionType.LossSupply,
          'transaction_type',
          ETransactionType.LossSupply,
        )}
        {LabeledSummaryStylePicker2Level(
          ETransactionType.Normal,
          'transaction_type',
          ETransactionType.Normal,
        )}
        {LabeledSummaryStylePicker2Level(
          ETransactionType.PseudoTie,
          'transaction_type',
          ETransactionType.PseudoTie,
        )}
        {LabeledSummaryStylePicker2Level(
          ETransactionType.Recallable,
          'transaction_type',
          ETransactionType.Recallable,
        )}
      </SummaryStylePickerGroupWrapper>
      <SummaryStylePickerGroupLabel>
        Styling for summary profile hour-ending cells
      </SummaryStylePickerGroupLabel>
      <SummaryStylePickerGroupWrapper>
        {LabeledSummaryStylePicker3Level(
          'Curtailed',
          HOUR_ENDING_STYLE_KEY,
          'curtailed',
          'true',
        )}
        {LabeledSummaryStylePicker3Level(
          'Pending',
          HOUR_ENDING_STYLE_KEY,
          'pending',
          'true',
        )}
        {LabeledSummaryStylePicker3Level(
          'Reloaded',
          HOUR_ENDING_STYLE_KEY,
          'reloaded',
          'true',
        )}
        {LabeledSummaryStylePicker3Level(
          'Terminated',
          HOUR_ENDING_STYLE_KEY,
          'terminated',
          'true',
        )}
      </SummaryStylePickerGroupWrapper>
      <SummaryStylePickerGroupLabel>
        Styling for attribute rows pending approval action
      </SummaryStylePickerGroupLabel>
      <SummaryStylePickerGroupWrapper>
        {LabeledSummaryStylePicker3Level(
          'Approval right',
          ATTRIBUTE_ROW_STYLE_KEY,
          'approval_right',
          'true',
        )}
      </SummaryStylePickerGroupWrapper>
      <SummaryStylePickerGroupLabel>
        Styling for profile rows pending approval action
      </SummaryStylePickerGroupLabel>
      <SummaryStylePickerGroupWrapper>
        {LabeledSummaryStylePicker3Level(
          'Approval right',
          PROFILES_ROW_STYLE_KEY,
          'approval_right',
          'true',
        )}
      </SummaryStylePickerGroupWrapper>
      <SummaryStylePickerGroupLabel>
        Styling for rows of Draft E-Tag
      </SummaryStylePickerGroupLabel>
      <SummaryStylePickerGroupWrapper>
        {LabeledSummaryStylePicker3Level(
          'Draft composite state',
          DRAFT_ROW_STYLE_KEY,
          'composite_state',
          ECompositeState.Draft,
        )}
      </SummaryStylePickerGroupWrapper>
    </>
  );
};
export default StyleCodingSelect;
