import {
  forwardRef,
  HTMLAttributes,
  MutableRefObject,
  ReactNode,
  useEffect,
  useRef,
} from 'react';

interface IContentWrapperProps extends HTMLAttributes<HTMLDivElement> {
  children?: ReactNode;
  className?: string;
  hideTooltip: () => void;
  isDisabled?: boolean;
  onMouseEnter?: (event: React.MouseEvent) => void;
  onMouseLeave?: (event: React.MouseEvent) => void;
  visible: boolean;
}

// AntDesignTooltip always passes a ref to its children for some internal
// logic. This causes a warning that a ref is being forwarded to our
// ContentWrapper since it is a functional component. We therefore consume
// the ref by forwarding it via forwardRef, but don't do anything with it
// since we manage the events instead of AntDesignTooltip.
const ContentWrapper = forwardRef(
  (
    {
      children,
      className,
      hideTooltip,
      isDisabled,
      onMouseEnter,
      onMouseLeave,
      visible,
    }: IContentWrapperProps,
    _ref: any,
  ): JSX.Element => {
    const divRef = useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;
    const mouseMoveRef = useRef<(mouseEvent: MouseEvent) => void>();

    useEffect(() => {
      return () => {
        if (mouseMoveRef.current !== undefined) {
          document.removeEventListener('mousemove', mouseMoveRef.current);
        }
      };
    }, []);

    useEffect(() => {
      mouseMoveRef.current = (mouseEvent: MouseEvent) => {
        if (
          divRef.current !== undefined &&
          divRef.current !== null &&
          !divRef.current.contains(mouseEvent.target as HTMLElement)
        ) {
          hideTooltip();
        }
      };
    }, [divRef, hideTooltip]);

    useEffect(() => {
      if (mouseMoveRef.current !== undefined) {
        if (visible) {
          document.addEventListener('mousemove', mouseMoveRef.current);
        } else {
          document.removeEventListener('mousemove', mouseMoveRef.current);
        }
      }
    }, [visible]);

    const handleMouseEnter = (event: React.MouseEvent) => {
      if (!isDisabled && onMouseEnter !== undefined) {
        onMouseEnter(event);
      }
    };

    const handleMouseLeave = (event: React.MouseEvent) => {
      if (!isDisabled && onMouseLeave !== undefined) {
        onMouseLeave(event);
      }
    };

    return (
      <div
        className={className}
        onClick={hideTooltip}
        onMouseOver={handleMouseEnter}
        onMouseOut={handleMouseLeave}
        ref={divRef}
      >
        {children}
      </div>
    );
  },
);

export default ContentWrapper;
