import PendingRequestRow from 'components/organisms/ToEntityETagReviewPendingRequests/PendingRequestRow';
import {
  IEditPendingRequest,
  IEditPendingRequests,
  IPendingRequest,
} from 'components/organisms/ToEntityETagReviewPendingRequests/types';
import { EApprovalStatus } from 'enums/Approval';
import { ERequestType } from 'enums/ETag';
import { TableComponents } from 'rc-table/lib/interface';

export const requestTypeToString = (requestType: ERequestType): string => {
  switch (requestType) {
    case ERequestType.AtfAdjustment:
      return 'ATF Adjustment';
    case ERequestType.BtfAdjustment:
      return 'BTF Adjustment';
    case ERequestType.Correction:
      return 'Correction';
    case ERequestType.Curtailment:
      return 'Curtailment';
    case ERequestType.Extension:
      return 'Extension';
    case ERequestType.NewTag:
      return 'New Tag';
    case ERequestType.Reload:
      return 'Reload';
    case ERequestType.Termination:
      return 'Termination';
    default:
      return 'None';
  }
};

export const approvalRightToString = (approvalRight: boolean): string =>
  approvalRight === true ? 'True' : 'False';

export const approvalActionToString = (
  approvalStatus: EApprovalStatus,
): string => {
  switch (approvalStatus) {
    case EApprovalStatus.Approved:
      return 'Approve';
    case EApprovalStatus.Denied:
      return 'Deny';
    case EApprovalStatus.Study:
      return 'Approve';
    default:
      return 'N/A';
  }
};

export const getPendingRequestForKey = (
  key: string,
  pendingRequests: IPendingRequest[],
): IPendingRequest | undefined =>
  pendingRequests.find(
    (pendingRequest: IPendingRequest): boolean => pendingRequest.key === key,
  );

export const editPendingRequestsToPendingRequests = (
  editPendingRequests: IEditPendingRequests,
  pendingRequests: IPendingRequest[],
): IPendingRequest[] => {
  const updatedPendingRequests: IPendingRequest[] = [...pendingRequests];
  const { editPendingRequestsMap } = editPendingRequests;

  if (editPendingRequestsMap !== undefined) {
    Object.keys(editPendingRequestsMap).forEach((key: string) => {
      const pendingRequestIndex: number = updatedPendingRequests.findIndex(
        (pendingRequest: IPendingRequest): boolean =>
          pendingRequest.key === key,
      );

      if (pendingRequestIndex !== -1) {
        const updatedPendingRequest: IPendingRequest = {
          ...pendingRequests[pendingRequestIndex],
        };
        const editPendingRequest: IEditPendingRequest =
          editPendingRequestsMap[key];
        const { action, pci_suggested_note } = editPendingRequest;

        if (action !== undefined) {
          updatedPendingRequest.action = action;
        }

        if (pci_suggested_note !== undefined) {
          updatedPendingRequest.pci_suggested_note = pci_suggested_note;
        }

        updatedPendingRequests[pendingRequestIndex] = updatedPendingRequest;
      }
    });
  }

  return updatedPendingRequests;
};

export const getCustomComponents = (): TableComponents<IPendingRequest> => ({
  body: {
    row: PendingRequestRow,
  },
});
