import { TRANSMISSION_CONTRACT_NUMBER_SPECIAL_KEY } from 'constants/Detail';
import { TAB_KEY } from 'constants/General';

export const DATA_GRID_CONTEXT_MENU_INSERT_ABOVE_ID =
  'dataGridContextMenuInsertAbove';
export const DATA_GRID_CONTEXT_MENU_INSERT_BELOW_ID =
  'dataGridContextMenuInsertBelow';
export const DATA_GRID_CONTEXT_MENU_REMOVE_ID = 'dataGridContextMenuRemove';
export const DATA_GRID_CONTEXT_MENU_REMOVE_ABOVE_ID =
  'dataGridContextMenuRemoveAbove';
export const DATA_GRID_CONTEXT_MENU_REMOVE_BELOW_ID =
  'dataGridContextMenuRemoveBelow';

export const PROFILE_PATH_START_HEADER_NAME = '\nPhysical Path\nStart';
export const PROFILE_PATH_STOP_HEADER_NAME = '\nPhysical Path\nStop';
export const PROFILE_PATH_RAMP_START_HEADER_NAME =
  '\nPhysical Path\nRamp Start';
export const PROFILE_PATH_RAMP_STOP_HEADER_NAME = '\nPhysical Path\nRamp Stop';

export const CELL_TOTALS_CLASS = 'rdg-cell-totals';
export const CELL_DISABLED_CLASS = 'rdg-cell-disabled';

export const PROFILE_CHANGE_ROW_EDIT_KEY_LABEL = 'profileChangeRowEdit';

export const TRANSMISSION_CONTRACT_NUMBER_KEY_REG_EXP = new RegExp(
  `${TRANSMISSION_CONTRACT_NUMBER_SPECIAL_KEY}(\\d+)${TRANSMISSION_CONTRACT_NUMBER_SPECIAL_KEY}`,
);
export const PROFILE_DATA_GRID_HEADER_ROW_HEIGHT_VALUE = 28;

export const EDITOR_OPTIONS = {
  onNavigation: (keyboardEvent: React.KeyboardEvent) =>
    keyboardEvent.key === TAB_KEY,
};
