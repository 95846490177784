import Tooltip from '../Tooltip/Tooltip';
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { TPlacement, TStateLoadTransform } from '../../../types/General';
import IconButton from '../../atoms/IconButton/IconButton';
import Modal from '../Modal/Modal';
import {
  ISelectColumnConfig,
  IViewDataTableColumn,
} from '../../../interfaces/View';
import {
  getColumnEntityInfoRender,
  getColumnPointInfoRender,
  getColumnRender,
  getColumnSelectRender,
  getPidSelectNoHighlightRender,
  getPidSelectRender,
} from '../../../utils/views';
import {
  editTransmissionPrioritySegmentsToDetailState,
  PRIORITIES_ADD_BUTTON_OFFSET,
  PRIORITIES_REMOVE_BUTTON_OFFSET,
} from './helpers';
import { IToEntity } from '../../../interfaces/ToEntity';
import {
  VIEW_DATA_TABLE_COLUMN_ENTITY_SELECT_COLUMN_WIDTH,
  VIEW_DATA_TABLE_COLUMN_POINT_SELECT_COLUMN_WIDTH,
  VIEW_DATA_TABLE_COLUMN_PRODUCT_SELECT_COLUMN_WIDTH,
} from '../../../constants/styles';
import { EViewMode } from '../../../enums/View';
import {
  IETagTemplate,
  IEtagTransmissionPriorityConfiguration,
} from '../../../interfaces/ETag';
import {
  isEmptyValue,
  isSuccessStatus,
  selectOptionLabelFilter,
  simpleEqualityChecker,
} from '../../../utils/general';
import { IOption } from '../../../interfaces/Component';
import { TETagTemplateId } from '../../../types/ETag';
import EditableDataTable from '../EditableDataTable/EditableDataTable';
import {
  detailEditETagDetail,
  detailRetrieveETagDetail,
} from '../../../reduxes/Detail/actions';
import { useDispatch, useSelector } from 'react-redux';
import { IEditTransmissionPrioritySegmentInformation } from './types';
import { TRootState } from '../../../types/Redux';
import SeparatedRowLayout from '../../atoms/SeparatedRowLayout/SeparatedRowLayout';
import styled from 'styled-components';
import Button from '../../atoms/Button/Button';
import { saveTransmissionPrioritySegment } from '../../../services/agent/transmissionPrioritySegment';
import { EActionState, ERegistryType } from '../../../enums/General';
import { FORBIDDEN_STATUS_CODE } from '../../../constants/misc';
import {
  pointInfoEqual,
  pointInfoToString,
  pointInfoToUid,
  registryPointToPointInfoOption,
} from '../../../utils/point';
import { IPointInfo, IRegistryPoint } from '../../../interfaces/Point';
import { AxiosResponse } from 'axios';
import { retrieveRegistryPoints } from '../../../services/naesb-registry/registry';
import { EPointTypeName } from '../../../enums/Point';
import { captureError } from '../../../utils/error';
import useGetCurrentETagTemplate from '../../organisms/ETagManager/useGetCurrentETagTemplate';
import { TToEntityId } from '../../../types/ToEntity';
import {
  entityInfoToUid,
  registryEntityToEntityInfoOption,
  tpEntityInfoEqual,
} from '../../../utils/entity';
import { IEntityInfo, IRegistryEntity } from '../../../interfaces/Entity';
import { retrieveAndTransformTransmissionPriorityConfigurations } from '../../../utils/detail';
import { EDetailIdType } from '../../../enums/Detail';
import { IDetailState } from '../../../reduxes/Detail/types';

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

const transmissionPrioritySegmentPidSelectRender = getPidSelectRender<any>();

const transmissionPrioritySegmentPidSelectNoHighlightRender =
  getPidSelectNoHighlightRender<any>();

const transmissionTpSelectRender = getColumnSelectRender<
  IEntityInfo | null,
  IEtagTransmissionPriorityConfiguration
>('100%');

const porSelectRender = getColumnSelectRender<
  IPointInfo | null,
  IEtagTransmissionPriorityConfiguration
>(
  '100%',
  undefined,
  (value: IPointInfo | (IPointInfo | null)[] | null | undefined) =>
    pointInfoToString(value as IPointInfo),
);

const podSelectRender = getColumnSelectRender<
  IPointInfo | null,
  IEtagTransmissionPriorityConfiguration
>(
  '100%',
  undefined,
  (value: IPointInfo | (IPointInfo | null)[] | null | undefined) =>
    pointInfoToString(value as IPointInfo),
);

const sourceSelectRender = getColumnSelectRender<
  IPointInfo | null,
  IEtagTransmissionPriorityConfiguration
>(
  '100%',
  undefined,
  (value: IPointInfo | (IPointInfo | null)[] | null | undefined) =>
    pointInfoToString(value as IPointInfo),
);

const sinkSelectRender = getColumnSelectRender<
  IPointInfo | null,
  IEtagTransmissionPriorityConfiguration
>(
  '100%',
  undefined,
  (value: IPointInfo | (IPointInfo | null)[] | null | undefined) =>
    pointInfoToString(value as IPointInfo),
);

const transmissionPriorityConfigurationPidRender =
  (
    selectColumnConfig: ISelectColumnConfig<number, any>,
    viewMode: EViewMode,
    initialTransmissionPriorityConfigurationIds: number[],
    isUnconstrained: boolean,
  ) =>
  (
    value: unknown,
    record: IEtagTransmissionPriorityConfiguration,
    index: number,
  ): JSX.Element => {
    return viewMode === EViewMode.ReviewETag //||
      ? getColumnRender(isUnconstrained)(value)
      : record.key === `${record.pid}-${record.order}`
      ? transmissionPrioritySegmentPidSelectNoHighlightRender(
          selectColumnConfig,
        )(value, record, index)
      : transmissionPrioritySegmentPidSelectRender(selectColumnConfig)(
          value,
          record,
          index,
        );
  };

const transmissionOrderSelectRender = getColumnSelectRender<
  number | null,
  IEtagTransmissionPriorityConfiguration
>('80%');

const transmissionPrioritySegmentProductRefRender = getColumnSelectRender<
  number | null,
  IEtagTransmissionPriorityConfiguration
>('100%');

interface ITransmissionPriorityConfigurationProps {
  toEntityId: TToEntityId;
  isDisabled: boolean;
  icon: JSX.Element;
  modalWidth: number;
  templateId: TETagTemplateId | undefined;
  title: string;
  toEntity: IToEntity | null;
  viewMode: EViewMode;
  tooltipPlacement?: TPlacement;
  ref?: any;
}

const retrievePhysicalPathEditState = (state: TRootState) => {
  const {
    config,
    composite_state,
    focusKey,
    isDetailDeleted,
    isDetailValidating,
    marketSegments,
    registryEntities,
    tag_id,
    toEntity,
    validations,
    viewMode,
    transmissionPriorityConfigurations,
    transmission_physical_segments,
  } = state.detail.present;
  const isDetailUpdating = false;
  const isTransmissionPrioritySegmentsLoading = false;

  return {
    config,
    composite_state,
    focusKey,
    isDetailDeleted,
    isDetailUpdating,
    isDetailValidating,
    isTransmissionPrioritySegmentsLoading,
    marketSegments,
    registryEntities,
    tag_id,
    toEntity,
    validations,
    viewMode,
    transmissionPriorityConfigurations,
    transmission_physical_segments,
  };
};

const TransmissionPriorityConfiguration = ({
  toEntityId,
  isDisabled,
  icon,
  title,
  toEntity,
  viewMode,
  templateId,
  tooltipPlacement,
  ref,
}: ITransmissionPriorityConfigurationProps): JSX.Element => {
  const dispatch = useDispatch();
  const getCurrentETagTemplate = useGetCurrentETagTemplate();
  const {
    transmissionPriorityConfigurations,
    transmission_physical_segments,
    registryEntities,
  } = useSelector(retrievePhysicalPathEditState);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [priorities, setPriorities] = useState<
    IEtagTransmissionPriorityConfiguration[]
  >([]);
  const [pidOptions, setPidOptions] = useState<IOption<number>[]>([]);
  const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(true);
  const [saveActionState, setSaveActionState] = useState<EActionState>(
    EActionState.NoAction,
  );

  const eTagTemplate: IETagTemplate | undefined = useMemo(
    () => getCurrentETagTemplate(),
    [getCurrentETagTemplate],
  );

  useEffect(() => {
    if (transmissionPriorityConfigurations.length > 0) {
      setPriorities(transmissionPriorityConfigurations);
    }
  }, [transmissionPriorityConfigurations]);

  const previousTransmissionPrioritySegments: MutableRefObject<
    IEtagTransmissionPriorityConfiguration[]
  > = useRef<IEtagTransmissionPriorityConfiguration[]>(
    transmissionPriorityConfigurations,
  );

  const initialTransmissionPrioritySegments: IEtagTransmissionPriorityConfiguration[] =
    useMemo(() => {
      previousTransmissionPrioritySegments.current =
        transmissionPriorityConfigurations;
      return transmissionPriorityConfigurations;
    }, [
      transmissionPriorityConfigurations,
      previousTransmissionPrioritySegments,
    ]);

  const initialTransmissionPriorityConfigurationIds: number[] = useMemo(
    () =>
      initialTransmissionPrioritySegments === null
        ? []
        : initialTransmissionPrioritySegments
            .filter((i) => i.pid !== undefined && i.pid !== null)
            .map(
              (
                detailLossAccounting: IEtagTransmissionPriorityConfiguration,
              ): number => detailLossAccounting.pid || 0,
            ),
    [initialTransmissionPrioritySegments],
  );

  const handleShow = useCallback(() => {
    setShowModal(true);
  }, [setShowModal]);

  useEffect(() => {
    if (transmission_physical_segments === null) {
      if (pidOptions.length > 0) {
        setPidOptions([]);
      }
    } else if (pidOptions.length !== transmission_physical_segments.length) {
      const newPidOptions: IOption<number>[] = [];

      for (
        let i: number = 0;
        i < transmission_physical_segments.length;
        i += 1
      ) {
        // Transmission physical segment ids start from 2
        newPidOptions.push({ label: `${i + 2}`, value: i + 2 });
      }

      setPidOptions(newPidOptions);
    }
  }, [pidOptions, transmission_physical_segments]);

  const getPidOptions = useCallback(
    async (_: IEtagTransmissionPriorityConfiguration, index: number) => {
      let highestPIDBefore: number = Number.MIN_SAFE_INTEGER;
      let lowestPIDAfter: number = Number.MAX_SAFE_INTEGER;

      for (let i: number = 0; i < index; i += 1) {
        const detailLossAccounting: IEtagTransmissionPriorityConfiguration =
          transmissionPriorityConfigurations[i];

        if (
          detailLossAccounting.pid &&
          detailLossAccounting.pid > highestPIDBefore
        ) {
          highestPIDBefore = detailLossAccounting.pid;
        }
      }

      for (
        let i: number = index + 1;
        i < transmissionPriorityConfigurations.length;
        i += 1
      ) {
        const detailLossAccounting: IEtagTransmissionPriorityConfiguration =
          transmissionPriorityConfigurations[i];

        if (
          detailLossAccounting.pid &&
          detailLossAccounting.pid < lowestPIDAfter
        ) {
          lowestPIDAfter = detailLossAccounting.pid;
        }
      }

      return pidOptions;
    },
    [transmissionPriorityConfigurations, pidOptions],
  );

  const getInitialTransmissionPrioritySegmentTpSelectValue = useCallback(
    (
      record: IEtagTransmissionPriorityConfiguration,
    ): IEntityInfo | null | undefined => {
      const initialTransmissionPrioritySegment:
        | IEtagTransmissionPriorityConfiguration
        | undefined = initialTransmissionPrioritySegments.find(
        (config: IEtagTransmissionPriorityConfiguration): boolean =>
          config.key === record.key,
      );

      return initialTransmissionPrioritySegment === undefined
        ? null
        : initialTransmissionPrioritySegment.tp;
    },
    [initialTransmissionPrioritySegments],
  );

  const getInitialTransmissionPrioritySegmentPorSelectValue = useCallback(
    (
      record: IEtagTransmissionPriorityConfiguration,
    ): IPointInfo | null | undefined => {
      const initialTransmissionPrioritySegment:
        | IEtagTransmissionPriorityConfiguration
        | undefined = initialTransmissionPrioritySegments.find(
        (config: IEtagTransmissionPriorityConfiguration): boolean =>
          config.key === record.key,
      );

      return initialTransmissionPrioritySegment === undefined
        ? null
        : initialTransmissionPrioritySegment.por;
    },
    [initialTransmissionPrioritySegments],
  );

  const getInitialTransmissionPrioritySegmentPodSelectValue = useCallback(
    (
      record: IEtagTransmissionPriorityConfiguration,
    ): IPointInfo | null | undefined => {
      const initialTransmissionPrioritySegment:
        | IEtagTransmissionPriorityConfiguration
        | undefined = initialTransmissionPrioritySegments.find(
        (config: IEtagTransmissionPriorityConfiguration): boolean =>
          config.key === record.key,
      );

      return initialTransmissionPrioritySegment === undefined
        ? null
        : initialTransmissionPrioritySegment.pod;
    },
    [initialTransmissionPrioritySegments],
  );

  const getInitialTransmissionPrioritySegmentSourceSelectValue = useCallback(
    (
      record: IEtagTransmissionPriorityConfiguration,
    ): IPointInfo | null | undefined => {
      const initialTransmissionPrioritySegment:
        | IEtagTransmissionPriorityConfiguration
        | undefined = initialTransmissionPrioritySegments.find(
        (config: IEtagTransmissionPriorityConfiguration): boolean =>
          config.key === record.key,
      );

      return initialTransmissionPrioritySegment === undefined
        ? null
        : initialTransmissionPrioritySegment.source;
    },
    [initialTransmissionPrioritySegments],
  );

  const getInitialTransmissionPrioritySegmentSinkSelectValue = useCallback(
    (
      record: IEtagTransmissionPriorityConfiguration,
    ): IPointInfo | null | undefined => {
      const initialTransmissionPrioritySegment:
        | IEtagTransmissionPriorityConfiguration
        | undefined = initialTransmissionPrioritySegments.find(
        (config: IEtagTransmissionPriorityConfiguration): boolean =>
          config.key === record.key,
      );

      return initialTransmissionPrioritySegment === undefined
        ? null
        : initialTransmissionPrioritySegment.sink;
    },
    [initialTransmissionPrioritySegments],
  );

  const getInitialTransmissionPrioritySegmentOrderSelectValue = useCallback(
    (
      record: IEtagTransmissionPriorityConfiguration,
    ): number | null | undefined => {
      const initialTransmissionPrioritySegment:
        | IEtagTransmissionPriorityConfiguration
        | undefined = initialTransmissionPrioritySegments.find(
        (config: IEtagTransmissionPriorityConfiguration): boolean =>
          config.key === record.key,
      );

      return initialTransmissionPrioritySegment === undefined
        ? null
        : initialTransmissionPrioritySegment.order;
    },
    [initialTransmissionPrioritySegments],
  );

  const productOptions: IOption<any>[] = useMemo(() => {
    const options = [
      { product_name: '0-NX', product_ref: 20 },
      { product_name: '1-NS', product_ref: 4 },
      { product_name: '2-NH', product_ref: 5 },
      { product_name: '3-ND', product_ref: 6 },
      { product_name: '4-NW', product_ref: 7 },
      { product_name: '5-NM', product_ref: 8 },
      { product_name: '6-CF', product_ref: 26 },
      { product_name: '6-NN', product_ref: 9 },
      { product_name: '7-CB', product_ref: 28 },
      { product_name: '7-F', product_ref: 10 },
      { product_name: '7-FN', product_ref: 21 },
    ];

    const optionsArray: IOption<any>[] = [];

    options.forEach((option) =>
      optionsArray.push({
        label: option.product_name || '',
        value: option.product_ref || '',
      }),
    );

    return optionsArray;
  }, []);

  const entityToTpOption = registryEntityToEntityInfoOption(true, false);

  const tpOptions: IOption<IEntityInfo>[] = useMemo(
    () =>
      registryEntities
        .filter(
          (registryEntity: IRegistryEntity): boolean =>
            registryEntity['Registry Type'] === ERegistryType.TSP,
        )
        .map(entityToTpOption),
    [entityToTpOption, registryEntities],
  );

  const getTpOptions = useCallback(async () => tpOptions, [tpOptions]);

  const orderOptions = useCallback(
    (record: IEtagTransmissionPriorityConfiguration): IOption<any>[] => {
      const options: IOption<any>[] = [];

      const orderCount =
        transmissionPriorityConfigurations
          .slice()
          .filter((config) => config.pid === record.pid).length || 1;
      for (let i = 1; i <= orderCount; i++) {
        options.push({
          label: i.toString(),
          value: i.toString(),
        });
      }

      return options;
    },
    [transmissionPriorityConfigurations],
  );

  const getOrderOptions = useCallback(
    async (record: IEtagTransmissionPriorityConfiguration) =>
      orderOptions(record),
    [orderOptions],
  );
  const getProductOptions2 = useCallback(
    async () => productOptions,
    [productOptions],
  );

  const getInitialProductSelectValue = useCallback(
    (
      record: IEtagTransmissionPriorityConfiguration,
    ): number | null | undefined => {
      const initialTransmissionPrioritySegment:
        | IEtagTransmissionPriorityConfiguration
        | undefined = initialTransmissionPrioritySegments.find(
        (config: IEtagTransmissionPriorityConfiguration): boolean =>
          config.key === record.key,
      );

      return initialTransmissionPrioritySegment === undefined
        ? null
        : initialTransmissionPrioritySegment.product;
    },
    [initialTransmissionPrioritySegments],
  );

  const getPorOptions = useCallback(
    async (record: IEtagTransmissionPriorityConfiguration) => {
      if (record.tp) {
        try {
          const response: AxiosResponse<IRegistryPoint[]> =
            await retrieveRegistryPoints(
              toEntityId,
              EPointTypeName.POR,
              undefined,
              undefined,
              record.tp?.tagging_entity_id,
            );

          if (!isSuccessStatus(response.status)) {
            throw new Error(response.statusText);
          }

          const registryPoints: IRegistryPoint[] = response.data;

          return registryPoints.map(registryPointToPointInfoOption);
        } catch (error: any) {
          captureError(error);
        }
      }

      return [];
    },
    [toEntityId],
  );

  const getPodOptions = useCallback(
    async (record: IEtagTransmissionPriorityConfiguration) => {
      if (record.tp) {
        try {
          const response: AxiosResponse<IRegistryPoint[]> =
            await retrieveRegistryPoints(
              toEntityId,
              EPointTypeName.POD,
              undefined,
              undefined,
              record.tp?.tagging_entity_id,
            );

          if (!isSuccessStatus(response.status)) {
            throw new Error(response.statusText);
          }

          const registryPoints: IRegistryPoint[] = response.data;

          return registryPoints.map(registryPointToPointInfoOption);
        } catch (error: any) {
          captureError(error);
        }
      }

      return [];
    },
    [toEntityId],
  );

  const handleChange = useCallback(
    (
      editTransmissionPrioritySegmentInformation: IEditTransmissionPrioritySegmentInformation,
    ) => {
      setIsSaveDisabled(false);
      return dispatch(
        detailEditETagDetail({
          isDetailEdited: true,
          stateTransform: editTransmissionPrioritySegmentsToDetailState(
            editTransmissionPrioritySegmentInformation,
          ),
        }),
      );
    },
    [dispatch],
  );

  const handleCancel = useCallback(async () => {
    if (viewMode === EViewMode.EditETagTemplate) {
      handleChange({
        removeAllTransmissionSegments: true,
      });
      const detailStateLoadTransforms: TStateLoadTransform<IDetailState>[] = [];
      detailStateLoadTransforms.push(
        retrieveAndTransformTransmissionPriorityConfigurations(
          toEntityId,
          templateId,
        ),
      );

      dispatch(
        detailRetrieveETagDetail(
          EDetailIdType.TemplateId,
          detailStateLoadTransforms,
          false,
        ),
      );
    }

    setShowModal(false);
  }, [viewMode, dispatch, setShowModal, templateId, toEntityId, handleChange]);

  const tpOnChange = useCallback(
    (
      value: IEntityInfo | null | undefined,
      record: IEtagTransmissionPriorityConfiguration,
    ) => {
      if (record.key && !!value) {
        handleChange({
          editTransmissionPrioritySegmentMap: {
            [record.key]: {
              tp: value,
            },
          },
        });
      }
    },
    [handleChange],
  );

  const orderOnChange = useCallback(
    (
      value: number | null | undefined,
      record: IEtagTransmissionPriorityConfiguration,
    ) => {
      if (record.key) {
        setPriorities(
          priorities.filter((priority) => priority.key !== record.key),
        );
        handleChange({
          editTransmissionPrioritySegmentMap: {
            [record.key]: {
              order: value,
              key: `${record.pid}-${value}`,
            },
          },
        });
      }
    },
    [handleChange, priorities],
  );

  const productOnChange = useCallback(
    (
      value: number | null | undefined,
      record: IEtagTransmissionPriorityConfiguration,
    ) => {
      if (record.key && !!value) {
        handleChange({
          editTransmissionPrioritySegmentMap: {
            [record.key]: {
              product: value,
            },
          },
        });
      }
    },
    [handleChange],
  );

  const porOnChange = useCallback(
    (
      value: IPointInfo | null | undefined,
      record: IEtagTransmissionPriorityConfiguration,
    ) => {
      if (record.key && !!value) {
        handleChange({
          editTransmissionPrioritySegmentMap: {
            [record.key]: {
              por: value,
            },
          },
        });
      }
    },
    [handleChange],
  );

  const podOnChange = useCallback(
    (
      value: IPointInfo | null | undefined,
      record: IEtagTransmissionPriorityConfiguration,
    ) => {
      if (record.key && !!value) {
        handleChange({
          editTransmissionPrioritySegmentMap: {
            [record.key]: {
              pod: value,
            },
          },
        });
      }
    },
    [handleChange],
  );

  const sourceOnChange = useCallback(
    (
      value: IPointInfo | null | undefined,
      record: IEtagTransmissionPriorityConfiguration,
    ) => {
      if (record.key && !!value) {
        handleChange({
          editTransmissionPrioritySegmentMap: {
            [record.key]: {
              source: value,
            },
          },
        });
      }
    },
    [handleChange],
  );

  const sinkOnChange = useCallback(
    (
      value: IPointInfo | null | undefined,
      record: IEtagTransmissionPriorityConfiguration,
    ) => {
      if (record.key && !!value) {
        handleChange({
          editTransmissionPrioritySegmentMap: {
            [record.key]: {
              sink: value,
            },
          },
        });
      }
    },
    [handleChange],
  );

  const pidOnChange = useCallback(
    (
      value: number | null | undefined,
      record: IEtagTransmissionPriorityConfiguration,
    ) => {
      if (record.key && record.key !== ' ') {
        handleChange({
          removeKey: record.pid !== value ? record.key : undefined,
          editTransmissionPrioritySegmentMap: {
            //[record.key]: {
            [`${value}-${record.order || 1}`]: {
              pid: value,
              key: `${value}-${record.order || 1}`,
              //key: record.key,
              order: record.order || 1,
            },
          },
        });
        setPidOptions(pidOptions);
      } else {
        if (value) {
          handleChange({
            editTransmissionPrioritySegmentMap: {
              [`${value}-${record.order || 1}`]: {
                pid: value,
                key: `${value}-${record.order || 1}`,
                order: record.order || 1,
              },
            },
          });
        }
      }
    },
    [handleChange, pidOptions],
  );

  const shouldGetSourceOptions = useCallback(
    (
      record: IEtagTransmissionPriorityConfiguration,
      previousRecord: IEtagTransmissionPriorityConfiguration | undefined,
    ): boolean => {
      if (previousRecord) {
        return record.tp !== previousRecord.tp;
      } else {
        return true;
      }
    },
    [],
  );

  const shouldGetSinkOptions = useCallback(
    (
      record: IEtagTransmissionPriorityConfiguration,
      previousRecord: IEtagTransmissionPriorityConfiguration | undefined,
    ): boolean => {
      if (previousRecord) {
        return record.tp !== previousRecord.tp;
      } else {
        return true;
      }
    },
    [],
  );

  const shouldGetPodPorOptions = useCallback(
    (
      record: IEtagTransmissionPriorityConfiguration,
      previousRecord: IEtagTransmissionPriorityConfiguration | undefined,
    ): boolean => {
      if (previousRecord) {
        return record.tp !== previousRecord.tp;
      } else {
        return true;
      }
    },
    // !tpEntityInfoEqual(record.tp, previousRecord.tp),
    [],
  );

  const getInitialTransmissionPriorityConfigurationPidSelectValue = useCallback(
    (
      record: IEtagTransmissionPriorityConfiguration,
    ): number | null | undefined => {
      const initialTransmissionPriorityConfiguration:
        | IEtagTransmissionPriorityConfiguration
        | undefined = initialTransmissionPrioritySegments.find(
        (config: IEtagTransmissionPriorityConfiguration): boolean =>
          config.key === record.key,
      );

      return initialTransmissionPriorityConfiguration === undefined
        ? null
        : initialTransmissionPriorityConfiguration.pid;
    },
    [initialTransmissionPrioritySegments],
  );

  const getSourceOptions = useCallback(async () => {
    if (
      (eTagTemplate && eTagTemplate.tag?.tag_id?.gca !== null) ||
      eTagTemplate.tag?.tag_id?.pse !== null
    ) {
      try {
        const response: AxiosResponse<IRegistryPoint[]> =
          await retrieveRegistryPoints(
            toEntityId,
            EPointTypeName.Source,
            eTagTemplate.tag?.tag_id?.gca?.tagging_entity_id,
            eTagTemplate.tag?.tag_id?.pse?.tagging_entity_id,
          );

        if (!isSuccessStatus(response.status)) {
          throw new Error(response.statusText);
        }

        const registryPoints: IRegistryPoint[] = response.data;

        return registryPoints.map(registryPointToPointInfoOption);
      } catch (error: any) {
        captureError(error);
      }
    }

    return [];
  }, [eTagTemplate, toEntityId]);

  const getSinkOptions = useCallback(async () => {
    if (
      (eTagTemplate && eTagTemplate.tag?.tag_id?.lca !== null) ||
      eTagTemplate.tag?.tag_id?.pse !== null
    ) {
      try {
        const response: AxiosResponse<IRegistryPoint[]> =
          await retrieveRegistryPoints(
            toEntityId,
            EPointTypeName.Sink,
            eTagTemplate.tag?.tag_id?.lca?.tagging_entity_id,
            eTagTemplate.tag?.tag_id?.pse?.tagging_entity_id,
          );

        if (!isSuccessStatus(response.status)) {
          throw new Error(response.statusText);
        }

        const registryPoints: IRegistryPoint[] = response.data;

        return registryPoints.map(registryPointToPointInfoOption);
      } catch (error: any) {
        captureError(error);
      }
    }

    return [];
  }, [eTagTemplate, toEntityId]);

  const transmissionSegmentConfigurationColumns: IViewDataTableColumn<IEtagTransmissionPriorityConfiguration>[] =
    useMemo(() => {
      return [
        {
          dataIndex: 'pid',
          render:
            viewMode === EViewMode.ReviewETagTemplate
              ? getColumnRender(false)
              : transmissionPriorityConfigurationPidRender(
                  {
                    allowClear: false,
                    equalityChecker: simpleEqualityChecker,
                    getInitialValue:
                      getInitialTransmissionPriorityConfigurationPidSelectValue,
                    getOptions: getPidOptions,
                    onChange: pidOnChange,
                    valueToUid: (value: number | null | undefined): string =>
                      isEmptyValue(value) ? '' : value!.toString(),
                  },
                  viewMode,
                  initialTransmissionPriorityConfigurationIds,
                  false,
                ),
          title: 'PID',
          width: '45px',
        },
        {
          dataIndex: 'tp',
          render:
            viewMode === EViewMode.ReviewETagTemplate
              ? getColumnEntityInfoRender(false)
              : transmissionTpSelectRender({
                  allowClear: false,
                  equalityChecker: tpEntityInfoEqual,
                  filter: selectOptionLabelFilter,
                  getInitialValue:
                    getInitialTransmissionPrioritySegmentTpSelectValue,
                  getOptions: getTpOptions,
                  onChange: tpOnChange,
                  showSearch: true,
                  valueToUid: entityInfoToUid,
                }),
          title: 'TP',
          width: VIEW_DATA_TABLE_COLUMN_ENTITY_SELECT_COLUMN_WIDTH,
        },
        {
          dataIndex: 'por',
          render:
            viewMode === EViewMode.ReviewETagTemplate
              ? getColumnPointInfoRender(false)
              : porSelectRender({
                  allowClear: false,
                  filter: selectOptionLabelFilter,
                  equalityChecker: pointInfoEqual,
                  equalityCheckerForOptions: pointInfoEqual,
                  getInitialValue:
                    getInitialTransmissionPrioritySegmentPorSelectValue,
                  getOptions: getPorOptions,
                  onChange: porOnChange,
                  shouldGetOptions: shouldGetPodPorOptions,
                  showSearch: true,
                  valueToUid: pointInfoToUid,
                }),
          title: 'POR',
          width: VIEW_DATA_TABLE_COLUMN_POINT_SELECT_COLUMN_WIDTH,
        },
        {
          dataIndex: 'pod',
          render:
            viewMode === EViewMode.ReviewETagTemplate
              ? getColumnPointInfoRender(false)
              : podSelectRender({
                  allowClear: false,
                  filter: selectOptionLabelFilter,
                  equalityChecker: pointInfoEqual,
                  equalityCheckerForOptions: pointInfoEqual,
                  getInitialValue:
                    getInitialTransmissionPrioritySegmentPodSelectValue,
                  getOptions: getPodOptions,
                  onChange: podOnChange,
                  shouldGetOptions: shouldGetPodPorOptions,
                  showSearch: true,
                  valueToUid: pointInfoToUid,
                }),
          title: 'POD',
          width: VIEW_DATA_TABLE_COLUMN_POINT_SELECT_COLUMN_WIDTH,
        },
        {
          dataIndex: 'source',
          render:
            viewMode === EViewMode.ReviewETagTemplate
              ? getColumnPointInfoRender(false)
              : sourceSelectRender({
                  allowClear: false,
                  filter: selectOptionLabelFilter,
                  equalityChecker: pointInfoEqual,
                  equalityCheckerForOptions: pointInfoEqual,
                  getInitialValue:
                    getInitialTransmissionPrioritySegmentSourceSelectValue,
                  getOptions: getSourceOptions,
                  onChange: sourceOnChange,
                  shouldGetOptions: shouldGetSourceOptions,
                  showSearch: true,
                  valueToUid: pointInfoToUid,
                }),
          title: 'Source',
          width: VIEW_DATA_TABLE_COLUMN_POINT_SELECT_COLUMN_WIDTH,
        },
        {
          dataIndex: 'sink',
          render:
            viewMode === EViewMode.ReviewETagTemplate
              ? getColumnPointInfoRender(false)
              : sinkSelectRender({
                  allowClear: false,
                  filter: selectOptionLabelFilter,
                  equalityChecker: pointInfoEqual,
                  equalityCheckerForOptions: pointInfoEqual,
                  getInitialValue:
                    getInitialTransmissionPrioritySegmentSinkSelectValue,
                  getOptions: getSinkOptions,
                  onChange: sinkOnChange,
                  shouldGetOptions: shouldGetSinkOptions,
                  showSearch: true,
                  valueToUid: pointInfoToUid,
                }),
          title: 'Sink',
          width: VIEW_DATA_TABLE_COLUMN_POINT_SELECT_COLUMN_WIDTH,
        },
        {
          dataIndex: 'product',
          render:
            viewMode === EViewMode.ReviewETagTemplate
              ? getColumnRender(false)
              : transmissionPrioritySegmentProductRefRender({
                  allowClear: false,
                  equalityChecker: simpleEqualityChecker,
                  getInitialValue: getInitialProductSelectValue,
                  getOptions: getProductOptions2,
                  onChange: productOnChange,
                  valueToUid: (value: number | null | undefined): string =>
                    isEmptyValue(value) ? '' : value!.toString(),
                }),
          title: 'Product',
          width: VIEW_DATA_TABLE_COLUMN_PRODUCT_SELECT_COLUMN_WIDTH,
        },
        {
          dataIndex: 'order',
          render:
            viewMode === EViewMode.ReviewETagTemplate
              ? getColumnRender(false)
              : transmissionOrderSelectRender({
                  allowClear: false,
                  equalityChecker: simpleEqualityChecker,
                  getInitialValue:
                    getInitialTransmissionPrioritySegmentOrderSelectValue,
                  getOptions: getOrderOptions,
                  onChange: orderOnChange,
                  valueToUid: (value: number | null | undefined): string =>
                    isEmptyValue(value) ? '' : value!.toString(),
                }),
          title: 'Order',
          width: '40px',
          shouldCellUpdate: (): boolean => true,
        },
      ];
    }, [
      getInitialProductSelectValue,
      getPidOptions,
      getInitialTransmissionPriorityConfigurationPidSelectValue,
      getInitialTransmissionPrioritySegmentOrderSelectValue,
      getInitialTransmissionPrioritySegmentSinkSelectValue,
      getInitialTransmissionPrioritySegmentSourceSelectValue,
      getInitialTransmissionPrioritySegmentTpSelectValue,
      getInitialTransmissionPrioritySegmentPorSelectValue,
      getInitialTransmissionPrioritySegmentPodSelectValue,
      getPodOptions,
      podOnChange,
      getPorOptions,
      porOnChange,
      getTpOptions,
      tpOnChange,
      getSourceOptions,
      sourceOnChange,
      getSinkOptions,
      sinkOnChange,
      getOrderOptions,
      getProductOptions2,
      initialTransmissionPriorityConfigurationIds,
      orderOnChange,
      pidOnChange,
      productOnChange,
      viewMode,
      shouldGetPodPorOptions,
      shouldGetSinkOptions,
      shouldGetSourceOptions,
    ]);

  const onAdd = (
    value: unknown,
    record: IEtagTransmissionPriorityConfiguration,
  ) => {
    handleChange({
      addAfterTransmissionPrioritySegment: record.key,
    });
  };

  const onRemove = (record: IEtagTransmissionPriorityConfiguration) => {
    const key = record.key;
    handleChange({
      removeTransmissionPrioritySegment: key,
    });
    return;
  };

  const handleSave = async () => {
    setSaveActionState(EActionState.Actioning);
    try {
      if (toEntity && toEntity.entity_code && templateId) {
        const response = await saveTransmissionPrioritySegment(
          toEntityId,
          templateId,
          transmissionPriorityConfigurations,
        );
        if (response.status === FORBIDDEN_STATUS_CODE) {
          setSaveActionState(EActionState.Failed);
        } else {
          setSaveActionState(EActionState.Succeeded);
        }
      } else {
        setSaveActionState(EActionState.Failed);
      }
    } catch (e) {
      setSaveActionState(EActionState.Failed);
    }
  };

  const initialiser = useCallback(
    () => getInitialTransmissionPriorityConfiguration(),
    [],
  );

  const getInitialTransmissionPriorityConfiguration =
    (): IEtagTransmissionPriorityConfiguration => ({
      key: '',
      pid: null,
      tp: null,
      por: null,
      pod: null,
      source: null,
      sink: null,
      order: null,
      product: null,
    });

  const allowRowRemove = (): boolean => {
    return viewMode !== EViewMode.ReviewETagTemplate;
  };

  const allowRowAdd = (
    value: unknown,
    record: IEtagTransmissionPriorityConfiguration,
  ): boolean => {
    return viewMode !== EViewMode.ReviewETagTemplate && !!record.pid;
  };

  return (
    <>
      <Tooltip
        isDisabled={showModal}
        placement={tooltipPlacement}
        title={title}
      >
        <IconButton
          buttonRef={ref}
          className={'configuration-toolbar-btn'}
          icon={icon}
          isDisabled={isDisabled}
          onClick={handleShow}
        />
      </Tooltip>
      <Modal
        footer={
          viewMode === EViewMode.EditETagTemplate ? (
            <SeparatedRowLayout>
              <ButtonContainer>
                <Button label='Cancel' onClick={handleCancel}></Button>
                <Button
                  actionState={saveActionState}
                  isPrimary={true}
                  label='Save'
                  onClick={handleSave}
                  isDisabled={isSaveDisabled}
                ></Button>
              </ButtonContainer>
            </SeparatedRowLayout>
          ) : null
        }
        isVisible={showModal}
        onCancel={handleCancel}
        title={title}
        width={1000}
      >
        <EditableDataTable<IEtagTransmissionPriorityConfiguration>
          addButtonOffset={PRIORITIES_ADD_BUTTON_OFFSET}
          maximumAllowableAdds={-1}
          columns={transmissionSegmentConfigurationColumns}
          data={priorities}
          hideExpandIconColumn={true}
          pagination={false}
          rowKey={(config: IEtagTransmissionPriorityConfiguration): string =>
            `${config.pid}-${config.order}`
          }
          allowRowRemove={allowRowRemove}
          allowRowAdd={allowRowAdd}
          removeButtonOffset={PRIORITIES_REMOVE_BUTTON_OFFSET}
          onAdd={onAdd}
          onRemove={onRemove}
          initialiser={initialiser}
          tableLayout='fixed'
        />
      </Modal>
    </>
  );
};

export default TransmissionPriorityConfiguration;
