import ProfileDataGridColumnHeader from 'components/molecules/ProfileDataGridColumnHeader/ProfileDataGridColumnHeader';
import ProfileDataGridCellEdit from 'components/organisms/ProfileDataGridCellEdit/ProfileDataGridCellEdit';
import ProfileDataGridCellReview from 'components/organisms/ProfileDataGridCellReview/ProfileDataGridCellReview';
import {
  EDITOR_OPTIONS,
  PROFILE_PATH_RAMP_START_HEADER_NAME,
  PROFILE_PATH_RAMP_STOP_HEADER_NAME,
  PROFILE_PATH_START_HEADER_NAME,
  PROFILE_PATH_STOP_HEADER_NAME,
} from 'components/organisms/ProfileInformationView/constants';
import {
  getTransmissionColumns,
  stopDisabledDateForRow,
} from 'components/organisms/ProfileInformationView/helpers';
import {
  PROFILE_PATH_RAMP_START_KEY,
  PROFILE_PATH_RAMP_STOP_KEY,
  PROFILE_PATH_START_KEY,
  PROFILE_PATH_STOP_KEY,
  TRANSMISSION_ALLOC_TOTALS_KEY_SUFFIX,
  TRANSMISSION_LOSS_KEY_SUFFIX,
  TRANSMISSION_POD_KEY_SUFFIX,
  TRANSMISSION_POR_KEY_SUFFIX,
} from 'constants/Detail';
import { LEFT_SEPARATOR_CLASS, RIGHT_SEPARATOR_CLASS } from 'constants/General';
import {
  DATA_GRID_CELL_CONTAINER,
  DATA_GRID_DATA_COLUMN_WIDTH_VALUE,
  DATA_GRID_DATE_TIME_COLUMN_WIDTH_VALUE,
} from 'constants/styles';
import { SUMMARY_PROFILE_TOTAL_LABEL } from 'constants/Summary';
import {
  EProfileDataGridCellType,
  EProfileTimeSplit,
  ETableConfiguration,
} from 'enums/Detail';
import { EViewMode } from 'enums/View';
import { IDataGridSelectionContext } from 'interfaces/Component';
import { IProfileDataGridCell } from 'interfaces/Detail';
import {
  IETagPhysicalSegmentProfile,
  IETagPhysicalSegmentsProfile,
  IETagTransmissionAllocation,
  IETagTransmissionPhysicalSegment,
  IETagTransmissionPhysicalSegmentProfile,
  IETagTransmissionSegment,
} from 'interfaces/ETag';
import { IEnergyProfile } from 'interfaces/General';
import { Context } from 'react';
import {
  EditorProps,
  FormatterProps,
  SummaryFormatterProps,
} from 'react-data-grid';
import { IDetailGenerationPhysicalSegment } from 'reduxes/Detail/types';
import styled from 'styled-components';
import {
  TProfileDataGridColumn,
  TProfileDataGridRow,
  TProfileDataGridSummaryRow,
} from 'types/Detail';
import {
  getAdjustedContractNumber,
  getBackgroundColourForEnergyProfile,
  getGenerationMarketLevelKey,
  getIsReviewingProfileDataGrid,
  getKeyForProfileTransmission,
  getProfileMwForTransAllocId,
  getTransmissionAllocationTotal,
  getTransmissionName,
  getTransmissionSegmentFromTransmissionPhysicalSegmentProfile,
} from 'utils/detail';
import { isEmptyValue } from 'utils/general';
import { ZonedDateTime } from '../../utils/zonedDateTime';
import { TTimeZone } from '../../types/DateTime';

const SummaryCell = styled.div`
  ${DATA_GRID_CELL_CONTAINER}
`;

export const getProfileInformationColumns = (
  transmissionPhysicalSegments: IETagTransmissionPhysicalSegment[] | null,
  sortedTransmissionAllocations: IETagTransmissionAllocation[],
  generationPhysicalSegment: IDetailGenerationPhysicalSegment | null,
  generationPhysicalSegmentName: string,
  loadPhysicalSegmentName: string,
  showReliability: boolean | undefined,
  selectedTableConfiguration: ETableConfiguration | undefined,
  useUniqueProfiles: boolean,
  viewMode: EViewMode,
  isDetailDeleted: boolean,
  isDetailUpdating: boolean,
  initialProfileInformationDataSet: TProfileDataGridRow[] | undefined,
  show_losses: boolean | null,
  isPrintView?: boolean,
): TProfileDataGridColumn[] => {
  const allowEdit: boolean =
    (viewMode === EViewMode.EditETagDraft ||
      viewMode === EViewMode.EditETagTemplate) &&
    !isDetailDeleted &&
    !isDetailUpdating;
  const isReviewing: boolean = getIsReviewingProfileDataGrid(viewMode);
  const isEditingUniqueProfiles: boolean =
    (viewMode === EViewMode.EditETagDraft ||
      viewMode === EViewMode.EditETagTemplate) &&
    useUniqueProfiles;
  const marketSuffix: string =
    viewMode === EViewMode.EditETagDraft ? 'Request' : 'Market';

  const getEditor = allowEdit
    ? (DataGridSelectionContext: Context<IDataGridSelectionContext>) =>
        (
          props: EditorProps<TProfileDataGridRow, TProfileDataGridSummaryRow>,
        ): JSX.Element =>
          (
            <ProfileDataGridCellEdit
              {...props}
              DataGridSelectionContext={DataGridSelectionContext}
              initialDataSet={initialProfileInformationDataSet}
            />
          )
    : undefined;
  const getFormatter =
    (DataGridSelectionContext: Context<IDataGridSelectionContext>) =>
    (
      props: FormatterProps<TProfileDataGridRow, TProfileDataGridSummaryRow>,
    ): JSX.Element =>
      (
        <ProfileDataGridCellReview
          {...props}
          DataGridSelectionContext={DataGridSelectionContext}
          initialDataSet={initialProfileInformationDataSet}
        />
      );

  const getCurrentFormatter =
    (DataGridSelectionContext: Context<IDataGridSelectionContext>) =>
    (
      props: FormatterProps<TProfileDataGridRow, TProfileDataGridSummaryRow>,
    ): JSX.Element =>
      (
        <ProfileDataGridCellReview
          {...props}
          DataGridSelectionContext={DataGridSelectionContext}
          getBackgroundColour={getBackgroundColourForEnergyProfile}
          initialDataSet={initialProfileInformationDataSet}
        />
      );

  const summaryFormatter = (
    props: SummaryFormatterProps<
      TProfileDataGridSummaryRow,
      TProfileDataGridRow
    >,
  ): JSX.Element => <SummaryCell>{props.row[props.column.key]}</SummaryCell>;

  let profileInformationColumns: TProfileDataGridColumn[] = [
    {
      editorOptions: EDITOR_OPTIONS,
      getEditor,
      getFormatter,
      frozen: true,
      headerRenderer: ProfileDataGridColumnHeader,
      key: PROFILE_PATH_START_KEY,
      name: PROFILE_PATH_START_HEADER_NAME,
      summaryFormatter: () => <div>{SUMMARY_PROFILE_TOTAL_LABEL}</div>,
      width: DATA_GRID_DATE_TIME_COLUMN_WIDTH_VALUE,
    },
    {
      editorOptions: EDITOR_OPTIONS,
      frozen: true,
      getEditor:
        (DataGridSelectionContext: Context<IDataGridSelectionContext>) =>
        (
          props: EditorProps<TProfileDataGridRow, TProfileDataGridSummaryRow>,
        ): JSX.Element =>
          (
            <ProfileDataGridCellEdit
              {...props}
              DataGridSelectionContext={DataGridSelectionContext}
              disabledDate={stopDisabledDateForRow(props.row)}
            />
          ),
      getFormatter,
      headerRenderer: ProfileDataGridColumnHeader,
      key: PROFILE_PATH_STOP_KEY,
      name: PROFILE_PATH_STOP_HEADER_NAME,
      width: DATA_GRID_DATE_TIME_COLUMN_WIDTH_VALUE,
    },
  ];

  // Generation Columns
  if (isReviewing) {
    profileInformationColumns.push({
      getFormatter: getCurrentFormatter,
      headerRenderer: ProfileDataGridColumnHeader,
      key: `gen:${generationPhysicalSegmentName}:current`,
      name: `Gen\n${generationPhysicalSegmentName}\nCurrent`,
      resizable: true,
      summaryFormatter,
      width: DATA_GRID_DATA_COLUMN_WIDTH_VALUE,
    });
  }

  const generationGetEditor =
    generationPhysicalSegment === null ? undefined : getEditor;

  if (showReliability) {
    profileInformationColumns.push(
      {
        editorOptions: EDITOR_OPTIONS,
        getEditor: generationGetEditor,
        getFormatter,
        headerRenderer: ProfileDataGridColumnHeader,
        key: getGenerationMarketLevelKey(generationPhysicalSegmentName),
        name: `Gen\n${generationPhysicalSegmentName}\n${marketSuffix}`,
        resizable: true,
        summaryFormatter,
        width: DATA_GRID_DATA_COLUMN_WIDTH_VALUE,
      },
      {
        getFormatter,
        headerRenderer: ProfileDataGridColumnHeader,
        key: `gen:${generationPhysicalSegmentName}:reliability`,
        name: `Gen\n${generationPhysicalSegmentName}\nReliability`,
        resizable: true,
        summaryFormatter,
        width: DATA_GRID_DATA_COLUMN_WIDTH_VALUE,
      },
    );
  } else {
    profileInformationColumns.push({
      editorOptions: EDITOR_OPTIONS,
      getEditor: generationGetEditor,
      getFormatter,
      headerRenderer: ProfileDataGridColumnHeader,
      key: getGenerationMarketLevelKey(generationPhysicalSegmentName),
      name: `Gen\n${generationPhysicalSegmentName}\n${marketSuffix}`,
      resizable: true,
      summaryFormatter,
      width: DATA_GRID_DATA_COLUMN_WIDTH_VALUE,
    });
  }

  // Transmission Columns
  profileInformationColumns = profileInformationColumns.concat(
    getTransmissionColumns(
      transmissionPhysicalSegments,
      sortedTransmissionAllocations,
      selectedTableConfiguration,
      isEditingUniqueProfiles,
      getCurrentFormatter,
      getFormatter,
      getEditor,
      summaryFormatter,
      true,
      show_losses || undefined,
      isPrintView,
    ),
  );

  // Load Columns
  if (show_losses) {
    const loadCurrentColumn: TProfileDataGridColumn = {
      getFormatter: getCurrentFormatter,
      headerRenderer: ProfileDataGridColumnHeader,
      key: `load:${loadPhysicalSegmentName}:current`,
      name: `Load\n${loadPhysicalSegmentName}\nCurrent`,
      resizable: true,
      summaryFormatter,
      width: DATA_GRID_DATA_COLUMN_WIDTH_VALUE,
    };

    if (sortedTransmissionAllocations.length > 0) {
      loadCurrentColumn.cellClass = LEFT_SEPARATOR_CLASS;
      loadCurrentColumn.headerCellClass = LEFT_SEPARATOR_CLASS;
      loadCurrentColumn.summaryCellClass = LEFT_SEPARATOR_CLASS;
    }

    profileInformationColumns.push(loadCurrentColumn);

    if (showReliability) {
      profileInformationColumns.push({
        getFormatter,
        headerRenderer: ProfileDataGridColumnHeader,
        key: `load:${loadPhysicalSegmentName}:reliability`,
        name: `Load\n${loadPhysicalSegmentName}\nReliability`,
        resizable: true,
        summaryFormatter,
        width: DATA_GRID_DATA_COLUMN_WIDTH_VALUE,
      });
    }
  }

  profileInformationColumns.push(
    {
      editorOptions: EDITOR_OPTIONS,
      getEditor,
      getFormatter,
      headerRenderer: ProfileDataGridColumnHeader,
      key: PROFILE_PATH_RAMP_START_KEY,
      name: PROFILE_PATH_RAMP_START_HEADER_NAME,
      width: DATA_GRID_DATA_COLUMN_WIDTH_VALUE,
    },
    {
      cellClass: RIGHT_SEPARATOR_CLASS,
      editorOptions: EDITOR_OPTIONS,
      getEditor,
      getFormatter,
      headerCellClass: RIGHT_SEPARATOR_CLASS,
      headerRenderer: ProfileDataGridColumnHeader,
      key: PROFILE_PATH_RAMP_STOP_KEY,
      name: PROFILE_PATH_RAMP_STOP_HEADER_NAME,
      summaryCellClass: RIGHT_SEPARATOR_CLASS,
      summaryFormatter,
      width: DATA_GRID_DATA_COLUMN_WIDTH_VALUE,
    },
  );

  return profileInformationColumns;
};

const updateProfileInformationSummaryRow = (
  profileInformationSummaryRow: TProfileDataGridSummaryRow,
  physicalSegmentsProfilesRow: TProfileDataGridRow,
  profileName: string,
) => {
  const cell: IProfileDataGridCell | null | undefined =
    physicalSegmentsProfilesRow[profileName];

  if (cell === undefined) {
    throw new Error(
      `Invalid profileName: ${profileName} for physicalSegmentsProfilesRow: ${JSON.stringify(
        physicalSegmentsProfilesRow,
      )}`,
    );
  }

  if (profileInformationSummaryRow[profileName] === undefined) {
    profileInformationSummaryRow[profileName] = 0;
  }

  if (!isEmptyValue(cell)) {
    if (cell!.type === EProfileDataGridCellType.Number) {
      profileInformationSummaryRow[profileName]! += cell!.value as number;
    } else if (
      cell!.type === EProfileDataGridCellType.EnergyProfile &&
      (cell!.value as IEnergyProfile).mw !== null
    ) {
      profileInformationSummaryRow[profileName]! += (
        cell!.value as IEnergyProfile
      ).mw!;
    }
  }
};

const getProfileInformation = (
  generationPhysicalSegmentName: string,
  loadPhysicalSegmentName: string,
  physicalSegmentsProfiles: IETagPhysicalSegmentsProfile[] | null,
  transmissionPhysicalSegments: IETagTransmissionPhysicalSegment[] | null,
  sortedTransmissionAllocations: IETagTransmissionAllocation[],
  includeDataSet: boolean = true,
  includeSummaryRow: boolean = true,
  show_losses: boolean | null,
  editedValues?: any,
  profileTimeSplit?: EProfileTimeSplit,
  timeZone?: TTimeZone,
  setUpdatedGridRows?: (rows: any[]) => void,
) => {
  const updatedGridRows: any[] = [];
  let hasGenerationReliability: boolean = false;
  let hasLoadReliability: boolean = false;
  const profileInformationDataSet: TProfileDataGridRow[] = [];
  const profileInformationSummaryRow: TProfileDataGridSummaryRow = {};

  let primaryId: number = 1;

  if (editedValues) {
    editedValues.originalRows.forEach((value: any) => {
      profileInformationDataSet.push(value.updatedRow);
    });
  }

  if (
    physicalSegmentsProfiles !== null &&
    physicalSegmentsProfiles.length > 0
  ) {
    physicalSegmentsProfiles.forEach(
      (eTagPhysicalSegmentsProfile: IETagPhysicalSegmentsProfile) => {
        const { start, stop } = eTagPhysicalSegmentsProfile;
        const generationCurrentName: string = `gen:${generationPhysicalSegmentName}:current`;
        const generationMarketLevelName: string = getGenerationMarketLevelKey(
          generationPhysicalSegmentName,
        );
        const generationReliabilityName: string = `gen:${generationPhysicalSegmentName}:reliability`;
        const loadCurrentName: string = `load:${loadPhysicalSegmentName}:current`;
        const loadMarketLevelName: string = `load:${loadPhysicalSegmentName}:marketLevel`;
        const loadReliabilityName: string = `load:${loadPhysicalSegmentName}:reliability`;

        let generation: IETagPhysicalSegmentProfile | null = null;
        let load: IETagPhysicalSegmentProfile | null = null;
        let transmission: IETagTransmissionPhysicalSegmentProfile | null = null;

        if (eTagPhysicalSegmentsProfile.physical_segments_profiles !== null) {
          generation =
            eTagPhysicalSegmentsProfile.physical_segments_profiles.generation;
          load = eTagPhysicalSegmentsProfile.physical_segments_profiles.load;
          transmission =
            eTagPhysicalSegmentsProfile.physical_segments_profiles.transmission;
        }

        let physicalSegmentsProfilesRow: TProfileDataGridRow = {
          id: {
            type: EProfileDataGridCellType.String,
            value: eTagPhysicalSegmentsProfile.key,
          },
          [PROFILE_PATH_START_KEY]: {
            type: EProfileDataGridCellType.DateTimeString,
            value: start === null ? '' : start,
          },
          [PROFILE_PATH_STOP_KEY]: {
            type: EProfileDataGridCellType.DateTimeString,
            value: stop === null ? '' : stop,
          },
          [PROFILE_PATH_RAMP_START_KEY]:
            generation === null ||
            generation.profile === null ||
            generation.profile.start_ramp_dur === null
              ? null
              : {
                  type: EProfileDataGridCellType.Number,
                  value: generation.profile.start_ramp_dur,
                },
          [PROFILE_PATH_RAMP_STOP_KEY]:
            generation === null ||
            generation.profile === null ||
            generation.profile.stop_ramp_dur === null
              ? null
              : {
                  type: EProfileDataGridCellType.Number,
                  value: generation.profile.stop_ramp_dur,
                },
          [generationCurrentName]:
            generation === null || generation.profile === null
              ? null
              : {
                  type: EProfileDataGridCellType.EnergyProfile,
                  value: generation.profile,
                },
          [generationMarketLevelName]:
            generation === null ||
            generation.profile === null ||
            generation.profile.market_level === null
              ? null
              : {
                  type: EProfileDataGridCellType.Number,
                  value: generation.profile.market_level,
                },
          [generationReliabilityName]:
            generation === null ||
            generation.profile === null ||
            generation.profile.reliability_limit === null
              ? null
              : {
                  type: EProfileDataGridCellType.Number,
                  value: generation.profile.reliability_limit,
                },
          [loadCurrentName]:
            load === null || load.profile === null
              ? null
              : {
                  type: EProfileDataGridCellType.EnergyProfile,
                  value: load.profile,
                },
          [loadMarketLevelName]:
            load === null ||
            load.profile === null ||
            load.profile.market_level === null
              ? null
              : {
                  type: EProfileDataGridCellType.Number,
                  value: load.profile.market_level,
                },
          [loadReliabilityName]:
            load === null ||
            load.profile === null ||
            load.profile.reliability_limit === null
              ? null
              : {
                  type: EProfileDataGridCellType.Number,
                  value: load.profile.reliability_limit,
                },
        };

        if (physicalSegmentsProfilesRow[generationReliabilityName] !== null) {
          hasGenerationReliability = true;
        }

        if (physicalSegmentsProfilesRow[loadReliabilityName] !== null) {
          hasLoadReliability = true;
        }

        if (includeSummaryRow) {
          updateProfileInformationSummaryRow(
            profileInformationSummaryRow,
            physicalSegmentsProfilesRow,
            generationCurrentName,
          );

          updateProfileInformationSummaryRow(
            profileInformationSummaryRow,
            physicalSegmentsProfilesRow,
            generationMarketLevelName,
          );

          updateProfileInformationSummaryRow(
            profileInformationSummaryRow,
            physicalSegmentsProfilesRow,
            generationReliabilityName,
          );

          updateProfileInformationSummaryRow(
            profileInformationSummaryRow,
            physicalSegmentsProfilesRow,
            loadCurrentName,
          );

          updateProfileInformationSummaryRow(
            profileInformationSummaryRow,
            physicalSegmentsProfilesRow,
            loadMarketLevelName,
          );

          updateProfileInformationSummaryRow(
            profileInformationSummaryRow,
            physicalSegmentsProfilesRow,
            loadReliabilityName,
          );
        }

        let previousPid: number | null = null;
        let transmissionName: string = '';

        sortedTransmissionAllocations.forEach(
          (eTagTransmissionAllocation: IETagTransmissionAllocation) => {
            const { physical_segment_ref, trans_alloc_id } =
              eTagTransmissionAllocation;
            const adjustedContractNumber: string = getAdjustedContractNumber(
              eTagTransmissionAllocation,
            );

            if (physical_segment_ref !== previousPid) {
              previousPid = physical_segment_ref;
              transmissionName = getTransmissionName(
                eTagTransmissionAllocation,
                transmissionPhysicalSegments,
              );
            }

            const key: string = getKeyForProfileTransmission({
              adjustedContractNumber,
              physicalSegmentRef: physical_segment_ref,
              transAllocId: trans_alloc_id,
              transmissionName,
            });

            const profileMw: number | null = getProfileMwForTransAllocId(
              transmission,
              trans_alloc_id,
              physical_segment_ref || 0,
              show_losses || false,
            );

            physicalSegmentsProfilesRow[key] =
              profileMw === null
                ? null
                : {
                    type: EProfileDataGridCellType.Number,
                    value: profileMw,
                  };

            const eTagTransmissionSegment: IETagTransmissionSegment | null =
              getTransmissionSegmentFromTransmissionPhysicalSegmentProfile(
                physical_segment_ref,
                transmission,
              );

            const transmissionPorEnergyProfileName: string = `${key}:${TRANSMISSION_POR_KEY_SUFFIX}`;

            physicalSegmentsProfilesRow[transmissionPorEnergyProfileName] =
              eTagTransmissionSegment === null ||
              eTagTransmissionSegment.por_energy_profile === null
                ? null
                : {
                    type: EProfileDataGridCellType.EnergyProfile,
                    value: eTagTransmissionSegment.por_energy_profile,
                  };

            const transmissionPodEnergyProfileName: string = `${key}:${TRANSMISSION_POD_KEY_SUFFIX}`;

            physicalSegmentsProfilesRow[transmissionPodEnergyProfileName] =
              eTagTransmissionSegment === null ||
              eTagTransmissionSegment.pod_energy_profile === null
                ? null
                : {
                    type: EProfileDataGridCellType.EnergyProfile,
                    value: eTagTransmissionSegment.pod_energy_profile,
                  };

            const transmissionLossName: string = `${key}:${TRANSMISSION_LOSS_KEY_SUFFIX}`;

            physicalSegmentsProfilesRow[transmissionLossName] =
              eTagTransmissionSegment === null ||
              eTagTransmissionSegment.por_energy_profile === null ||
              eTagTransmissionSegment.por_energy_profile.mw === null ||
              eTagTransmissionSegment.pod_energy_profile === null ||
              eTagTransmissionSegment.pod_energy_profile.mw === null
                ? null
                : {
                    type: EProfileDataGridCellType.Number,
                    value:
                      eTagTransmissionSegment.por_energy_profile.mw -
                      eTagTransmissionSegment.pod_energy_profile.mw,
                  };

            const transmissionProfileTotalName: string = `${key}:${TRANSMISSION_ALLOC_TOTALS_KEY_SUFFIX}`;

            const allocTotal: number | null = getTransmissionAllocationTotal(
              eTagTransmissionSegment,
            );

            physicalSegmentsProfilesRow[transmissionProfileTotalName] =
              allocTotal === null
                ? null
                : {
                    type: EProfileDataGridCellType.Number,
                    value: allocTotal,
                  };

            if (includeSummaryRow) {
              updateProfileInformationSummaryRow(
                profileInformationSummaryRow,
                physicalSegmentsProfilesRow,
                transmissionPorEnergyProfileName,
              );

              updateProfileInformationSummaryRow(
                profileInformationSummaryRow,
                physicalSegmentsProfilesRow,
                transmissionPodEnergyProfileName,
              );

              updateProfileInformationSummaryRow(
                profileInformationSummaryRow,
                physicalSegmentsProfilesRow,
                transmissionLossName,
              );

              updateProfileInformationSummaryRow(
                profileInformationSummaryRow,
                physicalSegmentsProfilesRow,
                transmissionProfileTotalName,
              );

              updateProfileInformationSummaryRow(
                profileInformationSummaryRow,
                physicalSegmentsProfilesRow,
                key,
              );
            }
          },
        );

        if (includeDataSet) {
          if (editedValues && start && stop && timeZone) {
            const startDateTime = ZonedDateTime.parseIso(start, timeZone);
            const stopDateTime = ZonedDateTime.parseIso(stop, timeZone);
            if (
              startDateTime.isSameOrAfter(editedValues.startDateTime) &&
              stopDateTime.isSameOrBefore(editedValues.stopDateTime)
            ) {
              editedValues.carryOverValues.forEach((value: any) => {
                if (
                  startDateTime.getHour() === value.startDate?.getHour() &&
                  startDateTime.getMinute() === value.startDate?.getMinute()
                ) {
                  const keys = Object.keys(value.editedRow.updatedRow);
                  let carryOverRow: TProfileDataGridRow = {};
                  keys.forEach((key) => {
                    carryOverRow[key] = value.editedRow.updatedRow[key];
                  });
                  if (carryOverRow) {
                    const pathStart =
                      physicalSegmentsProfilesRow[PROFILE_PATH_START_KEY];
                    const pathStop =
                      physicalSegmentsProfilesRow[PROFILE_PATH_STOP_KEY];
                    physicalSegmentsProfilesRow = carryOverRow;
                    physicalSegmentsProfilesRow['id'] = {
                      type: EProfileDataGridCellType.String,
                      value: `profileChangeRowEdit:${primaryId}:0`,
                    };
                    physicalSegmentsProfilesRow[PROFILE_PATH_START_KEY] =
                      pathStart;
                    physicalSegmentsProfilesRow[PROFILE_PATH_STOP_KEY] =
                      pathStop;
                  }
                }
              });
            }
            const isRowEmpty = isProfileRowEmpty(physicalSegmentsProfilesRow);
            if (!isRowEmpty) {
              profileInformationDataSet.push(physicalSegmentsProfilesRow);
              updatedGridRows.push({ updatedRow: physicalSegmentsProfilesRow });
              primaryId += 1;
            }
            if (setUpdatedGridRows) {
              if (editedValues?.originalRows) {
                setUpdatedGridRows(
                  editedValues?.originalRows.concat(updatedGridRows),
                );
              } else {
                setUpdatedGridRows(updatedGridRows);
              }
            }
          } else {
            profileInformationDataSet.push(physicalSegmentsProfilesRow);
          }
        }
      },
    );
  }

  return {
    hasGenerationReliability,
    hasLoadReliability,
    profileInformationDataSet,
    profileInformationSummaryRows: [profileInformationSummaryRow],
  };
};

const isProfileRowEmpty = (row: any): boolean => {
  for (const [key, value] of Object.entries(row)) {
    const prop = value as any;
    let isEmpty = true;
    if (key !== 'id' && key !== 'path-start' && key !== 'path-stop') {
      if (prop) {
        if (prop.type === 'number' && prop.value > 0) {
          isEmpty = false;
        }
      }
    }

    if (!isEmpty) {
      return false;
    }
  }
  return true;
};

export const getProfileInformationData = (
  generationPhysicalSegmentName: string,
  loadPhysicalSegmentName: string,
  physicalSegmentsProfiles: IETagPhysicalSegmentsProfile[] | null,
  transmissionPhysicalSegments: IETagTransmissionPhysicalSegment[] | null,
  sortedTransmissionAllocations: IETagTransmissionAllocation[],
  summaryRowPhysicalSegmentsProfiles: IETagPhysicalSegmentsProfile[] | null,
  show_losses: boolean | null,
  editedValues?: any,
  timeZone?: TTimeZone,
  setUpdatedGridRows?: (rows: any[]) => void,
) => {
  if (summaryRowPhysicalSegmentsProfiles !== null) {
    const {
      hasGenerationReliability,
      hasLoadReliability,
      profileInformationDataSet,
    } = getProfileInformation(
      generationPhysicalSegmentName,
      loadPhysicalSegmentName,
      physicalSegmentsProfiles,
      transmissionPhysicalSegments,
      sortedTransmissionAllocations,
      true,
      false,
      show_losses,
      editedValues,
      EProfileTimeSplit.Hourly,
      timeZone,
      setUpdatedGridRows,
    );

    const { profileInformationSummaryRows } = getProfileInformation(
      generationPhysicalSegmentName,
      loadPhysicalSegmentName,
      summaryRowPhysicalSegmentsProfiles,
      transmissionPhysicalSegments,
      sortedTransmissionAllocations,
      false,
      true,
      show_losses,
    );

    return {
      hasGenerationReliability,
      hasLoadReliability,
      profileInformationDataSet,
      profileInformationSummaryRows,
    };
  }

  return getProfileInformation(
    generationPhysicalSegmentName,
    loadPhysicalSegmentName,
    physicalSegmentsProfiles,
    transmissionPhysicalSegments,
    sortedTransmissionAllocations,
    true,
    true,
    show_losses,
  );
};
