import ReviewDataTable from 'components/molecules/ReviewDataTable/ReviewDataTable';
import { LOSS_METHODS_TITLE } from 'components/organisms/PhysicalPathReview/constants';
import { DETAIL_REVIEW_FLOAT_OVER_MAXIMUM_HEIGHT } from 'constants/styles';
import { ERetreiveState } from 'enums/General';
import usePrevious from 'hooks/usePrevious';
import { IViewDataTableColumn } from 'interfaces/View';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { IDetailLossAccounting } from 'reduxes/Detail/types';
import { TTimeZone } from 'types/DateTime';
import { TRootState } from 'types/Redux';
import { copyDetailLossAccounting } from 'utils/detail';
import { getLossMethodsReviewColumns } from 'utils/LossMethods';
import { getDetailToEntityUserSelectedTimeZone } from 'utils/user';

interface ILossMethodsReviewProps {
  allowPopover: boolean;
  isUnconstrained?: boolean;
}

const retrieveLossMethodsReviewState = (state: TRootState) => {
  const { lossAccountings, retrievingDetail } = state.detail.present;
  const isLoading: boolean =
    retrievingDetail !== ERetreiveState.NotRetrieving &&
    retrievingDetail !== ERetreiveState.RetrievingCompleted;
  const timeZone: TTimeZone = getDetailToEntityUserSelectedTimeZone(state);

  return {
    isLoading,
    lossAccountings,
    timeZone,
  };
};

const LossMethodsReview = ({
  allowPopover,
  isUnconstrained = false,
}: ILossMethodsReviewProps): JSX.Element => {
  const { isLoading, lossAccountings, timeZone } = useSelector(
    retrieveLossMethodsReviewState,
  );
  const previousTimeZone: string | undefined = usePrevious(timeZone);

  const adjustedLossAccountings = useMemo(
    () =>
      (previousTimeZone === timeZone
        ? lossAccountings
        : lossAccountings.map(copyDetailLossAccounting)
      ).filter(
        (detailLossAccounting: IDetailLossAccounting): boolean =>
          detailLossAccounting.physical_segment_ref !== null ||
          detailLossAccounting.lossMethod !== null,
      ),
    [lossAccountings, previousTimeZone, timeZone],
  );

  const columns: IViewDataTableColumn<IDetailLossAccounting>[] = useMemo(
    () => getLossMethodsReviewColumns(isUnconstrained, timeZone),
    [isUnconstrained, timeZone],
  );

  return (
    <>
      {allowPopover ? (
        <ReviewDataTable<IDetailLossAccounting>
          columns={columns}
          data={adjustedLossAccountings}
          id='lossMethodsReview'
          isLoading={isLoading}
          maximumHeight={DETAIL_REVIEW_FLOAT_OVER_MAXIMUM_HEIGHT}
          pagination={false}
          tableTitle='Loss Methods Details'
          title={LOSS_METHODS_TITLE}
        />
      ) : (
        <>{LOSS_METHODS_TITLE}</>
      )}
    </>
  );
};

export default LossMethodsReview;
