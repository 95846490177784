import SeparatedRowLayout from 'components/atoms/SeparatedRowLayout/SeparatedRowLayout';
import DetailView from 'components/molecules/DetailView/DetailView';
import Select, { ISelectProps } from 'components/molecules/Select/Select';
import Tooltip from 'components/molecules/Tooltip/Tooltip';
import { PROFILE_SEGMENT_GEN } from 'components/organisms/ProfileGraphView/constants';
import {
  getChartData,
  getProfileDateOptions,
  getProfileSegmentOptions,
  lastRequestTypeToColor,
  profileSegmentToUid,
} from 'components/organisms/ProfileGraphView/helpers';
import ProfileGraph from 'components/organisms/ProfileGraphView/ProfileGraph';
import {
  IHighlightRange,
  IProfileSegment,
} from 'components/organisms/ProfileGraphView/types';
import {
  CURRENT_PENDING_REQUEST_KEY,
  CURRENT_REQUEST_KEY,
} from 'constants/Detail';
import { EDistributedTagItem, EProfileSegment, ERequestType } from 'enums/ETag';
import { ERetreiveState } from 'enums/General';
import { EViewResize } from 'enums/View';
import { IOption } from 'interfaces/Component';
import {
  IETagPhysicalSegmentsProfile,
  IETagTransmissionSegment,
} from 'interfaces/ETag';
import { IEnergyProfile } from 'interfaces/General';
import { IViewProps } from 'interfaces/View';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { detailSetETagDetailSelectedPlotTime } from 'reduxes/Detail/actions';
import styled from 'styled-components';
import { TTimeZone } from 'types/DateTime';
import { TRootState } from 'types/Redux';
import { transmissionAllocationSorter } from 'utils/detail';
import { getDetailToEntityUserSelectedTimeZone } from 'utils/user';
import { ZonedDateTime } from 'utils/zonedDateTime';

const ProfileDateSelect = styled((props: ISelectProps<ZonedDateTime>) =>
  Select<ZonedDateTime>(props),
)`
  width: 113px;
`;

const ProfileSegmentSelect = styled((props: ISelectProps<IProfileSegment>) =>
  Select<IProfileSegment>(props),
)`
  width: 103px;
`;

const retrieveProfileGraphViewState = (state: TRootState) => {
  const {
    allTransmissionAllocations,
    end_date,
    physicalSegmentsProfiles,
    retrievingDetail,
    retrievingDistributedTagItems,
    retrievingProfiles,
    selectedPlotTime,
    selectedRequestKey,
    start_date,
    transmissionAllocations,
  } = state.detail.present;
  const isDetailLoading: boolean =
    (retrievingDetail !== ERetreiveState.NotRetrieving &&
      retrievingDetail !== ERetreiveState.RetrievingCompleted) ||
    (retrievingDistributedTagItems[EDistributedTagItem.TransmissionAllocations]
      .retrieveState !== ERetreiveState.NotRetrieving &&
      retrievingDistributedTagItems[EDistributedTagItem.TransmissionAllocations]
        .retrieveState !== ERetreiveState.RetrievingCompleted) ||
    (retrievingDistributedTagItems[EDistributedTagItem.PhysicalSegmentsProfiles]
      .retrieveState !== ERetreiveState.NotRetrieving &&
      retrievingDistributedTagItems[
        EDistributedTagItem.PhysicalSegmentsProfiles
      ].retrieveState !== ERetreiveState.RetrievingCompleted);
  const isLoadingProfiles: boolean =
    retrievingProfiles !== ERetreiveState.NotRetrieving &&
    retrievingProfiles !== ERetreiveState.RetrievingCompleted;
  const timeZone: TTimeZone = getDetailToEntityUserSelectedTimeZone(state);

  return {
    allTransmissionAllocations,
    end_date,
    isDetailLoading,
    isLoadingProfiles,
    physicalSegmentsProfiles,
    selectedPlotTime,
    selectedRequestKey,
    start_date,
    timeZone,
    transmissionAllocations,
  };
};

const ProfileGraphView = (props: IViewProps): JSX.Element => {
  const dispatch = useDispatch();
  const {
    allTransmissionAllocations,
    end_date,
    isDetailLoading,
    isLoadingProfiles,
    physicalSegmentsProfiles,
    selectedPlotTime,
    selectedRequestKey,
    start_date,
    timeZone,
    transmissionAllocations,
  } = useSelector(retrieveProfileGraphViewState);
  const { layoutGrid, resize, viewId } = props;
  const [selectedProfileDate, setSelectedProfileDate] = useState<
    ZonedDateTime | undefined
  >(undefined);
  const [selectedProfileSegment, setSelectedProfileSegment] = useState<
    IProfileSegment | undefined
  >(PROFILE_SEGMENT_GEN);
  const [highlightRanges, setHighlightRanges] = useState<IHighlightRange[]>([]);

  const setSelectedPlotTime = useCallback(
    (selectedPlotTime: Date) =>
      dispatch(detailSetETagDetailSelectedPlotTime({ selectedPlotTime })),
    [dispatch],
  );

  const sortedTransmissionAllocations = useMemo(
    () =>
      transmissionAllocations === null
        ? []
        : [...transmissionAllocations].sort(transmissionAllocationSorter),
    [transmissionAllocations],
  );

  const sortedAllTransmissionAllocations = useMemo(
    () =>
      allTransmissionAllocations === null
        ? []
        : [...allTransmissionAllocations].sort(transmissionAllocationSorter),
    [allTransmissionAllocations],
  );

  const eTagStart: ZonedDateTime | undefined = useMemo(
    () =>
      start_date === null
        ? undefined
        : ZonedDateTime.parseIso(start_date, timeZone),
    [start_date, timeZone],
  );

  const eTagStop: ZonedDateTime | undefined = useMemo(
    () =>
      end_date === null
        ? undefined
        : ZonedDateTime.parseIso(end_date, timeZone),
    [end_date, timeZone],
  );

  const profileDateOptions: IOption<ZonedDateTime>[] = useMemo(
    () => getProfileDateOptions(eTagStart, eTagStop),
    [eTagStart, eTagStop],
  );

  const profileSegmentOptions: IOption<IProfileSegment>[] = useMemo(
    () =>
      getProfileSegmentOptions(
        physicalSegmentsProfiles,
        sortedTransmissionAllocations,
      ),
    [physicalSegmentsProfiles, sortedTransmissionAllocations],
  );

  const {
    chartData,
    chartStart,
    chartStop,
    numberOfCurrentLevelSeries,
    numberOfTransmissionLimitSeries,
    valueMax,
  } = useMemo(
    () =>
      getChartData(
        physicalSegmentsProfiles,
        selectedProfileDate,
        selectedProfileSegment,
        sortedAllTransmissionAllocations,
        timeZone,
      ),
    [
      physicalSegmentsProfiles,
      selectedProfileDate,
      selectedProfileSegment,
      sortedAllTransmissionAllocations,
      timeZone,
    ],
  );

  useEffect(() => {
    setSelectedProfileDate(undefined);
  }, [isDetailLoading, isLoadingProfiles, timeZone]);

  useEffect(() => {
    if (selectedProfileDate === undefined) {
      let profileDate: ZonedDateTime | undefined = undefined;

      if (profileDateOptions.length > 0) {
        if (
          (selectedRequestKey === CURRENT_REQUEST_KEY ||
            selectedRequestKey === CURRENT_PENDING_REQUEST_KEY) &&
          selectedPlotTime !== undefined
        ) {
          // Select date based on selectedPlotTime
          const currentTime: ZonedDateTime = ZonedDateTime.fromDate(
            selectedPlotTime,
            timeZone,
          );
          const start: ZonedDateTime = profileDateOptions[0].value;
          const stop: ZonedDateTime =
            profileDateOptions[profileDateOptions.length - 1].value;

          if (currentTime.isBefore(start)) {
            profileDate = start;
          } else if (currentTime.isAfter(stop)) {
            profileDate = stop;
          } else {
            const currentDay: ZonedDateTime = currentTime.startOf('day');

            for (let i: number = 0; i < profileDateOptions.length; i += 1) {
              const current: ZonedDateTime = profileDateOptions[i].value;

              if (current.isSame(currentDay)) {
                profileDate = current;
                break;
              }
            }
          }

          setHighlightRanges([]);
        } else {
          // Select date based on last_request_type
          const updatedHighLightRanges: IHighlightRange[] = [];
          let rangeStart: ZonedDateTime | null = null;

          if (physicalSegmentsProfiles !== null) {
            physicalSegmentsProfiles.forEach(
              (eTagPhysicalSegmentsProfile: IETagPhysicalSegmentsProfile) => {
                let energyProfile: IEnergyProfile | undefined = undefined;

                if (
                  selectedProfileSegment?.profileSegment === EProfileSegment.Gen
                ) {
                  energyProfile =
                    eTagPhysicalSegmentsProfile.physical_segments_profiles ===
                      null ||
                    eTagPhysicalSegmentsProfile.physical_segments_profiles
                      .generation === null ||
                    eTagPhysicalSegmentsProfile.physical_segments_profiles
                      .generation.profile === null
                      ? undefined
                      : eTagPhysicalSegmentsProfile.physical_segments_profiles
                          .generation.profile;
                } else if (
                  selectedProfileSegment?.profileSegment ===
                  EProfileSegment.Load
                ) {
                  energyProfile =
                    eTagPhysicalSegmentsProfile.physical_segments_profiles ===
                      null ||
                    eTagPhysicalSegmentsProfile.physical_segments_profiles
                      .load === null ||
                    eTagPhysicalSegmentsProfile.physical_segments_profiles.load
                      .profile === null
                      ? undefined
                      : eTagPhysicalSegmentsProfile.physical_segments_profiles
                          .load.profile;
                } else if (
                  selectedProfileSegment?.profileSegment === EProfileSegment.Pod
                ) {
                  if (selectedProfileSegment.physicalSegmentRef === undefined) {
                    throw new Error(
                      'Missing selectedProfileSegment.physicalSegmentRef',
                    );
                  }

                  const eTagTransmissionSegment:
                    | IETagTransmissionSegment
                    | undefined = eTagPhysicalSegmentsProfile.physical_segments_profiles?.transmission?.transmission_segments?.find(
                    (
                      eTagTransmissionSegment: IETagTransmissionSegment,
                    ): boolean =>
                      eTagTransmissionSegment.physical_segment_id ===
                      selectedProfileSegment.physicalSegmentRef,
                  );

                  energyProfile =
                    eTagTransmissionSegment === undefined ||
                    eTagTransmissionSegment.pod_energy_profile === null
                      ? undefined
                      : eTagTransmissionSegment.pod_energy_profile;
                }

                if (
                  energyProfile !== undefined &&
                  energyProfile.last_request_type !== ERequestType.None &&
                  eTagPhysicalSegmentsProfile.start !== null &&
                  eTagPhysicalSegmentsProfile.stop !== null
                ) {
                  rangeStart = ZonedDateTime.parseIso(
                    eTagPhysicalSegmentsProfile.start,
                    timeZone,
                  );

                  updatedHighLightRanges.push({
                    color: lastRequestTypeToColor(
                      energyProfile.last_request_type,
                    ),
                    opacity: 0.41,
                    start: rangeStart,
                    stop: ZonedDateTime.parseIso(
                      eTagPhysicalSegmentsProfile.stop,
                      timeZone,
                    ),
                  });
                }
              },
            );
          }

          if (rangeStart !== null) {
            const currentDay: ZonedDateTime = ZonedDateTime.parseIso(
              rangeStart,
              timeZone,
            ).startOf('day');

            for (let i: number = 0; i < profileDateOptions.length; i += 1) {
              const current: ZonedDateTime = profileDateOptions[i].value;

              if (current.isSame(currentDay)) {
                profileDate = current;
                break;
              }
            }

            setHighlightRanges(updatedHighLightRanges);
          }
        }
      }

      if (profileDate === undefined && profileDateOptions.length > 0) {
        const currentDay: ZonedDateTime =
          ZonedDateTime.now(timeZone).startOf('day');

        if (currentDay.isSameOrBefore(profileDateOptions[0].value)) {
          profileDate = profileDateOptions[0].value;
        } else if (
          currentDay.isSameOrAfter(
            profileDateOptions[profileDateOptions.length - 1].value,
          )
        ) {
          profileDate = profileDateOptions[profileDateOptions.length - 1].value;
        } else {
          for (let i: number = 1; i < profileDateOptions.length - 1; i += 1) {
            const current: ZonedDateTime = profileDateOptions[i].value;

            if (current.isSame(currentDay)) {
              profileDate = current;
              break;
            }
          }
        }
      }

      setSelectedProfileDate(profileDate);
    }
  }, [
    physicalSegmentsProfiles,
    profileDateOptions,
    selectedPlotTime,
    selectedProfileDate,
    selectedProfileSegment,
    selectedRequestKey,
    timeZone,
  ]);

  useEffect(() => {
    if (
      selectedProfileDate !== undefined &&
      profileDateOptions.length > 0 &&
      (selectedRequestKey === CURRENT_REQUEST_KEY ||
        selectedRequestKey === CURRENT_PENDING_REQUEST_KEY)
    ) {
      setHighlightRanges([]);
    }
  }, [profileDateOptions, selectedProfileDate, selectedRequestKey]);

  return (
    <DetailView
      isLoading={isDetailLoading}
      layoutGrid={layoutGrid}
      leftActions={
        isDetailLoading ? undefined : (
          <SeparatedRowLayout>
            <Tooltip title='Profile Date Selection'>
              <ProfileDateSelect
                onChange={setSelectedProfileDate}
                options={profileDateOptions}
                placeholder='Select Profile Date'
                value={selectedProfileDate}
                valueToUid={(value: ZonedDateTime): string =>
                  value.toIsoString()
                }
              />
            </Tooltip>
            <Tooltip title='Profile Segment Selection'>
              <ProfileSegmentSelect
                dropdownMatchSelectWidth={false}
                onChange={setSelectedProfileSegment}
                options={profileSegmentOptions}
                placeholder='Select Profile Segment'
                value={selectedProfileSegment}
                valueToUid={profileSegmentToUid}
              />
            </Tooltip>
          </SeparatedRowLayout>
        )
      }
      resize={resize}
      title='Profile Graph'
      viewId={viewId}
      viewResizeSetting={EViewResize.Initial}
    >
      <ProfileGraph
        chartData={chartData}
        chartStart={chartStart}
        chartStop={chartStop}
        eTagStart={eTagStart}
        eTagStop={eTagStop}
        highlightRanges={highlightRanges}
        isLoading={isLoadingProfiles}
        numberOfCurrentLevelSeries={numberOfCurrentLevelSeries}
        numberOfTransmissionLimitSeries={numberOfTransmissionLimitSeries}
        setSelectedPlotTime={setSelectedPlotTime}
        timeZone={timeZone}
        valueMax={valueMax}
      />
    </DetailView>
  );
};

export default ProfileGraphView;
