import {
  copyViewLayout,
  copyViewLayoutMap,
  hasSameViewLayouts,
} from 'components/organisms/ETagManager/helpers';
import { VIEW_EXPAND_Z_INDEX, VIEW_PADDING_VALUE } from 'constants/styles';
import { EViewResize } from 'enums/View';
import { IViewLayout } from 'interfaces/View';
import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { TViewLayoutMap } from 'types/View';
import { encodeIds } from 'utils/general';

interface ILayoutProps {
  gridColumnGap: string;
  gridRowGap: string;
  gridTemplateColumns: string;
  gridTemplateRows: string;
}

const Layout = styled.div<ILayoutProps>`
  display: grid;
  height: 100%;
  padding: 0 ${VIEW_PADDING_VALUE}px;
  transition: all 1s;
  width: 100%;

  ${(props) => `grid-column-gap: ${props.gridColumnGap};`}
  ${(props) => `grid-row-gap: ${props.gridRowGap};`}
  ${(props) => `grid-template-columns: ${props.gridTemplateColumns};`}
  ${(props) => `grid-template-rows: ${props.gridTemplateRows};`}
`;

interface IViewManagerProps {
  columnGap: string;
  columns: string[];
  encodedPermissionsId: string;
  initialViewLayoutMap: TViewLayoutMap;
  isNarrowLayout?: boolean;
  preventAutoEdits?: boolean;
  rowGap: string;
  rows: string[];
}

const ViewManager = (props: IViewManagerProps): JSX.Element => {
  const {
    columnGap,
    columns,
    encodedPermissionsId,
    initialViewLayoutMap,
    isNarrowLayout,
    preventAutoEdits,
    rowGap,
    rows,
  } = props;
  const [gridTemplateColumns, setGridTemplateColumns] = useState<string>('');
  const [gridTemplateRows, setGridTemplateRows] = useState<string>('');
  const [viewLayoutMap, setViewLayoutMap] = useState<TViewLayoutMap>({});
  const [isNarrow, setIsNarrow] = useState<boolean | undefined>();

  useEffect(() => {
    if (
      !hasSameViewLayouts(viewLayoutMap, initialViewLayoutMap) ||
      isNarrowLayout !== isNarrow
    ) {
      setViewLayoutMap({ ...initialViewLayoutMap });
      setIsNarrow(isNarrowLayout);
    }
  }, [initialViewLayoutMap, viewLayoutMap, isNarrowLayout, isNarrow]);

  useEffect(() => {
    setGridTemplateColumns(columns.join(' '));
  }, [columns]);

  useEffect(() => {
    setGridTemplateRows(rows.join(' '));
  }, [rows]);

  const handleResize = useCallback(
    (id: string, viewResize: EViewResize) => {
      const newViewLayoutMap: TViewLayoutMap = copyViewLayoutMap(viewLayoutMap);

      switch (viewResize) {
        case EViewResize.Down: {
          const newViewLayout: IViewLayout = copyViewLayout(viewLayoutMap[id]);
          const rowValues: string[] =
            newViewLayout.layoutGrid.gridRow.split(' / ');
          newViewLayout.layoutGrid.gridRow = `${rowValues[0]} / ${
            rows.length + 1
          }`;
          newViewLayout.layoutGrid.zIndex = VIEW_EXPAND_Z_INDEX;
          newViewLayoutMap[id] = newViewLayout;
          break;
        }
        case EViewResize.Full: {
          const newViewLayout: IViewLayout = copyViewLayout(viewLayoutMap[id]);
          newViewLayout.layoutGrid.gridColumn = `1 / ${columns.length + 1}`;
          newViewLayout.layoutGrid.gridRow = `1 / ${rows.length + 1}`;
          newViewLayout.layoutGrid.zIndex = VIEW_EXPAND_Z_INDEX;
          newViewLayoutMap[id] = newViewLayout;
          break;
        }
        default: {
          newViewLayoutMap[id] = initialViewLayoutMap[id];
          break;
        }
      }
      setViewLayoutMap(newViewLayoutMap);
    },
    [columns.length, initialViewLayoutMap, rows.length, viewLayoutMap],
  );

  return (
    <Layout
      className={'view-manager'}
      gridColumnGap={columnGap}
      gridRowGap={rowGap}
      gridTemplateColumns={gridTemplateColumns}
      gridTemplateRows={gridTemplateRows}
    >
      {Object.keys(viewLayoutMap).map((key: string): JSX.Element => {
        const { layoutGrid, View } = viewLayoutMap[key];

        return (
          <View
            encodedPermissionsId={encodeIds([encodedPermissionsId, key])}
            key={key}
            layoutGrid={layoutGrid}
            preventAutoEdits={preventAutoEdits}
            resize={handleResize}
            viewId={key}
          />
        );
      })}
    </Layout>
  );
};

export default ViewManager;
