import { Checkbox as AntDesignCheckbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import usePermissions, { IusePermissionsProps } from 'hooks/usePermissions';

interface IProps extends IusePermissionsProps {
  checked: boolean;
  isDisabled?: boolean;
  label?: string;
  onChange: (checked: boolean) => void;
}

const Checkbox = (props: IProps): JSX.Element | null => {
  const { checked, encodedPermissionsId, isDisabled, label, onChange } = props;

  const permissions = usePermissions(encodedPermissionsId);

  const handleChange = (event: CheckboxChangeEvent) => {
    onChange(event.target.checked);
  };

  if (permissions.isDisplayable) {
    return (
      <AntDesignCheckbox
        checked={checked}
        disabled={isDisabled}
        onChange={handleChange}
      >
        {label}
      </AntDesignCheckbox>
    );
  }

  return null;
};

export default Checkbox;
