import withAlertsQueue, {
  IwithAlertsQueueProps,
} from 'components/hocs/withAlertsQueue/withAlertsQueue';
import withFloatOver from 'components/hocs/withFloatOver/withFloatOver';
import ToEntitySelector from 'components/molecules/ToEntitySelector/ToEntitySelector';
import { IPageContentProps } from 'components/organisms/Page/Page';
import ToEntityMonitor from 'components/organisms/ToEntityMonitor/ToEntityMonitor';
import { PAGE_LAYOUT_STYLES, STANDARD_SPACING_VALUE } from 'constants/styles';
import AnimationProvider from 'contexts/Animation/Animation';
import ETagFilteringProvider from 'contexts/ETagFiltering/ETagFiltering';
import ETagSortingProvider from 'contexts/ETagSorting/ETagSorting';
import { IToEntity } from 'interfaces/ToEntity';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { encodeIds } from 'utils/general';
import { AxiosResponse } from 'axios';
import { IETagConfigResponse } from '../../../interfaces/ETag';
import { retrieveToEntityConfig } from '../../../services/configclient/config';
import useAsyncEffect from 'use-async-effect';
import { IToEntityLossesConfig } from '../../../reduxes/User/types';
import ToEntityMonitorCombined from '../../organisms/TenantMonitor/TenantMonitor';
import ToggleSwitch from '../../atoms/ToggleSwitch/ToggleSwitch';
import { GroupOutlined } from '@ant-design/icons';
import Tooltip from '../../molecules/Tooltip/Tooltip';
import { captureError } from '../../../utils/error';

const Layout = styled.div`
  ${PAGE_LAYOUT_STYLES}
`;

const Actions = styled.div`
  padding: 8px 4px 0 4px;
  display: flex;
  gap: 10px;
`;

const ToggleSwitchContainer = styled.div`
  margin-top: 3px;
`;

const ToEntity = styled.div`
  height: 100%;
`;

interface ISummaryPageContentProps
  extends IPageContentProps<undefined>,
    IwithAlertsQueueProps {}

const SummaryPageContent = (props: ISummaryPageContentProps): JSX.Element => {
  const { addAlert, closeAllAlerts, userInfo } = props;
  const { toEntities, toEntityUserStates } = userInfo;
  const [selectedToEntities, setSelectedToEntities] = useState<IToEntity[]>([]);
  const [toEntityLossesConfig, setToEntityLossesConfig] = useState<
    IToEntityLossesConfig[]
  >([]);

  const [isCombinedViewEnabled, setIsCombinedViewEnabled] =
    useState<boolean>(false);
  const [showCombinedViewToggle, setShowCombinedViewToggle] =
    useState<boolean>(false);
  const [loadingConfigs, setLoadingConfigs] = useState<boolean>(false);

  // Set the combined view toggle as "on" by default
  useEffect(() => {
    const isSet = localStorage.getItem('isCombinedViewEnabled');
    if (!isSet) {
      localStorage.setItem('isCombinedViewEnabled', 'true');
      setIsCombinedViewEnabled(true);
    }
  }, []);

  useAsyncEffect(async () => {
    setLoadingConfigs(true);
    const toEntityLossesConfig: IToEntityLossesConfig[] = [];
    let combinedViewToggle = false;
    const promises: Promise<AxiosResponse<IETagConfigResponse>>[] = [];
    for (const entity of toEntities) {
      promises.push(retrieveToEntityConfig(entity.to_entity));
    }
    const responses = await Promise.all(promises);
    responses.forEach((response, index) => {
      if (
        response.data &&
        response.data.response &&
        response.data.response.combined_view
      ) {
        combinedViewToggle = response.data.response.combined_view;
      }
      toEntityLossesConfig.push({
        toEntity: toEntities[index].to_entity,
        showLosses: response.data.response.show_losses || false,
      });
    });

    setShowCombinedViewToggle(combinedViewToggle);
    setToEntityLossesConfig(toEntityLossesConfig);
    setSelectedToEntities(toEntities);
    setLoadingConfigs(false);
  }, [toEntities]);

  const hasMultipleToEntities: boolean = toEntities.length > 1;
  const toEntityMonitorHeight: string = `calc((100vh - ${
    2 * STANDARD_SPACING_VALUE
  }px) / ${Math.max(Math.min(selectedToEntities.length, 3), 1)}`;

  const toEntityCombinedMonitorHeight: string = `calc((98vh - ${
    2 * STANDARD_SPACING_VALUE
  }px))`;

  const handleCombinedViewChange = (isCombinedViewEnabled: boolean) => {
    try {
      localStorage.setItem(
        'isCombinedViewEnabled',
        isCombinedViewEnabled ? 'true' : 'false',
      );
    } catch (error: any) {
      captureError(error);
    }
    setIsCombinedViewEnabled(isCombinedViewEnabled);
  };

  useEffect(() => {
    try {
      const localStorageIsCombinedViewEnabled =
        localStorage.getItem('isCombinedViewEnabled') === 'true';
      setIsCombinedViewEnabled(localStorageIsCombinedViewEnabled);
    } catch (error: any) {
      captureError(error);
    }
  }, []);

  return (
    <Layout>
      {hasMultipleToEntities ? (
        <Actions>
          <ToEntitySelector
            onChange={setSelectedToEntities}
            selectedToEntities={selectedToEntities}
            toEntities={toEntities}
          />
          {showCombinedViewToggle ? (
            <ToggleSwitchContainer>
              <Tooltip placement='bottomRight' title='Toggle combined view'>
                <ToggleSwitch
                  checkedChildren={<GroupOutlined />}
                  isChecked={isCombinedViewEnabled}
                  onChange={handleCombinedViewChange}
                  unCheckedChildren={<GroupOutlined />}
                />
              </Tooltip>
            </ToggleSwitchContainer>
          ) : null}
        </Actions>
      ) : null}
      {!loadingConfigs &&
      isCombinedViewEnabled &&
      showCombinedViewToggle &&
      selectedToEntities &&
      selectedToEntities[0] ? (
        <AnimationProvider>
          <ETagFilteringProvider>
            <ETagSortingProvider>
              <ToEntityMonitorCombined
                closeAllAlerts={closeAllAlerts}
                encodedPermissionsId={encodeIds(
                  ['toEntityMonitor'],
                  selectedToEntities[0].to_entity,
                )}
                isEmbeddedTitle={hasMultipleToEntities}
                maxHeight={toEntityCombinedMonitorHeight}
                onAlert={addAlert}
                tenantEntities={selectedToEntities}
                toEntityUserState={
                  toEntityUserStates[selectedToEntities[0].to_entity]
                }
                toEntityUserStates={toEntityUserStates}
                toEntityLossesConfig={toEntityLossesConfig}
              />
            </ETagSortingProvider>
          </ETagFilteringProvider>
        </AnimationProvider>
      ) : !loadingConfigs ? (
        selectedToEntities.map(
          (toEntity: IToEntity): JSX.Element => (
            <ToEntity key={toEntity.to_entity}>
              <AnimationProvider>
                <ETagFilteringProvider>
                  <ETagSortingProvider>
                    <ToEntityMonitor
                      closeAllAlerts={closeAllAlerts}
                      encodedPermissionsId={encodeIds(
                        ['toEntityMonitor'],
                        toEntity.to_entity,
                      )}
                      isEmbeddedTitle={hasMultipleToEntities}
                      maxHeight={toEntityMonitorHeight}
                      onAlert={addAlert}
                      toEntity={toEntity}
                      toEntityUserState={toEntityUserStates[toEntity.to_entity]}
                      toEntityUserStates={toEntityUserStates}
                      toEntityLossesConfig={toEntityLossesConfig}
                    />
                  </ETagSortingProvider>
                </ETagFilteringProvider>
              </AnimationProvider>
            </ToEntity>
          ),
        )
      ) : null}
    </Layout>
  );
};

export default withFloatOver(withAlertsQueue(SummaryPageContent));
