import { EDraftScheduledStatusLabel } from 'components/molecules/ScheduleSubmission/interfaces';
import { EDraftScheduleStatus } from 'enums/ETag';

export const getStatusLabelFromStatus = (status: EDraftScheduleStatus) =>
  status === EDraftScheduleStatus.Failed
    ? EDraftScheduledStatusLabel.Failed
    : status === EDraftScheduleStatus.InProgress
    ? EDraftScheduledStatusLabel.InProgress
    : status === EDraftScheduleStatus.Scheduled
    ? EDraftScheduledStatusLabel.Scheduled
    : status === EDraftScheduleStatus.Submitted
    ? EDraftScheduledStatusLabel.Submitted
    : EDraftScheduledStatusLabel.Empty;
