import UndoButton from 'components/molecules/UndoButton/UndoButton';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ActionCreators } from 'redux-undo';
import { TRootState } from 'types/Redux';

interface IETagManagerUndoButtonProps {
  isDisabled?: boolean;
}

const retrieveUndoButtonState = (state: TRootState) => {
  const { past } = state.detail;
  const isUndoable: boolean = past.length > 0;

  return {
    isUndoable,
  };
};

const ETagManagerUndoButton = ({
  isDisabled,
}: IETagManagerUndoButtonProps): JSX.Element => {
  const dispatch = useDispatch();
  const { isUndoable } = useSelector(retrieveUndoButtonState);

  const handleClick = useCallback(() => {
    dispatch(ActionCreators.undo());
  }, [dispatch]);

  return (
    <UndoButton isDisabled={isDisabled || !isUndoable} onClick={handleClick} />
  );
};

export default ETagManagerUndoButton;
