import Page from 'components/organisms/Page/Page';
import AlertRulesPageContent from 'components/pages/AlertRulesPage/AlertRulesPageContent';
import { IRouteProps } from 'interfaces/Component';

const AlertRulesPage = ({ appActions }: IRouteProps): JSX.Element => (
  <Page
    appActions={appActions}
    PageContent={AlertRulesPageContent}
    pageTitle='Alert Rules - PCI e-Tag+'
  />
);

export default AlertRulesPage;
