export enum EQueryType {
  Availability = 'availability',
  Correction = 'correction',
  Request = 'request',
  RequestIds = 'requestIds',
  Resolution = 'resolution',
  Status = 'status',
  Summary = 'summary',
  Tag = 'tag',
  MissingTagsAndRevisions = 'missingTagsAndRevisions',
}
