import Select, { ISelectProps } from 'components/molecules/Select/Select';
import Tooltip from 'components/molecules/Tooltip/Tooltip';
import { ALL_TIME_ZONE_OPTIONS } from 'constants/time';
import useUserInfo from 'hooks/useUserInfo';
import { IToEntityUiConfiguration } from 'interfaces/Config';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  setTenantSelectedToTimeZone,
  userSetSelectedTimeZone,
} from 'reduxes/User/actions';
import { IToEntityUserState } from 'reduxes/User/types';
import styled from 'styled-components';
import { TTimeZone, TTimeZoneOption, TTimeZoneOptions } from 'types/DateTime';
import { TToEntityId } from 'types/ToEntity';
import { isTimeZone } from 'utils/time';
import { getToEntityUserState } from 'utils/user';
import useTenantInfo from '../../../hooks/useTenantInfo';

const StyledSelect = styled((props: ISelectProps<string>) =>
  Select<string>(props),
)`
  width: 137px;
`;

interface ITimeZoneSelectProps {
  isDisabled?: boolean;
  onChange?: (timeZone: string | undefined) => void;
  timeZoneOptions?: TTimeZoneOptions;
  toEntityId?: TToEntityId;
  defaultTimeZone?: TTimeZone;
}

/**
 * Used to show and select the state.user.selectedTimeZone in the redux store.
 */
const UserTimeZoneSelector = (props: ITimeZoneSelectProps): JSX.Element => {
  const { isDisabled, timeZoneOptions, toEntityId, defaultTimeZone, onChange } =
    props;
  const { selectedToEntity, toEntityUserStates } = useUserInfo();
  const dispatch = useDispatch();
  const [timeZoneSelectOpen, setTimeZoneSelectOpen] = useState<boolean>(false);
  const { search } = useLocation();

  const { selectedTimeZone } = useTenantInfo();

  const resolvedToEntityId = toEntityId ?? selectedToEntity?.to_entity;

  const toEntityUserState: IToEntityUserState | undefined =
    getToEntityUserState({ to_entity: resolvedToEntityId }, toEntityUserStates);

  const timeZone: TTimeZone | undefined = resolvedToEntityId
    ? toEntityUserState?.selectedTimeZone
    : defaultTimeZone || selectedTimeZone;
  const defaultTimeZoneQueryParam: string | null = new URLSearchParams(
    search,
  ).get('defaultTimeZone');

  const toEntityUiConfiguration: IToEntityUiConfiguration | undefined =
    toEntityUserState?.toEntityUiConfig;

  const handleChange = (timeZone: string | undefined): void => {
    if (isTimeZone(timeZone)) {
      if (resolvedToEntityId !== undefined) {
        if (onChange !== undefined) {
          onChange(timeZone);
        }
        dispatch(
          userSetSelectedTimeZone({
            toEntityId: resolvedToEntityId,
            selectedTimeZone: timeZone,
          }),
        );
      } else {
        dispatch(setTenantSelectedToTimeZone({ timeZone }));
      }
    }
  };

  const resolvedTimeZoneOptions: TTimeZoneOptions = useMemo(() => {
    if (timeZoneOptions === undefined) {
      if (toEntityUiConfiguration?.timeZones) {
        return ALL_TIME_ZONE_OPTIONS.filter(
          (timeZoneOption: TTimeZoneOption): boolean =>
            toEntityUiConfiguration.timeZones.includes(timeZoneOption.value),
        );
      }

      return ALL_TIME_ZONE_OPTIONS;
    }

    return timeZoneOptions;
  }, [timeZoneOptions, toEntityUiConfiguration?.timeZones]);

  // if no time zone has been selected and we have a to-entity default tz
  // or a default tz in the query parameters
  // then use that default tz in our selector. This is useful because
  // most users only have one to-entity and they want the time zone to
  // show their default selection
  useEffect(() => {
    if (timeZone === undefined && resolvedToEntityId !== undefined) {
      let defaultTimeZone: TTimeZone | undefined = undefined;

      if (isTimeZone(defaultTimeZoneQueryParam)) {
        defaultTimeZone = defaultTimeZoneQueryParam;
      } else if (isTimeZone(toEntityUiConfiguration?.defaultTimeZone)) {
        defaultTimeZone = toEntityUiConfiguration?.defaultTimeZone;
      }

      if (defaultTimeZone !== undefined) {
        dispatch(
          userSetSelectedTimeZone({
            toEntityId: resolvedToEntityId,
            selectedTimeZone: defaultTimeZone,
          }),
        );
      }
    }
  }, [
    defaultTimeZoneQueryParam,
    dispatch,
    resolvedToEntityId,
    selectedToEntity,
    timeZone,
    toEntityUserStates,
    toEntityUiConfiguration?.defaultTimeZone,
  ]);

  const handleTimeZoneSelectDropdownVisibleChange = (open: boolean) => {
    setTimeZoneSelectOpen(open);
  };

  return (
    <Tooltip isDisabled={timeZoneSelectOpen} title='Time Zone Selection'>
      <StyledSelect
        isDisabled={isDisabled ?? false}
        onChange={handleChange}
        onDropdownVisibleChange={handleTimeZoneSelectDropdownVisibleChange}
        open={timeZoneSelectOpen}
        options={resolvedTimeZoneOptions}
        showSearch={true}
        placeholder='Select Time Zone'
        value={timeZone}
        valueToUid={(value: string): string => value}
      />
    </Tooltip>
  );
};
export default UserTimeZoneSelector;
