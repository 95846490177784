import { IContactInfoEditableProps } from 'components/molecules/ContactInfoEditable/ContactInfoEditable';
import { IContactInfo } from 'interfaces/General';
import {
  IInputColumnConfig,
  IInputPhoneNumberColumnConfig,
} from 'interfaces/View';
import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { getEditInfoKey, getInitialContactInfo } from 'utils/detail';
import { eventToStringOrNull } from 'utils/general';

interface IuseContactInfoEditableReturn {
  contactInfoEditableProps: IContactInfoEditableProps;
  contactInfo: IContactInfo;
  isFaxValid: boolean;
  isPhoneValid: boolean;
}

// This is used to obtain the necessary configurations and values for a single
// contact info which can be passed to the ContactInfoEditable component
const useContactInfoEditable = (
  baseKey: string,
  isDisabled?: boolean,
  hasChanged?: boolean,
  defaultValue?: IContactInfo,
  showErrorMessageOnInvalidNumbers?: boolean,
): IuseContactInfoEditableReturn => {
  const initialEditKey: string = getEditInfoKey(baseKey, 0, 0);
  const [contactInfos, setContactInfos] = useState<IContactInfo[]>(
    defaultValue ? [defaultValue] : [getInitialContactInfo(initialEditKey)],
  );
  const [isFaxValid, setIsFaxValid] = useState<boolean>(true);
  const [isPhoneValid, setIsPhoneValid] = useState<boolean>(true);

  const contactInputColumnConfig: IInputColumnConfig<IContactInfo> = useMemo(
    () => ({
      getKey: (contactInfoRecord: IContactInfo): string =>
        contactInfoRecord.key,
      isDisabled,
      onChange: (event: ChangeEvent<HTMLInputElement>) => {
        setContactInfos(
          (previousContactInfos: IContactInfo[]): IContactInfo[] => [
            {
              ...previousContactInfos[0],
              contact: eventToStringOrNull(event),
            },
          ],
        );
      },
    }),
    [isDisabled],
  );

  const faxInputPhoneNumberColumnConfig: IInputPhoneNumberColumnConfig<IContactInfo> =
    useMemo(
      () => ({
        getIsValid: () => (isValid: boolean) => {
          setIsFaxValid(isValid);
        },
        showErrorMessageWhenInvalid: showErrorMessageOnInvalidNumbers,
        isDisabled,
        onChange: (value: string | null) => {
          setContactInfos(
            (previousContactInfos: IContactInfo[]): IContactInfo[] => [
              {
                ...previousContactInfos[0],
                fax: value,
              },
            ],
          );
        },
      }),
      [isDisabled, showErrorMessageOnInvalidNumbers],
    );

  const phoneInputPhoneNumberColumnConfig: IInputPhoneNumberColumnConfig<IContactInfo> =
    useMemo(
      () => ({
        getIsValid: () => (isValid: boolean) => {
          setIsPhoneValid(isValid);
        },
        showErrorMessageWhenInvalid: showErrorMessageOnInvalidNumbers,
        isDisabled,
        onChange: (value: string | null) => {
          setContactInfos(
            (previousContactInfos: IContactInfo[]): IContactInfo[] => [
              {
                ...previousContactInfos[0],
                phone: value,
              },
            ],
          );
        },
      }),
      [isDisabled, showErrorMessageOnInvalidNumbers],
    );

  const handleRemoveContactInfo = useCallback(
    () => setContactInfos([getInitialContactInfo(initialEditKey)]),
    [initialEditKey, setContactInfos],
  );

  return useMemo(
    () => ({
      contactInfoEditableProps: {
        contactInputColumnConfig,
        data: contactInfos,
        faxInputPhoneNumberColumnConfig,
        hasChanged,
        initialEditKey,
        isDisabled,
        onRemove: handleRemoveContactInfo,
        phoneInputPhoneNumberColumnConfig,
      },
      contactInfo: contactInfos[0],
      isFaxValid,
      isPhoneValid,
    }),
    [
      contactInfos,
      contactInputColumnConfig,
      faxInputPhoneNumberColumnConfig,
      handleRemoveContactInfo,
      hasChanged,
      initialEditKey,
      isDisabled,
      isFaxValid,
      isPhoneValid,
      phoneInputPhoneNumberColumnConfig,
    ],
  );
};

export default useContactInfoEditable;
