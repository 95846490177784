export const DEFAULT_FONT_SIZE_STEP = 0.1;
export const FONT_SIZE_EPSILON = 0.01;

export const DEFAULT_PADDING_STEP = 0.1;
export const PADDING_SIZE_EPSILON = 0.01;

export const DEFAULT_MINIMUM_PADDING_SIZE = 1;
export const DEFAULT_MAXIMUM_PADDING_SIZE = 4;
export const DEFAULT_MAXIMUM_FONT_SIZE = 12;
export const DEFAULT_MINIMUM_FONT_SIZE = 5;
