import Tooltip from 'components/molecules/Tooltip/Tooltip';
import {
  LEGEND_CELL_BORDER,
  LEGEND_CELL_CONTENT_HEIGHT,
  LEGEND_CELL_CONTENT_PADDING,
} from 'constants/styles';
import {
  ALTERNATING_ROW_STYLE_KEY,
  ATTRIBUTE_ROW_STYLE_KEY,
  DRAFT_ROW_STYLE_KEY,
  HOUR_ENDING_STYLE_KEY,
  PROFILES_ROW_STYLE_KEY,
  ROW_HIGHLIGHTING_STYLE_KEY,
} from 'constants/Summary';
import legendDataColumns from 'data/legendDataColumns.json';
import { IETagColumnData } from 'interfaces/ETag';
import { IIndexable } from 'interfaces/General';
import {
  ILegendRowConfiguration,
  ISummaryLegendConfiguration,
  ISummaryStyleCoding,
  ISummaryStyles,
  ISummaryThemedStyles,
} from 'interfaces/Summary';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import styled from 'styled-components';
import { unpackSummaryStyles } from 'utils/styles';

const CELL_CONTENT_WIDTH = '100px';
const CELL_BORDER_THIN = '1px solid #d0d0d0';

const EXCLUDED_STYLE_CODING_KEYS = [
  HOUR_ENDING_STYLE_KEY,
  ATTRIBUTE_ROW_STYLE_KEY,
  PROFILES_ROW_STYLE_KEY,
  DRAFT_ROW_STYLE_KEY,
  ROW_HIGHLIGHTING_STYLE_KEY,
  ALTERNATING_ROW_STYLE_KEY,
];

interface ISummaryStyleProps {
  summaryStyles?: ISummaryStyles;
}

const SharedCellStyles = (props: ISummaryStyleProps) => `
  height: ${LEGEND_CELL_CONTENT_HEIGHT};
  text-align: center;
  white-space: nowrap;
  width: ${CELL_CONTENT_WIDTH};
  ${unpackSummaryStyles(props.summaryStyles)};
`;

const StyledTable = styled.table`
  width: 500px;

  > thead > tr > td {
    width: 50%;
  }

  > tbody > tr > td > h4 {
    margin-top: 0.5em;
  }
`;

const StyledLegendTd = styled.td<ISummaryStyleProps>`
  border: ${LEGEND_CELL_BORDER};
  padding: ${LEGEND_CELL_CONTENT_PADDING};
  ${(props) => SharedCellStyles(props)};
`;

const States = styled.div`
  display: flex;
  flex-wrap: wrap;

  > div {
    flex: 0 calc(50% - 2px);
    margin: 1px;
  }
`;

const State = styled.div<ISummaryStyleProps>`
  border: ${CELL_BORDER_THIN};
  ${(props) => SharedCellStyles(props)};
`;

const StyledImpactedTd = styled.td`
  border: ${LEGEND_CELL_BORDER};
  padding: ${LEGEND_CELL_CONTENT_PADDING};
`;

interface ILegendRowProps {
  columns?: IETagColumnData[];
  configuration: ILegendRowConfiguration;
  dataIndex: string;
  stateStylesMap: Record<string, ISummaryThemedStyles>;
}

const LegendRow = (props: ILegendRowProps) => {
  const { currentTheme } = useThemeSwitcher();
  const { columns, configuration, dataIndex, stateStylesMap } = props;
  const stateStylesKeys: string[] = Object.keys(stateStylesMap);
  const impactedColumns: string[] = [];
  let impactedColumnsString: string = '';

  if (configuration.impactedColumns === undefined) {
    if (columns !== undefined) {
      columns.forEach((column: IETagColumnData) => {
        if (column.dataIndex === dataIndex || column.styleIndex === dataIndex) {
          impactedColumns.push(column.displayName);
        }
      });
      impactedColumnsString = impactedColumns.join(', ');
    }
  } else {
    impactedColumnsString = configuration.impactedColumns;
  }

  return (
    <tr>
      <StyledLegendTd
        summaryStyles={
          stateStylesKeys.length === 1
            ? (stateStylesMap[stateStylesKeys[0]] as IIndexable)[currentTheme!]
            : undefined
        }
      >
        <Tooltip title={configuration.description}>
          {configuration.displayName}
          {stateStylesKeys.length > 1 ? (
            <States>
              {Object.keys(stateStylesMap).map((key: string) => (
                <State
                  key={key}
                  summaryStyles={
                    (stateStylesMap[key] as IIndexable)[currentTheme!]
                  }
                >
                  <div>{key}</div>
                </State>
              ))}
            </States>
          ) : null}
        </Tooltip>
      </StyledLegendTd>
      <StyledImpactedTd>{impactedColumnsString}</StyledImpactedTd>
    </tr>
  );
};

interface ILegendProps {
  columns: IETagColumnData[];
  styleCoding: ISummaryStyleCoding & IIndexable;
}

const Legend = (props: ILegendProps) => {
  const legendConfiguration: ISummaryLegendConfiguration & IIndexable =
    legendDataColumns;
  const { columns, styleCoding } = props;

  return (
    <StyledTable>
      <thead>
        <tr>
          <td>
            <h3>Legends</h3>
          </td>
          <td>
            <h3>Impacted Columns</h3>
          </td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <h4>Tag Summary Legend</h4>
          </td>
        </tr>
        {Object.keys(styleCoding)
          .filter(
            (key: string): boolean => !EXCLUDED_STYLE_CODING_KEYS.includes(key),
          )
          .map((key: string) => (
            <LegendRow
              columns={columns}
              configuration={legendConfiguration[key]}
              dataIndex={key}
              key={key}
              stateStylesMap={
                styleCoding[key] as Record<string, ISummaryThemedStyles>
              }
            />
          ))}
        {Object.keys(styleCoding[ATTRIBUTE_ROW_STYLE_KEY]).map(
          (key: string) => (
            <LegendRow
              configuration={
                (legendConfiguration[ATTRIBUTE_ROW_STYLE_KEY] as IIndexable)[
                  key
                ]
              }
              dataIndex={key}
              key={key}
              stateStylesMap={
                (styleCoding[ATTRIBUTE_ROW_STYLE_KEY] as IIndexable)[
                  key
                ] as Record<string, ISummaryThemedStyles>
              }
            />
          ),
        )}
        {Object.keys(styleCoding[PROFILES_ROW_STYLE_KEY]).map((key: string) => (
          <LegendRow
            configuration={legendConfiguration[PROFILES_ROW_STYLE_KEY][key]}
            dataIndex={key}
            key={key}
            stateStylesMap={
              (styleCoding[PROFILES_ROW_STYLE_KEY] as IIndexable)[
                key
              ] as Record<string, ISummaryThemedStyles>
            }
          />
        ))}
        {Object.keys(styleCoding[DRAFT_ROW_STYLE_KEY]).map((key: string) => (
          <LegendRow
            configuration={
              (legendConfiguration[DRAFT_ROW_STYLE_KEY] as IIndexable)[key]
            }
            dataIndex={key}
            key={key}
            stateStylesMap={
              (styleCoding[DRAFT_ROW_STYLE_KEY] as IIndexable)[key] as Record<
                string,
                ISummaryThemedStyles
              >
            }
          />
        ))}
        <tr>
          <td>
            <h4>Hourly Integrated Profile Legend</h4>
          </td>
        </tr>
        {Object.keys(styleCoding[HOUR_ENDING_STYLE_KEY]).map(
          (key: string, index: number) => (
            <LegendRow
              configuration={
                (legendConfiguration[HOUR_ENDING_STYLE_KEY] as IIndexable)[key]
              }
              dataIndex={key}
              key={key}
              stateStylesMap={
                (styleCoding[HOUR_ENDING_STYLE_KEY] as IIndexable)[
                  key
                ] as Record<string, ISummaryThemedStyles>
              }
            />
          ),
        )}
      </tbody>
    </StyledTable>
  );
};

export default Legend;
