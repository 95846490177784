import { API } from 'aws-amplify';
import { AxiosResponse } from 'axios';
import { EProfileSegment } from 'enums/ETag';
import {
  IETagsSummaryAttributeBatchResponse,
  IETagsSummaryAttributeIdListResponse,
  IETagsSummaryDealLinkageResponse,
  IETagSummaryProfilesBatchResponse,
} from 'interfaces/ETag';
import {
  TAGS_SUMMARY_LOADER_IDS_ROUTE,
  TAGS_SUMMARY_LOADER_PROFILES_ROUTE,
  TAGS_SUMMARY_LOADER_SUMMARIES_ROUTE,
  TAG_TO_DEAL_LINKAGE_REPORT_ROUTE,
} from 'services/agent/constants';
import { AGENT_SERVICE_API_NAME, DEFAULT_OPTIONS } from 'services/constants';
import { TTimeZone } from 'types/DateTime';
import { TFilterId } from 'types/Filter';
import { TToEntityId } from 'types/ToEntity';
import { ZonedDateTime } from 'utils/zonedDateTime';

export const retrieveETagsSummaryAttributeIdList = async (
  toEntityId: TToEntityId,
  start: ZonedDateTime,
  end: ZonedDateTime,
  filterId?: TFilterId,
): Promise<AxiosResponse<IETagsSummaryAttributeIdListResponse>> => {
  return await API.get(
    AGENT_SERVICE_API_NAME,
    TAGS_SUMMARY_LOADER_IDS_ROUTE(toEntityId, start, end, filterId),
    DEFAULT_OPTIONS,
  );
};

export const retrieveETagsSummaryAttributeBatch = async (
  toEntityId: TToEntityId,
  idList: string[],
): Promise<AxiosResponse<IETagsSummaryAttributeBatchResponse>> => {
  return await API.get(
    AGENT_SERVICE_API_NAME,
    TAGS_SUMMARY_LOADER_SUMMARIES_ROUTE(toEntityId, idList),
    DEFAULT_OPTIONS,
  );
};

export const retrieveETagSummaryDealLinkageList = async (
  toEntityId: TToEntityId,
  start: ZonedDateTime,
  end: ZonedDateTime,
  timeZone: TTimeZone,
): Promise<AxiosResponse<IETagsSummaryDealLinkageResponse>> => {
  return await API.get(
    AGENT_SERVICE_API_NAME,
    TAG_TO_DEAL_LINKAGE_REPORT_ROUTE(toEntityId, start, end, timeZone),
    DEFAULT_OPTIONS,
  );
};

export const retrieveETagSummaryProfilesBatch = async (
  toEntityId: TToEntityId,
  idList: string[],
  timeZone: TTimeZone,
  start: ZonedDateTime,
  end: ZonedDateTime,
  profileSegment: EProfileSegment,
): Promise<AxiosResponse<IETagSummaryProfilesBatchResponse>> => {
  return await API.get(
    AGENT_SERVICE_API_NAME,
    TAGS_SUMMARY_LOADER_PROFILES_ROUTE(
      toEntityId,
      idList,
      timeZone,
      start,
      end,
      profileSegment,
    ),
    DEFAULT_OPTIONS,
  );
};
