import Tooltip from '../Tooltip/Tooltip';
import IconButton from '../../atoms/IconButton/IconButton';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import {
  IETagExtendedIdentifier,
  IETagReasons,
  IETagReasonsResponse,
  IETagTagId,
  IETagTransactionStatus,
  IETagTransactionStatuses,
  IETagTransactionStatusesResponse,
} from '../../../interfaces/ETag';
import styled from 'styled-components';
import { BUTTON_ICON_DIMENSIONS } from '../../../constants/styles';
import Modal from '../Modal/Modal';
import RequestsInformationDataTable from '../../organisms/RequestsInformationDataTable/RequestsInformationDataTable';
import { TTimeZone } from '../../../types/DateTime';
import {
  IDetailState,
  IDetailTransactionStatus,
  IDetailTransactionStatuses,
} from '../../../reduxes/Detail/types';
import { IToEntity } from '../../../interfaces/ToEntity';
import useAsyncEffect from 'use-async-effect';
import {
  encodeIds,
  isSuccessStatus,
  shallowObjectCompare,
} from '../../../utils/general';
import { AxiosResponse } from 'axios';
import {
  detailPageLocationString,
  getEditInfoKey,
  getRequestIdFromRequestKey,
  getRequestKey,
  retrieveAndTransformMarketInfos,
  retrieveAndTransformRegistryEntities,
} from '../../../utils/detail';
import Link from '../../atoms/Link/Link';
import { EPageMode } from '../../../enums/Page';
import { TDetailPageLocationIdParameter } from '../../../types/Detail';
import { EApprovalStatus } from '../../../enums/Approval';
import { getNoteOptionsByStatus } from '../../organisms/RequestsInformationView/helpers';
import { DEFAULT_REASONS, STATUS_KEYS } from '../../../constants/Approval';
import RequestActionSetState from '../RequestActionSetState/RequestActionSetState';
import { ECompositeState, ERequestType } from '../../../enums/ETag';
import usePermissions from '../../../hooks/usePermissions';
import { retrieveReasons } from '../../../services/approval/approval';
import ApprovalHistory from '../../organisms/ApprovalHistory/ApprovalHistory';
import { IContactInfo } from '../../../interfaces/General';
import { getUserContactInfo } from '../../../utils/user';
import { useDispatch, useSelector } from 'react-redux';
import { TRootState } from '../../../types/Redux';
import {
  detailRetrieveETagDetail,
  detailRetrieveETagDistributedTagItems,
} from '../../../reduxes/Detail/actions';
import { EDetailIdType } from '../../../enums/Detail';
import { TStateLoadTransform } from '../../../types/General';
import { getAllDistributedTagItemStateLoadTransforms } from '../../organisms/ETagManager/helpers';
import { IDistributedTagItemDetailStateLoadTransform } from '../../../interfaces/Detail';
import { parseSecurityKeyFromFullTagPrimaryKey } from '../../../utils/eTag';
import RequestActionWithdraw from '../RequestActionWithdraw/RequestActionWithdraw';
import { retrieveETagDistributedTransactionStatuses } from '../../../services/agent/tags/distributed';
import { EDIT_TRANSACTION_STATUSES_CONTACT_INFO_LABEL } from '../../../constants/Detail';

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const RequestActionsContainer = styled.div`
  display: flex;
  margin-right: 25px;
`;

const Icon = styled(InfoCircleOutlined)`
  ${BUTTON_ICON_DIMENSIONS}
`;

const WrappedDataTable = styled(RequestsInformationDataTable)`
  max-height: 90%;
  background-color: red;
`;

interface IToEntityRequestsInformation {
  eTagExtendedIdentifier: IETagExtendedIdentifier | undefined;
  timeZone: TTimeZone;
  encodedPermissionsId: string;
  toEntity?: IToEntity;
  tagId?: IETagTagId;
}

const retrieveRequestsInformationViewState = (state: TRootState) => {
  const {
    security_key,
    approval_right,
    transactionStatuses,
    selectedRequestKey,
    tag_id,
  } = state.detail.present;
  const contactInfo: IContactInfo | undefined = getUserContactInfo(state);

  return {
    approval_right,
    contactInfo,
    security_key,
    transactionStatuses,
    selectedRequestKey,
    tag_id,
  };
};

const ToEntityRequestsInformation = ({
  eTagExtendedIdentifier,
  timeZone,
  toEntity,
  encodedPermissionsId,
  tagId,
}: IToEntityRequestsInformation): JSX.Element => {
  const dispatch = useDispatch();

  const retrieveETagDetail = useCallback(
    (
      detailIdType: EDetailIdType,
      stateLoadTransform: TStateLoadTransform<IDetailState>[],
      shouldCaptureError?: boolean,
    ) =>
      dispatch(
        detailRetrieveETagDetail(
          detailIdType,
          stateLoadTransform,
          shouldCaptureError,
        ),
      ),
    [dispatch],
  );

  const retrieveETagDistributedTagItems = useCallback(
    (
      distributedTagItemDetailStateLoadTransform: IDistributedTagItemDetailStateLoadTransform[],
    ) =>
      dispatch(
        detailRetrieveETagDistributedTagItems(
          distributedTagItemDetailStateLoadTransform,
        ),
      ),
    [dispatch],
  );

  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    const detailStateLoadTransforms: TStateLoadTransform<IDetailState>[] = [];
    if (eTagExtendedIdentifier?.tag_primary_key && showModal && toEntity) {
      detailStateLoadTransforms.push(
        retrieveAndTransformRegistryEntities(toEntity.to_entity),
        retrieveAndTransformMarketInfos(
          toEntity.to_entity,
          null,
          eTagExtendedIdentifier.tag_primary_key,
        ),
      );
      retrieveETagDetail(
        EDetailIdType.TagPrimaryKey,
        detailStateLoadTransforms,
        false,
      );

      retrieveETagDistributedTagItems(
        getAllDistributedTagItemStateLoadTransforms(
          toEntity.to_entity,
          eTagExtendedIdentifier.tag_primary_key!,
        ),
      );
    }
  }, [
    eTagExtendedIdentifier,
    retrieveETagDetail,
    retrieveETagDistributedTagItems,
    toEntity,
    showModal,
  ]);

  const {
    /*approval_right,
    contactInfo,
    isDetailDeleted,
    isDetailLoading,
    isDetailUpdating,
    security_key,
    selectedRequestKey,
    tag_id,
    tag_primary_key,
    timeZone,
    toEntity,*/
    //transactionStatuses,
    selectedRequestKey,
    //viewMode,
  } = useSelector(retrieveRequestsInformationViewState, shallowObjectCompare);
  const [transactionStatuses, setTransactionStatuses] = useState<
    IDetailTransactionStatuses[]
  >([]);
  const newTagTransactionStatus =
    transactionStatuses &&
    transactionStatuses.length > 0 &&
    transactionStatuses.find(
      (item) => item.ui_transaction_message_type === ERequestType.NewTag,
    );
  const newTagApprovalRights =
    newTagTransactionStatus && newTagTransactionStatus.approval_rights;
  const [isPanelVisible, setIsPanelVisible] = useState<boolean>(false);
  const [tagHasCorrections, setTagHasCorrections] = useState<boolean>(false);
  const [noteOptions, setNoteOptions] = useState<IETagReasons>(DEFAULT_REASONS);
  const selectedTransactionStatus: IDetailTransactionStatuses | undefined =
    transactionStatuses.find(
      (transactionStatus: IDetailTransactionStatuses): boolean =>
        getRequestKey(transactionStatus) === selectedRequestKey,
    );
  const requestId: number = selectedRequestKey
    ? getRequestIdFromRequestKey(selectedRequestKey)
    : 0;

  const reasonsPermissions = usePermissions(encodedPermissionsId);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useAsyncEffect(async () => {
    if (toEntity && reasonsPermissions.isExecutable) {
      try {
        setIsLoading(true);
        const retrieveReasonsResponse: AxiosResponse<IETagReasonsResponse> =
          await retrieveReasons(toEntity.to_entity);

        const eTagReasonsResponse: IETagReasonsResponse =
          retrieveReasonsResponse.data;

        if (!isSuccessStatus(retrieveReasonsResponse.status)) {
          throw new Error(eTagReasonsResponse.errorMessage!);
        }

        setNoteOptions(eTagReasonsResponse.response as IETagReasons);
      } catch (error: any) {
        setNoteOptions(DEFAULT_REASONS);
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    }
  }, [toEntity, isPanelVisible]);

  const { approval_right, contactInfo, security_key, tag_id } = useSelector(
    retrieveRequestsInformationViewState,
    shallowObjectCompare,
  );

  /*const securityKey: string | undefined = useMemo(() => {
    if (eTagExtendedIdentifier) {
      return security_key ?? eTagExtendedIdentifier
        ? parseSecurityKeyFromFullTagPrimaryKey(
            eTagExtendedIdentifier.tag_primary_key,
          )
        : undefined;
    } else {
      return '';
    }
  }, [eTagExtendedIdentifier, security_key]);*/

  useAsyncEffect(async () => {
    if (
      eTagExtendedIdentifier &&
      eTagExtendedIdentifier.tag_primary_key &&
      toEntity &&
      showModal &&
      transactionStatuses.length === 0
    ) {
      setIsLoading(true);
      const response: AxiosResponse<IETagTransactionStatusesResponse> =
        await retrieveETagDistributedTransactionStatuses(
          toEntity.to_entity,
          eTagExtendedIdentifier.tag_primary_key,
        );
      const eTagTransactionStatusesResponse: IETagTransactionStatusesResponse =
        response.data;
      if (!isSuccessStatus(response.status)) {
        throw new Error(eTagTransactionStatusesResponse.errorMessage!);
      }

      const transactionStatuses: IDetailTransactionStatuses[] = [];
      eTagTransactionStatusesResponse.response.forEach(
        (eTagTransactionStatuses: IETagTransactionStatuses) => {
          transactionStatuses.push({
            act_on_by_time: eTagTransactionStatuses.act_on_by_time,
            approval_rights: eTagTransactionStatuses.approval_rights,
            contact_info:
              eTagTransactionStatuses.contact_info === null
                ? null
                : {
                    ...eTagTransactionStatuses.contact_info,
                    key: getEditInfoKey(
                      EDIT_TRANSACTION_STATUSES_CONTACT_INFO_LABEL,
                      eTagTransactionStatuses.request_id,
                      0,
                    ),
                  },
            correction_id: eTagTransactionStatuses.correction_id,
            notes: eTagTransactionStatuses.notes,
            request_id: eTagTransactionStatuses.request_id,
            requestor:
              eTagTransactionStatuses.requestor == null
                ? null
                : {
                    ...eTagTransactionStatuses.requestor,
                  },
            request_timestamp: eTagTransactionStatuses.request_timestamp,
            resolution_status: eTagTransactionStatuses.resolution_status,
            statuses:
              eTagTransactionStatuses.statuses === null
                ? null
                : eTagTransactionStatuses.statuses.map(
                    (
                      eTagTransactionStatus: IETagTransactionStatus,
                    ): IDetailTransactionStatus => ({
                      approval_status: eTagTransactionStatus.approval_status,
                      approval_status_type:
                        eTagTransactionStatus.approval_status_type,
                      approval_timestamp:
                        eTagTransactionStatus.approval_timestamp,
                      approver_notes: eTagTransactionStatus.approver_notes,
                      delivery_status: eTagTransactionStatus.delivery_status,
                      entity_code:
                        eTagTransactionStatus.entity === null
                          ? null
                          : eTagTransactionStatus.entity.entity_code,
                      entity_type:
                        eTagTransactionStatus.entity === null
                          ? null
                          : eTagTransactionStatus.entity.entity_type,
                    }),
                  ),
            ui_transaction_message_type:
              eTagTransactionStatuses.ui_transaction_message_type,
          });
        },
      );

      setTransactionStatuses(transactionStatuses);
      setIsLoading(false);
    }
  }, [eTagExtendedIdentifier, toEntity, showModal]);

  const securityKey: string | undefined = useMemo(
    () =>
      security_key ??
      parseSecurityKeyFromFullTagPrimaryKey(
        eTagExtendedIdentifier?.tag_primary_key,
      ),
    [eTagExtendedIdentifier, security_key],
  );

  useEffect(() => {
    if (transactionStatuses && transactionStatuses.length > 0) {
      const tagCorrection = transactionStatuses.find(
        (item) => item.ui_transaction_message_type === ERequestType.Correction,
      );
      setTagHasCorrections(!!tagCorrection);
    }
  }, [transactionStatuses]);

  const handleShow = useCallback(() => {
    setShowModal(true);
  }, [setShowModal]);

  const handleCancel = useCallback(() => {
    setTransactionStatuses([]);
    setShowModal(false);
  }, [setShowModal]);

  const modalTitle = useMemo(() => {
    const title = 'Review All Requests';
    const subTitle =
      eTagExtendedIdentifier?.ui_tag_id ?? eTagExtendedIdentifier?.draft_id;
    /* const title =
      eTagExtendedIdentifier?.ui_tag_id ?? eTagExtendedIdentifier?.draft_id;*/
    let idParams: TDetailPageLocationIdParameter | undefined;

    if (eTagExtendedIdentifier) {
      if (eTagExtendedIdentifier.draft_id !== null) {
        // `${DETAIL_PAGE_DRAFT_ID_QUERY_PARAMETER_NAME}=${record.draft_id}`;
        idParams = { draftId: eTagExtendedIdentifier.draft_id };
      } else if (
        eTagExtendedIdentifier.tag_primary_key !== null &&
        eTagExtendedIdentifier.tag_primary_key !== undefined
      ) {
        //`${DETAIL_PAGE_TAG_PRIMARY_KEY_QUERY_PARAMETER_NAME}=${record.tag_primary_key}`;
        idParams = { tagPrimaryKey: eTagExtendedIdentifier.tag_primary_key };
      } else {
        idParams = undefined;
      }
    }
    const detailUrl =
      idParams !== undefined && toEntity
        ? detailPageLocationString({
            ...idParams,
            defaultTimeZone: timeZone,
            mode: EPageMode.Review,
            toEntity: toEntity.to_entity,
            fullMode: 'requests',
          })
        : '';

    return (
      <HeaderContainer>
        <TitleContainer>
          <span>{title}</span>
          <Link to={detailUrl} external={false} target={'_blank'}>
            {subTitle}
          </Link>
        </TitleContainer>
        {/*<Link to={detailUrl} external={false} target={'_blank'}>
          <RequestsInformationLinkIconButton icon={<BlockOutlined />} />
        </Link>*/}
        {eTagExtendedIdentifier ? (
          <RequestActionsContainer>
            {securityKey !== undefined && tag_id !== null && toEntity ? (
              <RequestActionWithdraw
                defaultContactInfo={contactInfo}
                encodedPermissionsId={encodeIds([
                  encodedPermissionsId,
                  'eTagWithdrawRequest',
                ])}
                isDisabled={false}
                requestId={requestId}
                securityKey={securityKey}
                tagId={tag_id}
                timeZone={timeZone}
                toEntityId={toEntity.to_entity}
                transactionStatus={selectedTransactionStatus}
              />
            ) : null}
            {toEntity ? (
              <RequestActionSetState
                approvalRight={
                  tagHasCorrections
                    ? newTagApprovalRights || null
                    : approval_right
                }
                approvalStatus={EApprovalStatus.Approved}
                encodedPermissionsId={encodeIds(
                  [encodedPermissionsId, 'requestActionSetState'],
                  toEntity.to_entity,
                )}
                noteOptions={getNoteOptionsByStatus(
                  noteOptions[STATUS_KEYS[EApprovalStatus.Approved]],
                )}
                onPanelVisibleChange={setIsPanelVisible}
                requestId={requestId}
                tagPrimaryKey={eTagExtendedIdentifier.tag_primary_key}
                timeZone={timeZone}
                title={`Approve Request ${requestId}`}
                toEntityId={toEntity.to_entity}
                transactionStatus={selectedTransactionStatus}
              />
            ) : null}
            {toEntity ? (
              <RequestActionSetState
                approvalRight={
                  tagHasCorrections
                    ? newTagApprovalRights || null
                    : approval_right
                }
                approvalStatus={EApprovalStatus.Denied}
                encodedPermissionsId={encodeIds(
                  [encodedPermissionsId, 'requestActionSetState'],
                  toEntity.to_entity,
                )}
                noteOptions={getNoteOptionsByStatus(
                  noteOptions[STATUS_KEYS[EApprovalStatus.Denied]],
                )}
                onPanelVisibleChange={setIsPanelVisible}
                requestId={requestId}
                tagPrimaryKey={eTagExtendedIdentifier.tag_primary_key}
                timeZone={timeZone}
                title={`Deny Request ${requestId}`}
                toEntityId={toEntity.to_entity}
                transactionStatus={selectedTransactionStatus}
              />
            ) : null}
            {toEntity ? (
              <RequestActionSetState
                approvalRight={
                  tagHasCorrections
                    ? newTagApprovalRights || null
                    : approval_right
                }
                approvalStatus={EApprovalStatus.Study}
                encodedPermissionsId={encodeIds(
                  [encodedPermissionsId, 'requestActionSetState'],
                  toEntity.to_entity,
                )}
                noteOptions={getNoteOptionsByStatus(
                  noteOptions[STATUS_KEYS[EApprovalStatus.Study]],
                )}
                onPanelVisibleChange={setIsPanelVisible}
                requestId={requestId}
                tagPrimaryKey={eTagExtendedIdentifier.tag_primary_key}
                timeZone={timeZone}
                title={`Study Request ${requestId}`}
                toEntityId={toEntity.to_entity}
                transactionStatus={selectedTransactionStatus}
              />
            ) : null}
            {toEntity ? (
              <ApprovalHistory
                encodedPermissionsId={encodeIds(
                  [encodedPermissionsId, 'eTagApprovalHistory'],
                  toEntity.to_entity,
                )}
                id='approvalHistory'
                tagPrimaryKey={eTagExtendedIdentifier.tag_primary_key}
                timeZone={timeZone}
                toEntityId={toEntity.to_entity}
                isModal={true}
              />
            ) : null}
          </RequestActionsContainer>
        ) : null}
      </HeaderContainer>
    );
  }, [
    eTagExtendedIdentifier,
    timeZone,
    approval_right,
    encodedPermissionsId,
    newTagApprovalRights,
    noteOptions,
    requestId,
    selectedTransactionStatus,
    tagHasCorrections,
    contactInfo,
    securityKey,
    tag_id,
    toEntity,
  ]);

  return (
    <>
      <Tooltip
        isDisabled={showModal}
        placement={'top'}
        title={'Review All Requests'}
      >
        <IconButton
          icon={<Icon />}
          onClick={handleShow}
          isDisabled={
            eTagExtendedIdentifier === undefined ||
            !toEntity ||
            eTagExtendedIdentifier.composite_state === ECompositeState.Draft
          }
        />
      </Tooltip>
      <Modal
        footer={<></>}
        isVisible={showModal}
        onCancel={handleCancel}
        /* title={
          'Tag requests information - ' + eTagExtendedIdentifier?.ui_tag_id ??
          eTagExtendedIdentifier?.draft_id
        }*/
        title={modalTitle}
        width={500}
      >
        {toEntity ? (
          <WrappedDataTable
            isUnconstrained={false}
            selectedRequestKey={selectedRequestKey}
            tag_primary_key={eTagExtendedIdentifier?.tag_primary_key}
            toEntity={toEntity}
            timeZone={timeZone}
            transactionStatuses={transactionStatuses}
            fromSummary={true}
            isLoading={isLoading}
          />
        ) : null}
      </Modal>
    </>
  );
};

export default ToEntityRequestsInformation;
