import {
  Dropdown as AntDesignDropdown,
  Menu as AntDesignMenu,
  Tag as AntDesignTag,
} from 'antd';
import Tooltip from 'components/molecules/Tooltip/Tooltip';
import { ETAG_CUSTOM_FILTER_DETAIL_MAP } from 'constants/ETag';
import {
  INPUT_HEIGHT,
  STANDARD_SPACING,
  STANDARD_SPACING_VALUE,
} from 'constants/styles';
import { ECustomFilterType } from 'enums/Filter';
import { IFilterDetail } from 'interfaces/Filter';
import { MenuInfo } from 'rc-menu/lib/interface';
import { useCallback, useMemo } from 'react';
import styled from 'styled-components';

const StyledTag = styled(AntDesignTag)`
  height: ${INPUT_HEIGHT};
  margin: 0;
  padding: ${STANDARD_SPACING_VALUE / 2}px ${STANDARD_SPACING};
`;

interface IFilterSelectionProps {
  customFilterType: ECustomFilterType | null;
  isDisabled?: boolean;
  onChange?: (customFilterType: ECustomFilterType) => void;
}

const FilterSelector = ({
  customFilterType,
  isDisabled,
  onChange,
}: IFilterSelectionProps): JSX.Element => {
  const handleFilterSelect = useCallback(
    (menuInfo: MenuInfo) => {
      onChange?.(menuInfo.key as ECustomFilterType);
    },
    [onChange],
  );

  const filterDetail: IFilterDetail | undefined = useMemo(() => {
    if (customFilterType === null) {
      return undefined;
    }

    return ETAG_CUSTOM_FILTER_DETAIL_MAP[customFilterType];
  }, [customFilterType]);

  const filterMenu = useMemo(
    (): JSX.Element => (
      <AntDesignMenu onClick={handleFilterSelect}>
        {Object.keys(ETAG_CUSTOM_FILTER_DETAIL_MAP).map(
          (key: string): JSX.Element => (
            <AntDesignMenu.Item key={key}>
              {ETAG_CUSTOM_FILTER_DETAIL_MAP[key].description}
            </AntDesignMenu.Item>
          ),
        )}
      </AntDesignMenu>
    ),
    [handleFilterSelect],
  );

  return (
    <Tooltip isDisabled={isDisabled} title={filterDetail?.description}>
      <AntDesignDropdown
        disabled={isDisabled}
        overlay={filterMenu}
        trigger={['click']}
      >
        <StyledTag color='orange'>{filterDetail?.label}</StyledTag>
      </AntDesignDropdown>
    </Tooltip>
  );
};

export default FilterSelector;
