import { EAlertMessageAttribute, EAlertRuleType } from 'enums/Alert';
import {
  IAlertColourEffect,
  IAlertMessageTemplate,
  IAlertRuleByApprovalStatus,
  IAlertRuleByApprovalStatusAndUser,
  IAlertRuleByCompositeStateChange,
  IAlertRuleByMessageType,
  IAlertRuleByNotesAddition,
  IAlertRuleByRequestStatus,
  IAlertRuleByRequestStatusAndUser,
} from 'interfaces/Alert';
import { TAnyAlertRule, TEditableAlertRule } from 'types/Alert';
import { shallowListCompare } from 'utils/general';

// these are hidden in the ui, but used in the backend
export const CORE_ATTRIBUTES = [
  EAlertMessageAttribute.TagPrimaryKey,
  EAlertMessageAttribute.TagId,
];

export const ensureCoreAttributes = (
  attributes: EAlertMessageAttribute[] | undefined | null,
): EAlertMessageAttribute[] => {
  if (attributes === undefined || attributes === null) {
    return [...CORE_ATTRIBUTES];
  } else {
    const attributesCopy: EAlertMessageAttribute[] = [...attributes];
    for (const coreAttribute of CORE_ATTRIBUTES) {
      if (attributesCopy.indexOf(coreAttribute) < 0) {
        attributesCopy.push(coreAttribute);
      }
    }
    return attributesCopy;
  }
};

export const filteredAttributes = (
  template: IAlertMessageTemplate | undefined | null,
): EAlertMessageAttribute[] => {
  if (template === undefined || template === null) {
    return [];
  } else {
    return template.attributes.filter(
      (attribute: EAlertMessageAttribute) =>
        CORE_ATTRIBUTES.indexOf(attribute) < 0,
    );
  }
};

function compareTypeSpecificAttributes(
  alertRule1: Partial<TAnyAlertRule>,
  alertRule2: Partial<TAnyAlertRule>,
) {
  if (alertRule1.type === EAlertRuleType.AlertRuleByNotesAddition) {
    return (
      alertRule2.type === EAlertRuleType.AlertRuleByNotesAddition &&
      (alertRule1 as Partial<IAlertRuleByNotesAddition>)?.note_type ===
        (alertRule2 as Partial<IAlertRuleByNotesAddition>)?.note_type
    );
  } else if (alertRule1.type === EAlertRuleType.AlertRuleByRequestStatus) {
    return (
      alertRule2.type === EAlertRuleType.AlertRuleByRequestStatus &&
      (alertRule1 as Partial<IAlertRuleByRequestStatus>)?.request_status ===
        (alertRule2 as Partial<IAlertRuleByRequestStatus>)?.request_status &&
      (alertRule1 as Partial<IAlertRuleByRequestStatus>)?.request_type ===
        (alertRule2 as Partial<IAlertRuleByRequestStatus>)?.request_type
    );
  } else if (
    alertRule1.type === EAlertRuleType.AlertRuleByRequestStatusAndUser
  ) {
    return (
      alertRule2.type === EAlertRuleType.AlertRuleByRequestStatusAndUser &&
      (alertRule1 as Partial<IAlertRuleByRequestStatusAndUser>)
        ?.request_status ===
        (alertRule2 as Partial<IAlertRuleByRequestStatusAndUser>)
          ?.request_status &&
      (alertRule1 as Partial<IAlertRuleByRequestStatusAndUser>)
        ?.request_type ===
        (alertRule2 as Partial<IAlertRuleByRequestStatusAndUser>)?.request_type
    );
  } else if (alertRule1.type === EAlertRuleType.AlertRuleByApprovalStatus) {
    return (
      alertRule2.type === EAlertRuleType.AlertRuleByApprovalStatus &&
      (alertRule1 as Partial<IAlertRuleByApprovalStatus>)?.approval_status ===
        (alertRule2 as Partial<IAlertRuleByApprovalStatus>)?.approval_status &&
      (alertRule1 as Partial<IAlertRuleByApprovalStatus>)?.entity ===
        (alertRule2 as Partial<IAlertRuleByApprovalStatus>)?.entity
    );
  } else if (
    alertRule1.type === EAlertRuleType.AlertRuleByApprovalStatusAndUser
  ) {
    return (
      alertRule2.type === EAlertRuleType.AlertRuleByApprovalStatusAndUser &&
      (alertRule1 as Partial<IAlertRuleByApprovalStatusAndUser>)
        ?.approval_status ===
        (alertRule2 as Partial<IAlertRuleByApprovalStatusAndUser>)
          ?.approval_status &&
      (alertRule1 as Partial<IAlertRuleByApprovalStatusAndUser>)?.entity ===
        (alertRule2 as Partial<IAlertRuleByApprovalStatusAndUser>)?.entity
    );
  } else if (
    alertRule1.type === EAlertRuleType.AlertRuleByCompositeStateChange
  ) {
    return (
      alertRule2.type === EAlertRuleType.AlertRuleByCompositeStateChange &&
      (alertRule1 as Partial<IAlertRuleByCompositeStateChange>)
        ?.composite_state ===
        (alertRule2 as Partial<IAlertRuleByCompositeStateChange>)
          ?.composite_state
    );
  } else if (alertRule1.type === EAlertRuleType.AlertRuleByMessageType) {
    return (
      alertRule2.type === EAlertRuleType.AlertRuleByMessageType &&
      (alertRule1 as Partial<IAlertRuleByMessageType>)?.message_type ===
        (alertRule2 as Partial<IAlertRuleByMessageType>)?.message_type
    );
  } else {
    return false;
  }
}

export const alertMessateTemplateAttributesEqual = (
  a: EAlertMessageAttribute[],
  b: EAlertMessageAttribute[],
): boolean => shallowListCompare(a, b, true);

const colorEffectsEqual = (
  colorEffect1: IAlertColourEffect | undefined,
  colorEffect2: IAlertColourEffect | undefined,
): boolean => {
  return (
    colorEffect1?.dark === colorEffect2?.dark &&
    colorEffect1?.light === colorEffect2?.light
  );
};

const alertMessageTemplatesEqual = (
  alertMessageTemplate1: IAlertMessageTemplate | undefined,
  alertMessageTemplate2: IAlertMessageTemplate | undefined,
): boolean => {
  if (alertMessageTemplate1 === undefined) {
    return alertMessageTemplate2 === undefined;
  }

  return (
    alertMessageTemplate2 !== undefined &&
    shallowListCompare(
      alertMessageTemplate1.attributes,
      alertMessageTemplate2.attributes,
      true,
    )
  );
};

export const alertRulesEqual = (
  alertRule1: TEditableAlertRule | undefined,
  alertRule2: TEditableAlertRule | undefined,
): boolean => {
  if (alertRule1 === undefined) {
    return alertRule2 === undefined;
  }

  return (
    alertRule2 !== undefined &&
    alertRule1.type === alertRule2.type &&
    alertRule1.name === alertRule2.name &&
    alertRule1.group === alertRule2.group &&
    alertRule1.severity === alertRule2.severity &&
    colorEffectsEqual(alertRule1.color_effect, alertRule2.color_effect) &&
    alertRule1.sound_effect === alertRule2.sound_effect &&
    alertRule1.alert_duration_hours === alertRule2.alert_duration_hours &&
    alertMessageTemplatesEqual(
      alertRule1.alert_message_template,
      alertRule2.alert_message_template,
    ) &&
    compareTypeSpecificAttributes(alertRule1, alertRule2)
  );
};
