import { AxiosResponse } from 'axios';
import { NOT_FOUND_STATUS_CODE } from 'constants/misc';
import { EActionState } from 'enums/General';
import { IETagValidationResponse } from 'interfaces/ETag';
import { useCallback, useState } from 'react';
import { TErrorMessage } from 'types/Error';
import { captureError } from 'utils/error';
import {
  extractValidationFailureMessages,
  extractValidationWarningMessages,
} from 'utils/eTag';
import { isEmptyValue, isSuccessStatus } from 'utils/general';

export interface IUseValidationSubmissionHandlersResult {
  errorMessage: TErrorMessage;
  isValidateAndSubmitSuccessful: boolean;
  isValidateSuccessful: boolean;
  isValidating: boolean;
  setSkipValidation: (skipValidation: boolean) => void;
  setValidationFailures: (validationFailures: string[]) => void;
  setValidationWarnings: (validationWarnings: string[]) => void;
  skipValidation: boolean;
  successMessage: string | null;
  validateActionState: EActionState;
  validateAndSubmitActionState: EActionState;
  validationFailures: string[];
  validationWarnings: string[];
  wrapValidateAndSubmitHandler: (
    validateAndSubmitHandler: (
      skipValidation: boolean,
    ) => Promise<AxiosResponse<IETagValidationResponse>>,
  ) => () => Promise<void>;
  wrapValidateHandler: (
    validateHandler: () => Promise<AxiosResponse<IETagValidationResponse>>,
  ) => () => Promise<void>;
}

export const useValidationSubmissionHandlers = (
  requestDisplayName: string,
): IUseValidationSubmissionHandlersResult => {
  const [validateActionState, setValidateActionState] = useState<EActionState>(
    EActionState.NoAction,
  );
  const [validateAndSubmitActionState, setValidateAndSubmitActionState] =
    useState<EActionState>(EActionState.NoAction);
  const [skipValidation, setSkipValidation] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<TErrorMessage>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [validationFailures, setValidationFailures] = useState<string[]>([]);
  const [validationWarnings, setValidationWarnings] = useState<string[]>([]);
  const [isValidating, setIsValidating] = useState<boolean>(false);
  const [isValidateSuccessful, setIsValidateSuccessful] =
    useState<boolean>(false);
  const [isValidateAndSubmitSuccessful, setIsValidateAndSubmitSuccessful] =
    useState<boolean>(false);

  const wrapHandler =
    (
      setActionState: (actionState: EActionState) => void,
      handler: () => Promise<void>,
    ) =>
    async () => {
      try {
        setIsValidating(true);
        setIsValidateSuccessful(false);
        setIsValidateAndSubmitSuccessful(false);
        setValidateActionState(EActionState.NoAction);
        setValidateAndSubmitActionState(EActionState.NoAction);
        setErrorMessage(null);
        setSuccessMessage(null);
        setValidationFailures([]);
        setValidationWarnings([]);
        setActionState(EActionState.Actioning);
        await handler();
      } catch (error: any) {
        captureError(error, 'Error in validation/submission request');

        setIsValidating(false);

        setActionState(EActionState.Failed);

        setErrorMessage('Something went wrong. Please try again later.');
      } finally {
        setIsValidating(false);
      }
    };

  const wrapValidateHandler = useCallback(
    (
      validateHandler: () => Promise<AxiosResponse<IETagValidationResponse>>,
    ): (() => Promise<void>) => {
      return wrapHandler(setValidateActionState, async () => {
        const response: AxiosResponse<IETagValidationResponse> =
          await validateHandler();

        if (isSuccessStatus(response.status)) {
          const response200: IETagValidationResponse = response.data;

          setValidationFailures(
            extractValidationFailureMessages(
              response200.response?.failure_list ?? [],
            ),
          );

          setValidationWarnings(
            extractValidationWarningMessages(
              response200.response?.warning_list ?? [],
            ),
          );

          if (response200.response?.valid) {
            setSuccessMessage(
              `${requestDisplayName} request passed validations`,
            );
            setIsValidateSuccessful(true);
          }
          // action succeeded even though there are validation failures
          setValidateActionState(EActionState.Succeeded);
        } else {
          const msg = `Validate ${requestDisplayName} request error message: ${
            response.data?.errorMessage ?? ''
          }`;
          setErrorMessage(msg);
          setValidateActionState(EActionState.Failed);
          throw new Error(msg);
        }
      });
    },
    [
      requestDisplayName,
      setErrorMessage,
      setSuccessMessage,
      setValidationFailures,
      setValidationWarnings,
      setValidateActionState,
    ],
  );

  const getErrorMessage = useCallback(
    (response: AxiosResponse<IETagValidationResponse>): string => {
      const msg = isEmptyValue(response.data?.errorMessage)
        ? `${requestDisplayName} request failed. Please try again later.`
        : `${requestDisplayName} request error message: ${response.data?.errorMessage}`;
      return msg;
    },
    [requestDisplayName],
  );

  const wrapValidateAndSubmitHandler = useCallback(
    (
      validateAndSubmitHandler: (
        skipValidation: boolean,
      ) => Promise<AxiosResponse<IETagValidationResponse>>,
    ): (() => Promise<void>) => {
      return wrapHandler(setValidateAndSubmitActionState, async () => {
        const response: AxiosResponse<IETagValidationResponse> =
          await validateAndSubmitHandler(skipValidation);

        setSkipValidation(false);

        setValidationWarnings(
          extractValidationWarningMessages(
            response.data.response?.warning_list ?? [],
          ),
        );

        if (response.status === 400) {
          const response400: IETagValidationResponse = response.data;
          const msgs: string[] = extractValidationFailureMessages(
            response400.response?.failure_list ?? [],
          );
          // No validation failures means it must have failed during save or submit
          if (msgs.length === 0) {
            setErrorMessage(getErrorMessage(response));
          }
          setValidationFailures(msgs);
          // api call worked, just want to display validation errors
          setValidateAndSubmitActionState(EActionState.Succeeded);
        } else if (response.status === 429) {
          setErrorMessage('Authority responded: "DUPLICATE".');
          setValidateAndSubmitActionState(EActionState.Failed);
        } else if (response.status === NOT_FOUND_STATUS_CODE) {
          setErrorMessage(
            'Authority responded with "FAIL". Please try again later.',
          );
          setValidateAndSubmitActionState(EActionState.Failed);
        } else if (isSuccessStatus(response.status)) {
          setSuccessMessage(
            skipValidation
              ? `${requestDisplayName} request submitted without validations`
              : `${requestDisplayName} request passed validations and was submitted`,
          );
          setValidateAndSubmitActionState(EActionState.Succeeded);
          setIsValidateAndSubmitSuccessful(true);
        } else {
          const msg = getErrorMessage(response);
          setErrorMessage(msg);
          setValidateAndSubmitActionState(EActionState.Failed);
          throw new Error(msg);
        }
      });
    },
    [
      getErrorMessage,
      requestDisplayName,
      setErrorMessage,
      setIsValidateAndSubmitSuccessful,
      setSuccessMessage,
      setValidateAndSubmitActionState,
      setValidationFailures,
      setValidationWarnings,
      skipValidation,
    ],
  );

  return {
    errorMessage,
    isValidateAndSubmitSuccessful,
    isValidateSuccessful,
    isValidating,
    setSkipValidation,
    setValidationFailures,
    setValidationWarnings,
    skipValidation,
    successMessage,
    validateActionState,
    validateAndSubmitActionState,
    validationFailures,
    validationWarnings,
    wrapValidateAndSubmitHandler,
    wrapValidateHandler,
  };
};
