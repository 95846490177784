import {
  EVENING_TIME_SUFFIX,
  MORNING_TIME_SUFFIX,
  SUPPORTED_DATE_TIME_STRING_REGEX,
} from 'components/organisms/ProfileDataGrid/constants';
import { NULL_CELL_VALUE } from 'constants/Component';
import {
  PROFILE_PATH_START_KEY,
  PROFILE_PATH_STOP_KEY,
} from 'constants/Detail';
import { EProfileDataGridCellType } from 'enums/Detail';
import { IDataGridPosition } from 'interfaces/Component';
import { IProfileDataGridCell } from 'interfaces/Detail';
import { IEnergyProfile } from 'interfaces/General';
import { IDetailEnergyProfileSnapshot } from 'reduxes/Detail/types';
import { TTimeZone } from 'types/DateTime';
import { TProfileDataGridColumn, TProfileDataGridRow } from 'types/Detail';
import { TDataGridMatrix } from 'types/General';
import { updateColumns } from 'utils/component';
import { isEmptyValue } from 'utils/general';
import { toFormattedUtcString } from 'utils/time';
import { ZonedDateTime } from 'utils/zonedDateTime';

export const getProfileDataGridCellToString =
  (timeZone: TTimeZone) =>
  (
    cell: IProfileDataGridCell | null | undefined,
    useNullCellValue?: boolean,
  ): string => {
    let valueAsString: string = '';

    if (!isEmptyValue(cell?.value)) {
      const { type, value } = cell!;

      if (type === EProfileDataGridCellType.DateTimeString) {
        valueAsString = toFormattedUtcString(
          ZonedDateTime.parseIso(value as string, timeZone),
        );
      } else if (type === EProfileDataGridCellType.Number) {
        valueAsString = value as string;
      } else if (type === EProfileDataGridCellType.EnergyProfile) {
        const energyProfile: IEnergyProfile = value as IEnergyProfile;

        valueAsString =
          energyProfile.mw === null ? '' : energyProfile.mw.toString();
      } else if (type === EProfileDataGridCellType.EnergyProfileSnapshot) {
        const energyProfileSnapshot: IDetailEnergyProfileSnapshot =
          value as IDetailEnergyProfileSnapshot;

        valueAsString =
          energyProfileSnapshot.mw === null
            ? ''
            : energyProfileSnapshot.mw.toString();
      }
    }

    if (valueAsString === '' && useNullCellValue === true) {
      return NULL_CELL_VALUE;
    }

    return valueAsString;
  };

export const getSelectedRegionDimesions = () => {
  const height: number = 0;
  const width: number = 0;

  return { height, width };
};

export const parseDateTimeString = (
  dateTimeString: string,
  timeZone: TTimeZone,
): ZonedDateTime => {
  const dateTime: RegExpMatchArray | null = dateTimeString.match(
    SUPPORTED_DATE_TIME_STRING_REGEX,
  );

  if (dateTime === null) {
    return ZonedDateTime.parseIso(dateTimeString, timeZone);
  }

  const month: string = dateTime[1].padStart(2, '0');
  const day: string = dateTime[2].padStart(2, '0');
  const timeSuffix: string | undefined = dateTime[7];
  let hour: string = dateTime[4];

  if (timeSuffix === MORNING_TIME_SUFFIX) {
    hour = (parseInt(hour, 10) % 12).toString();
  } else if (timeSuffix === EVENING_TIME_SUFFIX) {
    let hourValue: number = parseInt(hour, 10);

    if (hourValue < 12) {
      hour = (hourValue + 12).toString();
    }
  }

  return ZonedDateTime.parse(
    `${dateTime[3]}-${month}-${day}-${hour}-${dateTime[5]}`,
    timeZone,
    'YYYY-MM-DD-hh-mm',
  );
};

export const getUpdateRowWithValue =
  (timeZone: TTimeZone) =>
  (
    value: string,
    targetColumnKey: string,
    targetRow: TProfileDataGridRow,
  ): TProfileDataGridRow => {
    const targetCell: IProfileDataGridCell | null | undefined =
      targetRow[targetColumnKey];

    if (targetCell === undefined) {
      throw new Error(
        `Invalid targetColumnKey: ${targetColumnKey} for targetRow: ${JSON.stringify(
          targetRow,
        )}`,
      );
    }

    let updatedRow: TProfileDataGridRow = targetRow;

    if (
      targetCell === null ||
      targetCell.type === EProfileDataGridCellType.Number
    ) {
      let updatedValue: number | undefined = undefined;

      if (value !== NULL_CELL_VALUE) {
        const numberValue: number = parseInt(value, 10);

        if (!Number.isNaN(numberValue)) {
          updatedValue = numberValue;
        }
      }

      if (
        targetCell === null ||
        (targetCell !== null && (targetCell.value as number) !== updatedValue)
      ) {
        if (updatedValue === undefined) {
          updatedRow = {
            ...updatedRow,
            [targetColumnKey]: null,
          };
        } else {
          updatedRow = {
            ...updatedRow,
            [targetColumnKey]: {
              type: EProfileDataGridCellType.Number,
              value: updatedValue,
            },
          };
        }
      }
    } else if (targetCell.type === EProfileDataGridCellType.DateTimeString) {
      let updatedValue: string | undefined = undefined;

      if (value === NULL_CELL_VALUE) {
        updatedValue = NULL_CELL_VALUE;
      } else {
        const dateTimeString: string = toFormattedUtcString(
          parseDateTimeString(value, timeZone),
        );

        if ((targetCell.value as string) !== dateTimeString) {
          updatedValue = dateTimeString;
        }
      }

      if (updatedValue !== undefined) {
        updatedRow = {
          ...updatedRow,
          [targetColumnKey]: {
            type: EProfileDataGridCellType.DateTimeString,
            value: updatedValue === NULL_CELL_VALUE ? '' : updatedValue,
          },
        };
      }
    } else if (targetCell.type === EProfileDataGridCellType.EnergyProfile) {
      const targetCellValue: IEnergyProfile =
        targetCell.value as IEnergyProfile;
      let updatedValue: number | null | undefined = undefined;

      if (value !== NULL_CELL_VALUE) {
        const numberValue: number = parseInt(value, 10);

        if (!Number.isNaN(numberValue)) {
          updatedValue = numberValue;
        }
      }

      updatedValue = updatedValue === undefined ? null : updatedValue;

      if (targetCell !== null && targetCellValue.mw !== updatedValue) {
        updatedRow = {
          ...updatedRow,
          [targetColumnKey]: {
            type: EProfileDataGridCellType.EnergyProfile,
            value: {
              ...targetCellValue,
              mw: updatedValue,
            },
          },
        };
      }
    }

    return updatedRow;
  };

export const getUpdateExtraRows =
  (
    updateRowWithValue: (
      value: string,
      targetColumnKey: string,
      targetRow: TProfileDataGridRow,
    ) => TProfileDataGridRow,
  ) =>
  (
    updatedRowsLength: number,
    startPosition: IDataGridPosition,
    endPosition: IDataGridPosition,
    extraRowsEndIdx: number,
    columns: TProfileDataGridColumn[],
    matrix: TDataGridMatrix,
  ): TProfileDataGridRow[] => {
    const extraRows: TProfileDataGridRow[] = [];
    // It is expected that none of the prior rows will be updated in the next
    // update loop since all our indexes at this point should be for the extra
    // rows only. As such we only need to have empty rows of the same length as
    // the updatedRows in order to keep the row dimension matching the
    // matrix row dimension.
    const extendedRows: TProfileDataGridRow[] = new Array(updatedRowsLength);

    for (
      let rowIdx: number = endPosition.rowIdx + 1;
      rowIdx < extraRowsEndIdx;
      rowIdx += 1
    ) {
      const extraRow: TProfileDataGridRow = {};

      columns.forEach((column: TProfileDataGridColumn) => {
        extraRow[column.key] = null;
      });

      // We must always correctly type cells so that the updateColumns function
      // is able to determine how to interpret the pasted values
      extraRow[PROFILE_PATH_START_KEY] = {
        type: EProfileDataGridCellType.DateTimeString,
        value: '',
      };
      extraRow[PROFILE_PATH_STOP_KEY] = {
        type: EProfileDataGridCellType.DateTimeString,
        value: '',
      };

      extendedRows.push(extraRow);

      updateColumns(
        matrix,
        columns,
        extendedRows,
        rowIdx,
        startPosition.rowIdx,
        startPosition.idx,
        endPosition.idx,
        updateRowWithValue,
      );
    }

    for (let i: number = updatedRowsLength; i < extendedRows.length; i += 1) {
      extraRows.push(extendedRows[i]);
    }

    return extraRows;
  };

export const copyProfileDataGridCellTo = (
  sourceRow: TProfileDataGridRow,
  sourceColumnKey: string,
  targetRow: TProfileDataGridRow,
  targetColumnKey: string,
): TProfileDataGridRow => {
  const sourceCell: IProfileDataGridCell | null | undefined =
    sourceRow[sourceColumnKey];

  if (sourceCell === undefined) {
    throw new Error(
      `Invalid sourceColumnKey: ${sourceColumnKey} for sourceRow: ${JSON.stringify(
        sourceRow,
      )}`,
    );
  }

  const targetCell: IProfileDataGridCell | null | undefined =
    targetRow[targetColumnKey];

  if (targetCell === undefined) {
    throw new Error(
      `Invalid targetColumnKey: ${targetColumnKey} for targetRow: ${JSON.stringify(
        targetRow,
      )}`,
    );
  }

  if (sourceCell === null) {
    if (targetCell !== null) {
      if (
        targetCell.type === EProfileDataGridCellType.Empty ||
        targetCell.type === EProfileDataGridCellType.Number
      ) {
        return { ...targetRow, [targetColumnKey]: sourceCell };
      } else if (targetCell.type === EProfileDataGridCellType.EnergyProfile) {
        return {
          ...targetRow,
          [targetColumnKey]: {
            type: EProfileDataGridCellType.EnergyProfile,
            value: {
              ...(targetCell.value as IEnergyProfile),
              mw: null,
            },
          },
        };
      }
    }
  } else {
    if (targetCell === null) {
      if (sourceCell.type === EProfileDataGridCellType.DateTimeString) {
        return targetRow;
      } else if (sourceCell.type === EProfileDataGridCellType.EnergyProfile) {
        const sourceCellValue: IEnergyProfile =
          sourceCell.value as IEnergyProfile;

        return {
          ...targetRow,
          [targetColumnKey]:
            sourceCellValue.mw === null
              ? null
              : {
                  type: EProfileDataGridCellType.Number,
                  value: sourceCellValue.mw,
                },
        };
      }

      return { ...targetRow, [targetColumnKey]: { ...sourceCell } };
    } else {
      if (sourceCell.type === EProfileDataGridCellType.DateTimeString) {
        if (targetCell.type === EProfileDataGridCellType.DateTimeString) {
          return { ...targetRow, [targetColumnKey]: { ...sourceCell } };
        }
      } else if (sourceCell.type === EProfileDataGridCellType.Empty) {
        if (targetCell.type === EProfileDataGridCellType.DateTimeString) {
          return {
            ...targetRow,
            [targetColumnKey]: {
              type: EProfileDataGridCellType.DateTimeString,
              value: '',
            },
          };
        } else if (targetCell.type === EProfileDataGridCellType.EnergyProfile) {
          return {
            ...targetRow,
            [targetColumnKey]: {
              type: EProfileDataGridCellType.EnergyProfile,
              value: {
                ...(targetCell.value as IEnergyProfile),
                mw: null,
              },
            },
          };
        } else if (targetCell.type === EProfileDataGridCellType.Number) {
          return { ...targetRow, [targetColumnKey]: null };
        }
      } else if (sourceCell.type === EProfileDataGridCellType.EnergyProfile) {
        const sourceCellValue: IEnergyProfile =
          sourceCell.value as IEnergyProfile;

        if (
          targetCell.type === EProfileDataGridCellType.Empty ||
          targetCell.type === EProfileDataGridCellType.Number
        ) {
          return {
            ...targetRow,
            [targetColumnKey]:
              sourceCellValue.mw === null
                ? null
                : {
                    type: EProfileDataGridCellType.Number,
                    value: sourceCellValue.mw,
                  },
          };
        } else if (targetCell.type === EProfileDataGridCellType.EnergyProfile) {
          const targetCellValue: IEnergyProfile =
            targetCell.value as IEnergyProfile;

          return {
            ...targetRow,
            [targetColumnKey]: {
              type: EProfileDataGridCellType.EnergyProfile,
              value: {
                ...targetCellValue,
                mw: sourceCellValue.mw,
              },
            },
          };
        }
      } else if (sourceCell.type === EProfileDataGridCellType.Number) {
        if (targetCell.type === EProfileDataGridCellType.Empty) {
          return { ...targetRow, [targetColumnKey]: { ...sourceCell } };
        } else if (targetCell.type === EProfileDataGridCellType.EnergyProfile) {
          const targetCellValue: IEnergyProfile =
            targetCell.value as IEnergyProfile;

          return {
            ...targetRow,
            [targetColumnKey]: {
              type: EProfileDataGridCellType.EnergyProfile,
              value: {
                ...targetCellValue,
                mw: sourceCell.value as number,
              },
            },
          };
        } else if (targetCell.type === EProfileDataGridCellType.Number) {
          return { ...targetRow, [targetColumnKey]: { ...sourceCell } };
        }
      }
    }
  }

  return targetRow;
};
