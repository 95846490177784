import { TStateTransform } from '../../../types/General';
import { IDetailState } from '../../../reduxes/Detail/types';
import { IEtagTransmissionPriorityConfiguration } from '../../../interfaces/ETag';
import { IEditTransmissionPrioritySegmentInformation } from './types';
import { BUTTON_ICON_DIMENSION_VALUE } from '../../../constants/styles';

export const PRIORITIES_REMOVE_BUTTON_OFFSET = {
  right: '-10px',
  top: `calc(50% - ${BUTTON_ICON_DIMENSION_VALUE / 2}px)`,
};

export const PRIORITIES_ADD_BUTTON_OFFSET = {
  left: '-847px',
  top: 'calc(100% - 4px)',
};

const getInitialTransmissionPriorityConfiguration = (
  key?: string,
  pid?: number | null,
): IEtagTransmissionPriorityConfiguration => ({
  key,
  pid: pid || null,
  tp: null,
  owner: null,
  por: null,
  pod: null,
  source: null,
  sink: null,
  order: null,
  product: null,
});

const copyTransmissionPriorityConfiguration = (
  transmissionPriorityConfiguration: IEtagTransmissionPriorityConfiguration,
): IEtagTransmissionPriorityConfiguration => ({
  key: transmissionPriorityConfiguration.key,
  pid: transmissionPriorityConfiguration.pid,
  tp: transmissionPriorityConfiguration.tp,
  owner: transmissionPriorityConfiguration.owner,
  por: transmissionPriorityConfiguration.por,
  pod: transmissionPriorityConfiguration.pod,
  source: transmissionPriorityConfiguration.source,
  sink: transmissionPriorityConfiguration.sink,
  order: transmissionPriorityConfiguration.order,
  product: transmissionPriorityConfiguration.product,
});

export const editTransmissionPrioritySegmentsToDetailState = (
  editTransmissionPrioritySegmentInformation: IEditTransmissionPrioritySegmentInformation,
): TStateTransform<IDetailState> => {
  return (detailState: IDetailState): IDetailState => {
    const updatedDetailState: IDetailState = { ...detailState };
    let updatedTransmissionPriorityConfigurations: IEtagTransmissionPriorityConfiguration[] =
      [...updatedDetailState.transmissionPriorityConfigurations];

    let segment: IEtagTransmissionPriorityConfiguration | undefined;
    let updatedTransmissionPriorityConfiguration: IEtagTransmissionPriorityConfiguration =
      {};
    let highestOrder = 0;

    let {
      addAfterTransmissionPrioritySegment,
      editTransmissionPrioritySegmentMap,
      removeTransmissionPrioritySegment,
      removeAllTransmissionSegments,
      removeKey,
    } = editTransmissionPrioritySegmentInformation;

    if (removeAllTransmissionSegments) {
      updatedTransmissionPriorityConfigurations = [];
    }

    if (addAfterTransmissionPrioritySegment) {
      const currentSegment = updatedTransmissionPriorityConfigurations.find(
        (segment) => segment.key === addAfterTransmissionPrioritySegment,
      );

      const maxOrder = currentSegment
        ? Math.max(
            ...updatedTransmissionPriorityConfigurations
              .slice()
              .filter(
                (configuration: IEtagTransmissionPriorityConfiguration) =>
                  configuration.pid === currentSegment.pid &&
                  configuration.order,
              )
              .map((segment) => (segment.order ? segment.order : 1)),
            0,
          )
        : 0;

      updatedTransmissionPriorityConfigurations.push({
        key:
          currentSegment && currentSegment.order
            ? `${currentSegment.pid}-${currentSegment.order ? maxOrder + 1 : 1}`
            : '1-1',
        pid: (currentSegment && currentSegment.pid) || null,
        order: maxOrder + 1 || null,
      });

      updatedDetailState.transmissionPriorityConfigurations =
        updatedTransmissionPriorityConfigurations;
    }

    if (removeTransmissionPrioritySegment) {
      const removedSegment = updatedTransmissionPriorityConfigurations.find(
        (segment) => segment.key === removeTransmissionPrioritySegment,
      );
      const index = updatedTransmissionPriorityConfigurations.findIndex(
        (config) => config.key === removeTransmissionPrioritySegment,
      );
      if (removedSegment) {
        const removedSegmentPid = removedSegment.pid;
        const removedSegmentOrder = removedSegment.order || 1;

        updatedTransmissionPriorityConfigurations
          .filter(
            (segment) =>
              segment.pid === removedSegmentPid &&
              segment.order &&
              segment.order > removedSegmentOrder,
          )
          .map((segment) => {
            if (segment.order) {
              segment.order = segment.order - 1;
              segment.key = `${segment.pid}-${segment.order}`;
            }
            return segment;
          });
      }
      if (index > -1) {
        delete updatedTransmissionPriorityConfigurations[index];
      }

      updatedDetailState.transmissionPriorityConfigurations =
        updatedTransmissionPriorityConfigurations;
    }

    if (editTransmissionPrioritySegmentMap !== undefined) {
      for (let transmissionPriorityConfigurationKey in editTransmissionPrioritySegmentMap) {
        const transmissionPriorityConfiguration: IEtagTransmissionPriorityConfiguration =
          editTransmissionPrioritySegmentMap[
            transmissionPriorityConfigurationKey
          ];

        let transmissionPriorityConfigurationIndex: number =
          updatedTransmissionPriorityConfigurations.findIndex(
            (
              transmissionPriorityConfiguration: IEtagTransmissionPriorityConfiguration,
            ): boolean =>
              transmissionPriorityConfiguration.key ===
              transmissionPriorityConfigurationKey,
          );

        updatedTransmissionPriorityConfiguration =
          transmissionPriorityConfigurationIndex === -1
            ? getInitialTransmissionPriorityConfiguration(
                transmissionPriorityConfiguration.key,
                transmissionPriorityConfiguration.pid,
              )
            : copyTransmissionPriorityConfiguration(
                updatedTransmissionPriorityConfigurations[
                  transmissionPriorityConfigurationIndex
                ],
              );

        const { key, tp, product, order, por, pod, source, sink, owner } =
          transmissionPriorityConfiguration;

        let { pid } = transmissionPriorityConfiguration;

        if (!pid && key) {
          pid = parseInt(key.substring(0, 1));
        }

        //segmentPid = pid;

        if (key && pid) {
          updatedTransmissionPriorityConfiguration.pid = pid;
          updatedTransmissionPriorityConfiguration.key = key;
        }

        let diffSegment = false;
        let outdatedSegmentIndex =
          updatedTransmissionPriorityConfigurations.findIndex(
            (config) => config.key === removeKey,
          );
        if (removeKey) {
          const outdatedSegment =
            updatedTransmissionPriorityConfigurations.find(
              (config) => config.key === removeKey,
            );
          if (outdatedSegment) {
            const orderCollection = updatedTransmissionPriorityConfigurations
              .slice()
              .filter(
                //(segment) => outdatedSegment.pid && outdatedSegment.pid === pid,
                (segment) => segment.pid && segment.pid === pid,
              )
              .map((segment) => segment.order || 0);
            const newOrder =
              orderCollection.length > 0 ? Math.max(...orderCollection) + 1 : 1;

            const originalOrder = outdatedSegment.order || 1;

            outdatedSegment.key = `${pid}-${newOrder}`;
            outdatedSegment.pid = pid;
            outdatedSegment.order = newOrder;

            const originalPid = parseInt(removeKey.substring(0, 1));

            updatedTransmissionPriorityConfiguration = outdatedSegment;

            updatedTransmissionPriorityConfigurations
              .filter(
                (config) =>
                  config.pid === originalPid &&
                  config.order &&
                  config.order > originalOrder,
              )
              .map((config) => {
                if (config.order) {
                  config.order = config.order - 1;
                  config.key = `${originalPid}-${config.order}`;
                }

                return config;
              });

            diffSegment = true;
          }
        }
        if (pid !== undefined && key !== undefined && !removeKey) {
          const currentPid = parseInt(key.substring(0, 1));
          if (currentPid !== pid) {
            const currentKey = updatedTransmissionPriorityConfiguration.key;
            const orderCollection = updatedTransmissionPriorityConfigurations
              .filter((segment) => segment.pid && segment.pid === pid)
              .map((segment) => segment.order || 0);
            highestOrder =
              orderCollection.length > 0 ? Math.max(...orderCollection) : 0;
            const oldSegment = updatedTransmissionPriorityConfigurations
              .slice()
              .find((item) => item.key === removeKey || key);
            segment = updatedTransmissionPriorityConfigurations.find(
              (item) => item.key === currentKey,
            );

            // Update the order based on the old pid
            const oldPidSegments =
              updatedTransmissionPriorityConfigurations.filter(
                (segment) => segment.pid === currentPid,
              );

            if (oldPidSegments.length > 0) {
              const previousRecord = oldPidSegments
                .slice()
                .find((segment) => segment.key === removeKey || key);
              if (previousRecord && previousRecord.order) {
                oldPidSegments
                  .slice()
                  .filter(
                    (segment) =>
                      segment.order &&
                      previousRecord.order &&
                      segment.order > previousRecord.order,
                  )
                  .map((segment) => {
                    if (segment.order) {
                      segment.order = segment.order - 1;
                      segment.key = `${segment.pid}-${segment.order}`;
                    }

                    return segment;
                  });
              }
            }

            if (segment) {
              updatedTransmissionPriorityConfiguration.key = `${pid}-${
                highestOrder + 1
              }`;
              updatedTransmissionPriorityConfiguration.pid = pid;
              updatedTransmissionPriorityConfiguration.tp = segment.tp;
              updatedTransmissionPriorityConfiguration.owner = segment.owner;
              updatedTransmissionPriorityConfiguration.pod = segment.pod;
              updatedTransmissionPriorityConfiguration.por = segment.por;
              updatedTransmissionPriorityConfiguration.source = segment.source;
              updatedTransmissionPriorityConfiguration.sink = segment.sink;
              updatedTransmissionPriorityConfiguration.order = highestOrder + 1;
              updatedTransmissionPriorityConfiguration.product =
                (oldSegment && oldSegment.product) || segment.product;
              diffSegment = true;
            }
          }
        }

        let segmentWithOrderAssigned:
          | IEtagTransmissionPriorityConfiguration
          | undefined = undefined;

        const configPid = updatedTransmissionPriorityConfiguration.pid;

        if (order !== undefined && order !== null && !diffSegment) {
          segmentWithOrderAssigned =
            updatedTransmissionPriorityConfigurations.find(
              (segment) =>
                segment.pid === configPid &&
                segment.order === parseInt(order.toString()),
            );

          if (segmentWithOrderAssigned) {
            segmentWithOrderAssigned.key = `${segmentWithOrderAssigned.pid}-${updatedTransmissionPriorityConfiguration.order}`;
            segmentWithOrderAssigned.order =
              updatedTransmissionPriorityConfiguration.order;
          }

          updatedTransmissionPriorityConfiguration.key = `${updatedTransmissionPriorityConfiguration.pid}-${order}`;
          updatedTransmissionPriorityConfiguration.order = parseInt(
            order.toString(),
          );
        }

        if (product !== undefined) {
          updatedTransmissionPriorityConfiguration.product = product;
        }

        if (tp !== undefined) {
          updatedTransmissionPriorityConfiguration.tp = tp;
        }

        if (por !== undefined) {
          updatedTransmissionPriorityConfiguration.por = por;
        }

        if (pod !== undefined) {
          updatedTransmissionPriorityConfiguration.pod = pod;
        }

        if (source !== undefined) {
          updatedTransmissionPriorityConfiguration.source = source;
        }

        if (sink !== undefined) {
          updatedTransmissionPriorityConfiguration.sink = sink;
        }

        if (owner !== undefined) {
          updatedTransmissionPriorityConfiguration.owner = owner;
        }

        if (!removeKey) {
          updatedTransmissionPriorityConfigurations[
            transmissionPriorityConfigurationIndex === -1
              ? outdatedSegmentIndex !== -1
                ? outdatedSegmentIndex
                : updatedTransmissionPriorityConfigurations.length
              : transmissionPriorityConfigurationIndex
          ] = updatedTransmissionPriorityConfiguration;
        }
      }
    }

    let sortedUpdatedTransmissionPriorityConfigurations =
      updatedTransmissionPriorityConfigurations.slice().filter(Boolean);
    sortedUpdatedTransmissionPriorityConfigurations =
      sortedUpdatedTransmissionPriorityConfigurations
        .slice()
        .sort((a, b) =>
          a.key && b.key
            ? a.key?.toLowerCase() > b.key?.toLowerCase()
              ? 1
              : -1
            : -1,
        );

    const initialEtagTransmissionPriorityConfiguration: IEtagTransmissionPriorityConfiguration[] =
      [
        {
          key: '',
          pid: null,
          tp: null,
          owner: null,
          por: null,
          pod: null,
          source: null,
          sink: null,
          order: null,
          product: null,
        },
      ];

    updatedDetailState.transmissionPriorityConfigurations =
      sortedUpdatedTransmissionPriorityConfigurations.length > 0
        ? sortedUpdatedTransmissionPriorityConfigurations
        : initialEtagTransmissionPriorityConfiguration;

    return updatedDetailState;
  };
};
