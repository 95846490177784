import {
  chooseBestSize,
  dayBasedImageNumber,
} from 'components/atoms/IndustryBackground/helpers';
import { ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';

interface IBackgroundContainerProps {
  fileSize: string;
  height?: string;
  imageNumber: () => number;
}

const BackgroundContainer = styled.div<IBackgroundContainerProps>`
  ${(props) =>
    `background-image: url('${process.env.PUBLIC_URL}/assets/images/background${
      props.fileSize
    }-bg-${props.imageNumber()}.jpg');`}
  ${(props) => (props.height === undefined ? '' : `height: ${props.height};`)}
`;

interface IIndustryBackgroundProps {
  children: ReactNode;
  height?: string;
  imageNumber?: () => number;
}

const IndustryBackground = ({
  children,
  height,
  imageNumber,
}: IIndustryBackgroundProps) => {
  const [fileSize, setFileSize] = useState('');

  useEffect(() => {
    const handleResize = () => {
      setFileSize(chooseBestSize(window.innerWidth, window.innerHeight));
    };

    // Call once to set the initial fileSize
    handleResize();

    window.addEventListener('resize', handleResize);

    // unregister listener when component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <BackgroundContainer
      fileSize={fileSize}
      imageNumber={
        imageNumber === undefined ? dayBasedImageNumber : imageNumber
      }
      height={height}
    >
      {children}
    </BackgroundContainer>
  );
};

export default IndustryBackground;
