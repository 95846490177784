import { API } from 'aws-amplify';
import { AxiosResponse } from 'axios';
import { ICustomFilter } from 'interfaces/Filter';
import {
  ISummaryCustomFilterResponse,
  ISummaryFilterRemoveResponse,
  ISummaryFiltersV2Response,
} from 'interfaces/Summary';
import {
  TAGS_SUMMARY_FILTER_ROUTE,
  TAGS_SUMMARY_FILTERSV2_ROUTE,
  TAGS_SUMMARY_ROUTE,
} from 'services/agent/constants';
import { AGENT_SERVICE_API_NAME, DEFAULT_OPTIONS } from 'services/constants';
import { TFilterId } from 'types/Filter';
import { TToEntityId } from 'types/ToEntity';
import { ZonedDateTime } from 'utils/zonedDateTime';

export const retrieveETagsSummaryAttribute = async (
  toEntityId: TToEntityId,
  start: ZonedDateTime,
  end: ZonedDateTime,
  filterId?: TFilterId,
): Promise<AxiosResponse> => {
  return await API.get(
    AGENT_SERVICE_API_NAME,
    TAGS_SUMMARY_ROUTE(toEntityId, start, end, filterId),
    DEFAULT_OPTIONS,
  );
};

export const retrieveSummaryCustomFilters = async (
  toEntityId: TToEntityId,
): Promise<AxiosResponse<ISummaryFiltersV2Response>> => {
  return await API.get(
    AGENT_SERVICE_API_NAME,
    TAGS_SUMMARY_FILTERSV2_ROUTE(toEntityId),
    DEFAULT_OPTIONS,
  );
};

export const createSummaryCustomFilter = async (
  toEntityId: TToEntityId,
  customFilter: ICustomFilter,
): Promise<AxiosResponse<ISummaryCustomFilterResponse>> => {
  const { filter_id, ...rest } = customFilter;
  const options = {
    ...DEFAULT_OPTIONS,
    body: rest,
  };
  return await API.put(
    AGENT_SERVICE_API_NAME,
    TAGS_SUMMARY_FILTERSV2_ROUTE(toEntityId),
    options,
  );
};

export const updateSummaryCustomFilter = async (
  toEntityId: TToEntityId,
  customFilter: ICustomFilter,
): Promise<AxiosResponse<ISummaryCustomFilterResponse>> => {
  const options = {
    ...DEFAULT_OPTIONS,
    body: customFilter,
  };
  return await API.put(
    AGENT_SERVICE_API_NAME,
    TAGS_SUMMARY_FILTERSV2_ROUTE(toEntityId),
    options,
  );
};

export const removeSummaryFilter = async (
  toEntityId: TToEntityId,
  filterId: TFilterId,
): Promise<AxiosResponse<ISummaryFilterRemoveResponse>> => {
  return await API.del(
    AGENT_SERVICE_API_NAME,
    TAGS_SUMMARY_FILTER_ROUTE(toEntityId, filterId),
    DEFAULT_OPTIONS,
  );
};
