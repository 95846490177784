import { AutoComplete, Input as AntDesignInput } from 'antd';
import { AxiosResponse } from 'axios';
import Button from 'components/atoms/Button/Button';
import ErrorMessage from 'components/atoms/ErrorMessage/ErrorMessage';
import SeparatedRowLayout from 'components/atoms/SeparatedRowLayout/SeparatedRowLayout';
import Spinner from 'components/atoms/Spinner/Spinner';
import { CONTAINER_MAX_WIDTH } from 'components/molecules/SetStatePanel/constants';
import { NOTES_MAX_LENGTH } from 'constants/Detail';
import {
  COLUMN_LAYOUT_SHARED_STYLES,
  STANDARD_SPACING,
} from 'constants/styles';
import { EApprovalStatus } from 'enums/Approval';
import { EActionState } from 'enums/General';
import { ChangeEvent, useState } from 'react';
import { setStateOnRequest } from 'services/approval/approval';
import styled from 'styled-components';
import { TNoteOption } from 'types/Component';
import { TErrorMessage } from 'types/Error';
import { TETagTagPrimaryKey } from 'types/ETag';
import { TToEntityId } from 'types/ToEntity';
import { captureError } from 'utils/error';
import { isEmptyValue, isSuccessStatus } from 'utils/general';
const { TextArea } = AntDesignInput;

const Label = styled.span`
  max-width: 130px;
  width: 100%;
`;

const Container = styled.div`
  ${COLUMN_LAYOUT_SHARED_STYLES}

  justify-content: flex-end;
  width: ${CONTAINER_MAX_WIDTH};
  & > button {
    margin-top: ${STANDARD_SPACING};
  }
`;

interface IRequestActionSetStateProps {
  approvalStatus: EApprovalStatus;
  noteOptions: TNoteOption[];
  notesRequired: boolean;
  requestId: number;
  tagPrimaryKey: TETagTagPrimaryKey | undefined;
  toEntityId: TToEntityId;
}

const SetStatePanel = (props: IRequestActionSetStateProps): JSX.Element => {
  const {
    approvalStatus,
    noteOptions,
    notesRequired,
    requestId,
    tagPrimaryKey,
    toEntityId,
  } = props;
  const [notes, setNotes] = useState<string | null>(null);
  const [actionState, setActionState] = useState<EActionState>(
    EActionState.NoAction,
  );
  const [isSettingState, setIsSettingState] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<TErrorMessage>(null);

  const handleNotesInput = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setNotes(event.target.value);
  };

  const handleSetState = async () => {
    try {
      setErrorMessage(null);
      setActionState(EActionState.Actioning);
      setIsSettingState(true);
      const response: AxiosResponse = await setStateOnRequest(
        toEntityId,
        tagPrimaryKey!, // action is disabled if tagPrimaryKey is undefined
        requestId,
        approvalStatus,
        notes ?? undefined,
      );
      if (!isSuccessStatus(response.status)) {
        setErrorMessage(response.data.errorMessage);
        throw new Error(
          `Set state response does not indicate success. ${response.data.errorMessage}.`,
        );
      }
      setActionState(EActionState.Succeeded);
    } catch (error: any) {
      captureError(error, 'Set State Failed');

      setActionState(EActionState.Failed);
    } finally {
      setIsSettingState(false);
    }
  };

  const isDisabled: boolean =
    notesRequired &&
    isEmptyValue(notes) &&
    `${notes}`.length < NOTES_MAX_LENGTH;

  return (
    <Container>
      <SeparatedRowLayout centered>
        <Label>
          Notes:
          {notesRequired ? null : (
            <>
              <br />
              (optional)
            </>
          )}
        </Label>
        <AutoComplete
          options={noteOptions}
          style={{ width: 400 }}
          filterOption={true}
        >
          <TextArea
            maxLength={NOTES_MAX_LENGTH}
            onChange={handleNotesInput}
            placeholder='Notes for Set State Request'
            rows={2}
            value={notes ?? undefined}
          />
        </AutoComplete>
      </SeparatedRowLayout>
      {isSettingState ? <Spinner /> : null}
      {!isEmptyValue(errorMessage) && (
        <ErrorMessage maxWidth={CONTAINER_MAX_WIDTH}>
          {errorMessage}
        </ErrorMessage>
      )}
      <Button
        actionState={actionState}
        isDisabled={isDisabled}
        label={`Set State`}
        onClick={handleSetState}
      />
    </Container>
  );
};

export default SetStatePanel;
